import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { ConfigurationProvider } from '../configuration/configuration.provider.service';
import { DateToolsService } from '../../services/utils/date-tools.service';
import { Detail, DistanceResponse, FilterMenu, ShipmentRequest, Tracking } from '../../interfaces';
import { DriverAppStatus } from '../../enums';
import { environment } from '../../../environments/environment';
import { TemperatureTrackingInterface } from '../../interfaces/temperature';

const trackingUrl = environment.trackingUrl;

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  public shipmentsByFilters: Subject<Array<FilterMenu>>;
  public detailSelected: Subject<Detail>;
  public shipmentSelected: Subject<ShipmentRequest>;
  public shipmentToUpdate: Subject<ShipmentRequest>;
  public shipmentsSearchBar: Subject<{shipment: string, db: boolean}>;

  constructor(
    private configurationService: ConfigurationProvider,
    private dateToolsService: DateToolsService,
    private http: HttpClient
  ) {
    this.shipmentsSearchBar = new Subject<{ shipment: string, db: boolean}>();
    this.shipmentsByFilters = new Subject<Array<FilterMenu>>();
    this.shipmentSelected = new Subject<ShipmentRequest>();
    this.detailSelected = new Subject<Detail>();
    this.shipmentToUpdate = new Subject<ShipmentRequest>();
  }

  /**
  *@description Actives the observable for the stop bar
  *@return Observable of Detail
  **/
  public getStopSelected(): Observable<Detail> {
    return this.detailSelected.asObservable();
  }

  /**
  *@description  Selects a stop from the stop bar
  *@params detail: Detail
  **/
  public selectStop(detail: Detail): void {
    this.detailSelected.next(detail);
  }

  /**
  *@description Clears the stop selected from the stop bar
  **/
  public clearStopSelected(): void {
    this.detailSelected.next();
  }

  /**
   * @description Starts the process to update maker
   * @param {ShipmentRequest} shipment Current shipment request
   */
  public shipmentValues(shipment: ShipmentRequest): void {
    this.shipmentToUpdate.next(shipment);
  }

  /**
   * @description Actives the observable for the update marker position
   */
  public updateShipmentTrackingValues(): Observable<ShipmentRequest> {
    return this.shipmentToUpdate.asObservable();
  }

  /**
   * @description Creates blobName and sasUrl
   * @param extension The file extension to create un blob
   * @return {object} Returns a SAS object file
   */
  public async generateSAS(extension: string): Promise<object> {
    return await this.http.get<any>(trackingUrl + '/generateSAS?extension=' + extension).toPromise();
  }

  /**
   * @description Creates an evidence, the property isRegisteredByMobileApp in query string is added to notice to endpoint
   * that isn't a evidence to save from mobile app
   * @param shipmentOid Current shipmnet's ObjectId
   * @param detailOid Current detail's ObjectId
   * @param evidenceType Kind of evidence
   * @param body Body with complete information
   */
  public async createEvidence(shipmentOid: string, detailOid: string, evidenceType: string, body: any): Promise<any> {
    const isEvidenceByMobileApp = false;

    return await this.http.post<any>(trackingUrl + '/shipmentRequests/' + shipmentOid + '/details/'
      + detailOid + '/evidence?evidenceType=' + evidenceType + '&isRegisteredByMobileApp=' + isEvidenceByMobileApp, body).toPromise();
  }

  /**
   * @description Get the shipment request’s tracking registry and objectId.
   * @param shipmentRequestOid Current shipment's ObjectId
   */
  public async getTracking(shipmentRequestOid: string): Promise<Tracking> {
    const url = `${trackingUrl}/shipmentRequests/${shipmentRequestOid}/tracking`;
    return await this.http.get<Tracking>(url).toPromise();
  }

  /**
   * @description Get Shipments's tracking by ObjectId
   * @param {Array<string>} shipmentsOids Shipments Object ids
   */
  public async getShipmentsTracking (shipmentsOids: Array<string>): Promise<Array<Tracking>> {
    const url = `${trackingUrl}/shipmentRequest/getShipmentsTracking`;
    const payload = { shipments: shipmentsOids };
    return await this.http.post<Array<Tracking>>(url, payload).toPromise();
  }

  /**
   * @description setShipmentKms
   * @param {string} shipmentOid _id of new shipment
   * @return {response} http response
   */
  public async setShipmentKms (shipmentOid: string): Promise<DistanceResponse> {
    const url = `${trackingUrl}/shipmentRequests/${shipmentOid}/distance`;
    return await this.http.post<DistanceResponse>(url, {}).toPromise();
  }

  /**
   * @description Checks if the shipment request has tracking to handle if traveled by app or not
   * @param {string} shipmentOId Shipment request OId
   * @returns {Promise<boolean>} true | false  if traveled by app
   */
  public async doesShipmentTraveledByApp(shipmentOId: string): Promise<boolean> {
    const url = `${trackingUrl}/shipmentRequest/${shipmentOId}/traveledByApp`;
    return await this.http.get<boolean>(url).toPromise();
  }

  /**
   * @description Get the temperatures of the route
   * @param {string} shipmentRequestOid Shipment request OId
   *  @param {string} shipperOid Shipper ID
   * @returns {Promise<Array<TemperatureTrackingInterface>>} Arrays of temperatures by shipmentRequestOid
   */
  public async getTemperaturesTracking(shipmentRequestOid: string, shipperOid: string): Promise<Array<TemperatureTrackingInterface>> {
    const url = `${trackingUrl}/shipper/${shipperOid}/shipmentRequests/${shipmentRequestOid}/temperatureTracking`;
    return await this.http.get<Array<TemperatureTrackingInterface>>(url).toPromise();
  }

  /**
   * @description Calculate the minutes between current date and last tracking registered to verify
   * if the driver is using the mobile app
   * @param {Date} lastDriverConnectionDate last tracking registered date
   * @return {string} the actual status of driver
   */
  public async verifyDriverAppConnection(lastDriverConnectionDate: Date): Promise<string> {
    try {
      const shipperConfig = await this.configurationService.getShipperConfig();
      const maxDriverInactiveTime = shipperConfig.driverConfig.maxDriverInactiveTime;
      const actualDate = new Date();
      const minutes = this.dateToolsService.differenceInMinutesBewteenTwoDates(actualDate, lastDriverConnectionDate);

      return (minutes <= maxDriverInactiveTime) ? DriverAppStatus.connectedStatus : DriverAppStatus.disconnectedStatus;
    } catch (error) {

    }
  }

  /**
   * @description Get last tracking date registered
   * @param {ShipmentRequest} shipment shipment to get last tracking registered
   * @return {Date} last tracking date registered
   */
  public getLastTrackingDateRegistered(shipment: ShipmentRequest): Date {
    const geopositions = shipment.tracking.polyline;
    geopositions.sort((a, b) => a.timestamp - b.timestamp).reverse();
    const lastGeopositionDate = new Date(geopositions[0].timestamp);

    return lastGeopositionDate;
  }

}
