import { ImportationCategory } from '../../../../interfaces';

export const LOCATION_INFO_FORM: ImportationCategory = {
  name: 'locationInfo',
  rules: [
    {
      name: 'originWarehouse',
      required: true
    },
    {
      name: 'destinationID',
      required: true
    },
    {
      name: 'destinationName',
      required: true
    },
    {
      name: 'destinationAddress',
      required: true
    },
    {
      name: 'destinationZIP',
      required: true
    },
    {
      name: 'destinationSettlement',
      required: true
    },
    {
      name: 'destinationMunicipality',
      required: true
    },
    {
      name: 'destinationState',
      required: true
    },
    {
      name: 'destinationLatitude',
      required: true
    },
    {
      name: 'destinationLongitude',
      required: true
    },
    {
      name: 'destinationContact',
      required: false
    },
    {
      name: 'destinationPhone',
      required: false
    }
  ]
};
