export const CHART_GRAPH_CONSTANTS = {
  GRAPH_CENTER_LABEL: {
    ALIGN: 'center',
    BASELINE: 'middle',
    FONT_STYLE: 'lighter 1.5em sans-serif',
    ID: 'datasetLabel'
  },
  GRAPH_COLORS: ['#3354e5', '#0a174d'],
  GRAPH_CUTOUT: '60%',
  GRAPH_LABELS_PADDING: 20,
  GRAPH_LABELS_POSITION: 'right',
  GRAPH_LABELS_SIZE: 12,
  GRAPH_LABELS_WEIGHT: 'bold',
  GRAPH_ORDER_COLORS: [
    '#5065FF',
    '#FFCA81',
    '#B2A2FF',
    '#FC7998',
    '#79DFFF',
    '#79D9C0',
    '#659FE0'
  ],
  GRAPH_SUBTITLE_COLOR: '#405C6B',
  GRAPH_SUBTITLE_FONT: 'bold 20px Arial',
  GRAPH_SUBTITLE_ID: 'subtitle',
  GRAPH_SUBTITLE_POSITION_ADJUST: 1.45,
  GRAPH_TEXT_ALLIGN_LEFT: 'left',
  GRAPH_TITLE_COLOR: '#000',
  GRAPH_TITLE_FONT: 'bold 20px Arial',
  GRAPH_TITLE_ID: 'title',
  GRAPH_TITLE_SIZE: 20,
  GRAPH_TITLE_START_POSITION: 20,
  GRAPH_TITLE_SUBTITLE_TOP_POSITION: 30,
  GRAPH_TYPE: 'doughnut',
};
