<p-dialog [(visible)]="isVisible" [position]="dialogPosition" [modal]="true"
  [style]="{width: '25vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
  [modal]="isBackgroundBlocked" (onHide)="onClickClose()" >

  <ng-template pTemplate="header">
    <p-dialog-header class="nps-rating-dialog-header-p" *ngIf="isRatingSectionActive">
      {{ 'netPromoterScoreLabels.howYouRateYourExperience' | translate }}
    </p-dialog-header>
    <p-dialog-header class="nps-rating-dialog-header-p" *ngIf="!isRatingSectionActive">
      {{ 'netPromoterScoreLabels.thankForComments' | translate }}
    </p-dialog-header>
  </ng-template>

  <div name="nps-rating-section" *ngIf="isRatingSectionActive">

    <div name="nps-rating-div" class="nps-rating-div">
      <p-rating [(ngModel)]="scoreValue" [stars]="scoreValueRangeMax" [cancel]="false"
        (onRate)="onClickRate($event)">
      </p-rating>
    </div>

    <form [formGroup]="netPromoterScoreForm">
      <input [hidden]="true" readonly id="score" formControlName="score"
        matInput maxlength="1" type="number" min="1" max="scoreValueRangeMax" required>
      <br />

      <mat-form-field *ngIf="scoreValue && scoreValue < 5">
        <mat-label>
          {{ 'netPromoterScoreLabels.whatCanImprove' | translate }}
        </mat-label>
        <textarea class="nps-rating-form-text-area" matInput name="client-observations"
          cols="35" rows="5" formControlName="clientObservations" maxlength="300"
          minlength="5" required>
        </textarea>
      </mat-form-field>

      <mat-form-field *ngIf="scoreValue && scoreValue > 4">
        <mat-label>
          {{ 'netPromoterScoreLabels.whatProductFeaturesHighlight' | translate }}
        </mat-label>
        <textarea class="nps-rating-form-text-area" matInput name="client-observations"
          cols="35" rows="5" formControlName="clientObservations" maxlength="300"
          minlength="5" required>
        </textarea>
      </mat-form-field>
    </form>

    <mat-dialog-actions class="nps-rating-button-div" *ngIf="scoreValue">
      <button
          id="nps-cancel-button"
          mat-raised-button
          class="secondary-button"
          (click)="onClickClose()">
          {{ 'netPromoterScoreLabels.cancelButton' | translate }}
        </button>
        <button
          id="nps-save-button"
          mat-raised-button
          [disabled]="!netPromoterScoreForm.valid || isSaveButtonDisabled"
          class="primary-button nps-rating-button-save"
          color="primary"
          (click)="onClickSave()">
          {{ 'netPromoterScoreLabels.sendButton' | translate }}
        </button>
    </mat-dialog-actions>
  </div>

  <div name="nps-rating-thanks-section" *ngIf="!isRatingSectionActive">
    <p *ngIf="!isScoredBefore" class="nps-rating-thanks-p">
      {{ 'netPromoterScoreLabels.yourOpinionIsVeryImportant' | translate }}
    </p>
    <p *ngIf="isScoredBefore" class="nps-rating-thanks-p">
      {{ 'netPromoterScoreLabels.alreadyReceivedYourOpinion' | translate }}
    </p>
    <img id="nps-rating-image" class="nps-rating-image"
      src="../../../assets/icons/nps/nps-completed-icon.svg" alt="thank-u-image">
    <mat-dialog-actions class="nps-rating-button-div">
      <button id="nps-close-button" mat-raised-button color="primary"
        class="primary-button" (click)="onClickClose()">
        {{ 'netPromoterScoreLabels.okButton' | translate }}
      </button>
    </mat-dialog-actions>
  </div>

</p-dialog>
