import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { WarehouseDefault } from 'src/app/interfaces';
import { ConfigurationLabels } from '../../pages/configuration/configuration.labels';
import { DialogSelectWarehouseComponent } from '../dialog/dialog-select-warehouse/dialog-select-warehouse.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../providers/user/user.service';

const CONFIRM = 'Confirm';
const DIALOG_HEIGHT = '300px';
const DIALOG_WIDTH = '640px';
const TOOLTIP = 'Te encuentras en:';

@Component({
  selector: 'app-user-warehouse',
  templateUrl: './user-warehouse.component.html',
  styleUrls: ['./user-warehouse.component.scss']
})
export class UserWarehouseComponent implements OnInit {
  @Input() isAvailableWarehouseChange?: boolean;
  @Output() updatedWarehouse?: EventEmitter<void>;
  public validWarehouse: boolean;
  public warehouse: WarehouseDefault;
  public tooltip: string;

  constructor(
    private appService: AppService,
    private dialog: MatDialog,
    private userProvider: UserService
  ) {
    this.updatedWarehouse = new EventEmitter<void>();
  }

  ngOnInit() {
    this.tooltip = TOOLTIP;
    this.getUserWarehouse();
  }

  public async getUserWarehouse(): Promise<void> {
    this.warehouse = await this.appService.getUserWarehouse();
    if (!this.warehouse) {
      this.validWarehouse = false;
    } else {
      this.validWarehouse = true;
    }
  }

  public async changeWarehouse(): Promise<void> {
    const userOid = this.appService.getUserOid();
    const user = await this.userProvider.getUserById(userOid);
    const dialogRef = this.dialog.open(DialogSelectWarehouseComponent, {
      data: {
        title: ConfigurationLabels.dialogWarehouseTitle,
        resume: ConfigurationLabels.dialogWarehouseResume,
        imgRef: '../../../assets/warehouse_switch.svg',
        button1: ConfigurationLabels.dialogWarehouseCancel,
        button2: ConfigurationLabels.dialogWarehouseAccept,
        warehouses: user.warehouses,
        defaultWarehouse: user.warehouseDefault,
        userId: userOid
      },
      width: DIALOG_WIDTH,
      height: DIALOG_HEIGHT
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === CONFIRM) {
        this.getUserWarehouse();
        this.updatedWarehouse.emit();
      }
    });
  }


}
