import { ShipmentRequestStatusResponse } from '../../../interfaces/marketplace-shipper/shipment-request';
export interface CarrierShipmentLabel {
  accept: string;
  acceptedStatus: string;
  acceptRequest: string;
  assignedStatus: string;
  assignTransport: string;
  assignedTransportation: string;
  beginResume: string;
  cancel: string;
  completedShipmentsTabTitle: string;
  counterOffer: string;
  counterOfferStatus: string;
  createdStatus: string;
  cubicMeters: string;
  currency: string;
  descriptionCard: string;
  emptyString: string;
  finaleResume: string;
  getDataError: string;
  inTransitShipmentsTabTitle: string;
  intermediateResume: string;
  invalidAmountError: string;
  kg: string;
  modalQuestion: string;
  modalTitle: string;
  myAmount: string;
  myAmountWarning: string;
  noApply: string;
  noInfo: string;
  noRateMessage: string;
  noReference: string;
  no_request: string;
  offers: string;
  pageTitle: string;
  pendingShipmentsTabTitle: string;
  request: string;
  searchPlaceholder: string;
  setRateQuestion: string;
  setRateToShipment: string;
  shipmentsLimitReached: string;
  unavailableData: string;
  unavailableTransport: string;
  updateRequestError: string;
  viewDetail: string;
  wasAccepted: string;
}

export const CarrierShipmentLabels: CarrierShipmentLabel = {
  accept: 'Aceptar',
  acceptedStatus: 'Aceptada',
  acceptRequest: 'Aceptar viaje',
  assignTransport: 'Asignar transporte',
  assignedStatus: 'Asignar transporte',
  assignedTransportation: 'Con transporte asignado',
  beginResume: 'Será aceptado el presupuesto del embarcador por $',
  cancel: 'Cancelar',
  completedShipmentsTabTitle: 'Finalizadas',
  counterOffer: 'Realizar contraoferta',
  counterOfferStatus: 'Contraoferta',
  createdStatus: 'Creada',
  cubicMeters: 'm³',
  currency: '$',
  descriptionCard: 'Solicitudes',
  emptyString: '',
  finaleResume: 'Esta acción no podrá deshacerse.',
  getDataError: 'Ocurrió un error al obtener los datos del transportista',
  inTransitShipmentsTabTitle: 'En curso',
  intermediateResume: ' y se le enviará una notificación.',
  invalidAmountError: 'Ingresa un monto mayor a $0',
  kg: 'kg',
  modalQuestion: '¿Deseas continuar?',
  modalTitle: 'Aceptar viaje de la solicitud ',
  myAmount: 'Mi monto es (MXN): ',
  myAmountWarning: 'Nota: Este monto será enviado al embarcador',
  noApply: 'N/A',
  noInfo: 'Sin información',
  noRateMessage: 'No se tiene un presupuesto asignado para esta solicitud, ',
  noReference: 'Sin referencia',
  no_request: 'No se pudo obtener las solicitudes',
  offers: 'Ofertas',
  pageTitle: 'Solicitudes Transportista',
  pendingShipmentsTabTitle: 'Pendientes',
  request: 'La solicitud ',
  searchPlaceholder: 'Buscar',
  setRateToShipment: 'Configurar monto para el viaje de la solicitud ',
  setRateQuestion: '¿Cuál es el monto que deseas cobrar por este servicio?.',
  shipmentsLimitReached: 'Has alcanzado el límite máximo de viajes aceptados. La opción para ofertar no estará disponible',
  unavailableData: 'No hay información disponible',
  unavailableTransport: 'Asignar transporte (No disponible)',
  updateRequestError: 'Ocurrió un error al aceptar la solicitud',
  viewDetail: 'Ver detalles',
  wasAccepted: ' ha sido aceptada.',
};

export const SHIPMENT_REQUEST_STATUS: Array<ShipmentRequestStatusResponse> = [
  {
    cssClass: 'noOffersPoint',
    description: 'Pendientes',
    status: 'Creada',
    color: '#7E8894',
    shipments: []
  },
  {
    cssClass: 'acceptedPoint',
    description: 'Aceptadas',
    status: 'Aceptada',
    color: '#3BB2A2',
    shipments: []
  },
  {
    cssClass: 'counterofferPoint',
    description: 'Contraoferta',
    status: 'Contraoferta',
    color: '#FAC100',
    shipments: []
  },
  {
    cssClass: 'assignedPoint',
    description: 'Asignadas',
    status: 'Asignada',
    color: '#3E608F',
    shipments: []
  },
  {
    cssClass: 'inTransitPoint',
    description: 'En tránsito',
    status: 'En transito',
    color: '#EE501E',
    shipments: []
  },
  {
    cssClass: 'finishedPoint',
    description: 'Finalizadas',
    status: 'Finalizada',
    color: '#51A251',
    shipments: []
  }
];

export const INVALID_STATUS_FOR_COUNTER_OFFER = [
  'aceptada',
  'asignada',
  'en transito',
  'finalizadas'
];

export const VALID_STATUS_UNASSIGNED_TRANSPORT = [
  'creada',
  'contraoferta',
  'en transito',
  'finalizadas'
];
