<div id="print-section" class="style-print-section">
  <div class="general-container">
    <p>
      {{ 'shipmentRequestFormatTags.creationDate' | translate }}
      <strong>{{ printDatetime | nodata }}</strong>
    </p>
    <div class="div-header-container">
      <div class="div-logo-container"> 
        <img
          alt="Logo"
          src='{{ logoUrl }}'
          id="logo"
          (error)="setDefaultImage($event);">
      </div>
      <div name="title-container" id="title-container" class="div-title-container">
        <div class="div-right-title">
          <p>{{ 'shipmentRequestFormatTags.title' | translate }} {{ shipmentRequestData.identifier | nodata }}</p>
        </div>
        <div class="div-left-title">
          <span>{{ shipmentRequestData.reference | nodata }}</span>
        </div>
      </div>
    </div>
    <div class="div-summary-info">
      <div id="shipper-data">
        <div class="partial-text-divider">
          <div class="subtitle">
            <span>{{ 'shipmentRequestFormatTags.carrierSectionTitle' | translate }}</span>
          </div>
          <div class="divider">
            <hr>
          </div>
        </div>
        <table aria-describedby="table-shipper-data" class="table-shipper-data">
          <tr>
            <th id="shipperName-header"> {{ 'shipmentRequestFormatTags.carrierName' | translate }} </th>
            <th id="fiscalAddress-header"> {{ 'shipmentRequestFormatTags.loadTypeTitle' | translate }}</th>
          </tr>
          <tr>
            <td> {{ shipmentRequestData.acceptedCarrier?.carrier?.name | nodata }} </td>
            <td> {{ shipmentRequestData.loadType?.name | nodata }} </td>
          </tr>
          <tr>
            <th id="driverNameTittle-header"> {{ 'shipmentRequestFormatTags.driverNameTitle' | translate }}</th>
            <th id="platesTittle-header"> {{ 'shipmentRequestFormatTags.platesTitle' | translate }}</th>
          </tr>
          <tr>
            <td> {{ shipmentRequestData.transport?.driver | nodata }} </td>
            <td> {{ shipmentRequestData.transport?.plates | nodata }} </td>
          </tr>
          <tr>
            <th id="vehicleTypeTitle"> {{ 'shipmentRequestFormatTags.vehicleTypeTitle' | translate }}</th>
          </tr>
          <tr>
            <td> {{ shipmentRequestData.vehicleType?.name  | nodata}} </td>
          </tr>
          <tr>
            <th id="loadDateTitle"> {{ 'shipmentRequestFormatTags.loadDateTitle' | translate }}</th>
          </tr>
          <tr>
            <td> {{ shipmentRequestData.origin?.loadDate  | nodata | date: 'dd/MM/yyyy, HH:mm'}} </td>
          </tr>
        </table>
      </div>
      <div id="merchandise-data">
        <div class="partial-text-divider">
          <div class="subtitle">
            <span>{{ 'shipmentRequestFormatTags.shipperRequirementsTitle' | translate }}</span>
          </div>
          <div class="divider">
            <hr>
          </div>
        </div>
        <table aria-describedby="table-resume-indicator" class="table-shipper-requirements">
          <th id="th-resume-indicators" *ngFor="let requirement of shipmentRequestData.shipperRequirements">
            &bull; {{ requirement.name }}
          </th>
          <td id="th-resume-indicators" *ngIf="!shipmentRequestData.shipperRequirements">
            &bull; {{ 'shipmentRequestFormatTags.noInfo' | translate }}
          </td>
        </table>
        <div class="partial-text-divider">
          <div class="subtitle">
            <span>{{ 'shipmentRequestFormatTags.additionalRequirementsTitle' | translate }}</span>
          </div>
          <div class="divider">
            <hr>
          </div>
        </div>
        <table aria-describedby="table-resume-indicator" class="table-shipper-requirements">
          <th id="th-resume-indicators">
            &bull; {{ 'shipmentRequestFormatTags.noInfo' | translate }}
          </th>
        </table>
      </div>
    </div>
    <br/>
    <div class="partial-text-divider">
      <div class="subtitle">
        <span>{{ 'shipmentRequestFormatTags.origin' | translate }}</span>
      </div>
      <div class="divider">
        <hr>
      </div>
    </div>
    <table aria-describedby="table-shipper-data" class="table-shipper-data">
      <tr>
        <th id="shipperName-header"> {{ 'shipmentRequestFormatTags.originName' | translate }} </th>
        <th id="fiscalAddress-header"> {{ 'shipmentRequestFormatTags.originAddress' | translate }}</th>
      </tr>
      <tr>
        <td> {{ shipmentRequestData.origin?.name | nodata }} </td>
        <td>
          {{ shipmentRequestData.origin?.address | nodata }}
          <br/>
          {{ shipmentRequestData.origin?.city | nodata }}
          <br/>
          {{ shipmentRequestData.origin?.state | nodata }}
          <br/>
          {{ shipmentRequestData.origin?.postalCode | nodata }}
        </td>
      </tr>
    </table>
    <br/>
    <div class="partial-text-divider">
      <div class="subtitle">
        <span>{{ 'shipmentRequestFormatTags.destionationsSection' | translate }}</span>
      </div>
      <div class="divider">
        <hr>
      </div>
    </div>
    <table aria-describedby="table-resume-indicator" class="table-shipper-requirements" *ngIf="false">
      <tr>
        <th id="th-resume-indicators">
          &bull; {{ 'shipmentRequestFormatTags.totalDistance' | translate }} {{ distanceRoute ?  distanceRoute : ('shipmentRequestFormatTags.noInfo' | translate) }}
        </th>
      </tr>
      <tr>
        <td id="th-resume-indicators">
          &bull; {{ 'shipmentRequestFormatTags.totalDuration' | translate }} {{ durationRoute ?  (durationRoute + ' ' + ('shipmentRequestFormatTags.durationFormat' | translate) ) : ('shipmentRequestFormatTags.noInfo' | translate) }}
        </td>
      </tr>
    </table>
    <div id="additional-info">
      <table mat-table aria-describedby="table-additional-info" [dataSource]="dataSource">
        <ng-container matColumnDef="destinationName">
          <th
            id="destinationName"
            mat-header-cell
            *matHeaderCellDef>
            {{ 'shipmentRequestFormatTags.destinationName' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="destinationAddress">
          <th
            id="destinationAddress"
            mat-header-cell
            *matHeaderCellDef>
            {{ 'shipmentRequestFormatTags.destinationAddress' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.address }}
          </td>
        </ng-container>
        <ng-container matColumnDef="destinationCity">
          <th
            id="destinationCity"
            mat-header-cell
            *matHeaderCellDef>
            {{ 'shipmentRequestFormatTags.city' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.city }}
          </td>
        </ng-container>
        <ng-container matColumnDef="destinationState">
          <th
            id="destinationState"
            mat-header-cell
            *matHeaderCellDef>
            {{ 'shipmentRequestFormatTags.state' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.state }}
          </td>
        </ng-container>
        <ng-container matColumnDef="deliveryDate">
          <th
            id="deliveryDate"
            mat-header-cell
            *matHeaderCellDef>
            {{ 'shipmentRequestFormatTags.deliveryDate' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.deliveryDate | date: 'dd/MM/yyyy HH:mm' }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div class="qr-code">
      <qrcode
        [qrdata]="qrData"
        [width]="90"
        [errorCorrectionLevel]="'M'"
        [elementType]="'svg'">
      </qrcode>
    </div>
  </div>
</div>
