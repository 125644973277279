import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { AppConstants } from '../../../constants/app-constants.constants';
import { DialogSelectOptionData, OptionFields } from '../../../interfaces/dialog-select-option';

@Component({
  selector: 'app-dialog-select-option',
  styleUrls: ['./dialog-select-option.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-select-option.component.html'
})
export class DialogSelectOptionComponent implements OnInit {
  public image: string;
  public isImagePathAviable: boolean;
  public providedOptions: Array<OptionFields>;
  public selectForm: UntypedFormGroup;
  constructor(
    private dialogRef: MatDialogRef<DialogSelectOptionComponent>,
    private readonly builder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogSelectOptionData) {
  }

  /**
   * @description Angular lifecycle for component initialization.
   */
  public async ngOnInit(): Promise<void> {
    this.providedOptions = this.data.options;
    this.isImagePathAviable = false;
    this.initForm(this.builder);
    this.setImage();
  }

  /**
   * @description Action for click on 'Action' button.
   */
  public onClickAction(): void {
    const optionSelected = this.selectForm.value;
    this.dialogRef.close(optionSelected);
  }

  /**
   * @description Action for click on 'Cancel' button.
   */
  public onClickCancel(): void {
    this.dialogRef.close(AppConstants.CANCEL);
  }

  /**
   * @description Action for click on 'Close' button.
   */
  public onClickClose(): void {
    this.dialogRef.close(AppConstants.CLOSED);
  }

  /**
   * @description Sets the image path.
   */
  public setImage(): void {
    if (this.data.imagePath) {
      this.isImagePathAviable = true;
      this.image = this.data.imagePath;
    }
  }

  /**
   * @description Initialize the form for 'selectForm'.
   * @param {UntypedFormBuilder} fb - The form builder.
   */
  private initForm(fb: UntypedFormBuilder): void {
    this.selectForm = fb.group({
      selectedOption: new UntypedFormControl('', [
        Validators.required])
    });
  }
}
