import { Pipe, PipeTransform } from '@angular/core';
import { LanguageTranslateService } from '../services/translate/language-translate.service';

@Pipe({
    name: 'nodata'
})
export class NoDataPipe implements PipeTransform {
  constructor(
    private _languageTranslateService: LanguageTranslateService
  ) { }

    transform(value: string): string {
      const language = this._languageTranslateService.getLanguage();
      return value !== '' && value !== null && value !== undefined ? value : language === 'es' ? 'Sin información' : 'No information';
    }

}
