<div>
  <mat-grid-list cols="2" rowHeight="1:.10">
    <mat-grid-tile colspan="2" rowspan="1">
      <span class="dialog-title">
        <p>
          {{ reasonToEdit ? ('reasonCreateTags.titleEdit' | translate) + ' ' + reasonToEdit.identifier : ('reasonCreateTags.title' | translate) }}
        </p>
      </span>
    </mat-grid-tile>
  </mat-grid-list>
  <form [formGroup]="reasonFormGroup">
    <mat-grid-list cols="2" rowHeight="1:.40">
      <mat-grid-tile>
        <mat-form-field>
          <input
              id="drcIdentifier"
              maxlength="16"
              #drcIdentifier
              required
              formControlName="identifier"
              matInput
              placeholder="{{ 'reasonCreateTags.identifier' | translate }}">
          <mat-error *ngIf="reasonFormGroup.get('identifier').hasError('required')">
            <span>
              {{ 'reasonCreateTags.errorLineIdentifier' | translate }}
            </span>
            <strong>
              {{ 'reasonCreateTags.errorRequiredSuffixO' | translate }},
              {{ 'reasonCreateTags.errorIdentifierComplement' | translate }}
            </strong>
          </mat-error>
          <mat-error *ngIf="reasonFormGroup?.get('identifier').hasError('repeatIdentifier')">
            <strong>{{ 'reasonCreateTags.duplicateIdentifier' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field>
          <mat-select
            id="drcType"
            placeholder="{{ 'reasonCreateTags.reasonsType' | translate }}"
            required
            formControlName="type">
            <mat-option *ngFor="let reasonType of reasonsTypes" [value]="reasonType.value">
              {{ reasonType.label }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="reasonFormGroup.get('type').hasError('required')">
            <span>
              {{ 'reasonCreateTags.errorLineReasonType' | translate }}
            </span>
            <strong>
              {{ 'reasonCreateTags.errorRequiredSuffixO' | translate }}
            </strong>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile
        [colspan]="2"
        [rowspan]="1">
        <mat-form-field class="description-field">
          <input
            id="drcDescription"
            #drcDescription
            maxlength="120"
            required
            formControlName="description"
            matInput
            placeholder="{{ 'reasonCreateTags.description' | translate }}">
          <mat-error *ngIf="reasonFormGroup.get('description').hasError('required')">
            <span>
              {{ 'reasonCreateTags.errorLineDescription' | translate }}
            </span>
            <strong>
              {{ 'reasonCreateTags.errorRequiredSuffixO' | translate }},
              {{ 'reasonCreateTags.errorDescriptionComplement' | translate }}
            </strong>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
   
  </form>
  <div class="cards-container-button">
    <div class="buttons">
      <button
        id="drc-btn-cancel"
        mat-stroked-button
        color="primary"
        class="secondary-button m-r-10"
        (click)="onCancel()">
        {{ 'reasonCreateTags.cancel' | translate }}
      </button>
      <button
        *ngIf="reasonToEdit"
        id="drc-update-button"
        mat-raised-button
        color="primary"
        class="primary-button"
        (click)="onEdit()"
        [disabled]="reasonFormGroup?.invalid || !hasChange">
        {{ 'reasonCreateTags.save' | translate }}
      </button>
      <button
        *ngIf="!reasonToEdit"
        id="drc-save-button"
        mat-raised-button
        color="primary"
        class="primary-button"
        (click)="onSave()"
        [disabled]="reasonFormGroup?.invalid">
        {{ 'reasonCreateTags.create' | translate }}
      </button>
    </div>
  </div>
</div>
