<div class="delivery-detail-container">
  <div class="detail-container table--scroll table--scroll-hidden">
    <table aria-describedby="deliveryDetailTable">
      <tr>
        <table
          aria-describedby="deliveryCardTable"
          *ngFor="let delivery of deliveryDetails; let i = index"
          [ngClass]="{'selected-row': i === selectedRow}">
          <div
            class="item"
            [ngClass]="{'selected-row': i === selectedRow}"
            (click)="onRowClick(delivery, i)">
            <tr>
              <td rowspan="3" id="indicator">
                <mat-icon [ngClass]="{'active-circle-indicator': i === selectedRow, 'inactive-circle-indicator': i !== selectedRow}">
                  brightness_1
                </mat-icon>
              </td>
              <td
                class="cell--width"
                colspan="5"
                [ngClass]="{'text-highlighted': i === selectedRow}">
                 <p class="date"> {{ delivery.date | date: 'dd MMM yy' : 'GMT'}}</p> <p class="date-hour"> {{ delivery.date | date : 'h:mm a' }}</p>
              </td>
            </tr>
            <tr>
              <td class="location" [ngClass]="{'text-highlighted': i === selectedRow}">
               <strong>{{ delivery.warehouseName }}</strong>
              </td>
            </tr>
            <tr>
              <td
                class="description-column"
                [ngClass]="{'text-highlighted': i === selectedRow}"
                [matTooltip]="delivery.description" >
                {{ delivery.description }}
              </td>
            </tr>
          </div>
        </table>
      </tr>
    </table>
  </div>
  <div class="delivery-description-container">
    <div class="description-container">
      <div class="table-description">
        <table class="detail-table" aria-hidden="true">
          <tr>
            <td class="align-value number-indicator">{{ 'manualDeliveryDetailTags.visit' | translate }}</td>
            <td class="text-detail">{{ descriptionData?.numVisit }}</td>
          </tr>
          <tr>
            <td class="align-value number-indicator">{{ 'manualDeliveryDetailTags.type' | translate }}</td>
            <td class="text-detail">{{ descriptionData?.type }}</td>
          </tr>
          <tr>
            <td class="align-value number-indicator">{{ 'manualDeliveryDetailTags.reason' | translate }}</td>
            <td class="text-detail">{{ descriptionData?.reason }}</td>
          </tr>
          <tr>
            <td class="align-value number-indicator">{{ 'manualDeliveryDetailTags.description' | translate }}</td>
            <td
              class="text-detail description-text"
              [matTooltip]="descriptionData?.reasonDescription?.length > 60 ? descriptionData?.reasonDescription : ''"
              [ngClass]="{'description--long': descriptionData?.reasonDescription?.length > 60}">
              {{ descriptionData?.reasonDescription }}
            </td>
          </tr>
        </table>
      </div>
      <div class="add-button-container">
        <button
          color="primary"
          class="add-button"
          (click)="clickFile()">
          {{ 'manualDeliveryDetailTags.addFile' | translate }}
        </button>
          <input
            type="file"
            name="files"
            id="inputFilesByDetail"
            multiple
            (change)="addFilesByDetail()"
            hidden
            accept=".pdf,.jpg,.jpeg,.png">
      </div>
    </div>
    <div class="carousel-container">
      <app-mini-carousel [title]="carouselTitle" [images]="descriptionData?.files"></app-mini-carousel>
    </div>
  </div>
</div>
