import { SpecialShipmentRequirements } from '../../interfaces/index';
import { SpecialRequirementsConstants } from '../../interfaces/index';

export const SpecialRequirementsArray: Array<SpecialShipmentRequirements> = [
  {
    identifier: 1,
    name: 'Cubre boca',
    isRequired: true,
    isSuggested: true
  },
  {
    identifier: 2,
    name: 'Guantes',
    isRequired: true,
    isSuggested: true
  },
  {
    identifier: 3,
    name: 'Casco',
    isRequired: false,
    isSuggested: false

  },
  {
    identifier: 4,
    name: 'Sin barba',
    isRequired: false,
    isSuggested: false

  },
  {
    identifier: 5,
    name: 'Lentes',
    isRequired: false,
    isSuggested: false
  },
  {
    identifier: 6,
    name: 'Chaleco',
    isRequired: false,
    isSuggested: false
  },
  {
    identifier: 7,
    name: 'Overol',
    isRequired: false,
    isSuggested: false
  },
  {
    identifier: 8,
    name: 'Ópalo',
    isRequired: false,
    isSuggested: false
  },
  {
    identifier: 9,
    name: 'RC',
    isRequired: false,
    isSuggested: false
  }
];

export const SPECIAL_REQUIREMENTS_CONSTANTS: SpecialRequirementsConstants = {
  faceMask: 'Cubre boca',
  gloves: 'Guantes'
};
