import { OrderForShipment } from '../../interfaces/shipment';
import { PortageRecord, Shipments } from '../../interfaces';
import { ShipmentType } from '../../enums/shipmentType';

const PORTAGE_SHIPMENTS = [
  ShipmentType.Portage.toString(),
  ShipmentType.PortageMultiStop.toString()
];

/**
 * Service for evidences module.
 */
export class EvidencesService {
  /**
   * @description Checks is full portage or not with shipment trip type.
   * @param {Shipments} shipment - Data from shipment to check.
   * @returns {boolean} True if trip type is "Portage", otherwise false.
   */
  public isFullPortageShipment(shipment: Shipments): boolean {
    return shipment.tripType === ShipmentType.Portage.toString();
  }

  /**
   * @description Checks if portage shipment is portage.
   * @param {Shipments} shipment - Data from shipment to check.
   * @returns {boolean} True if shipment trip type is portage or portage with distribution, otherwise false.
   */
  public isPortageShipment(shipment: Shipments): boolean {
    return PORTAGE_SHIPMENTS.includes(shipment.tripType);
  }

  /**
   * @description Checks if order has portage record to verify if current shipment where is associated
   * has been received in portage warehouse.
   * @param {Array<PortageRecord>} portageRecord - Portage record from order to check.
   * @param {string} shipmentFolio - Shipment folio where the order want to evidence.
   * @returns {boolean} True if order has portage record and is registered a reception in current shipment. Otherwise false.
   */
  public isOrderReceivedInPortageWarehouse(portageRecord: Array<PortageRecord>, shipmentFolio: string): boolean {
    return Boolean(portageRecord && portageRecord.find((record: PortageRecord) => {
      return record.shipmentId === shipmentFolio;
    }));
  }

  /**
   * @description Checks if the spefcified order belongs to portage stop.
   * @param {string} orderOid - ObjectId of the order to check.
   * @param {Shipments} shipment - Data from shipment where the order is associated.
   * @returns {boolean} Status of property "stopIsPortage" in order data of shipment.
   */
  public isPortageOrder(orderOid: string, shipment: Shipments): boolean {
    return (shipment.orders.find((order: OrderForShipment) => {
      return order._id === orderOid;
    }))?.stopIsPortage;
  }
}
