<div class="total-divider">
  <div class="subtitle-container">
    <h4 class="sub-header">{{texts}} {{number}} </h4>
  </div>
  <div class="divider">
    <hr>
  </div>
</div>
<app-no-info
  *ngIf="notInfo"
  [title]="title"
  [message]="''">
</app-no-info>
