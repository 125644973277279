import { ImportationCategory } from '../../../../interfaces';

export const MERCHANDISE_INFO_FORM: ImportationCategory = {
  name: 'merchandiseInfo',
  rules: [
    {
      name: 'boxes',
      required: true
    },
    {
      name: 'pieces',
      required: true
    },
    {
      name: 'pallets',
      required: true
    },
    {
      name: 'weight',
      required: true
    },
    {
      name: 'volume',
      required: true
    },
    {
      name: 'guides',
      required: false
    }
  ]
};
