<div class="container" *ngFor="let resume of generalResume; let i = index" style="width: 100%; height: 100%">

  <div id="header">
    <div
      class="watermark"
      *ngIf="pdfPrinted"
      style="width: 100%;
        height: 335px;
        font-size: 10em;
        position: absolute;
        opacity: 0.2;
        text-align: center;">
      REIMPRESIÓN
    </div>
    <div class="title" style="padding:2px; margin: 0px; font-size: 25px;">
      <h3>Sábana de Costeo
        <img
          src="../../../assets/biisicon2.png"
          alt="BIIS"
          class="imgLogo"
          style=" height: 55px; float: right !important;"/>
      </h3>
    </div>
    <div class="subtitle" style="font-size: 20px; padding-top: 10px; padding-bottom: 10px;">
      <strong>Información General de la Sábana</strong>
    </div>
  </div>

    <table class="table table-bordered fontSizeGralInfo" align="center" style="border-collapse:separate; border: 1px solid #dddddd; font-size:15px !important;" aria-describedby="resumeFreightRateBillingTable">
      <tr>
        <th style="border: 1px solid #ddd !important;">Transporte: </th>
        <th style="border: 1px solid #ddd !important;">Empresa: </th>
        <th style="border: 1px solid #ddd !important;">Folio: </th>
        <th style="border: 1px solid #ddd !important;">Feha Inicio (d/m/a): </th>
        <th style="border: 1px solid #ddd !important;">Fecha Fin (d/m/a): </th>
        <th style="border: 1px solid #ddd !important;">Fecha Generada: </th>
        <th style="border: 1px solid #ddd !important;">Observaciones: </th>
      </tr>
      <tr>
        <td style="border: 1px solid #ddd !important;">{{resume.carrierId}}</td>
        <td style="border: 1px solid #ddd !important;">{{resume.company}}</td>
        <td style="border: 1px solid #ddd !important;">{{resume.billingId}}</td>
        <td style="border: 1px solid #ddd !important;">{{resume.beginDate  | date : 'dd/MM/yyyy'}}</td>
        <td style="border: 1px solid #ddd !important;">{{resume.endDate  | date : 'dd/MM/yyyy'}}</td>
        <td style="border: 1px solid #ddd !important;">{{resume.creationDate  | date : 'dd/MM/yyyy HH:mm:ss'}}</td>
        <td style="border: 1px solid #ddd !important;">{{comments[i]}}</td>
      </tr>
    </table>

  <div id="bodyControl">
    <div 
      class="watermark"
      *ngIf="pdfPrinted"
      style="width: 100%;
        height: 335px;
        font-size: 10em;
        position: absolute;
        opacity: 0.2;
        text-align: center;">
      REIMPRESIÓN
    </div>
    <div class="subtitle" style="font-size: 20px; padding-top: 10px; padding-bottom: 10px;">
      <strong>Detalles de la Sábana</strong>
    </div>
    <div>
        <table class="table table-bordered fontSizeDetails" align="center" style="border-collapse:separate; border: 1px solid #dddddd;  font-size: 9px !important;" aria-describedby="detailFreightRateBillingTable">
          <tr>
                <th style="border: 1px solid #ddd !important;">Cuenta</th>
                <th style="border: 1px solid #ddd !important;">Embarque</th>
                <th style="border: 1px solid #ddd !important;">Fecha en ruta</th>
                <th style="border: 1px solid #ddd !important;">Flete</th>
                <th style="border: 1px solid #ddd !important;">Devolución</th>
                <th style="border: 1px solid #ddd !important;">Estadías</th>
                <th style="border: 1px solid #ddd !important;">Maniobra</th>
                <th style="border: 1px solid #ddd !important;">Maniobra Externa</th>
                <th style="border: 1px solid #ddd !important;">Peaje</th>
                <th style="border: 1px solid #ddd !important;">Pensión</th>
                <th style="border: 1px solid #ddd !important;">Rechazo</th>
                <th style="border: 1px solid #ddd !important;">Reparto</th>
                <th style="border: 1px solid #ddd !important;">TR2</th>
                <th style="border: 1px solid #ddd !important;">Paquetería</th>
                <th style="border: 1px solid #ddd !important;">Seguro de Mercancía</th>
                <th style="border: 1px solid #ddd !important;">Sobrepeso</th>
                <th style="border: 1px solid #ddd !important;">Combustible</th>
                <th style="border: 1px solid #ddd !important;">Especiales</th>
                <th style="border: 1px solid #ddd !important;">Seguro</th>
                <th style="border: 1px solid #ddd !important;">Subtotal</th>
                <th style="border: 1px solid #ddd !important;">IVA</th>
                <th style="border: 1px solid #ddd !important;">Retención</th>
                <th style="border: 1px solid #ddd !important;">Total</th>
              </tr>
              <tr *ngFor="let data of shipmentBillingDetails[i]">
                  <td style="border: 1px solid #ddd !important;">{{data.accountId}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.shipmentId}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.dateOnRoute}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.freight | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.return | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.stays | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.maneuver | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.externalManeuver | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.toll | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.pension | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.toll | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.distribution | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.tr2 | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.parcel | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.merchInsurence | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.overweight | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.gas | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.specials | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.insurence | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.subtotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.iva | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.withholding | currency}}</td>
                  <td style="border: 1px solid #ddd !important;">{{data.total | currency}}</td>
                </tr>
                <tr>
                  <td style="border: 1px solid #ddd !important; font-weight: bold" COLSPAN="3">Totales</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].freightTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].returnTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].staysTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].maneuverTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].externalManeuverTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].tollTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].pensionTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].rejectionTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].distributionTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].tr2Total | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].parcelTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].merchInsurenceTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].overweightTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].gasTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].specialsTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].insurenceTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].subtotalTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].ivaTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].withholdingTotal | currency}}</td>
                  <td style="border: 1px solid #ddd !important; font-weight: bold">{{sumShipmentBilling[i].totalSum | currency}}</td>
                </tr>
        </table>
    </div>


  </div>

  <div id="footerControl">
      <p style="text-align: justify; font-size: 9px">
        HOY {{resume.creationDate  | date : 'dd'}} DE {{resume.creationDate  | date : 'MMMM'}} DE {{resume.creationDate  | date : 'yyyy'}} A LAS {{resume.creationDate  | date : 'HH:mm:ss'}} HORAS, RECIBÍ DE: BIIS LOGISTICS, S.A. DE C.V. SÁBANA DE FACTURACIÓN QUE INDICAN EL COSTO DE CADA FLETE POR EMBARQUE, ASÍ COMO LOS CARGOS GENERADOR EN CADA UNO, LOS CUALES SE DETALLAN Y
  SUMARIZAN EN LA PRESENTE "SÁBANA DE FACTURACIÓN" A EFECTO DE QUE EN CUMPLIMIENTO SE ENTREGUE A LOS DESTINATARIOS CORRESPONDIENTES.
      </p>
    </div>

</div>