import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BillingSheetInvoiceRequest, Invoice, InvoiceResponse } from '../../interfaces/invoice';
import { environment } from '../../../environments/environment';

const TMS_FREIGHTCOST_API = environment.tmsFreightCostApiUrl;

@Injectable()

/**
 * @description Service to make request for invoice.
 */
export class InvoiceProvider {
  /**
   * @description Initializes the variables of the service when it is instantiated.
   * @param {HttpClient} http - Service with methods to generate http request.
   */
  constructor(private http: HttpClient) {}

  /**
   * @description Creates a new invoice by sending a request to the server.
   * @param {BillingSheetInvoiceRequest} data - The data object containing the details for the invoice creation.
   * @param {boolean} isSaleSheet - A flag indicating if the invoice belongs to a sale sheet.
   * @returns {Promise<InvoiceResponse>} The invoice creation request.
   */
  public async createInvoice(data: BillingSheetInvoiceRequest, isSaleSheet: boolean): Promise<InvoiceResponse> {
    const url = TMS_FREIGHTCOST_API + `/invoices/?isSaleSheet=${isSaleSheet}`;

    return this.http.post<InvoiceResponse>(url, data).toPromise();
  }

  /**
   * @description Updates an existing invoice by sending a PUT request to the server.
   * @param {Invoice} invoice - The invoice object containing updated information.
   * @returns {Promise<InvoiceResponse>} - The updated invoice response.
   */
  public async updateInvoice(invoice: Invoice): Promise<InvoiceResponse> {
    const url = TMS_FREIGHTCOST_API + `/invoices/${invoice.id}`;

    return this.http.put<InvoiceResponse>(url, invoice).toPromise();
  }
}
