import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { AppConstants } from '../../../constants/app-constants.constants';
import { AppService } from '../../../app.service';
import { ConfigurationProvider } from '../../../providers/configuration/configuration.provider.service';
import { DATEFORMAT_CONSTANTS } from 'src/app/constants/dateformat.constants';
import { EventFormat, Shipper } from '../../../interfaces';
import { FormattedDateService } from '../../../services/utils/formatted-date.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { MapsService } from '../../../providers/maps/maps.service';
import { ShipmentRequestFormatConstants } from './dialog-shipment-request-format.constants';
import { ShipmentRequestFormatProperties } from './dialog-shipment-request-format.properties';
import { ShipmentRequestResponse } from '../../../interfaces/marketplace-shipper/shipment-request';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shipment-request-format',
  templateUrl: './dialog-shipment-request-format.component.html',
  styleUrls: ['./dialog-shipment-request-format.component.scss', '../../../app.component.scss']
})
export class ShipmentRequestFormatComponent implements OnInit, OnDestroy {
  @Input() public shipmentRequestData: ShipmentRequestResponse;

  public codeProperty: string;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: Array<string>;
  public distanceRoute: string;
  public durationRoute: string;
  public languageSuscription: Subscription;
  public languageLabels: any;
  public logoUrl: string;
  public printDatetime: string;
  public qrData: string;
  public shipperData: Shipper;
  public shipperOId: string;
  public svgPropertie: string;

  constructor(
    private _appService: AppService,
    private configProvider: ConfigurationProvider,
    private formattedDateService: FormattedDateService,
    private mapService: MapsService,
    private toast: ToastrAlertsService,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService
  ) {
   this.setLanguage();
  }

  /**
   * @description Angular destroy lifecycle
   */
  public ngOnDestroy() {
    this.languageSuscription?.unsubscribe();
  }

  /**
   * @description Init angular component life-cycle
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
    this.setPrintDatetime();
    this.shipperOId = this._appService.getShipperOid();
    this.displayedColumns = ShipmentRequestFormatConstants.COLUMN_NAMES;
    this.getShipperLogo();
    this.dataSource = new MatTableDataSource(this.shipmentRequestData.destinations);
    this.setQRData();
    await this.setEstimatedTravel();
  }

  /**
   * @description Gets Shipper logo for the document view
   */
  public async getShipperLogo(): Promise<void> {
    this.logoUrl = await this.configProvider.getShipperLogo();
  }

  /**
   * @description Set default image in case isn't defined
   * @param {EventFormat} event Error given by GET failure
   */
  public setDefaultImage(event: EventFormat): void {
    event.target.src = AppConstants.SYSTEM_DEFAULT_LOGO;
    document.getElementById(ShipmentRequestFormatConstants.TITLE_CONTAINER).style.borderBottom =
      ShipmentRequestFormatProperties.borderLinePropertiesWithColor;
  }

  /**
   * @description Set print date and time to the format
   */
  public setPrintDatetime(): void {
    this.printDatetime = moment().format(DATEFORMAT_CONSTANTS.GENERAL_FORMAT);
  }

  /**
   * @description Set QR data to the format
   */
  public setQRData(): void {
    try {
      const loadDate = moment(this.shipmentRequestData.origin.loadDate).format(DATEFORMAT_CONSTANTS.GENERAL_FORMAT);
      const id = this.shipmentRequestData.identifier;
      const carrier = this.shipmentRequestData.acceptedCarrier.carrier.name;
      const driver = this.shipmentRequestData.transport.driver;

      this.qrData = `${id}, ${loadDate}, ${carrier}, ${driver}`;
    } catch (error) {}
  }

  /**
   * @description Set estimated travel to the format by using Google's distance matrix API
   */
  public async setEstimatedTravel(): Promise<void> {
    const originLat = this.shipmentRequestData.origin.geometry.coordinates[0];
    const originLng = this.shipmentRequestData.origin.geometry.coordinates[1];
    const origin = { lat: originLat, lng: originLng };

    const lastDestination = this.shipmentRequestData.destinations[this.shipmentRequestData.destinations.length - 1];
    const destinationLat = lastDestination.geometry.coordinates[0];
    const destinationLng = lastDestination.geometry.coordinates[1];
    const destination = { lat: destinationLat, lng: destinationLng };
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey Optional language key string, default is spanish 'es'
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
      },
      (error) => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }
}
