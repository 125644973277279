import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CarrierProvider } from '../../../providers/carrier/carrier.provider.service';
import { DialogPrivateCarrierTags } from './dialog-private-carrier.labels';
import { DialogStandardFocusComponent } from '../dialog-standard-focus/dialog-standard-focus.component';
import { Carrier, PrivateCarrier, Shipper } from './../../../interfaces';
import { ShipperService } from '../../../providers/shipper/shipper.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

import { Subscription } from 'rxjs';

const CANCEL = 'Cancel';
const CONFIRM = 'Confirm';
const editDialogWidth = '650px';

@Component({
  selector: 'app-dialog-private-carrier',
  templateUrl: './dialog-private-carrier.component.html',
  styleUrls: ['./dialog-private-carrier.component.scss']
})
export class DialogPrivateCarrierComponent implements OnInit {

  public carrierList: Array<Carrier>;
  public labels: PrivateCarrier;
  public modalSub: Subscription;
  public selectedCarriers: Array<Carrier>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public shipper: Shipper,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogPrivateCarrierComponent>,
    private carrierProv: CarrierProvider,
    private shipperProvider: ShipperService,
    private toastsAlerts: ToastrAlertsService) {
      this.selectedCarriers = [];
      this.labels = DialogPrivateCarrierTags;
    }

  /**
   * @description do the function and everything inside it when you
   * start the page
   */
  public ngOnInit(): void {
    this.getPrivateCarriers();
  }

  /**
   * @description Get all carrier names
   */
  public async getPrivateCarriers(): Promise<void> {
    const carrier = await this.carrierProv.getAllCarrierNames();
    this.carrierList = Object(carrier);
    this.findSelectedCarriers();
  }

  /**
   * @description Set carriers selected by the shipper
   */
  public findSelectedCarriers(): void {
    this.shipper.lineasTransporte.forEach(carrier => {
      const selected = this.carrierList.find(element => element._id === carrier);
      if (selected) { this.selectedCarriers.push(selected); }
    });
  }

  /**
   * @description select all carrier for the shipper
   */
  public selectAllCarriers(): void {
    this.selectedCarriers = this.carrierList;
  }

  /**
   * @description deselect all carrier
   */
  public clearSelection(): void {
    this.selectedCarriers = [];
  }

  /**
   * @description update carrier selected by the shipper
   */
  public async onUpdate(): Promise<void> {
    const payload = {
      lineasTransporte: []
    };
    this.selectedCarriers.forEach(carrier => {
      payload.lineasTransporte.push(carrier._id);
    });

    await this.shipperProvider.updateShipperById(payload, this.shipper._id)
      .then(() => {
        this.toastsAlerts.successAlert(`${this.labels.successMessage}${this.shipper.nombre}`);
        this.onClose(CONFIRM);
      })
      .catch(() => {
        this.toastsAlerts.errorAlert(this.labels.errorMessage);
      });
  }

  /**
   * @description close the dialog
   */
  public onCancel(): void {
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        title: DialogPrivateCarrierTags.cancelEditDialogTitle,
        resume: DialogPrivateCarrierTags.cancelEditDialogResume,
        iconPath: '../../../assets/cedis_edition_dialog.svg',
        question: DialogPrivateCarrierTags.cancelEditDialogQuestion,
        textButton1: DialogPrivateCarrierTags.back,
        textButton2:  DialogPrivateCarrierTags.cancel
      },
      width: editDialogWidth
    });

    this.modalSub = dialogRef.afterClosed().subscribe(result => {
      if (result === CONFIRM) {
        this.onClose(CANCEL);
      }
    });
  }

  public onClose(status?: string): void {
    this.dialogRef.close(status);
  }
}
