export enum WhiteGuidesCodes {
  boxes = 'BXS',
  pallets = 'PLS',
  Pieces = 'PCS'
}

export enum WhiteGuidesNumericalCodes {
  boxes = 2,
  pallets = 3,
  Pieces = 1
}
