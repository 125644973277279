import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { ImportVehiclesDetailsLabels, IImportVehiclesDetailsLabels } from './dialog-importation-vehicles.labels';
import { ImportationErrorTypes } from '../../../enums/importation-error-type';
import { ImportationResume, ImportationVehicle, VehicleImportationError } from '../../../interfaces';
import { VehicleImportation } from '../../../pages/catalogs/vehicle/import-vehicles/vehicle-importation';
import { VehicleImportationFields } from '../../../enums';

const CONTINUE = 'Continue';
const DISPLAYED_COLS = [
  'errorType',
  'rowLine',
  'description',
  'value'
];
const RETRY = 'Retry';

@Component({
  selector: 'app-dialog-importation-vehicles-details',
  templateUrl: './dialog-importation-vehicles-details.component.html',
  styleUrls: ['./dialog-importation-vehicles-details.component.scss', '../../../app.component.scss']
})
export class DialogImportationVehiclesDetailsComponent implements OnInit {
  public countVehiclesWithoutErrors: number;
  public data: Array<VehicleImportation>;
  public dataSourceErrors: MatTableDataSource<VehicleImportationError>;
  public displayedCols: Array<string>;
  public enabledRetryButton: boolean;
  public expandedDetails: boolean;
  public labels: IImportVehiclesDetailsLabels;
  public resume: Array<ImportationResume>;
  public vehicles: Array<ImportationVehicle>;
  public vehiclesWithErrors: Array<VehicleImportation>;
  public vehiclesWithoutErrors: Array<VehicleImportation>;
  public vehiclesWithWarnings: Array<VehicleImportation>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: Array<VehicleImportation>,
    public dialogRef: MatDialogRef<DialogImportationVehiclesDetailsComponent>
  ) {
    this.data = data;
    this.displayedCols = DISPLAYED_COLS;
    this.labels = ImportVehiclesDetailsLabels;
   }

   /**
    * @description When component is ready, these functions being called
    */
  public ngOnInit(): void {
    this.countVehiclesWithoutErrors = 0;
    this.enabledRetryButton = false;
    this.expandedDetails = false;
    this.resume = [];
    this.vehiclesWithoutErrors = [];
    this.buildResume();
    this.buildDataSource();
    this.fillVehicles();
  }

  /**
   * @description Fill vehicles variable
   */
  public fillVehicles(): void {
    const data = this.data;
    const auxVehicles: Array<ImportationVehicle> = [];

    data.forEach(element => {
      auxVehicles.push({
        axles: element.ejes,
        carrier: element.carrierReceived,
        color: element.color,
        driverUsername: element.driver.username,
        kms: element.kmTraveled,
        maxPallets: element.max_pallet,
        maxVolume: element.max_volumen,
        maxWeight: element.max_peso,
        model: element.modelo,
        plates: element.placas,
        vehicleSpec: element.vehicleSpecsReceived,
        vehicleType: element.vehicleTypeReceived,
        year: element.anio,
        __rowNum__: element.__rowNum__
      });
    });
    this.vehicles = auxVehicles;
  }

  /**
   * @description Get vehicles with Importation Errors
   * @param alerts Array of vehicles to be imported
   * @returns Array of vehicles with Importation Errors
   */
  private getVehiclesWithErrors(alerts: Array<VehicleImportation>): Array<VehicleImportation> {
    const errors: Array<VehicleImportation> = [];
    alerts.forEach(alert => {
      if (alert) {
        const errorsData = alert.importationErrors.filter(error => error.errorType === ImportationErrorTypes.Error);

        if (errorsData.length > 0) {
          errors.push(alert);
        }
      }
    });

    return errors;
  }

  /**
   * @description Get vehicles with Importation Warnings
   * @param alerts Array of vehicles to be imported
   * @returns Array of vehicles with Importation Warnings
   */
  private getVehiclesWithWarnings(alerts: Array<VehicleImportation>): Array<VehicleImportation> {
    const warnings: Array<VehicleImportation> = [];
    alerts.forEach(alert => {
      const warningsData = alert.importationErrors.filter(warning => warning.errorType === ImportationErrorTypes.Warning);
      const errorsData = alert.importationErrors.filter(error => error.errorType === ImportationErrorTypes.Error);
      if (warningsData.length > 0 && errorsData.length === 0) {
        warnings.push(alert);
      }
    });

    return warnings;
  }

  /**
   * @description Build the resume information
   */
  public buildResume(): void {
    const vehicles = this.data;
    const vehiclesWithAlerts = vehicles.filter(vehicle => vehicle.importationErrors.length > 0);
    const vehiclesWithoutAlerts = vehicles.filter(vehicle => vehicle.importationErrors.length === 0);

    this.vehiclesWithErrors = this.getVehiclesWithErrors(vehiclesWithAlerts);
    this.vehiclesWithWarnings = this.getVehiclesWithWarnings(vehiclesWithAlerts);

    if (vehiclesWithoutAlerts.length > 0) {
      this.resume.push({ count: vehiclesWithoutAlerts.length, text: this.labels.successRows });
      this.countVehiclesWithoutErrors = this.countVehiclesWithoutErrors + vehiclesWithoutAlerts.length;
      this.vehiclesWithoutErrors = vehiclesWithoutAlerts;
    }
    if (this.vehiclesWithWarnings.length > 0) {
      this.resume.push({ count: this.vehiclesWithWarnings.length, text: this.labels.warningRows });
      this.vehiclesWithWarnings.forEach(vehicle => {
        const haveError = this.vehiclesWithErrors.find(error => error.__rowNum__ === vehicle.__rowNum__);
        if (!haveError) { this.countVehiclesWithoutErrors = this.countVehiclesWithoutErrors + 1; }
      });
    }
    if (this.vehiclesWithErrors.length > 0) {
      this.resume.push({ count: this.vehiclesWithErrors.length, text: this.labels.errorRows});
    }
  }

  /**
   * @description Build source of alerts
   */
  public buildDataSource(): void {
    let alerts: Array<VehicleImportationError> = [];
    let vehiclesAux: Array<VehicleImportation> = [];

    vehiclesAux = this.vehiclesWithErrors.concat(this.vehiclesWithWarnings);
    vehiclesAux.forEach(vehicle => {
      alerts = alerts.concat(vehicle.importationErrors);
    });

    alerts = alerts.sort((a, b) => (a.__rowNum__ < b.__rowNum__) ? -1 : 1);
    this.dataSourceErrors = new MatTableDataSource(alerts);
  }

  /**
   * @description Event fires when expand details
   */
  public expandDetails(): void {
    this.expandedDetails = !this.expandedDetails;
  }

  /**
   * @description Event handle key down event
   * @param $event Event object
   */
  public keyDownHandler($event) {
    const value = $event.target.value;
    if (value.trim().length === 0) {
      if ($event.keyCode === 32) {
        return false;
      }
    }
  }

  /**
   * @description Event fires when change information of a error alert
   * @param $event Event information
   * @param vehicle Row vehicle modified
   */
  public onChangeInfo($event, vehicle: VehicleImportationError) {
    const newValue = $event.target.value;

    if (newValue.trim().length !== 0) {
      const vehicleIndex = this.vehicles.findIndex(element =>
        element.__rowNum__ === vehicle.__rowNum__
      );

      switch (vehicle.errorLabel) {
        case VehicleImportationFields.Axles:
          this.vehicles[vehicleIndex].axles = newValue;
          break;
        case VehicleImportationFields.Carrier:
          this.vehicles[vehicleIndex].carrier = newValue;
          break;
        case VehicleImportationFields.Color:
          this.vehicles[vehicleIndex].color = newValue;
          break;
        case VehicleImportationFields.Driver:
          this.vehicles[vehicleIndex].driverUsername = newValue;
          break;
        case VehicleImportationFields.Km:
          this.vehicles[vehicleIndex].kms = newValue;
          break;
        case VehicleImportationFields.MaxPallets:
          this.vehicles[vehicleIndex].maxPallets = newValue;
          break;
        case VehicleImportationFields.MaxVolume:
          this.vehicles[vehicleIndex].maxVolume = newValue;
          break;
        case VehicleImportationFields.MaxWeight:
          this.vehicles[vehicleIndex].maxWeight = newValue;
          break;
        case VehicleImportationFields.Model:
          this.vehicles[vehicleIndex].model = newValue;
          break;
        case VehicleImportationFields.Plates:
          this.vehicles[vehicleIndex].plates = newValue;
          break;
        case VehicleImportationFields.VehicleSpecs:
          this.vehicles[vehicleIndex].vehicleSpec = newValue;
          break;
        case VehicleImportationFields.VehicleType:
          this.vehicles[vehicleIndex].vehicleType = newValue;
          break;
        case VehicleImportationFields.Year:
          this.vehicles[vehicleIndex].year = newValue;
          break;
      }
      this.enabledRetryButton = true;
    } else {
      this.enabledRetryButton = false;
    }
  }

  /**
   * @description Event fires when click on Retry Button
   */
  public onRetry() {
    this.dialogRef.close({ action: RETRY, data: this.vehicles});
  }

  /**
   * @description Event fires when click on Continue button
   */
  public onContinue() {
    let vehicles = this.vehiclesWithoutErrors;
    vehicles = vehicles.concat(this.vehiclesWithWarnings);
    this.dialogRef.close({action: CONTINUE, data: vehicles});
  }
}
