import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogFocusData } from '../../../interfaces/dialog-standard-focus';

const CANCEL = 'Cancel';
const CONFIRM = 'Confirm';
const CLOSED = 'Closed';

@Component({
  selector: 'app-dialog-standard-focus',
  templateUrl: './dialog-standard-focus.component.html',
  styleUrls: ['./dialog-standard-focus.component.scss', '../../../app.component.scss']
})
export class DialogStandardFocusComponent {

  constructor(public dialogRef: MatDialogRef<DialogStandardFocusComponent>,
     @Inject(MAT_DIALOG_DATA) public data: DialogFocusData ) { }

  public onClickButton1(): void {
    this.dialogRef.close(CANCEL);
  }

  public onClickButton2(): void {
    this.dialogRef.close(CONFIRM);
  }

  public onClickClose(): void {
    this.dialogRef.close(CLOSED);
  }

}
