/**
 * @description Enums to handle the order to pay flow.
 */
export enum ShipmentLoadNotExecutedStatus {
  Costed = 'Costed',
  Closed = 'Closed',
  Pending = 'Pending',
  Invoice = 'Invoice',
  Sent = 'Sent'
}
