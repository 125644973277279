import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BillingSheetFolioResponse,
  BillingSheetRequest,
  BillingSheetResponse
} from '../../interfaces';
import { environment } from '../../../environments/environment';

const TMS_FREIGHTCOST_API = environment.tmsFreightCostApiUrl;

/**
 * @description Service for sales sheet module, this contains all necessary to make http request.
 */
@Injectable()
export class SalesSheetProvider {
  /**
   * @description Initializes the variables of the class when it is instantiated.
   * @param {HttpClient} http - Provider with differents endpoints to make http requests.
   */
  constructor(private http: HttpClient) {}

  /**
   * @description Saves the new sales sheet data on db.
   * @param {string} tenantId - Required id to save data.
   * @param {BillingSheetRequest} salesSheet - List of generated sales sheets to save on db.
   * @returns {Promise<BillingSheetResponse>} Response of freight cost api that contains inserted data on db.
   */
  public async createSalesSheet(tenantId: string, salesSheet: BillingSheetRequest): Promise<BillingSheetResponse> {
    return this.http.post<BillingSheetResponse>(TMS_FREIGHTCOST_API + '/salesSheets/tenant/' + tenantId, salesSheet).toPromise();
  }

  /**
   * @description Get the new sales sheet folio using prefix, date in format YYYYMMDD and sequence value provided by database.
   * @param {string} date - The current date.
   * @returns {Promise<BillingSheetFolioResponse>} Response with built sales sheet folio.
   */
  public async getSalesSheetFolio(date: string): Promise<BillingSheetFolioResponse> {
    const url = `${TMS_FREIGHTCOST_API}/salesSheets/date/${date}/folio`;

    return this.http.get<BillingSheetFolioResponse>(url).toPromise();
  }
}
