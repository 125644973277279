import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import { NetPromoterScoreArrayResponse, NetPromoterScoreResponse } from "../../interfaces/supplynet-core/net-promoter-score.interface";

const API_URL = `${environment.supplynetCoreApiUrl}/net-promoter-scores`;
const SOLUTION_ID_PARAM = 'solutionId';
const USER_ID_PARAM = 'userId';

@Injectable()
export class NetPromoterScoreProvider {
  /**
   * @description - Creates a Net Promoter Score Provider service instance.
   * @param { HttpClient } _http - HTTP provider service.
   */
  constructor(private _http: HttpClient) {}

  /**
   * @description - Creates a new net promoter score record in database.
   * @param { any } body - Object to create.
   *
   * @returns { Promise<NetPromoterScoreResponse> } - Request response.
   */
  public createNetPromoterScore(body: any): Promise<NetPromoterScoreResponse> {
    return this._http.post<any>(API_URL, body).toPromise();
  }

  /**
   * @description - Gets one net promoter score record in database by userId and solutionID.
   * @param { string } userId - Net promote score id  to create.
   * @param { string } solutionId - Net promote score id  to create.
   * 
   * @returns { Promise<NetPromoterScoreArrayResponse> } - Request response.
   */
  public getNetPromoterScoresByUserAndSolution(userId: string, solutionId: string): Promise<NetPromoterScoreArrayResponse> {
    const options =
      userId && solutionId
        ? {
            params: new HttpParams()
              .set(USER_ID_PARAM, userId)
              .set(SOLUTION_ID_PARAM, solutionId),
          }
        : {};

    return this._http.get<any>(API_URL, options).toPromise();
  }
}
