export const DIALOG_REASON_CREATE_PROPERTIES = {
  DEBOUNCE_TIME: 1000,
  DIALOG_ICON_CANCEL: '../../../assets/edit_icon_dialog.svg',
  DIALOG_ICON_CONFIRM_EDITION: '../../../assets/cedis_edition_dialog.svg',
  DIALOG_WIDTH: '650px',
  ERROR_CODE_DUPLICATE: 409,
  MAX_LENGHT_DESCRIPTION: 120,
  MAX_LENGHT_IDENTIFIER: 16,
  MIN_LENGHT_DESCRIPTION: 3,
  MIN_LENGHT_IDENTIFIER: 1
};
