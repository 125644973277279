import { Component, OnInit, Input, OnChanges } from '@angular/core';

import { Subtitle } from '../../interfaces/component';

import * as _ from 'lodash';

@Component({
  selector: 'app-subtitle-divider',
  templateUrl: './subtitle-divider.component.html',
  styleUrls: ['./subtitle-divider.component.scss', '../../app.component.scss']
})
export class SubtitleDividerComponent implements OnInit, OnChanges {
  @Input() info: Subtitle;

  public title: string;
  public notInfo: boolean;
  public number: string;
  public texts: string;

  constructor() { }

  ngOnInit() {
    this.texts = this.info.subtitle;
    this.number = this.info.number ? '(' + this.info.number + ')' : '';
    this.title = this.info.title;
    this.notInfo = _.isEqual(this.number, '(0)') ? true : false;
  }

  ngOnChanges() {
    this.ngOnInit();
  }

}
