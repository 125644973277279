<mat-grid-list cols="3" rowHeight="1:.3">
  <mat-grid-tile colspan="2">
    <span class="dialog-title">
      <p>{{ 'customerInvoiceDetailLabels.title' | translate }} <span class="focus-text">{{ customerInvoiceData.folio }}</span></p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1">
    <mat-icon
      id="closeButton"
      style="cursor: pointer"
      class="dialog-close-icon"
      (click)="onClickClose()">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>
<span
  *ngIf="customerInvoiceDetailLabels"
  class="background-info"
  [matTooltip]="customerInvoiceDetailLabels.account">
  {{ customerInvoiceData.account }}
</span>

<div>
  <app-no-info *ngIf="!dataSourceAvailable"></app-no-info>

  <div [hidden]="!dataSourceAvailable" class="scf-grid-container" cdkDropListGroup>
    <scf-grid
      *ngIf="isScfGridReady"
      [columnToggler]="scfGridConfig.columnToggler"
      [selectable]="scfGridConfig.selectable"
      [pageSize]="scfGridConfig.pageSize"
      [pageSizeOptions]="scfGridConfig.pageSizeOptions"
      [data]="dataSource"
      [columns]="displayedColumns"
      [rowDraggable]="scfGridConfig.rowDraggable"
      (actioned)="gridActionHandler($event.data, $event.action)">
    </scf-grid>
  </div>
</div>
