<mat-grid-list cols="3" rowHeight="1:.15">
  <mat-grid-tile colspan="2" rowspan="1">
    <span class="dialog-title">
      <p>{{ 'dialogRevertStampTags.title' | translate }}</p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1" rowspan="1">
    <mat-icon
      style="cursor: pointer"
      class="dialog-close-icon"
      (click)="onClickClose()">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>
<div class="dialog-resume">
  {{ 'dialogRevertStampTags.resume' | translate }}
</div>
<mat-grid-list cols="3" rowHeight="1:.40">
  <mat-grid-tile colspan="1" rowspan="2">
    <img src="{{ data.imgRef }}" width="95%" height="95%" alt="Dialog image">
  </mat-grid-tile>
  <form [formGroup]="revertReasonFormGroup">
    <mat-grid-tile colspan="2" rowspan="1">
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'dialogRevertStampTags.revertReasonPlaceholder' | translate }}"
          type="text"
          formControlName="revertReasonInput"
          maxlength="25"
          required>
          <mat-error>{{ 'dialogRevertStampTags.folioIs' | translate }}
            <strong>
              {{ 'dialogRevertStampTags.required' | translate }}
            </strong>
          </mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </form>
  <mat-grid-tile class="button-container">
    <button
      mat-button class="secondary-button align-left"
      (click)="onClickCancel()">
      {{ 'dialogRevertStampTags.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      class="primary-button align-right"
      (click)="onClickConfirm()"
      [disabled]="!revertReasonFormGroup.valid">
      {{ 'dialogRevertStampTags.revert' | translate }}
    </button>
  </mat-grid-tile>
</mat-grid-list>

