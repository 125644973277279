import { Injectable } from '@angular/core';

import { BlobFileData, POD } from '../interfaces/evidence';
import { BlobProvider } from '../providers/evidence/blob-provider.service';
import { FileData, FilesToShow } from '../interfaces/index';

const KEY_ADDRESS = 'address';
const KEY_ID = 'id';
const KEY_ORDERID = 'orderId';
const STATUS = 'status';

@Injectable()
export class FileConversorService {

  constructor(private blobProvider: BlobProvider) { }

  /**
   *@description Converts the url image as image
   *@param storageArray Url storage image
   *@param isMobilityEvidence Indicates if is a mobility evidence
   *@param pods POD existing
   *@param fileData Extra properties from files
   *@returns {Promise<FilesToShow>} The array with files data to show
   */
  public async fileConversor(storageArray: Array<string>, isMobilityEvidence: boolean,
    pods?: Array<POD>, fileData?: Array<FileData>, isDownloadEvidences?: boolean): Promise<Array<FilesToShow>> {
    // TODO @aaron.castillo replace "any" for property interface used in DialogValidateComponent line 46
    const filesToShow: Array<FilesToShow> = [];

    for (let index = 0; index < storageArray.length; index++) {
      const orderIdPosition = 5;
      const element = storageArray[index];
      const elementSplitted = element.split('/');
      const orderId = elementSplitted[orderIdPosition];
      const extensionSplit = element.split('.');
      const fileExtension = extensionSplit[extensionSplit.length - 1];
      const nameSplit = element.split('/');
      const fileName = nameSplit[nameSplit.length - 1];
      const extensionFile = await this.blobProvider.getMIMEtype(fileExtension);
      if (isDownloadEvidences) {
        await fetch(element)
        .then(res => res.blob())
        .then(blob => {
          const fileAux = this.generateBlobFile(blob, fileName, extensionFile,
            isMobilityEvidence, element, orderId, index, pods, fileData);
          filesToShow.push(fileAux);
        });
      } else {
        fetch(element)
        .then(res => res.blob())
        .then(blob => {
          const fileAux = this.generateBlobFile(blob, fileName, extensionFile,
            isMobilityEvidence, element, orderId, index, pods, fileData);
          filesToShow.push(fileAux);
        });
      }
    }
    return filesToShow;
  }

  /**
   * @description Generates Blob file and add extra params about order info
   * @param {BlobPart} blob Blob file image
   * @param {string} fileName Current file name
   * @param {string} extensionFile Extension file
   * @param {boolean} isMobilityEvidence Verifies if a mobility evidence
   * @param {string} element Url image
   * @param {string} orderId Current order id
   * @param {number} index Current index
   * @param {Array<POD>} pods An array with pods existing
   * @param {Array<FileData>} fileData Extra properties from files
   * @returns {FilesToShow} Object built to show image
   */
  public generateBlobFile(blob: BlobPart, fileName: string, extensionFile: string, isMobilityEvidence: boolean,
    element: string, orderId: string, index: number, pods?: Array<POD>, fileData?: Array<FileData>): FilesToShow {
    const file = new File([blob], fileName, { type: extensionFile });
    const fileAux = {
      file: file,
      isMobilityEvidence: isMobilityEvidence,
      name: fileName,
      url: element,
      orderId: orderId
    };

    if (isMobilityEvidence && element === pods[index].url) {
      fileAux[STATUS] = pods[index].status;
    }

    if (fileData) {
      fileAux[KEY_ADDRESS] = fileData[index].address;
      fileAux[KEY_ID] = fileData[index].id;
      fileAux[KEY_ORDERID] = fileData[index].orderId;
    }

    return fileAux;
  }

  /**
   * @description Convert base64 encoding to Blob
   * @param {string} b64Data Base64 to be decoded
   * @param {RegExp} regex Regular expression for MIME removing
   * @returns {Blob} Blob object
   */
  public base64toBlob(b64Data: string, regex?: RegExp): Blob {
    const _regex = regex || /^data:application\/(pdf);base64,/;
    const contentType = '';
    const sliceSize = 512;
    const byteCharacters = atob(b64Data.replace(_regex, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  /**
   * @description Get file from blob file URI.
   * @param { Array<BlobFileData> } blobFiles - Blob file data.
   * @returns { Array<File> } Files retrieved.
   */
  public async getFiles(blobFiles: Array<BlobFileData>): Promise<Array<File>> {
    const files = [];
    for await (const blobFile of blobFiles) {
      const extensionFile = await this.blobProvider.getMIMEtype(blobFile.extension);
      const response = await fetch(blobFile.fileURI);
      const blob = await response.blob();
      const file = new File([blob], blobFile.fileName, { type: extensionFile });

      files.push(file);
    }

    return files;
  }
}
