import { ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips'

import { AppConstants } from '../../constants/app-constants.constants';
import { GenericRegexp } from './../../regexp/generic.regexp';
import { MultipleInputObject } from '../../interfaces/shipment';

const MINIMAL_THEME = 'minimal';

@Component({
  selector: 'app-multiple-input-search',
  templateUrl: './multiple-input-search.component.html',
  styleUrls: ['./multiple-input-search.component.scss']
})
export class MultipleInputSearchComponent implements OnInit {

  @Output() public inputElements: EventEmitter<Array<string>>;
  @Input() public elements: Array<string>;

  public addOnBlur: boolean;
  public removable: boolean;
  public readonly separatorKeysCodes = [ENTER] as const;
  public splitPattern: RegExp;
  public tagsInput = [];
  public theme: string;

  constructor() {
    this.removable = true;
    this.addOnBlur = true;
    this.inputElements = new EventEmitter<Array<string>>();
  }

  ngOnInit(): void {
    this.theme = MINIMAL_THEME;
    this.splitPattern = new RegExp(GenericRegexp.MULTIPLE_SEARCH_SPLIT);
    this.tagsInput = this.elements ? this.elements : [];
  }


  /**
   * @description Checks if the object has the new tag.
   * @param {MultipleInputObject} tag - A single tag from tags array.
   * @returns Boolean if it contains the input.
   */
  public containsObject(tag: MultipleInputObject, list: Array<MultipleInputObject>): boolean {
    for (let index = 0; index < list.length; index++) {

        if (list[index].value === tag.value) {
          return true;
        }
    }

    return false;
  }

  /**
   * @description Deletes all tags from input box
   */
  public deleteItems(): void {
    this.tagsInput = [];
    this.inputElements.emit(this.tagsInput);
  }

  /**
   * @description Removes the tag value selected.
   * @param {MultipleInputObject} tag - Taf to remove from tags added.
   */
  public remove(tag: MultipleInputObject): void {
    const index = this.tagsInput.indexOf(tag);

    if (index >= 0) {
      this.tagsInput.splice(index, 1);
    }

    this.inputElements.emit(this.tagsInput);
  }

  /**
   * @description Adds a new to tags input array.
   * @param {MatChipInputEvent} event - Tag event added.
   */
  public add(event: MatChipInputEvent): void {
    const value = (event.value || AppConstants.EMPTY_STRING).trim();
    const newTag = { display: value, value: value };

    if (value && !this.containsObject(newTag, this.tagsInput)) {
      this.tagsInput.push(newTag);
    }

    event.chipInput!.clear();
    this.inputElements.emit(this.tagsInput);
  }

  /**
   * @description Splits the values pasted on the input.
   * @param {ClipboardEvent} event - Information related to modification of the clipboard,
   * that is cut, copy, and paste events.
   */
  public onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const newTags = event.clipboardData.getData('Text');
    newTags.split(GenericRegexp.MULTIPLE_SPLIT_STRING).forEach(value => {

      if (value.trim()) {
        const newTag = {
          display: value.replace(AppConstants.CARRIAGE_RETURN, AppConstants.EMPTY_STRING),
          value: value.replace(AppConstants.CARRIAGE_RETURN, AppConstants.EMPTY_STRING)
        };

        if (value && !this.containsObject(newTag, this.tagsInput)) {
          this.tagsInput.push(newTag);
        }
      }
    });
    this.inputElements.emit(this.tagsInput);
  }
}
