<div>
  <agm-map
    id="map"
    [latitude]="latitude"
    [longitude]="longitude"
    [zoom]="zoom"
    [zoomControl]="zoomControl"
    [ngStyle]="{'height': height, 'width': width}">
    <agm-marker
      [latitude]="latitude"
      [longitude]="longitude"
      [iconUrl]="{ url:'../../../assets/bluePin.png', anchor: {x:10,y:40} }">
      <agm-info-window>
        {{ address }}
        <div *ngIf="hasShipment">
          <a (click)="goTo()">{{ labels.clickLabel }}</a>
        </div>
      </agm-info-window>
      <agm-circle [latitude]="latitude" [longitude]="longitude"></agm-circle>
    </agm-marker>
  </agm-map>
</div>
