import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class RejectionReasonsService {

  constructor(private http: HttpClient) {}

  /**
   * @description Get rejection reasons by shipper
   * @param {string} shipperOid to find by shipper
   * @returns {Promise<object>} rejection reaons
   */
  public async getRejectionReasonsByShipper(shipperOid: string): Promise<Array<object>> {
    return await this.http.get<Array<object>>(`${apiUrl}/embarcadores/${shipperOid}/motivosRechazo`).toPromise();
  }

  /**
   * @description Create new rejection reason
   * @param {string} shipperOid to save in shipper info
   * @param {object} body rejection reason to create
   * @returns {Promise<object>} response after create
   */
  public async createRejectionReasons(shipperOid: string, body: object): Promise<object> {
    return await this.http.post<object>(`${apiUrl}/embarcadores/${shipperOid}/motivosRechazo`, body).toPromise();
  }

  /**
   * @description Update rejection reason
   * @param {string} shipperOid to update in shipper info
   * @param {object} body rejection reason to update
   * @returns {Promise<object>} response after update
   */
  public async updateRejectionReasons(shipperOid: string, body: object, rejectionReasonOid: string): Promise<object> {
    return await this.http.put<object>(`${apiUrl}/embarcadores/${shipperOid}/motivosRechazo/${rejectionReasonOid}`, body).toPromise();
  }

  /**
   * @description Delete specific rejection reason
   * @param {string} shipperOid to delete in shipper info
   * @param {string} rejectionReasonOid rejection reason to delete
   * @returns {Promise<object>} response after delete
   */
  public async deleteRejectionReasons(shipperOid: string, rejectionReasonOid: string): Promise<object> {
    return await this.http.delete<object>(`${apiUrl}/embarcadores/${shipperOid}/motivosRechazo/${rejectionReasonOid}`).toPromise();
  }
}
