
export const ShipmentCardInfoLabels: IShipmentCardInfoLabels = {
  acceptedStatus: 'Aceptada',
  assignedCarrier: 'Asignar Transportista',
  assignedMarketplace: 'Asignar en Marketplace',
  assignedShipmentsTabtitle: 'Asignadas',
  assignedStatus: 'Asignada',
  associateShipment: 'Asociar embarque',
  cancelOffer: 'Cancelar Solicitud',
  createdStatus: 'Creada',
  cubicMeters: 'm³',
  deleteButton: 'Eliminar',
  deleteDiaglogResume: 'Toda la configuración e información de la solicitud seleccionado se eliminará y no podrá usarse',
  deleteDialogQuestion: '¿Estás seguro que deseas eliminar la solicitud?',
  deleteDialogTitle: 'Eliminación de Solicitud ',
  deleteIcon: '../../../assets/eliminate.svg',
  everythingShipmentsTabTitle: 'Todas',
  focusText: 'Esta acción no podrá deshacerse',
  goBackButton: 'Regresar',
  inTransitShipmentsTabTitle: 'En curso',
  kg: 'Kg',
  multiOffers: 'Multi-oferta',
  noApply: 'N/A',
  noDate: 'Sin fecha',
  noInfo: 'Sin información',
  noReference: 'Sin referencia',
  notOfferShipmentsTabTitle: 'Sin ofertas',
  oneOffer: 'Una oferta',
  pageTitle: 'Solicitudes Clientes',
  pendingShipmentsTabTitle: 'Pendientes',
  printLabel: 'Imprimir formato',
  requestNotAssignedMessage: 'La solicitud debe encontrarse en estatus asignada',
  searchPlaceholder: 'Buscar en la lista',
  shipmentDetected: 'Esta solicitud ya tiene un viaje asignado',
  viewDetail: 'Ver detalle',
  viewOffers: 'Ver ofertas',
  withoutAssignedShipmentsTabTitle: 'Sin asignar',
  withoutOffers: 'Sin ofertas',
  withoutQuote: 'Sin cotización',
};

export interface IShipmentCardInfoLabels {
  acceptedStatus: string;
  assignedCarrier: string;
  assignedMarketplace: string;
  assignedShipmentsTabtitle: string;
  assignedStatus: string;
  associateShipment: string;
  cancelOffer: string;
  createdStatus: string;
  cubicMeters: string;
  deleteButton: string;
  deleteDiaglogResume: string;
  deleteDialogQuestion: string;
  deleteDialogTitle: string;
  deleteIcon: string;
  everythingShipmentsTabTitle: string;
  focusText: string;
  goBackButton: string;
  inTransitShipmentsTabTitle: string;
  kg: string;
  multiOffers: string;
  noApply: string;
  noDate: string;
  noInfo: string;
  noReference: string;
  notOfferShipmentsTabTitle: string;
  oneOffer: string;
  pageTitle: string;
  pendingShipmentsTabTitle: string;
  printLabel: string;
  requestNotAssignedMessage: string;
  searchPlaceholder: string;
  shipmentDetected: string;
  viewDetail: string;
  viewOffers: string;
  withoutAssignedShipmentsTabTitle: string;
  withoutOffers: string;
  withoutQuote: string;
}
