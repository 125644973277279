export enum MPShipmentRequestStatus {
  ACCEPTED = 'Aceptada',
  ASSIGNED = 'Asignada',
  CONFIRMED = 'Confirmada',
  COUNTER_OFFER = 'Contraofertada',
  CREATED = 'Creada',
  DELIVERED = 'Entregada',
  FINISHED = 'Finalizada',
  ON_TRANSIT = 'En transito'
}
