import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { isEmpty as _isEmpty } from 'lodash';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { GenericRegexp } from '../../regexp/generic.regexp';
import { NetPromoterScoreArrayResponse } from '../../interfaces/supplynet-core/net-promoter-score.interface';
import { NetPromoterScoreProvider } from '../../providers/net-promoter-score/net-promoter-score-provider.service';

const ERROR_CODE_DUPLICATE = 409;

@Component({
  selector: 'net-promoter-score',
  templateUrl: './net-promoter-score.component.html',
  styleUrls: ['./net-promoter-score.component.scss', '../../app.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class NetPromoterScoreComponent implements OnInit {
  public dialogPosition: string;
  public isBackgroundBlocked: boolean;
  public isRatingSectionActive: boolean;
  public isSaveButtonDisabled: boolean;
  public isScoredBefore: boolean;
  public isVisible: boolean;
  public netPromoterScoreForm: FormGroup;
  public scoreValue: number;
  public scoreValueRangeMax: number;
  public userId: string;

  /**
   * @description - Initializes required component services.
   * @param { FormBuilder } _formBuilder - Form builder service.
   */
  constructor(
    private _appService: AppService,
    private _formBuilder: FormBuilder,
    private _netPromoterScoreProvider: NetPromoterScoreProvider
  ) {}

  /**
   * @description - Initializes Angular lifecycle for component.
   */
  ngOnInit() {
    this.dialogPosition = 'bottom-right';
    this.isBackgroundBlocked = false;
    this.isRatingSectionActive = true;
    this.isSaveButtonDisabled = false;
    this.isScoredBefore = false;
    this.scoreValueRangeMax = 5;
    this.userId = this._appService.getUserOid();
    this.initForm();
    this.validateScoreDisplay();
  }

  /**
   * @description - Initializes the form 'netPromoterScoreForm'.
   */
  private initForm(): void {
    this.netPromoterScoreForm = this._formBuilder.group({
      score: new FormControl(null, [Validators.required, Validators.min(1),
        Validators.max(10), Validators.maxLength(1), Validators.minLength(1),
        Validators.pattern(GenericRegexp.NON_DECIMAL_ALLOWED)]),
      clientObservations: new FormControl(null, [Validators.required,
        Validators.minLength(5), Validators.maxLength(300)])
    });
  }

  /**
   * @description - Validates whether the net promoter score component
   * should be displayed or not.
   */
  private async validateScoreDisplay(): Promise<void> {
    this.isVisible = false;
    await this._netPromoterScoreProvider
      .getNetPromoterScoresByUserAndSolution(this.userId, environment.solutionId)
      .then((result: NetPromoterScoreArrayResponse) => {
        if (_isEmpty(result.item)) {
          this.isVisible = true;
        }
      })
    .catch(() => {});
  }

  /**
   * @description - Resets score form and closes dialog.
   */
  public onClickClose(): void {
    this.scoreValue = null;
    this.netPromoterScoreForm.reset();
    this.isRatingSectionActive = true;
    this.isVisible = false
  }

  /**
   * @description - Sets user score.
   */
  public onClickRate(event: any): void { 
    this.isBackgroundBlocked = true;
    this.scoreValue = event.value;
    this.netPromoterScoreForm.controls.score.setValue(this.scoreValue);
  }

  /**
   * @description - Creates user score.
   */
  public async onClickSave(): Promise<void> {
    this.isSaveButtonDisabled = true;
    const shipperId = this._appService.getShipperOid();
    const tenantName = this._appService.getUserInfoFromStorage().embarcador?.nombre;

    let netPromoterScore = {
      respondentCustomer: {
        id: this.userId,
        tenantId: shipperId,
        tenantName: tenantName,
      },
      solution: {
        id: environment.solutionId,
        name: environment.solutionName,
      },
      survey: {
        comments: this.netPromoterScoreForm.value.clientObservations,
        scoreValue: this.netPromoterScoreForm.value.score,
      },
    };

    await this._netPromoterScoreProvider.createNetPromoterScore(netPromoterScore)
      .then(() => {
        this.isRatingSectionActive = false;
      }).catch((error) => {
        if (error.status === ERROR_CODE_DUPLICATE) {
          this.isRatingSectionActive = false;
          this.isScoredBefore = true;
        } else {
          this.onClickClose();
        }
      });
  }
}
