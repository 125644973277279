export enum OrderGuidesLabels {
  actions = 'Acciones',
  affirmation = 'Aceptar',
  confirm = 'Confirm',
  confirmDelete = 'La guía ha sido eliminada',
  deleteGuide = 'Eliminar guía ',
  guide = 'Guía',
  negation = 'Cancelar',
  number = 'Número',
  orderGuidesTittle = 'Guias de la órden: '
}
