import { ImportationCategory } from '../../../../interfaces';

export const PRODUCT_COFEPRIS_FORM: ImportationCategory = {
  name: 'productCofepris',
  rules: [
    {
      name: 'productIsCofeprisProduct',
      required: false
    },
    {
      name: 'productMatterType',
      required: false
    },
    {
      name: 'productCofeprisSector',
      required: false
    },
    {
      name: 'productActiveIngredientName',
      required: false
    },
    {
      name: 'productChemicalName',
      required: false
    },
    {
      name: 'productActiveSubstanceGeneric',
      required: false
    },
    {
      name: 'productTrademarkMedication',
      required: false
    },
    {
      name: 'productManufacturer',
      required: false
    },
    {
      name: 'productExpirationDate',
      required: false
    },
    {
      name: 'productLot',
      required: false
    },
    {
      name: 'productPharmaceuticalForm',
      required: false
    },
    {
      name: 'productTransportSpecialConditions',
      required: false
    },
    {
      name: 'productHealthRegisterFolio',
      required: false
    },
    {
      name: 'productImportLicense',
      required: false
    },
    {
      name: 'productImportVUCEMFolio',
      required: false
    },
    {
      name: 'productNumCAS',
      required: false
    },
    {
      name: 'productImportCompany',
      required: false
    },
    {
      name: 'productCofeprisPesticideNumber',
      required: false
    },
    {
      name: 'productPesticideManufacturerCountryName',
      required: false
    },
    {
      name: 'productActiveIngredientManufacturerCountryName',
      required: false
    },
    {
      name: 'productActiveIngredientMaquilaCountryName',
      required: false
    },
    {
      name: 'productAuthorizedUse',
      required: false
    }
  ]
};
