export class EdxConstants {
  public static readonly FILE_EXTENSION = 'json';
  public static readonly FREIGHT = 'FLETE';
  public static readonly INGRESS = 'I';
  public static readonly INGRESS_QUANTITY = '1';
  public static readonly INGRESS_SERVICE_KEY = '78101802';
  public static readonly INGRESS_UNIT_DESC = 'UNIDAD DE SERVICIO';
  public static readonly INGRESS_UNIT_TYPE_KEY = 'E48';
  public static readonly NO = 'No';
  public static readonly SOURCE_NAME_INGRESS = 'INGRESS_CL_ALL';
  public static readonly SOURCE_NAME_TRANSFER = 'TRANSFER_CL_ALL';
  public static readonly TRANSFER = 'T';
  public static readonly TRANSFER_CURRENCY = 'XXX';
  public static readonly YES = 'Sí';
  public static readonly ENTRANCE = 'Entrada';
  public static readonly EXIT = 'Salida';
}
