export const AssociateShipmentsConstants = {
  ASSIGNED_STATUS: 'Asignado',
  CONFIRM: 'Confirm',
  DAYS_TO_SUBTRACT: 7,
  DATE_FORMAT: 'DD/MM/yyyy',
  MODAL_ICON: '../../../../../assets/accept_offer.svg',
  MODAL_SIZE: '650px',
  REQUEST_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  SHIPMENT_STATUS: 'Planeado',
  SHIPPER_REQUESTS_VIEW: '/marketplace-shipper/shipper-shipment-request',
  SLASH: '/',
  TIME_FORMAT: 'h:mm a',
  TRYP_TYPE: 'Recolección',
};

export const DISPLAY_COLUMNS = [
  '_id',
  'shipmentId',
  'status',
  'origin',
  'orders'
];
