<div class="general-container">
  <h3 class="title-page"> {{ 'customerInvoiceLabels.title' | translate }} </h3>
  <mat-divider class="mat-divider-header"></mat-divider>
  <div class=secondary-container>
    <div>
      <button
        mat-icon-button
        [matTooltip]="'customerInvoiceLabels.specificSearchToolTip' | translate"
        matTooltipPosition="above"
        class="icon-button"
        (click)="onSearchChange(true)">
        <img
          alt="Search enabled"
          id="specificSearch"
          src="../../../assets/icons/specific-search.svg"
          class="image-button"/>
      </button>
      <button
        mat-icon-button
        [matTooltip]="'customerInvoiceLabels.generalSeachToolTip' | translate"
        matTooltipPosition="above"
        class="icon-button"
        (click)="onSearchChange()">
        <img
          alt="Search db enabled"
          id="generalSearch"
          src="../../../assets/icons/general-search.svg"
          class="image-button"/>
      </button>
    </div>
  </div>

  <div id="customer-invoice-container">
    <div id="customer-invoice-general-container" *ngIf="isGeneralSearch">
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        [expanded]=expandedInfo>
        <mat-expansion-panel-header
          class="arrow-color"
          [collapsedHeight]="'40px'"
          [expandedHeight]="'40px'">
          <mat-panel-title class="panel-title">
            {{ 'customerInvoiceLabels.generalSearchTitle' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-grid-list cols="4" rowHeight="1:.30">
          <form [formGroup]="customerInvoiceGroup">
            <mat-grid-tile>
              <mat-form-field>
                <input
                  id="beginDateInput"
                  class="input--pointer"
                  matInput
                  [matDatepicker]="beginDate"
                  placeholder="{{ 'customerInvoiceLabels.beginDate' | translate }}"
                  readonly
                  required
                  (dateChange)="setEndDate()"
                  formControlName="beginDate"
                  [max]="today"
                  (click)="beginDate.open()">
                <mat-error>
                  {{ 'customerInvoiceLabels.beginDateIs' | translate }}
                  <strong>
                    {{ 'customerInvoiceLabels.required' | translate }}
                  </strong>
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="beginDate"></mat-datepicker-toggle>
                <mat-datepicker #beginDate></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field>
                <input
                  id="endDateInput"
                  #inputEndDate
                  class="input--pointer"
                  matInput
                  [matDatepicker]="endDate"
                  placeholder="{{ 'customerInvoiceLabels.endDate' | translate }}"
                  readonly
                  required
                  (dateChange)="validateGeneralFormChanges()"
                  formControlName="endDate"
                  [min]="minEndDate"
                  [max]="(isNearDate) ? today : limitEndDate"
                  (click)="endDate.open()">
                <mat-error>
                  {{ 'customerInvoiceLabels.endDateIs' | translate }}
                  <strong>
                    {{ 'customerInvoiceLabels.required' | translate }}
                  </strong>
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <app-search-filter
                class="select-search-width"
                id="cic-accountSelectSearch"
                selectLabel="{{ 'customerInvoiceLabels.account' | translate }}"
                [controlName]="accountControlName"
                [isRequiredInput]="true"
                [results]="accounts">
              </app-search-filter>
            </mat-grid-tile>
          </form>
          <mat-grid-tile>
            <button
              id="gci-search-button"
              mat-raised-button
              class="primary-button"
              [disabled]="!searchEnabled"
              (click)="onSearch()">
              {{ 'customerInvoiceLabels.search' | translate }}
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>
    </div>

  <div id="customer-invoice-specific-container" *ngIf="isSpecificSearch">
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [expanded]=expandedInfo>
      <mat-expansion-panel-header collapsedHeight='2.5em' expandedHeight='2.5em'>
        <mat-panel-title>
          {{ 'customerInvoiceLabels.specificSearchTitle' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-grid-list cols="4" rowHeight="1:.30">
        <form [formGroup]="specificCustomerInvoiceGroup">
          <mat-grid-tile>
            <mat-form-field>
              <input
              id="specificFolioInput"
              #searchInput
              formControlName="invoiceProposalFolio"
              matInput
              placeholder="{{ 'customerInvoiceLabels.searchFolioTooltip' | translate }}"
              required>
              <mat-error>
                {{ 'customerInvoiceLabels.errorRequired' | translate }}
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
        </form>
        <mat-grid-tile>
          <button
            id="sci-search-button"
            mat-raised-button
            class="primary-button"
            [disabled]="!specificCustomerInvoiceGroup.valid || !searchEnabled"
            (click)="onSearch()">
            {{ 'customerInvoiceLabels.search' | translate }}
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-expansion-panel>
  </div>

  <app-no-info *ngIf="!dataSourceAvailable"></app-no-info>

  <div [hidden]="!dataSourceAvailable" class="scf-grid-container" cdkDropListGroup>
    <scf-grid
      *ngIf="isScfGridReady"
      [columnToggler]="scfGridConfig.columnToggler"
      [selectable]="scfGridConfig.selectable"
      [pageSize]="scfGridConfig.pageSize"
      [pageSizeOptions]="scfGridConfig.pageSizeOptions"
      [data]="dataSource"
      [actions]="actions"
      [columns]="displayedColumns"
      [rowDraggable]="scfGridConfig.rowDraggable"
      (actioned)="gridActionHandler($event.data, $event.action)">
    </scf-grid>
  </div>

  <app-customer-invoice-pdf
    id="customer-invoice-pdf"
    [data]="dataSource"
    [customerInvoiceLabels]="customerInvoiceLabels"
    [hidden]=true
    *ngIf="isScfGridReady">
  </app-customer-invoice-pdf>

  <button
    (click)="exportDataToExcel()"
    [disabled]="!isScfGridReady"
    *ngIf="isScfGridReady"
    class="secondary-btn-class"
    id="exportExcelButton"
    mat-raised-button>
    {{ 'registerFreightInvoicesLabels.exportToExcel' | translate }}
  </button>

  <button
    id="ci-exportPdfButton"
    [disabled]="!isScfGridReady"
    [printTitle]="customerInvoiceLabels.title"
    [useExistingCss]="true"
    *ngIf="isScfGridReady"
    class="primary-btn-class"
    mat-raised-button
    ngxPrint
    printSectionId="customer-invoice-pdf">
    {{ 'registerFreightInvoicesLabels.exportToPDF' | translate }}
  </button>

</div>
