import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { AppConstants } from '../../../constants/app-constants.constants';
import { AppService } from '../../../app.service';
import { DialogFilePreviewConst, ResourceType } from './constants/dialog-file-preview.constants';
import { DownloadFilesService } from '../../../services/utils/download-files.service';
import { EvidenceFolio, EvidenceHistoryDetail, ShipmentRowExtended } from '../../../interfaces/index';
import { FileConversorService } from './../../../services/file-conversor';
import { IncidenceData } from './../../../interfaces/incidence_data';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { Pages, Profiles } from '../../../enums';
import { ShipmentRequestResponse } from '../../../interfaces/marketplace-shipper/shipment-request';
import { ShowFullImageComponent } from '../show-full-image/show-full-image.component';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

import * as jsPDF from 'jspdf';
import { Subscription } from 'rxjs';
import html2canvas from 'html2canvas';

const CLOSE_MODAL = 'Closed';
const EVIDENCE_IDENTIFIER = 'EV';
const HEIGHT_MODAL = '98%';
const MAX_WIDTH_MODAL = '98%';
const MODAL_CLASS = 'background-dialog-image';
const WIDTH_MODAL = '98%';

@Component({
  selector: 'app-dialog-file-preview',
  templateUrl: './dialog-file-preview.component.html',
  styleUrls: ['./dialog-file-preview.component.scss', '../../../app.component.scss'],
  providers: [DownloadFilesService]
})
export class DialogFilePreviewComponent implements OnInit, OnDestroy {
  public additionalFunctions: boolean;
  public additionalInformation: boolean;
  public dialogFilePreviewTagsTranslated: any;
  public evidenceComments: string;
  public evidenceDate: string;
  public evidenceFolio: string;
  public evidenceFolioData: EvidenceFolio;
  public evidenceOrigin: string;
  public evidenceReceptor: string;
  public evidenceReleaseFolioData: EvidenceFolio;
  public evidenceShipment: string;
  public incidenceData: IncidenceData;
  public isDownloadable: boolean;
  public isEvidenceInfo: boolean;
  public isPDF: boolean;
  public isPrintable: boolean;
  public languageSuscription: Subscription;
  public languageLabels: any;
  public reader: FileReader;
  public registeredFrom: string;
  public resourceType: number;
  public shipmentRequestData: ShipmentRequestResponse;
  public shipmentToLoadPlan: ShipmentRowExtended;
  public showIncidence: boolean;
  public url: string | object;

  private angle: number;
  private formatFile: string;
  private scale: number;
  private isImageRotated: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private downloadService: DownloadFilesService,
    private sanitizer: DomSanitizer,
    private toast: ToastrAlertsService,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<DialogFilePreviewComponent>,
    private appService: AppService,
    private fileCService: FileConversorService,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService
  ) {
    this.setLanguage();
  }

  /**
   * @description Angular destroy lifecycle
   */
   public ngOnDestroy() {
    this.languageSuscription?.unsubscribe();
  }

  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
    await this.getDialogFilePreviewTags();
    this.angle = 0;
    this.scale = 1;
    this.isEvidenceInfo = this.data.isEvidenceInfo ? this.data.isEvidenceInfo : false;
    this.isImageRotated = false;
    this.evidenceOrigin = AppConstants.EMPTY_STRING;
    this.evidenceFolio = AppConstants.EMPTY_STRING;
    this.evidenceReceptor = AppConstants.EMPTY_STRING;
    this.evidenceShipment = AppConstants.EMPTY_STRING;
    this.evidenceDate = AppConstants.EMPTY_STRING;
    this.evidenceComments = AppConstants.EMPTY_STRING;
    this.defineResourceType();
    if (this.data.displayInfo) {
      if (this.data.incidenceInfo && this.data.incidenceInfo.isIncidence) {
        this.showIncidence = true;
        this.incidenceData = this.data.incidenceInfo.incidence;
        this.setIncidenceRegisteredFromLabel();
      } else {
        this.showIncidence = false;
      }
      if (!this.showIncidence) {
        this.additionalInformation = true;
        this.setAdditionalInfo();
      }
    }
  }

  /**
   * @description Sets label registeredFrom
   */
  private setIncidenceRegisteredFromLabel() {
    if (this.incidenceData.registeredFrom === Pages.OrdersReception) {
      this.registeredFrom = this.dialogFilePreviewTagsTranslated.ordersReception;
    } else if (this.incidenceData.registeredFrom === Pages.MobileApp) {
      this.registeredFrom = this.dialogFilePreviewTagsTranslated.mobileApp;
    } else if (this.incidenceData.registeredFrom === Pages.ManualVisits) {
      this.registeredFrom = this.dialogFilePreviewTagsTranslated.manualVisits;
    }
  }

  /**
   * @description set the additional information that will be displayed in the dialog
   */
  public setAdditionalInfo(): void {
    if (this.data.evidenceInfoV3) {
      this.evidenceOrigin = this.data.evidenceInfoV3.createdBy.module;
      this.evidenceFolio = this.data.evidenceInfoV3.folio;
      this.evidenceReceptor = this.data.evidenceInfoV3.receptor;
      this.evidenceShipment = this.data.evidenceInfoV3.shipmentId;
      this.evidenceDate = this.data.evidenceInfoV3.registerDate;
      this.evidenceComments = this.data.evidenceInfoV3.notes;
    } else {
      this.evidenceOrigin = this.data.evidenceInfo.evidenceOrigin;
      this.evidenceFolio = this.data.evidenceInfo.folio ?? AppConstants.EMPTY_STRING;
      this.evidenceReceptor = this.data.evidenceInfo.receptor ?? AppConstants.EMPTY_STRING;
      this.evidenceShipment = this.data.evidenceInfo.shipment ?? AppConstants.EMPTY_STRING;

      if (this.data.evidenceInfo.evidenceOrigin === DialogFilePreviewConst.ORIGIN_EVIDENCE_CONTROL) {
        this.evidenceDate = this.data.evidenceInfo.timestamp ?? AppConstants.EMPTY_STRING;
      } else {
        const evidences: Array<EvidenceHistoryDetail> = this.data.evidenceInfo.evidence;
        const evidenceFiltered = evidences.filter(el => {
          return el.evidence.img.nombre === this.data.file.name;
        });
        if (evidenceFiltered.length) {
          this.evidenceDate = evidenceFiltered[0].evidence.timestampCaptura;
        } else {
          this.evidenceDate = AppConstants.EMPTY_STRING;
        }
      }
    }
  }

  /**
   * @description Event fires when click on download image button
   */
  public onDownloadImage(): void {
    try {
      const fileName = DialogFilePreviewConst.FILE_NAME;
      const imageFile: File = this.data.file;
      this.downloadService.downloadFile(imageFile, fileName);
    } catch (error) {
      this.toast.errorAlert(this.dialogFilePreviewTagsTranslated.errorDownloadFile);
    }
  }

  public onClickClose(): void {
    if (!this.isImageRotated) {
      this.dialogRef.close(DialogFilePreviewConst.CONFIRM);
    } else {
      this.toast.processingAlert(this.dialogFilePreviewTagsTranslated.savingChanges);
      this.saveImage();
    }
  }

  public onDownload(): void {
    (document.getElementById(DialogFilePreviewConst.DOWNLOADBUTTON) as HTMLButtonElement).disabled = true;
    const data = document.getElementById(DialogFilePreviewConst.PRINTSECTION);
    html2canvas(data, {
      width: DialogFilePreviewConst.MAXWIDTHPDF,
      scale: DialogFilePreviewConst.PDFSCALE
    }).then(canvas => {
      const contentDataURL = canvas.toDataURL(DialogFilePreviewConst.DATAURLFORMAT, DialogFilePreviewConst.QUALITYIMAGE);
      const pdf = jsPDF(DialogFilePreviewConst.PAGEORIENTATION, DialogFilePreviewConst.PDFMEASUREMENTUNIT,
        DialogFilePreviewConst.PAGESIZE);
      const imgHeight = canvas.height * DialogFilePreviewConst.IMGWIDTH / canvas.width;
      let heightLeft = imgHeight;
      let position = DialogFilePreviewConst.PDFHORIZONTALALIGN;

      pdf.addImage(contentDataURL, DialogFilePreviewConst.IMAGEFORMAT, DialogFilePreviewConst.PDFVERTICALALIGN, position,
        DialogFilePreviewConst.IMGWIDTH, imgHeight + DialogFilePreviewConst.PDF_HEIGHT_ADDED);
      heightLeft -= DialogFilePreviewConst.PAGEHEIGHT;

      while (heightLeft >= DialogFilePreviewConst.ZERO) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, DialogFilePreviewConst.IMAGEFORMAT, DialogFilePreviewConst.PDFVERTICALALIGN, position,
          DialogFilePreviewConst.IMGWIDTH, imgHeight + DialogFilePreviewConst.PDF_HEIGHT_ADDED);
        heightLeft -= DialogFilePreviewConst.PAGEHEIGHT;
      }
      pdf.save(this.dialogFilePreviewTagsTranslated.pdfPrefix +
        this.shipmentToLoadPlan.shipmentId +
        DialogFilePreviewConst.DOWNLOADPDFEXTENSION);
      (document.getElementById(DialogFilePreviewConst.DOWNLOADBUTTON) as HTMLButtonElement).disabled = false;
    });
  }

  /**
   * @description Rotates image in dialog
   * @param direction direction to be rotated on fixed angles of 45 degrees
   */
  public rotateImage(direction: string): void {
    this.angle += direction === DialogFilePreviewConst.LEFT ? -DialogFilePreviewConst.DEGREES : DialogFilePreviewConst.DEGREES;
    this.isImageRotated = true;
    this.renderer.setStyle(document.querySelector(DialogFilePreviewConst.IMAGEPREVIEW), DialogFilePreviewConst.TRANSFORM,
      `scale(${this.scale}) rotate(${this.angle}deg)`);
  }

  /**
   * @description Zoom image
   * @param zoom whether the zoom will be in or out
   */
  public zoom(zoom: string): void {
    if (zoom === DialogFilePreviewConst.OUT && this.scale <= DialogFilePreviewConst.SCALEMIN) {
      this.scale = DialogFilePreviewConst.SCALEMINVALID;
    } else if (zoom === DialogFilePreviewConst.IN && this.scale >= DialogFilePreviewConst.SCALEMAX) {
      this.scale = DialogFilePreviewConst.SCALEMAXVALID;
    }
    this.scale += zoom === DialogFilePreviewConst.OUT ? -DialogFilePreviewConst.SCALEADD : DialogFilePreviewConst.SCALEADD;
    this.renderer.setStyle(document.querySelector(DialogFilePreviewConst.IMAGEPREVIEW), DialogFilePreviewConst.TRANSFORM,
      `scale(${this.scale}) rotate(${this.angle}deg)`);
  }

  /**
   *@description Deploy modal for show full image
   *@returns void
   */
  public openImage(): void {
    const dialogImage = this.dialog.open(ShowFullImageComponent, {
      data: {
        image: this.url,
        isRotatable: true,
        shouldHideActions: true
      },
      width: WIDTH_MODAL,
      height: HEIGHT_MODAL,
      maxWidth: MAX_WIDTH_MODAL,
      panelClass: MODAL_CLASS
    });

    dialogImage.afterClosed().subscribe(async result => {
      if (result !== CLOSE_MODAL) {
        this.isImageRotated = true;
        this.reader.readAsDataURL(result);
        this.reader.onload = () => {
          this.formatFile = result.type;
          this.url = this.reader.result;
          this.isPDF = false;
        };
      }
    });
  }

  /**
   * @description Opens modal to view full image pdf.
   */
  public expandPDF(): void {
    this.dialog.open(ShowFullImageComponent, {
      data: {
        pdfFile: this.data.file,
        isPDF: true,
        shouldHideActions: false
      },
      width: WIDTH_MODAL,
      height: HEIGHT_MODAL,
      maxWidth: MAX_WIDTH_MODAL,
      panelClass: MODAL_CLASS
    });
  }

  /**
   * @description Convert edited image to url data to save image
   */
  private saveImage() {
    const data = document.getElementById(DialogFilePreviewConst.IMAGEPREVIEWDIALOG);
    this.renderer.setStyle(document.querySelector(DialogFilePreviewConst.IMAGEPREVIEW), DialogFilePreviewConst.TRANSFORM,
      `scale(1) rotate(${this.angle}deg)`);
    html2canvas(data).then(canvas => {
      canvas.toBlob((blob) => {
        this.dialogRef.close(blob);
      }, this.formatFile, DialogFilePreviewConst.QUALITYIMAGE);
    });
  }

  /**
   * @description Determinate which type of file will be display
   */
  public defineResourceType(): void {
    switch (true) {
      case this.data.hasOwnProperty(DialogFilePreviewConst.KEY_SHIPMENT_TO_LOAD_PLAN) === true: {
        this.additionalFunctions = true;
        this.resourceType = ResourceType.LOAD_PLAN;
        this.shipmentToLoadPlan = this.data.shipmentToLoadPlan;
        this.isDownloadable = this.data.isDownloadable;
        this.isPrintable = this.data.isPrintable;
        break;
      }
      case this.data.hasOwnProperty(DialogFilePreviewConst.KEY_EVIDENCE_RELEASE_FOLIO_DATA) === true &&
        this.data.evidenceReleaseFolioData.folio.startsWith(EVIDENCE_IDENTIFIER): {
        this.additionalFunctions = true;
        this.resourceType = ResourceType.EVIDENCE_DOC;
        this.evidenceFolioData = this.data.evidenceReleaseFolioData;
        this.isDownloadable = false;
        this.isPrintable = true;
        break;
      }
      case this.data.hasOwnProperty(DialogFilePreviewConst.KEY_EVIDENCE_RELEASE_FOLIO_DATA) === true: {
        this.additionalFunctions = true;
        this.resourceType = ResourceType.EVIDENCE_RELEASE_DOC;
        this.evidenceReleaseFolioData = this.data.evidenceReleaseFolioData;
        this.isDownloadable = this.data.isDownloadable;
        this.isPrintable = this.data.isPrintable;
        break;
      }
      case this.data.hasOwnProperty(DialogFilePreviewConst.KEY_SHIPMENT_REQUEST_DATA) === true: {
        this.additionalFunctions = true;
        this.resourceType = ResourceType.SHIPMENT_REQUEST_DOC;
        this.shipmentRequestData = this.data.shipmentRequestData;
        this.isDownloadable = this.data.isDownloadable;
        this.isPrintable = this.data.isPrintable;
        break;
      }
      default : {
        this.additionalFunctions = false;
        this.resourceType = ResourceType.IMAGE_OR_PDF;
        this.isDownloadable = false;
        this.isPrintable = false;
        this.reader = new FileReader();
        this.isPDF = false;

        this.reader.readAsDataURL(this.data.file);
        this.reader.onload = () => {
          if (this.data.file.type === DialogFilePreviewConst.PDFEXTENSION) {
            const base64 = this.reader.result.toString();
            const blob = new Blob([this.fileCService.base64toBlob(base64)], { type: DialogFilePreviewConst.MIME_PDF });
            const fileURL = URL.createObjectURL(blob);
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
            this.isPDF = true;
          } else {
            this.formatFile = this.data.file.type;
            this.url = this.reader.result;
            this.isPDF = false;
          }
        };
        this.reader.onerror = () => {
          this.toast.errorAlert(this.dialogFilePreviewTagsTranslated.error);
        };
        break;
      }
    }
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey Optional language key string, default is spanish 'es'
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getDialogFilePreviewTags();
        this.setIncidenceRegisteredFromLabel();
      },
      (error) => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets dialog file preview component Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getDialogFilePreviewTags(): Promise<void> {
    this.dialogFilePreviewTagsTranslated = await this._languageTranslateService.getLanguageLabels(LanguageConstants.DIALOG_FILE_PREVIEW)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Validates if need to show download button by profile
   * @returns {boolean} Hide/Show button by profile.
   */
  public showDownloadButtonByProfile(): boolean {
    let showDownloadButton = true;
    const profile = this.appService.getProfile();
    switch (profile) {
      case Profiles.Carrier:
        showDownloadButton = false
        break;
    
      default:
        break;
    }

    return showDownloadButton;
  }

  /**
   * @description Shows or hide the actions available in the component.
   * @returns { boolean } Shows or hide actions according to the condition.
   */
  public shouldShowActions(): boolean {
    const shouldHideActions = this.data?.shouldHideActions ?? false;

    return !shouldHideActions;
  }
}
