import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogInputData } from '../../../interfaces/index';
import { DialogAuthErrorProperties, DialogAuthErrorPropertiesTags } from './dialog-auth-error.properties';

@Component({
  selector: 'app-dialog-auth-error',
  templateUrl: './dialog-auth-error.component.html',
  styleUrls: ['./dialog-auth-error.component.scss', '../../../app.component.scss']
})
export class DialogAuthErrorComponent {
  public properties: DialogAuthErrorPropertiesTags;

  constructor(
    public dialogRef: MatDialogRef<DialogAuthErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogInputData
  ) {
    this.properties = DialogAuthErrorProperties;
  }

  /**
   * @description Catch signOut button action to close modal
   */
  public onSignOut(): void {
    this.dialogRef.close(true);
  }
}
