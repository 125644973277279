import { Injectable } from '@angular/core';

import Keycloak, { KeycloakConfig } from 'keycloak-js';

import { AUTHENTICATION_CONSTANTS } from '../../constants/authentication.constants';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../utils/local-storage.service';

const KEYCLOAK_ERROR = 'Authentication error: ';
const KEYCLOAK_ON_LOAD = 'check-sso';
const KEYCLOAK_MIN_TOKEN_VALIDITY = 300;

/**
 * @class
 * @description - SSO Authentication Service Class.
 */
@Injectable({ providedIn: 'root' })
export class SsoAuthenticationService {
  private _keycloak: Keycloak.KeycloakInstance;
  private _keycloakConfig: KeycloakConfig = {
    clientId: environment.ssoMicroservice,
    realm: environment.ssoRealm,
    url: environment.ssoApiUrl
  };

  /**
   * @description - Creates an instance of SSO Authentication Service class.
   * @param { LocalStorageService } localStorageService - Local storage service.
   */
  constructor(private localStorageService: LocalStorageService) {}

  /**
   * @description - Tries to get realm from localStorage.
   *
   * @returns { string } - Realm from localStorage or default environment variable.
   */
  public getRealmFromLocalStorage(): string {
    const realm = this.localStorageService.getItem(AUTHENTICATION_CONSTANTS.LOCAL_STORAGE_SHIPPER_REALM_KEY);

    if (realm) {
      return realm;
    } else {
      return environment.ssoRealm;
    }
  }

  /**
   * @description - Gets token from Keycloak instance. If the token is expired,
   * tries to get a new token from Keycloak.
   *
   * @throws { Error } - Keycloak error.
   * @returns { Promise<string> } - Token issued by Keycloak.
   */
  public async getToken(): Promise<string> {
    try {
      await this._keycloak.updateToken(KEYCLOAK_MIN_TOKEN_VALIDITY);

      return this._keycloak.token;
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR}${error}`);
    }
  }

  /**
   * @description - Initializes a Keycloak instance to connect with Supplynet SSO.
   *
   * @throws { Error } - Keycloak error.
   * @returns { boolean } - Returns true if Keycloak initialization succeeded,
   * otherwise returns false or thrown an error.
   */
  public async init(): Promise<boolean> {
    try {
      this._keycloakConfig.realm = this.getRealmFromLocalStorage();
      this._keycloak = new Keycloak(this._keycloakConfig);

      const isInitSuccess = await this._keycloak.init(
        {
          checkLoginIframe: false,
          onLoad: KEYCLOAK_ON_LOAD
        });

      return isInitSuccess;
    } catch (error) {
      this.userLogout();

      throw new Error(`${KEYCLOAK_ERROR}${error}`);
    }
  }

  /**
   * @description - Checks if the user is authenticated.
   *
   * @returns { boolean } - Returns true if the user is authenticated, otherwise returns false.
   */
  public isAuthenticated(): boolean {
    if (this._keycloak) {
      return this._keycloak.authenticated;
    }

    return false;
  }

  /**
   * @description - Sets realm into Keycloak instance and localStorage.
   * @param { string } realm - Tenant name used by user to login.
   *
   * @returns { void }
   */
  public setRealmInLocalStorage(realm: string): void {
    this._keycloak.realm = realm;
    this.localStorageService.saveItem(AUTHENTICATION_CONSTANTS.LOCAL_STORAGE_SHIPPER_REALM_KEY, realm);
  }

  /**
   * @description - Authenticates the user and redirects to the login page.
   * @param { string } username - Username to try login.
   *
   * @returns { Promise<void> }
   */
  public async userLogin(username: string): Promise<void> {
    return this._keycloak.login({ loginHint: username });
  }

  /**
   * @description - Logged out an user from SSO and remove items from localStorage.
   *
   * @returns { Promise<void> }
   */
  public async userLogout(): Promise<void> {
    this.localStorageService.removeItem(AUTHENTICATION_CONSTANTS.LOCAL_STORAGE_SHIPPER_REALM_KEY);
    this.localStorageService.removeItem(AUTHENTICATION_CONSTANTS.LOCAL_STORAGE_USERNAME_KEY);

    await this._keycloak.logout();
  }
}
