import { ConfirmationLabel, SpecialRequirementsViewTag } from '../../../interfaces/index';

export const ConfirmationLabels: ConfirmationLabel = {
  activeNotification: 'Notificación de caducidad activada',
  addressOrigin: 'Dirección origen',
  addressDestination: 'Dirección destino',
  allCarriers: 'Solicitud para todos los transportistas',
  assignBudget: 'Presupuesto',
  assignBudgetString: 'assignBudget',
  assignBudgetTitle: 'Quiero asignar mi presupuesto',
  at: ' a las ',
  budgetSubtitle: 'TARIFA CON PRESUPUESTO',
  carrier: 'Transportista',
  merchandiseType: 'Entarimado',
  codePostal: 'C.P.',
  comma: ', ',
  confirmationTitle: 'Confirmación de la Solicitud',
  cubicMeters: 'm³',
  dateAndTimeDelibery: 'Hora de entrega',
  dateAndTimeDestination: 'Hora de destino',
  dateAndTimeOrigin: 'Hora de origen',
  dateAndTimeOriginLoad: 'Hora de carga',
  dateAndTimeTrip: 'FECHA Y HORA DEL VIAJE',
  dateDelivery: 'Fecha de entrega',
  dateDestination: 'Fecha de destino',
  dateOrigin: 'Fecha de origen',
  dateOriginLoad: 'Fecha de carga',
  dateTimeDistributeSubtitle: 'Fecha y hora por reparto',
  dateTimeShipmentSubtitle: 'Fecha y Hora Viaje',
  destination: 'Destino: ',
  destinationTitle: 'Destino Final ',
  distribute: 'Reparto',
  distributeCount: 'Número de repartos',
  distributeTabTitle: 'Repartos',
  distributeTypeSubtitle: 'Tipos de mercancía por reparto',
  dollarSign: '$',
  editDateAndTime: 'Editar Fechas y Horas',
  editDelivery: 'Editar Reparto',
  editLoad: 'Editar Carga',
  editMerchandise: 'Editar Mercancía',
  editOriginAndDestination: 'Editar Origen y Destino',
  editRate: 'Editar Tarifa',
  eta: 'ETA',
  expirationShipmentSubtitle: 'Caducidad de Solicitud',
  finalDestination: 'Destino final',
  generalTabTitle: 'General',
  kilograms: ' kgs.',
  kilogramsTitle: 'Kilogramos totales',
  kilometers: ' km.',
  labelTime: ' (HH:MM)',
  loadSubtitle: 'Carga y transporte',
  locationErrorMessage: 'Existen ubicaciones con coordenadas no válidas. Los marcadores no podrán mostrarse en el mapa correctamente',
  maxKilograms: 'Capacidad máxima: ',
  maxVolume: 'Volumen máximo: ',
  moneySign: '$',
  moneyWord: ' pesos (MXN)',
  negotiationSubtitle: 'Negociación',
  noActiveNotification: 'Notificación de caducidad desactivada',
  noAssign: 'Sin asignar',
  noAvailable: 'No disponible',
  noDelivery: 'Sin repartos',
  noInfo: 'Sin información',
  noResultDirections: 'Actualmente no hay una ruta sugerida para este viaje',
  offerSubtitle: 'Contraofertas',
  origin: 'Origen: ',
  originTitle: 'Origen ',
  publish: 'Publicar',
  publishAndWait: 'Quiero recibir ofertas',
  rate: 'Tarifa: ',
  receiveCounterOffers: 'Quiero recibir contraofertas de transportistas',
  receiverOffers: 'receiveOffers',
  reference: 'Referencia Interna: ',
  requiresManeuvering: 'Requiere maniobras',
  route: 'Ruta',
  routeDestination: 'No se puede calcular la distancia estimada para mas de 25 paradas',
  routeDetails: 'Detalles de Ruta',
  shipmentRequest: 'Fecha de expiración',
  shipmentInformationSubtitle: 'DATOS DE VEHÍCULO Y TRANSPORTISTA',
  specialRequerimentsSubtitle: 'REQUERIMIENTOS ESPECIALES',
  specialAdditionalsSubtitle: 'REQUERIMIENTOS ADICIONALES',
  specialRequerimentstitle: 'Requisitos especiales',
  stopTabTitle: 'Paradas',
  timeEstimedForRute: 'ETA: ',
  timeRouteDestination: 'No se puede calcular el tiempo estimado para mas de 25 paradas',
  title: 'Detalle de solicitud',
  titleOffers: 'Ofertas de solicitud a',
  titleOffersSub: 'Selecciona una de las ofertas de transporte',
  totalDistance: 'Distancia total de ruta: ',
  transportSubtitle: 'Transportista',
  typeLoad: 'Tipo de carga',
  typeVehicle: 'Tipo de vehículo',
  unavailableMarketPlaceData: 'Sin ofertas',
  vehicleAndMerchandise: 'Carga y Transporte',
  volume: ' m³',
  volumetry: 'Volumen total',
  volumetryInformationSubTitle: 'VOLUMETRÍA MÁXIMA DEL VEHÍCULO SOLICITADO',
  volumetrySubtitle: 'Volumetría de Mercancía',
  withoutExpiration: 'Sin caducidad configurada',
  withoutReference: 'Sin referencia interna',
  withoutSpecialRequirements: 'Sin requisitos especiales',
};

export const SpecialRequirementsViewTags: SpecialRequirementsViewTag = {
  address: 'Dirección',
  municipality: 'Municipio',
  postalCode: 'Código postal',
  state: 'Estado',
  stop: 'Parada',
  suburb: 'Colonia'
};
