<mat-grid-list cols="4" rowHeight="1:.15">
  <mat-grid-tile colspan="3" rowspan="1">
    <span class="dialog-title">
      <strong>
        <p>{{ data.title }}</p>
      </strong>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1" rowspan="1">
    <mat-icon class="dialog-close-icon" (click)="onClickClose()">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>
<br>
<mat-grid-list cols="3" rowHeight="1:.20">
  <mat-grid-tile colspan="3" rowspan="1" class="height-resume">
    <span class="dialog-resume">
      <p>{{ data.resume }}</p>
    </span>
  </mat-grid-tile>
</mat-grid-list>
<mat-grid-list cols="3" rowHeight="1:.20" class="overWritePadding">
  <mat-grid-tile colspan="1" rowspan="5" class="image-overwrite">
    <img src="{{data.iconPath}}" width="95%" height="95%" alt="Dialog image">
  </mat-grid-tile>
  <mat-grid-tile colspan="2" rowspan="2">
    <span class="text text-direction">{{ data.question }}</span>
  </mat-grid-tile>
  <mat-grid-tile class="button-container">
    <button
      mat-raised-button
      id="openNewTapButton"
      cdkFocusInitial
      class="on-click-button1 button-dialog"
      (click)="onClickButton1()">
      {{ data.textButton1 }}
    </button>
    <button
      mat-raised-button
      id="acceptButton"
      cdkFocusInitial
      class="on-click-button2 button-dialog"
      (click)="onClickButton2()">
      {{ data.textButton2 }}
    </button>
  </mat-grid-tile>
</mat-grid-list>