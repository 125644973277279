<div id="print-section" class="style-print-section">
  <div class="general-container">
    <p>
      {{ 'evidenceFolioFormatTags.creationDate' | translate }}
      <strong>{{ evidenceFolio.timestamp | date: 'dd/MM/yyyy' }}</strong>
    </p>
    <div class="div-header-container">
      <div class="div-logo-container">
        <img
          alt="Logo"
          src='{{ logoUrl }}'
          id="logo"
          (error)="setDefaultImage($event);">
      </div>

      <div name="title-container" id="title-container" class="div-title-container">
        <div class="div-right-title">
          <p>{{ 'evidenceFolioFormatTags.title' | translate }}</p>
        </div>

        <div class="div-left-title">
          <span>{{ evidenceFolio.folio }}</span>
        </div>
      </div>

      <div id="div-barcode">
        <ngx-barcode
          id="barcode"
          [bc-value]="evidenceFolio.folio"
          [bc-display-value]="false"
          [bc-width]="1"
          [bc-font-size]="10"
          [bc-height]="45"
          [bc-margin]="0"
          [bc-margin-top]="0"
          [bc-margin-bottom]="0"
          [bc-margin-left]="5"
          [bc-margin-right]="0">
        </ngx-barcode>
      </div>
    </div>

    <div class="div-summary-info">
      <div id="shipper-data">
        <div class="partial-text-divider">
          <div class="subtitle">
            <span>{{ 'evidenceFolioFormatTags.shipperInfo' | translate }}</span>
          </div>
          <div class="divider">
            <hr>
          </div>
        </div>

        <table aria-describedby="table-shipper-data" class="table-shipper-data" *ngIf="shipperData">
          <tr>
            <th id="shipperName-header"> {{ 'evidenceFolioFormatTags.shipperName' | translate }} </th>
            <th id="fiscalAddress-header"> {{ 'evidenceFolioFormatTags.fiscalAddress' | translate }}</th>
          </tr>
          <tr>
            <td> {{ shipperData.nombre }} </td>
            <td> {{ shipperData.direccionFiscal.calle }} </td>
          </tr>
          <tr>
            <th id="rfc-header"> {{ 'evidenceFolioFormatTags.rfc' | translate }}</th>
          </tr>
          <tr>
            <td> {{ shipperData.rfc }} </td>
          </tr>
        </table>
      </div>

      <div id="merchandise-data">
        <div class="partial-text-divider">
          <div class="subtitle">
            <span>{{ 'evidenceFolioFormatTags.merchandiseInfo' | translate }}</span>
          </div>
          <div class="divider">
            <hr>
          </div>
        </div>

        <table aria-describedby="table-resume-indicator" class="table-resume-indicators">
          <th id="th-resume-indicators"></th>
          <tr>
            <td><img alt="Pieces" src='../../../assets/pieces.svg'/></td>
            <td class="number-indicator">{{ evidenceFolio.totalPieces }}</td>
            <td class="text-indicator">{{ 'evidenceFolioFormatTags.pieces' | translate }}</td>
            <td><img alt="Boxes" src='../../../assets/boxes.svg'/></td>
            <td class="number-indicator">{{ evidenceFolio.totalBoxes }}</td>
            <td class="text-indicator">{{ 'evidenceFolioFormatTags.boxes' | translate }}</td>
            <td><img alt="Pallets" src='../../../assets/pallets.svg'/></td>
            <td class="number-indicator">{{ evidenceFolio.totalPallets }}</td>
            <td class="text-indicator">{{ 'evidenceFolioFormatTags.pallets' | translate }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div *ngFor="let row of evidenceFolio.rows; let i=index">
      <div id="evidence-info">
        <table mat-table aria-describedby="table-evidence-info" [dataSource]="dataSource.filteredData[i].orders">

          <ng-container matColumnDef="shipmentId">
            <th
              id="shipmentId"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.shipmentId' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.shipmentId }}
            </td>
          </ng-container>

          <ng-container matColumnDef="tripType">
            <th
              id="tripType"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.tripType' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.tripType }}
            </td>
          </ng-container>

          <ng-container matColumnDef="serviceType">
            <th
              id="serviceType"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.serviceType' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.serviceType }}
            </td>
          </ng-container>

          <ng-container matColumnDef="orderId">
            <th
              id="orderId"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.orderId' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.orderFolio }}
            </td>
          </ng-container>

          <ng-container matColumnDef="clientOrderNumber">
            <th
              id="clientOrderNumber"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.orderClientId' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.orderId }}
            </td>
          </ng-container>

          <ng-container matColumnDef="internalReference">
            <th
              id="internalReference"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.internalReference' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.internalReference }}
            </td>
          </ng-container>

          <ng-container matColumnDef="invoice">
            <th
              id="invoice"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.invoice' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.invoice | nodata }}
            </td>
          </ng-container>

          <ng-container matColumnDef="order">
            <th
              id="orderGrouper"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.orderNumber' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.orderGrouper }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsEvidence; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsEvidence;"></tr>
        </table>
      </div>

      <div id="additional-info">
        <table mat-table aria-describedby="table-additional-info" [dataSource]="dataSource.filteredData[i].details">
          <ng-container matColumnDef="account">
            <th
              id="account"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.account' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.account }}
            </td>
          </ng-container>

          <ng-container matColumnDef="origin">
            <th
              id="origin"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.origin' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.origin.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="destinationName">
            <th
              id="destinationName"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.destinationName' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.destination.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="destinationAddress">
            <th
              id="destinationAddress"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.destinationAddress' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.destination.address }}
            </td>
          </ng-container>

          <ng-container matColumnDef="municipality">
            <th
              id="municipality"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.municipality' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.destination.municipality }}
            </td>
          </ng-container>

          <ng-container matColumnDef="state">
            <th
              id="state"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.state' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.destination.state }}
            </td>
          </ng-container>

          <ng-container matColumnDef="carrier">
            <th
              id="carrier"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.carrier' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.carrier.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="deliveryDate">
            <th
              id="deliveryDate"
              mat-header-cell
              *matHeaderCellDef>
              {{ 'evidenceFolioFormatTags.deliveryDate' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.deliveryDate | date: 'dd/MM/yyyy' }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsAdditional; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsAdditional;"></tr>
        </table>
      </div>

      <div id="resume-by-row">
        <table aria-describedby="table-resume-indicator" class="table-resume-indicators">
          <th id="th-resume-indicators"></th>
          <tr>
            <td><img alt="Pieces" src='../../../assets/pieces.svg'/></td>
            <td class="number-indicator">{{ row.totalPiecesByRow }}</td>
            <td class="text-indicator">{{ 'evidenceFolioFormatTags.pieces' | translate }}</td>
            <td><img alt="Boxes" src='../../../assets/boxes.svg'/></td>
            <td class="number-indicator">{{ row.totalBoxesByRow }}</td>
            <td class="text-indicator">{{ 'evidenceFolioFormatTags.boxes' | translate }}</td>
            <td><img alt="Pallets" src='../../../assets/pallets.svg'/></td>
            <td class="number-indicator">{{ row.totalPalletsByRow }}</td>
            <td class="text-indicator">{{ 'evidenceFolioFormatTags.pallets' | translate }}</td>
          </tr>
        </table>
      </div>
  </div>
</div>
