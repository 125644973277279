export enum VariableCharges {
  Parcel = 'Paquetería',
  Specials = 'Especiales',
  Tr2 = 'TR2',
  MerchInsurence = 'Seguro de Mercancía',
  ExternalManeuver = 'Maniobra externa',
  Toll = 'Peaje',
  Stays = 'Estadías',
  Pension = 'Pensión',
  Return = 'Devolución',
  Rejection = 'Rechazo',
  Distribution = 'Reparto',
  Freight = 'Flete'
}
