import { isEmpty as _isEmpty } from 'lodash';

import { Component, Input, OnInit } from '@angular/core';

import { WaybillResponse } from '../../interfaces/couriers/waybill-response.interface';
import { OrderViewsGuides } from '../../interfaces/order-reception';
import { MatchedGuidesResponse } from '../../interfaces/shipment-request';
import { CourierService } from '../../providers/courier/courier-service.provider';
import { OrderProvider } from '../../providers/orders/order-provider.service';
import { ShipmentProvider } from '../../providers/shipments/shipment-provider.service';
import { OrderLabelViewLabels } from './constants/order-label-view-label';
import { OrderLabelViewConstants } from './constants/order-label-view.constants';
import { OrderLabelViewTags } from './interface/order-label-view-tags.interface';
import { OrderLabelViewTableRow } from './interface/order-label-view.interface';

@Component({
  selector: 'app-order-label-view',
  templateUrl: './order-label-view.html',
  styleUrls: ['../../app.component.scss', './order-label-view.scss']
})
export class OrderLabelViewComponent implements OnInit {
  @Input() public folio: string;
  @Input() public tenantId: string;
  @Input() public isExpanded: boolean;
  @Input() public isHeader: boolean;

  public courierLabelsTable: Array<OrderLabelViewTableRow>;
  public labels: OrderLabelViewTags;
  public matchedLabelsTable: Array<OrderLabelViewTableRow>;
  public whiteLabelsTable: Array<OrderLabelViewTableRow>;

  public maxWidth: string;
  public whiteTdwidth: string;
  public courierTdwidth: string;
  public matchedTdwidth: string;

  constructor(
    private orderProvider: OrderProvider,
    private courierService: CourierService,
    private shipmentProvider: ShipmentProvider
  ) {
    this.labels = OrderLabelViewLabels;
  }

  public ngOnInit(): void {
    this.getWhiteGuides();
    this.getCourierGuides();
    this.getmatchedGuides();

    this.isHeader = this.isHeader ?? true;
    this.whiteTdwidth = this.getPercentageTd(OrderLabelViewConstants.MAX_WHITE_LABELS);
    this.courierTdwidth = this.getPercentageTd(OrderLabelViewConstants.MAX_COURIER_LABELS);
    this.matchedTdwidth = this.getPercentageTd(OrderLabelViewConstants.MAX_MATCHED_LABELS);
    this.maxWidth = OrderLabelViewConstants.MAX_WIDTH_TABLE.toString() + OrderLabelViewLabels.percentage;
  }

  /**
   * @description get white guides and create cols and rows for table
   */
  private async getWhiteGuides(): Promise<void> {
    const whiteGuides: Array<OrderViewsGuides> = await this.orderProvider.getGuidesByFolio(this.folio);
    const whiteGuidesFiltered = whiteGuides.length ? whiteGuides.filter(guide => guide.isActive) : [];
    this.whiteLabelsTable = [];

    if (whiteGuidesFiltered && whiteGuidesFiltered.length) {
      const whiteLabels = whiteGuidesFiltered.map((guide) => guide.subfolio);
      this.whiteLabelsTable = this.groupColumns(whiteLabels, OrderLabelViewConstants.MAX_WHITE_LABELS);
    }
  }

  /**
   * @description get courier guides and create cols and rows for table
   */
  private async getCourierGuides(): Promise<void> {
    const courierGuides: Array<WaybillResponse> = await this.courierService.getCourierGuidesByOrder(this.tenantId, this.folio);

    this.courierLabelsTable = [];

    if (courierGuides && courierGuides.length > 0) {
      const courierLabels: Array<string> = [];

      for (const guide of courierGuides) {
        const childs = JSON.parse(guide.childWaybills.toString());
        const waybills = childs.map((waybill) => waybill.waybillNumber);
        waybills.forEach((waybill) => { courierLabels.push(waybill); });
      }

      this.courierLabelsTable = this.groupColumns(courierLabels, OrderLabelViewConstants.MAX_COURIER_LABELS);
    }
  }

  /**
   * @description get matches between white guides and courier guides, create cols and rows of matched labels table
   */
  private async getmatchedGuides(): Promise<void> {

    this.matchedLabelsTable = [];
    const matchesGuides: Array<MatchedGuidesResponse> = await this.shipmentProvider.getMatchedGuidesByOrder(this.tenantId, this.folio);

    if (matchesGuides && matchesGuides.length > 0) {
      const matched: Array<string> = matchesGuides.map((match) => match.whiteGuide + OrderLabelViewConstants.HYPEN + match.courierGuide);

      this.matchedLabelsTable = this.groupColumns(matched, OrderLabelViewConstants.MAX_MATCHED_LABELS);
    }
  }

  /**
   * @description Cluster cols into rows
   * @param {Array<string>} list array of lebels of matched labels
   * @param {number} maxColumns max num of columns for row
   * @returns {Array<OrderLabelViewTableRow>} Array of group cols
   */
  private groupColumns(list: Array<string>, maxColumns: number): Array<OrderLabelViewTableRow> {
    const rows: Array<OrderLabelViewTableRow> = [];
    let columns: Array<string> = [];

    list.forEach(column => {
      if (columns.length > maxColumns) {
        const columnsItem = {
          columns: columns
        };

        rows.push(columnsItem);

        columns = [];
        columns.push(column);
      } else {
         columns.push(column);
      }
    });

    if (columns.length > 0) {
      const columnsItem = {
        columns: columns
      };

      if (columns.length < maxColumns) {
        const columnsDiff = maxColumns - columns.length;
        let column = 0;

        for (column = 0; column < columnsDiff; column++) {
          columns.push(OrderLabelViewLabels.empty_string);
        }
      }

      rows.push(columnsItem);
    }

    return rows;
  }

  /**
   * @description Calculate cell's width
   * @param {number} numCells max num of columns for row
   * @returns {string} width of cell in percentage
   */
  private getPercentageTd(numCells: number): string {
    const width = Math.floor(OrderLabelViewConstants.MAX_WIDTH_TABLE / numCells);
    const widthInPercent = width.toString() + OrderLabelViewLabels.percentage;

    return widthInPercent;
  }
}
