import { Component, OnInit } from '@angular/core';

import { DialogImportationProcessLabels } from './dialog-importation-process-labels';

@Component({
  selector: 'app-dialog-importation-process',
  templateUrl: './dialog-importation-process.component.html',
  styleUrls: ['./dialog-importation-process.component.scss']
})
export class DialogImportationProcessComponent implements OnInit {
  public labels;
  constructor() { }

  /**
   * @description Angular Lifecycle for component initialization
   */
  public ngOnInit(): void {
    this.labels = DialogImportationProcessLabels;
  }

}
