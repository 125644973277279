import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { ShipmentRequest } from '../../interfaces/marketplace-shipper/shipment-request';
import { SpecialRequirementsArray } from '../../constants/marketplace/special-requirements.constants';
import { SpecialShipmentRequirements } from '../../interfaces/index';

const shipmentRequestApiUrl = environment.shipmentRequestApiUrl;

@Injectable()
export class MarketplaceService {
  public activeNotification: boolean;
  public dateExpirationIsSelected: boolean;
  public dateShipmentRequest: Date;
  public requireSpecialRequirements: boolean;
  public shipmentRequest: boolean;
  public specialRequirements: Array<SpecialShipmentRequirements>;
  public specialRequirementsInput: string;

  constructor(private http: HttpClient, private _appService: AppService) {
    this.requireSpecialRequirements = false;
    this.specialRequirements = SpecialRequirementsArray;
  }

  /**
   * @description request to endpoint shipper shipment request
   * @param {ShipmentRequest} body
   * @returns {Promise<ShipmentRequest>} Response to Shipper requests
   */
  public async sendToBiis(body: ShipmentRequest): Promise<ShipmentRequest> {
    const tenantId = this._appService.getShipperOid();
    body['lastUpdate'] = new Date();

    return await this.http.post<ShipmentRequest>(shipmentRequestApiUrl + '/shipmentRequests/', body).toPromise();
  }

  /**
   * @description updates the information from a shipment request of the offers
   * @param {ShipmentRequest} body information of the shipment request to update
   * @returns {ShipmentRequest} response of the shipment request to update
   */
  public async updateRequest(body: ShipmentRequest): Promise<ShipmentRequest> {
    body['lastUpdate'] = new Date();
    return await this.http.post<ShipmentRequest>(shipmentRequestApiUrl + '/shipmentRequests/offer/', body).toPromise();
  }
}
