import { Component, OnInit, Input } from '@angular/core';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html'
})
export class CarouselComponent implements OnInit {
  public actualPosition: number;
  public icon: string;
  public carouselSelectVisible: boolean;
  public carouselVisible: boolean;

  @Input() images: Array<string>;

  constructor(private toast: ToastrAlertsService) { }

  ngOnInit() {
    this.actualPosition = 0;
    if (this.images === undefined) {
      this.toast.warningAlert('No existen imágenes por mostrar');
      this.carouselVisible = false;
    } else {
    if (this.images.length <= 5) {
        this.carouselVisible = true;
        this.carouselSelectVisible = true;
      } else {
        this.carouselVisible = true;
        this.carouselSelectVisible = false;
      }
    }
  }

  public nextPicture() {
    if (this.actualPosition >= this.images.length - 1) {
      this.actualPosition = 0;
    } else {
      this.actualPosition++;
    }
  }

  public previousPicture() {
    if (this.actualPosition <= 0) {
      this.actualPosition = this.images.length - 1;
    } else {
      this.actualPosition--;
    }
  }

  public onClickSelect(indexPosition: any) {
    this.actualPosition = indexPosition;
  }
}
