import { Sheet2JSONOpts } from 'xlsx';

import { DETAIL_INFO_FORM } from './order-bulk-load-forms/detail-info-form';
import { GENERAL_INFO_FORM } from './order-bulk-load-forms/general-info-form';
import { LOCATION_INFO_FORM } from './order-bulk-load-forms/location-info-form';
import { MERCHANDISE_INFO_FORM } from './order-bulk-load-forms/merchandise-info-form';
import { PRODUCT_COFEPRIS_FORM } from './order-bulk-load-forms/product-cofepris-form';
import { PRODUCT_INFO_FORM } from './order-bulk-load-forms/product-info-form';

/**
 * Order Bulk Load Component Properties.
 */
export class OrderBulkLoadProperties {
  public static acceptedDropzoneFileFormats = 'application/*';
  public static acceptedFileFormats = '.xlsx, .xls';
  public static allowedFileFormats = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  public static byDestinationIDInputKeys = [
    'locationInfo.destinationID'
  ];

  public static byLocationInputKeys = [
    'locationInfo.destinationName',
    'locationInfo.destinationAddress',
    'locationInfo.destinationZIP',
    'locationInfo.destinationMunicipality',
    'locationInfo.destinationState',
    'locationInfo.destinationLatitude',
    'locationInfo.destinationLongitude',
    'locationInfo.destinationSettlement'
  ];

  public static byProductInputKeys = [
    'productInfo.productName',
    'productInfo.productUnitKey',
    'productInfo.productWeight',
    'productInfo.productVolume',
    'productInfo.productIsDangerousMaterial'
  ];

  public static byProductsCodeInputKeys = [
    'productInfo.productLot',
    'productInfo.productExpirationDate'
  ];

  public static byProductIDInputKeys = [
    'productInfo.productCode',
    'productInfo.productTotal'
  ];

  public static codeProperty = 'code';
  public static collapsedHeight = '2.5em';
  public static createGuidesBy = {
    boxes: {
      name: 'cajas',
      code: 2
    },
    pallets: {
      name: 'tarimas',
      code: 3
    },
    pieces: {
      name: 'piezas',
      code: 1
    }
  };

  public static dialogCreateConfigWidth = '600px';
  public static dialogResetConfigWidth = '600px';
  public static dialogSeeConfigsWidth = '900px';
  public static dropzoneIconAlt = 'Dropzone Icon';
  public static dropzoneIconPath = '../../../assets/excel-archive-1.svg';
  public static errorCodes = {
    errorDuplicatedId: 'errorDuplicatedId',
    identifierRequired: 'identifierRequired',
    prodErrorNocode: 'prodErrorNocode'
  };

  public static expandedHeight = '2.5em';
  public static formDetailInfoInputs = DETAIL_INFO_FORM;
  public static formGeneralInfoInputs = GENERAL_INFO_FORM;
  public static formLocationInfoInputs = LOCATION_INFO_FORM;
  public static formMerchandiseInfoInputs = MERCHANDISE_INFO_FORM;
  public static formProductInfoInputs = PRODUCT_INFO_FORM;
  public static formProductCofeprisInputs = PRODUCT_COFEPRIS_FORM;
  public static invalidFormControlClassName = 'label-select-invalid';
  public static isByProductCodeLength = 2;
  public static locationBy = { destinationId: 'destinationId', location: 'location' };
  public static maxFileSize = 25000000; // 25 MB
  public static productBy = { product: 'product', productCode: 'productCode' };
  public static productSliceStart = 1;
  public static productSliceEnd = 5;
  public static resetConfigWarningActions = {
    headerCheckChange: 'headerCheckChange',
    loadConfig: 'loadConfig',
    removeFile: 'removeFile',
    sheetChange: 'sheetChange'
  };

  public static rowNumberHeader = '__rowNum__';
  public static sheetToJSONOptions: Sheet2JSONOpts = { defval: null, raw: true };
  public static sheetToJSONNoHeaderOptions: Sheet2JSONOpts = { defval: null, header: 'A' };
  public static splitProductSeparators = {
    hyphenSpace: ' - ',
    productsString: 'product(s)'
  };
  public static stepKeys = { fileUpload: 0, generalDetailInfo: 1, locationMerchandiseInfo: 2 };
  public static sufixInputLabel = 'InputLabel';
  public static validFormControlClassName = 'label-select-valid';
  public static viewOrdersRoute = '/order/view-orders';
}
