import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { catchError } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { AuthService } from '../authentication/auth.service';
import { DialogSessionExpiredComponent } from '../components/dialog/dialog-session-expired/dialog-session-expired.component';
import { HTTPCodes } from '../enums/http-codes';
import { LOCALSTORAGE_KEYS } from '../constants/localstorage/localstorage.constants';
import { LocalStorageService } from '../services/utils/local-storage.service';

const EXPIRED_TOKEN = 'EXPIRED_TOKEN';
const INVALID_TOKEN = 'INVALID_TOKEN';
const NO_TOKEN = 'NO_TOKEN';
const DIALOG_WIDTH = '680px';

/**
 * @class
 * @description - Verifies if server responses are authorized.
 */
@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  /**
   * @description - Verifies if route to navigate is in allowed routes of the user.
   * @param { AuthService } authServices - The log prefix.
   */
  constructor(
    private authServices: AuthService,
    private dialog: MatDialog,
    private localStorage: LocalStorageService
  ) {
    this.localStorage.removeItem(LOCALSTORAGE_KEYS.SESSION_EXPIRED_TOKEN);
  }

  /**
   * @description - Initialize a Keycloak instance to connect with Supplynet SSO.
   * @param {HttpRequest<any>} request - The log prefix.
   * @param {HttpHandler} next - The log prefix.
   *
   * @returns {Observable<HttpEvent<any>>} - Returns false or thrown an error.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error.status &&
          (error.status === HTTPCodes.UNAUTHORIZED ||
            isEqual(error.error.message, INVALID_TOKEN) ||
            isEqual(error.error.message, NO_TOKEN) ||
            isEqual(error.error.message, EXPIRED_TOKEN))
        ) {
          this.showSessionExpiredAlert();
        }

        return throwError(error);
      })
    );
  }

  /**
   * @description - Shows an expired session alert before logout current user.
   *
   * @returns { Promise<void> }
   */
  public async showSessionExpiredAlert(): Promise<void> {
    const alreadyNotified = this.localStorage.getItem(
      LOCALSTORAGE_KEYS.SESSION_EXPIRED_TOKEN
    );

    if (alreadyNotified) {
      return;
    }

    const dialogRef = this.dialog.open(DialogSessionExpiredComponent, {
      disableClose: true,
      width: DIALOG_WIDTH
    });

    this.localStorage.saveItem(LOCALSTORAGE_KEYS.SESSION_EXPIRED_TOKEN, true);
    await dialogRef
      .afterClosed()
      .pipe(
        map(async (result): Promise<void> => {
          if (result) {
            this.localStorage.removeItem(
              LOCALSTORAGE_KEYS.SESSION_EXPIRED_TOKEN
            );
            this.authServices.teplogout(true);
            // this.ssoAuthService.userLogout();
            // this.router.navigateByUrl('/supplynet-login');
          }
        })
      )
      .toPromise();
  }
}
