import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { SearchMatSelectEvent, SearchMatSelectResults } from '../interfaces/search-mat-select';

@Injectable()
export class CommunicationService {
  private carrierCreateSubject = new Subject<any>();
  private driverCreateSubject =  new Subject<any>();
  private evidenceInfoSubject = new Subject<any>();
  private evidenceReleaseSubject = new Subject<void>();
  private freightRateInfoSubject = new Subject<any>();
  private incidenceRegisteredSubject = new Subject<any>();
  private incidenceRegisterSubject = new Subject<any>();
  private manualDeliverySubject = new Subject<any>();
  private roleCreateSubject = new Subject<any>();
  private shipmentEditInfoSubject = new Subject<any>();
  private userCreateSubject = new Subject<any>();
  private searchMatSelectSubject = new Subject<SearchMatSelectEvent>();
  private searchMatSelectionSubject = new Subject<Array<SearchMatSelectResults>>();
  private clearSelectionSubject = new Subject<Array<SearchMatSelectResults>>();

  /**
   * @description Sends signal for open and close evidence info.
  */
  public showEvidenceInfoByShipment() {
    this.evidenceInfoSubject.next();
  }

  public showEvidenceInfoByParams() {
    this.evidenceReleaseSubject.next();
  }

  public showFreightRateInfo() {
    this.freightRateInfoSubject.next();
  }

  public showShipmentEdit() {
    this.shipmentEditInfoSubject.next();
  }

  public showManualDelivery() {
    this.manualDeliverySubject.next();
  }

  public showIncidenceRegister() {
    this.incidenceRegisterSubject.next();
  }

  public showIncidenceRegistered() {
    this.incidenceRegisteredSubject.next();
  }

  public showUserCreate() {
    this.userCreateSubject.next();
  }

  public showRoleCreate() {
    this.userCreateSubject.next();
  }

  public showDriverCreate() {
    this.driverCreateSubject.next();
  }

  public showCarrierCreate() {
    this.carrierCreateSubject.next();
  }

  /**
   * @description Emit mat selection search
   * @param {SearchMatSelectEvent} searchEvent Search event to be emitted
   */
  public searchMatSelectEvent(searchEvent: SearchMatSelectEvent): void {
    this.searchMatSelectSubject.next(searchEvent);
  }

  /**
   * @description Emit mat selection.
   * @param {SearchMatSelectResults} results - mat selection to be emitted.
   */
  public searchMatSelection(results: Array<SearchMatSelectResults>): void {
    this.searchMatSelectionSubject.next(results);
  }

  /**
   * @description Emit mat selection to clear searchs in the component filters.
   * @param {SearchMatSelectResults} results - mat selection to be emitted.
   */
  public resetComponent(results: Array<SearchMatSelectResults>): void {
    this.clearSelectionSubject.next(results);
  }

  /**
   * @description Subscribes to evidence info subject.
  */
  public showEvidenceByShipmentSubscribe(): Observable<any> {
    return this.evidenceInfoSubject.asObservable();
  }

  public showEvidenceByParamsSubscribe(): Observable<void> {
    return this.evidenceReleaseSubject.asObservable();
  }

  public freightRateCreateSubscribe(): Observable<any> {
    return this.freightRateInfoSubject.asObservable();
  }

  public showShipmentEditSubscribe(): Observable<any> {
    return this.shipmentEditInfoSubject.asObservable();
  }

  public showManualDeliverySubscribe(): Observable<any> {
    return this.manualDeliverySubject.asObservable();
  }

  public showIncidenceRegisterSubscribe(): Observable<any> {
    return this.incidenceRegisterSubject.asObservable();
  }

  public showIncidenceRegisteredSubscribe(): Observable<any> {
    return this.incidenceRegisteredSubject.asObservable();
  }

  public showUserCreateSubscribe(): Observable<any> {
    return this.userCreateSubject.asObservable();
  }

  public showRoleCreateSubscribe(): Observable<any> {
    return this.roleCreateSubject.asObservable();
  }

  public showDriverCreateSubscribe(): Observable<any> {
    return this.driverCreateSubject.asObservable();
  }

  public showCarrierCreateSubscribe(): Observable<any> {
    return this.carrierCreateSubject.asObservable();
  }

  /**
   * @description Create Mat selection search event Observable
   * @returns Mat selection search event Observable
   */
  public searchMatSelectSubscribe(): Observable<SearchMatSelectEvent> {
    return this.searchMatSelectSubject.asObservable();
  }

  /**
   * @description Create Mat selection event Observable
   * @returns Mat selection event Observable
   */
  public searchMatSelectionSubscribe(): Observable<Array<SearchMatSelectResults>> {
    return this.searchMatSelectionSubject.asObservable();
  }

  /**
   * @description Create Mat selection event Observable to clear component search filter.
   * @returns Mat selection event Observable.
   */
  public resetComponentSubscribe(): Observable<Array<SearchMatSelectResults>> {
    return this.clearSelectionSubject.asObservable();
  }
}
