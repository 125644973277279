import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogInputData } from '../../../interfaces/index';
import { DialogSessionExpiredProperties, DialogSessionExpiredPropertiesTags } from './dialog-session-expired.properties';

@Component({
  selector: 'app-dialog-session-expired',
  templateUrl: './dialog-session-expired.component.html',
  styleUrls: ['./dialog-session-expired.component.scss', '../../../app.component.scss']
})
export class DialogSessionExpiredComponent {
  public properties: DialogSessionExpiredPropertiesTags;

  constructor(
    public dialogRef: MatDialogRef<DialogSessionExpiredComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogInputData
  ) {
    this.properties = DialogSessionExpiredProperties;
  }

  /**
   * @description Catch signOut button action to close modal
   */
  public onSignOut(): void {
    this.dialogRef.close(true);
  }
}
