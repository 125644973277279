<mat-grid-list cols="3" rowHeight="1:.25">
  <mat-grid-tile colspan="2" rowspan="1">
    <span class="dialog-title">
      <p>{{data.title}}</p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1" rowspan="1">
    <mat-icon
      style="cursor: pointer"
      class="dialog-close-icon"
      (click)="onClickClose()">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>
<div class="dialog-resume">
  {{data.resume}}
</div>
<mat-grid-list cols="3" rowHeight="1:.40">
  <mat-grid-tile colspan="1" rowspan="2">
    <img *ngIf="data.imgRef" src="{{data.imgRef}}" width="95%" height="95%" alt="Dialog image">
  </mat-grid-tile>
  <mat-grid-tile colspan="2" rowspan="1">
    <p class="text">{{data.question}}</p>
  </mat-grid-tile>
  <mat-grid-tile class="button-container">
    <button mat-button class="secondary-button align-left" (click)="onClickButton1()">{{data.button1}}</button>
    <button
      mat-button
      cdkFocusInitial
      class="primary-button align-right"
      (click)="onClickButton2()">
      {{data.button2}}
    </button>
  </mat-grid-tile>
</mat-grid-list>
