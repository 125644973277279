export enum PaymentType {
  originDestination = 'Origen - Destino',
  fixed = 'Fijo',
  variable = 'Variable',
}

export enum ORDER_STATUS {
  assigned = 'Asignada',
  cancelled = 'Cancelada',
  delivered = 'Entregada',
  noEvidence = 'Sin Evidencia',
  inTransit = 'En Transito',
  partial = 'Parcial',
  rejected = 'Rechazada',
  released = 'Liberada',
  unassigned = 'Sin Asignar',
  notDelivered = 'No Entregada'
}

export enum FREQUENCY {
  bimonthly = 'Bimestral',
  biweekly = 'Quincenal',
  immediate = 'Inmediato',
  monthly = 'Mensual',
  quarterly = 'Trimestral',
  weekly = 'Semanal'
}

export const ACCOUNT_BILLING_SCHEMES_CONSTANTS = {
  AMOUNT_MAX: 999999999.999,
  CONFIRM: 'Confirm',
  COST_FIELD_NAME: 'cost',
  CREDITDAYS_MAX: 999,
  CREDIT_DAYS: 'creditDays',
  DEFAULT_PAGE_SIZE: 25,
  DEFAULT_PAGE_SIZE_TARIFF: 10,
  ERROR_CODE_DUPLICATE: 409,
  FIXED_CHARGE: 'fixedCharge',
  FIXED_CHARGE_FEE: 'fixedChargeFee',
  ICON_DELETE_DIALOG: '../../../assets/role-delete.svg',
  ICON_DIALOG: '../../../assets/edit_icon_dialog.svg',
  ICON_EDIT_DIALOG: '../../../assets/editPackage.svg',
  INVALID_COST: 'invalid',
  INVALID_DECIMALS_COST: 'maxDecimalsReached',
  INVOICE_STATUS_CONTROL_NAME: 'invoiceStatus',
  STRING_ZERO: '0',
  PAGE_SIZE_OPTIONS: [25, 50, 100],
  PAGE_SIZE_OPTIONS_TARIFF: [10, 25, 50],
  PERCENTAGE_MAX: 100,
  PRE_INVOICE_STATUS_CONTROL_NAME: 'preInvoiceStatus',
  PROVISION_STATUS_CONTROL_NAME: 'provisionStatus',
  TIMEOUT: 109,
  TRIP_TYPE: 'tripType',
  TRIP_TYPE_VIEW: 'tripTypeView',
  WIDTH_DIALOG: '650px'
}
