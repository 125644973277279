import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { AppConstants } from '../../constants/app-constants.constants';
import { BillingSheetService } from '../../services/billing-sheet.service';
import { DateToolsService } from '../../services/utils/date-tools.service';
import { GRID_COLUMNS, GRID_COLUMNS_PROPERTIES } from '../../pages/costs/register-freight-invoices/register-freight-invoices.properties';
import { IBillingSheetPost } from '../../interfaces';
import { PdfLabels } from '../../interfaces/label/register-freight-invoices';
@Component({
  selector: 'app-register-freight-invoices-pdf',
  styleUrls: ['../../app.component.scss', './register-freight-invoices-pdf.scss'],
  templateUrl: './register-freight-invoices-pdf.component.html'
})

/**
 * Component used to build a table with freight invoices received and export to pdf file.
 */
export class RegisterFreightInvoicesPdfComponent implements OnInit {
  @Input() public data: Array<IBillingSheetPost>;
  @Input() public gridHeaders: Array<string>;
  @Input() public gridHeadersLabels: PdfLabels;
  @Input() public isInvoiceRegisterBySaleSheet?: boolean;

  public dataSource: MatTableDataSource<IBillingSheetPost>;
  public displayedColumns: Array<string>;
  public moneySign: string;
  public noData: string;
  public numberDigits: string;
  public numberFormat: string;

  /**
   * @description Builds an instance of necessaries services for correctly work of component.
   * @param {BillingSheetService} billingSheetService - Service with differents utils to use related to billing sheets.
   * @param {DateToolsService} dateToolsService - Service with differents methods to format properties of billing sheets.
   */
  constructor(
    private billingSheetService: BillingSheetService,
    private dateToolsService: DateToolsService,
  ) {
    this.moneySign = AppConstants.CURRENCY_SIGN;
    this.numberDigits = AppConstants.NUMBER_DIGITS;
    this.numberFormat = AppConstants.US_NUMBER_FORMAT;
    this.isInvoiceRegisterBySaleSheet = false;
  }

  /**
   * @description Angular lifecycle hook for component init.
   */
  public ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);

    if (this.isInvoiceRegisterBySaleSheet) {
      this.displayedColumns = this.billingSheetService.replaceColumn(GRID_COLUMNS,
        GRID_COLUMNS_PROPERTIES.carrierName, GRID_COLUMNS_PROPERTIES.accountName);
    } else {
      this.displayedColumns = GRID_COLUMNS;
    }
  }

  /**
   * Builds creation date from billing sheet provided as string to display correctly.
   * @param {string} date - Date from billing sheet to process.
   * @returns {string} creation date as string format to display it in table.
   */
  public getBillingSheetCreationDate(date: string): string {
    return this.dateToolsService.getLocalTime(date, false);
  }

  /**
   * @description Receives status of billing sheet to display status in current language.
   * @param {string} status - Status of billing sheet to translate.
   * @returns {string} Label in current language.
   */
  public getLabelStatus(status?: string): string {
    return this.billingSheetService.getInvoiceStatusLabel(this.gridHeadersLabels.pending, this.gridHeadersLabels.paid, status);
  }
}
