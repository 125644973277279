import { Injectable } from '@angular/core';

import { AppConstants } from '../../constants/app-constants.constants';
import { CofeprisSectorsConstants } from '../../constants/tax-stamp/cofepris.constants';
import { EdxConstants } from '../../constants/tax-stamp/edx.constants';
import { GoodsDetail, TaxStampData } from '../../interfaces/shipment';
import { StampDataCofeprisErros, StampDataErrors } from '../../pages/shipments/shipment-view/shipment-view.labels';
import { TaxStampParms } from '../../interfaces/taxStamp';
import { TaxStampVersion } from '../../enums/taxStamp-version';

/**
 * @description Service class for handling goods validator fields related to COFEPRIS.
 */
@Injectable()
export class TaxStampCofeprisService {
  constructor() {
  }

  /**
   * @description Checks if the given value is of boolean type.
   * @param {boolean} value - The value to be checked.
   * @returns {boolean} Returns true if the value is of boolean type, otherwise false.
   */
  public isBoolType(value: boolean) {
    return typeof value === 'boolean';
  }

  /**
   * @description - Checks the properties of goods within the stamp shipment for required fields.
   * @param {TaxStampData} shipmentToStamp - The stamp shipment containing goods information.
   * @param {number} carryLetterVersion - The version of the carry letter.
   * @returns {Array<string>} An array of exceptions if required properties are missing.
   */
  public checkPropertiesGoods(shipmentToStamp: TaxStampData, carryLetterVersion: number): Array<string> {
    const errors: Array<string> = [];
    const isCarryLetterVersionV3 = carryLetterVersion === TaxStampVersion.V3.valueOf();
    const isInternationalTransport = shipmentToStamp.stampData.complement.isInternationalTransport ? EdxConstants.YES : EdxConstants.NO;
    const inOutMerchandise =
      shipmentToStamp.stampData.complement.inOutMerchandise === EdxConstants.ENTRANCE ? EdxConstants.ENTRANCE : AppConstants.EMPTY_STRING;

    const params: TaxStampParms = {
      isInternationalTransport: isInternationalTransport,
      inOutMerchandise: inOutMerchandise
    };

    if(!isCarryLetterVersionV3) {
      return [];
    }

    shipmentToStamp.stampData.goodsList.forEach((good: GoodsDetail) => {
      const orderId = good['orderId'] || AppConstants.EMPTY_STRING;
      const isCofeprisProduct = good['isCofeprisProduct'];
      this.validateUnitKey(good, errors);
      this.validateProdServKey(good, errors);
      this.validateQuantity(good, errors);
      this.validateDangerousMaterial(good, errors);

      if (isCarryLetterVersionV3 && (!this.isBoolType(isCofeprisProduct))) {
        errors.push(StampDataErrors.product + good.description +
          StampDataErrors.inOrder + orderId + StampDataCofeprisErros.cofeprisProduct);
      }

      if (isCofeprisProduct) {
        this.validateCofeprisSector(good, errors);
        this.validateActiveIngredient(good, errors);
        this.validateChemicalName(good, errors);
        this.validateActiveSubstanceGeneric(good, errors);
        this.validateTrademarkMedication(good, errors);
        this.validateManufacturer(good, errors);
        this.validateExpirationDate(good, errors);
        this.validateLot(good, errors);
        this.validatePharmaceuticalForm(good, errors);
        this.validateTransportSpecialConditions(good, errors);
        this.validateHealthRegisterFolio(good, errors);
        this.validateMatterType(good, errors, params);
        this.validateImportLicence(good, errors, params);
        this.validateImportVUCEMFolio(good, errors, params);
        this.validateNumCASAndImportCompany(good, errors);
        this.validateCofeprisPesticideNumber(good, errors);
        this.validatePesticideManufacturerCountryName(good, errors);
        this.validateActiveIngredientManufacturerCountryName(good, errors);
        this.validateActiveIngredientMaquilaCountryName(good, errors);
        this.validateAuthorizedUse(good, errors);
      }
    });

    return errors;
  }

  /**
   * @description Validates the dangerous material details for a given goods item.
   * @param {GoodsDetail} good - The goods item to validate.
   * @param {Array<string>} errors - An array to store validation errors.
   */
  private validateDangerousMaterial(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!this.isBoolType(good['isDangerousMaterial'])) {
      errors.push(
        StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.dangerousMaterial
      );
    }
  
    if (good['isDangerousMaterial']) {
      if (!good.dangerousMaterialKey) {
        errors.push(
          StampDataErrors.product + good.description +
          StampDataErrors.inOrder + orderId + StampDataCofeprisErros.dangerousMaterialKey
        );
      }
  
      if (!good.packaging) {
        errors.push(
          StampDataErrors.product + good.description +
          StampDataErrors.inOrder + orderId + StampDataCofeprisErros.packaging
        );
      }
  
      if (!good.packagingDescription) {
        errors.push(
          StampDataErrors.product + good.description +
          StampDataErrors.inOrder + orderId + StampDataCofeprisErros.packagingDescription
        );
      }
    }
  }

  /**
   * @description Validates the 'unitkey' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateUnitKey(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.unitKey) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.unitKey);
    }
  }

  /**
   * @description Validates the 'prodServKey' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateProdServKey(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.prodServKey) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.prodServKey);
    }
  }

  /**
   * @description Validates the 'weightInKg' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateQuantity(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.weightInKg) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.weightInKg);
    }
  }

  /**
   * @description Validates the 'cofepris sector' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateCofeprisSector(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.cofeprisSector) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.cofeprisSector);
    }
  }

  /**
   * @description Validates the 'active ingredient' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateActiveIngredient(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.activeIngredientName && [CofeprisSectorsConstants.KEY_02, CofeprisSectorsConstants.KEY_05].includes(good.cofeprisSector)) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.activeIngredientName);
    }
  }

  /**
   * @description Validates the 'chemical name' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateChemicalName(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.chemicalName && [CofeprisSectorsConstants.KEY_02, CofeprisSectorsConstants.KEY_04].includes(good.cofeprisSector)) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.chemicalName);
    }
  }

  /**
   * @description Validates the 'active substance generic' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateActiveSubstanceGeneric(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.activeSubstanceGeneric && [CofeprisSectorsConstants.KEY_01, CofeprisSectorsConstants.KEY_03].includes(good.cofeprisSector)) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.activeSubstanceGeneric);
    }
  }

  /**
   * @description Validates the 'trademark medication' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateTrademarkMedication(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.trademarkMedication && good.cofeprisSector === CofeprisSectorsConstants.KEY_03) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.trademarkMedication);
    }
  }

  /**
   * @description Validates the 'manufacturer' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateManufacturer(good: GoodsDetail, errors: Array<string>): void {
    this.validateField(
      good,
      'manufacturer',
      errors,
      [CofeprisSectorsConstants.KEY_01, CofeprisSectorsConstants.KEY_02, CofeprisSectorsConstants.KEY_03],
      StampDataCofeprisErros.manufacturer
    );
  }

  /**
   * @description Validates the 'expiration date' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateExpirationDate(good: GoodsDetail, errors: Array<string>): void {
    this.validateField(
      good,
      'expirationDate',
      errors,
      [CofeprisSectorsConstants.KEY_01, CofeprisSectorsConstants.KEY_02, CofeprisSectorsConstants.KEY_03],
      StampDataCofeprisErros.expirationDate
    );
  }

  /**
   * @description Validates the 'lot' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateLot(good: GoodsDetail, errors: Array<string>): void {
    this.validateField(
      good,
      'lot',
      errors,
      [CofeprisSectorsConstants.KEY_01, CofeprisSectorsConstants.KEY_02, CofeprisSectorsConstants.KEY_03],
      StampDataCofeprisErros.lot
    );
  }

  /**
   * @description Validates the 'pharmaceutical form' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validatePharmaceuticalForm(good: GoodsDetail, errors: Array<string>): void {
    this.validateField(
      good,
      'pharmaceuticalForm',
      errors,
      [CofeprisSectorsConstants.KEY_01, CofeprisSectorsConstants.KEY_02, CofeprisSectorsConstants.KEY_03],
      StampDataCofeprisErros.pharmaceuticalForm
    );
  }

  /**
   * @description Validates the 'trasport special conditions' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateTransportSpecialConditions(good: GoodsDetail, errors: Array<string>): void {
    this.validateField(
      good,
      'transportSpecialConditions',
      errors,
      [CofeprisSectorsConstants.KEY_01, CofeprisSectorsConstants.KEY_02, CofeprisSectorsConstants.KEY_03],
      StampDataCofeprisErros.trasportSpecialConditions
    );
  }

  /**
   * @description Validates the 'health register folio' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateHealthRegisterFolio(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.healthRegisterFolio && [CofeprisSectorsConstants.KEY_01, CofeprisSectorsConstants.KEY_03].includes(good.cofeprisSector)) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.healthRegisterFolio);
    }
  }

  /**
   * @description Validates the 'matter type' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors. If there is an error, it will be added to the list.
   * @param {TaxStampParms} params - Parameters related to tax stamps.
   */
  private validateMatterType(good: GoodsDetail, errors: Array<string>, params: TaxStampParms): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;
    const isInclude = [
      CofeprisSectorsConstants.KEY_01,
      CofeprisSectorsConstants.KEY_02,
      CofeprisSectorsConstants.KEY_04,
      CofeprisSectorsConstants.KEY_05
    ].includes(good.cofeprisSector)

    if (params.isInternationalTransport === EdxConstants.YES && isInclude && !good.matterType) {
      errors.push(StampDataErrors.product + good.description + StampDataErrors.inOrder +
        orderId + StampDataCofeprisErros.matterType);
    }
  }

  /**
   * @description Validates the 'import licence' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   * @param {TaxStampParms} params - Parameters related to tax stamps.
   */
  private validateImportLicence(good: GoodsDetail, errors: Array<string>, params: TaxStampParms): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    const isInclude = [
      CofeprisSectorsConstants.KEY_01,
      CofeprisSectorsConstants.KEY_02,
      CofeprisSectorsConstants.KEY_04,
      CofeprisSectorsConstants.KEY_05
    ].includes(good.cofeprisSector)

    if (params.isInternationalTransport === EdxConstants.YES && 
      params.inOutMerchandise === EdxConstants.ENTRANCE && !good.importLicense && isInclude) {
      errors.push(StampDataErrors.product + good.description + StampDataErrors.inOrder +
        orderId + StampDataCofeprisErros.importLicense);
    }
  }

  /**
   * @description Validates the 'import VUCEM Folio' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   * @param {TaxStampParms} params - Parameters related to tax stamps.
   */
  private validateImportVUCEMFolio(good: GoodsDetail, errors: Array<string>, params: TaxStampParms): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    const isInclude = [
      CofeprisSectorsConstants.KEY_01,
      CofeprisSectorsConstants.KEY_02,
      CofeprisSectorsConstants.KEY_04,
      CofeprisSectorsConstants.KEY_05
    ].includes(good.cofeprisSector)

    if (isInclude && !good.importVUCEMFolio) {
      errors.push(StampDataErrors.product + good.description + StampDataErrors.inOrder +
        orderId + StampDataCofeprisErros.importVUCEMFolio);
    }
  }

  /**
   * @description Validates the 'numCAS' and 'import company' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateNumCASAndImportCompany(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.numCAS && good.cofeprisSector === CofeprisSectorsConstants.KEY_04) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.numCAS);
    }

    if (!good.importCompany && good.cofeprisSector === CofeprisSectorsConstants.KEY_04) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.importCompany);
    }
  }

  /**
   * @description Validates the 'cofepris pesticide number' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateCofeprisPesticideNumber(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.cofeprisPesticideNumber && good.cofeprisSector === CofeprisSectorsConstants.KEY_05) {
      errors.push(StampDataErrors.product + good.description + StampDataErrors.inOrder + orderId +
        StampDataCofeprisErros.cofeprisPesticideNumber);
    }
  }

  /**
   * @description Validates the 'pesticide manufacturer country name' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validatePesticideManufacturerCountryName(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.pesticideManufacturerCountryName && good.cofeprisSector === CofeprisSectorsConstants.KEY_05) {
      errors.push(StampDataErrors.product + good.description + StampDataErrors.inOrder +
        orderId + StampDataCofeprisErros.pesticideManufacturerCountryName);
    }
  }

  /**
   * @description Validates the 'active ingredient manufacturer country name' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateActiveIngredientManufacturerCountryName(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.activeIngredientManufacturerCountryName && good.cofeprisSector === CofeprisSectorsConstants.KEY_05) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + StampDataCofeprisErros.activeIngredientManufacturerCountryName);
    }
  }

  /**
   * @description Validates the 'active ingredient maquila country name' field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateActiveIngredientMaquilaCountryName(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.activeIngredientMaquilaCountryName && good.cofeprisSector === CofeprisSectorsConstants.KEY_05) {
      errors.push(StampDataErrors.product + good.description + StampDataErrors.inOrder +
        orderId + StampDataCofeprisErros.activeIngredientMaquilaCountryName);
    }
  }

  /**
   * @description Validates the 'import authorized' use field in a product.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors, If there is an error, it will be added to the list.
   */
  private validateAuthorizedUse(good: GoodsDetail, errors: Array<string>): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;

    if (!good.authorizedUse && good.cofeprisSector === CofeprisSectorsConstants.KEY_05) {
      errors.push(StampDataErrors.product + good.description + StampDataErrors.inOrder +
        orderId + StampDataCofeprisErros.authorizedUse);
    }
  }

  /**
   * @description Validates a specific field in a product based on the provided condition.
   * @param {GoodsDetail} good - Product details.
   * @param {Array<string>} errors - List of errors. If there is an error, it will be added to the list.
   * @param {string} field - The field to validate.
   * @param {Array<string>} validSectors - Array of valid Cofepris sectors for the validation.
   * @param {string} errorMessage - The error message to be added to the list if the validation fails.
  */
  private validateField(good: GoodsDetail, field: string, errors: Array<string>, validSectors: Array<string>, errorMessage: string): void {
    const orderId = good['orderId'] || AppConstants.EMPTY_STRING;
    const isInclude = validSectors.includes(good.cofeprisSector);

    if (!good[field] && isInclude) {
      errors.push(StampDataErrors.product + good.description +
        StampDataErrors.inOrder + orderId + errorMessage);
    }
  }
}
