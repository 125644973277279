<div class="principal-container">
  <div class="title-container">
    <span *ngIf="!data.isEdit; else recordTitle" class="dialog-title">
      {{ 'invoiceRecordLabels.title' | translate }}
    </span>
    <ng-template #recordTitle>
      <span class="dialog-title">
        {{ 'invoiceRecordLabels.editTitle' | translate }}
      </span>
    </ng-template>
    <mat-icon
      id="dir-close-button"
      class="close-icon"
      (click)="onClickClose()">
      close
    </mat-icon>
  </div>
  <div>
    <div class="subtitle-container">
      <span class="dialog-subtitle">
        {{ 'invoiceRecordLabels.proposalFolio' | translate }} <strong>{{ customerInvoice.folio }}</strong>
      </span>
    </div>
    <form [formGroup]="invoiceRecordForm">
      <mat-grid-list cols="2" rowHeight="1:.40" class="record-form">
        <mat-grid-tile>
          <mat-form-field>
            <input
              id="invoiceFolio"
              formControlName="invoiceFolio"
              matInput
              maxlength="50"
              placeholder="{{ 'invoiceRecordLabels.invoiceFolio' | translate }}"
              required>
            <mat-error>
              <strong> {{ 'invoiceRecordLabels.isRequired' | translate }} </strong>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field>
            <input
              id="observations"
              *ngIf="data.isEdit; else optionalField"
              formControlName="observations"
              matInput
              maxlength="125"
              placeholder="{{ 'invoiceRecordLabels.observations' | translate }}"
              required>
            <ng-template #optionalField>
              <input
                id="observations"
                formControlName="observations"
                matInput
                maxlength="125"
                placeholder="{{ 'invoiceRecordLabels.observations' | translate }}">
            </ng-template>
            <mat-error>
              <strong> {{ 'invoiceRecordLabels.isRequired' | translate }} </strong>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </div>
  <div class="buttons-container">
    <button
      id="dir-cancel-button"
      mat-button
      class="secondary-button secondary-button--align"
      (click)="onClickCancel()">
      {{ 'invoiceRecordLabels.cancel' | translate }}
    </button>
    <button
      id="dir-save-button"
      [disabled]="!invoiceRecordForm.valid || isFormUnchanged()"
      mat-button
      class="primary-button"
      (click)="onClickSave()">
      {{ 'invoiceRecordLabels.save' | translate }}
    </button>
  </div>
</div>
