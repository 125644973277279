export enum RoutingStatusLabels {
  Hold = 'Pendiente de envío a ruteo',
  OnRouter = 'En ruteador sin planear',
  Planned = 'Planeado en ruteador',
  Created = 'Con embarque generado'
}

export enum RoutingStatus {
  created = 'Created',
  hold = 'Hold',
  onRouter = 'OnRouter',
}
