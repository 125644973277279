import { EvidenceFolio } from 'src/app/interfaces';

export enum EvidenceReleaseFolioDocumentConsts {
  DEFAULT_IMAGE = '../../../assets/biis.png',
  TITLE_CONTAINER = 'title-container'
}

export const EVIDENCE_COLUMNS = [
  'shipmentId',
  'tripType',
  'serviceType',
  'orderId',
  'internalReference',
  'invoice',
  'orderGrouper'
];

export const EVIDENCE_COLUMNS_ADDITIONAL = [
  'account',
  'origin',
  'destinationName',
  'destinationAddress',
  'destinationMunicipality',
  'destinationState',
  'carrier',
  'realDeliveryDate'
];

export const EXAMPLE: EvidenceFolio = { // TODO Remove when not necessary any more
  timestamp: new Date(),
  folio: 'EVFOLIO123456789',
  totalPieces: 300,
  totalBoxes: 500,
  totalPallets: 100,
  rows: [
    {
      orders: [
        {
          shipmentId: 'S2021123456789',
          tripType: 'PORTEO',
          serviceType: 'Express',
          orderId: 'ORD123456',
          orderFolio: 'MDK1234567',
          internalReference: 'Referencias',
          invoice: 'Factura1234567',
          orderGrouper: 'Pedido1232323'
        },
        {
          shipmentId: 'S2021123456789',
          tripType: 'PORTEO',
          serviceType: 'Express',
          orderId: 'ORD123456',
          orderFolio: 'MDK1234567',
          internalReference: 'Referencias',
          invoice: 'Factura1234567',
          orderGrouper: 'Pedido1232323'
        },
        {
          shipmentId: 'S2021123456789',
          tripType: 'PORTEO',
          serviceType: 'Express',
          orderId: 'ORD123456',
          orderFolio: 'MDK1234567',
          internalReference: 'Referencias',
          invoice: 'Factura1234567',
          orderGrouper: 'Pedido1232323'
        }
      ],
      details: [{
        account: 'Cuenta 1',
        origin: {
          name: 'Coecillo',
        },
        destination: {
          name: 'Doña Rosa',
          address: 'Av Revolución 432',
          municipality: 'Tultitlán',
          state: 'México'
        },
        carrier: {
          _id: '12345678',
          name: 'LINEA DE TRANSPORTE 1'
        },
        deliveryDate: new Date(),
      }],
      totalPiecesByRow: 100,
      totalBoxesByRow: 200,
      totalPalletsByRow: 300
    },

    {
      orders: [
        {
          shipmentId: 'S20211234567890',
          tripType: 'PORTEO CON REPARTO',
          serviceType: 'Express',
          orderId: 'ORD123456',
          orderFolio: 'MDK1234567',
          internalReference: 'Referencias',
          invoice: 'Factura1234567',
          orderGrouper: 'Pedido1232323'
        },
        {
          shipmentId: 'S20211234567890',
          tripType: 'PORTEO CON REPARTO',
          serviceType: 'Express',
          orderId: 'ORD123456',
          orderFolio: 'MDK1234567',
          internalReference: 'Referencias',
          invoice: 'Factura1234567',
          orderGrouper: 'Pedido1232323'
        },
      ],
      details: [{
        account: 'Cuenta 2',
        origin: {
          name: 'Coecillo',
        },
        destination: {
          name: 'Testing',
          address: 'Av Insurgentes',
          municipality: 'Toluca',
          state: 'México'
        },
        carrier: {
          _id: '1234567890',
          name: 'LINEA DE TRANSPORTE 2'
        },
        deliveryDate: new Date(),
      }],
      totalPiecesByRow: 300,
      totalBoxesByRow: 200,
      totalPalletsByRow: 100
    }
  ]
};
