<div>
  <h3 class="title-dialog">
    {{ data.title }}
  </h3>
  <div class="message-section">
    <div class="icon-container">
      <mat-icon>report_problem</mat-icon>
    </div>
    <div class="text-message-container">
      <p> {{ data.firstMessage }} </p>
      <b> {{ data.secondMessage }} </b>
    </div>
  </div>
</div>
<mat-dialog-content>
  <mat-card>
    <scf-grid
      *ngIf="isScfGridReady"
      [columnToggler]="scfGridConfig.columnToggler"
      [selectable]="scfGridConfig.selectable"
      [pageSize]="scfGridConfig.pageSize"
      [pageSizeOptions]="scfGridConfig.pageSizeOptions"
      [rowDraggable]="scfGridConfig.rowDraggable"
      [globalFilter]="false"
      [data]="errorsData"
      [columns]="gridColumns">
    </scf-grid>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
      id="dble-back-button"
      mat-raised-button
      class="secondary-button"
      (click)="onClickBackButon()">
      {{ 'dialogBulkLoadErrorsLabels.backButton' | translate }}
    </button>
    <button
      id="dble-load-file-button"
      mat-raised-button
      class="primary-button"
      (click)="onClickLoadButton()">
      {{ 'dialogBulkLoadErrorsLabels.uploadFileButton' | translate }}
    </button>
</mat-dialog-actions>

<ng-template #excelRows let-element="element" let-row="row">
  <ng-container>
    <button
      *ngFor="let excelRow of row.excelRows"
      mat-flat-button
      class="scf-grid-chip-button">
      {{ excelRow }}
    </button>
  </ng-container>
</ng-template>
