import { Injectable, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LanguageTranslateService } from './language-translate.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageChangeEventService {
  @Input()
  _languageEmitter: EventEmitter<string> = new EventEmitter();

  constructor(
    public _languageTranslateService: LanguageTranslateService,
    public translate: TranslateService
    ) { }

  /**
   * @description Subscribe to the event created when the language is changed.
   * @return {void}
   */
  // NOT WORKING
  public suscribeLanguageEmitter(): void {
    this._languageEmitter.subscribe((key: string) => {
      this.translate.use(key);
      this._languageTranslateService.setLanguage(key);
    });
  }

  /**
   * @description Emits a language change event to be used by other components.
   * @return {void}
   */
  public emitLanguageChangeEvent(languageKey: string): void {
    this._languageEmitter.emit(languageKey);
  }

  /**
   * @description Unsubscribe to the event created when the language is changed.
   * @return {void}
   */
  public unsubscribeLanguageChangeEvent(): void {
    this._languageEmitter.unsubscribe();
  }
}
