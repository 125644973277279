export const DATEFORMAT_CONSTANTS = {
  ANGULAR_12_DATE_FORMAT: 'dd/MM/yyyy hh:mm:ss a',
  ANGULAR_PIPE_FULL_DATE_FORMAT: 'dd/MM/yyyy HH:mm:ss',
  DASH_DATE_FORMAT_DDMMYYYY: 'DD-MM-YYYY',
  DATE_FORMAT_DB: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  DATE_FORMAT_SLASH_YYYYMMDD: 'YYYY/MM/DD',
  DATE_FORMAT_YYYYMMDD: 'YYYY-MM-DD',
  DATE_FORMAT: 'YYYYMMDD',
  DATE_FORMAT_FOLIO: 'yyyyMMdd',
  DAY: 'day',
  DAYS_ON_MONTH_L: 31,
  DAYS_ON_MONTH: 30,
  EVIDENCE_RELEASE_12_HOUR_FORMAT_ONLY_HOURS: 'hh:mm:ss a',
  FILE_DOWNLOAD_DATE: 'DDMMYYYY[_]HHmmss',
  FORMAT_DATE_ISO_LITERAL_T: 'YYYY-MM-DD[T]HH:mm:ss',
  FORMAT_DATE_ISO: 'YYYY-MM-DD',
  FULL_DATE_FORMAT_WITHOUT_SECONDS: 'DD/MM/YYYY HH:mm',
  FULL_DATE_FORMAT: 'DD/MM/YYYY HH:mm:ss',
  GENERAL_FORMAT: 'DD/MM/YYYY hh:mm:ss a',
  HOUR_AND_MINUTES_FORMAT: 'HH:mm',
  HOURS_ON_DAY: 24,
  LITERAL_Z: 'Z',
  LOCAL_DATE_AND_TIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
  MINUTES_ON_HOUR: 60,
  MS_ON_SECOND: 1000,
  POD_DOWNLOAD_FILE: 'DD_MM_YYYY',
  SECS_ON_MINUTE: 60,
  SLASH_DATE_FORMAT_DDMMYY: 'DD/MM/YYYY',
  STARTS_WITH_MONTH_EN: 'MM/DD/YYYY',
  STARTS_WITH_MONTH: 'MM/DD/YY HH:mm',
  TIME_FORMAT: 'HHmmss',
  TWELVE_HOURS_FORMAT: 'hh:mm a',
  TWO_DIGITS_DATE: 'DD/MM/YY'
};
