import { groupBy as _groupBy } from 'lodash';
import { Injectable } from '@angular/core';

import { AppConstants } from '../../constants/app-constants.constants';
import { ErrorData } from '../../interfaces/dialog-bulk-load-errors';
import { ORDERS_CREATION_ERRORS } from '../../constants/order.constants';
import { ImportationOrderRow, OrderErrors, OrderRequestBody, OrderExcelError, ProductOrderRequest } from '../../interfaces/order-bulk-load';
import { OrderBulkLoadProperties } from '../../pages/orders/order-bulk-load/order-bulk-load.properties';

/**
 * Service to handle erros for order bulk load..
 */
@Injectable()
export class OrderBulkLoadErrorHandlerService {
  public importationData: Array<ImportationOrderRow>;
  public orderBulkLoadErrorsTranslated: any;
  public orderBulkLoadProperties: typeof OrderBulkLoadProperties;

  constructor() {
    this.orderBulkLoadProperties = OrderBulkLoadProperties;
  }

  /**
   * @description Build errors data with multiple errors by identifiers in
   * order interface endpoint response and make match with excel file.
   * @param {Array<OrderErrors>} arrayErrors - Errors from endpoint.
   * @param {Array<OrderRequestBody>} orders - Orders sent.
   * @param {Array<ImportationOrderRow>} importationData - Data from excel file.
   * @param {any} orderBulkLoadErrorsTranslated - Error labels to set.
   * @returns {Array<ErrorData>} Errors data to show.
   */
  public buildOrdersErrors(arrayErrors: Array<OrderErrors>, orders: Array<OrderRequestBody>,
    importationData: Array<ImportationOrderRow>, orderBulkLoadErrorsTranslated: any): Array<ErrorData> {
    this.orderBulkLoadErrorsTranslated = orderBulkLoadErrorsTranslated;
    const errorsBuilt = [];
    this.importationData = importationData;

    for (const errorByIdentifier of arrayErrors) {
      const currentOrder = orders.find((order: OrderRequestBody) => {
        return order.identifier === errorByIdentifier.identifier;
      });

      if (errorByIdentifier.identifier.toLowerCase() === ORDERS_CREATION_ERRORS.errorDuplicatedId.toLowerCase()) {
        const duplicatedIdErrors = this.buildDuplicatedIdError(errorByIdentifier.errors);
        errorsBuilt.push(...duplicatedIdErrors);

        continue;
      }

      if (!errorByIdentifier.errors) {
        const identifiersErrors = this.buildRequiredIdentifierErrors();
        errorsBuilt.push(...identifiersErrors);

        continue;
      }

      for (const error of errorByIdentifier.errors) {
        const errorBuilt = this.buildError(error, currentOrder);

        if (errorBuilt.code === this.orderBulkLoadProperties.errorCodes.prodErrorNocode) {
          const noProductCodeErrors = this.buildNoProductCodeError(currentOrder.identifier);
          errorsBuilt.push(...noProductCodeErrors);
        } else {
          errorsBuilt.push(errorBuilt);
        }
      }
    }

    return this.groupAndSetErrors(errorsBuilt);
  }

  /**
   * @description Builds duplidated identifier error.
   * @param {Array<string>} identifiers - Repeated identifiers.
   * @returns {Array<OrderExcelError>} Error built.
   */
  public buildDuplicatedIdError(identifiers: Array<string>): Array<OrderExcelError> {
    const duplicatedIdErrors = [];

    for (const row of this.importationData) {
      const identifierFound = identifiers.find((orderId: string) => {
        return orderId === row.identifier;
      });

      if (identifierFound) {
        const error = this.buildOrderExcelError(this.orderBulkLoadProperties.errorCodes.errorDuplicatedId, row.__rowNum__);
        duplicatedIdErrors.push(error);
      }
    }

    return duplicatedIdErrors;
  }

  /**
   * @description Builds when product code is missing.
   * @param {string} orderIdentifier - Order identifier without product codes.
   * @returns {Array<OrderExcelError>} Error built.
   */
  public buildRequiredIdentifierErrors(): Array<OrderExcelError> {
    const identifiersErrors = [];

    for (const row of this.importationData) {
      if (!row.identifier) {
        const error = this.buildOrderExcelError(this.orderBulkLoadProperties.errorCodes.identifierRequired, row.__rowNum__);
        identifiersErrors.push(error);
      }
    }

    return identifiersErrors;
  }

  /**
   * @description Builds errors when product code is missing.
   * @param {string} orderIdentifier - Order identifier without product codes.
   * @returns {Array<OrderExcelError>} Error built.
   */
  public buildNoProductCodeError(orderIdentifier: string): Array<OrderExcelError> {
    const noProductCodeErrors = [];

    for (const row of this.importationData) {

      if (orderIdentifier === row.identifier && !row.productCode) {
        const error = this.buildOrderExcelError(this.orderBulkLoadProperties.errorCodes.prodErrorNocode, row.__rowNum__);
        noProductCodeErrors.push(error);
      }
    }

    return noProductCodeErrors;
  }
  
  /**
   * @description Build data for order error matched with excel file.
   * @param {string} errorCode - Error code found.
   * @param {number} excelRow - Excel row matched.
   * @returns {OrderExcelError} Error built.
   */
  public buildOrderExcelError(errorCode: string, excelRow: number): OrderExcelError {
    const error = {
      code: errorCode,
      excelRow,
      orderId: AppConstants.EMPTY_STRING,
      productCode: AppConstants.EMPTY_STRING,
    };

    return error;
  }

  /**
   * @description Build error with current error by identifier.
   * @param {string} currentError - Current error by identifier.
   * @param {OrderRequestBody} currentOrder - Current order with errors.
   * @returns {OrderExcelError} Error built.
   */
  public buildError(currentError: string, currentOrder: OrderRequestBody): OrderExcelError {
    const productErrorData = this.splitProductError(currentError, this.orderBulkLoadProperties.splitProductSeparators.productsString);
    const errorProductCode = this.getErrorProductCode(currentError, currentOrder);
    const error = {
      code: AppConstants.EMPTY_STRING,
      productCode: AppConstants.EMPTY_STRING,
      excelRow: undefined,
      orderId: currentOrder.identifier
    };

    if (errorProductCode) {
      error.productCode = errorProductCode;
      currentError = this.splitProductError(currentError, this.orderBulkLoadProperties.splitProductSeparators.hyphenSpace)[0];
    }

    if (productErrorData.length) {
      currentError = this.orderBulkLoadProperties.splitProductSeparators.productsString + productErrorData[1];
    }

    for (const errorProperty in ORDERS_CREATION_ERRORS) {
      if (ORDERS_CREATION_ERRORS[errorProperty] !== null &&
        ORDERS_CREATION_ERRORS[errorProperty].toLowerCase() === currentError.toLowerCase()) {
        error.code = errorProperty;
        error.excelRow = this.setExcelRow(error);
      }
    }

    return error;
  }

  /**
   * @description Set excel row for current error.
   * @param {OrderExcelError} errorFound - Current error found.
   * @returns {number} Excel row found.
   */
  public setExcelRow(errorFound: OrderExcelError): number {
    const orderId = errorFound.orderId;
    const productCode = errorFound.productCode;

    for (const row of this.importationData) {
      if (productCode && orderId === row.identifier && row.productCode === productCode) {
        return row.__rowNum__;
      } else if (!productCode && orderId === row.identifier) {
        return row.__rowNum__;
      }
    }
  }

  /**
   * @description Gets product code if provided error is a product error.
   * @param {string} currentError - Current error to validate.
   * @param {OrderRequestBody} currentOrder - Current order data.
   * @returns {string} Product code from error found.
   */
  public getErrorProductCode(currentError: string, currentOrder: OrderRequestBody): string {
    let productsCodes = [];
    let currentProductCode;

    if (currentOrder.products && currentOrder.products.length) {
      productsCodes = currentOrder.products.map((product: ProductOrderRequest) => {
        return product.code;
      });
    }

    const productErrorData = this.splitProductError(currentError, this.orderBulkLoadProperties.splitProductSeparators.hyphenSpace);

    if (productErrorData.length) {
      currentError = productErrorData[0];
      const errorProductCode = productErrorData[1];
      currentProductCode = productsCodes.find((code: string) => {
        return code === errorProductCode; 
      });
    }

    return currentProductCode;
  }

  /**
   * @description Split error by provided separator, to split description from product
   * code if is the case.
   * @param {string} currentError - Error to split.
   * @param {string} separator - Value that identifies character to use in
   * separating the string.
   * @returns {Array<string>} Error split.
   */
  public splitProductError(currentError: string, separator: string): Array<string> {
    const separatorIndex = currentError.indexOf(separator);
    let productErrorData = [];

    if (separatorIndex > 0) {
      productErrorData = currentError.split(separator);
    }

    return productErrorData;
  }

  /**
   * @description Group erros by code and sets nececssary data tod show errors in dialog.
   * @param {Array<OrdersErrors>} errorsBuilt - Created errors from endpoint response.
   * @returns {Array<ErrorData>} Errors data to show.
   */
  public groupAndSetErrors(errorsBuilt: Array<OrderExcelError>): Array<ErrorData> {
    const groupedErrors = _groupBy(errorsBuilt, this.orderBulkLoadProperties.codeProperty);
    const errorsToShow = [];
    
    for (const codeError in groupedErrors) {
      if (groupedErrors[codeError] !== null) {
        const errorToShow = this.buildErrorToShow(codeError, groupedErrors[codeError]);
        errorsToShow.push(errorToShow);
      }
    }

    return errorsToShow;
  }

  /**
   * @description Build error to show in dialog.
   * @param {string} errorCode - Current error code.
   * @param {OrdersExcelErrors} groupedErrors - Error grouped by codes.
   * @returns {ErrorData} Error data to show.
   */
  public buildErrorToShow(errorCode: string, groupedErrors: Array<OrderExcelError>): ErrorData {
    const errorLabel = this.orderBulkLoadErrorsTranslated[errorCode];
    const errorRows = groupedErrors.map((error: OrderExcelError) => {
      return (error.excelRow ?? 0).toString();
    });

    const errorToShow = {
      errorType: errorLabel,
      excelRows: errorRows
    };

    return errorToShow;
  }


}
