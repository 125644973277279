<div class="dialog-title-flex">
  <h2 mat-dialog-title>{{ 'dialogCreateBulkLoadConfigLabels.dialogTitle' | translate }}</h2>
</div>
<mat-dialog-content>
  <div class="dialog-label-container">
  <mat-icon id="info-icon">info</mat-icon>
  {{ data.label }}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'dialogCreateBulkLoadConfigLabels.configNameInputLabel' | translate }}
    </mat-label>
    <input id="dcb-config-name"
      #configNameInput
      [(ngModel)]="configName"
      matInput
      required>
  </mat-form-field>
  <div class="flex">
    <button id="dcb-cancel-button"
      class="secondary-button"
      mat-button
      mat-dialog-close>
      {{ 'dialogCreateBulkLoadConfigLabels.dialogCancelButton' | translate }}
    </button>
    <button id="dcb-save-button"
      class="primary-button"
      [disabled]="!isValidConfigNameEntry()"
      (click)="onSaveConfig()"
      mat-button>
      {{ 'dialogCreateBulkLoadConfigLabels.dialogSaveButton' | translate }}
    </button>
  </div>
</mat-dialog-actions>
