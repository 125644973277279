<div class="preview-title-container">
  <div class="preview-title">
    <span class="dialog-title">
      <p>{{ data.title }}</p>
    </span>
  </div>
  <div class="preview-icon">
    <mat-icon style="cursor: pointer" (click)="onClickClose()">
      close
    </mat-icon>
  </div>
</div>

<div class="dialog-data-container">
  <div class="dialog-image-container">
    <img src="{{data.imgRef}}" alt="Dialog image">
  </div>
  <div class="general-subtitle-container">
    <div class="subtitle-container" *ngFor="let subtitle of subtitles, let i = index">
      {{ subtitle }}<br>
      <span class="subtitle-data" [ngClass]="{'first-value': i === 0}">{{ subtitleData[i] }}</span>
    </div>
  </div>
</div>
<div class="button-container">
   <button
    mat-button
    cdkFocusInitial
    class="primary-button"
    (click)="onClickButton()">
    {{ data.button }}
  </button>
</div>