import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ArrayToolsService } from './array-tools.service';
import { BatchedResponse } from '../../interfaces/batched-response';
import { PaginationResponse } from '../../interfaces/pagination/pagination.response';

@Injectable()
export class RequestToolsService {
  constructor (private arrayToolsService: ArrayToolsService, private http: HttpClient) {}

  /**
   * @description Performs a POST Request by batches
   * @param url Endpoint URL
   * @param body Array of elemnts to be sent
   * @param chunkSize Indicates how many elements per chunk should be sent
   */
  public async doPOSTRequestByBatch (url: string, body: Array<any>,
    chunkSize: number = 10): Promise<BatchedResponse> {
    const success = [];
    const errors = [];
    let chunks = [];
    chunks = this.arrayToolsService.batch(body, chunkSize);

    while (chunks.length) {
      try {
        const chunk = chunks.shift();
        const payload = { data: chunk };
        const result = await this.http.post<any>(url, payload).toPromise();
        if ((result.error && result.error.length > 0) || (result.error && result.error.invalid)) {
          errors.push({error: result.error});
        }
        success.push(result);
      } catch (error) {
        errors.push(error);
      }
    }
    const response: BatchedResponse = { success, errors };

    if (!response.success || !response.success.length) {
      throw response;
    }
    return response;
  }

  public async doGetRequestByBatch(body: Array<any>, url?: string, ): Promise<BatchedResponse> {
    const success = [];
    const errors = [];

    while (body.length) {
      try {
        const chunk = body.shift();
        const urlRequest = chunk.url ? chunk.url : url;
        const result = await this.http.get<any>(urlRequest).toPromise();
        success.push(result);
      } catch (error) {
        errors.push(error);
      }
    }
    return { success, errors };
  }
}
