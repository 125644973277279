import { Injectable } from '@angular/core';

import { ShipmentRequest } from '../interfaces';

@Injectable()
export class ShipmentCommunicationService {
  public shipmentsReq: ShipmentRequest[];
  public releaseFolio: string;

  constructor() { this.shipmentsReq = []; }

  /**
  *@description This function is to set the shipmentRequest when you are going to send it to another page.
  *@param shipment Is the shipment
  */
  public setShipment(shipment: ShipmentRequest): void {
    this.shipmentsReq.push(shipment);
  }

  /**
  *@description This function is to set the shipmentRequest when you are going to send it to another page.
  *@param shipment Is the shipment
  */
  public setFolio(folio: string): void {
    this.releaseFolio = folio;
  }

  /**
  *@description This functions gets the shipmentRequesID saved in the service
  *@param null
  *@returns a string with the shipmentRequestID
  */
  public getShipment(): ShipmentRequest {
    let lastShipment: ShipmentRequest;
    lastShipment = this.shipmentsReq[this.shipmentsReq.length - 1];
    return lastShipment;
  }

  /**
  *@description This functions gets the release folio saved in the service
  *@returns a string with the releaseFolio
  */
  public getReleaseFolio(): string {
    return this.releaseFolio;
  }

  /**
  *@description This functions resets the shipmentRequest saved in the service.
  *@returns a string with the shipmentRequestID
  */
  public resetShipment(): void {
    this.shipmentsReq = null;
  }
}
