export enum CostStatusEnum {
  accepted = 'Aceptado',
  canceled = 'Cancelado',
  created = 'Creado',
  deleted = 'Eliminado',
  modified = 'Modificado',
  pending = 'Pendiente',
  release = 'Liberado',
  sent = 'Enviado'
}
