import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FilteredOrder, IShipmentTemperature } from '../../../interfaces/shipment';
import { IOrderStatusLabels } from '../../../interfaces/labels/order-labels.interface';
import { IShipmentCreationLabels, IShipmentTemperatureLabels } from '../../../interfaces/labels/shipment-labels.interface';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { ORDER_STATUS } from '../../../constants/order.constants';
import { TEMPERATURE_VALUES } from '../../../constants/temperature.constants';
import { TemperatureInterface } from '../../../interfaces/temperature';

import { Subscription } from 'rxjs';
import { IOrderStatus } from 'src/app/interfaces';
import { SHIPMENT_TEMPERATURE } from 'src/app/constants/shipment.constants';

const CANCEL = 'Cancel';
const CLOSED = 'Closed';

@Component({
  selector: 'app-dialog-order-detail',
  templateUrl: './dialog-order-detail.component.html',
  styleUrls: ['./dialog-order-detail.component.scss',  '../../../app.component.scss']
})
export class DialogOrderDetailComponent implements OnInit {
  public dialogOrderDetailLabels: IShipmentCreationLabels;
  public languageSuscription: Subscription;
  public isCourierDetail: boolean;
  public orderStatus: Array <IOrderStatus>;
  public orderStatusLabels: IOrderStatusLabels;
  public shipmentTemperatureLabels: IShipmentTemperatureLabels;
  public shipmentTemperature: Array <IShipmentTemperature>;
  public temperatureList: Array<TemperatureInterface>;

  constructor(
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService,
    public dialogRef: MatDialogRef<DialogOrderDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FilteredOrder) {
      this.setLanguage();
      this.isCourierDetail = this.data.isCourierDetail ? this.data.isCourierDetail : false;
      this.temperatureList = TEMPERATURE_VALUES;
    }

  async ngOnInit() {
    this.orderStatus = ORDER_STATUS;
    this.shipmentTemperature = SHIPMENT_TEMPERATURE;
    this.subscribeLanguageChangeEvent();
    await this.getLabels();
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getLabels();
      },
      () => {});
  }

  /**
   * @description Gets the necessary tags from the JSON files to use throughout the component
   * @return {void}
   */
  public async getLabels(): Promise<void> {
    await this.getOrderStatusLabels();
    await this.getShipmentTemperatureLabels();
    await this.getDialogOrderDetailLabels();
  }

  /**
   * @description Gets Dialog Order Detail Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getDialogOrderDetailLabels(): Promise<void> {
    this.dialogOrderDetailLabels = await this._languageTranslateService
    .getLanguageLabels(LanguageConstants.DIALOG_ORDER_DETAIL_LABELS)
    .catch(() => {});
  }

  /**
   * @description Gets Order Status Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getOrderStatusLabels(): Promise<void> {
    this.orderStatusLabels = await this._languageTranslateService
    .getLanguageLabels(LanguageConstants.ORDER_STATUS_LABELS)
    .catch(() => {});
  }

  /**
   * @description Gets Shipment Temperature Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getShipmentTemperatureLabels(): Promise<void> {
    this.shipmentTemperatureLabels = await this._languageTranslateService
    .getLanguageLabels(LanguageConstants.SHIPMENT_TEMPERATURE_LABELS)
    .catch(() => {});
  }

  /**
   * @description Get translate value for an specific order status.
   *
   * @param {string} status Order status to be translated.
   * @return {string} Order status translated.
   */
  public getTranslatedOrderStatusLabel(status: string): string {
    let statusTranslated: string;
    if (status && this.orderStatusLabels) {
      this.orderStatus.forEach(element => {
        if (element.value === status) {
          if (this.orderStatusLabels[element.label]) {
            statusTranslated = this.orderStatusLabels[element.label];
          }
        }
      });
    }

    return statusTranslated ?? status;
  }

  /**
   * @description Get translate value for an specific shipment temperature.
   *
   * @param {string} value Shipment temperature to be translated.
   * @return {string} Shipment temperature translated.
   */
  public getTranslatedShipmentTemperatureLabel(value: string): string {
    let labelTranslated: string;
    if (value && this.shipmentTemperatureLabels) {
      this.shipmentTemperature.forEach(element => {
        if (element.value === value) {
          if (this.shipmentTemperatureLabels[element.label]) {
            labelTranslated = this.shipmentTemperatureLabels[element.label];
          }
        }
      });
    }

    return labelTranslated ?? value;
  }

  public onClickButton1(): void {
    this.dialogRef.close(CANCEL);
  }

  public onClickClose(): void {
    this.dialogRef.close(CLOSED);
  }

  /**
   * @description gets the correct temperature description with the code given by order row
   * @param code temperature code given by order data
   * @returns an string with the correspondient description
   */
   public getTemperatureDescriptionByCode(code: string): string {
    if (code && this.temperatureCodeExists(code)) {
      const temperature = this.temperatureList.find(temperatureElement => temperatureElement.code === code).description;
      return this.getTranslatedShipmentTemperatureLabel(temperature);
    }
  }

  /**
   * @description verifies if temperature code exists in temperature constants file
   * @param code temperature code given by order data
   * @returns true if the code exists in temperature constants false if not
   */
  public temperatureCodeExists(code: string): boolean {
    return this.temperatureList.find(tempertureElement => tempertureElement.code === code) ? true : false;
  }

}
