<mat-grid-list cols="3" rowHeight="2rem">
  <mat-grid-tile colspan="2">
    <span class="dialog-title" matTooltip="{{ shipmentRequestResponse.identifier ? shipmentRequestResponse.identifier : labels.withoutReference }}">
      <p> {{ labels.title }} {{ shipmentRequestResponse.identifier ? shipmentRequestResponse.identifier : labels.withoutReference }}</p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1">
    <button mat-icon-button class="dialog-close-icon" [mat-dialog-close]="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </mat-grid-tile>
</mat-grid-list>
<div mat-dialog-content>
  <mat-tab-group animationDuration="0ms"  mat-stretch-tabs class="">
    <mat-tab label="{{ labels.generalTabTitle }}">
      <mat-card class="cardConfirmation">
        <mat-card-content class="mat-container" label="general">
          <mat-grid-list cols="1" rowHeight="45px">
            <mat-grid-tile colspan="1">
              <mat-icon class="icon-origin">
                <img class="img-origin" src="{{ originAndDestination }}" alt="origin and destination image"/>
              </mat-icon>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="2" rowHeight="11rem">
            <mat-grid-tile colspan="1">
              <div class="container-info-origin">
                <div class="mat-grid-info-left">
                  <div class="paragraph font-13" matTooltip="{{ shipmentRequestResponse?.origin.name ? shipmentRequestResponse?.origin.name : labels.noInfo }}">
                    <div class="dot-description">
                      &bull; 
                    </div>
                    <div class="description">
                      {{ labels.origin }} <span class="element-decription">{{ shipmentRequestResponse?.origin.name ? shipmentRequestResponse?.origin.name : labels.noInfo }}</span>
                    </div>
                  </div>
                  <div class="paragraph font-13" matTooltip="{{ fullOriginShipmentAddress ? fullOriginShipmentAddress : labels.noInfo }}">
                    <div class="dot-description">
                      &bull; 
                    </div>
                    <div class="description">
                      {{ labels.addressOrigin }}: <span class="element-decription">{{ fullOriginShipmentAddress ? fullOriginShipmentAddress : labels.noInfo }}</span>
                    </div>
                  </div>
                  <div class="paragraph font-13" matTooltip="{{ shipmentRequestResponse.origin.loadDate ?  getDate(shipmentRequestResponse.origin.loadDate) : labels.noInfo }}">
                    <div class="dot-description">
                      &bull; 
                    </div>
                    <div class="description">
                      {{ labels.dateOriginLoad }}: <span class="element-decription">{{ shipmentRequestResponse.origin.loadDate ?  getDate(shipmentRequestResponse.origin.loadDate) : labels.noInfo }}</span>
                    </div>
                  </div>
                  <div class="paragraph font-13" matTooltip="{{ shipmentRequestResponse.origin.loadDate ? getTime(shipmentRequestResponse.origin.loadDate) : labels.noInfo }}">
                    <div class="dot-description">
                      &bull; 
                    </div>
                    <div class="description">
                      {{ labels.dateAndTimeOriginLoad }}: <span class="element-decription">{{ shipmentRequestResponse.origin.loadDate ? getTime(shipmentRequestResponse.origin.loadDate) : labels.noInfo }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
              <div class="container-info-destination"> 
                <div class="mat-grid-info-right">

                <div class="paragraph font-13" matTooltip="{{
                  shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1].name ?
                  shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1].name
                : labels.noInfo
                }}">
                  <div class="dot-description">
                    &bull; 
                  </div>
                  <div class="description">
                    {{ labels.destination }} <span class="element-decription"> {{
                      shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1].name ?
                      shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1].name
                    : labels.noInfo
                    }}</span>
                  </div>
                </div>
                <div class="paragraph font-13" matTooltip="{{ fullDestinationShipmentAddress ? fullDestinationShipmentAddress : labels.noInfo }}">
                  <div class="dot-description">
                    &bull; 
                  </div>
                  <div class="description">
                    {{ labels.addressDestination }}: <span class="element-decription"> {{ fullDestinationShipmentAddress ? fullDestinationShipmentAddress : labels.noInfo }}</span>
                  </div>
                </div>
                <div class="paragraph font-13" matTooltip="{{ 
                  shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1].deliveryDate ?
                  getDate(shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1].deliveryDate)
                : labels.noInfo
                }}">
                  <div class="dot-description">
                    &bull; 
                  </div>
                  <div class="description">
                    {{ labels.dateDelivery }}: <span class="element-decription"> {{ 
                      shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1].deliveryDate ?
                      getDate(shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1].deliveryDate)
                    : labels.noInfo
                    }}</span>
                  </div>
                </div>
                <div class="paragraph font-13" matTooltip="{{ 
                  shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1].deliveryDate ?
                  getTime(shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1].deliveryDate)
                : labels.noInfo
                }}">
                  <div class="dot-description">
                    &bull; 
                  </div>
                  <div class="description">
                    {{ labels.dateAndTimeDelibery }}: <span class="element-decription"> {{ 
                      shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1].deliveryDate ?
                      getTime(shipmentRequestResponse.destinations[this.shipmentRequestResponse.destinations.length - 1].deliveryDate) 
                    : labels.noInfo
                    }}</span>
                  </div>
                </div>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="1" rowHeight="5px">
            <mat-grid-tile colspan="1"><div></div></mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="6" rowHeight="2rem">
            <mat-grid-tile colspan="6">
              <div class="container-subtitle">
                <div>
                  <h2 class="subtitle"> {{ labels.shipmentInformationSubtitle }}</h2>
                </div>
                <hr class="hr-subtitle">
              </div>
             </mat-grid-tile>
             <mat-grid-tile colspan="6">
                <div class="container-paragraph">
                  <div class="paragraph-inline mr-15 width-c-3" matTooltip="{{ labels.noInfo }}">
                    <div class="dot-description">
                      &bull;
                    </div>
                    <div class="paragraph-description">
                      <div>
                        {{ labels.transportSubtitle }}:
                      </div>
                      <div class="text-custom max-w-310 ml-5">
                        <span class="element-decription">{{ shipmentRequestResponse.acceptedCarrier ? shipmentRequestResponse.acceptedCarrier.carrier.name : labels.noInfo }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="paragraph-inline mr-15 width-c-3" matTooltip="{{ shipmentRequestResponse.vehicleType.name ? shipmentRequestResponse.vehicleType.name : labels.noInfo }}">
                    <div class="dot-description">
                      &bull;
                    </div>
                    <div class="paragraph-description">
                      <div>
                        {{ labels.typeVehicle }}:
                      </div>
                      <div class="text-custom max-w-150 ml-5">
                        <span class="element-decription">{{ shipmentRequestResponse.vehicleType.name ? shipmentRequestResponse.vehicleType.name : labels.noInfo }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="paragraph-inline mr-15 width-c-3" matTooltip="{{ shipmentRequestResponse.loadType.name ? shipmentRequestResponse.loadType.name : labels.noInfo }}">
                    <div class="dot-description">
                      &bull;
                    </div>
                    <div class="paragraph-description">
                      <div>
                        {{ labels.typeLoad }}:
                      </div>
                      <div class="text-custom max-w-150 ml-5">
                        <span class="element-decription">{{ shipmentRequestResponse.loadType.name ? shipmentRequestResponse.loadType.name : labels.noInfo }}</span>
                      </div>
                    </div>
                  </div>
               </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="1" rowHeight="5px">
            <mat-grid-tile colspan="1"><div></div></mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="4" rowHeight="2rem">
            <mat-grid-tile colspan="4">
              <div class="container-subtitle">
                <div>
                  <h2 class="subtitle">{{ labels.volumetryInformationSubTitle }}</h2>
                </div>
                <hr class="hr-subtitle">
              </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="4">
              <div class="container-paragraph">
                <div class="paragraph-inline mr-15 width-c-2" matTooltip="{{ shipmentRequestResponse.weight ? (shipmentRequestResponse.weight + ' ' + labels.kilograms) : labels.noInfo }}">
                  <div class="dot-description">
                    &bull;
                  </div>
                  <div class="paragraph-description">
                    <div>
                      {{ labels.kilogramsTitle }}:
                    </div>
                    <div class="text-custom max-w-150 ml-5">
                      <span class="element-decription">{{ shipmentRequestResponse.weight ? (shipmentRequestResponse.weight + ' ' + labels.kilograms) : labels.noInfo }}</span>
                    </div>
                  </div>
                </div>
                <div class="paragraph-inline mr-15 width-c-2" matTooltip="{{ shipmentRequestResponse.volume ? (shipmentRequestResponse.volume + ' ' + labels.cubicMeters) : labels.noInfo }}">
                  <div class="dot-description">
                    &bull;
                  </div>
                  <div class="paragraph-description">
                    <div>
                      {{ labels.volumetry }}:
                    </div>
                    <div class="text-custom max-w-150 ml-5">
                      <span class="element-decription">{{ shipmentRequestResponse.volume ? (shipmentRequestResponse.volume + ' ' + labels.cubicMeters) : labels.noInfo }}</span>
                    </div>
                  </div>
                </div>
             </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="1" rowHeight="5px">
            <mat-grid-tile colspan="1"><div></div></mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="4" rowHeight="2rem">
            <mat-grid-tile colspan="4">
              <div class="container-subtitle">
                <div>
                  <h2 class="subtitle"> {{ labels.budgetSubtitle }} </h2>
                </div>
                <hr class="hr-subtitle">
              </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="4">
              <div class="container-paragraph">
                <div class="paragraph-inline mr-15 width-c-2" matTooltip="{{ shipmentRequestResponse.defaultOffer ? (labels.dollarSign + ' ' + ( shipmentRequestResponse.defaultOffer | number )) : labels.noInfo }}">
                  <div class="dot-description">
                    &bull;
                  </div>
                  <div class="paragraph-description">
                    <div>
                      {{ labels.assignBudget }}:
                    </div>
                    <div class="text-custom max-w-150 ml-5">
                      <span class="element-decription">{{ shipmentRequestResponse.defaultOffer ? (labels.dollarSign + ' ' + (shipmentRequestResponse.defaultOffer | number:'1.0':'en-US')) : labels.noInfo }}</span>
                    </div>
                  </div>
                </div>
                <div class="paragraph-inline mr-15 width-c-2" matTooltip="{{ shipmentRequestResponse.expirationDate ? getDate(shipmentRequestResponse.expirationDate) : labels.noInfo }}">
                  <div class="dot-description">
                    &bull;
                  </div>
                  <div class="paragraph-description">
                    <div>
                      {{ labels.shipmentRequest }}:
                    </div>
                    <div class="text-custom max-w-150 ml-5">
                      <span class="element-decription">{{ shipmentRequestResponse.expirationDate ? getDate(shipmentRequestResponse.expirationDate) : labels.noInfo }}</span>
                    </div>
                  </div>
                </div>
             </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="1" rowHeight="5px">
            <mat-grid-tile colspan="1"><div></div></mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="4" rowHeight="2rem">
            <mat-grid-tile colspan="4">
              <div class="container-subtitle">
                <div>
                  <h2 class="subtitle"> {{ labels.specialRequerimentsSubtitle }}</h2>
                </div>
                <hr class="hr-subtitle">
              </div>
             </mat-grid-tile>
            <mat-grid-tile colspan="4">
             <div class="mat-grid-info-right">
              <p class="element e-requeriments">
                <span *ngFor="let requirement of shipmentRequestResponse.shipperRequirements" 
                      class="element-decription mr-10 font-13"
                      matTooltip="{{ requirement.name }}">
                  &bull; {{ requirement.name }}
                </span>
                 <span *ngIf="!shipmentRequestResponse.shipperRequirements" 
                      class="element-decription font-13" 
                      matTooltip="{{ labels.noInfo }}">&bull; {{ labels.noInfo }}</span>
              </p>
             </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="1" rowHeight="5px">
            <mat-grid-tile colspan="1"><div></div></mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="4" rowHeight="2rem">
            <mat-grid-tile colspan="4">
              <div class="container-subtitle">
                <div>
                  <h2 class="subtitle"> {{ labels.specialAdditionalsSubtitle }}</h2>
                </div>
                <hr class="hr-subtitle">
              </div>
             </mat-grid-tile>
            <mat-grid-tile colspan="4">
             <div class="mat-grid-info-right">
              <p class="element e-requeriments" matTooltip="{{ labels.noInfo }}">
                &bull; <span class="element-decription">{{ labels.noInfo }}</span>
              </p>
             </div>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="{{ labels.stopTabTitle }}" class="tab-reparts">
      <div class="map">
        <agm-map
          [latitude]="latitude"
          [longitude]="longitude"
          [minZoom]="zoom">
          <agm-direction 
            [origin]="origin"
            [destination]="destination"
            [renderOptions]="renderOptions"
            [markerOptions]="markerOptions"
            [optimizeWaypoints]="optimizeWaypoints"
            [waypoints]="waypoints"
            [visible]="true"
            ></agm-direction>
            <agm-marker
              *ngFor="let marker of markers"
              [latitude]="marker.location.lat"
              [longitude]="marker.location.lng"
              [title]="marker.destination.address || marker.destination.name"
              [iconUrl]="waypointIcon">
              <agm-info-window
                [disableAutoPan]="false"
                #infoWindow>
                  <div >
                    {{ marker.addressMarker }}
                  </div>
              </agm-info-window>
            </agm-marker> 
        </agm-map>
      </div>
      <mat-card class="" *ngIf="false">
        <mat-card-content class="">
          <mat-grid-list cols="2" rowHeight="3rem">
            <mat-grid-tile colspan="1">
              <div class="container-paragraph">
                <div class="paragraph-inline mr-15 width-c-2" matTooltip="{{ distanceRoute ?  distanceRoute : labels.noInfo  }}">
                  <div class="dot-description">
                    &bull;
                  </div>
                  <div class="paragraph-description">
                    <div>
                      {{ labels.totalDistance }}
                    </div>
                    <div class="text-custom max-w-150 ml-5">
                      <span class="element-decription">{{ distanceRoute ?  distanceRoute : labels.noInfo  }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
              <div class="container-paragraph">
                <div class="paragraph-inline mr-15 width-c-2" matTooltip="{{ timeDistanceRoute ? timeDistanceRoute : labels.noInfo }} {{ labels.labelTime }}">
                  <div class="dot-description">
                    &bull;
                  </div>
                  <div class="paragraph-description">
                    <div>
                      {{ labels.timeEstimedForRute }}
                    </div>
                    <div class="text-custom max-w-150 ml-5">
                      <span class="element-decription">{{ timeDistanceRoute ? timeDistanceRoute : labels.noInfo }} {{ labels.labelTime }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>
      <table #tableShipperRequest mat-table [dataSource]="destinationsSource"
      aria-describedby="guideModaltableShipperRequestTable">
      <ng-container matColumnDef="stopping">
        <th id="stopping" mat-header-cell *matHeaderCellDef> {{ viewTags.stop }}</th>
        <td mat-cell *matCellDef="let element; let guideIndex = index" matTooltip="{{ (guideIndex + defaultStop) }}">
          <div>  {{ (guideIndex + defaultStop) }} </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th id="state" mat-header-cell *matHeaderCellDef> {{ viewTags.state }} </th>
        <td mat-cell *matCellDef="let ShipmentRequest" matTooltip="{{ ShipmentRequest.state ? ShipmentRequest.state : labels.noInfo }}">
          <div> {{ ShipmentRequest.state ? ShipmentRequest.state : labels.noInfo }} </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="municipality">
        <th id="municipality" mat-header-cell *matHeaderCellDef> {{ viewTags.municipality }} </th>
        <td mat-cell *matCellDef="let ShipmentRequest" matTooltip="{{ ShipmentRequest.city ? ShipmentRequest.city : labels.noInfo }}">
          <div> {{ ShipmentRequest.city ? ShipmentRequest.city : labels.noInfo }} </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="suburb">
        <th id="suburb" mat-header-cell *matHeaderCellDef> {{ viewTags.suburb }} </th>
        <td mat-cell *matCellDef="let ShipmentRequest" matTooltip="{{ labels.noInfo }}">
          <div> {{ ShipmentRequest.cargoType ? ShipmentRequest.cargoType : labels.noInfo }} </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="postalCode">
        <th id="postalCode" mat-header-cell *matHeaderCellDef> {{ viewTags.postalCode }} </th>
        <td mat-cell *matCellDef="let ShipmentRequest" matTooltip="{{ ShipmentRequest.postalCode ? ShipmentRequest.postalCode : labels.noInfo }}">
          <div> {{ ShipmentRequest.postalCode ? ShipmentRequest.postalCode : labels.noInfo }} </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th id="address" mat-header-cell *matHeaderCellDef> {{ viewTags.address }} </th>
        <td mat-cell *matCellDef="let ShipmentRequest" matTooltip="{{ ShipmentRequest.address ? ShipmentRequest.address : labels.noInfo }}">
          <div> {{ ShipmentRequest.address ? ShipmentRequest.address : labels.noInfo }} </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    </mat-tab>
  </mat-tab-group>
</div>
