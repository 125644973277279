import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogStandardBackgroundInfoLabels } from '../../../interfaces/labels/dialog-standar-background-info.interface';
import { DialogStandardFocus } from '../../../enums/dialog-stantdard-focus';

@Component({
  selector: 'app-dialog-standard-background-info',
  styleUrls: ['./dialog-standard-background-info.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-standard-background-info.component.html'
})

/**
 * @description Dialog Standard with filled background and icons.
 */
export class DialogStandardBackgroundInfoComponent implements OnInit {
  public sanitizedResume: SafeHtml;

  /**
   * @description Contains instances from providers used in this component.
   * @param {MatDialogRef<DialogStandardBackgroundInfoComponent>} dialogRef - Reference to opened dialog.
   * @param {MAT_DIALOG_DATA} data - Data provided to display in this dialog.
   * @param {DomSanitizer} sanitizer - Service to sanitize html elements in order to maintain security.
   */
  constructor(
    public dialogRef: MatDialogRef<DialogStandardBackgroundInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogStandardBackgroundInfoLabels,
    private sanitizer: DomSanitizer
  ) {}

  /**
   * @description Angular lifecycle for component initialization.
   */
  public ngOnInit(): void {
    if (this.data && this.data.resume) {
      this.sanitizedResume = this.sanitizer.bypassSecurityTrustHtml(this.data.resume);
    }
  }

  /**
   * @description Action when cancel button is clicked.
   */
  public onClickCancelButton(): void {
    this.dialogRef.close(DialogStandardFocus.CANCEL);
  }

  /**
   * @description Action when continue button is clicked.
   */
  public onClickContinueButton(): void {
    this.dialogRef.close(DialogStandardFocus.CONFIRM);
  }
}
