export const DialogAssignTransportLabels: IDialogAssignTransportLabels = {
  cancelButton: 'Cancelar',
  dateTimeSubtitle: 'Fecha y hora viaje',
  destination: 'Destino',
  distanceTraveled: 'Distancia recorrida',
  distribution: 'Repartos',
  driverQuestion: '¿Qué Operador lo va a realizar?',
  errorAssign: 'Ocurrió un error al asignar el transporte',
  kilometers: 'km',
  origin: 'Origen',
  transportQuestion: '¿Cuál va a ser el Transporte para esta solicitud?',
  routeSubtitle: 'Ruta',
  sendButton: 'Enviar',
  successAssign: 'Solicitud asignada correctamente.',
  title: 'Configurar Viaje para Solicitud',
  totalDistance: 'Distancia total'
};

export interface IDialogAssignTransportLabels {
  cancelButton: string;
  dateTimeSubtitle: string;
  destination: string;
  distanceTraveled: string;
  distribution: string;
  driverQuestion: string;
  errorAssign: string;
  kilometers: string;
  origin: string;
  routeSubtitle: string;
  sendButton: string;
  successAssign: string;
  title: string;
  totalDistance: string;
  transportQuestion: string;
}
