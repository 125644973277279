import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges, } from '@angular/core';

const EMPTY = '';
const WHITE_SPACE = ' ';
const SLASH = '/';
const DOT = '.';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective implements OnChanges {
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  @Input() decimal = false;
  @Input() negative = false;
  @Input() min = -Infinity;
  @Input() max = Infinity;
  @Input() pattern?: string | RegExp;

  inputElement: HTMLInputElement;

  private decimalSeparator = DOT;
  private regex: RegExp;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.pattern) {
      if (this.pattern.toString().startsWith(SLASH)) {
        const tempPattern = this.pattern.toString().split(SLASH);
        if (tempPattern[0] === EMPTY) {
          tempPattern.shift();
        }
        if (tempPattern[tempPattern.length - 1] === EMPTY) {
          tempPattern.pop();
        }
        this.pattern = tempPattern.join(SLASH);
      }
      this.regex = this.pattern ? RegExp(this.pattern) : null;
    }
    if (changes.min) {
      const maybeMin = Number(this.min);
      this.min = isNaN(maybeMin) ? -Infinity : maybeMin;
    }

    if (changes.max) {
      const maybeMax = Number(this.max);
      this.max = isNaN(maybeMax) ? Infinity : maybeMax;
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): any {
    if (this.navigationKeys.indexOf(e.key) > -1) {
      return;
    }

    let newValue = EMPTY;

    if (this.decimal && e.key === this.decimalSeparator) {
      newValue = this.forecastValue(e.key);
      if (newValue.split(this.decimalSeparator).length > 2) {
        e.preventDefault();
        return;
      } else {
        return;
      }
    }

    if (e.key === WHITE_SPACE || isNaN(Number(e.key))) {
      e.preventDefault();
      return;
    }

    newValue = newValue || this.forecastValue(e.key);
    if (this.regex) {
      if (!this.regex.test(newValue)) {
        e.preventDefault();
        return;
      }
    }

    const newNumber = Number(newValue);
    if (newNumber > this.max || newNumber < this.min) {
      e.preventDefault();
    }
  }

  private forecastValue(key: string): string {
    const selectionStart = this.inputElement.selectionStart;
    const selectionEnd = this.inputElement.selectionEnd;
    const oldValue = this.inputElement.value;
    const selection = oldValue.substring(selectionStart, selectionEnd);
    return selection ? oldValue.replace(selection, key)
      : oldValue.substring(0, selectionStart) + key + oldValue.substring(selectionStart);
  }
}
