import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BillingSheetFilters,
  BillingSheetFolioResponse,
  BillingSheetFreightInvoice,
  BillingSheetGetResponse,
  BillingSheetPostResponse,
  BillingSheetRequest,
  BillingSheetResponse,
  IBillingSheetPost
} from '../../interfaces';
import { Charge } from '../../interfaces/charge';
import { environment } from '../../../environments/environment';

const freightRateApiUrl = environment.freightRateApiUrl;
const TMS_FREIGHTCOST_API = environment.tmsFreightCostApiUrl;
const apiUrl = environment.apiUrl;

@Injectable()
export class BillingSheetProvider {

  constructor(private http: HttpClient) {}

  public async getShipmentsByIntervalAndCarrierId(searchData): Promise<object> {
    return await this.http.post<object>(apiUrl + '/shipment/costedShipments', searchData).toPromise();
  }

  public async getChargesByTenantId(tenantId: string): Promise<Array<Charge>> {
    return await this.http.get<Array<Charge>>(freightRateApiUrl + '/charge/tenant/' + tenantId).toPromise();
  }

  /**
   * @description Saves the new billing sheet data on db.
   * @param {BillingSheetRequest} billingSheet List of generated billing sheets to save on db
   * @param {string} tenantId Required id to save data
   * @returns {Promise<BillingSheetResponse>} Response of freight cost api that contains inserted data on db
   */
  public async createBillingSheet(tenantId: string, billingSheet: BillingSheetRequest): Promise<BillingSheetResponse> {
    return this.http.post<BillingSheetResponse>(TMS_FREIGHTCOST_API + '/billingSheets/tenant/' + tenantId, billingSheet).toPromise();
  }

  /**
   * @description Saves billing sheet data on db
   * @param {object} billingSheets List of generated billing sheets to save on db
   * @param {string} tenantId Required id to save data
   * @returns {object} Response of freight cost api that contains inserted data on db
  **/
  public async saveBillingSheet(billingSheets: Object, tenantId: string): Promise<object> {
    return await this.http.post<object>(freightRateApiUrl + '/billing/tenant/' + tenantId, billingSheets).toPromise();
  }

  public async updateShipmentsStatus(shipmentId: any, body: any): Promise<any> {
    return await this.http.put<Object>(apiUrl + '/solicitudes/' + shipmentId + '/estatus', body).toPromise();
  }

  public async loadDocumentFile(tenantId: any, shipmentFolio: any, document: any): Promise<Object> {
    // TODO When Costing MS will have a blobstorage method, change this petition if necessary
    return this.http.post(freightRateApiUrl + '/tenantId/' + tenantId + '/shipmentFolio/' + shipmentFolio, document);
  }

  public async getBillingSheetByFolio(tenantId: string, isFolio: boolean,
    folio: string, shipment: string, beginDate: string, endDate: string): Promise<Array<IBillingSheetPost>> {
    return this.http.get<Array<IBillingSheetPost>>(freightRateApiUrl + '/billing/tenant/' + tenantId + '?isFolio=' + isFolio + '&folio='
    + folio + '&shipment=' + shipment + '&beginDate=' + beginDate + '&endDate=' + endDate).toPromise();
  }

  /**
   * @description Searchs billing sheets by folio or by carriers in begin-end date range.
   * @param {string} tenantOId - ObjectId from shipper is making query.
   * @param {BillingSheetFilters} queryFilters - Filters to make the search of billing sheets.
   * @returns {Promise<BillingSheetGetResponse>} Endpoints response. Billing sheets found with params provided.
   */
  public async searchBillingSheets(tenantOId: string, queryFilters: BillingSheetFilters): Promise<BillingSheetGetResponse> {
    return this.http.get<BillingSheetGetResponse>(freightRateApiUrl + `/billingSheets/tenantOId/${tenantOId}` +
      `?isSearchByFolio=${queryFilters.isSpecificSearch}&folio=${queryFilters.folio}&beginDate=${queryFilters.beginDate}` +
      `&endDate=${queryFilters.endDate}&carriersOid=${queryFilters.carriers}`).toPromise();
  }

  /**
   * @description Searchs details of billing sheet folio provided, like charges in of shipment's billing sheet.
   * @param {string} tenantOId - OId of tenant.
   * @param {string} billingSheet - Folio of billing sheet to search details.
   * @returns {Promise<BillingSheetGetResponse>} Response of endpoint with details of billing sheet.
   */
  public async getBillingSheetDetails(tenantOId: string, billingSheet: string): Promise<BillingSheetGetResponse> {
    return this.http.get<BillingSheetGetResponse>(freightRateApiUrl + `/billingSheets/tenantOId/${tenantOId}` +
      `/billingSheet/${billingSheet}`).toPromise();
  }

  /**
   * @description Get the billing sheet by tenant and range of dates
   * @param {string} tenantId Required id to find data
   * @param {Date | string} startDate Initial date to find
   * @param {Date | string} endDate end date to find
   * @returns {BillingSheetPostResponse} Response of billing sheet api that contains inserted data on db
   */
  public async getBillingSheetByRangeCreationDate(tenantId: string, startDate: Date | string, endDate: Date | string): Promise<BillingSheetPostResponse> {
    return await this.http.get<BillingSheetPostResponse>(
      freightRateApiUrl + '/billingSheet/tenant/' + tenantId + '?startDate=' + startDate + '&endDate=' + endDate
    ).toPromise();
  }

  /**
   * @description Set the invoice and invoice notes to billing sheet.
   * @param {string} tenantId - Required id to find data.
   * @param {BillingSheetFreightInvoice} billingSheetInvoice - Data to updated in billing sheet.
   * @returns {Promise<BillingSheetPostResponse>} Response of updated billing sheet.
   */
  public async setInvoiceBillingSheet(tenantId: string, billingSheetInvoice: BillingSheetFreightInvoice): Promise<BillingSheetPostResponse> {
    const url = freightRateApiUrl + '/billingSheets/tenant/' + tenantId + '/invoice/';
    return await this.http.put<BillingSheetPostResponse>(url, billingSheetInvoice).toPromise();
  }

  /**
   * @description Generate a new billing sheet folio using prefix, date in format YYYYMMDD and sequence value provided by database.
   * @param {string} date - Date pipe to build folio.
   * @returns {Promise<BillingSheetFolioResponse>} Response with built billing sheet folio.
   */
  public async generateBillingSheetFolio(date: string): Promise<BillingSheetFolioResponse> {
    const url = `${freightRateApiUrl}/date/${date}/billingSheetFolio`;

    return await this.http.get<BillingSheetFolioResponse>(url).toPromise();
  }

  /**
   * @description Searches for billing sheets based on the provided tenant Id and filters.
   * @param {string} tenantId - The tenant Id to filter the billing sheets.
   * @param {BillingSheetFilters} filters - The filters to apply to the billing sheet search.
   * @returns {Promise<BillingSheetResponse>} - The response containing the billing sheets.
   */
  public async searchBillingSheeet(tenantId: string, filters: BillingSheetFilters): Promise<BillingSheetResponse> {
    const url = `${TMS_FREIGHTCOST_API}/billingSheets/tenant/${tenantId}/invoices?isSearchByFolio=${filters.isSpecificSearch}`;

    return this.http.post<BillingSheetResponse>(url, filters).toPromise();
  }
}
