import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { DIALOG_SHIPMENT_CREATION_PROPERTIES } from './dialog-shipment-creation.properties';
import { DialogShipmentCreation } from '../../../interfaces';
import { DialogStandardFocus } from '../../../enums/dialog-stantdard-focus';

@Component({
  selector: 'app-dialog-shipment-creation',
  styleUrls: ['./dialog-shipment-creation.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-shipment-creation.component.html'
})

/**
 * Dialog component for creating shipments.
 * It has the option of continue creating shipments after created one.
 */
export class DialogShipmentCreationComponent {
  public isChecked: boolean;

  /**
   * @description Contains intances from providers used in this component.
   * @param {MatDialogRef<DialogShipmentCreationComponent>} dialogRef - Reference to opened dialog.
   * @param {MAT_DIALOG_DATA} data - Data provided from shipment creation component.
   */
  constructor(public dialogRef: MatDialogRef<DialogShipmentCreationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogShipmentCreation) {
    this.isChecked = false;
  }

  /**
   * @description Action when cancel button is clicked.
   */
  public onClickCancelButton(): void {
    this.dialogRef.close(DialogStandardFocus.CANCEL);
  }

  /**
   * @description Action when continue button is clicked.
   */
  public onClickContinueButton(): void {
    if (this.isChecked) {
      this.dialogRef.close(DIALOG_SHIPMENT_CREATION_PROPERTIES.confirmAndContinue);
    } else {
      this.dialogRef.close(DialogStandardFocus.CONFIRM);
    }
  }

  /**
   * @description Action for continue creating shipments checkbox when it changes its value.
   * @param {MatCheckboxChange} event - State of the continue planning shipments checkbox.
   */
  public onContinuePlanningShipments(event: MatCheckboxChange): void {
    this.isChecked = event.checked;
  }
}
