import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { RegionsReponse } from '../../interfaces';

const API_URL = environment.apiUrl;

/**
 * Region provider service.
 */
@Injectable()
export class RegionProvider {
  /**
   * @description Region provider service.
   * @param {HttpClient} http - Instance to make http request.
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * @description Get all the regions available.
   * @returns {RegionsReponse} - Regions found and success message.
   */
  public async getAllRegions(): Promise<RegionsReponse> {
    return await this.http.get<RegionsReponse>(`${API_URL}/regions`).toPromise();
  }
}
