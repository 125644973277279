import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogChargeValidation } from '../../../interfaces/invoiceProposal';
import { DialogStandardFocus } from './../../../enums/dialog-stantdard-focus';

const ICON_PATH = '../../../../assets/icons/icon-paid.svg';

@Component({
  selector: 'app-dialog-charge-validation',
  styleUrls: ['./dialog-charge-validation.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-charge-validation.component.html'
})

/**
 * Class to display the dialog to accept/reject charges and discounts.
 */
export class DialogChargeValidationComponent {
  /**
   * @description Builds an instance of all the services necessary for this component to work correctly.
   * @param {DialogChargeValidation} data - Data provided from previous component.
   * @param {MatDialogRef<DialogChargeValidationComponent>} dialogRef - Reference to opened dialog.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogChargeValidation,
    public dialogRef: MatDialogRef<DialogChargeValidationComponent>
  ) {
    this.iconPath = ICON_PATH;
  }

  /**
   * @description Fires when user clicks on accept button.
   */
  public onClickAccept(): void {
    this.dialogRef.close(DialogStandardFocus.CONFIRM);
  }

  /**
   * @description Fires when user clicks on cancel button.
   */
  public onClickCancel(): void {
    this.dialogRef.close(DialogStandardFocus.CANCEL);
  }

  public iconPath: string;
}
