import { Pipe, PipeTransform } from '@angular/core';
import { LanguageTranslateService } from '../services/translate/language-translate.service';

const VALUES = {
  inBillingSheet: 'En Sabana',
  inTransit: 'En Transito'
};
const SPANISH_LABELS = {
  IN_TRANSIT: 'En Tránsito',
  IN_BILLING_SHEET: 'En Sábana'
};
const ENGLISH_LABELS = {
  IN_TRANSIT: 'In Transit',
  IN_BILLING_SHEET: 'In Billing Sheet'
};

@Pipe({
  name: 'correctMisspelling'
})
export class CorrectMisspellingPipe implements PipeTransform {
  constructor (
    private _languageTranslateService: LanguageTranslateService
  ) { }

  transform(value: string): string {
    const language = this._languageTranslateService.getLanguage();
    if (value === VALUES.inTransit) {
      return language === 'es' ? SPANISH_LABELS.IN_TRANSIT : ENGLISH_LABELS.IN_TRANSIT;
    } else if (value === VALUES.inBillingSheet) {
      return language === 'es' ? SPANISH_LABELS.IN_BILLING_SHEET : ENGLISH_LABELS.IN_BILLING_SHEET;
    }
    return value;
  }
}
