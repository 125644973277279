export const ShipperShipmentRequestProperties = {
  box: '../../../assets/icons/modeList/box.svg',
  group: '../../../assets/icons/group.svg',
  person: '../../../assets/icons/modeList/person.svg',
  phone: '../../../assets/icons/modeList/phone.svg',
  pin: '../../../assets/icons/modeList/pin.svg',
  plate: '../../../assets/icons/modeList/plates-2.svg',
  money: '../../../assets/icons/modeList/attach_money_black.svg',
  number: '../../../assets/icons/modeList/number.svg',
  search: '../../../assets/search.svg',
  shipment: '../../../assets/icons/modeList/newShipment.svg',
  tracing: '../../../assets/icons/modeList/tracing.svg',
  vehicle: '../../../assets/icons/modeList/vehicle.svg',
  weigth: '../../../assets/icons/modeList/weight.svg',
};
