import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogCheck } from '../../../interfaces/dialog-check-temperatures';

const CANCEL = 'Cancel';
const CONFIRM = 'Confirm';
const CLOSED = 'Closed';

@Component({
  selector: 'app-dialog-check-temperatures',
  templateUrl: './dialog-check-temperatures.component.html',
  styleUrls: ['./dialog-check-temperatures.component.scss', '../../../app.component.scss']
})
export class DialogCheckTemperaturesComponent {

  constructor(public dialogRef: MatDialogRef<DialogCheckTemperaturesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogCheck ) { }

  public onClickButton1(): void {
    this.dialogRef.close(CANCEL);
 }

  public onClickButton2(): void {
    this.dialogRef.close(CONFIRM);
 }

  public onClickClose(): void {
    this.dialogRef.close(CLOSED);
 }
}
