<span *ngIf = "carouselVisible">
  <span *ngIf = "carouselSelectVisible">
    <mat-grid-list cols="3" rowHeight="2:1">
      <mat-grid-tile colspan="3" rowspan="1">
        <img src="{{images[actualPosition]}}" alt="Actual picture">
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
          <mat-icon (click)="previousPicture(indexPosition)" style="cursor: pointer">
            <img src='../../../assets/back.svg' alt="Previous picture">
          </mat-icon>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <span *ngFor="let icon of images; let indexPosition = index">
            <span *ngIf="actualPosition===indexPosition; else unselected">
              <mat-icon (click)="onClickSelect(indexPosition)" style="cursor: pointer">
                <img src='../../../assets/selected.svg' alt="Selected picture">
              </mat-icon>
            </span>
            <ng-template #unselected>
              <mat-icon (click)="onClickSelect(indexPosition)" style="cursor: pointer">
                <img src='../../../assets/unselected.svg' alt="Unselected picture">
              </mat-icon>
            </ng-template>
        </span>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
          <mat-icon (click)="nextPicture(indexPosition)" style="cursor: pointer" >
            <img src='../../../assets/next.svg' alt="Next picture">
          </mat-icon>
      </mat-grid-tile>
    </mat-grid-list>
  </span>
  
  <span *ngIf = "!carouselSelectVisible">
    <mat-grid-list cols="2" rowHeight="2:1">
      <mat-grid-tile colspan="2" rowspan="1">
        <img src="{{images[actualPosition]}}" alt="Actual position">
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
          <mat-icon (click)="previousPicture(indexPosition)" style="cursor: pointer">
            <img src='../../../assets/back.svg' alt="Previous position">
          </mat-icon>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
          <mat-icon (click)="nextPicture(indexPosition)" style="cursor: pointer" >
            <img src='../../../assets/next.svg' alt="Next position">
          </mat-icon>
      </mat-grid-tile>
    </mat-grid-list>
  </span>
</span>