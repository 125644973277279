import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import {
  LoadAllocationBodyRequest,
  LoadAllocationResponse,
  OrderPrioritizationBodyRequest,
  OrderPrioritizationResponse
} from '../../interfaces/index';

const SERVERLESS_URL = environment.serverlessUrl;

@Injectable()
export class ServerlessProvider {

  constructor(
    private http: HttpClient,
    ) {}

  public async podConfiguration(body: object): Promise<object> {
    return await this.http.post<object>(SERVERLESS_URL + '/podConfiguration', body).toPromise();
  }

  /**
   * @description Send a request to serverless in order to create a CSV file in a FTP
   * @param {OrderPrioritizationBodyRequest} body Information to send for order prioritization CSV file
   * @returns {Promise<OrderPrioritizationResponse>} Object with code and message response
   */
  public async orderPrioritization(body: OrderPrioritizationBodyRequest): Promise<OrderPrioritizationResponse> {
    return await this.http.post<OrderPrioritizationResponse>(SERVERLESS_URL + '/orderPrioritization', body).toPromise();
  }

  /**
   * @description Send a request to serverless in order to create a CSV file in a FTP with load allocation
   * @param {LoadAllocationBodyRequest} body Information to send for load allocation CSV file
   * @returns {Promise<LoadAllocationResponse>} Object with code and message response
   */
  public async loadAllocation(body: LoadAllocationBodyRequest): Promise<LoadAllocationResponse> {
    return await this.http.post<LoadAllocationResponse>(SERVERLESS_URL + '/loadAllocation', body).toPromise();
  }
}
