export enum StarRatingColor {
  accent = 'accent',
  primary = 'primary',
  warn = 'warn'
}

export enum styleStar {
  border = 'star_border',
  fill = 'star'
}
