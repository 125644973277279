export const environment = {

  production: window['appGlobalEnv']['production'],
  nodeEnv: window['appGlobalEnv']['nodeEnv'],

  appInsightsInstrumentationKey: window['appGlobalEnv']['appInsightsInstrumentationKey'],
  appInsightsRoleName: window['appGlobalEnv']['appInsightsRoleName'],
  appInsightsEnabled: window['appGlobalEnv']['appInsightsEnabled'],
  apiUrl: window['appGlobalEnv']['apiUrl'],
  baseStorageUrl: window['appGlobalEnv']['baseStorageUrl'],
  cmrConsignmentNoteApiUrl: window['appGlobalEnv']['cmrConsignmentNoteApiUrl'],
  mainContainer: window['appGlobalEnv']['mainContainer'],
  evidenceContainer: window['appGlobalEnv']['evidenceContainer'],
  evidencesContainer: window['appGlobalEnv']['evidencesContainer'],
  trackingUrl: window['appGlobalEnv']['trackingUrl'],
  freightRateApiUrl: window['appGlobalEnv']['freightRateApiUrl'],
  evidenceApiUrl: window['appGlobalEnv']['evidenceApiUrl'],
  tmsEvidenceApiUrl: window['appGlobalEnv']['tmsEvidenceApiUrl'],
  tmsFreightCostApiUrl: window['appGlobalEnv']['tmsFreightCostApiUrl'],
  tmsIntegratorApiUrl: window['appGlobalEnv']['tmsIntegratorApiUrl'],
  orderApiUrl: window['appGlobalEnv']['orderApiUrl'],
  searsApiUrl:  window['appGlobalEnv']['searsApiUrl'],
  shipmentApiUrl: window['appGlobalEnv']['shipmentApiUrl'],
  clientUrl: window['appGlobalEnv']['clientUrl'],
  googleMaps: window['appGlobalEnv']['googleMaps'],
  roadnetApiUrl: window['appGlobalEnv']['roadnetApiUrl'],
  languageEnabled: window['appGlobalEnv']['languageEnabled'],
  labelApiUrl: window['appGlobalEnv']['labelApiUrl'],
  limitRecordsLoad: window['appGlobalEnv']['limitRecordsLoad'],
  serverlessUrl: window['appGlobalEnv']['serverlessUrl'],
  secondaryBlobStorage: window['appGlobalEnv']['secondaryBlobStorage'],
  shipmentRequestApiUrl: window['appGlobalEnv']['shipmentRequestApiUrl'],
  courierConnectionUrl: window['appGlobalEnv']['courierConnectionUrl'],
  tmsIntegratorSystemOid: window['appGlobalEnv']['tmsIntegratorSystemOid'],
  ssoAdminApiUrl: window['appGlobalEnv']['ssoAdminApiUrl'],
  ssoApiUrl: window['appGlobalEnv']['ssoApiUrl'],
  ssoMicroservice: window['appGlobalEnv']['ssoMicroservice'],
  ssoRealm: window['appGlobalEnv']['ssoRealm'],
  firebase: {
    apiKey: window['appGlobalEnv']['apiKey'],
    authDomain: window['appGlobalEnv']['authDomain'],
    databaseURL: window['appGlobalEnv']['databaseURL'],
    projectId: window['appGlobalEnv']['projectId'],
    storageBucket: window['appGlobalEnv']['storageBucket'],
    messagingSenderId: window['appGlobalEnv']['messagingSenderId'],
    appId: window['appGlobalEnv']['appId']
  },
  notificationApiUrl: window['appGlobalEnv']['notificationApiUrl'],
  tmsWebappMedistikCmr: window['appGlobalEnv']['tmsWebappMedistikCmr'],
  netprintLogoUrl: window['appGlobalEnv']['netprintLogoUrl'],
  evidencesStorageUrl: window['appGlobalEnv']['evidencesStorageUrl'],
  ssoLoginUrl: window['appGlobalEnv']['ssoLoginUrl'],
  supplynetCoreApiUrl: window['appGlobalEnv']['supplynetCoreApiUrl'],
  solutionId: window['appGlobalEnv']['solutionId'],
  solutionName: window['appGlobalEnv']['solutionName'],
};
