import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AccountBillingScheme, AccountBillingSchemeResponse, AccountBillingSchemeUpdate } from '../../interfaces/account-billing-scheme';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';

const tmsFreightCostApiUrl = environment.tmsFreightCostApiUrl;

@Injectable()
export class AccountBillingSchemeProvider {

  constructor(private http: HttpClient, private _appService: AppService) { }

  /**
   * @description Gets all related shipper billing schemes by given shipperId
   * @param {string} shipperId - Optional Param to get related accounts
   * @returns {Promise<AccountBillingSchemeResponse>} Shipper found accounts
   */
  public async getAccountBillingSchemes(shipperId?: string): Promise<AccountBillingSchemeResponse> {
    const shipperOid = shipperId ?? this._appService.getShipperOid();
    return await this.http.get<AccountBillingSchemeResponse>(`${tmsFreightCostApiUrl}/accountBillingSchemes/shipper/${shipperOid}`).toPromise();
  }

  /**
   * @description Generate request to create a new billing scheme
   * @param {AccountBillingScheme} body to send in the request
   * @returns {Promise<AccountBillingSchemeResponse>} response of shipper
   */
  public async createAccountBillingScheme(body: AccountBillingScheme): Promise<AccountBillingSchemeResponse> {
    return await this.http.post<AccountBillingSchemeResponse>(`${tmsFreightCostApiUrl}/accountBillingSchemes/`, body).toPromise();
  }

  /**
   * @description Generate request to update a billing scheme data
   * @param {AccountBillingScheme} body An object with info with previous and new data from account billing scheme edited.
   * Also user who's making changes in account billing scheme.
   * @returns {Promise<AccountBillingSchemeResponse>} Response of endpoint
   */
  public async updateAccountBillingScheme(billingSchemeOId: string, body: AccountBillingSchemeUpdate): Promise<AccountBillingSchemeResponse> {
    return await this.http.put<AccountBillingSchemeResponse>(`${tmsFreightCostApiUrl}/accountBillingSchemes/` + billingSchemeOId, body).toPromise();
  }

  /**
   * @description Generate request to delete a billing scheme data.
   * @param {AccountBillingScheme} body - An object with info with previous and new data from account billing scheme deleted.
   * @returns {Promise<AccountBillingSchemeResponse>} - Response of endpoint.
   */
  public async deleteAccountBillingScheme(billingSchemeOId: string, body: AccountBillingSchemeUpdate): Promise<AccountBillingSchemeResponse> {
    return await this.http.put<AccountBillingSchemeResponse>(`${tmsFreightCostApiUrl}/accountBillingSchemes/delete/${billingSchemeOId}`, body).toPromise();
  }

  /**
   * @description Generate request to delete a concept from billing scheme.
   * @param {string} billingSchemeOid -Object id of account billing scheme data.
   * @param {string} conceptOid - Object id of concept data.
   * @returns {Promise<AccountBillingSchemeResponse>} - Response of endpoint.
   */
  public async deleteConceptById(billingSchemeOid: string, conceptOid: string): Promise<AccountBillingSchemeResponse> {
    return await this.http.delete<AccountBillingSchemeResponse>(
      `${tmsFreightCostApiUrl}/accountBillingSchemes/${billingSchemeOid}/concept/${conceptOid}`).toPromise();
  }

  /**
   * @description Get the account billing scheme by account id.
   * @param {string} accountId - Account id to find.
   * @returns {Promise<AccountBillingSchemeResponse>} - Response of endpoint.
   */
  public async getAccountBillingSchemeByAccountId(accountId: string): Promise<AccountBillingSchemeResponse> {
    return await this.http.get<AccountBillingSchemeResponse>(`${tmsFreightCostApiUrl}/accountBillingSchemes/account/${accountId}`).toPromise();
  }
}
