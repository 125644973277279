<div>
  <mat-grid-list cols="2" rowHeight="1:.10">
    <mat-grid-tile colspan="2" rowspan="1">
      <span class="dialog-title">
        <p>
          {{ 'accountBillingSchemeCreateTags.titleDialogVariable' | translate }}
        </p>
      </span>
    </mat-grid-tile>
  </mat-grid-list>
  <form [formGroup]="chargeVariableCreateGroup">
    <mat-grid-list cols="2" rowHeight="1:.40">
      <mat-grid-tile>
        <mat-form-field>
          <mat-select
            id="concept"
            #concept
            placeholder="{{ 'accountBillingSchemeCreateTags.concept' | translate }}"
            required
            formControlName="concept">
            <mat-option>--</mat-option>
            <mat-option *ngFor="let conceptElement of conceptList" [value]="conceptElement">
              {{ conceptElement.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="chargeVariableCreateGroup.get('concept').hasError('required')">
            <span>
              {{ 'accountBillingSchemeCreateTags.errorLineConcept' | translate }}
            </span>
            <strong>
              {{ 'accountBillingSchemeCreateTags.errorRequiredSuffixO' | translate }}
            </strong>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field>
          <input
            id="unitKey"
            #unitKey
            readonly
            disabled
            formControlName="unitKey"
            matInput
            placeholder="{{ 'accountBillingSchemeCreateTags.unitKey' | translate }}">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field>
          <mat-select
            id="tripType"
            placeholder="{{ 'accountBillingSchemeCreateTags.tripType' | translate }}"
            multiple
            #allTypeTripSelected
            disableOptionCentering="true"
            formControlName="tripType">
            <mat-option
              class="select-all"
              [value]="defaultValueTypeTrip"
              (click)="selectAll(allTypeTripSelected)"
              *ngIf="typeTripList && typeTripList.length">
              {{ 'accountBillingSchemeCreateTags.selectAll' | translate }}
            </mat-option>
            <mat-option *ngFor="let tripType of typeTripList" [value]="tripType.value">
              {{ tripType.label }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="chargeVariableCreateGroup.get('tripType').hasError('required')">
            <span>
              {{ 'accountBillingSchemeCreateTags.errorLineTypeTrip' | translate }}
            </span>
            <strong>
              {{ 'accountBillingSchemeCreateTags.errorRequiredSuffixO' | translate }}
            </strong>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field>
          <input
            id="cost"
            #cost
            type="number"
            formControlName="cost"
            matInput
            required
            placeholder="{{ 'accountBillingSchemeCreateTags.cost' | translate }}">
          <mat-error
            *ngIf="chargeVariableCreateGroup?.get('cost')?.hasError('invalid_number')">
            <span>
              {{ 'accountBillingSchemeCreateTags.errorLineCost' | translate }}
            </span>
            <strong>
              {{ 'accountBillingSchemeCreateTags.errorRequiredSuffixO' | translate }},
              {{ 'accountBillingSchemeCreateTags.errorLineCostComplement' | translate }}
            </strong>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
  <div class="cards-container-button">
    <div class="buttons">
      <button
        id="cancel"
        mat-stroked-button
        color="primary"
        class="secondary-button m-r-10"
        (click)="onCancel()">
        {{ 'accountBillingSchemeCreateTags.cancel' | translate }}
      </button>
      <button
        id="save"
        mat-raised-button
        color="primary"
        class="primary-button"
        (click)="onSave()"
        [disabled]="chargeVariableCreateGroup?.invalid">
        {{ 'accountBillingSchemeCreateTags.add' | translate }}
      </button>
    </div>
  </div>
</div>
