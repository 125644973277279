import { Injectable } from '@angular/core';

import { isEqual as _isEqual, isNil as _isNil } from 'lodash';

import { AppConstants } from '../../constants/app-constants.constants';
import { AppService } from '../../app.service';
import { BILLING_SHEET_CONSTANTS } from '../../pages/costs/billing-sheet/billing-sheet.properties';
import { RoleInUser } from '../../interfaces/role';
import { RoleProvider } from '../../providers/role/role-provider.service';
import { ShipmentCostChargeData, ShipmentCostData, ShipmentCostResult } from '../../interfaces/shipment-cost-data';
import { View } from '../../interfaces/view';
import { ViewProvider } from '../../providers/view/view-provider.service';

/**
 * @description A utility service that provides methods for work a costing sheet module.
 */
@Injectable()
export class CostingSheetService {
  /**
   * @description Initializes the variables of the class when it is instantiated.
   * @param {AppService} appService - Obtains the necessary information from the authenticated tenant in the system.
   * @param {RoleProvider} roleService - Service to get the methods to work with roles.
   * @param {ViewProvider} viewProvider - Service to get the methods to work with views.
   */
  constructor(
    private appService: AppService,
    private roleService: RoleProvider,
    private viewProvider: ViewProvider
  ) { }

  /**
   * @description Converts a data object into a ShipmentCostResult object.
   * @param {Object} data - The input data object containing shipment cost details.
   * @returns {ShipmentCostResult} - The converted ShipmentCostResult object.
   */
  public convertToShipmentCostResult(data: object): ShipmentCostResult {
    const shipmentCostData: ShipmentCostResult = {} as ShipmentCostResult;
    shipmentCostData.id = data['id'];
    shipmentCostData.accountId = data['accountId'];
    shipmentCostData.accountName = data['accountName'];
    shipmentCostData.additionalChargeId = data['additionalChargeId'];
    shipmentCostData.additionalChargeName = data['additionalChargeName'];
    shipmentCostData.chargeId = data['chargeId'];
    shipmentCostData.chargeName = data['chargeName'];
    shipmentCostData.charge = data['charge'] || this.convertToPositive(data['amount']);
    shipmentCostData.isDiscountAmount = data['isDiscountAmount'] ?
      data['isDiscountAmount'] : this.isNegative(data['charge'] || data['amount']);
    shipmentCostData.dateCreated = data['dateCreated'] || data['createdDate'];
    shipmentCostData.userCreate = data['userCreate'] || data['createdBy'];
    shipmentCostData.dateUpdated = data['dateUpdated'] || data['updatedDate'];
    shipmentCostData.userUpdate = data['userUpdate'] || data['updatedBy'];
    shipmentCostData.status = data['status'] || data['statusName'];
    shipmentCostData.statusId = data['statusId'];
    shipmentCostData.tenantId = data['tenantId'];
    shipmentCostData.isFreight = data['isFreight'];
    shipmentCostData['isActive'] = data['isActive'];
    shipmentCostData.chargeEstimated = data['chargeEstimated'] || data['freightCostEstimate'];
    shipmentCostData.supplierId = data['supplierId'];
    shipmentCostData.supplierName = data['supplierName'];

    return shipmentCostData;
  }

  /**
   * @description Gets all registered views and set isAssigned property for modules and views.
   * @returns {Promise<Array<View>>} Array of views.
   */
  public async getViews(): Promise<Array<View>> {
    const views: Array<string> = [];
    const newViews: Array<View> = [];
    let viewData = await this.viewProvider.getViews();
    const result: RoleInUser = await this.appService.getUserRole();

    if (result) {
      const role = await this.roleService.getRoleByOId(result._id);

      if (role) {
        role['view'].forEach((view: View) => {
          views.push(view._id);
        });
      }

      views.forEach((id: string) => {
        const viewAvailable = viewData.find((view: View) => {
          return _isEqual(view._id, id);
        });

        if (viewAvailable) {
          newViews.push(viewAvailable);
        }
      });
      viewData = newViews;
    }

    return viewData;
  }

  /**
   * @description Validates if the views array contains both the Cost review and the Review Payment Supplier module.
   * @param {Array<View>} views - Array of view objects.
   * @returns {boolean} True if both modules are present, otherwise false.
   */
  public hasCostReviewAndReviewPaymentSupplierModule(views: Array<View>): boolean {
    return this.hasCostReviewModule(views) && this.hasReviewPaymentSupplierModule(views);
  }

  /**
   * @description Validates if the views array contains the Cost review module.
   * @param {Array<View>} views - Array of view objects.
   * @returns {boolean} True if the Cost review module is present, otherwise false.
   */
  public hasCostReviewModule(views: Array<View>): boolean {
    const view = views.find((viewData: View) => {
      return BILLING_SHEET_CONSTANTS.COST_REVIEW_MODULE === viewData.name.toLocaleLowerCase();
    });

    if (view) {
      return true;
    }

    return false;
  }

  /**
   * @description Validates if the views array contains only the Cost Review module and not the Review Payment Supplier module.
   * @param {Array<View>} views - Array of view objects.
   * @returns {boolean} True if only the Cost Review module is present, otherwise false.
   */
  public hasOnlyCostReviewModule(views: Array<View>): boolean {
    if (this.hasCostReviewAndReviewPaymentSupplierModule(views)) {
      return false;
    }

    return this.hasCostReviewModule(views);
  }

  /**
   * @description Validates if the views array contains only the Review Payment Supplier module and not the Cost review module.
   * @param {Array<View>} views - Array of view objects.
   * @returns {boolean} True if only the Review Payment Supplier module is present, otherwise false.
   */
  public hasOnlyReviewPaymentSupplierModule(views: Array<View>): boolean {
    if (this.hasCostReviewAndReviewPaymentSupplierModule(views)) {
      return false;
    }

    return this.hasReviewPaymentSupplierModule(views);
  }

  /**
   * @description Validates if the views array contains the Review Payment Supplier module.
   * @param {Array<View>} views - Array of view objects.
   * @returns {boolean} True if the Review Payment Supplier module is present, otherwise false.
   */
  public hasReviewPaymentSupplierModule(views: Array<View>): boolean {
    const view = views.find((viewData: View) => {
      return BILLING_SHEET_CONSTANTS.REVIEW_PAYMENT_SUPPLIER_MODULE === viewData.name.toLocaleLowerCase();
    });

    if (view) {
      return true;
    }

    return false;
  }

  /**
   * @description Maps the input array of chargesResult to an array of ShipmentCostData objects.
   * @param {Array<ShipmentCostData | ShipmentCostChargeData>} chargesResult - The array of charges result data to be mapped.
   * @returns {Array<ShipmentCostData>} - The mapped array of ShipmentCostData objects.
   */
  public mapToShipmentCosts(chargesResult: Array<ShipmentCostData> | Array<ShipmentCostChargeData>): Array<ShipmentCostData> {
    const shipmentCosts: Array<ShipmentCostData> = [];

    if (!chargesResult?.length) {
      return shipmentCosts;
    }

    for (const chargeResult of chargesResult) {
      const shipmentCostsResult: Array<ShipmentCostResult> = chargeResult.result.map((item: object) => {
        return this.convertToShipmentCostResult(item);
      });

      const shipmentCostData: ShipmentCostData = {
        id: chargeResult.id,
        result: shipmentCostsResult
      };
      shipmentCosts.push(shipmentCostData);
    }

    return shipmentCosts;
  }

  /**
   * @description Converts a given value to its positive equivalent.
   * @param {number} value - The value to be converted to positive.
   * @returns {number} - The positive equivalent of the given value.
   */
  private convertToPositive(value: number): number {
    return Math.abs(value);
  }

  /**
   * @description Determines if a given value is negative.
   * @param {number} value - The value to be checked.
   * @returns {boolean} - True if the value is negative, false otherwise.
   */
  private isNegative(value: number): boolean {
    return value < AppConstants.ZERO;
  }
}
