<div class="page-container">
  <div class="message-container">
    <h2>{{ 'homeTags.exclamationOpening' | translate }}{{ 'homeTags.title' | translate }} {{ userName }}!</h2>
    <h3> {{ 'homeTags.resume' | translate }} </h3>
  </div>
  <div class="image-container">
    <img
      src='../../../assets/home.svg'
      class="home-image"
      alt="Home">
  </div>
</div>
