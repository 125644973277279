import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { UserTenantArrayResponse } from '../../../interfaces/sso/user-tenant.interface';

const AUTH_SEARCH_PARAM = 'authenticationSearch';
const SSO_ADMIN_API_URL = environment.ssoAdminApiUrl

@Injectable({
  providedIn: "root",
})
export class UserTenantService {
  constructor(private http: HttpClient) {}

  /**
   * @description - Gets all users and their tenant values whose username matches.
   * @param { string } username - Username trying to login.
   *
   * @returns { Promise<UserTenantArrayResponse> } - Express response with user tenants array value.
   */
  public async getTenantNameByUsername(username: string): Promise<UserTenantArrayResponse> {
    const endpoint = `/users/${username}/tenants`;
    const options = username ? 
      { params: new HttpParams().set(AUTH_SEARCH_PARAM, true) }
      : {};

    return this.http.get<UserTenantArrayResponse>(SSO_ADMIN_API_URL + endpoint, options).toPromise();
  }
}
