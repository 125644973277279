export enum FilterMenuTypes {
  account = 'Cuentas',
  dates = 'Fechas',
  driver = 'Operadores',
  finishedRoutes = 'Rutas Finalizadas',
  incidenceRoutes = 'Rutas con Incidencias',
  carriers = 'Líneas de Transporte',
  partialRoutes = 'Entrega Parcial',
  rejectionsRoutes = 'Rutas con Rechazos',
  shipment = 'Solicitud',
  shippers = 'Embarcadores',
  status = 'Estatus',
  warehouse = 'Bodegas',
  zone = 'Zonas'
}
