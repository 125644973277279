export const EvidenceFolioFormatConstants = {
  CODE_128P_ROPERTY: 'CODE128',
  DESTINATION_PROPERTY: 'destination',
  DEFAULT_IMAGE: '../../../assets/biis.png',
  FIRST_EVIDENCE_COLUMN: [
    'shipmentId',
    'tripType',
    'serviceType',
    'orderId',
    'clientOrderNumber',
    'internalReference',
    'invoice',
    'order'],
  NAME_PROPERTY: 'name',
  SECOND_EVIDENCE_COLUMN: [
    'account',
    'origin',
    'destinationName',
    'destinationAddress',
    'municipality',
    'state',
    'carrier',
    'deliveryDate'
  ],
  SVG_PROPERTY: 'svg',
  TITLE_CONTAINER: 'title-container'
};
