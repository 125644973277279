<div class="scf-flex-row hidden-overflow">
  <div  class="flex-class min-width-5" *ngIf="shouldShowZoomActions()">
    <mat-icon class="modal-buttons" (click)="zoomIn()">zoom_in</mat-icon>
  </div>
  <div class="flex-class min-width-5" *ngIf="shouldShowZoomActions()">
    <mat-icon class="modal-buttons" (click)="zoomOut()">zoom_out</mat-icon>
  </div>
  <div class="flex-class min-width-5" *ngIf="shouldShowRotateActions()">
    <mat-icon class="modal-buttons" (click)="rotateImage('left')">rotate_left</mat-icon>
  </div>
  <div class="flex-class min-width-5" *ngIf="shouldShowRotateActions()">
    <mat-icon class="modal-buttons" (click)="rotateImage('right')">rotate_right</mat-icon>
  </div>
  <div class="flex-class min-width-75"></div>
  <div class="flex-class min-width-5">
    <mat-icon class="modal-buttons" (click)="onClickClose()">
      close
    </mat-icon>
  </div>
</div>
<div class="modal-content scf-flex-row">
  <div *ngIf="!isPDF" class="image-container">
    <img 
      #image 
      [src]="imageSource" 
      crossingimage 
      onerror="src='../../../../assets/notImage.png'"
      alt="full image">
  </div>
  <div *ngIf="isPDF" class="pdf-preview-container">
    <object class="pdf-preview" type="application/pdf" [data]="pdfUrl"></object>
  </div>
</div>
