import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogStandardFocus } from './../../../enums/dialog-stantdard-focus';

@Component({
  selector: 'app-dialog-standard',
  templateUrl: './dialog-standard.component.html',
  styleUrls: ['./dialog-standard.component.scss', '../../../app.component.scss']
})
export class DialogStandardComponent {

  constructor(public dialogRef: MatDialogRef<DialogStandardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  public onClickButton1(): void {
    this.dialogRef.close(DialogStandardFocus.CANCEL);
  }
  public onClickButton2(): void {
    this.dialogRef.close(DialogStandardFocus.CONFIRM);
  }
  public onClickClose(): void {
    this.dialogRef.close(DialogStandardFocus.CLOSED);
  }

}
