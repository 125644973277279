import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AppConstants } from '../../../constants/app-constants.constants';
import { WarningDialogLabels } from '../../../interfaces/status-changes';

@Component({
  selector: 'app-dialog-status-changes-warning',
  styleUrls: ['../../../app.component.scss', './dialog-status-changes-warning.component.scss'],
  templateUrl: './dialog-status-changes-warning.component.html'
})

/**
 * Dialog to display warning messages to user before to apply changes.
 */
export class DialogStatusChangesWarningComponent {

  /**
   * @description Builds an instance from neccesary services in this component.
   * @param {ConfirmationDialogLabels} data - Dialog data.
   * @param {MatDialogRef<DialogStatusChangesConfirmationComponent>} dialogRef - Instance of this component as dialog.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WarningDialogLabels,
    private dialogRef: MatDialogRef<DialogStatusChangesWarningComponent>
  ) { }

  /**
   * @description Confirms to continue with ths status change when dialog warning is displayed.
   */
  public onContinue(): void {
    this.dialogRef.close(AppConstants.CONFIRM);
  }
}
