export const DialogFilePreviewConst = {
  CONFIRM: 'Confirm',
  DATAURLFORMAT: 'image/png',
  DEGREES: 45,
  DOWNLOADBUTTON: 'download-button',
  DOWNLOADPDFEXTENSION: '.pdf',
  FILE_NAME: 'Evidence',
  IMAGEFORMAT: 'PNG',
  IMAGEPREVIEW: '.image-preview',
  IMAGEPREVIEWDIALOG: 'image-preview-dialog',
  IMGWIDTH: 210,
  IN: 'in',
  KEY_EVIDENCE_RELEASE_FOLIO_DATA: 'evidenceReleaseFolioData',
  KEY_SHIPMENT_REQUEST_DATA: 'shipmentRequestData',
  KEY_SHIPMENT_TO_LOAD_PLAN: 'shipmentToLoadPlan',
  LEFT: 'left',
  MAXWIDTHPDF: 1200,
  MIME_PDF: 'application/pdf',
  NO_INFO: 'Sin Información',
  ORIGIN_EVIDENCE_CONTROL: 'Control de Evidencias',
  OUT: 'out',
  PAGEHEIGHT: 297,
  PAGEORIENTATION: 'p',
  PAGESIZE: 'a4',
  PDF_HEIGHT_ADDED: 15,
  PDFEXTENSION: 'application/pdf',
  PDFHORIZONTALALIGN: 0,
  PDFMEASUREMENTUNIT: 'mm',
  PDFSCALE: 0.9,
  PDFVERTICALALIGN: 30,
  PRINTSECTION: 'print-section',
  QUALITYIMAGE: 1.0,
  SCALEADD: 0.1,
  SCALEMAX: 1.8,
  SCALEMAXVALID: 1.7,
  SCALEMIN: 1.0,
  SCALEMINVALID: 1.1,
  TRANSFORM: 'transform',
  ZERO: 0
};

export const enum ResourceType {
  IMAGE_OR_PDF,
  LOAD_PLAN,
  EVIDENCE_RELEASE_DOC,
  EVIDENCE_DOC,
  SHIPMENT_REQUEST_DOC
}
