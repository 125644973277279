import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { AppConstants } from '../../../constants/app-constants.constants';
import { AppService } from '../../../app.service';
import {
  CustomerInvoiceBody, InvoiceProposal, Order, RecordDialogData, RecordDialogLabels, RegisteredInvoice, ResponseInvoiceProposal
} from '../../../interfaces/invoiceProposal';
import { FormsService } from '../../../services/utils/forms.service';
import { ILanguageLabels } from '../../../interfaces/labels/language-labels.interface';
import { InvoiceProposalProvider } from '../../../providers/invoice-proposal/invoice-proposal.provider.service';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { OrderProvider } from '../../../providers/orders/order-provider.service';
import { RECORD_INVOICE_CONST } from './dialog-invoice-record.constants';
import { SimpleOrderInfo, UpdateOrderBody } from '../../../interfaces';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

@Component({
  selector: 'app-dialog-invoice-record',
  styleUrls: ['./dialog-invoice-record.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-invoice-record.component.html'
})

/**
 * @description - Dialog that allows registering/editing the customer invoice of an invoice proposal.
 */
export class DialogInvoiceRecordComponent implements OnInit {
  public customerInvoice: Partial<CustomerInvoiceBody>;
  public invoiceFolio: string;
  public invoiceProposal: InvoiceProposal;
  public invoiceRecordForm: FormGroup;
  public invoiceRecordLabels: RecordDialogLabels;
  public languageLabels: ILanguageLabels;
  public languageSuscription: Subscription;
  public observations: string;
  public originalFormValue: RegisteredInvoice;
  public shipperName: string;

  /**
   * @description Initialize component required services.
   * @param {RecordDialogData} data - Provided invoice proposal data.
   * @param {AppService} appService - App general services.
   * @param {FormBuilder} builder - Form builder service.
   * @param {MatDialogRef<DialogInvoiceRecordComponent>} dialogRef - Dialog reference.
   * @param {FormsService} formsService - Forms services.
   * @param {InvoiceProposalProvider} invoiceProposalProvider - Invoice proposal provider service.
   * @param {LanguageChangeEventService} languageChangeEventService - Language change event services.
   * @param {LanguageTranslateService} languageTranslateService - Language translation services.
   * @param {OrderProvider} orderProvider - Order provider services.
   * @param {ToastrAlertsService} toast - Toast alerts service.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RecordDialogData,
    private appService: AppService,
    private builder: FormBuilder,
    private dialogRef: MatDialogRef<DialogInvoiceRecordComponent>,
    private formsService: FormsService,
    private invoiceProposalProvider: InvoiceProposalProvider,
    private languageChangeEventService: LanguageChangeEventService,
    private languageTranslateService: LanguageTranslateService,
    private orderProvider: OrderProvider,
    private toast: ToastrAlertsService
  ) {
    this.setLanguage();
  }

  /**
   * @description Gets Billing Sheet Labels from translate JSON files.
   */
  public async getInvoiceRecordLabels(): Promise<void> {
    try {
      this.invoiceRecordLabels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.INVOICE_RECORD_LABELS);
    } catch (error) {
      error.message = this.languageLabels.errorGettingLabels;
      this.toast.errorAlert(error.message);
    }
  }

  /**
   * @description Gets the necessary tags from the JSON files to use throughout the component.
   */
  public async getLabels(): Promise<void> {
    await this.getLanguageLabels();
    await this.getInvoiceRecordLabels();
  }

  /**
   * @description Gets Language labels from translate JSON files.
   */
  public async getLanguageLabels(): Promise<void> {
    try {
      this.languageLabels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS);
    } catch (error) {
      error.message = this.languageLabels.errorGettingLabels;
      this.toast.errorAlert(error.message);
    }
  }

  /**
   * @description Detects if the form has been changed.
   * @returns {boolean} - Returns true if the form is valid.
   */
  public isFormUnchanged(): boolean {
    return JSON.stringify(this.originalFormValue) === JSON.stringify(this.invoiceRecordForm.value);
  }

  /**
   * @description Angular lifecycle for component initialization.
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    this.shipperName = await this.appService.getShipperNameCookie();
    this.customerInvoice = this.data.customerInvoice;
    this.invoiceProposal = this.data.invoiceProposal;
    this.originalFormValue = null;
    this.initForm();
    await this.getLabels();
  }

  /**
   * @description Fires when user clicks on cancel button.
   */
  public onClickCancel(): void {
    this.dialogRef.close(RECORD_INVOICE_CONST.CANCEL);
  }

  /**
   * @description Fires when user clicks on close button.
   */
  public onClickClose(): void {
    this.dialogRef.close(RECORD_INVOICE_CONST.CLOSED);
  }

  /**
   * @description Fires when user clicks on save button.
   */
  public async onClickSave(): Promise<void> {
    this.appService.loaderStatus(true);
    await this.updateInvoiceProposal(this.buildProposalUpdateBody());
    this.appService.loaderStatus(false);
    if (this.data.isEdit) {
      this.toast.successAlert(this.invoiceRecordLabels.invoiceEditSuccess);
    } else {
      this.toast.successAlert(this.invoiceRecordLabels.invoiceRecordSuccess);
    }
    this.dialogRef.close(RECORD_INVOICE_CONST.CONFIRM);
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey - Language key indicator.
   */
  public setLanguage(languageKey?: string): void {
    this.languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this.languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        setTimeout(async () => {
          await this.getLabels();
        }, AppConstants.TIME_OUT_RESTART_SCF_GRID);
      }, () => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Builds the UpdateOrderBody required to update order logs with invoice record/update action.
   * @param { Array<Order>} orders - Current invoice proposal orders.
   * @returns {UpdateOrderBody} Order logs to update and action to apply to them.
   */
  private buildOrderUpdateLogBodys(orders: Array<Order>): UpdateOrderBody {
    const ordersToUpdate = [];
    const customerAction = this.data.isEdit ? RECORD_INVOICE_CONST.UPDATE_ACTION : RECORD_INVOICE_CONST.RECORD_ACTION;

    for (const order of orders) {
      const auxOrderToUpdate: SimpleOrderInfo = {
        _id: order.id,
        account: this.invoiceProposal.account.name,
        lastUpdate: new Date().toDateString(),
        orderId: order.identifier
      };

      if (!ordersToUpdate.includes(auxOrderToUpdate)) {
        ordersToUpdate.push(auxOrderToUpdate);
      }
    }
    const orderLogBody = {
      action: customerAction,
      orders: ordersToUpdate
    };

    return orderLogBody;
  }

  /**
   * @description Builds the invoice proposal update body.
   * @returns {InvoiceProposal} Invoice proposal updated body.
   */
  private buildProposalUpdateBody(): InvoiceProposal {
    const proposalToUpdate = this.invoiceProposal;
    let auxCustomerInvoice = null;
    if (!this.data.isEdit) {
      auxCustomerInvoice = {
        invoiceFolio: this.invoiceRecordForm.value.invoiceFolio,
        invoiceObservations: this.invoiceRecordForm.value.observations ?? AppConstants.EMPTY_STRING,
        invoiceRecordDate: new Date()
      };
    } else {
      auxCustomerInvoice = {
        invoiceFolio: this.invoiceRecordForm.value.invoiceFolio,
        invoiceObservations: this.invoiceRecordForm.value.observations ?? this.customerInvoice.invoiceObservations,
        invoiceRecordDate: new Date(this.invoiceProposal.customerInvoice.invoiceRecordDate)
      };
    }
    proposalToUpdate.customerInvoice = auxCustomerInvoice;

    return proposalToUpdate;
  }

  /**
   * @description Initialize the form for 'invoiceRecordForm'.
   */
  private initForm(): void {
    this.invoiceRecordForm = this.builder.group({
      invoiceFolio: new FormControl(this.customerInvoice.invoiceFolio ?? null, [Validators.required,
        this.formsService.noWhitespaceValidator]),
      observations: new FormControl(this.customerInvoice.invoiceObservations ?? null, this.data.isEdit ?
        [Validators.required, this.formsService.noWhitespaceValidator] : [])
    });

    if (this.data.isEdit) {
      this.originalFormValue = this.invoiceRecordForm.value;
    }
  }

  /**
   * @description Updates the provided invoice proposal.
   * @param {InvoiceProposal} proposalToUpdate - Object with the proposal data to update.
   * @returns {Promise<ResponseInvoiceProposal>} Invoice proposal response.
   */
  private async updateInvoiceProposal(proposalToUpdate: InvoiceProposal): Promise<ResponseInvoiceProposal> {
    try {
      const proposalUpdateResponse = await this.invoiceProposalProvider.updateInvoiceProposal(proposalToUpdate);

      if (proposalUpdateResponse) {
        await this.updateOrderLogs(this.invoiceProposal.orders);
      }

      return proposalUpdateResponse;
    } catch (error) {
      error.message = this.invoiceRecordLabels.proposalUpdateError;
      this.toast.errorAlert(error.message);
    }
  }

  /**
   * @description Updates the current invoice proposal orders logs.
   * @param {Array<Order>} orders - Orders to update.
   */
  private async updateOrderLogs(orders: Array<Order>): Promise<void> {
    try {
      const orderLogBodys = this.buildOrderUpdateLogBodys(orders);
      await this.orderProvider.updateMultipleOrders(orderLogBodys, this.shipperName);
    } catch (error) {
      error.message = this.invoiceRecordLabels.proposalOrdersLogError;
      this.toast.errorAlert(error.message);
    }
  }
}
