import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { AppService } from 'src/app/app.service';
import { ConfigurationProvider } from 'src/app/providers/configuration/configuration.provider.service';
import { EvidenceFolio, EvidenceFolioRow, Shipper } from 'src/app/interfaces';
import { EvidenceReleaseFolioDocumentConsts, EVIDENCE_COLUMNS,
  EVIDENCE_COLUMNS_ADDITIONAL } from './constants/evidence-release-folio-document.constants';
import { EvidenceReleaseFolioDocumentProperties } from './evidence-release-folio-document.properties';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { ShipperService } from './../../../providers/shipper/shipper.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

import getAverageColor from 'get-average-color';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-evidence-release-folio-document',
  templateUrl: './evidence-release-folio-document.component.html',
  styleUrls: ['./evidence-release-folio-document.component.scss', '../../../app.component.scss']
})
export class EvidenceReleaseFolioDocumentComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<EvidenceFolioRow>;
  public displayedColumnsAdditional: Array<string>;
  public displayedColumnsEvidence: Array<string>;
  public evidenceReleaseLabelsTranslate: any;
  public languageSuscription: Subscription;
  public languageLabels: any;
  public logoUrl: string;
  public shipperData: Shipper;
  public shipperOId: string;

  @Input() evidenceFolio: EvidenceFolio;

  constructor(
    private _appService: AppService,
    private configProvider: ConfigurationProvider,
    private shipperService: ShipperService,
    private toast: ToastrAlertsService,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService
  ) {
    this.setLanguage();
  }

  /**
   * @description Angular destroy lifecycle
   */
  public ngOnDestroy() {
    this.languageSuscription?.unsubscribe();
  }

  /**
   * @description Angular lifecycle for component initialization
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
    await this.getEvidenceReleaseFolioLabels();
    this.shipperOId = this._appService.getShipperOid();
    this.configProvider.getShipperLogo().then((res) => this.logoUrl = res);
    this.displayedColumnsEvidence = EVIDENCE_COLUMNS;
    this.displayedColumnsAdditional = EVIDENCE_COLUMNS_ADDITIONAL;
    this.dataSource = new MatTableDataSource(this.evidenceFolio.rows);
    this.getShipperData();
  }

  /**
   * Get the shipper data associated to the user
   */
  public async getShipperData(): Promise<void> {
    const shippersFound = await this.shipperService.getShipper();
    if (shippersFound) {
      this.shipperData = shippersFound.find((shipper) => shipper._id === this.shipperOId);
    } else {
      this.toast.errorAlert(this.evidenceReleaseLabelsTranslate.toastErrorGetShippers);
    }
  }

  /**
   * @description Set default image if shipper images is not found
   * @param event Event when default image is not found
   */
  public setDefaultImage(event): void {
    event.target.src = EvidenceReleaseFolioDocumentConsts.DEFAULT_IMAGE;
    document.getElementById(EvidenceReleaseFolioDocumentConsts.TITLE_CONTAINER).style.borderBottom =
      EvidenceReleaseFolioDocumentProperties.borderLinePropertiesWithColor;
  }

  /**
   * @description Set the average color according to image
   */
  public async setBarDividerColor(): Promise<void> {
    await getAverageColor(this.logoUrl)
    .then(rgb => {
      const barColor = 'rgb(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ')';
      const allTitleContainers = document.getElementsByName(EvidenceReleaseFolioDocumentConsts.TITLE_CONTAINER);
      allTitleContainers.forEach((titleElement: HTMLElement) => {
        titleElement.style.borderBottom = EvidenceReleaseFolioDocumentProperties.borderLinePropertiesWithoutColor + barColor;
      });
    }, error => console.log(error));
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey Optional language key string, default is spanish 'es'
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getEvidenceReleaseFolioLabels();
      },
      (error) => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets Evidence release folios Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getEvidenceReleaseFolioLabels(): Promise<void> {
    this.evidenceReleaseLabelsTranslate =
      await this._languageTranslateService.getLanguageLabels(LanguageConstants.EVIDENCE_RELEASE_FOLIO_DOCUMENT_LABEL)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }
}
