import { Injectable } from '@angular/core';

@Injectable()
export class DataToolsService {

  /**
   * @description Cast any value to Float. If not return undefined
   * @param {any} value Value to be casted
   * @returns {number} The casted value
   */
  public castToFloat (value: any): number {
    const castedValue = parseFloat(value);

    if (isNaN(castedValue)) {
      return undefined;
    } else {
      return castedValue;
    }
  }
}
