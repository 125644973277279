import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';
import { LoadAllocationBodyRequest, Accounts } from '../../interfaces/index';
import { LoadAllocationLabels } from './load-allocation.labels';
import { OrderProvider } from '../../providers/orders/order-provider.service';
import { ServerlessProvider } from '../../providers/serverless/serverless-provider.service';
import { ShipmentRowExtended, VehicleList } from '../../interfaces';
import { ToastrAlertsService } from '../utils/toastr-alerts.service';

const TEP = 'TEP';

@Injectable()
export class LoadAllocationService {
  constructor(
    private _appService: AppService,
    private orderProvider: OrderProvider,
    private serverlessProvider: ServerlessProvider,
    private toast: ToastrAlertsService
  ) {}

  /**
   * @description Generate body of csv to sent ftp-wms
   * @param {ShipmentRowExtended} shipment Shipment that was assigned transportation
   * @param {VehicleList} vehicle Vehicle specifications to send ftp-wms
   * @returns {Promise<string>} Code ftp response
   */
  public async generateTransportInformationCSV(shipment: ShipmentRowExtended, vehicle: VehicleList): Promise<void> {
    const data = await this.bodyCreation(shipment, vehicle);
    await this.serverlessProvider.loadAllocation(data)
    .then(() => {
      this.toast.successAlert(LoadAllocationLabels.toastMessageFileCreatedSuccess);
    })
    .catch(err => {
      this.toast.errorAlert(LoadAllocationLabels.toastMessageFileCreatedError);
      throw new Error(err);
    });
  }

  /**
   * @description Create the body to be send to the CSV azure function to load allocation
   * @param {ShipmentRowExtended} shipment Complete data in the shipment
   * @param {VehicleList} vehicle List with complete vehicle data
   * @returns {Promise<LoadAllocationBodyRequest>} The response with the message from serverless
   */
  public async bodyCreation(shipment: ShipmentRowExtended, vehicle: VehicleList): Promise<LoadAllocationBodyRequest> {
    const shipmentsToConfirm = [];
    const ordersToSend = {
      data: [],
      title: shipment.shipmentId
    };
    const ordersOId = [];
    for (const order of shipment.orders) {
      ordersOId.push(order._id);
    }
    const ordersData = await this.orderProvider.getOrdersByOids({ ordersIds: ordersOId });

    if (shipment.orders) {
      for (const order of shipment.orders) {
        const orderInShipment = [];
        const orderStatus = ordersData.find((orderData) => orderData._id === order._id).status;

        orderInShipment.push(
          shipment.shipmentId,
          shipment.transport.vehicle,
          shipment.transport.driver,
          shipment.transport.plates,
          shipment.tripType,
          shipment.transport.transportCarrier,
          this.getAccountPrefix(shipment.account, order.account._id),
          order.orderId,
          order.destination.name,
          vehicle.tipo.especificaciones.tipo,
          orderStatus ? orderStatus : shipment.status,
          );
        ordersToSend.data.push(orderInShipment);
      }
      shipmentsToConfirm.push(ordersToSend);
    }

    const loadAllocationBodyRequest: LoadAllocationBodyRequest = {
      ftpConfig: {},
      warehouse: shipment.originWarehouse.name,
      shipmentsToConfirm: shipmentsToConfirm
    };

    return loadAllocationBodyRequest;
  }

  /**
   * @description Returns the prefix associated to the account in the order, else return TEP
   * @param {Array<Accounts>} accountList Account list associated to the shipment
   * @param {string} accountToFind Account ObjectId to find
   * @returns {string} The prefix found by ObjectId
   */
  public getAccountPrefix(accountList: Array<Accounts>, accountToFind: string): string {
    const prefix = accountList.find((account) => account._id === accountToFind).prefix;
    return prefix ? prefix : TEP;
  }
}
