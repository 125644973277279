export enum ShipmentType {
  Collection = 'Recolección',
  Consolidated = 'Consolidado',
  CustomerPickup = 'Cliente recoge',
  Courier = 'Paquetera',
  Parcel = 'Paquetería',
  NonStop = 'Directo',
  MultiStop = 'Reparto',
  Portage = 'Porteo',
  PortageMultiStop = 'Porteo con reparto'
}
