export enum DialogEditFreightCostLabels {
  editWarning = 'El valor indicado es igual al existente'
}

export enum DialogEditFreightCostTags {
  dialogTitle = 'Actualización de costo',
  rateIs = 'La tarifa es',
  ratePlaceholder = 'Costo',
  required = 'requerid',
  suffixA = 'a',
  updateButton = 'Actualizar'
}
