export const DialogShipmentDetail: IDialogShipmentDetail = {
  autoacceptSubtitle: 'Autoaceptación',
  dateTimeDistributeSubtitle: 'Fecha y hora por reparto',
  dateTimeShipmentSubtitle: 'Fecha y hora viaje',
  destination: 'Destino',
  distanceTraveled: 'Distancia recorrida',
  distribute: 'Reparto',
  distributeCount: 'Número de repartos',
  distributeTabTitle: 'Repartos',
  distributeTypeSubtitle: 'Tipos de mercancía por reparto',
  eta: 'ETA',
  expirationShipmentSubtitle: 'Caducidad de solicitud',
  finalDestination: 'Destino final',
  generalTabTitle: 'General',
  kilograms: 'kilogramos',
  kilometers: 'km',
  loadSubtitle: 'Carga y transporte',
  negotiationSubtitle: 'Negociación',
  noAssign: 'Sin asignar',
  noConfirm: 'Sin confirmar',
  noInfo: 'Sin información',
  offerSubtitle: 'Contraofertas',
  origin: 'Origen',
  requiresManeuvering: 'Requiere maniobras',
  routeDetails: 'Detalles de Ruta',
  specialRequerimentsSubtitle: 'Requisitos especiales',
  title: 'Detalle de solicitud',
  totalDistance: 'Distancia total de ruta',
  transportSubtitle: 'Transportista',
  volume: 'metros cúbicos',
  volumetrySubtitle: 'Volumetría de mercancía',
  withoutExpiration: 'Sin caducidad configurada',
  withoutSpecialRequirements: 'Sin requisitos especiales'
};

export interface IDialogShipmentDetail {
  autoacceptSubtitle: string;
  dateTimeDistributeSubtitle: string;
  dateTimeShipmentSubtitle: string;
  destination: string;
  distanceTraveled: string;
  distribute: string;
  distributeCount: string;
  distributeTabTitle: string;
  distributeTypeSubtitle: string;
  eta: string;
  expirationShipmentSubtitle: string;
  finalDestination: string;
  generalTabTitle: string;
  kilograms: string;
  kilometers: string;
  loadSubtitle: string;
  negotiationSubtitle: string;
  noAssign: string;
  noConfirm: string;
  noInfo: string;
  offerSubtitle: string;
  origin: string;
  requiresManeuvering: string;
  routeDetails: string;
  specialRequerimentsSubtitle: string;
  title: string;
  totalDistance: string;
  transportSubtitle: string;
  volume: string;
  volumetrySubtitle: string;
  withoutExpiration: string;
  withoutSpecialRequirements: string;
}
