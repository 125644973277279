import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { styleStar } from './star-rate.properties';
@Component({
  selector: 'app-star-rate',
  templateUrl: './star-rate.component.html',
  styleUrls: ['./star-rate.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class StarRateComponent implements OnInit {

  @Input() public color: string;
  @Input() public rating: number;
  @Input() public starCount: number;
  @Output() public ratingUpdated = new EventEmitter();

  public ratingArr: Array<number>;

  constructor() {
  }


  /**
   * @description initialize the component and generates the amount of stars setted
   */
  public ngOnInit(): void {
    this.ratingArr = [];
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }

  /**
   * @description event triggered when a star-button is clicked
   * @param {number} rating rate number selected
   */
  public onClick(rating: number): void {
    this.ratingUpdated.emit(rating);
  }

  /**
   * @description method to display as fill or empties the stars, dependig the rating selected
   * @param {number} rating rate selected
   * @returns {string} the style of star to display
   */
  public showIcon(rating: number): string {
    if (this.rating >= rating + 1) {
      return styleStar.fill;
    } else {
      return styleStar.border;
    }
  }
}
