import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AccountByShipper, Carrier, CollectionsNumberByShipper, Driver, Zone, Shipper, } from '../../interfaces';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ShipperService {

  constructor(
    private _appService: AppService,
    private http: HttpClient,
  ) { }

  /**
   *@description This function returns a array of all the shipper
   *@return Shipper[]
   **/
  public async getShipper(): Promise<Shipper[]> {
    return await this.http.get<Shipper[]>(
      apiUrl + '/embarcadores/')
    .toPromise();
  }

  /**
  * @description This function returns a shipper
  * @param {string} shipperOId shipper id
  * @return Shipper
  **/
  public async getShipperById(shipperOId?: string): Promise<Shipper> {
    const shipperOid = shipperOId ?? this._appService.getShipperOid();
    return await this.http.get<Shipper>(apiUrl + '/embarcadores/' +  shipperOid)
    .toPromise();
  }

  /**
  *@description This function returns a array of all the zones of a shipper
  *@return Zone[]
  **/
  public async getZOnes(): Promise<Zone[]> {
    const shipperOid = this._appService.getShipperOid();
    const userOid = this._appService.getUserOid();
    return await this.http.get<Zone[]>(
      apiUrl + '/embarcadores/' + shipperOid + '/zonas?userOid=' + userOid)
    .toPromise();
  }

  /**
  *@description This function finds and returns all the drivers for each TL
  *@params A single transport line or and array of transport lines
  *@return Driver[]
  **/
  public async getDrivers(TL: Array<string>): Promise<Driver[]> {
    return await this.http.get<Driver[]>(
      apiUrl + '/lineaTransporte/' + TL + '/choferes?activo=true')
    .toPromise();
  }

  /**
  *@description This function returns a array of all the TL of a shipper
  *@return Carrier[]
  **/
  public async getTL(): Promise<Carrier[]> {
    let TLs: Carrier [];
    let resp: {lineasTransporte: Carrier[], _id: string};
    const shipperOid = this._appService.getShipperOid();
    resp = await this.http.get<{lineasTransporte: Carrier[], _id: string}>(
      apiUrl + '/embarcador/' + shipperOid + '/lineaTransporte?names=1')
    .toPromise();
    TLs = resp.lineasTransporte;
    return TLs;
  }

  /**
   * @description Get related accounts to user profile
   * @return AccountByShipper
   */
  public async getAccounts(): Promise<AccountByShipper> {
    const shipperOid = this._appService.getShipperOid();
    const userOid = this._appService.getUserOid();
    return await this.http.get<AccountByShipper>(
      apiUrl + '/embarcadores/' + shipperOid + '/cuentas?userOid=' + userOid)
    .toPromise();
  }

  /**
  *@description This function finds and returns an array of all active shippers
  *@return Shipper[]
  **/
  public async getActiveShippers(): Promise<Shipper[]> {
    return await this.http.get<Shipper[]>(
      apiUrl + '/embarcadores?activo=true')
    .toPromise();
  }

  /**
   * @description Update Shipper by OId
   * @param {Shipper} shipperInfo Shipper info body
   * @param {string} shipperOId shipper id
   * @return {Array<Shipper>} shipper updated
   */
  public async updateShipperById(shipperInfo: Shipper, shipperOId: string): Promise<Array<Shipper>> {
    return await this.http.put<Array<Shipper>>(`${apiUrl}/embarcadores/${shipperOId}`, shipperInfo)
    .toPromise();
  }

  /**
   * @description Send the shipper info to create it
   * @param {Shipper} shipperInfo Shipper info body
   * @return {Array<object>} shipper created
   */
  public async setShipper(shipper: Shipper): Promise<Array<object>> {
    return await this.http.post<Array<object>>(apiUrl + '/embarcadores', shipper)
    .toPromise();
  }

  /**
   * @description Get all shipper´s rfc
   * @return {Array<string>} all shipper's rfc
   */
  public async getShippersRFC(): Promise<Array<string>> {
    return await this.http.get<Array<string>>(apiUrl + '/embarcadores?getRFC=1')
    .toPromise();
  }

  /**
   * @description Delete a Shipper by OId
   * @param shipperOId OId of shipper
   */
  public async deleteShipper(shipperOId): Promise<object> {
    return await this.http.delete(`${apiUrl}/embarcadores/${shipperOId}`).toPromise();
  }

  /**
   * @description Returns document quantity by collection (Carriers, Accounts, Warehouses) linked to current shipper
   * @param {string} shipperOid Current Shipper Object ID
   * @returns {CollectionsNumberByShipper} An object with quantity by each collection
   */
  public async countShipperCollections(shipperOid: string): Promise<CollectionsNumberByShipper> {
    return await this.http.get<CollectionsNumberByShipper>(`${apiUrl}/shippers/${shipperOid}/collections?count=true`).toPromise();
  }

  /**
   * @description Gets drivers by carriers ID.
   * @param {Array<string>} carriers - Carriers identifiers.
   * @returns {Array<Driver>} Drivers found.
   */
  public async getDriversByCarriers(carriers: Array<string>): Promise<Array<Driver>> {
    return await this.http.post<Array<Driver>>(`${apiUrl}/driversByCarriers?active=true`, carriers).toPromise();
  }
}
