import { Component, Inject, OnInit } from '@angular/core';
import { DialogData } from 'scf-library/lib/scf-lib/scf-dialog/scf-dialog.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

import { DialogQuoteBiiShipmentProperties } from './dialog-quote-biis-shipment.properties';
import { CustomerQuoteShipmentRequestLabel, CustomerShipmentRequestLabels } from './dialog-quote-biis-shipment.component.label';
import { MARKETPLACE_SHIPPER_CONSTANTS_ERRORS} from '../../../../constants/marketplace-shipper/marketplace-shipper.constants-errors';
import { ShipmentRequestResponse } from '../../../../interfaces/marketplace-shipper/shipment-request';
import { ToastrAlertsService } from '../../../../services/utils/toastr-alerts.service';

import * as moment from 'moment';

const CONFIRM = 'Confirm';
const CLOSED = 'Closed';
const MIN_QUOTE = 0;
@Component({
  selector: 'app-dialog-quote-biis-shipment',
  templateUrl: './dialog-quote-biis-shipment.component.html',
  styleUrls: ['./dialog-quote-biis-shipment.component.scss', '../../../../app.component.scss'],
})
export class DialogQuoteBiisShipmentComponent implements OnInit {

  public labels: CustomerQuoteShipmentRequestLabel;
  public moneyUpIcon: string;
  public quote: number;
  public shipmentRequestResponse: ShipmentRequestResponse;
  public validQuote: boolean;
  public valueQuote: string;

  constructor( public dialogRef: MatDialogRef<DialogQuoteBiisShipmentComponent>,
    private alertService: ToastrAlertsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.labels = CustomerShipmentRequestLabels;
    this.moneyUpIcon = DialogQuoteBiiShipmentProperties.moneyUp;
    this.quote = 0;
    this.shipmentRequestResponse = data as ShipmentRequestResponse;
    this.validQuote = false;
  }

  /**
   * @description Angular Lifecycle for component initialization
   */
  public async ngOnInit(): Promise<void> {
  }

  /**
   * @description Valided min quote capture
   */
  public validateQuote(event: Event | number): void {
    this.validQuote = Number(event) > MIN_QUOTE;
  }

  /**
   * @description Capture and send quote to marketplace
   */
  public async sendToMarketPlace(): Promise<void> {
    try {
      this.dialogRef.close(this.quote);
    } catch (errorMessage) {
      this.alertService.warningAlert(MARKETPLACE_SHIPPER_CONSTANTS_ERRORS.NO_REQUESTS);
    }
  }

  /**
   * @description Close dialog
   */
  public close(): void {
    this.dialogRef.close(CLOSED);
  }
}
