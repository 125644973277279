<div class="dialog-container">
  <mat-grid-list cols="3" rowHeight="1:.25">
      <mat-grid-tile colspan="2" rowspan="1">
        <div class="dialog-title">
          <span>
            <p>{{ data.title }}</p>
          </span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  <div *ngIf="!data.hasAdditionalConfiguration" class="dialog-message-container background-container">
      <div class="dialog-resume-icon">
        <mat-icon class="resume-mat-icon">{{ data.icon }}</mat-icon>
      </div>
      <div class="dialog-container-resume-text">
        <div class="dialog-resume-text">
          <span>{{ data.resume }}</span>
          <span><b>{{ data.tripType }}</b></span>
          <span>{{ data.resumeConnectorLabel }}</span>
          <span><b>{{ data.orderCount }}</b></span>
        </div>
        <div class="dialog-question">
          <span>{{ data.question }}</span>
        </div>
      </div>
  </div>
  <div *ngIf="data.hasAdditionalConfiguration" class="dialog-message-container">
    <div class="dialog-container-resume-text">
      <div>
        <span>{{ data.tripTypeTitle }}: <b>{{ data.tripType }}</b></span>
        <br>
        <br>
        <div class="aditional-config-div">{{ data.resume }}</div>
        <br>
        <div class="aditional-config-div">{{ data.carrierText }} <b>{{ data.carrier }}</b> </div>
        <br>
        <div class="aditional-config-div">{{ data.vehicleText }} <b>{{ data.vehicle }}</b> </div>
        <br>
        <div class="aditional-config-div">{{ data.platesText }} <b>{{ data.plates }}</b> </div>
        <br>
        <div class="aditional-config-div">{{ data.driverText }} <b>{{ data.driver }}</b> </div>
        <br>
        <br>
      </div>
      <div class="dialog-additional-question">
        <span>{{ data.question }}</span>
      </div>
    </div>
</div>
  <div class="dialog-buttons-container">
    <div class="dialog-container-checkbox">
      <mat-checkbox
        color="primary"
        id="dsc-continue-planning-checkbox"
        (change)="onContinuePlanningShipments($event)" 
        class="dialog-checkbox"></mat-checkbox>
      <label class="checkbox-label">{{ data.continuePlanningLabel }}</label>
    </div>
      <button
        mat-stroked-button
        id="dscc-cancel-button"
        class="secondary-button dialog-button cancel-button" 
        (click)="onClickCancelButton()">
        {{ data.cancel }}
      </button>
      <button
        mat-stroked-button
        id="dscc-continue-button"
        class="primary-button dialog-button continue-button"
        (click)="onClickContinueButton()">
        {{ data.continue }}
      </button>
  </div>
</div>
