<div class="not-found-container">
  <div class="not-found">
    <img src="../../../../../assets/404.svg" alt="Not-found">

    <h3>{{ labels.noPermission }}</h3>

    <button
      mat-raised-button
      color="primary"
      class="primary-button"
      (click)="onClick()">
      {{ labels.goHome }}
    </button>
  </div>
</div>