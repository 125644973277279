import { ImportationCategory } from '../../../../interfaces';

export const DETAIL_INFO_FORM: ImportationCategory = {
  name: 'detailInfo',
  rules: [
    {
      name: 'internalReference',
      required: false
    },
    {
      name: 'deliveryDate',
      required: true
    },
    {
      name: 'deliveryHour',
      required: false
    }
  ]
};
