import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Receiver } from '../../interfaces/receiver/receiver.interface';
import { UniqueReceiverFieldRequest } from '../../interfaces/receiver/unique-receiver-field-request';

const apiUrl = environment.apiUrl;

@Injectable()
export class ReceiverProvider {

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * @description Saves new receiver
   * @param {Receiver} newReceiver The data to save
   * @returns {Observable<void>} An Observable event void
   */
  public save(newReceiver: Receiver): Observable<void> {
    return this.http.post<void>(`${apiUrl}/receiver`, newReceiver);
  }

  /**
   * @description Gets all active receivers by shipperOid
   * @param {string} shipperOid The shipper identifier
   * @returns {Promise<Array<ServiceType>>} A list with active receiverss
   */
  public getAllByShipperOid(shipperOid: string): Promise<Array<Receiver>> {
    return this.http.get<Array<Receiver>>(`${apiUrl}/receiver/${shipperOid}`).toPromise();
  }

  /**
   * @description Checks unique receiver components fields
   * @param {UniqueReceiverFieldRequest} fieldValidationRequest The request object fields
   * @returns {Observable<boolean>} An observable event boolean
   */
  public checkUniqueReceiverField(fieldValidationRequest: UniqueReceiverFieldRequest): Observable<boolean> {
    return this.http.post<boolean>(`${apiUrl}/receiver/field-validation`, fieldValidationRequest);
  }

  /**
   * @description Function to delete a receiver with the query id
   * @param {string} deleteReceiverId The request object fields
   * @returns {Observable<Receiver>} An observable event boolean
   */
  public deleteReceiver(deleteReceiverId: string): Observable<Receiver> {
    return this.http.delete<Receiver>(`${apiUrl}/receiver?receiverId=${deleteReceiverId}`);
  }

  /**
   * @description Function to edit a receiver with the query data
   * @param {Receiver} receiverToEdit The request object fields
   * @returns {Observable<Receiver>} An observable event boolean
   */
  public editReceiver(receiverToEdit: Receiver): Observable<Receiver> {
    return this.http.put<Receiver>(`${apiUrl}/receiver`, receiverToEdit);
  }
}
