
export interface LabelScfGrid {
  canNotDelete?: string;
  canNotEdit?: string;
  delete?: string;
  edit?: string;
  view?: string;
}

/**
 * @description Sfc grid table configs.
 */
export interface ScfGridConfig {
  columnToggler: boolean;
  pageSize: number;
  pageSizeOptions: Array<number>;
  rowDraggable: boolean;
  selectable: boolean;
}

export const ACTIONS = {
  CLOSE: 'close',
  COLUMN_CHIP: 'columnChip',
  CREATE: 'create',
  DELETE: 'delete',
  DONE: 'done',
  EDIT: 'edit',
  PERMISSION_TOGGLE: 'permissionToggle',
  ROW_DESELECTED: 'rowDeselected',
  ROW_SELECTED: 'rowSelected',
  VIEW: 'view'
};
