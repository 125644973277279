import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';

import { AppService } from '../../app.service';
import { ConfigurationProvider } from '../../providers/configuration/configuration.provider.service';
import { Coords } from '../../interfaces/map';
import {
  DialogMarketplaceConfirmComponent
} from '../../components/dialog/dialog-marketplace/dialog-marketplace-confirm/dialog-marketplace-confirm.component';
import { GeoLocationService } from '../../services/utils/get-position.service';
import { MapConstants } from '../../components/map/map-tracking.constants';
import { MARKETPLACE_SHIPPER_CONSTANTS } from '../../constants/marketplace-shipper/marketplace-shipper.constants';
import { MARKETPLACE_SHIPPER_CONSTANTS_ERRORS} from '../../constants/marketplace-shipper/marketplace-shipper.constants-errors';
import { MarketplaceService } from '../../services/marketplace/marketplace.service';
import {
  MarketplaceShipperRequestProvider
} from '../../providers/marketplace-shipper-request/marketplace-shipper-request.provider.service';
import {
  ResponseShipmentRequest,
  ShipmentRequestResponse,
  ShipmentRequestStatusResponse
} from '../../interfaces/marketplace-shipper/shipment-request';
import { ShipperShipmentRequestLabel } from '../../interfaces/index';
import { ShipperShipmentRequestLabels } from './shipper-shipment-request.labels';
import { ShipperShipmentRequestProperties } from './shipper-shipment-request.properties';
import { SHIPMENT_REQUEST_STATUS } from './shipper-shipment-request.labels';
import { ShipmentRequest, OfferBody, QuoteType } from '../../interfaces/marketplace-shipper/shipment-request';
import { TenderingConfig, TenderingShipmentsPerCarrier } from '../../interfaces/configuration';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';
import { UserService } from '../../providers/user/user.service';

import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-shipper-request',
  templateUrl: './shipper-shipment-request.component.html',
  styleUrls: ['./shipper-shipment-request.component.scss', '../../app.component.scss'],
})
export class ShipperRequestComponent implements OnInit {
  private geoCoder: object;
  public boxIcon: string;
  public configToSortOffers: string;
  public coordinates: Coords;
  public dataAvailable: boolean;
  public dateFormat: string;
  public destinationIndex: number;
  public destinationTime: string;
  public displayCarrierName: boolean;
  public distanceRoute: string;
  public expirationDate: string;
  public finishedCounter: number;
  public groupIcon: string;
  public inTransitCounter: number;
  public isBiisPerson: boolean;
  public isShowedModal: boolean;
  public labels: ShipperShipmentRequestLabel;
  public marketplaceCounter: number;
  public maxOffersInConfig: number;
  public maximumShipmentsPerCarrier: number;
  public message: string;
  public modalSub: Subscription;
  public moneyIcon: string;
  public numberFormat: string;
  public numberIcon: string;
  public onlyDateFormat: string;
  public originTime: string;
  public pendingCounter: number;
  public personIcon: string;
  public phoneIcon: string;
  public pinIcon: string;
  public plateIcon: string;
  public quickFilterIndex: number;
  public quickFilterSelected: boolean;
  public region: string;
  public searchIcon: string;
  public shipmentFilters: Array<ShipmentRequestStatusResponse>;
  public shipmentIcon: string;
  public shipmentRequests: Array<ShipmentRequestResponse>;
  public shipmentRequestsDataSource: MatTableDataSource<ShipmentRequestResponse>;
  public shipmentSelected: ShipmentRequest;
  public shipmentsAccepted: Array<ShipmentRequestResponse>;
  public shipmentsCounterPerCarrier: Array<TenderingShipmentsPerCarrier>;
  public shipmentsOffer: Array<OfferBody>;
  public shipperConfig: TenderingConfig;
  public shipperOId: string;
  public showOffers: boolean;
  public starCount: number;
  public timeFormat: string;
  public toastId: number;
  public tracingIcon: string;
  public userName: string;
  public vehicleIcon: string;
  public weigthIcon: string;

  constructor(
    private appService: AppService,
    private configurationProvider: ConfigurationProvider,
    private geoLocationService: GeoLocationService,
    private mapsAPILoader: MapsAPILoader,
    private router: Router,
    private toast: ToastrAlertsService,
    private userService: UserService,
    private marketplaceService: MarketplaceService,
    private marketplaceShipperRequestProvider: MarketplaceShipperRequestProvider,
    public dialog: MatDialog
    ) {
    this.boxIcon = ShipperShipmentRequestProperties.box;
    this.timeFormat = MARKETPLACE_SHIPPER_CONSTANTS.TIME_FORMAT;
    this.dateFormat = MARKETPLACE_SHIPPER_CONSTANTS.DATE_FORMAT;
    this.destinationIndex = MARKETPLACE_SHIPPER_CONSTANTS.START;
    this.finishedCounter = MARKETPLACE_SHIPPER_CONSTANTS.START;
    this.groupIcon = ShipperShipmentRequestProperties.group;
    this.inTransitCounter = MARKETPLACE_SHIPPER_CONSTANTS.START;
    this.isBiisPerson = false;
    this.displayCarrierName = true;
    this.isShowedModal = false;
    this.labels = ShipperShipmentRequestLabels;
    this.marketplaceCounter = MARKETPLACE_SHIPPER_CONSTANTS.START;
    this.moneyIcon = ShipperShipmentRequestProperties.money;
    this.numberFormat = MARKETPLACE_SHIPPER_CONSTANTS.NUMBER_FORMAT;
    this.numberIcon =  ShipperShipmentRequestProperties.number;
    this.pendingCounter = MARKETPLACE_SHIPPER_CONSTANTS.START;
    this.personIcon = ShipperShipmentRequestProperties.person;
    this.plateIcon = ShipperShipmentRequestProperties.plate;
    this.pinIcon = ShipperShipmentRequestProperties.pin;
    this.phoneIcon = ShipperShipmentRequestProperties.phone;
    this.region = MARKETPLACE_SHIPPER_CONSTANTS.REGION;
    this.searchIcon = ShipperShipmentRequestProperties.search;
    this.shipmentFilters = [];
    this.shipmentsOffer = [];
    this.shipmentIcon = ShipperShipmentRequestProperties.shipment;
    this.tracingIcon = ShipperShipmentRequestProperties.tracing;
    this.vehicleIcon = ShipperShipmentRequestProperties.vehicle;
    this.weigthIcon = ShipperShipmentRequestProperties.weigth;
  }

  /**
   * @description Angular lifecycle for component initialization
   */
  public async ngOnInit(): Promise<void> {
    this.starCount = MARKETPLACE_SHIPPER_CONSTANTS.STAR_RATING;
    this.quickFilterSelected = false;
    this.showOffers = false;
    this.message = this.labels.unavailableMarketPlaceData;
    this.geoLocationService.getPosition().subscribe( result => {
      this.coordinates = {
        lat: result ? result.coords.latitude : MapConstants.defaultLatitude,
        lng: result ? result.coords.longitude : MapConstants.defaultLongitude
      };
    });
    await this.getShipperConfig();
    await this.getShipmentRequests();
    this.getShipmentsFilters();
    this.getShipmentsWithCarrierAccepted();
    this.shipperOId = this.appService.getUserOid();
    await this.getUserName(this.shipperOId);
  }

  /**
   * @description Requests from each status are counted
   */
  public startCounters(): void {
    if (!this.shipmentRequests) { return; }
    SHIPMENT_REQUEST_STATUS.forEach(element => element.shipments = []);
    SHIPMENT_REQUEST_STATUS.forEach( shipmentElement => {
      if (shipmentElement.status === MARKETPLACE_SHIPPER_CONSTANTS.ONE_OFFER_STATUS) {
        const shipments: Array<ShipmentRequestResponse> =
        this.shipmentRequests.filter(shipment =>
                                    shipment.status === MARKETPLACE_SHIPPER_CONSTANTS.CREATED && shipment.offers?.length === 1);
        shipmentElement.shipments.push(...shipments);
      } else if (shipmentElement.status === MARKETPLACE_SHIPPER_CONSTANTS.COUNTER_OFFER_STATUS) {
        const shipments: Array<ShipmentRequestResponse> =
        this.shipmentRequests.filter(shipment => shipment.status === MARKETPLACE_SHIPPER_CONSTANTS.COUNTER_OFFER_STATUS &&
                                    shipment.offers?.length === 1);
        shipmentElement.shipments.push(...shipments);
      } else if (shipmentElement.status === MARKETPLACE_SHIPPER_CONSTANTS.CREATED) {
        const shipments: Array<ShipmentRequestResponse> =
        this.shipmentRequests.filter(shipment => !shipment.offers);
        shipmentElement.shipments.push(...shipments);
      } else if (shipmentElement.status === MARKETPLACE_SHIPPER_CONSTANTS.MULTI_OFFER_STATUS) {
        const shipments: Array<ShipmentRequestResponse> =
        this.shipmentRequests.filter(shipment =>
                                    (shipment.status === MARKETPLACE_SHIPPER_CONSTANTS.CREATED ||
                                    shipment.status === MARKETPLACE_SHIPPER_CONSTANTS.COUNTER_OFFER_STATUS) &&
                                    shipment.offers?.length > 1);
        shipmentElement.shipments.push(...shipments);
      } else {
        const shipments: Array<ShipmentRequestResponse> =
        this.shipmentRequests.filter(shipment => shipment.status.toLocaleLowerCase() === shipmentElement.status.toLocaleLowerCase());
        shipmentElement.shipments.push(...shipments);
      }
    });
  }

  /**
   * @description gets the tendering config if exists to display, sort or hide offers, depending of the configuration get
   */
  public async getShipperConfig(): Promise<void> {
    try {
      const shipperConfig = await this.configurationProvider.getShipperConfig();
      const shipperTenderingConfig = shipperConfig.tenderingConfig ? shipperConfig.tenderingConfig : null;
      this.shipperConfig = shipperTenderingConfig;
      this.maximumShipmentsPerCarrier = this.shipperConfig.maximumShipmentsPerCarrier ?
      this.shipperConfig.maximumShipmentsPerCarrier : null;
      this.configToSortOffers = this.shipperConfig.sortOffersBy ? this.shipperConfig.sortOffersBy : null;
      this.maxOffersInConfig = this.shipperConfig.maximumOffers ? this.shipperConfig.maximumOffers : null;
      this.displayCarrierName = this.shipperConfig.showCarrierNameInOffers === false ? false : true;
    } catch (error) {
      this.toast.errorAlert(ShipperShipmentRequestLabels.getShipperConfigError);
    }
  }

  /**
   * @description Gets the username of shipper
   * @param {string} id shipper's Oid
   */
  public async getUserName(id: string): Promise<void> {
    const user = await this.userService.getUserById(id);
    this.userName = user.username;
  }

  /**
   * @description Fill view to shipment request
   */
  public async getShipmentRequests(): Promise<void> {
    try {
      this.showToastMessage();
      this.shipmentRequests = (await this.getMarketplaceShipmentRequest())?.item;
      if (this.shipmentRequests?.length) {
        this.shipmentRequestsDataSource = new MatTableDataSource(this.shipmentRequests);
        this.shipmentRequestsDataSource.filterPredicate = (data, filter) => {
          const dataStr = data.id + data.reference;
          return dataStr.indexOf(filter) !== -1;
        };
      }
      this.startCounters();
      this.dataAvailable = this.shipmentRequests?.length > 0;
      this.closeToastMessage();

      if (!this.shipmentRequests?.length) {
        this.toast.warningAlert(MARKETPLACE_SHIPPER_CONSTANTS_ERRORS.NO_REQUESTS_FOR_SHIPPER);
      }
    } catch (errorMessage) {
      this.closeToastMessage();
      this.toast.warningAlert(MARKETPLACE_SHIPPER_CONSTANTS_ERRORS.NO_REQUESTS);
    }
  }

  /**
   * @description gets and filter the requests by carrier, then generates an array with all carriers with shipments accepted/associated
   * and a counter of all shipments accepted
   */
  public getShipmentsWithCarrierAccepted(): void {
    let carriersAccepted = [];
    this.shipmentsCounterPerCarrier = [];
    if (this.maximumShipmentsPerCarrier) {
      this.shipmentsAccepted = this.shipmentRequests.filter(item => item.acceptedCarrier ? item.acceptedCarrier.carrier.id : null);
      carriersAccepted = [...new Set(this.shipmentsAccepted.map(item => item.acceptedCarrier.carrier.id))];
      for (let i = 0; i < carriersAccepted.length; i++) {
        const shipmentsPerCarrier = this.shipmentsAccepted.filter(shipments =>
          shipments.acceptedCarrier.carrier.id === carriersAccepted[i]);
        this.shipmentsCounterPerCarrier.push({
          id: shipmentsPerCarrier[0].acceptedCarrier.carrier.id,
          carrierName: shipmentsPerCarrier[0].acceptedCarrier.carrier.name,
          shipmentsAccepted: shipmentsPerCarrier.length
        });
      }
    }
  }

  /**
   * @description Gets all the requests
   * @param {string} statusShipment The status of shipment optional
   * @return {Promise<ResponseShipmentRequest>} The ResponseShipmentRequest from shipment request
   */
  public async getMarketplaceShipmentRequest(statusShipment?: string): Promise<ResponseShipmentRequest> {
    const requestQuery = `&isBiisPerson=${this.isBiisPerson}`;
    return await this.marketplaceShipperRequestProvider.getMarketplaceShipmentsRequests(requestQuery, statusShipment);
  }

  /**
   * @description method to generate and display inforation about the shipment selected
   * @param {ShipmentRequest} shipmentData data of the shipment selected
   */
  public async goToOffersShipment(shipmentData: ShipmentRequest): Promise<void> {
    this.shipmentSelected = shipmentData;
    this.originTime = '';
    this.destinationTime = '';
    this.expirationDate = '';
    this.originTime = this.getTime(shipmentData.origin.loadDate.toString());
    this.destinationTime = this.getTime(shipmentData.destinations[shipmentData.destinations.length - 1].deliveryDate);
    const expirationDate = new Date(this.shipmentSelected.expirationDate);
    this.expirationDate = this.getExpirationDate(expirationDate);
    if (shipmentData.offers) {
      const offersQuantity = shipmentData.offers.length;
      this.generateOffersInfo(offersQuantity, shipmentData);
    } else {
      this.shipmentsOffer = [];
    }
    this.showOffers = true;
  }

  /**
   * @description generate the expiration date in the correct fomrat
   * @param {Date} date date received from the shipment request
   * @returns {string} expiration date formated
   */
  public getExpirationDate(date: Date): string {
    return moment(date, MARKETPLACE_SHIPPER_CONSTANTS.MOMENT_FORMAT).format(this.dateFormat);
  }

  /**
   * @description creates information about distance of the route of the shipment
   */
  public async generateTotalDistance(): Promise<void> {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      const distanceMatrixService = new google.maps.DistanceMatrixService();
      const destination = this.shipmentSelected.destinations[this.shipmentSelected.destinations.length - 1].geometry.coordinates;
      const origin = this.shipmentSelected.origin.geometry.coordinates;
      const matrixOptions: google.maps.DistanceMatrixRequest = {
        destinations: [`${destination[0].toString()}, ${destination[1].toString()}`],
        origins: [`${origin[0].toString()}, ${origin[1].toString()}`],
        travelMode: google.maps.TravelMode.DRIVING,
      };
      distanceMatrixService.getDistanceMatrix(matrixOptions, (response, status) => {
        if (status !== MARKETPLACE_SHIPPER_CONSTANTS.CONFIRMATION_STATUS) { return; }
        this.distanceRoute = response.rows[0].elements[0].distance.text;
      });
    });
  }

  /**
   * @description Generates the information about time of shipment
   * @param {string} date The date received from the shipment request
   * @returns {string} Time formated
   */
  public getTime(date: string): string {
    return moment(date, MARKETPLACE_SHIPPER_CONSTANTS.MOMENT_FORMAT).format(this.timeFormat);
  }

  /**
   * @description gets the information about the offer and generates a new shipment body with that information
   * @param {OfferBody} offer data about the offer selected
   */
  public selectOffer(offer: OfferBody): void {
    const requestBody: ShipmentRequest = {
      id: this.shipmentSelected.id,
      creationDate: this.shipmentSelected.creationDate,
      autoAcceptance: this.shipmentSelected.autoAcceptance,
      shipperId: this.shipmentSelected.shipperId,
      reference: this.shipmentSelected.reference,
      vehicleSpecs: this.shipmentSelected.vehicleSpecs,
      vehicleType: {
        name: this.shipmentSelected.vehicleType.name,
        id: this.shipmentSelected.vehicleType.id
      },
      productType: {
        name: this.shipmentSelected.productType.name,
        id: this.shipmentSelected.productType.id
      },
      loadType: {
        name: this.shipmentSelected.loadType.name,
        id: this.shipmentSelected.loadType.id
      },
      defaultOffer: offer.offer,
      expirationDate: this.shipmentSelected.expirationDate,
      user: {
        id: this.shipperOId,
        username: this.userName
      },
      status: MARKETPLACE_SHIPPER_CONSTANTS.ACCEPTED_STATUS,
      insurancedShipment: this.shipmentSelected.insurancedShipment,
      identifier: this.shipmentSelected.identifier,
      weight: this.shipmentSelected.weight,
      volume: this.shipmentSelected.volume,
      destinations: this.shipmentSelected.destinations,
      origin: this.shipmentSelected.origin,
      quoteType: QuoteType.FIXED_COST,
      acceptedCarrier: {
        carrier: {
          id: offer.carrier.id,
          name: offer.carrier.name,
          rate: 0
        },
        date: new Date(),
        fee: 0,
        offer: offer.offer
      }
    };
    this.updateShipmentInfo(requestBody);
  }

  /**
   * @description display a modal to confirmate if the offer selected
   * @param {OfferBody} offer data about the offer selected
   */
  public onConfirm(offer: OfferBody): void {
    const offerShipment = Number(offer.offer).toLocaleString(MARKETPLACE_SHIPPER_CONSTANTS.NUMERIC_FORMAT);
    const dialogRef = this.dialog.open(DialogMarketplaceConfirmComponent, {
      data: {
        textButton1: MARKETPLACE_SHIPPER_CONSTANTS.CANCEL,
        textButton2: MARKETPLACE_SHIPPER_CONSTANTS.ACCEPT,
        iconPath: MARKETPLACE_SHIPPER_CONSTANTS.ICON_PATH,
        focusQuestion: MARKETPLACE_SHIPPER_CONSTANTS.OFFERS,
        question: MARKETPLACE_SHIPPER_CONSTANTS.MODAL_QUESTION,
        resume: MARKETPLACE_SHIPPER_CONSTANTS.BEGIN_RESUME + offerShipment + MARKETPLACE_SHIPPER_CONSTANTS.INTERMEDIATE_RESUME
                + offer.carrier.name + MARKETPLACE_SHIPPER_CONSTANTS.FINALE_RESUME,
        title: MARKETPLACE_SHIPPER_CONSTANTS.MODAL_TITLE
      },
      width: MARKETPLACE_SHIPPER_CONSTANTS.WIDTH
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result[0] === MARKETPLACE_SHIPPER_CONSTANTS.CONFIRM) {
        this.selectOffer(offer);
      }
    });
  }

  /**
   * @description method to update the info of the shipment
   * @param {ShipmentRequest} requestBody shipment data to be updated
   */
  public async updateShipmentInfo(requestBody: ShipmentRequest): Promise<void> {
    try {
      await this.marketplaceService.updateRequest(requestBody);
      this.toast.successAlert(MARKETPLACE_SHIPPER_CONSTANTS_ERRORS.OFFER_SUCCESS_ACCEPTED);
      this.hideOffersShipment();
      this.refreshShipmentView();
    } catch (error) {
      this.toast.errorAlert(MARKETPLACE_SHIPPER_CONSTANTS_ERRORS.OFFER_SELECTED_ERROR);
    }
  }

  /**
   * @description creates information about the offers of the shipment selected and checks if a configuration for tendering
   * exists to sort, remove or hide some offers
   * @param {number} offersQuantity number of the offers in the shipment
   * @param {ShipmentRequest} shipmentData data about the shipment selected
   */
  public generateOffersInfo(offersQuantity: number, shipmentData: ShipmentRequest): void {
    this.shipmentsOffer = [];
    if (this.shipperConfig.maximumOffers) {
      if (this.maxOffersInConfig > offersQuantity) {
        for (let i = 0; i < offersQuantity; i++ ) {
          this.shipmentsOffer.push(shipmentData.offers[i]);
        }
      } else if (this.maxOffersInConfig <= offersQuantity) {
        for (let i = 0; i < this.maxOffersInConfig; i++ ) {
          this.shipmentsOffer.push(shipmentData.offers[i]);
        }
      }
    } else {
      for (let i = 0; i < offersQuantity; i++ ) {
        this.shipmentsOffer.push(shipmentData.offers[i]);
      }
    }
    if (this.configToSortOffers === MARKETPLACE_SHIPPER_CONSTANTS.PRICE_PARAM) {
      this.shipmentsOffer.sort((a, b) => a.offer - b.offer);
    } else if (this.configToSortOffers === MARKETPLACE_SHIPPER_CONSTANTS.RATE_PARAM) {
      this.shipmentsOffer.sort((a, b) => b.carrier.rate - a.carrier.rate);
    }
  }

  /**
   * @description removes a carrier Offer if this has reached the limit setted in tendering configurations
   * @param {string} carrierId Id from the carrier offer to remove
   */
  public removeOffersForCarriers(carrierId: string): void {
    this.shipmentsOffer.forEach((offer, index) => {
      if (offer.carrier.id === carrierId) {
        this.shipmentsOffer.splice(index, 1);
      }
    });
  }

  /**
   * @description This function return shipments status for filter
   */
  public async getShipmentsFilters(): Promise<void> {
    this.shipmentFilters.push(...SHIPMENT_REQUEST_STATUS);
  }

  /**
   * method to reset the shipment selected and to hide the offers view
   */
  public hideOffersShipment(): void {
    this.showOffers = false;
    this.shipmentSelected = undefined;
  }

  /**
   * @description Filter information result by status Shipments
   * @param {ShipmentRequestStatusResponse} statusShipments String value to search
   * @param {number} index Position value to filters status Shipments
   */
  public async getShipperShipmentsByStatus(statusShipments: ShipmentRequestStatusResponse, index: number): Promise<void> {
    this.quickFilterSelected =
      (index !== this.quickFilterIndex) ? true
      : (index === this.quickFilterIndex) ? !this.quickFilterSelected
      : this.quickFilterSelected;
    this.quickFilterIndex = index;
    if (this.quickFilterSelected && statusShipments.status === MARKETPLACE_SHIPPER_CONSTANTS.ONE_OFFER_STATUS) {
      this.shipmentRequests = (await this.getMarketplaceShipmentRequest(MARKETPLACE_SHIPPER_CONSTANTS.CREATED))?.item;
      if (this.shipmentRequests?.length > 0) {
        const shipmentsFiltered = this.shipmentRequests.filter(request => request.offers?.length === 1);
        this.shipmentRequests = shipmentsFiltered;
        this.shipmentRequestsDataSource = new MatTableDataSource(this.shipmentRequests);
      }
      return;
    } else if (this.quickFilterSelected && statusShipments.status === MARKETPLACE_SHIPPER_CONSTANTS.COUNTER_OFFER_STATUS) {
      this.shipmentRequests = (await this.getMarketplaceShipmentRequest(MARKETPLACE_SHIPPER_CONSTANTS.COUNTER_OFFER_STATUS))?.item;
      if (this.shipmentRequests?.length > 0) {
        const shipmentsFiltered = this.shipmentRequests.filter(request => request.offers?.length === 1);
        this.shipmentRequests = shipmentsFiltered;
        this.shipmentRequestsDataSource = new MatTableDataSource(this.shipmentRequests);
      }
      return;
    } else if (this.quickFilterSelected && statusShipments.status === MARKETPLACE_SHIPPER_CONSTANTS.CREATED) {
      this.shipmentRequests = (await this.getMarketplaceShipmentRequest(MARKETPLACE_SHIPPER_CONSTANTS.CREATED))?.item;
      if (this.shipmentRequests?.length > 0) {
        const shipmentsFiltered = this.shipmentRequests.filter(request => !request.offers);
        this.shipmentRequests = shipmentsFiltered;
        this.shipmentRequestsDataSource = new MatTableDataSource(this.shipmentRequests);
      }
      return;
    } else if (this.quickFilterSelected && statusShipments.status === MARKETPLACE_SHIPPER_CONSTANTS.MULTI_OFFER_STATUS) {
      const requestFiltered = [];
      const shipmentsCreated = (await this.getMarketplaceShipmentRequest(MARKETPLACE_SHIPPER_CONSTANTS.CREATED))?.item;
      const shipmentsCounterOffer = (await this.getMarketplaceShipmentRequest(MARKETPLACE_SHIPPER_CONSTANTS.COUNTER_OFFER_STATUS))?.item;
      requestFiltered.push.apply(requestFiltered, shipmentsCounterOffer);
      requestFiltered.push.apply(requestFiltered, shipmentsCreated);
      this.shipmentRequests = requestFiltered;
      if (this.shipmentRequests?.length > 0) {
        const shipmentsFiltered = this.shipmentRequests.filter(request => request.offers?.length > 1);
        this.shipmentRequests = shipmentsFiltered;
        this.shipmentRequestsDataSource = new MatTableDataSource(this.shipmentRequests);
      }
      return;
    } else if (this.quickFilterSelected) {
      this.shipmentRequests = (await this.getMarketplaceShipmentRequest(statusShipments.status))?.item;
      if (this.shipmentRequests?.length > 0) {
        this.shipmentRequestsDataSource = new MatTableDataSource(this.shipmentRequests);
      }
      return;
    }
    this.getShipmentRequests();
  }

  /**
   * @description Filter information result by filtervalue
   * @param {string} filterValue String value to search
   */
  public applyFilter(filterValue: string): void {
    this.shipmentRequestsDataSource.filterPredicate = (data: object, filter: string) => {
      const accumulator = (currentTerm: string, key: string) => {
        return this.nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    this.shipmentRequestsDataSource.filter = filterValue.trim().toLowerCase();
    this.shipmentRequests = this.shipmentRequestsDataSource.filteredData;
  }

  /**
   * @description method to reload the shipment view
   */
  public refreshShipmentView(): void {
    this.router.navigateByUrl(MARKETPLACE_SHIPPER_CONSTANTS.SLASH, { skipLocationChange: true }).then(() =>
    this.router.navigate([MARKETPLACE_SHIPPER_CONSTANTS.SHIPMENT_VIEW]));
  }

  /**
   * @description Filter check values for nested objects
   * @param {string} search String value to search
   * @param {object} data data value to search
   * @param {string} key key value to search
   * @returns {string} The search value
   */
  public nestedFilterCheck(search: string, data: object, key: string): string {
    if (typeof data[key] === MARKETPLACE_SHIPPER_CONSTANTS.OBJECT) {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  /**
   * @description Close the toast active
   */
  private closeToastMessage(): void {
    if (this.toastId) {
      this.toast.closeProcessing(this.toastId);
      this.toastId = null;
    }
  }

  /**
   * @description Show the toast not fount in the action
   */
  private showDataFoundMessage(): void {
    this.closeToastMessage();
    if (!this.isShowedModal) {
      this.toast.warningAlert(MARKETPLACE_SHIPPER_CONSTANTS_ERRORS.NO_REQUESTS);
    }
  }

  /**
   * @description Show the toast in the action
   */
  private showToastMessage(): void {
    if (this.toastId) {
      this.closeToastMessage();
    }
    if (!this.toastId) {
      this.toastId = this.toast.processingAlert();
    }
  }
}
