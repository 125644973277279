import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { CUSTOMER_INVOICE } from '../../pages/costs/customer-invoice/customer-invoice.constants';
import { CustomerInvoiceBody, CustomerInvoiceColumns, CustomerInvoiceLabels } from '../../interfaces/invoiceProposal';

@Component({
  selector: 'app-customer-invoice-pdf',
  styleUrls: ['../../app.component.scss', './customer-invoice-pdf.scss'],
  templateUrl: './customer-invoice-pdf.component.html'
})

/**
 * Component used to build a table with customer invoice received and export to pdf file.
 */
export class CustomerInvoicePdfComponent implements OnInit {
  @Input() public customerInvoiceLabels: CustomerInvoiceLabels;
  @Input() public data: Array<CustomerInvoiceBody>;

  public dataSource: MatTableDataSource<CustomerInvoiceBody>;
  public displayedColumns: Array<string>;
  public headersLabels: CustomerInvoiceColumns;
  public noInfo: string;

  /**
   * @description Builds an instance of necessaries services for correctly work of component.
   */
  constructor() {
    this.displayedColumns = CUSTOMER_INVOICE.GRID_COLUMNS;
  }

  /**
   * @description Angular lifecycle hook for component init.
   */
  public async ngOnInit(): Promise<void> {
    this.dataSource = new MatTableDataSource(this.data);
    this.headersLabels = this.customerInvoiceLabels.columns;
    this.noInfo = this.customerInvoiceLabels.noData;
  }
}
