import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ResponseMatrixOriginDestination } from '../../interfaces/waypoint';

const apiUrl = environment.apiUrl;

@Injectable()
export class MapsProvider {

  constructor(private http: HttpClient) { }

  /**
   * @description Saves an Array of Google API responses with the route and duration per stop
   * @param {Array<ResponseMatrixOriginDestination>} responseDistanceMatrix Array with all responses of distance matrix to save
   * @param {string} shipperOid Id from shipper
   * @returns {ResponseMatrixOriginDestination} Response of endpoint
   */
  public async saveDistanceMatrixLog(responseDistanceMatrix: Array<ResponseMatrixOriginDestination>, shipperOid: string) {
    return this.http.post<ResponseMatrixOriginDestination>
      (`${apiUrl}/shipperOid/${shipperOid}/distanceMatrixLog`, responseDistanceMatrix).toPromise();
  }
}
