export const CHARGE_TYPE_VALUES = [
  { value: 'delivery', description: 'Por entrega' },
  { value: 'unit', description: 'Unidad completa' }
];

export const CHARGE_TYPES = [
  { value: 'Granel', label: 'bulk' },
  { value: 'Entarimado', label: 'pallets' },
  { value: 'Mixto', label: 'mixed' }
];
