import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../../app.service';
import { CarrierGroup, CarrierGroupBody } from '../../interfaces/carrier-group';
import { environment } from '../../../environments/environment';

const apiUrl = environment.apiUrl;
const shipmentRequestApiUrl = environment.shipmentRequestApiUrl;

@Injectable()
export class CarrierGroupProvider {

  constructor(private http: HttpClient, private appService: AppService) {}

  /**
   * @description gets all carrier groups from shipper
   * @returns {Promise<Array<CarrierGroup>>} an array with all carrier groups data
   */
  public async getAllCarrierGroups(): Promise<Array<CarrierGroup>> {
    return await this.http.get<Array<CarrierGroup>>(shipmentRequestApiUrl + '/carrierGroup/').toPromise();
  }

  /**
   * @description gets all carrier groups where the carrier is associated
   * @param {string} carrierId Oid from the carrier to find
   * @returns {Promise<Array<CarrierGroup>>} All carrier groups found
   */
  public async getAllCarrierGroupsByCarrier(carrierId: string): Promise<Array<CarrierGroup>> {
    return await this.http.get<Array<CarrierGroup>>(shipmentRequestApiUrl + '/carrierGroup/carrierId/' + carrierId).toPromise();
  }

  /**
   * @description gets all carrier groups of shipper
   * @param {string} carrierId Oid from shipper
   * @returns {Promise<Array<CarrierGroup>>} All carrier groups found
   */
  public async getAllCarrierGroupsByShipper(shipperId: string): Promise<Array<CarrierGroup>> {
    return await this.http.get<Array<CarrierGroup>>(shipmentRequestApiUrl + '/carrierGroup/shipperId/' + shipperId).toPromise();
  }

  /**
   * @description creates a new carrier group
   * @param {CarrierGroup} carrierGroup a carrier group object
   * @returns {Promise<CarrierGroup>} response of endpoint
   */
  public async createCarrierGroup(carrierGroup: CarrierGroup): Promise<CarrierGroup> {
    return await this.http.post<CarrierGroup>(shipmentRequestApiUrl + '/carrierGroup/', carrierGroup).toPromise();
  }

  /**
   * @description updates a carrier group
   * @param {CarrierGroupBody} carrierGroup the carrier group object with the new data to save
   * @param {boolean} justUpdateCarriers flag to indicate if just want to update the carriers associated info
   * @returns {Promise<CarrierGroup>} response of endpoint
   */
  public async updateCarrierGroup(carrierGroup: CarrierGroupBody, justUpdateCarriers?: boolean): Promise<CarrierGroupBody> {
    return await this.http.put<CarrierGroup>(shipmentRequestApiUrl + '/carrierGroup/' + carrierGroup.id + '/justUpdateCarriers/' +
    justUpdateCarriers , carrierGroup).toPromise();
  }

  /**
   * @description deletes a carrier group specified
   * @param {string} carrierGroupId the carrier group id to delete
   * @param {string} shipperId the shipper id asociated to the carrier group to delete
   * @returns {Promise<CarrierGroup>} response of endpoint
   */
  public async deleteCarrierGroup(carrierGroupId: string, shipperId: string): Promise<CarrierGroupBody> {
    return await this.http.delete<CarrierGroup>(shipmentRequestApiUrl + '/carrierGroup/' + carrierGroupId + '/' + shipperId).toPromise();
  }
}
