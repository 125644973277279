import { UntypedFormControl } from '@angular/forms';

import { LocationProvider } from '../providers/locations/location-provider.service';

import { map, switchMap } from 'rxjs/operators';
import { timer } from 'rxjs';

const TIMER_TO_TRIGGER_ACTION_MS = 500;

export const uniqueLocationFieldValueAsync =
    (locationProvider: LocationProvider, uniqueRequestField: any) => {
        return (input: UntypedFormControl) => {
            return timer(TIMER_TO_TRIGGER_ACTION_MS).pipe(
                switchMap(() => {
                    if (uniqueRequestField.clientId !== undefined) {
                        uniqueRequestField.clientId = input.value;
                    }
                    if (uniqueRequestField.locationName !== undefined) {
                        uniqueRequestField.locationName = input.value;
                    }
                    return locationProvider.checkUniqueLocationField(uniqueRequestField);
                }),
                map((isAvailable: boolean) => {
                    return isAvailable ? null : { alreadyTaken: true };
                })
            );
        };
    };
