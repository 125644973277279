import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { isUndefined as _isUndefined } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { ScfGridAction, ScfGridColumn } from 'scf-library';
import { Subscription } from 'rxjs';

import { AccountBillingScheme } from '../../../interfaces/account-billing-scheme';
import { AccountBillingSchemeProvider } from '../../../providers/account-billing-schemes/account-billing-scheme.service';
import { AccountBody, AccountResponse, SelectedSearchData, WorkSheet } from '../../../interfaces';
import { AccountProvider } from '../../../providers/accounts/account-provider.service';
import {
  AdditionalChargeAndDiscount, CustomerInvoiceBody, CustomerInvoiceDetailData, CustomerInvoiceLabels, CustomerInvoiceSearch, InvoiceProposal
} from '../../../interfaces/invoiceProposal';
import { AppConstants } from '../../../constants/app-constants.constants';
import { AppService } from '../../../app.service';
import { CommunicationService } from '../../../services/communication';
import { CUSTOMER_INVOICE } from './customer-invoice.constants';
import { DATEFORMAT_CONSTANTS } from '../../../constants/dateformat.constants';
import { DateToolsService } from '../../../services/utils/date-tools.service';
import {
  DialogCustomerInvoiceDetailComponent
} from '../../../components/dialog/dialog-customer-invoice-detail/dialog-customer-invoice-detail.component';
import { DialogInvoiceRecordComponent } from '../../../components/dialog/dialog-invoice-record/dialog-invoice-record.component';
import { ExcelService } from '../../../services/utils/excel-tools.service';
import { ILanguageLabels } from '../../../interfaces/labels/language-labels.interface';
import { InvoiceProposalProvider } from '../../../providers/invoice-proposal/invoice-proposal.provider.service';
import { InvoiceProposalStatus } from '../../../enums/invoice-proposal-status';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

@Component({
  selector: 'app-customer-invoice',
  styleUrls: ['./customer-invoice.component.scss', '../../../app.component.scss'],
  templateUrl: './customer-invoice.component.html'
})

/**
 * @description - Component that display a list of invoice proposals and customer invoices added to them.
 */
export class CustomerInvoiceComponent implements OnInit, OnDestroy {
  @ViewChild('inputEndDate', { read: MatInput, static: false }) inputEndDate: MatInput;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public accountBillingSchemesList: Array<AccountBillingScheme>
  public accountControlName: string;
  public accountFormControl: Array<AccountBody>;
  public accountIds: Array<string>
  public accounts: Array<AccountBody>;
  public accountSelectLabel: string;
  public actions: Array<ScfGridAction>;
  public beginDate: FormControl;
  public beginDateFormControl: Date;
  public currentDate: number;
  public currentDatePdf: number;
  public customerInvoiceGroup: FormGroup;
  public customerInvoiceLabels: CustomerInvoiceLabels;
  public dataSource: Array<CustomerInvoiceBody>;
  public dataSourceAvailable: boolean;
  public date: Date;
  public displayedColumns: Array<ScfGridColumn>;
  public endDate: FormControl;
  public endDateFormControl: Date;
  public expandedInfo: boolean;
  public invoiceProposalsFound: Array<InvoiceProposal>
  public isGeneralSearch: boolean;
  public isNearDate: boolean;
  public isScfGridReady: boolean;
  public isSpecificSearch: boolean;
  public languageLabels: ILanguageLabels;
  public languageSuscription: Subscription;
  public latestSearch: CustomerInvoiceSearch;
  public limitEndDate: Date;
  public minEndDate: Date;
  public panelOpenState: boolean;
  public scfGridConfig: object;
  public searchEnabled: boolean;
  public searchMatSelectSelection: Subscription;
  public selectedAccounts: Array<SelectedSearchData>;
  public shipperOid: string;
  public specificCustomerInvoiceGroup: FormGroup;
  public today: Date;

  /**
   * @description - Initialize component required services.
   * @param {AccountBillingSchemeProvider} accountBillingSchemeProvider - Account billing scheme provider service.
   * @param {AccountProvider} accountProvider - Account provider service.
   * @param {AppService} appService - App general services.
   * @param {CommunicationService} communicationService - Communication service.
   * @param {dateToolService} dateToolService - Date tools services.
   * @param {ExcelService} excelService - Excel service.
   * @param {InvoiceProposalProvider} invoiceProposalProvider - Invoice proposal provider service.
   * @param {LanguageChangeEventService} languageChangeEventService - Language event services.
   * @param {LanguageTranslateService} languageTranslateService - Language translation services.
   * @param {UntypedFormBuilder} builder - Form builder service.
   * @param {ToastrAlertsService} toast - Toast alert service.
   * @param {MatDialog} dialog - Mat dialog service.
   */
  constructor(
    private accountBillingSchemeProvider: AccountBillingSchemeProvider,
    private accountProvider: AccountProvider,
    private appService: AppService,
    private communicationService: CommunicationService,
    private dateToolService: DateToolsService,
    private excelService: ExcelService,
    private invoiceProposalProvider: InvoiceProposalProvider,
    private languageChangeEventService: LanguageChangeEventService,
    private languageTranslateService: LanguageTranslateService,
    private readonly builder: UntypedFormBuilder,
    private toast: ToastrAlertsService,
    public dialog: MatDialog
  ) {
    this.setLanguage();
  }

  /**
   * @description Exports all data available in grid to excel file.
   */
  public exportDataToExcel(): void {
    this.appService.loaderStatus(true);
    const excelProperties = {
      excelMinRowWidth: CUSTOMER_INVOICE.EXCEL_MIN_ROW_WIDTH,
      setFullName: true
    };
    const worksheet: Array<WorkSheet> = [];
    worksheet.push(this.buildWorkSheet());
    const fileName = this.customerInvoiceLabels.excelFileName;
    const excelButton = (document.getElementById(CUSTOMER_INVOICE.EXCEL_BUTTON) as HTMLButtonElement);
    excelButton.disabled = true;
    setTimeout(() => {
      this.excelService.exportAsExcelFile(worksheet, fileName, excelProperties);
      excelButton.disabled = false;
      this.toast.successAlert(this.customerInvoiceLabels.excelDownloaded);
      this.appService.loaderStatus(false);
    }, CUSTOMER_INVOICE.EXCEL_TIME_OUT);
  }

  /**
   * @description Gets all actives shipper ccounts and then sort it by name.
   */
  public async getAndSortShipperAccounts(): Promise<void> {
    const accounts = await this.getShipperAccounts();
    this.accounts = accounts.cuentas.sort((accountA: AccountBody, accountB: AccountBody) => {
      return (accountA.name.toLocaleLowerCase() > accountB.name.toLocaleLowerCase()) ? 1 :
        ((accountB.name.toLocaleLowerCase() > accountA.name.toLocaleLowerCase()) ? CUSTOMER_INVOICE.MINUS_ONE : 0);
    });
  }

  /**
   * @description Gets Customer Invoice Labels from translate JSON files.
   */
  public async getCustomerInvoiceLabels(): Promise<void> {
    try {
      this.customerInvoiceLabels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.CUSTOMER_INVOICE_LABELS);
    } catch (error) {
      error.message = this.languageLabels.errorGettingLabels;
      this.toast.errorAlert(error.message);
    }
  }

  /**
   * @description Gets the necessary tags from the JSON files to use throughout the component.
   * @returns {void}
   */
  public async getLabels(): Promise<void> {
    await this.getLanguageLabels();
    await this.getCustomerInvoiceLabels();
  }

  /**
   * @description Gets Language labels from translate JSON files.
   */
  public async getLanguageLabels(): Promise<void> {
    try {
      this.languageLabels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS);
    } catch (error) {
      error.message = this.languageLabels.errorGettingLabels;
      this.toast.errorAlert(error.message);
    }
  }

  /**
   * @description Get assoiciated accounts to shipper.
   * @returns {Promise<AccountResponse>} Shipper's accounts.
   */
  public async getShipperAccounts(): Promise<AccountResponse> {
    try {
      const accounts = await this.accountProvider.getAccounts(this.shipperOid);
      accounts.cuentas.forEach((account: AccountBody) => {
        return account.name = account.nombre;
      });

      return accounts;
    } catch (error) {
      error.message = this.customerInvoiceLabels.toastMessages.getAccountError;
      this.toast.errorAlert(error.message);
    }
  }

  /**
   * @description Handle grid action to execute the correct method.
   * @param {CustomerInvoiceBody} row - Shipment selected.
   * @param {string} action - Action identifier.
   */
  public gridActionHandler(row: CustomerInvoiceBody, action: string): void {
    switch (action) {
      case CUSTOMER_INVOICE.ACTIONS.viewDetails: this.showInvoiceDetailDialog(row);
        break;
      case CUSTOMER_INVOICE.ACTIONS.add: this.showInvoiceRecordDialog(row);
        break;
      case CUSTOMER_INVOICE.ACTIONS.edit: this.showInvoiceRecordDialog(row, true);
        break;
      default: break;
    }
  }

  /**
   * @description Initialize customerInvoiceGroup and specificCustomerInvoiceGroup.
   * @param {UntypedFormBuilder} fb - The instance of form builder.
   */
  public initForm(fb: UntypedFormBuilder): void {
    this.customerInvoiceGroup = fb.group({
      account: new UntypedFormControl(this.accountFormControl, [Validators.required]),
      beginDate: new UntypedFormControl(this.beginDateFormControl, [Validators.required]),
      endDate: new UntypedFormControl(this.endDateFormControl, [Validators.required])
    });
    this.specificCustomerInvoiceGroup = fb.group({ invoiceProposalFolio: new UntypedFormControl(null, [Validators.required]) });
  }

  /**
   * @description Angular destroy Lifecycle.
   */
  public ngOnDestroy(): void {
    if (!(_isUndefined(this.languageSuscription))) {
      this.languageSuscription.unsubscribe();
    }
  }

  /**
   * @description Angular lifecycle for component initialization.
   */
  public async ngOnInit(): Promise<void> {
    this.invoiceProposalsFound = null;
    this.searchEnabled = false;
    this.isScfGridReady = false;
    this.scfGridConfig = CUSTOMER_INVOICE.SCF_GRID_CONFIG;
    this.currentDatePdf = Date.now();
    this.date = new Date(this.currentDatePdf);
    this.dataSourceAvailable = false;
    this.isGeneralSearch = true;
    this.isSpecificSearch = false;
    this.latestSearch = null;
    this.selectedAccounts = [];
    this.shipperOid = this.appService.getShipperOid();
    this.initForm(this.builder);
    this.subscribeLanguageChangeEvent();
    await this.getLabels();
    await this.getAndSortShipperAccounts();
    this.accountSelectLabel = this.customerInvoiceLabels.account;
    this.accountControlName = CUSTOMER_INVOICE.ACCOUNT_CONTROL_NAME;
    this.listenSearchMatSelection();
    this.accountIds = [];
    this.selectedAccounts = [];
    this.expandedInfo = true;
    this.today = new Date();
    this.isNearDate = true;
    this.beginDateFormControl = null;
    this.endDateFormControl = null;
    this.accountFormControl = null;
  }

  /**
   * @description Gets customer invoice proposal data.
   * @param {boolean} isRefreshSearch - Indicates if the search is a refresh.
   */
  public async onSearch(isRefreshSearch?: boolean): Promise<void> {
    if (!isRefreshSearch) {
      this.appService.loaderStatus(true);
    }
    this.searchEnabled = false;
    this.accountIds = [];
    this.currentDate = Date.now();
    this.invoiceProposalsFound = await this.getInvoiceProposal(isRefreshSearch);
    if (this.invoiceProposalsFound.length) {
      await this.getAccountBillingSchemes();
      this.dataSource = this.buildCustomerInvoiceBody();
      this.buildScfGrid();
      this.dataSourceAvailable = true;
      this.expandedInfo = false;
    } else if (!isRefreshSearch) {
      this.dataSource = null;
      this.toast.warningAlert(this.customerInvoiceLabels.infoNotFound);
      this.isScfGridReady = false;
      this.dataSourceAvailable = false;
      this.dataSource = null;
      this.expandedInfo = true;
    }
    this.searchEnabled = true;

    if (!isRefreshSearch) {
      this.appService.loaderStatus(false);
    }
  }

  /**
   * @description Handles general and specific search scenarios.
   * @param {boolean} specificSearch - If any, the specific search scenario will be handled, otherwise the general search scenario will.
   */
  public onSearchChange(specificSearch?: boolean): void {
    if (specificSearch) {
      this.isGeneralSearch = false;
      this.isSpecificSearch = true;
      this.searchEnabled = true;
    } else {
      this.isSpecificSearch = false;
      this.isGeneralSearch = true;
      this.searchEnabled = false;
    }
    this.customerInvoiceGroup.reset();
    this.specificCustomerInvoiceGroup.reset();
  }

  /**
   * @description Sets the maximum end date according current begin date.
   */
  public setEndDate(): void {
    this.beginDateFormControl = this.customerInvoiceGroup.value.beginDate;
    this.endDateFormControl = null;
    this.initForm(this.builder);
    this.validateGeneralFormChanges();
    const beginDate = this.customerInvoiceGroup.value.beginDate;
    const endDate = this.inputEndDate.value;
    const endDateValue = (endDate !== null) ? new Date(this.inputEndDate.value) : null;
    const differenceToday = this.today.getTime() - beginDate.getTime();
    const days = Math.round(differenceToday / (DATEFORMAT_CONSTANTS.MS_ON_SECOND * DATEFORMAT_CONSTANTS.SECS_ON_MINUTE *
      DATEFORMAT_CONSTANTS.MINUTES_ON_HOUR * DATEFORMAT_CONSTANTS.HOURS_ON_DAY));
    this.inputEndDate.value = null;
    if (days < DATEFORMAT_CONSTANTS.DAYS_ON_MONTH_L) {
      this.isNearDate = true;
      this.limitEndDate = this.today;
      this.minEndDate = beginDate;
    } else {
      if (endDateValue !== null) {
        const differenceRange = endDateValue.getTime() - beginDate.getTime();
        const daysRange = Math.round(differenceRange / (DATEFORMAT_CONSTANTS.MS_ON_SECOND * DATEFORMAT_CONSTANTS.SECS_ON_MINUTE *
          DATEFORMAT_CONSTANTS.MINUTES_ON_HOUR * DATEFORMAT_CONSTANTS.HOURS_ON_DAY));
        if (daysRange > DATEFORMAT_CONSTANTS.DAYS_ON_MONTH_L) {
          this.isNearDate = false;
          this.toast.warningAlert(this.customerInvoiceLabels.warningPeriodSearch);
          this.limitEndDate = new Date(beginDate.setDate(beginDate.getDate() + DATEFORMAT_CONSTANTS.DAYS_ON_MONTH));
          this.minEndDate = new Date(beginDate.setDate(beginDate.getDate() - DATEFORMAT_CONSTANTS.DAYS_ON_MONTH));
        } else if (daysRange < DATEFORMAT_CONSTANTS.DAYS_ON_MONTH_L && endDateValue < beginDate) {
          this.toast.warningAlert(this.customerInvoiceLabels.warningErrorDates);
          this.limitEndDate = new Date(beginDate.setDate(beginDate.getDate() + DATEFORMAT_CONSTANTS.DAYS_ON_MONTH));
          this.minEndDate = new Date(beginDate.setDate(beginDate.getDate() - DATEFORMAT_CONSTANTS.DAYS_ON_MONTH));
        }
      } else {
        this.isNearDate = false;
        this.limitEndDate = new Date(beginDate.setDate(beginDate.getDate() + DATEFORMAT_CONSTANTS.DAYS_ON_MONTH));
        this.minEndDate = new Date(beginDate.setDate(beginDate.getDate() - DATEFORMAT_CONSTANTS.DAYS_ON_MONTH));
      }
    }
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey - Language key indicator.
   * @returns {void}
   */
  public setLanguage(languageKey?: string): void {
    this.languageTranslateService.setLanguage(languageKey);
  }


  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @returns {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this.languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.isScfGridReady = false;
        this.setLanguage(key);
        setTimeout(async () => {
          await this.getLabels();
          this.onSearch(true);
        }, AppConstants.TIME_OUT_RESTART_SCF_GRID);
      }, () => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Validates if the form values are valid to enable search button
   */
  public validateGeneralFormChanges(): void {
    let isDateEmptyFlag = true;
    let isAccountEmptyFlag = true;
    const beginDate = this.customerInvoiceGroup.value.beginDate;
    const endDate = this.customerInvoiceGroup.value.endDate;

    if (beginDate !== null && endDate !== null) {
      isDateEmptyFlag = false;
    }

    if (this.selectedAccounts.length) {
      isAccountEmptyFlag = false;
    }

    this.searchEnabled = Boolean(!isDateEmptyFlag && !isAccountEmptyFlag)
  }

  /**
   * @description Build necessary properties to creates SCF Grid.
   */
  private buildScfGrid(): void {
    this.createActions();
    this.initColumns();
    this.isScfGridReady = true;
  }

  /**
   * @description Builds an array of CustomerInvoiceBody objects based on an array of InvoiceProposal objects.
   * @returns {Array<CustomerInvoiceBody>} CustomerInvoiceBody array.
   */
  private buildCustomerInvoiceBody(): Array<CustomerInvoiceBody> {
    const auxCustomerInvoiceData = [];
    for (const proposal of this.invoiceProposalsFound) {
      const customerInvoiceBody: CustomerInvoiceBody = {
        account: proposal.account.name ?? undefined,
        accountId: proposal.account.id ?? undefined,
        additionalCharges: proposal.additionalCharges ? this.getAdditionalChargesAmount(proposal.additionalCharges) : 0,
        discounts: proposal.discounts ? this.getAdditionalChargesAmount(proposal.discounts) : 0,
        folio: proposal.identifier ?? undefined,
        generationDate: this.dateToolService.dateToString(proposal.creationDate) ?? undefined,
        invoiceConcept: proposal.invoiceConcept ?? undefined,
        invoiceFolio: proposal.customerInvoice ? proposal.customerInvoice.invoiceFolio.trim() ?? undefined : undefined,
        invoiceObservations: proposal.customerInvoice && proposal.customerInvoice.invoiceObservations ?
          proposal.customerInvoice.invoiceObservations.trim() ?? undefined : undefined,
        invoiceRecordDate: proposal.customerInvoice ?
          this.dateToolService.dateToString(proposal.customerInvoice.invoiceRecordDate) : undefined,
        orders: proposal.orders.length ? proposal.orders : [],
        status: proposal.customerInvoice && proposal.customerInvoice.invoiceRecordDate ?
          this.customerInvoiceLabels.paid : this.customerInvoiceLabels.pending,
        subtotal: proposal.freightCost ? Number(proposal.freightCost) : 0,
        total: 0,
        vat: proposal.freightCost ? Number(proposal.freightCost * AppConstants.IVA_FACTOR) : 0
      };
      customerInvoiceBody.total = this.getCustomerInvoiceTotal(customerInvoiceBody);
      customerInvoiceBody.additionalCharges = this.formatNumberToCurrency(Number(customerInvoiceBody.additionalCharges));
      customerInvoiceBody.discounts = this.formatNumberToCurrency(Number(customerInvoiceBody.discounts));
      customerInvoiceBody.subtotal = this.formatNumberToCurrency(Number(customerInvoiceBody.subtotal));
      customerInvoiceBody.vat = this.formatNumberToCurrency(Number(customerInvoiceBody.vat));
      auxCustomerInvoiceData.push(customerInvoiceBody);
    }

    return auxCustomerInvoiceData;
  }

  /**
   * @description Builds info to export in excel.
   * @returns {WorkSheet} Info to export in excel.
   */
  private buildWorkSheet(): WorkSheet {
    const headers = [
      this.customerInvoiceLabels.columns.folio,
      this.customerInvoiceLabels.columns.account,
      this.customerInvoiceLabels.columns.generationDate,
      this.customerInvoiceLabels.columns.invoiceConcept,
      this.customerInvoiceLabels.columns.invoiceFolio,
      this.customerInvoiceLabels.columns.invoiceObservations,
      this.customerInvoiceLabels.columns.invoiceRecordDate,
      this.customerInvoiceLabels.columns.status,
      this.customerInvoiceLabels.columns.subtotal,
      this.customerInvoiceLabels.columns.vat,
      this.customerInvoiceLabels.columns.additionalCharges,
      this.customerInvoiceLabels.columns.discounts,
      this.customerInvoiceLabels.columns.total
    ];

    if (!this.dataSource?.length) {
      return;
    }
    const rows = this.customerInvoiceSheetDataToExcel();
    const worksheet: WorkSheet = {
      data: rows,
      headers: headers,
      title: this.customerInvoiceLabels.title
    };

    return worksheet;
  }

  /**
   * @description Creates properties to set actions in SCF Grid.
   */
  private createActions(): void {
    this.actions = [
      {
        action: CUSTOMER_INVOICE.ACTIONS.viewDetails,
        icon: CUSTOMER_INVOICE.ICONS.view,
        label: this.customerInvoiceLabels.viewDetails
      },
      {
        action: CUSTOMER_INVOICE.ACTIONS.add,
        disableFunction: (row?: CustomerInvoiceBody) => {
          return this.disableRecordButton(row);
        },
        label: this.customerInvoiceLabels.invoiceRecord
      },
      {
        action: CUSTOMER_INVOICE.ACTIONS.edit,
        disableFunction: (row?: CustomerInvoiceBody) => {
          return this.disableEditButton(row);
        },
        label: this.customerInvoiceLabels.edit
      }
    ];
  }

  /**
   * @description Sets disabled state in edit button for grid rows.
   * @param {CustomerInvoiceBody} row - Current row.
   * @returns {boolean} Validation to enable/disable button.
   */
  private disableEditButton(row: CustomerInvoiceBody): boolean {
    return row.invoiceFolio === undefined;
  }

  /**
   * @description Sets disabled state in record button for grid rows.
   * @param {CustomerInvoiceBody} row - Current row.
   * @returns {boolean} Validation to enable/disable button.
   */
  private disableRecordButton(row: CustomerInvoiceBody): boolean {
    return row.invoiceFolio !== undefined;
  }

  /**
   * @description Retrieves order invoice detail data required to display detail dialog.
   * @param {CustomerInvoiceBody} row - Current invoice proposal.
   * @returns {CustomerInvoiceDetailData} The order invoice detail data.
   */
  private getCustomerDetailData(row: CustomerInvoiceBody): CustomerInvoiceDetailData {
    const accountBillingScheme = this.accountBillingSchemesList.find((billingScheme: AccountBillingScheme) => {
      return billingScheme.accountId === row.accountId;
    });
    const customerDetailData = {
      accountBillingScheme: accountBillingScheme,
      customerInvoiceData: row
    };

    return customerDetailData;
  }

  /**
   * @description Calculates the total amount for the customer invoice.
   * @param {CustomerInvoiceBody} customerInvoiceBody - Customer invoice body.
   * @returns {string} Total amount formated to money.
   */
  private getCustomerInvoiceTotal(customerInvoiceBody: CustomerInvoiceBody): string {
    const total = this.formatNumberToCurrency(Number(customerInvoiceBody.additionalCharges) -
      Number(customerInvoiceBody.discounts) + Number(customerInvoiceBody.subtotal) + Number(customerInvoiceBody.vat));

    return total;
  }

  /**
   * @description Initialize default grid columns.
   */
  private initColumns(): void {
    this.displayedColumns = [];
    this.displayedColumns.push(
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.folio, header: this.customerInvoiceLabels.columns.folio },
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.account, header: this.customerInvoiceLabels.columns.account },
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.generationDate, header: this.customerInvoiceLabels.columns.generationDate },
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.invoiceConcept, header: this.customerInvoiceLabels.columns.invoiceConcept },
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.invoiceFolio, header: this.customerInvoiceLabels.columns.invoiceFolio },
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.invoiceObservations, header: this.customerInvoiceLabels.columns.invoiceObservations },
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.invoiceRecordDate, header: this.customerInvoiceLabels.columns.invoiceRecordDate },
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.status, header: this.customerInvoiceLabels.columns.status },
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.subtotal, header: this.customerInvoiceLabels.columns.subtotal },
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.vat, header: this.customerInvoiceLabels.columns.vat },
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.additionalCharges, header: this.customerInvoiceLabels.columns.additionalCharges },
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.discounts, header: this.customerInvoiceLabels.columns.discounts },
      { field: CUSTOMER_INVOICE.DISPLAYEDCOLUMNS.total, header: this.customerInvoiceLabels.columns.total }
    );
  }

  /**
   * @description Converts a number into a currency-formatted string.
   * @param {number} amount - The number to be formatted.
   * @returns {string} The currency-formatted string.
   */
  private formatNumberToCurrency(amount: number): string {
    const formatter = new Intl.NumberFormat(AppConstants.US_NUMBER_FORMAT, {
      currency: AppConstants.UNITED_STATES_DOLLAR,
      style: AppConstants.CURRENCY_KEYWORD
    });

    return formatter.format(amount);
  }

  /**
   * @description Execute the request to find the all billing scheme by shipper.
   */
  private async getAccountBillingSchemes(): Promise<void> {
    try {
      this.accountBillingSchemesList = (await this.accountBillingSchemeProvider.getAccountBillingSchemes(
        this.shipperOid)).item as Array<AccountBillingScheme>;
    } catch (error) {
      error.message = this.customerInvoiceLabels.toastMessages.getAccountBillingSchemesError;
      this.toast.errorAlert(error.message);
    }
  }

  /**
   * @description Calculates the total amount of additional charges.
   * @param {Array<AdditionalChargeAndDiscount>} additionalCharges - An array of objects representing additional charges and discounts.
   * @returns {number} The total amount of additional charges as a number. If the 'additionalCharges' array is empty, it will return 0.
   */
  private getAdditionalChargesAmount(additionalCharges: Array<AdditionalChargeAndDiscount>): number {
    if (!additionalCharges.length) {
      return 0;
    }

    return additionalCharges.reduce((total: number, charge: AdditionalChargeAndDiscount) => {
      return total + charge.amount;
    }, 0);
  }

  /**
   * @description Finds the matching invoice proposal data by it's identifier.
   * @param {string} currentFolio - Invoice proposal folio/identifier.
   * @returns {InvoiceProposal} Invoice proposal full object.
   */
  private getProposalDetail(currentFolio: string): InvoiceProposal {
    return this.invoiceProposalsFound.find((proposal: InvoiceProposal) => {
      return proposal.identifier === currentFolio;
    });
  }

  /**
   * @description Get the invoice proposal response.
   * @param {boolean} isRefreshSearch - Indicates if the search is a refresh.
   * @returns {Array<InvoiceProposal>} Invoice proposal response.
   */
  private async getInvoiceProposal(isRefreshSearch?: boolean): Promise<Array<InvoiceProposal>> {
    let invoiceProposal: Array<InvoiceProposal>;

    const searchData = !isRefreshSearch ? this.getSearchParams(isRefreshSearch) : this.latestSearch;

    if (!searchData || (!searchData.accountIds && !searchData.invoiceProposalFolio)) {
      return [];
    }
    try {
      const isSpecificSearch = isRefreshSearch ? this.latestSearch?.isSpecificSearch : this.isSpecificSearch;

      if (isSpecificSearch) {
        invoiceProposal = [(await this.invoiceProposalProvider.getInvoiceProposalByIdentifier(
          searchData.invoiceProposalFolio, this.shipperOid)).item];
      } else {
        invoiceProposal = (await this.invoiceProposalProvider.getInvoiceProposalByDateAndAccount(searchData, this.shipperOid)).item;
      }
    } catch (error) {
      return [];
    }

    invoiceProposal = invoiceProposal.filter((invoice: InvoiceProposal) => {
      return invoice.status === InvoiceProposalStatus.generated;
    });

    return invoiceProposal;
  }

  /**
   * @description Get params for general or specific search.
   * @param {boolean} isRefreshSearch - Indicates if the search is a refresh.
   * @returns {CustomerInvoiceSearch} Params body.
   */
  private getSearchParams(isRefreshSearch?: boolean): CustomerInvoiceSearch {
    let searchData = null;
    if (!this.isSpecificSearch) {
      if (this.customerInvoiceGroup.value.account?.length) {
        this.selectedAccounts = this.customerInvoiceGroup.value.account;
      }

      this.selectedAccounts.forEach((account: AccountBody) => {
        this.accountIds.push(account._id);
      });
      const bDate = this.customerInvoiceGroup.value.beginDate;
      const eDate = this.customerInvoiceGroup.value.endDate ?
        new Date(this.customerInvoiceGroup.value.endDate.setHours(AppConstants.MAX_TIME.hour,
          AppConstants.MAX_TIME.minsAndSecs, AppConstants.MAX_TIME.minsAndSecs, AppConstants.MAX_TIME.miliseconds)) : undefined;
      searchData = {
        accountIds: this.accountIds,
        beginDate: bDate,
        endDate: eDate
      };
    } else {
      const invoiceProposalFolio = this.specificCustomerInvoiceGroup.value.invoiceProposalFolio;
      searchData = { invoiceProposalFolio: invoiceProposalFolio };
    }

    if (!isRefreshSearch) {
      this.latestSearch = searchData;
      this.latestSearch.isSpecificSearch = this.isSpecificSearch;
    }

    return searchData;
  }

  /**
   * @description Iterates over all customer invoice availables to create an array with all data neccesary to export.
   * @returns {Array<Array<string>>} - An Array with all data from all customer invoice available to export it to excel.
   */
  private customerInvoiceSheetDataToExcel(): Array<string> {
    const allInvoiceSheetRows = [];

    for (const invoice of this.dataSource) {
      const invoiceSheetRows = [];
      invoiceSheetRows.push(invoice.folio ?? this.customerInvoiceLabels.noData);
      invoiceSheetRows.push(invoice.account ?? this.customerInvoiceLabels.noData);
      invoiceSheetRows.push(invoice.generationDate ?? this.customerInvoiceLabels.noData);
      invoiceSheetRows.push(invoice.invoiceConcept ?? this.customerInvoiceLabels.noData);
      invoiceSheetRows.push(invoice.invoiceFolio ?? this.customerInvoiceLabels.noData);
      invoiceSheetRows.push(invoice.invoiceObservations ?? this.customerInvoiceLabels.noData);
      invoiceSheetRows.push(invoice.invoiceRecordDate ?? this.customerInvoiceLabels.noData);
      invoiceSheetRows.push(invoice.status ?? this.customerInvoiceLabels.noData);
      invoiceSheetRows.push(invoice.subtotal ?? this.customerInvoiceLabels.noData);
      invoiceSheetRows.push(invoice.vat ?? this.customerInvoiceLabels.noData);
      invoiceSheetRows.push(invoice.additionalCharges ?? this.customerInvoiceLabels.noData);
      invoiceSheetRows.push(invoice.discounts ?? this.customerInvoiceLabels.noData);
      invoiceSheetRows.push(invoice.total ?? this.customerInvoiceLabels.noData);
      allInvoiceSheetRows.push(invoiceSheetRows);
    }

    return allInvoiceSheetRows;
  }

  /**
   * @description Listen select search fields.
   */
  private listenSearchMatSelection(): void {
    this.searchMatSelectSelection = this.communicationService.searchMatSelectionSubscribe().subscribe(selection => {
      const selectedData = selection.map(item => item);

      if (selectedData[0].controlName === CUSTOMER_INVOICE.ACCOUNT_CONTROL_NAME) {
        this.selectedAccounts = selectedData[0]._id ? selectedData : [];
      }
      this.validateGeneralFormChanges();
    });
  }

  /**
   * @description Opens modal to view the invoice proposal detail.
   * @param {CustomerInvoiceBody} row - Current invoice proposal data.
   */
  private showInvoiceDetailDialog(row: CustomerInvoiceBody): void {
    this.dialog.open(DialogCustomerInvoiceDetailComponent, {
      data: this.getCustomerDetailData(row),
      maxHeight: CUSTOMER_INVOICE.DETAIL_DIALOG_HEIGHT,
      width: CUSTOMER_INVOICE.DETAIL_DIALOG_WIDTH
    });
  }

  /**
   * @description Opens modal to view the record invoice dialog.
   * @param {CustomerInvoiceBody} row - Current invoice proposal data.
   * @param {boolean} isEdit - Indicates if the record invoice dialog call is for editing or not.
   */
  private showInvoiceRecordDialog(row: CustomerInvoiceBody, isEdit?: boolean): void {
    const dialogRef = this.dialog.open(DialogInvoiceRecordComponent, {
      data: {
        customerInvoice: row,
        invoiceProposal: this.getProposalDetail(row.folio),
        isEdit: isEdit
      },
      width: CUSTOMER_INVOICE.RECORD_DIALOG_WIDTH
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === CUSTOMER_INVOICE.DIALOG_CONFIRM) {
        this.onSearch(true);
      }
    });
  }
}
