import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { Reason, ReasonResponse, ReasonsByTypesResponse } from '../../interfaces/reason';

const apiUrl = environment.apiUrl;

@Injectable()
export class ReasonProvider {
  constructor(private http: HttpClient, private appService: AppService) { }

  /**
   * @description Generate request for create a new reason.
   * @param {Reason} body - To send in the request.
   * @returns {Promise<ReasonResponse>} Response of shipper.
   */
  public async createReason(body: Reason): Promise<ReasonResponse> {
    return await this.http.post<ReasonResponse>(`${apiUrl}/reasons/`, body).toPromise();
  }

  /**
   * @description Gets all related shipper reasons by given shipperId.
   * @param {string} shipperId - Optional Param to get related reasons.
   * @returns {Promise<ReasonResponse>} Shipper found reasons.
   */
  public async getReasons(shipperId?: string): Promise<ReasonResponse> {
    const shipperOid = shipperId ?? this.appService.getShipperOid();

    return await this.http.get<ReasonResponse>(`${apiUrl}/reasons/${shipperOid}`).toPromise();
  }

  /**
   * @description Gets reason by identifier.
   * @param {string} shipperId - Optional Param to get related reasons.
   * @param {string} identifier - Reason id to find.
   * @returns {Promise<ReasonResponse>} Reason found.
   */
  public async getReasonByIdentifier(identifier: string, shipperId?: string): Promise<ReasonResponse> {
    const shipperOid = shipperId ?? this.appService.getShipperOid();

    return await this.http.get<ReasonResponse>(`${apiUrl}/reasons/${shipperOid}/identifier/${identifier}`).toPromise();
  }

  /**
   * @description Gets active reasons from shipper for provided types.
   * @param {string} shipperOId - Object ID from shipper.
   * @param {Array<string>} reasonsTypes - Array with types of reasons to search.
   * @returns {Promise<ReasonResponse>} Response of request.
   */
  public async getReasonByTypes(shipperOId: string, reasonsTypes: Array<string>): Promise<ReasonsByTypesResponse> {
    const searchParams = { reasonsTypes: reasonsTypes };

    return await this.http.post<ReasonsByTypesResponse>(`${apiUrl}/reasons/shipper/${shipperOId}/types/`, searchParams).toPromise();
  }

  /**
   * @description Generate request to update a reason by object id.
   * @param {Reason} reason - The reason object containing the updated data.
   * @returns {Promise<ReasonResponse>} The updated reason as response.
   */
  public async updateReason(reason: Reason): Promise<ReasonResponse> {
    return await this.http.put<ReasonResponse>(`${apiUrl}/reasons/${reason._id}`, reason).toPromise();
  }

  /**
   * @description Generate request to enable or disable a reason by object id.
   * @param {Reason} reason - The reason object to be disabled.
   * @returns {Promise<ReasonResponse>} The disbled or enabled reason as response.
   */
  public async enableOrDisable(reason: Reason): Promise<ReasonResponse> {
    return await this.http.put<ReasonResponse>(`${apiUrl}/reasons/${reason._id}/disable`, reason).toPromise();
  }
}
