import { Component, Input, OnInit } from '@angular/core';

const NO_DATA_ICON = '../../../assets/icons/icon-emptystatesearch.svg';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent implements OnInit {
  @Input() title: string;

  public noDataIcon: string;

  constructor() { }

  /**
   * @description Angular lifecycle hook for component init.
   */
  public ngOnInit(): void {
    this.noDataIcon = NO_DATA_ICON;
  }
}
