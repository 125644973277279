import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import { AccountBillingSchemeConcept } from '../../../../interfaces/account-billing-scheme-concept';
import { AccountBillingSchemeConceptProvider } from '../../../../providers/account-billing-schemes/account-billing-scheme-concept.service';
import { AdditionalCharge } from '../../../../interfaces/additional-charge';
import { AppConstants } from '../../../../constants/app-constants.constants';
import { AppService } from '../../../../app.service';
import { ConceptAccountBillingScheme } from '../../../../interfaces/account-billing-scheme';
import { ControlSelectTranslated } from '../../../../interfaces/control-element';
import { DIALOG_BILLING_SCHEME_CONSTANTS } from './dialog-billing-scheme.constants';
import { DialogBillingSchemeVariable } from '../../../../interfaces/dialog-billing-scheme';
import { GenericRegexp } from '../../../../regexp/generic.regexp';
import { LanguageChangeEventService } from '../../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../../constants/language.constants';
import { LanguageTranslateService } from '../../../../services/translate/language-translate.service';
import { ShipmentType } from '../../../../enums';
import { ToastrAlertsService } from '../../../../services/utils/toastr-alerts.service';
import { UtilsService } from '../../../../services/utils.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialog-billing-scheme-variable',
  styleUrls: [
    './dialog-billing-scheme-variable.component.scss',
    '../../../../app.component.scss'
  ],
  templateUrl: './dialog-billing-scheme-variable.component.html'
})
export class DialogBillingSchemeVariableComponent implements OnInit {
  public conceptList: Array<AccountBillingSchemeConcept>;
  public chargeVariableCreateGroup: UntypedFormGroup;
  public defaultValueTypeTrip: string;
  public dialogBillingSchemeLabelsTranslated: any;
  public labels: any;
  public languageLabels: any;
  public languageSuscription: Subscription;
  public shipmentTypeTranslated: any;
  public shipperId: string;
  public typeTripList: Array<ControlSelectTranslated>;

  constructor(
    private appService: AppService,
    private accountBillingSchemeConceptProvider: AccountBillingSchemeConceptProvider,
    private dialogRef: MatDialogRef<DialogBillingSchemeVariableComponent>,
    private readonly builder: UntypedFormBuilder,
    private utils: UtilsService,
    private toastService: ToastrAlertsService,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogBillingSchemeVariable
  ) {
    this.conceptList = [];
    this.typeTripList = [];
    this.defaultValueTypeTrip = AppConstants.EMPTY_STRING;
    this.setLanguage();
  }

  /**
   * @description Angular lifecycle for component initialization
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    this.initForm();
    await this.getLanguageTags();
    await this.getShipmentTypesTranslated();
    await this.getDialogBillingSchemeLabelsTranslated();
    await this.getAccountBillingSchemeConcept();
    this.typeTripList = this.getTypeTripList();
    this.disableOrEnableUnitKey();
    this.shipperId = await this.appService.getShipperOid();
  }

  /**
   * @description Initialize the form for 'chargeVariableCreateGroup'
   */
  public initForm(): void {
    this.chargeVariableCreateGroup = this.builder.group({
      concept: new UntypedFormControl(null, [Validators.required]),
      tripType: new UntypedFormControl(null, [Validators.required]),
      unitKey: new UntypedFormControl(DIALOG_BILLING_SCHEME_CONSTANTS.DEFAULT_UNIT),
      cost: new UntypedFormControl(null, [this.customCostValidator.bind(this)])
    });
  }

  /**
   * @description Check if the field value is empty or not
   * @param {AbstractControl} control The form control to validate
   * @returns {boolean} If is empty return true
   */
  public isControlEmpty(control: AbstractControl): boolean {
    return !control ||
    control.value === null ||
    (control.value.toString() ?? AppConstants.EMPTY_STRING).trim() === AppConstants.EMPTY_STRING;
  }

  /**
   * @description Verify if value of 'Fixed Charge' is valid
   * @param {AbstractControl} control The form control to validate
   * @returns {object} If is valid retuns and object, else return null
   */
  private customCostValidator(control: AbstractControl): object | null {
    if (this.isControlEmpty(control)) {
      return null;
    }

    return this.throwRuleInvalid(control, DIALOG_BILLING_SCHEME_CONSTANTS.COST.toLowerCase());
  }

   /**
   * @description Check if the field value is valid, currently only validates numeric data
   * and add new rules according to the need
   * @param {AbstractControl} control The form control to validate
   * @param {string} controlName name of control as id
   * @returns {object} If is valid retuns and object, else return null
   */
  private throwRuleInvalid(control: AbstractControl, controlName: string): object | null {
    const ctrl_value: string = control.value.toString();
    const value = parseFloat(ctrl_value);
    const isInValidNumber =  (
      !GenericRegexp.NUMBER_WITH_THREE_DECIMALS_POINTS.test(ctrl_value) || isNaN(value)) ||
      (value <= AppConstants.ZERO || value > DIALOG_BILLING_SCHEME_CONSTANTS.MAX_COST
    );

    if (
      (controlName.toLowerCase() === DIALOG_BILLING_SCHEME_CONSTANTS.COST.toLocaleLowerCase())
      && isInValidNumber
    ) {
      return { 'invalid_number': true };
    }

    return null;
  }

  /**
   * @description Set values or reset the form 'chargeVariableCreateGroup' if set additionalCharge as parameter
   * @param {AdditionalCharge} additionalCharge object with values to set in each field in form chargeVariableCreateGroup
   */
  private setValuesForm(additionalCharge?: AdditionalCharge): void {
    this.chargeVariableCreateGroup.patchValue({
      unitKey: additionalCharge?.unitKey ?? null,
    });
  }

  /**
   * @description Disable the 'unitKey' field of 'chargeVariableCreateGroup'.
   */
  private disableOrEnableUnitKey(): void {
    this.chargeVariableCreateGroup.get(DIALOG_BILLING_SCHEME_CONSTANTS.UNIT_KEY).enable();
  }

  /**
   * @description Close dialog opened and 'save' the information in memory and after this can be persist
   */
  public onSave(): void {
    const concept = this.buildConcept();
    if (!this.existsConcept(concept)) {
      this.dialogRef.close(concept);
      return;
    }

    this.toastService.warningAlert(this.dialogBillingSchemeLabelsTranslated.conceptReady);
  }

  /**
   * @description Close dialog opened, everithing captured will be lost
   */
  public onCancel(): void {
    this.dialogRef.close(DIALOG_BILLING_SCHEME_CONSTANTS.CLOSED);
  }

  /**
   * @description Validate if is valid and it doesn't exist in the data source
   * @param {ConceptAccountBillingScheme} concept object to validate
   * @return {boolean} result of evaluation
   */
  private existsConcept(concept: ConceptAccountBillingScheme): boolean {
    if (!this.data.concepts || !this.data.concepts.length) {
      return false;
    }

    const matchingConcept = this.data.concepts.find((item: ConceptAccountBillingScheme) => {
      return item.concept === concept.concept &&
      item.tripType.some((tripType: string) => {
        return concept.tripType.includes(tripType);
      });
    });

    return !!matchingConcept;
  }

  /**
   * @description Builder of concept for send in the request
   * @return {ConceptAccountBillingScheme} model to sent request
   */
  private buildConcept(): ConceptAccountBillingScheme {
    const one = 1;
    const {
      concept,
      cost,
      tripType
    } = this.chargeVariableCreateGroup.value;
    const conceptAccountBillingScheme: ConceptAccountBillingScheme = {} as ConceptAccountBillingScheme;
    conceptAccountBillingScheme.id = concept?.id;
    conceptAccountBillingScheme.concept = concept?.name?.trim();
    conceptAccountBillingScheme.unitKey = DIALOG_BILLING_SCHEME_CONSTANTS.DEFAULT_UNIT;
    conceptAccountBillingScheme.tripType = tripType?.filter(item => item) ?? [];
    conceptAccountBillingScheme.cost = this.utils.castNumber(cost);
    conceptAccountBillingScheme.priority = this.data.priority + one;
    conceptAccountBillingScheme.isActive = true;

    return conceptAccountBillingScheme;
  }

  /**
   * @description Get all concepts of account billing scheme to show in the control.
   */
  private async getAccountBillingSchemeConcept(): Promise<void> {
    try {
      this.conceptList = (await this.accountBillingSchemeConceptProvider.getAccountBillingSchemeConcepts(this.shipperId, true)).item;
    } catch (error) {
      this.toastService.warningAlert(this.dialogBillingSchemeLabelsTranslated.noConceptsFound);
    }
  }

  /**
   * @description Get a list of type of shipment to show in the view
   * @return {Array<ControlSelectTranslated>} list of shipments type
   */
  private getTypeTripList(): Array<ControlSelectTranslated> {
    const shipmentTypeList: Array<ControlSelectTranslated> = [];

    shipmentTypeList.push({
      label: this.shipmentTypeTranslated.nonStop,
      value: ShipmentType.NonStop.valueOf(),
    },{
      label: this.shipmentTypeTranslated.multiStop,
      value: ShipmentType.MultiStop.valueOf(),
    },{
      label: this.shipmentTypeTranslated.consolidated,
      value: ShipmentType.Consolidated.valueOf(),
    },{
      label: this.shipmentTypeTranslated.portage,
      value: ShipmentType.Portage.valueOf(),
    },{
      label: this.shipmentTypeTranslated.portageMultiStop,
      value: ShipmentType.PortageMultiStop.valueOf(),
    },{
      label: this.shipmentTypeTranslated.collection,
      value: ShipmentType.Collection.valueOf(),
    },{
      label: this.shipmentTypeTranslated.parcel,
      value: ShipmentType.Parcel.valueOf(),
    });

    return shipmentTypeList;
  }

  /**
   * @description Set and check if all items or some element if selected in the control
   * @param {MatSelect} matSelect object from form configShipmentOrderFormGroup
   */
  public selectAll(matSelect: MatSelect): void {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item?.value === this.defaultValueTypeTrip)
      .map((item: MatOption) => item.selected)[0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getShipmentTypesTranslated();
        await this.getDialogBillingSchemeLabelsTranslated();
      },
      (error) => {
        this.toastService.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(error => {
      this.toastService.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey Optional language key string, default is spanish 'es'
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Gets shipment types from translate JSON files.
   * @return {Promise<void>}
   */
  public async getShipmentTypesTranslated(): Promise<void> {
    this.shipmentTypeTranslated = await this._languageTranslateService.getLanguageLabels('shipmentTypeTags')
    .catch(error => {
      this.toastService.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets account billing scheme labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getDialogBillingSchemeLabelsTranslated(): Promise<void> {
    this.dialogBillingSchemeLabelsTranslated = await this._languageTranslateService.getLanguageLabels('accountBillingSchemeLabels')
    .catch(error => {
      this.toastService.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }
}
