<button
  mat-icon-button [color]="color"
  *ngFor="let ratingId of ratingArr; index as i"
  [id]="'star_' + i"
  (click)="onClick(i + 1)"
  [matTooltip]="ratingId+1" matTooltipPosition="above">
  <mat-icon class="star-class">
    {{ showIcon(i) }}
  </mat-icon>
</button>
