export enum DialogDynamicLocationConsts {
  KEY_ID = '_id',
  OK = 'OK',
  ORIGIN = 'Origen',
  VALID_FORM = 'VALID'
}

export enum DialogDynamicLocationToastLabels {
  errorGettingRFCLocations = 'Error al consultar los RFC de las ubicaciones'
}
