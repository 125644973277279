import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  AdditionalChargeChanges,
  CustomerInvoiceSearch,
  GeneralSearch,
  InvoiceProposal,
  MultipleInvoiceResponse,
  ResponseInvoiceProposal
} from '../../interfaces/invoiceProposal';
import { environment } from '../../../environments/environment';
import { InvoiceProposalParams } from '../../interfaces/account-billing-scheme';

const FREIGHT_COST_API_URL = environment.tmsFreightCostApiUrl;

/**
 * Service to make requests to freight-cost-api for invoice proposal flow.
 */
@Injectable()
export class InvoiceProposalProvider {
  /**
   * @description  Builds an instance of all necessaries services for correctly work of this component.
   * @param {HttpClient} http - Plugin to make requests to backend.
   */
  constructor(private http: HttpClient) {}

  /**
   * @description Adds or updates additional charges inside an invoice proposal.
   * @param {AdditionalChargeChanges} additionalCharge - Additional charge data to saves.
   * @returns {ResponseInvoiceProposal} A succes or fail response message.
   */
  public async addAdditionalCharge(additionalCharge: AdditionalChargeChanges): Promise<ResponseInvoiceProposal> {
    return await this.http.post<ResponseInvoiceProposal>(FREIGHT_COST_API_URL + '/invoiceProposal/additionalCharge',
      additionalCharge).toPromise();
  }

  /**
   * @description Adds or updates discount inside an invoice proposal.
   * @param {AdditionalChargeChanges} discount - Discount data to saves.
   * @returns {ResponseInvoiceProposal} A succes or fail response message.
   */
  public async addDiscount(discount: AdditionalChargeChanges): Promise<ResponseInvoiceProposal> {
    return await this.http.post<ResponseInvoiceProposal>(FREIGHT_COST_API_URL + '/invoiceProposal/discount',
      discount).toPromise();
  }

  /**
   * @description Creates invoice proposal object.
   * @param {InvoiceProposal} invoiceProposal - Invoice proposal object to saves.
   * @returns {ResponseInvoiceProposal} A success or fail message with the object created.
   */
  public async generateInvoicePreInvoiceProposal(invoiceProposal: InvoiceProposal): Promise<ResponseInvoiceProposal> {
    return await this.http.post<ResponseInvoiceProposal>(FREIGHT_COST_API_URL + '/invoiceProposal', invoiceProposal).toPromise();
  }

  /**
   * @description Get the invoice proporsal by shipper/tenant, billing scheme id and others params with invoiceParams.
   * @param {string} shipperId - As id of shipper/tenant.
   * @param {string} billingSchemeId - Id of billing scheme.
   * @param {InvoiceProposalParams} invoiceParams - Params for find the invoice proporsal.
   * @returns {Promise<ResponseInvoiceProposal>} - Response with data.
   */
  public async getInvoiceProporsalByBillingSchemeId(shipperId: string, billingSchemeId: string,
    invoiceParams: InvoiceProposalParams): Promise<ResponseInvoiceProposal> {
    const url = FREIGHT_COST_API_URL + '/invoiceProposal/shipper/' + shipperId + '/billingScheme/' + billingSchemeId;

    return await this.http.post<ResponseInvoiceProposal>(url, invoiceParams).toPromise();
  }

  /**
   * @description Gets many invoice proposals by account and invoice proposal identifier.
   * @param {string} account - Account to filters invoice proposal.
   * @param {string} shipperOId - Shipper _id to set in query.
   * @param {string} invoiceProporsalIdentifier - Invoice identifier to find specific.
   * @returns {ResponseInvoiceProposal} Invoice Proposal found, with success message.
   */
  public async getInvoiceProporsalsByAccountAndIdentifier(account: string, shipperOId: string,
    invoiceProporsalIdentifier: string): Promise<ResponseInvoiceProposal> {
    let url = `${FREIGHT_COST_API_URL}/invoiceProposal/shipper/${shipperOId}/account/${account}`;

    if (invoiceProporsalIdentifier) {
      url += `?invoiceProposal=${invoiceProporsalIdentifier}`;
    }

    return await this.http.get<ResponseInvoiceProposal>(url).toPromise();
  }

  /**
   * @description Gets invoice proposal by date rage and account.
   * @param {string} params - Invoice proposal search params.
   * @param {string} shipperId - Current shipper Object ID.
   * @returns {ResponseInvoiceProposal} Invoice Proposal found, with success message.
   */
  public async getInvoiceProposalByDateAndAccount(params: CustomerInvoiceSearch, shipperId: string): Promise<MultipleInvoiceResponse> {
    return await this.http.post<MultipleInvoiceResponse>(FREIGHT_COST_API_URL +
      '/invoiceProposal/invoiceProposalsByDateAndAccount/shipper/' + shipperId, params).toPromise();
  }

  /**
   * @description Gets invoice proposal by identifier.
   * @param {string} identifier - Invoice proposal identifier generated previously.
   * @param {string} shipperOid - Current shipper Object ID.
   * @returns {ResponseInvoiceProposal} Invoice Proposal found, with success message.
   */
  public async getInvoiceProposalByIdentifier(identifier: string, shipperOid: string): Promise<ResponseInvoiceProposal> {
    return await this.http.get<ResponseInvoiceProposal>(FREIGHT_COST_API_URL + '/invoiceProposal/' +
      identifier + '/shipper/' + shipperOid).toPromise();
  }

  /**
   * @description Gets invoice proposal by orders Oid.
   * @param {GeneralSearch} params - Params to serch invoice proposal general.
   * @returns {ResponseInvoiceProposal} A succes message and Invoice Proposal object.
   */
  public async getInvoiceProposalByOrders(params: GeneralSearch): Promise<ResponseInvoiceProposal> {
    return await this.http.post<ResponseInvoiceProposal>(FREIGHT_COST_API_URL + '/invoiceProposal/invoiceProposalsByOrders',
      params).toPromise();
  }

  /**
   * @description Deletes additional charge.
   * @param {string} invoiceProposalOid - Invoice proposal to search.
   * @param {string} additionalChargeOid - Additional charge to delete.
   * @param {string} username - Username to set in event log.
   * @returns {ResponseInvoiceProposal} Success or fail response message.
   */
  public async removeAdditionalCharge(invoiceProposalOid: string, additionalChargeOid: string, username: string):
    Promise<ResponseInvoiceProposal> {
    return await this.http.delete<ResponseInvoiceProposal>(FREIGHT_COST_API_URL + '/invoiceProposal/' + invoiceProposalOid +
      '/additionalCharge/' + additionalChargeOid + '/user/' + username).toPromise();
  }

  /**
   * @description Delete charge.
   * @param {string} invoiceProposalOid - Invoice proposal to search.
   * @param {string} orderId - Order id.
   * @param {string} chargeId - Charge to delete.
   * @param {string} username - Username to set in event log.
   * @param {string} orderStatus - Order status to set in event log.
   * @returns {Promise<ResponseInvoiceProposal>} Success or fail response message.
   */
  public async removeCharge(
    invoiceProposalOid: string,
    orderId: string,
    chargeId: string,
    username: string,
    orderStatus: string
  ): Promise<ResponseInvoiceProposal> {
    const url = FREIGHT_COST_API_URL +
    '/invoiceProposal/' + invoiceProposalOid + '/order/' + orderId + '/charge/' + chargeId + '/user/' + username +
    '?orderStatus=' + orderStatus;

    return await this.http.delete<ResponseInvoiceProposal>(url).toPromise();
  }

  /**
   * @description Deletes discount.
   * @param {string} invoiceProposalOid - Invoice proposal to search.
   * @param {string} additionalChargeOid - Additional charge to delete.
   * @param {string} username - Username to set in event log.
   * @returns {ResponseInvoiceProposal} Success or fail response message.
   */
  public async removeDiscount(invoiceProposalOid: string, additionalChargeOid: string, username: string):
    Promise<ResponseInvoiceProposal> {
    return await this.http.delete<ResponseInvoiceProposal>(FREIGHT_COST_API_URL + '/invoiceProposal/' + invoiceProposalOid +
      '/discount/' + additionalChargeOid + '/user/' + username).toPromise();
  }

  /**
   * @description Updates additional charges inside an invoice proposal.
   * @param {AdditionalChargeChanges} additionalCharge - Additional charge data to saves.
   * @returns {ResponseInvoiceProposal} A succes or fail response message.
   */
  public async updateAdditionalCharge(additionalCharge: AdditionalChargeChanges): Promise<ResponseInvoiceProposal> {
    return await this.http.put<ResponseInvoiceProposal>(FREIGHT_COST_API_URL + '/invoiceProposal/additionalCharge',
      additionalCharge).toPromise();
  }

  /**
   * @description Updates discount inside an invoice proposal.
   * @param {AdditionalChargeChanges} discount - Discount data to saves.
   * @returns {ResponseInvoiceProposal} A succes or fail response message.
   */
  public async updateDiscount(discount: AdditionalChargeChanges): Promise<ResponseInvoiceProposal> {
    return await this.http.put<ResponseInvoiceProposal>(FREIGHT_COST_API_URL + '/invoiceProposal/discount',
      discount).toPromise();
  }

  /**
   * @description Updates invoice proposal object.
   * @param {InvoiceProposal} invoiceProposal - Invoice proposal object to update.
   * @returns {ResponseInvoiceProposal} A success or fail message with the object updated.
   */
  public async updateInvoiceProposal(invoiceProposal: InvoiceProposal): Promise<ResponseInvoiceProposal> {
    return await this.http.put<ResponseInvoiceProposal>(FREIGHT_COST_API_URL + '/invoiceProposal', invoiceProposal).toPromise();
  }
}
