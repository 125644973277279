import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { fromPromise } from 'rxjs/internal-compatibility';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppService } from '../app.service';
import { AUTHENTICATION_CONSTANTS } from '../constants/authentication.constants';
import { environment } from '../../../src/environments/environment';
import { SsoAuthenticationService } from '../services/authentication/sso-authentication.service';
import { User } from '../interfaces';

/**
 * @class
 * @description - Token Interceptor class.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  urlsToExclude: Array<string>;

  /**
   * @description - Initialize a Token Interceptor instance to add SSO token into requests.
   * @param { AppService } appService - Application Service.
   * @param { SsoAuthenticationService } ssoAuthService - SSO Authentication service.
   */
  constructor(
    private appService: AppService,
    private ssoAuthService: SsoAuthenticationService
  ) {
    this.urlsToExclude= [
      environment.baseStorageUrl,
      environment.secondaryBlobStorage,
      environment.netprintLogoUrl,
      environment.searsApiUrl,
      environment.roadnetApiUrl
    ];
  }

  /**
   * @description - Gets current tenant realm from local storage.
   *
   * @returns { string } - Obtained tenant realm.
   */
  public static isValidTenant(): string {
    const realm = localStorage.getItem(AUTHENTICATION_CONSTANTS.LOCAL_STORAGE_SHIPPER_REALM_KEY);
    if (realm) {
      return realm;
    } else {
      return '';
    }
  }

  public getUserInfo(): User {
    const userinfo = this.appService.getUserInfoFromStorage();

    if (userinfo) {
      return userinfo;
    }

    return null;
  }

  /**
   * @description - Checks wheter the HTTP requests must include Authorization token.
   * @param { string } requestUrl - An outgoing HTTP request url.
   *
   * @returns { boolean } - Returns true or false value.
   */
  private isRequestUrlValidToExclude(requestUrl: string): boolean {
    return this.urlsToExclude.some(url => { 
      if (requestUrl.includes(url)) {
        return requestUrl.includes(url);
      }

      return false;
    });
  }

  /**
   * @description - Intercepts HTTP requests to add SSO token.
   * @param { HttpRequest<any> } request - An outgoing HTTP request.
   * @param { HttpHandler } next - Handler instance to dispatch requests.
   *
   * @returns { Observable<HttpEvent<any>> } - Returns false or thrown an error.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.ssoAuthService.isAuthenticated()) {
      const user = this.getUserInfo();

      if (this.isRequestUrlValidToExclude(request.url)) {
        return next.handle(request);
      }

      return fromPromise(this.ssoAuthService.getToken()).pipe(
        switchMap((token: string) => {
          if (token) {
            request = request.clone({
              setHeaders: {
                authorization: `Bearer ${token}`,
                carrierOid: user ?
                  user.lineaTransporte ?
                    user.lineaTransporte._id :  '' :
                  '',
                shipperOid: user ?
                  user.embarcador ?
                    user.embarcador._id : '' :
                  '',
                tenantId: TokenInterceptor.isValidTenant(),
              }
            });

            return next.handle(request);
          }

          return next.handle(request);
        })
      );
    }

    return next.handle(request);
  }
}
