import { Services } from '../../../../interfaces/marketplace-shipper/shipment-request';

export const MarketplaceConstants = {
  ADDRESS: 'address',
  ALL_REQUEST: 'all',
  ALWAYS: 'always',
  AMOUNT: 'amount',
  APPOINTMENTHOUR: '06:00',
  ASSIGN: 'Asignar',
  ASSIGN_BUDGET: 'assignBudget',
  ASSIGN_EXPIRATION: 'expirationDateAssign',
  CARRIER_GROUP_REQUEST: 'group',
  CARRIER_REQUEST: 'carrier',
  CARRIER_REQUEST_ID: 2,
  CITY: 'city',
  COLLAPSE: 'collapse',
  COMMENTS_TO_CARRIER: 'commentsToCarrier',
  CONFIRMATION_STEP: 3,
  CONTACT: 'contact',
  DATEFORMAT: 'DD/MM/YYYY',
  DATE_FORMAT: 'MM/DD/YYYY',
  DEFAULT_LATITUDE: 19.4325412,
  DEFAULT_LONGITUDE: -99.1338442,
  DEFAULT_ZOOM: 15,
  DELIVERY: 'delivery',
  DELIVERY_ADDRESS: 'deliveryAddress',
  DELIVERY_CITY: 'deliveryCity',
  DELIVERY_CONTACT: 'deliveryContact',
  DELIVERY_DATE: 'deliveryDate',
  DELIVERY_MERCHANDISE_TYPE: 'deliveryMerchandiseType',
  DELIVERY_NAME: 'deliveryName',
  DELIVERY_ONLY_DOWNLOAD: 'deliveryOnlyDownload',
  DELIVERY_ONLY_LOAD: 'deliveryOnlyLoad',
  DELIVERY_PHONE: 'deliveryPhone',
  DELIVERY_POSTAL_CODE: 'deliveryPostalCode',
  DELIVERY_RESPONSIBLE: 'deliveryResponsible',
  DELIVERY_SETTLEMENT: 'deliverySettlement',
  DELIVERY_STATE: 'deliveryState',
  DELIVERY_TIME: 'deliveryTime',
  DELIVERY_VOLUME: 'deliveryVolume',
  DELIVERY_WARNING: 'Favor de llenar los campos de reparto faltantes, si no se agregará más repartos favor de eliminarlos',
  DELIVERY_WEIGHT: 'deliveryWeight',
  DESTINATION_DATE: 'destinationDate',
  DESTINATION_TIME: 'destinationTime',
  DISPLAYED_COLUMNS: [
    'deliveryNumber',
    'name',
    'state',
    'municipality',
    'settlement',
    'postalCode',
    'address',
    'actions'
  ],
  EMPTY_STRING: '',
  EN_DATE_FORMAT: 'en',
  ERROR_MESSAGE: 'Ha ocurrido un error, inténtalo nuevamente',
  EXPIRATION_DATE: 'expirationDate',
  EXPIRATION_ENABLED: 'expirationEnabled',
  EXPIRATION_TIME: 'expirationTime',
  FORMAT_DATE: 'yyyy-MM-dd',
  FRACTIONS_DIGITS: 2,
  GEOLOCATION: 'geolocation',
  GET_ADDRESS: 'address',
  GET_CITY: 'city',
  GET_POSTAL_CODE: 'postalCode',
  GET_SPECIFICATIONS_ERROR: 'No se pudo encontrar especificación del vehículo',
  GET_STATE: 'state',
  GROUP_REQUEST_ID: 3,
  INVALID_DATE: 'Fecha inválida',
  IS_MINOR_TIME: 'isMinorTime',
  LOCALITY: 'locality',
  MINUTES_IN_HOUR: 60,
  MOMENT_DATE: 'yyyy-MM-DDHH:mm',
  MOMENT_FORMAT: 'yyy-MM-DDHH:mm',
  NAME: 'name',
  NOTIFY_SHIPPER: 'notifyToShipper',
  OK: 'OK',
  ONLY_DOWNLOAD: 'onlyDownload',
  ONLY_LOAD: 'onlyLoad',
  ORIGEN: 'Origen',
  ORIGIN: 'origin',
  ORIGIN_ADDRESS: 'originAddress',
  ORIGIN_CITY: 'originCity',
  ORIGIN_CONTACT: 'originContact',
  ORIGIN_DATE: 'originDate',
  ORIGIN_NAME: 'originName',
  ORIGIN_PHONE: 'originPhone',
  ORIGIN_POSTALCODE: 'originPostalCode',
  ORIGIN_REFERENCES: 'originReferences',
  ORIGIN_STATE: 'originState',
  ORIGIN_TIME: 'originTime',
  PANEL_WIDTH: '22%',
  PHONE: 'phone',
  POSTALCODE: 'postalCode',
  POSTAL_CODE: 'postal_code',
  PUBLISH: 'Publicar',
  PUBLISH_EXPIRATION: 'expirationDatePublish',
  RATE_OPTION: 'rateOption',
  RATE_STEP: 2,
  RATING_INIT: 3,
  RATING_STAR_COUNT: 5,
  RECEIVE_COUNTER_OFFERS: 'receiveCounterOffers',
  RECEIVE_OFFERS: 'receiveOffers',
  REQUEST_TYPE: 'Tendering',
  ROUTE: 'route',
  ROUTE_STEP: 1,
  SEARCH_DIRECTION: 'Introduce una ubicación',
  SECONDS_IN_MINUTE: 3600,
  SERVICE_DATE: 'serviceDate',
  SERVICE_TIME: 'serviceTime',
  SHIPPER_SHIPMENT_REQUEST_VIEW: '/marketplace-shipper/shipper-shipment-request',
  SLASH: '/',
  SPECIFICATION: 'specification',
  STATE: 'administrative_area_level_1',
  STATE_PLACE: 'state',
  STATUS_RESPONSE: 'OK',
  STAR_RATING: 5,
  STREET: 'street_address',
  STRING: 'string',
  TIMEFORMAT: 'HH:mm',
  TIMESTAMP_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  TIME_FORMAT: '24:00',
  TRANSPORTLINE: 'transportLine',
  US: 'us',
  VEHICLE_TYPE: 'vehicleType',
  VOLUME: 'volume',
  WEIGHT: 'weight',
  WORD_DATE: 'date',
  WORD_TIME: 'time',
  ZERO_RESULTS: 'ZERO_RESULTS',
  ZOOM: 15
};

export const ShipmentRequestServices: Array<Services> = [
  {icon: 'wc', description: 'Sanitarios'},
  {icon: 'hotel', description: 'Áreas de descanso'},
  {icon: 'restaurant', description: 'Comedor'},
  {icon: 'wifi', description: 'Internet'},
  {icon: 'phone', description: 'Teléfono'}
];
