export enum FilterMenuActions {
  byAccount = 'Account',
  byCarrier = 'TransLine',
  byDateRage = 'Date',
  byDriver = 'Driver',
  byShipper = 'Shipper',
  byStatus = 'Status',
  byWarehouse = 'Warehouse',
  finishedRoutes = 'Finalizadas'
}
