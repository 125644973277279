import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-information-band',
  styleUrls: ['./information-band.component.scss'],
  templateUrl: './information-band.component.html'
})

/**
 * @description Component that displays an informative band where is called, with icon and text provided.
 */
export class InformationBandComponent {
  @Input() public iconName?: string;
  @Input() public iconTooltip?: string;
  @Input() public mainFocusText?: string;
  @Input() public mainText?: string;

  /**
   * @description Constains intances from services or providers used in this component.
   */
  constructor() { }
}
