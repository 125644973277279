<mat-grid-list cols="3" rowHeight="2rem">
    <mat-grid-tile colspan="3">
        <h3 class="dialog-title" >
            {{ labels.title }} {{ shipmentRequestResponse.identifier }}
        </h3>
      </mat-grid-tile>
</mat-grid-list>
<div mat-dialog-content>
    <mat-card class="cardConfirmation">
        <mat-card-content class="mat-container" label="general">
            <mat-grid-list cols="3" rowHeight="7rem" class="mat-container-icon">
                <mat-grid-tile colspan="1">
                  <div class="container-route">
                    <p class="element-decription" >{{ labels.routeLabel }}</p>
                    <p matTooltip="{{ labels.noInfo }}" matTooltipPosition="above">
                      &bull; <span class="element-decription">{{ labels.totalDistanceLabel }}</span>: {{ labels.noInfo }}
                    </p>
                    <p matTooltip="{{ labels.noInfo }}" matTooltipPosition="above">
                      &bull; <span class="element-decription">{{ labels.frequencyLabel }}</span>: {{ labels.noInfo }}
                    </p>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                  <div class="container-route">
                    <p class="element-decription">{{ labels.transportLabel }}</p>
                    <p matTooltip="{{ shipmentRequestResponse.vehicleType.name }}" matTooltipPosition="above">
                      &bull; <span class="element-decription">{{ labels.typeLabel }}</span>: {{ shipmentRequestResponse.vehicleType.name }}
                    </p>
                    <p matTooltip="{{ labels.noInfo }}" matTooltipPosition="above">
                      &bull; <span class="element-decription">{{ labels.specificationsLabel }}</span>: {{ labels.noInfo }}
                    </p>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile class="" colspan="1">
                   <div class="container-money-up">
                     <mat-icon class="icon-money-up">
                       <img class="" src="{{ moneyUpIcon }}" alt="moneyUp icon"/>
                     </mat-icon>
                     <div class="container-text-money-up" matTooltip="{{ shipmentRequestResponse.defaultOffer ? labels.currency + ' ' + (shipmentRequestResponse.defaultOffer | number) : labels.noInfo }}" matTooltipPosition="above">
                       <p class="text-money-up-description element-decription" >{{ labels.rateLabel }}</p>
                       <p class="text-money-up-price element-decription">{{ shipmentRequestResponse.defaultOffer ? labels.currency + ' ' +  (shipmentRequestResponse.defaultOffer | number) : labels.noInfo }}</p>
                     </div>
                  </div>
                </mat-grid-tile>
            </mat-grid-list>
            <hr class="hr-subtitle">
            <mat-grid-list cols="3" rowHeight="7rem" class="mat-container-icon">
                <mat-grid-tile colspan="3">
                  <div class="capture-label-input">
                    <p class="element-decription">
                      {{ labels.descriptionInput }}:
                    </p>
                  </div>
                    <mat-form-field class="full-width">
                      <span class="simbol-input" matPrefix> {{ labels.currency }} &nbsp;</span>
                      <input
                        id="txtQuoteBiis"
                        [(ngModel)]="quote"
                        (ngModelChange)="validateQuote($event)"
                        type="number"
                        min="0"
                        class="input-quote"
                        matInput >
                    </mat-form-field>
                    <mat-icon
                      [ngClass]="'infoIcon'"
                      matTooltip="{{ labels.descriptionPlace }}">
                      info
                    </mat-icon>
                </mat-grid-tile> 
            </mat-grid-list>
            <mat-grid-list cols="3" rowHeight="6rem" class="mat-container-icon">
              <mat-grid-tile colspan="1">
              </mat-grid-tile> 
              <mat-grid-tile colspan="2">
                <button
                  mat-raised-button
                  class="secondary-button align-left button-dialog"
                  (click)="close()">
                  {{ labels.backLabel }}
                </button>
                <button
                  mat-raised-button
                  cdkFocusInitial
                  [disabled]="!validQuote"
                  (click)="sendToMarketPlace()"
                  class="primary-button align-right button-dialog">
                   {{ labels.sendMarketplaceLabel }}
                </button>
              </mat-grid-tile> 
           </mat-grid-list>
        </mat-card-content>
      </mat-card>
</div>