
<mat-grid-list cols="4" rowHeight="1:.15">
  <mat-grid-tile colspan="3" rowspan="1">
    <span class="dialog-title">
      <p>{{ data.title }}</p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1" rowspan="1">
    <mat-icon
      style="cursor: pointer"
      class="dialog-close-icon"
      (click)="onClickClose()">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>
<br>
<mat-grid-list cols="3" rowHeight="1:.20" >
  <mat-grid-tile colspan="3" rowspan="1.50">
    <span class="dialog-resume">
      <p [ngClass]="{'resume-margin': data.warningText}">{{ data.resume }}</p>
    </span>
  </mat-grid-tile>
</mat-grid-list>
<mat-grid-list cols="3" rowHeight="1:.20" >
  <mat-grid-tile colspan="1" rowspan="5">
    <br/>
    <img src="{{ data.iconPath }}" width="80%" height="80%" alt="Dialog image">
  </mat-grid-tile>
  <mat-grid-tile  colspan="2" rowspan="1">
    <span class="text-direction"><strong>{{ data.focusText }}</strong></span>
  </mat-grid-tile>
  <mat-grid-tile  colspan="2" rowspan="1" *ngIf="data.warningText">
    <span class="text-warning"><strong> {{ data.warningText }} </strong></span>
  </mat-grid-tile>
  <mat-grid-tile colspan="2" rowspan="2">
    <span class="text text-direction">{{ data.question }}</span>
  </mat-grid-tile>
  <mat-grid-tile class="button-container">
    <button
      id="dial-std-button-1"
      mat-raised-button
      class="secondary-button align-left button-dialog"
      (click)="onClickButton1()">
      {{data.textButton1}}
    </button>
    <button
      id="dial-std-button-2"
      mat-raised-button
      cdkFocusInitial
      class="primary-button align-right button-dialog"
      (click)="onClickButton2()">
      {{data.textButton2}}
    </button>
  </mat-grid-tile>
</mat-grid-list>
