import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { noPermissionTags } from './no-permission.labels';
import { NoPermissionLabel } from '../../interfaces';

@Component({
  selector: 'app-no-pemission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.scss', '../../app.component.scss']
})
export class NoPermissionComponent {
  public labels: NoPermissionLabel;

  constructor(private router: Router) { this.labels = noPermissionTags; }

  public onClick(): void {
    this.router.navigateByUrl('/home');
  }
}
