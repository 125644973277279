import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppConstants } from '../../../../../app/constants/app-constants.constants';
import { CarrierShipmentLabel, CarrierShipmentLabels } from '../../../../pages/shipments/carrier-shipments/carrier-shipments.labels';
import { DialogMarketplaceConfirm } from '../../../../interfaces/dialog-marketplace-confirm';
import { ToastrAlertsService } from '../../../../services/utils/toastr-alerts.service';

const CANCEL = 'Cancel';
const CONFIRM = 'Confirm';
const CLOSED = 'Closed';
@Component({
  selector: 'app-dialog-marketplace-confirm',
  templateUrl: './dialog-marketplace-confirm.component.html',
  styleUrls: ['./dialog-marketplace-confirm.component.scss', '../../../../app.component.scss']
})
export class DialogMarketplaceConfirmComponent {
  public errorAlertDisplayed: boolean;
  public labels: CarrierShipmentLabel;
  public rateFormGroup: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogMarketplaceConfirm,
    public dialogRef: MatDialogRef<DialogMarketplaceConfirmComponent>,
    private formBuilder: UntypedFormBuilder,
    private toastService: ToastrAlertsService
  ) {
    this.labels = CarrierShipmentLabels;
    this.errorAlertDisplayed = false;
    this.rateFormGroup = formBuilder.group({
      amount: new UntypedFormControl(''),
    });
    this.evaluateRate();
  }

  /**
   * @description Method to evaluate if data has the focus question to set as required the amount input
   */
  private evaluateRate(): void {
    if (this.data.focusQuestion === AppConstants.EMPTY_STRING) {
      this.rateFormGroup.controls['amount'].setValidators(Validators.required);
    }
  }

  /**
   * @description check if the amount is valid to hide/display an error toast
   */
   public validateBudget(): void {
    const amountInput = this.rateFormGroup.get('amount').value;
    if ((amountInput !== AppConstants.EMPTY_STRING) && (amountInput <= 0)) {
      this.rateFormGroup.controls['amount'].setErrors({'invalidAmount': true});
      if (!this.errorAlertDisplayed) {
        this.toastService.errorAlert(CarrierShipmentLabels.invalidAmountError);
        this.errorAlertDisplayed = true;
      }
    } else {
      this.errorAlertDisplayed = false;
    }
  }

  /**
   * @description function called when the primary button of the modal is clicked (cancel)
   */
  public onPrimaryButton(): void {
    this.dialogRef.close(CANCEL);
  }

  /**
   * @description function called when the secondary button of the modal is clicked (confirm)
   */
  public onSecondaryButton(): void {
    const response = [];
    response.push(CONFIRM, this.rateFormGroup.value.amount);
    this.dialogRef.close(response);
  }

  /**
   * @description method to close the modal
   */
  public onClose(): void {
    this.dialogRef.close(CLOSED);
  }
}
