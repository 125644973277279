import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogSuccessDetailData } from '../../../interfaces/index';

const CLOSED = 'Closed';
const CONFIRM = 'Confirm';

@Component({
  selector: 'app-dialog-success-detail',
  templateUrl: './dialog-success-detail.component.html',
  styleUrls: ['./dialog-success-detail.component.scss', '../../../app.component.scss']
})
export class DialogSuccessDetailComponent implements OnInit {
  public subtitles: Array<string>;
  public subtitleData: Array<string>;

  constructor(public dialogRef: MatDialogRef<DialogSuccessDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogSuccessDetailData) { }

  ngOnInit(): void {
    this.subtitles =  this.data.subtitles;
    this.subtitleData = this.data.subtitleData;
  }

  public onClickButton(): void {
    this.dialogRef.close(CONFIRM);
  }

  public onClickClose(): void {
    this.dialogRef.close(CLOSED);
  }
}
