import { Component, OnDestroy, OnInit } from '@angular/core';
import { find, isEmpty, isUndefined } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { Router, RoutesRecognized } from '@angular/router';
import { ScfCommunicationService, ScfNotification, ScfNotificationCenterConfig } from 'scf-library';
import { Subscription } from 'rxjs';

import { AppConstants } from './constants/app-constants.constants';
import { AppService } from './app.service';
import { AuthService } from './authentication/auth.service';
import { DialogStandardComponent } from './components/dialog/dialog-standard/dialog-standard.component';
import { environment } from '../environments/environment';
import { filter, pairwise } from '../../node_modules/rxjs/operators';
import { IMenuInterface } from './interfaces/menu-interface';
import { Language } from './interfaces/language.interface';
import { LanguageArrayResponse } from './interfaces/languageResponse.interface';
import { LanguageChangeEventService } from './services/translate/language-change-event.service';
import { LanguageService } from './providers/languages/language.service';
import { LanguageTranslateService } from './services/translate/language-translate.service';
import { NotificationsService } from './providers/notifications/notifications.service';
import { ToastrAlertsService } from './services/utils/toastr-alerts.service';
import {
  User,
  WarehouseChangeBody,
  WarehouseDefault,
  WarehouseSelector
} from './interfaces';
import { UserResponse } from './interfaces/userResponse.interface';
import { UserService } from './providers/user/user.service';
import { UtilsService } from './services/utils.service';

const KEY_ALLOWEDROUTES = 'allowed_routes';
const SOLUTION = 'tep';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit, OnDestroy {
  public avatarImgSrc: string;
  public configurationPanel: boolean;
  public currentRoute: string;
  public defaultWarehouse: WarehouseDefault;
  public languageChangedSubs: Subscription;
  public languageLabels: any;
  public languages: Array<Language>;
  public logoutRequest: boolean;
  public logoutSubs: Subscription;
  public menu: IMenuInterface[];
  public navigateToSubs: Subscription;
  public notificationCenterConfig: ScfNotificationCenterConfig;
  public notificationReadSubs: Subscription;
  public owner: string;
  public pagesLabelsTranslated: any;
  public previousRoute: string;
  public solution: string;
  public tenantName: string;
  public userFullName: string;
  public userProfile: string;
  public warehouseChangedSubs: Subscription;
  public warehouseList: Array<WarehouseSelector>;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private dialog: MatDialog,
    private languageChangeEventService: LanguageChangeEventService,
    private languageService: LanguageService,
    private languageTranslateService: LanguageTranslateService,
    private userService: UserService,
    private notificationService: NotificationsService,
    private router: Router,
    private scfComService: ScfCommunicationService,
    private toast: ToastrAlertsService,
    private utilsService: UtilsService
  ) {
    this.configurationPanel = environment.languageEnabled;
    this.currentRoute = null;
    this.buildMenu();
    this.owner = '';
    this.previousRoute = null;
    this.solution = SOLUTION;
    this.notificationCenterConfig = {
      url: environment.notificationApiUrl,
      userId: this.appService.getUserOid()
    };
    this.setLanguage();
  }

  /**
   * @description Angular destroy lifecycle.
   */
  public ngOnDestroy(): void {
    if (!isUndefined(this.navigateToSubs)) {
      this.navigateToSubs.unsubscribe();
    }

    if (!isUndefined(this.logoutSubs)) {
      this.logoutSubs.unsubscribe();
    }

    if (!isUndefined(this.notificationReadSubs)) {
      this.notificationReadSubs.unsubscribe();
    }

    if (!isUndefined(this.warehouseChangedSubs)) {
      this.warehouseChangedSubs.unsubscribe();
    }

    if (!isUndefined(this.languageChangedSubs)) {
      this.languageChangedSubs.unsubscribe();
    }
  }

  /**
   * @description Angular lifecycle for component initialization.
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeToScfEvents();
    await this.getLanguages();
    await this.getLanguageTags();
    await this.getPagesLanguageLabels();
    this.logoutRequest = false;
    this.subscribeRoutes();
    await this.initUserPanel();

    const user = this.appService.getUserInfoFromStorage();
    if (!user.lineaTransporte) {
      await this.getUserWarehouses();
    }
  }

  /**
   * @description Retrieves the warehouses of user to show in the warehouse selector.
   */
  public async getUserWarehouses(): Promise<void> {
    try {
      const propertyName = 'name';
      const user = await this.userService.getUserById(this.notificationCenterConfig.userId);
      this.defaultWarehouse = user.warehouseDefault;

      if (!isEmpty(user?.warehouses)) {
        const data = user.warehouses.map(item => {
            const isSelected = (user.warehouseDefault._id === item._id ||
              user.warehouseDefault.name.trim() === item.name.trim());
            return {
              id: item._id,
              name: item.name,
              isSelected: isSelected
            }
        }) as Array<WarehouseSelector>;
        this.utilsService.orderByAsc(data, propertyName, true);
        this.warehouseList = data;
      }
    } catch (error) {
      this.toast.warningAlert(this.pagesLabelsTranslated.errorGettingUserInformation);
    }
  }

  /**
   * @description Build the sidebar Menu
   * @return {void}
   */
  public async buildMenu(): Promise<void> {
    this.menu = JSON.parse(localStorage.getItem(KEY_ALLOWEDROUTES));
  }

  /**
   * @description Subscribes to the Router Service and switches current and previous route accordingly
   * @return {void}
   */
  private subscribeRoutes(): void {
    this.router.events
      .pipe(filter(event => event instanceof RoutesRecognized))
      .pipe(pairwise())
      .subscribe((events: any[]) => {
        this.currentRoute = events.pop().url;
        this.previousRoute = events.pop().url;
      });
  }

  /**
   * @description Init User panel data
   */
  public async initUserPanel(): Promise<void> {
    const user = this.appService.getUserInfoFromStorage();
    this.owner = user.username;
    this.userProfile = await (await this.appService.getUserRole()).name;

    if (user.lineaTransporte) {
      this.tenantName = user.lineaTransporte.nombre;
    } else {
      this.tenantName = user.embarcador.nombre;
    }

    this.avatarImgSrc = this.getTenantLogo(user);
  }

  /**
   * @description Gets application languages.
   * @return {Promise<void>}
   */
  public async getLanguages(): Promise<void> {
    await this.languageService
      .getLanguages()
      .then((result: LanguageArrayResponse) => {
        this.languages = result.item;
      })
      .catch((err) => {
        console.error(err);
        this.toast.errorAlert(this.languageLabels.errorGettingLanguages);
      });
  }

  /**
  * @description Subscribes to SCF Events
  * @return {void}
  */
  private subscribeToScfEvents(): void {
    this.navigateToSubs = this.scfComService
      .navigateToSubscribe()
      .subscribe((routerLink: string) => {
        if (routerLink) {
          this.router.navigate([routerLink]);
        }
      });

    this.logoutSubs = this.scfComService
      .logoutSubscribe()
      .subscribe((navRequest: any) => {
        if (!navRequest) {
          this.logoutRequest = true;
          // this._authService.logout();
          this.authService.teplogout();
        }
      });

    this.notificationReadSubs = this.scfComService
      .notificationReadSubscribe()
      .subscribe((notification: ScfNotification) => {
        if (notification) {
          this.notificationService.handleNotificationAction(notification);
        }
      });

    this.languageChangedSubs = this.scfComService
      .languageChangedSubscribe()
      .subscribe(async (languageKey: string) => {
        if (languageKey) {
          this.setLanguage(languageKey);
          this.emitLanguageChangeEvent(languageKey);
          this.updateUserLanguage(languageKey);
          await this.translateMenu();
          await this.getPagesLanguageLabels();
        }
      });

    this.warehouseChangedSubs = this.scfComService
      .warehouseChangedSubscribe()
      .subscribe((warehouseId: number) => {
        if (warehouseId) {
          const warehouse: WarehouseSelector = find(this.warehouseList, ['id', warehouseId]);

          if (warehouse) {
            this.showWarehouseDialogChange(warehouse);
          }
        }
      });
  }

  /**
   * @description Translate menu labels and sort them alphabetically
   * @return {Promise<void>}
   */
  private async translateMenu(): Promise<void> {
    const currentMenu = JSON.parse(localStorage.getItem(KEY_ALLOWEDROUTES));
    const translatedMenu = await this.authService.translateMenu(currentMenu);
    await this.authService.sortModulesAndViews(translatedMenu);
    localStorage.setItem(KEY_ALLOWEDROUTES, JSON.stringify(translatedMenu));
    this.menu = translatedMenu;
  }

  /**
   * @description Reacts to the SCF language change event emitting another event to be used by other components.
   * @return {void}
   */
  public emitLanguageChangeEvent(languageKey: string): void {
    this.languageChangeEventService.emitLanguageChangeEvent(languageKey);
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this.languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the SCF language change event updating the user's language in database
   * @return {Promise<void>}
   */
  public async updateUserLanguage(key: string): Promise<void> {
    const userID = this.appService.getUserOid();
    const userBody = {
      language: {
        _key: key
      }
    };

    await this.userService.updateUserLanguage(userID, userBody)
      .then((result: UserResponse) => {
        return result.item;
      })
      .catch((err) => {
        console.error(err);
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this.languageTranslateService.getLanguageLabels('languageLabels')
    .catch(error => {
      console.log(error);
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets Language Labels of page from translate JSON files.
   */
  public async getPagesLanguageLabels(): Promise<void> {
    this.pagesLabelsTranslated = await this.languageTranslateService.getLanguageLabels('pagesLabels')
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Get tenant logo
   * @param {User} user Current logged user
   * @returns {string} URL logo
   */
  public getTenantLogo(user: User): string {
    let logo = environment.baseStorageUrl + environment.mainContainer;
    let url: string;

    if (user.embarcador) {
      url = user.embarcador.configuracion.logoUrl;
    }

    if (url && url !== '') {
      logo += url;
    } else {
      logo = undefined;
    }

    return logo;
  }

  /**
   * @description Changes the user's default warehouse to specified in param.
   * @param {WarehouseSelector} warehouseData - Information about the warehouse to set as default.
   */
  private async changeWarehouse(warehouseData: WarehouseSelector): Promise<void> {
    try {
      this.toast.processingAlert();
      const bodyAux: WarehouseChangeBody = {
        warehouseDefaultUpdate: true,
        warehouse: {
          _id: warehouseData.id,
          name: warehouseData.name
        }
      };
      const result = await this.userService.updateUserDefaultWarehouse(this.notificationCenterConfig.userId, bodyAux);

      if (result['item']) {
        this.toast.successAlert(this.pagesLabelsTranslated.successChangeWarehouse + warehouseData.name);
        this.updateWarehouseList(warehouseData);
        const path = location.pathname;
        this.navigateView(path);
      } else {
        this.toast.errorAlert(this.pagesLabelsTranslated.errorChangeWerehouse);
      }
    } catch (error) {
      this.toast.errorAlert(this.pagesLabelsTranslated.errorChangeWerehouse);
    } finally {
      this.toast.closeProcessing();
    }
  }

  /**
   * @description Updates the warehouse list based on the provided data, selecting a new warehouse.
   * @param {WarehouseSelector} warehouseData - The data of the warehouse to be selected.
   */
  private updateWarehouseList(warehouseData: WarehouseSelector): void {
    const currentWarehouse = this.warehouseList.find((item: WarehouseSelector) => {
      return item.isSelected;
    });
    currentWarehouse.isSelected = false;
    warehouseData.isSelected = true;
    this.utilsService.orderByAsc(this.warehouseList, 'name', true);
  }

  /**
   * @description Navigates to a specific view within the system.
   * @param {string} path - The route to navigate to.
   */
  private navigateView(path: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    this.router.navigate([path]));
  }

  /**
   * @description Displays a dialog for changing the warehouse.
   * @param {WarehouseSelector} warehouseData - Current Information of warehouse.
   */
  private showWarehouseDialogChange(warehouseData: WarehouseSelector): void {
    const dialogRef = this.dialog.open(DialogStandardComponent, {
      data: {
        title: this.pagesLabelsTranslated?.dialogChangeWarehouseTitle + warehouseData.name,
        resume: this.pagesLabelsTranslated?.dialogChangeWarehouseResume,
        question: this.pagesLabelsTranslated?.dialogChangeWarehouseQuestion,
        button1: this.pagesLabelsTranslated?.dialogChangeWarehouseCancel,
        button2: this.pagesLabelsTranslated?.dialogChangeWarehouseContinue,
        warehouses: this.warehouseList,
        defaultWarehouse: this.defaultWarehouse,
        userId: this.notificationCenterConfig.userId
      },
      width: AppConstants.DIALOG_STANDARD_SIZE,
      height: AppConstants.DIALOG_STANDARD_HEIGHT
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result === AppConstants.CONFIRM) {
        await this.changeWarehouse(warehouseData);
      } else {
        this.refillWarehouseList();
      }
    });
  }

  /**
   * @description Refills the warehouse list with a delay to trigger a view update.
   */
  private refillWarehouseList(): void {
    const timeOut = 100;
    const warehouseListCopy = [...this.warehouseList];
    this.warehouseList = undefined;
    setTimeout(() => {
      this.warehouseList = warehouseListCopy;
    }, timeOut);
  }
}
