import { Injectable } from '@angular/core';

import { AppConstants } from '../../app/constants/app-constants.constants';
import { BILLING_SHEET_CONSTANTS } from '../pages/costs/billing-sheet/billing-sheet.properties';
import { GroupedShipmentSaleChargeByFolio, IBillingSheetPost } from '../interfaces';
import { SalesSheetService } from './sales-sheet/sales-sheet.service';
import { ShipmentCostCharge } from '../interfaces/shipmentCostCharges';
import { ShipmentSaleCharge } from '../interfaces/shipmentSaleCharge';
import { UtilsService } from './utils.service';

@Injectable()

/**
 * @description Service with methods to format differents properties from billing sheets.
 */
export class BillingSheetService {
  /**
   * @description Builds an instance of all necessaries services for correctly work of this component.
   * @param {SalesSheetService} salesSheetService - Service to work with sales sheet info.
   * @param {UtilsService} utils - Gets the utility functions.
   */
  constructor(private salesSheetService: SalesSheetService, private utils: UtilsService) { }

  /**
   * @description Receives data from billing sheet to build as string the creation date of that billing sheet.
   * @param {IBillingSheetPost} billingSheet - Data of billing sheet.
   * @returns {string} Returns as string the creation date of received billing scheme (YYYY-MM-DD HH:MM:SS).
   */
  public buildBillingSheetCreationDate(billingSheet: IBillingSheetPost): string {
    const dateTime = billingSheet.creationDate.split(AppConstants.DATE_SEPARATOR);
    const time = dateTime[1].split(AppConstants.DOT_CHAR);

    return `${dateTime[0]} ${time[0]}`;
  }

  /**
   * @description Calculates and updates subtotal, VAT, and total values for each billing sheet.
   * @param {Array<IBillingSheetPost>} billingSheetPosts - List of billing sheets to update.
   * @param {Array<GroupedShipmentSaleChargeByFolio>} shipmentSaleChargesByFolio - Grouped shipment sale charges by folio.
   * @param {boolean} hasWithHoldingsTotal - Flag indicating if withholdings should be calculated.
   * @returns {Promise<Array<IBillingSheetPost>>} - Updated list of billing sheets with calculated values.
   */
  public async calculateAndUpdateTotals(billingSheetPosts: Array<IBillingSheetPost>,
    shipmentSaleChargesByFolio: Array<GroupedShipmentSaleChargeByFolio>, hasWithHoldingsTotal: boolean): Promise<Array<IBillingSheetPost>> {
    await this.salesSheetService.getShipperConfig();

    return billingSheetPosts.map((billingSheetPost: IBillingSheetPost) => {
      const shipmentSaleChargeByFolio = shipmentSaleChargesByFolio.find((item: GroupedShipmentSaleChargeByFolio) => {
        return item.folio === billingSheetPost.folio;
      });
      const subTotalValue = this.utils.fixNumberToDecimals(shipmentSaleChargeByFolio?.totalCharge ?? 0);
      const vatValue = this.salesSheetService.calculateVat(subTotalValue, AppConstants.IVA_FACTOR);
      let witholdings = 0;

      if (hasWithHoldingsTotal) {
        witholdings = this.calculateWithholdingsTotal(
          shipmentSaleChargeByFolio.charges,
          shipmentSaleChargeByFolio.totalCharge
        );
      }
      billingSheetPost.withholdingsTotal = this.utils.fixNumberToDecimals(witholdings);
      billingSheetPost.subTotal = subTotalValue;
      billingSheetPost.vat = vatValue;
      billingSheetPost.total = this.utils.fixNumberToDecimals(subTotalValue + vatValue - witholdings);

      return billingSheetPost;
    });
  }

  /**
   * @description Returns label status of billing sheet if have invoice registered or pending.
   * @param {string} pendingLabel - Label for pending status in current language.
   * @param {string} paidLabel - Label for paid status in current language.
   * @param {string} status - Status of billing sheet.
   * @returns {string} Label to display for billing sheet status.
   */
  public getInvoiceStatusLabel(pendingLabel: string, paidLabel: string, status?: string): string {
    if (!status || status === pendingLabel) {
      return pendingLabel;
    }

    return paidLabel;
  }

  /**
   * @description Groups shipment sale charges or shipment cost charges by their billing sheet folio and calculates total charges
   * and charge names for each group.
   * @param {Array<ShipmentSaleCharge | ShipmentCostCharge>} charges - The array of charges to be grouped.
   * @returns {Array<GroupedShipmentSaleChargeByFolio>} - An array of grouped charges.
   */
  public groupShipmentSaleChargesByFolio(charges: Array<ShipmentSaleCharge | ShipmentCostCharge>): Array<GroupedShipmentSaleChargeByFolio> {
    const groupedData = {};

    for (const charge of charges) {
      const folio = charge['saleBillingSheetFolio'] || charge['costBillingSheetFolio'];
      const key = `${folio}`;

      if (!groupedData[key]) {
        groupedData[key] = {
          charges: [],
          folio: folio,
          totalCharge: 0
        };
      }
      groupedData[folio].totalCharge += charge.amount;
      groupedData[folio].charges.push(charge);
    }

    return Object.values(groupedData) as Array<GroupedShipmentSaleChargeByFolio>;
  }

  /**
   * @description Replaces a specified column in an array of column names.
   * @param {Array<string>} columns - The array of column names.
   * @param {string} targetColumn - The column name to be replaced.
   * @param {string} newColumn - The new column name to replace the target.
   * @returns {Array<string>} - The array with the updated column names.
   */
  public replaceColumn(columns: Array<string>, targetColumn: string, newColumn: string): Array<string> {
    return columns.map((column: string) => {
      return column === targetColumn ? newColumn : column;
    });
  }

  /**
   * @description Calculates the total amount of withholdings for a set of charges,
   * including various types such as freight, maneuvers, withholding costs, and income tax.
   * @param {Array<object>} charges - The list of charges to be processed.
   * @param {number} subTotal - The subtotal amount used for calculating income tax.
   * @returns {number} - The total amount of withholdings after applying all calculations.
   */
  private calculateWithholdingsTotal(charges: Array<object>, subTotal: number): number {
    const chargeItems = charges as Array<any>;
    const freightsWithholdings = this.salesSheetService.calculateFreightsWithholdingCost(chargeItems,
      [BILLING_SHEET_CONSTANTS.FREIGTH.toLowerCase(), BILLING_SHEET_CONSTANTS.FREIGHTS.toLowerCase()]);
    const maneuversWithholdings = this.salesSheetService.calculateManeuversWithholdingCost(chargeItems, [
      BILLING_SHEET_CONSTANTS.MANEUVER.toLowerCase(), BILLING_SHEET_CONSTANTS.MANEUVERS.toLowerCase()]);
    const withholdingCost = this.salesSheetService.calculateWithholdingCost(
      chargeItems,
      [BILLING_SHEET_CONSTANTS.DELIVERY_CHARGE.toLowerCase(),
        BILLING_SHEET_CONSTANTS.REJECTION_CHARGE.toLowerCase(),
        BILLING_SHEET_CONSTANTS.RETURN_CHARGE.toLowerCase()],
      false);
    const incomeTax = this.salesSheetService.calculateIncomeTaxCost(subTotal);
    const withholdingsTotal = freightsWithholdings + maneuversWithholdings + withholdingCost + incomeTax;

    return this.utils.fixNumberToDecimals(withholdingsTotal);
  }
}
