import { Injectable } from '@angular/core';

import { COFEPRIS_CONSTANTS, FieldsByCofeprisSector } from '../../../app/enums/cofepris-common-data';

/**
 * @description A service with methods to handle the properties from a product object based on its Cofepris sector.
 */
@Injectable()
export class CofeprisUtilsService {
  constructor() { }

  /**
   * @description Cleans unnecessary properties from a product object based on its Cofepris sector.
   * @param {object} product - The product object to be cleaned.
   */
  public cleanProduct(product: object): void {
    const allProperties = [
      FieldsByCofeprisSector.ACTIVE_INGREDIENT_NAME,
      FieldsByCofeprisSector.CHEMICAL_NAME,
      FieldsByCofeprisSector.MANUFACTURER,
      FieldsByCofeprisSector.EXPIRATION_DATE,
      FieldsByCofeprisSector.LOT,
      FieldsByCofeprisSector.PHARMACEUTICAL_FORM,
      FieldsByCofeprisSector.TRANSPORT_SPECIAL_CONDITIONS,
      FieldsByCofeprisSector.IMPORT_VUCEM_FOLIO,
      FieldsByCofeprisSector.IMPORT_LICENSE,
      FieldsByCofeprisSector.MATTER_TYPE,
      FieldsByCofeprisSector.ACTIVE_SUBSTANCE_GENERIC,
      FieldsByCofeprisSector.TRADEMARK_MEDICATION,
      FieldsByCofeprisSector.HEALTH_REGISTER_FOLIO,
      FieldsByCofeprisSector.NUM_CAS,
      FieldsByCofeprisSector.IMPORT_COMPANY,
      FieldsByCofeprisSector.COFEPRIS_PESTICIDE_NUMBER,
      FieldsByCofeprisSector.ACTIVE_INGREDIENT_MANUFACTURER_COUNTRY_NAME,
      FieldsByCofeprisSector.ACTIVE_INGREDIENT_MAQUILA_COUNTRY_NAME,
      FieldsByCofeprisSector.AUTHORIZED_USE
    ];
    const propertiesBySector: Array<string> = this.getPropertiesBySector(product['cofeprisSector']);

    if (!propertiesBySector.length) {
      return;
    }
    const propertiesFiltered: Array<string> = allProperties.filter(element => !propertiesBySector.includes(element));

    propertiesFiltered?.forEach((property: string) => {
      if (product.hasOwnProperty(property)) {
        product[property] = undefined;
      }
    });
  }

  /**
   * @description Retrieves properties based on the COFEPRIS sector.
   * @param {string} cofeprisSector - The COFEPRIS sector key.
   * @returns {Array<string>} An array containing properties related to the specified COFEPRIS sector.
   */
  public getPropertiesBySector(cofeprisSector: string): Array<string> {
    const propertiesBySector = [];

    if (cofeprisSector === COFEPRIS_CONSTANTS.SECTOR_COFEPRIS.KEY_01) {
      propertiesBySector.push(
        ...FieldsByCofeprisSector.FIELDS_SECTOR_01
      );
    }

    if (cofeprisSector === COFEPRIS_CONSTANTS.SECTOR_COFEPRIS.KEY_02) {
      propertiesBySector.push(
        ...FieldsByCofeprisSector.FIELDS_SECTOR_02
      );
    }

    if (cofeprisSector === COFEPRIS_CONSTANTS.SECTOR_COFEPRIS.KEY_03) {
      propertiesBySector.push(
        ...FieldsByCofeprisSector.FIELDS_SECTOR_03
      );
    }

    if (cofeprisSector === COFEPRIS_CONSTANTS.SECTOR_COFEPRIS.KEY_04) {
      propertiesBySector.push(
        ...FieldsByCofeprisSector.FIELDS_SECTOR_04
      );
    }

    if (cofeprisSector === COFEPRIS_CONSTANTS.SECTOR_COFEPRIS.KEY_05) {
      propertiesBySector.push(
        ...FieldsByCofeprisSector.FIELDS_SECTOR_05
      );
    }

    return propertiesBySector;
  }
}
