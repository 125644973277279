import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogTemperatureAlertData } from '../../../interfaces/dialog-temperature-alert';

const ACCEPT = 'Accept';
const BACK = 'Back';
const OPEN_NEW_TAB = 'Open a new tap';

@Component({
  selector: 'app-dialog-temperature-alert',
  templateUrl: './dialog-temperature-alert.component.html',
  styleUrls: ['./dialog-temperature-alert.component.scss']
})
export class DialogTemperatureAlertComponent {

  constructor(public dialogRef: MatDialogRef<DialogTemperatureAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTemperatureAlertData ) { }

  /**
   * @description Closes the dialog
   */
  public onClickClose(): void {
    this.dialogRef.close(BACK);
  }

  /**
   * @description Redirects to the tracking view in the same tab
   */
  public onClickButton1(): void {
    this.dialogRef.close(OPEN_NEW_TAB);
  }

  /**
   * @description Redirects to the tracking view in another tab
   */
  public onClickButton2(): void {
    this.dialogRef.close(ACCEPT);
  }
}
