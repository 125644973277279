import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { AppConstants } from '../../../constants/app-constants.constants';
import { DIALOG_PASSWORD_CONSTANTS } from './dialog-password.constants';
import { DialogPasswordData } from '../../../interfaces/dialog-password';
import { DriverProvider } from '../../../providers/driver/driver.provider.service';
import { DriverUpdateBody } from '../../../interfaces';
import { FormsService } from '../../../services/utils/forms.service';
import { GenericRegexp } from '../../../regexp/generic.regexp';
import { IDialogPasswordLabels } from '../../../interfaces/labels/dialog-labels.interface';
import { ILanguageLabels } from '../../../interfaces/labels/language-labels.interface';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';


@Component({
  selector: 'app-dialog-password',
  templateUrl: './dialog-password.component.html',
  styleUrls: ['./dialog-password.component.scss', '../../../app.component.scss']
})
export class DialogPasswordComponent implements OnInit {
  public confirmPassword: string;
  public confirmNewPasswordType: string;
  public currentPassword: string;
  public image: string;
  public languageLabels: ILanguageLabels;
  public languageSuscription: Subscription;
  public newPasswordType: string;
  public notMatch: boolean;
  public password: string;
  public dialogPasswordLabels: IDialogPasswordLabels;
  public passwordForm: UntypedFormGroup;
  public user: string;
  public validPasswords: boolean;

  async ngOnInit(): Promise<void> {
    this.user = this.data.user;
    this.password = AppConstants.EMPTY_STRING;
    this.confirmPassword = AppConstants.EMPTY_STRING;
    this.newPasswordType = DIALOG_PASSWORD_CONSTANTS.PASSWORD_TYPE;
    this.confirmNewPasswordType = DIALOG_PASSWORD_CONSTANTS.PASSWORD_TYPE;
    this.initForm(this.builder);
    this.subscribeLanguageChangeEvent();
    await this.getLabels();
    this.notMatch = true;
    this.validPasswords = false;
    this.validatePasswords();
    this.image = DIALOG_PASSWORD_CONSTANTS.IMG_REF;
  }

  constructor(
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService,
    private dialogRef: MatDialogRef<DialogPasswordComponent>,
    private driverProvider: DriverProvider,
    private formsService: FormsService,
    private readonly builder: UntypedFormBuilder,
    private toast: ToastrAlertsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogPasswordData) {
      this.currentPassword = AppConstants.EMPTY_STRING;
      this.setLanguage();
    }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Gets the necessary tags from the JSON files to use throughout the component
   * @return {void}
   */
  public async getLabels(): Promise<void> {
    await this.getLanguageLabels();
    await this.getDialogPasswordLabels();
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getLabels();
      },
      () => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageLabels(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets Dialog Password Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getDialogPasswordLabels(): Promise<void> {
    this.dialogPasswordLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.DIALOG_PASSWORD_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  public onClickCancel(): void {
    this.dialogRef.close(DIALOG_PASSWORD_CONSTANTS.CANCEL);
  }
  public onClickSave(): void {
    this.onUpdate();
  }
  public onClickClose(): void {
    this.dialogRef.close(DIALOG_PASSWORD_CONSTANTS.CLOSED);
  }

  private initForm(fb: UntypedFormBuilder): void {
    this.passwordForm = fb.group({
      password: new UntypedFormControl(AppConstants.EMPTY_STRING, [
        Validators.required,
        Validators.minLength(DIALOG_PASSWORD_CONSTANTS.MIN_LENGTH_PASSWORD),
        this.formsService.noWhitespaceValidator,
        this.formsService.inbetweenSpaceValidator,
        Validators.pattern(GenericRegexp.CAPITAL_AND_LOWERCASE_LETTER_NUMBER_AND_SPECIAL_CHARACTER)]),
      confirmPassword: new UntypedFormControl(AppConstants.EMPTY_STRING, [
        Validators.required,
        Validators.minLength(DIALOG_PASSWORD_CONSTANTS.MIN_LENGTH_PASSWORD),
        this.formsService.noWhitespaceValidator])
      }, { validators: this.formsService.passwordValidator });
  }

  /**
   * @description It evaluates the error that should be displayed in the mat error tag
   * @param confirm a flag that helps to know if the form control is for the confirm input
   */
  public getErrorMessage(confirm: boolean = false) {
    let message = '';
    let control: AbstractControl;
    control = confirm ? this.passwordForm.get(DIALOG_PASSWORD_CONSTANTS.KEY_CONFIRM_PASSWORD) : this.passwordForm.get(DIALOG_PASSWORD_CONSTANTS.KEY_PASSWORD);

    if (control.hasError(DIALOG_PASSWORD_CONSTANTS.KEY_ERROR_REQUIRED)) {
      message = this.dialogPasswordLabels ? this.dialogPasswordLabels.messageRequired : AppConstants.EMPTY_STRING;
    } else if (control.hasError(DIALOG_PASSWORD_CONSTANTS.KEY_ERROR_MINLENGTH)) {
      message = this.dialogPasswordLabels.messageMinLength;
    } else if (this.passwordForm.hasError(DIALOG_PASSWORD_CONSTANTS.KEY_ERROR_NOTMATCH) && confirm) {
      message = this.dialogPasswordLabels.messageNotMatch;
    } else if (control.hasError(DIALOG_PASSWORD_CONSTANTS.KEY_ERROR_WHITESPACE)) {
      message = this.dialogPasswordLabels.messageWhitespace;
    } else if (control.hasError(DIALOG_PASSWORD_CONSTANTS.KEY_ERROR_PATTERN)) {
      message = this.dialogPasswordLabels.messagePattern;
    } else if (this.isNewPasswordSameAsCurrent()) {
      message = this.dialogPasswordLabels.messageErrorPasswordRepeated;
    }

    return message;
  }

  /**
   * @description It contains the validations to know if a password is savable
   */
  public validatePasswords(): void {
    this.passwordForm.valueChanges.subscribe(() => {
      if (this.passwordForm.get(DIALOG_PASSWORD_CONSTANTS.KEY_CONFIRM_PASSWORD).hasError(DIALOG_PASSWORD_CONSTANTS.KEY_ERROR_INCORRECT) 
          || this.passwordForm.hasError(DIALOG_PASSWORD_CONSTANTS.KEY_ERROR_NOTMATCH)) {
        this.validPasswords = false;
      } else {
        if (this.passwordForm.get(DIALOG_PASSWORD_CONSTANTS.KEY_CONFIRM_PASSWORD)
            .hasError(DIALOG_PASSWORD_CONSTANTS.KEY_ERROR_WHITESPACE)) {
          this.validPasswords = false;
        } else {
          this.validPasswords = true;
        }
      }
    });
  }

  /**
   * @description It updates the password sending the driver object to the core-api
   */
  public onUpdate(): void {
    if (!this.validPasswords) {
      return;
    } else {
      const driver = this.data.driver;
      const updateBody: DriverUpdateBody = {
        apmaterno: driver.apmaterno,
        appaterno: driver.appaterno,
        email: driver.email,
        imss: driver.imss,
        licenciaId: driver.licenciaId,
        movil: driver.movil,
        nombre: driver.nombre,
        password: this.passwordForm.get(DIALOG_PASSWORD_CONSTANTS.KEY_PASSWORD).value,
        rfc: driver.rfc,
        usuario: this.data.user
      };

      this.driverProvider.updateDriver(driver.lineaTransporte, driver._id, updateBody)
        .then(() => {
          this.toast.successAlert(this.dialogPasswordLabels.tooltipSuccess);
          this.dialogRef.close(DIALOG_PASSWORD_CONSTANTS.CONFIRM);
        })
        .catch(error => {
          if (error.status !== DIALOG_PASSWORD_CONSTANTS.PRECONDITION_FAILED_ERROR) {
            this.toast.errorAlert(this.dialogPasswordLabels.tooltipErrorUpdate);
          } else {
            this.currentPassword = this.passwordForm.controls[DIALOG_PASSWORD_CONSTANTS.KEY_PASSWORD].value;
          }
        });
    }
  }

  /**
   * @description Changes the type of the input field for the new password.
   */
  public toggleNewPasswordVisibility(): void {
    this.newPasswordType = this.newPasswordType === DIALOG_PASSWORD_CONSTANTS.PASSWORD_TYPE ? 
      DIALOG_PASSWORD_CONSTANTS.TEXT_TYPE : DIALOG_PASSWORD_CONSTANTS.PASSWORD_TYPE;
  }

  /**
   * @description Changes the type of the input field for confirm the new password.
   */
  public toggleConfirmNewPasswordVisibility(): void {
    this.confirmNewPasswordType = this.confirmNewPasswordType === DIALOG_PASSWORD_CONSTANTS.PASSWORD_TYPE ? 
      DIALOG_PASSWORD_CONSTANTS.TEXT_TYPE : DIALOG_PASSWORD_CONSTANTS.PASSWORD_TYPE;
  }

  /**
   * @description Verifies if the input type for the new password is text.
   * @returns {boolean} - TRUE if the type of the input is text.
   */
  public isNewPasswordInputTextType(): boolean {
    return this.newPasswordType === DIALOG_PASSWORD_CONSTANTS.TEXT_TYPE;
  }

  /**
   * @description Verifies if the input type for the confirm new password is text.
   * @returns {boolean} - TRUE if the type of the input is text.
   */
  public isConfirmNewPasswordInputTextType(): boolean {
    return this.confirmNewPasswordType === DIALOG_PASSWORD_CONSTANTS.TEXT_TYPE;
  }

  /**
   * @description Validates if the passwordForm has valids fields to update the driver.
   * @returns {boolean} TRUE if the new password and confirm new password are valids.
   */
  public isValidToUpdate(): boolean {
    const hasNewPasswordErrors = this.passwordForm.controls[DIALOG_PASSWORD_CONSTANTS.KEY_PASSWORD].valid;
    const hasConfirmNewPasswordErrors = this.passwordForm.controls[DIALOG_PASSWORD_CONSTANTS.KEY_CONFIRM_PASSWORD].valid;

    return hasNewPasswordErrors && hasConfirmNewPasswordErrors;
  }

  /**
   * @description Validates if the new password is the same as the current password.
   * @returns {boolean} - TRUE if both passwords are the same.
   */
  public isNewPasswordSameAsCurrent(): boolean {
    const newPassword = this.passwordForm.controls[DIALOG_PASSWORD_CONSTANTS.KEY_PASSWORD].value;

    if (newPassword === this.currentPassword) {
      const errors = this.passwordForm.controls[DIALOG_PASSWORD_CONSTANTS.KEY_CONFIRM_PASSWORD].errors || {};
      errors[DIALOG_PASSWORD_CONSTANTS.KEY_ERROR_REPEATED_PASSWORD] = true;
      this.passwordForm.controls[DIALOG_PASSWORD_CONSTANTS.KEY_CONFIRM_PASSWORD].setErrors(errors);
    } else {
      const errors = this.passwordForm.controls[DIALOG_PASSWORD_CONSTANTS.KEY_CONFIRM_PASSWORD].errors;

      if (errors) {
        delete errors[DIALOG_PASSWORD_CONSTANTS.KEY_ERROR_REPEATED_PASSWORD];

        if (Object.keys(errors).length === 0) {
          this.passwordForm.controls[DIALOG_PASSWORD_CONSTANTS.KEY_CONFIRM_PASSWORD].setErrors(null);
        } else {
          this.passwordForm.controls[DIALOG_PASSWORD_CONSTANTS.KEY_CONFIRM_PASSWORD].setErrors(errors);
        }
      }
    }

    return newPassword === this.currentPassword;
  }
}
