import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { SupplierRequestParams, SupplierResponse } from '../../interfaces/supplier';

const apiUrl = environment.apiUrl;

@Injectable({ providedIn: 'root' })

/**
 * @description Service to make request for suppliers.
 */
export class SupplierService {
  /**
   * @description Builds an instance of all the services necessary for this component to work correctly.
   * @param {HttpClient} http - To make http request.
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * @description Creates supplier with provided body.
   * @param {SupplierRequestParams} params - Params to create supplier.
   * @returns {SupplierResponse} Supplier created and message.
   */
  public async createSupplier(params: SupplierRequestParams): Promise<SupplierResponse> {
    const url = `${apiUrl}/tenant/${params.shipperOId}/user/${params.userOId}/suppliers`;

    return await this.http.post<SupplierResponse>(url, params.supplierBody).toPromise();
  }

  /**
   * @description Deactivates supplier with provided params.
   * @param {SupplierRequestParams} params - Params to deactivate supplier.
   * @returns {SupplierResponse} Supplier deactivated and message.
   */
  public async deactivateSupplier(params: SupplierRequestParams): Promise<SupplierResponse> {
    const url = `${apiUrl}/tenant/${params.shipperOId}/user/${params.userOId}/suppliers?supplierId=${params.supplierIdentifier}`;

    return await this.http.delete<SupplierResponse>(url).toPromise();
  }

  /**
   * @description Gets active suppliers for provided shipper id.
   * @param {string} shipperOId - Shipper Id.
   * @param {string} userOId - User Id.
   * @param {boolean} isRetrievingOnlyActiveSuppliers - Flag to only retrieve active suppliers from shipper.
   * @returns {SupplierResponse} Suppliers found and message.
   */
  public async getShipperSuppliers(shipperOId: string, userOId: string,
    isRetrievingOnlyActiveSuppliers?: boolean): Promise<SupplierResponse> {
    return await this.http.get<SupplierResponse>(`${apiUrl}/tenant/${shipperOId}/user/${userOId}` +
      `/suppliers?isRetrievingOnlyActiveSuppliers=${isRetrievingOnlyActiveSuppliers}`).toPromise();
  }

  /**
   * @description Reactivates supplier with provided params.
   * @param {SupplierRequestParams} params - Params to reactivate supplier.
   * @returns {SupplierResponse} Supplier reactivated and message.
   */
  public async reactivateSupplier(params: SupplierRequestParams): Promise<SupplierResponse> {
    const url = `${apiUrl}/tenant/${params.shipperOId}/user/${params.userOId}/suppliers/reactivate`;

    return await this.http.put<SupplierResponse>(url, params.supplierBody).toPromise();
  }

  /**
   * @description Updates supplier with provided params.
   * @param {SupplierRequestParams} params - Params to update supplier.
   * @returns {SupplierResponse} Supplier updated and message.
   */
  public async updateSupplier(params: SupplierRequestParams): Promise<SupplierResponse> {
    const url = `${apiUrl}/tenant/${params.shipperOId}/user/${params.userOId}/suppliers?supplierId=${params.supplierOId}`;

    return await this.http.put<SupplierResponse>(url, params.supplierBody).toPromise();
  }
}
