<div class="general-drag-and-drop-container">
  <div
    id="drop-zone"
    class="custom-dropzone max-width-file"
    ngx-dropzone
    [accept]="allowedExtension"
    [multiple] ="multiple"
    (change)="addFilesByDrop($event)"
    (click)="openDisabled()"
    expandable="true">
    <ngx-dropzone-label>
      <div class="dropzone-label">
        <span>{{ 'dragAndDropLabels.dragAndDropLabelTitle' | translate }}</span><br>
        <span class="subtitle-label">{{ 'dragAndDropLabels.dragAndDropLabelSubtitle' | translate }}</span><br>
        <span class="subtitle-label">{{ label }}</span>
      </div>
    </ngx-dropzone-label>
    <ngx-dropzone-image-preview
      id="image-preview"
      class="dropzone-image"
      ngProjectAs="ngx-dropzone-preview"
      *ngFor="let f of filesInDropZone; let i = index"
      [file]="f.file"
      removable=true
      (removed)="removeFile(i)"
      (click)="onFileClick(f.file)">
      <ngx-dropzone-label *ngIf="f.file.type === 'application/pdf'">
        <div class="dropzone-label">
          <div class="label-pdf">
            {{f.file.name}}
          </div>
          <img
            id="default-pdf-image"
            src="../../../../assets/pdf_Icon.png"
            alt="PDF icon">
        </div>
      </ngx-dropzone-label>
    </ngx-dropzone-image-preview>
  </div>
</div>
