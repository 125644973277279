/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AUTHENTICATION_CONSTANTS } from '../../constants/authentication.constants';
import { AuthService } from '../../authentication/auth.service';
import { DialogAuthErrorComponent } from '../../components/dialog/dialog-auth-error/dialog-auth-error.component';
import { HTTP_CODE_ERROR } from '../../constants/http-codes-error';
import { ILanguageLabels } from '../../interfaces/labels/language-labels.interface';
import { ISupplynetLoginLabels } from '../../interfaces/labels/login-labels.interface';
import { LanguageChangeEventService } from '../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../constants/language.constants';
import { LanguageTranslateService } from '../../services/translate/language-translate.service';
import { LOCALSTORAGE_KEYS } from '../../constants/localstorage/localstorage.constants';
import { LocalStorageService } from '../../services/utils/local-storage.service';
import { RoadnetService } from 'src/app/services/roadnet/roadnet-service';
import { ROUTE_HOME } from '../../constants/routes.constants';
import { SsoAuthenticationService } from '../../services/authentication/sso-authentication.service';
import { Subscription } from 'rxjs';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';
import { UserTenant } from '../../interfaces/sso/user-tenant.interface';
import { UserTenantService } from '../../providers/sso/user/user-tenant.service';

const ONE_CONSTANT = 1;
const DIALOG_WIDTH = '680px';

/**
 * @class
 * @description - Supplynet Suite Login Component.
 */
@Component({
  selector: 'app-login',
  styleUrls: ['./supplynet-login.component.scss'],
  templateUrl: './supplynet-login.component.html',
})
export class SupplyNetLoginComponent implements OnInit {
  public isLoginFormVisible: boolean;
  public isSpinnerVisible: boolean;
  public isTenatFormVisible: boolean;
  public languageLabels: ILanguageLabels;
  public languageSuscription: Subscription;
  public loginForm: FormGroup;
  public supplynetLoginLabels: ISupplynetLoginLabels;
  public tenantForm: FormGroup;
  public formUserName: string;
  public userTenants: Array<UserTenant>;

  /**
   * @description - Creates an instance of SupplyNetLoginComponent class.
   * @param { AuthService } tepAuthService - Authentication service.
   * @param { FormBuilder } builder - Form Builder service.
   * @param { LanguageChangeEventService} languageChangeEventService - Tenant name from user to try login.
   * @param { LanguageTranslateService} languageTranslateService - Tenant name from user to try login.
   * @param { LocalStorageService } localStorageService - Local storage service.
   * @param { RoadnetService} roadnetService - Tenant name from user to try login.
   * @param { Router } router - Tenant name from user to try login.
   * @param { SsoAuthenticationService } ssoAuthService - Tenant name from user to try login.
   * @param { ToastrAlertsService } toastrService - Tenant name from user to try login.
   * @param { UserTenantService } userTenantService - User tenant service.
   */
  constructor(
    private readonly builder: FormBuilder,
    private _dialog: MatDialog,
    private tepAuthService: AuthService,
    private languageChangeEventService: LanguageChangeEventService,
    private languageTranslateService: LanguageTranslateService,
    private localStorageService: LocalStorageService,
    private roadnetService: RoadnetService,
    private router: Router,
    private ssoAuthService: SsoAuthenticationService,
    private toastrService: ToastrAlertsService,
    private userTenantService: UserTenantService
  ) {
    this.setLanguage();
  }

  /**
   * @description - Runs all necessary functions when the Login component is first loaded.
   *
   * @returns { Promise<void> }
   */
  public async ngOnInit(): Promise<void> {
    this.isLoginFormVisible = true;
    this.isSpinnerVisible = false;
    this.isTenatFormVisible = false;
    this.initForm(this.builder);
    this.subscribeLanguageChangeEvent();
    await this.getLabels();

    if (this.ssoAuthService.isAuthenticated()) {
      this.isSpinnerVisible = true;
      const username = this.localStorageService.getItem(
        AUTHENTICATION_CONSTANTS.LOCAL_STORAGE_USERNAME_KEY
      );
      const isTepAuthenticated = await this.tepAuthService.tepLogin(username);

      this.localStorageService.saveItem(
        AUTHENTICATION_CONSTANTS.IS_TEP_AUTHENTICATED,
        isTepAuthenticated
      );

      if (isTepAuthenticated) {
        await this.router.navigateByUrl(ROUTE_HOME);
      } else {
        await this.showAuthErrorAlert();
      }
    }
  }

  /**
   * @description - Reacts to the submit action on the login form,
   * creating a request to login into the system.
   *
   * @returns { Promise<void> }
   */
  public async loginSubmit(): Promise<void> {
    const username = this.loginForm.value.username;
    this.userTenants = await this.getTenantsValue(username);
    this.formUserName = username;

    if (this.userTenants.length > ONE_CONSTANT) {
      this.isLoginFormVisible = false;
      this.isTenatFormVisible = true;
    } else {
      this.userTenants[0].username = username;
      await this.login(this.userTenants[0]);
    }
  }

  /**
   * @description - Shows an authentication error alert before logout current user.
   *
   * @returns { Promise<void> }
   */
  public async showAuthErrorAlert(): Promise<void> {
    const alreadyNotified = this.localStorageService.getItem(
      LOCALSTORAGE_KEYS.SESSION_EXPIRED_TOKEN
    );

    if (alreadyNotified) {
      return;
    }

    const dialogRef = this._dialog.open(DialogAuthErrorComponent, {
      disableClose: true,
      width: DIALOG_WIDTH,
    });

    this.localStorageService.saveItem(
      LOCALSTORAGE_KEYS.SESSION_EXPIRED_TOKEN,
      true
    );
    await dialogRef
      .afterClosed()
      .pipe(
        map(async (result): Promise<void> => {
          if (result) {
            this.localStorageService.removeItem(LOCALSTORAGE_KEYS.SESSION_EXPIRED_TOKEN);
            await this.tepAuthService.teplogout(true);
          }
        })
      )
      .toPromise();
  }

  /**
   * @description - Reacts to the tenant submit action on the login form,
   * creating a request to login to the system.
   *
   * @returns { Promise<void> }
   */
  public async tenantsubmit(): Promise<void> {
    const tenant = this.tenantForm.value.tenant;
    if (tenant) {
      tenant.username = this.formUserName;
      await this.login(tenant);
    }
  }

  /**
   * @description - Authenticates the user.
   * @param { UserTenant } userTenant - The user to login.
   *
   * @returns { Promise<void> }
   */
  public async login(userTenant: UserTenant): Promise<void> {
    try {
      this.ssoAuthService.setRealmInLocalStorage(userTenant.tenantName);
      this.localStorageService.saveItem(
        AUTHENTICATION_CONSTANTS.LOCAL_STORAGE_USERNAME_KEY,
        userTenant.username
      );
      await this.ssoAuthService.userLogin(userTenant.username);
      this.setExternalInterfacesConfig();
    } catch (error) {
      await this.ssoAuthService.userLogout();

      throw error;
    }
  }

  /**
   * @description - Gets the necessary tags from the JSON files to use throughout the component.
   *
   * @returns { Promise<void> }
   */
  private async getLabels(): Promise<void> {
    await this.getLanguageTags();
    await this.getLoginLabels();
  }

  /**
   * @description - Gets language labels from JSON files.
   *
   * @returns { Promise<void> }
   */
  private async getLanguageTags(): Promise<void> {
    this.languageLabels = await this.languageTranslateService
      .getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
      .catch((): void => {
        this.toastrService.errorAlert(this.languageLabels.errorGettingLabels);
      });
  }

  /**
   * @description - Gets login labels from JSON files.
   *
   * @returns { Promise<void> }
   */
  private async getLoginLabels(): Promise<void> {
    this.supplynetLoginLabels = await this.languageTranslateService
      .getLanguageLabels(LanguageConstants.SUPPLYNET_LOGIN_LABELS)
      .catch(() => {
        this.toastrService.errorAlert(this.languageLabels.errorGettingLabels);
      });
  }

  /**
   * @description - Gets all users and their respective tenant value based on the username entered.
   * @param { string } username - The username passed in the form.
   *
   * @returns { Promise<Array<UserTenant>> } - Returns all tenant values if the user exists,
   * otherwise returns an error.
   */
  private async getTenantsValue(username: string): Promise<Array<UserTenant>> {
    try {
      const userTenantsArray = await this.userTenantService.getTenantNameByUsername(
        username
      );

      return userTenantsArray.item;
    } catch (error) {
      if (error.status === HTTP_CODE_ERROR.NOT_FOUND) {
        this.toastrService.warningAlert(
          this.supplynetLoginLabels.loginNotFoundUser
        );
      } else {
        this.toastrService.errorAlert(
          this.supplynetLoginLabels.loginServerError
        );
      }

      throw error;
    }
  }

  /**
   * @description - Initializes the login and tenant forms.
   * @param { FormBuilder } formBuilder - The FormBuilder used.
   *
   * @returns { void }
   */
  private initForm(formBuilder: FormBuilder): void {
    this.loginForm = formBuilder.group({
      username: new FormControl(null, [Validators.required]),
    });
    this.tenantForm = formBuilder.group({
      tenant: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * @description - Cancels tenant login form.
   *
   * @returns { void }
   */
  public cancelTenatLogin(): void {
    this.isLoginFormVisible = true;
    this.isTenatFormVisible = false;
  }

  /**
   * @description - Changes language configuration in the application.
   * @param { string } languageKey - Optional key string to set language, default is spanish 'es'.
   *
   * @returns { void }
   */
  private setLanguage(languageKey?: string): void {
    this.languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description - Reacts to the created event when the language is changed by the SCF component,
   * setting the configuration in the application.
   *
   * @returns { void }
   */
  private subscribeLanguageChangeEvent(): void {
    this.languageSuscription =
      this.languageChangeEventService._languageEmitter.subscribe(
        async (key: string) => {
          this.setLanguage(key);
          await this.getLabels();
        },
        () => {
          this.toastrService.errorAlert(
            this.languageLabels.errorChangingLanguage
          );
        }
      );
  }

  /**
   * @description Checks wether user interfaces configuration.
   */
  private setExternalInterfacesConfig() {
    this.roadnetService.handleRoadnetIntegration();
  }
}
