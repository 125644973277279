<mat-grid-list cols="3" rowHeight="2rem">
  <mat-grid-tile colspan="3">
    <span class="dialog-title">
      <p> {{ labels.associateShipmentTitle }} {{ shipmentRequestData.identifier ? shipmentRequestData.identifier : labels.withoutData }} </p>
    </span>
  </mat-grid-tile>
</mat-grid-list>
<mat-card class="cardConfirmation">
  <mat-card-content class="mat-container">
    <mat-grid-list cols="1" rowHeight="37px">
      <mat-grid-tile colspan="1">
        <mat-icon class="icon-origin">
          <img class="img-origin" src="{{ routeIcon }}" alt="route icon"/>
        </mat-icon>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="2" rowHeight="7rem">
      <mat-grid-tile colspan="1">
        <div class="mat-grid-info-left">
          <div class="paragraph font-13">
            <div class="dot-description">
              &bull;
            </div>
            <div class="description">
              {{ labels.originTitle }}<span class="element-decription">{{ shipmentRequestData.origin.name ? shipmentRequestData.origin.name : labels.withoutData }}</span>
            </div>
          </div>
          <div class="paragraph font-13">
            <div class="dot-description">
              &bull; 
            </div>
            <div class="description">
              {{ labels.loadDate }} <span class="element-decription">{{ originDate ? originDate : labels.withoutData }}</span>
            </div>
          </div>
          <div class="paragraph font-13">
            <div class="dot-description">
              &bull; 
            </div>
            <div class="description">
              {{ labels.loadTime }} <span class="element-decription">{{ originTime ? originTime : labels.withoutData }}</span>
            </div>
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <div class="mat-grid-info-right">
          <div class="paragraph font-13">
            <div class="dot-description">
              &bull; 
            </div>
            <div class="description">
              {{ labels.destination }} <span class="element-decription">{{ shipmentRequestData.destinations[destinationsNumber - 1].name }}</span>
            </div>
          </div>
          <div class="paragraph font-13">
            <div class="dot-description">
              &bull; 
            </div>
            <div class="description">
              {{ labels.stopsNumber }} <span class="element-decription">{{ destinationsNumber }}</span>
            </div>
          </div>
          <div class="paragraph font-13">
            <div>
              &nbsp;
            </div>
            <div class="description">
            </div>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="1" rowHeight="5px">
      <mat-grid-tile colspan="1"><div></div></mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="6" rowHeight="2rem">
      <mat-grid-tile colspan="6">
        <div class="container-subtitle">
          <div>
            <h2 class="subtitle"> {{ labels.selectShipmentSection }}</h2>
          </div>
          <hr class="hr-subtitle">
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="5" rowHeight="4rem">
      <div class="table-container" *ngIf="filteredShipments.length > 0">
        <table
          mat-table
          aria-describedby="associate a request with a shipment"
          [dataSource]="dataSource">
          <ng-container matColumnDef="_id">
            <th
              id="selectShipmentCol" 
              mat-header-cell
              *matHeaderCellDef class="text-column">
            </th>
            <td
              mat-cell 
              *matCellDef="let shipment">
              <mat-radio-button value="shipment" (change)="selectShipment(shipment)"></mat-radio-button>
            </td>
          </ng-container>
          <ng-container matColumnDef="shipmentId">
            <th
              id="shipmentIdCol"
              mat-header-cell
              *matHeaderCellDef class="text-column">
                {{ labels.shipmentId }}
            </th>
            <td
              mat-cell
              *matCellDef="let shipment">
                {{ shipment.shipmentId }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th
              id="shipmentStatusCol"
              mat-header-cell
              *matHeaderCellDef class="text-column">
              {{ labels.status }}
            </th>
            <td
              mat-cell
              *matCellDef="let shipment">
              <div class="status-class">
              {{ shipment.status }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="origin">
            <th
              id="shipmentOriginCol"
              mat-header-cell
              *matHeaderCellDef
              class="text-column">
              {{ labels.origin }}
            </th>
            <td 
            mat-cell
            *matCellDef="let shipment">
              {{ shipment.origin.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="orders">
            <th
              id="shipmentStopsNumberCol"
              mat-header-cell
              *matHeaderCellDef
              class="text-column">
              {{ labels.stopsNumber }}
            </th>
            <td
              mat-cell
              *matCellDef="let shipment">
                {{ shipment.orders.length }}
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns">
          </tr>
          <tr
            mat-row
            *matRowDef="let row;
            columns: displayedColumns;">
          </tr>
        </table>
      </div>
      <div *ngIf="displayNoShipments">
        <p class="no-shipments-found">
          {{ labels.noCoincidences }}
        </p>
      </div>
      <div
        [hidden]="itemsCount <= 0 || hidePaginator">
        <mat-paginator
          [pageSizeOptions]="[10, 25, 50]"
          [pageSize]="10"
          showFirstLastButtons
          [length]="itemsCount">
        </mat-paginator>
      </div>
      <button *ngIf="filteredShipments.length === 0"
        id="cancelButton"
        mat-stroked-button
        (click)="onClose()"
        color="primary"
        class="secondary-button-cancel-no-results">
        {{ labels.cancel }}
      </button>
      <button *ngIf="filteredShipments.length === 0"
        [disabled]="!shipmentSelected"
        (click)="confirmSelection()"
        id="acceptButton"
        mat-raised-button
        class="primary-button-accept">
        {{ labels.accept }}
      </button>
      <button *ngIf="filteredShipments.length > 0"
        id="cancelButtonNoResults"
        mat-stroked-button
        (click)="onClose()"
        color="primary"
        class="secondary-button-cancel">
        {{ labels.cancel }}
      </button>
      <button *ngIf="filteredShipments.length > 0"
        [disabled]="!shipmentSelected"
        (click)="confirmSelection()"
        id="acceptButtonNoResults"
        mat-raised-button
        class="primary-button-accept">
        {{ labels.accept }}
      </button>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
