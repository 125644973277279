import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { IncidenceReasons, IncidenceRequest, IncidenceResponse } from '../../interfaces/incidences';

const apiUrl = environment.apiUrl;

@Injectable()
export class IncidenceProvider {

  constructor(
    private _appService: AppService,
    private http: HttpClient
    ) { }

  /**
   * @description Get all incidence reasons registered by a shipper
   * @param {string} shipperId - Optional Param to get related incidence reasons
   * @returns {Promise<IncidenceReasons} Shipper found incidence reasons
   */
  public async getIncidenceReasons(shipperId?: string): Promise<IncidenceReasons> {
    const shipperOid = shipperId ? shipperId : this._appService.getShipperOid();
    return await this.http.get<IncidenceReasons>(apiUrl + '/embarcadores/' + shipperOid + '/motivosIncidencia').toPromise();
  }

  /**
   * @description Create an incidence reason
   * @param {IncidenceRequest} incidence - Array of IncidenceReasonsDetails interface
   * @param {string} shipperId - Optional Param to link the incidence  with the shipper
   * @returns {Promise<IncidenceReasons} Incidence reason saved
   */
  public async saveIncidenceReasons(incidence: IncidenceRequest, shipperId?: string): Promise<IncidenceResponse> {
    const shipperOid = shipperId ? shipperId : this._appService.getShipperOid();
    return await this.http.post<IncidenceResponse>(apiUrl +  '/embarcadores/' + shipperOid + '/motivosIncidencia', incidence)
    .toPromise();
  }

  /**
   * @description Update an incidence reason
   * @param {IncidenceRequest} incidence - Array of IncidenceReasonsDetails interface
   * @param {string} shipperId - Optional Param to link the incidence  with the shipper
   * @returns {Promise<IncidenceReasons} Incidence reason updated
   */
  public async updateIncideReasons(incidence: IncidenceRequest, shipperId?: string): Promise<IncidenceResponse> {
    const shipperOid = shipperId ? shipperId : this._appService.getShipperOid();
    const payload = incidence.motivosIncidencia[0];
    return await this.http.put<IncidenceResponse>(apiUrl +  '/embarcadores/' + shipperOid +
                                                  '/motivosIncidencia/' + payload._id, {reason: payload})
    .toPromise();
  }

  /**
   * @description Delete an incidence reason
   * @param {string} incidenceId - Id of incidence
   * @param {string} shipperId - Optional Param to link the incidence  with the shipper
   * @returns {Promise<IncidenceReasons} Incidence reason deleted
   */
   public async deleteIncidenceReasons(incidenceId: string, shipperId?: string): Promise<IncidenceRequest> {
    const shipperOid = shipperId ? shipperId : this._appService.getShipperOid();
    return await this.http.delete<IncidenceRequest>(apiUrl +  '/embarcadores/' + shipperOid + '/motivosIncidencia/' + incidenceId )
    .toPromise();
  }
}
