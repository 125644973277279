import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const apiUrl = environment.apiUrl;

@Injectable()
export class LoadTypeProvider {

  constructor(private http: HttpClient) { }

  public async getLoadTypeByShipperId (shipperOid: string): Promise <Array<object>> {
    return await this.http.get<Array<object>>(apiUrl + '/tiposCarga?embarcador=' + shipperOid).toPromise();
  }
}
