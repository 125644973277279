import { CATALOG_CONFIG_CONSTANTS } from '../../../constants/catalogs.constants';

export const CUSTOMER_INVOICE_DETAIL = {
  CANCEL: 'Cancel',
  CLOSED: 'Closed',
  CONCEPTS: {
    boxes: 'boxes',
    freightValue: 'freightValue',
    pallets: 'pallets',
    pieces: 'pieces',
    shipmentType: 'shipmentType',
    vehicleType: 'vehicleType',
    volume: 'volume',
    weight: 'weight'
  },
  DISPLAYEDCOLUMNS: {
    additCharges: 'additionalCharges',
    folio: 'folio',
    freight: 'freight',
    identifier: 'identifier',
    shipmentFolio: 'shipmentFolio',
    total: 'total'
  },
  PRIORITY_CONCEPT: 1,
  SCF_GRID_CONFIG: {
    columnToggler: false,
    pageSize: CATALOG_CONFIG_CONSTANTS.DEFAULT_PAGE_SIZE,
    pageSizeOptions: CATALOG_CONFIG_CONSTANTS.PAGE_SIZE_OPTIONS,
    rowDraggable: false,
    selectable: false
  },
};
