export enum VehicleImportationFields {
  Axles = 'Axles',
  Carrier = 'Carrier',
  Color = 'Color',
  Driver = 'Driver',
  Km = 'Kms',
  MaxPallets = 'MaxPallets',
  MaxVolume = 'MaxVolume',
  MaxWeight = 'MaxWeight',
  Model = 'Model',
  Plates = 'Plates',
  VehicleSpecs = 'VehicleSpecs',
  VehicleType = 'VehicleType',
  Year = 'Year'
}
