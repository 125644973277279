export const CATALOG_CONFIG_CONSTANTS = {
  DEFAULT_PAGE_SIZE: 25,
  PAGE_SIZE_OPTIONS: [25, 50, 100]
};

export const CATALOG_ACTIONS_CONSTANTS = {
  ACCEPT: 'accept',
  DELETE: 'delete',
  EDIT: 'edit',
  REJECT: 'reject'
};

export const CATALOG_ICON_CONSTANTS = {
  INFO_ICON_CLASS: 'info',
  SUCCESS_ICON_CLASS: 'success',
  WARNING_ICON_CLASS: 'warning'
};

export const ANGULAR_ICONS = {
  CANCEL: 'cancel',
  CHECK_BOX: 'check_box',
  CHECK_CIRCLE: 'check_circle',
  CHECK_ICON: 'check',
  CLOSE_ICON: 'close',
  FIBER_MANUAL_RECORD: 'fiber_manual_record',
  INDETERMINATE_CHECK_BOX: 'indeterminate_check_box'
};
