import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-stepper-dynamic',
  templateUrl: './stepper-dynamic.component.html',
  styleUrls: ['./stepper-dynamic.component.scss']
})
export class StepperDynamicComponent implements OnInit {
  @Input() actualStatus: string;
  @Input() stepStatus: Array<string>;
  public actualStep: number;
  public stepsDone: Array<string>;
  public stepsPending: Array<string>;

  constructor() {}

  ngOnInit() {
    this.stepsValidator();
  }

  public stepsValidator(): void {
    this.actualStep = this.stepStatus.findIndex(status => status === this.actualStatus);
    this.stepsDone = this.stepStatus.slice(0, this.actualStep);
    this.stepsPending = this.stepStatus.slice(this.actualStep);
  }

}
