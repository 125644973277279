<mat-horizontal-stepper
  labelPosition="bottom"
  #stepper
  [selectedIndex]=actualStep>
  <mat-step
    *ngFor="let stepDone of stepsDone; let i = index"
    completed="true"
    editable="false">
    <ng-template matStepLabel><strong>{{stepDone}}</strong></ng-template>
  </mat-step>
  <mat-step *ngFor="let stepPending of stepsPending; let j = index">
    <ng-template matStepLabel><strong>{{stepPending}}</strong></ng-template>
  </mat-step>
</mat-horizontal-stepper>