export enum ShipmentStatus {
  assigned = 'Asignado',
  confirmed = 'Confirmado',
  costed = 'Costeado',
  deleted = 'Eliminado',
  delivered = 'Entregado',
  evidenced = 'Evidenciado',
  inBillingSheet = 'En Sabana',
  inTransit = 'En Transito',
  planned = 'Planeado',
  released = 'Liberado'
}
