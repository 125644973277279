<mat-grid-list cols="3" rowHeight="1:.15">
  <mat-grid-tile colspan="2" rowspan="1">
    <span class="dialog-title">
      <p>{{labels.dialogProcessStatus}}</p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1" rowspan="1">
    <mat-icon
      style="cursor: pointer"
      class="dialog-close-icon">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>
<mat-progress-bar mode="indeterminate" class="importation-progress-bar"></mat-progress-bar>
