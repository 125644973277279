export enum Profiles {
  Administrator = 'Administrador',
  Carrier = 'Transportista Biis',
  Client = 'Cliente',
  Shipper = 'Embarcador Biis',
  SysAdmin = 'Sysadmin'
}

export enum AllowedProfiles {
  Carrier = 'Transportista',
  Client = 'Cliente',
  Shipper = 'Embarcador'
}

export enum BusinessType {
  Client_B2B = 'B2B',
  Client_B2C = 'B2C'
}

export enum AvailabilityStatus {
  AVAILABLE = 'Disponible',
}
