import { Injectable } from '@angular/core';

import { AppConstants } from '../../app/constants/app-constants.constants';

const DIGITS_TO_SUBSTRACT = -2;
const TWO_MAX_DIGITS = 2;

@Injectable()

/**
 * @description Service to process and build a date provided to custom output using library native of Date.
*/
export class DateFormatService {
  public date: number | string;
  public day: number| string;
  public hours: number| string;
  public minutes: number| string;
  public month: number| string;
  public seconds: number| string;

  constructor() { }

  /**
   * @description Receives a date to transform it to string.
   * @param {Date} dateToProcess - Date you want to process and transform.
   * @param {string} separator - Separator will be put it between day, month and year values.
   * @returns {string} Date provided but in string as format MMDDYYYY.
   */
  public buildDateInMMDDYYYYFormat(dateToProcess: Date, separator: string): string {
    this.date = dateToProcess.getDate();
    this.month = dateToProcess.getMonth() + 1;
    const year = dateToProcess.getFullYear();
    this.date = this.date.toString().padStart(TWO_MAX_DIGITS, AppConstants.ZERO_STRING);
    this.month = this.month.toString().padStart(TWO_MAX_DIGITS, AppConstants.ZERO_STRING);

    return `${this.month}${separator}${this.date}${separator}${String(year).slice(DIGITS_TO_SUBSTRACT)}`;
  }

  /**
   * @description Generates a timestamp with just date from current date in format "DDMMYYYY".
   * @param {Date} date - Date to process.
   * @param {string} separator - Separator to display in timestamp (if is provided).
   * @returns {string} Timestamp from provided date as string.
   */
  public buildJustDateTimestamp(date: Date, separator?: string): string {
    this.date = date.getDate();
    this.month = date.getMonth() + 1;
    const year = date.getFullYear();
    this.date = this.date.toString().padStart(TWO_MAX_DIGITS, AppConstants.ZERO_STRING);
    this.month = this.month.toString().padStart(TWO_MAX_DIGITS, AppConstants.ZERO_STRING);

    return separator ? `${this.date}${separator}${this.month}${separator}${String(year)}` :
      `${this.date}${this.month}${String(year)}`;
  }

  /**
   * @description Generates a timestamp with just time from current date in format "HHMMSS".
   * @param {Date} date - Date to process.
   * @param {string} separator - Separator to display in timestamp (if is provided).
   * @returns {string} Timestamp from provided date as string.
   */
  public buildJustTimeTimestamp(date: Date, separator?: string): string {
    this.minutes = date.getMinutes() + 1;
    this.hours = date.getHours() + 1;
    this.seconds = date.getSeconds() + 1;

    this.minutes = this.minutes.toString().padStart(TWO_MAX_DIGITS, AppConstants.ZERO_STRING);
    this.hours = this.hours.toString().padStart(TWO_MAX_DIGITS, AppConstants.ZERO_STRING);
    this.seconds = this.seconds.toString().padStart(TWO_MAX_DIGITS, AppConstants.ZERO_STRING);

    return separator ? `${this.hours}${separator}${this.minutes}${separator}${this.seconds}` :
      `${this.hours}${this.minutes}${this.seconds}`;
  }
}
