import { Injectable } from '@angular/core';

import {
  VALID_COFEPRIS_SECTORS_FOR_ACTIVE_INGREDIENT_MANUFACTURER_COUNTRY_NAME_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_ACTIVE_INGREDIENT_MAQUILA_COUNTRY_NAME_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_ACTIVE_INGREDIENT_NAME_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_ACTIVE_SUBSTANCE_GENERIC_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_AUTHORIZED_USE_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_CHEMICAL_NAME_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_COFEPRIS_PESTICIDE_NUMBER_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_EXPIRATION_DATE_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_HEALTH_REGISTER_FOLIO_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_IMPORT_COMPANY_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_IMPORT_LICENSE_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_IMPORT_VUCEM_FOLIO_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_LOT_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_MANUFACTURER_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_NUM_CAS_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_PESTICIDE_MANUFACTURER_COUNTRY_NAME_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_PHARMACEUTICAL_FORM_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_TRADEMARK_MEDICATION_FIELD,
  VALID_COFEPRIS_SECTORS_FOR_TRANSPORT_SPECIAL_CONDITIONS_FIELD
} from '../../enums/cofepris-common-data';

/**
 * Service to implement methods common used in operations related with orders.
 */
@Injectable()
export class OrderService {
  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'active ingredient manufacturer country name' property
   * for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForActiveIngredientManufacturerCountryNameProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_ACTIVE_INGREDIENT_MANUFACTURER_COUNTRY_NAME_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'active ingredient maquila country name' property
   * for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForActiveIngredientMaquilaCountryNameProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_ACTIVE_INGREDIENT_MAQUILA_COUNTRY_NAME_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'active ingredient' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForActiveIngredientProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_ACTIVE_INGREDIENT_NAME_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'active substance generic' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForActiveSubstanceGenericProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_ACTIVE_SUBSTANCE_GENERIC_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'authorized use' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForAuthorizedUseProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_AUTHORIZED_USE_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'chemical Name' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForChemicalNameProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_CHEMICAL_NAME_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'expiration date' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForCofeprisExpirationDateProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_EXPIRATION_DATE_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'lot' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForCofeprisLotProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_LOT_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'pesticide number' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForCofeprisPesticideNumberProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_COFEPRIS_PESTICIDE_NUMBER_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'health register folio' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForHealthRegisterFolioProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_HEALTH_REGISTER_FOLIO_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'import company' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForImportCompanyProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_IMPORT_COMPANY_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'import license' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForImportLicenseProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_IMPORT_LICENSE_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'import VUCEM folio' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForImportVUCEMFolioProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_IMPORT_VUCEM_FOLIO_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'manufacturer' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForManufacturerProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_MANUFACTURER_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'NumCAS' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForNumCASProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_NUM_CAS_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'pesticide manufacturer country name' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForPesticideManufacturerCountryNameProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_PESTICIDE_MANUFACTURER_COUNTRY_NAME_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'pharmaceutical form' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForPharmaceuticalFormProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_PHARMACEUTICAL_FORM_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'trademark medication' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForTrademarkMedicationProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_TRADEMARK_MEDICATION_FIELD.includes(sectorCode);
  }

  /**
   * @description Checks if valid sector COFEPRIS provided should save info for 'transport special conditions' property for products.
   * @param {string} sectorCode - Code from COFEPRIS sector to check if should save this info.
   * @returns {boolean} True in code sector provided is included in sector codes to save this info. Otherwise false.
   */
  public isValidSectorForTransportSpecialConditionsProperty(sectorCode: string): boolean {
    return VALID_COFEPRIS_SECTORS_FOR_TRANSPORT_SPECIAL_CONDITIONS_FIELD.includes(sectorCode);
  }
}
