import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IImportationMapping, IImportationMappingRule, ScfGridAction, ScfGridColumn } from 'scf-library';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Subscription } from 'rxjs';

import { ACTIONS } from '../../../interfaces/scfgrid';
import { AppConstants } from '../../../constants/app-constants.constants';
import { DATEFORMAT_CONSTANTS } from '../../../constants/dateformat.constants';
import { DialogOrderBulkLoadConfigProperties } from './dialog-order-bulk-load-config.properties';
import { GENERAL_INFO_FORM } from '../../../pages/orders/order-bulk-load/order-bulk-load-forms/general-info-form';
import { ImportationService } from '../../../providers/importation/importation.services';
import { ImportationTemplate } from '../../../interfaces/order-bulk-load';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { OrderBulkLoadConfig } from '../../../interfaces/order-bulk-load-config/order-bulk-load-config';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

@Component({
  selector: 'app-dialog-order-bulk-load-config',
  styleUrls: ['./dialog-order-bulk-load-config.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-order-bulk-load-config.component.html'
})

/**
 * Class representation for DialogOrderBulkLoadConfigComponent.
 */
export class DialogOrderBulkLoadConfigComponent implements OnDestroy, OnInit {
  public actions: Array<ScfGridAction>;
  public columns: Array<ScfGridColumn>;
  public configs: Array<OrderBulkLoadConfig>;
  public configSelected: OrderBulkLoadConfig;
  public dialogOBLProperties: typeof DialogOrderBulkLoadConfigProperties;
  public dialogOBLLabelsTranslated: any;
  public isDeleteProccessRunning: boolean;
  public isGridReady: boolean;
  public languageLabels: any; 
  public languageSuscription: Subscription;
  public pageSize: number;
  public pageSizeOptions: Array<number>;

  @ViewChild('configSelector', {static: true})
  public configSelector: TemplateRef<any>;

  /**
   * @description Create Dialog Order Bulk Load Config Component.
   * @param {Array<ImportationTemplate>} data - Import template/config data for orders bulk load.
   * @param {LanguageChangeEventService} _languageChangeEventService - Language Change Event Service instance.
   * @param {LanguageTranslateService} _languageTranslateService - Language Translate Service instance.
   * @param {MatDialogRef<DialogOrderBulkLoadConfigComponent>} dialogRef - MatDialogRef instance.
   * @param {ImportationService} importService - Importation Sevicre instance.
   * @param {ToastrAlertsService} toastService - Toast Service instance.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Array<ImportationTemplate>,
    public _languageChangeEventService: LanguageChangeEventService,
    public _languageTranslateService: LanguageTranslateService,
    public dialogRef: MatDialogRef<DialogOrderBulkLoadConfigComponent>,
    public importService: ImportationService,
    public toastService: ToastrAlertsService
  ) {
    this.setLanguage();
    this.dialogOBLProperties = DialogOrderBulkLoadConfigProperties;
    this.configs = [];
    this.pageSize = this.dialogOBLProperties.gridPageSize;
    this.pageSizeOptions = AppConstants.PAGE_SIZE_OPTIONS;
    this.isGridReady = false;
    this.isDeleteProccessRunning = false;
  }

  /**
   * @description  Angular lifecycle hook for component init.
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    await this.getDialogOrderBulkLoadLabels();
    await this.getLanguageLabels();
    this.initGrid();
  }

  /**
   * @description Get Dialog Order bulk load translated labels for the selected language.
   */
  public async getDialogOrderBulkLoadLabels(): Promise<void> {
    try {
      this.dialogOBLLabelsTranslated = await this._languageTranslateService
        .getLanguageLabels(LanguageConstants.DIALOG_ORDER_BULK_LOAD_CONFIG);
    } catch (error) {
      this.toastService.errorAlert(this.languageLabels.errorGettingLabels);
    }
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   */
  public async getLanguageLabels(): Promise<void> {
    this.languageLabels = await this._languageTranslateService
      .getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
      .catch(error => {
        this.toastService.errorAlert(this.languageLabels.errorGettingLabels);
      });
  }

  /**
   * @description Handle grid action to execute the correct method.
   * @param {OrderBulkLoadConfig} row - Config selected.
   * @param {string} action - Action identifier.
   */
  public gridActionHandler(row: OrderBulkLoadConfig, action: string): void {
    switch (action) {
    case ACTIONS.DELETE:
      this.onDeleteConfig(row.name);
      break;
    default: break;
    }
  }

  /**
   * @description Init menu actions.
   */
  public initActions(): void {
    this.actions = [
      {
        action: ACTIONS.DELETE,
        disableFunction: (row?: OrderBulkLoadConfig) => {
          if (row) {
            return this.isDeleteProccessRunning;
          }
        },
        label: this.dialogOBLLabelsTranslated.deleteTemplateLabel
      }
    ];
  }

  /**
   * @description Initialize default grid columns.
   */
  public initColumns(): void {
    this.columns = [
      { field: this.dialogOBLProperties.configGridColumns.configSelector, header: AppConstants.EMPTY_STRING, columnTemplate: this.configSelector },
      { field: this.dialogOBLProperties.configGridColumns.name, header: this.dialogOBLLabelsTranslated.nameLabel },
      {
        field: this.dialogOBLProperties.configGridColumns.createdDate,
        header: this.dialogOBLLabelsTranslated.creationDateLabel,
        isDate: true,
        datePipeFormat: DATEFORMAT_CONSTANTS.ANGULAR_12_DATE_FORMAT
      },
      { field: this.dialogOBLProperties.configGridColumns.account, header: this.dialogOBLLabelsTranslated.accountLabel },
      { field: this.dialogOBLProperties.configGridColumns.orderType, header: this.dialogOBLLabelsTranslated.orderTypeLabel },
      { field: this.dialogOBLProperties.configGridColumns.invoice, header: this.dialogOBLLabelsTranslated.invoiceLabel },
      { field: this.dialogOBLProperties.configGridColumns.identifier, header: this.dialogOBLLabelsTranslated.identifierLabel },
      { field: this.dialogOBLProperties.configGridColumns.serviceType, header: this.dialogOBLLabelsTranslated.serviceTypeLabel },
      { field: this.dialogOBLProperties.configGridColumns.clientPickup, header: this.dialogOBLLabelsTranslated.costumerPickupLabel },
      { field: this.dialogOBLProperties.configGridColumns.warehouse, header: this.dialogOBLLabelsTranslated.warehouseLabel }
    ];
  }

  /**
   * @description Init SCF Grid data.
   */
  public initData(): void {
    this.configs = [];

    for (const config of this.data) {
      const newConfig: OrderBulkLoadConfig = {};
      newConfig.name = config.name;
      newConfig.hasHeader = config.hasHeader;
      newConfig.configSelector = null;
      newConfig.createdDate = config.createdDate;

      const category = config.mapping.find((mapping: IImportationMapping) => {
        return mapping.category === GENERAL_INFO_FORM.name;
      });
      newConfig.account = category?.rules.find((rule: IImportationMappingRule) => {
        return rule.field === this.dialogOBLProperties.configGridColumns.account;
      })?.column;
      newConfig.orderType = category?.rules.find((rule: IImportationMappingRule) => {
        return rule.field === this.dialogOBLProperties.configGridColumns.orderType;
      })?.column;
      newConfig.invoice = category?.rules.find((rule: IImportationMappingRule) => {
        return rule.field === this.dialogOBLProperties.configGridColumns.invoice;
      })?.column;
      newConfig.identifier = category?.rules.find((rule: IImportationMappingRule) => {
        return rule.field === this.dialogOBLProperties.configGridColumns.identifier;
      })?.column;
      newConfig.serviceType = category?.rules.find((rule: IImportationMappingRule) => {
        return rule.field === this.dialogOBLProperties.configGridColumns.serviceType;
      })?.column;
      newConfig.clientPickup = category?.rules.find((rule: IImportationMappingRule) => {
        return rule.field === this.dialogOBLProperties.configGridColumns.clientPickup;
      })?.column;
      newConfig.warehouse = category?.rules.find((rule: IImportationMappingRule) => {
        return rule.field === this.dialogOBLProperties.configGridColumns.warehouse;
      })?.column;

      this.configs.push(newConfig);
    }
  }

  /**
   * @description Init grid by SCF Grid implementation.
   */
  public initGrid(): void {
    this.initData();
    this.initActions();
    this.initColumns();
    this.isGridReady = true;
  }

  /**
   * @description Emit cancel config selection event to parent component.
   */
  public onCancelSelection(): void {
    this.dialogRef.close(null);
  }

  /**
   * @description Emit config selection event to parent component.
   */
  public onConfigSelection(): void {
    const configSelected = this.data.find((config: ImportationTemplate) => {
      return config.name === this.configSelected.name;
    });
    this.dialogRef.close(configSelected);
  }

  /**
   * @description Listen to any config selection change.
   * @param {MatRadioChange} event - The event trigered when the function is called.
   */
  public onConfigSelectionChange(event: MatRadioChange): void {
    this.configSelected = event.value;
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey - Optional language key string, default is spanish 'es'.
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Listen to the event fired when the language is changed by the SCF.
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      (languageKey: string) => {
        this.setLanguage(languageKey);
      },
      (error) => {
        this.toastService.errorAlert(this.languageLabels.errorChangingLanguage);
      }
    );
  }

  /**
   * @description  Angular lifecycle hook for component destroy.
   */
  public ngOnDestroy(): void {
    this.languageSuscription.unsubscribe();
  }

  /**
   * @description Runs delete process of the provided template.
   * @param {string} templateName - Provided template name.
   */
  private async onDeleteConfig(templateName: string): Promise<void> {
    try {
      this.isDeleteProccessRunning = true;
      const foundTemplateOid = this.data.find((template: ImportationTemplate) => {
        return template.name === templateName;
      })._id;
      await this.importService.removeImportationTemplate(foundTemplateOid);
      this.data.forEach((config: ImportationTemplate, index: number) => {
        if (config.name === templateName) this.data.splice(index, 1);
      });
      this.initData();
      this.configSelected = null;
      this.isDeleteProccessRunning = false;
    } catch (error) {
      this.toastService.errorAlert(this.dialogOBLLabelsTranslated.deleteErrorMessage);
    }
  }
}
