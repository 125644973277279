<mat-grid-list cols="2" rowHeight="1:.05">
  <mat-grid-tile>
    <span class="dialog-title">
      <p>{{ labels.dialogTitle }}</p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile >
    <mat-icon
      style="cursor: pointer"
      class="dialog-close-icon"
      (click)="onClickClose()">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>
<br>
<mat-grid-list cols="2" rowHeight="1:.3">
  <form [formGroup]="editFreightCostFormGroup">
    <mat-grid-tile>
      <mat-form-field>
        <input
          matInput
          type="number"
          min="0.01"
          step=".10"
          [placeholder]=labels.ratePlaceholder
          (change)="edited()"
          pattern="^[0-9]+([.][0-9]+)?$"
          formControlName="rate"
          required>
        <mat-error>
          {{ labels.rateIs }} <strong>{{ labels.required }}{{ labels.suffixA }}</strong>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile >
      <button
        mat-raised-button
        class="primary-button"
        [disabled]="!editFreightCostFormGroup.valid  || !costEdited"
        (click)="onUpdate()">
        {{ labels.updateButton }}
      </button>
    </mat-grid-tile>
  </form>
</mat-grid-list>