import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { isNil as _isNil } from 'lodash';

import { AccountCharge, Shipments, ShipperConfiguration } from '../../interfaces';
import { AppConstants } from '../../constants/app-constants.constants';
import { ChargesPerShipment, SalesSheetRow, ShipmentAccount } from '../../interfaces/sales-sheet';
import { ConfigurationProvider } from '../../providers/configuration/configuration.provider.service';
import { CostStatusEnum } from '../../enums/cost-status';
import { DATEFORMAT_CONSTANTS } from '../../constants/dateformat.constants';
import { SALES_SHEET_CONSTANTS } from '../../pages/costs/sales-sheet/sales-sheet.constants';
import { SalesSheetProvider } from '../../providers/sales-sheet/sales-sheet.provider.service';
import { ShipmentLoadNotExecutedStatus } from '../../enums/shipment-load-not-executed-status';
import { ShipmentSaleCharge } from '../../interfaces/shipmentSaleCharge';
import { ShipmentStatus } from '../../enums/shipment-status';
import { UtilsService } from '../utils.service';
import { VariableTypes } from '../../../app/enums';

/**
 * @description Service for sales sheet module, this contains all necessary to work this.
 */
@Injectable()
export class SalesSheetService {
  private _currentDate: string | number | Date;
  private _freightsPercent: number;
  private _maneuversPercent: number;
  private _percentageIVA: number;
  private _shipperConfig: ShipperConfiguration;
  private _symbolCurrent: string;

  /**
   * @description Initializes the variables of the class when it is instantiated.
   * @param {ConfigurationProvider} configurationProvider - Service with calls to endpoint related with shipper config.
   * @param {DatePipe} datePipe -  Date pipe service.
   * @param {SalesSheetProvider} salesSheetProvider - Provider with differents endpoints to make requests related to billing sheets.
   * @param {UtilsService} utilsService - Service to get the utilities functions.
   */
  constructor(
    private configurationProvider: ConfigurationProvider,
    private datePipe: DatePipe,
    private salesSheetProvider: SalesSheetProvider,
    private utilsService: UtilsService
  ) {
    this._symbolCurrent = AppConstants.CURRENCY_SIGN_NO_SPACE;
    this._percentageIVA = AppConstants.ZERO;
    this._freightsPercent = AppConstants.ZERO;
    this._maneuversPercent = AppConstants.ZERO;
    this._currentDate = Date.now();
  }

  /**
   * @description Generates a new billing sheet folio through endpoint with database sequence.
   * @returns {string} Built billing sheet folio.
   */
  public async buildFolio(): Promise<string> {
    const format = DATEFORMAT_CONSTANTS.DATE_FORMAT_FOLIO;
    const dateString = this.datePipe.transform(this._currentDate, format);
    const billingFolioResponse = await this.salesSheetProvider.getSalesSheetFolio(dateString);

    return billingFolioResponse?.folio;
  }

  /**
   * @description Calculates the withholding cost for freight charges.
   * @param {Array<ShipmentSaleCharge>} charges - The list of shipment sale charges.
   * @param {Array<string>} chargeNames - The names of the charges to include in the calculation.
   * @returns {number} - The calculated withholding cost for freight charges.
   */
  public calculateFreightsWithholdingCost(charges: Array<ShipmentSaleCharge>, chargeNames: Array<string>): number {
    let totalFreightsCost = 0;

    for (const charge of charges) {
      if (chargeNames.includes(charge.chargeName.toLocaleLowerCase())) {
        totalFreightsCost += charge.amount;
      }
    }
    const total = totalFreightsCost * (this._freightsPercent / SALES_SHEET_CONSTANTS.DIVIDER);

    return this.utilsService.fixNumberToDecimals(total);
  }

  /**
   * @description Calculates the income tax withholding cost based on the subtotal.
   * @param {number} subTotal - The subtotal amount.
   * @param {boolean} isSimplifiedTrustRegime - Indicates if the simplified trust regime is applied.
   * @returns {number} - The calculated income tax withholding cost.
   */
  public calculateIncomeTaxCost(subTotal: number, isSimplifiedTrustRegime?: boolean): number {
    const incomeTaxPercent = 0.0125;
    const incomeTaxWithholding = subTotal * (isSimplifiedTrustRegime ? incomeTaxPercent : AppConstants.ZERO);

    return this.utilsService.fixNumberToDecimals(incomeTaxWithholding);
  }

  /**
   * @description Calculates the withholding cost for maneuver charges.
   * @param {Array<ShipmentSaleCharge>} charges - The list of shipment sale charges.
   * @param {Array<string>} chargeNames - The names of the charges to include in the calculation.
   * @returns {number} - The calculated withholding cost for maneuver charges.
   */
  public calculateManeuversWithholdingCost(charges: Array<ShipmentSaleCharge>, chargeNames: Array<string>): number {
    let totalManeuversCost = 0;

    for (const charge of charges) {
      if (chargeNames.includes(charge.chargeName.toLocaleLowerCase())) {
        totalManeuversCost += charge.amount;
      }
    }
    const total = totalManeuversCost * (this._freightsPercent / SALES_SHEET_CONSTANTS.DIVIDER);

    return this.utilsService.fixNumberToDecimals(total);
  }

  /**
   * @description Calculates the subtotal for all sales sheets.
   * @param {Array<SalesSheetRow>} salesSheetRow - Array of salesSheetRow objects.
   * @returns {number} The accumulated subtotal, rounded to a fixed number of decimals.
   */
  public calculateSubTotalForSalesSheet(salesSheetRow: Array<SalesSheetRow>): number {
    let total = 0;

    salesSheetRow.forEach((row: SalesSheetRow) => {
      total += row.subTotal;
    });

    return this.utilsService.fixNumberToDecimals(total);
  }

  /**
   * @description Calculates the subtotal by summing up the charges from an array of shipments.
   * @param {Array<ChargesPerShipment>} charges - Array of ChargesPerShipment objects, each containing a charge amount.
   * @returns {number} The subtotal, rounded to a fixed number of decimals.
   */
  public calculateSubTotalFromCharges(charges: Array<ChargesPerShipment>): number {
    let total = 0;

    charges.forEach((item: ChargesPerShipment) => {
      total += item.charge;
    });

    return this.utilsService.fixNumberToDecimals(total);
  }

  /**
   * @description Calculates the total of a specific charge across all sales sheet rows.
   * @param {Array<SalesSheetRow>} salesSheetRow - Array of salesSheetRow objects.
   * @param {string} chargeName - The name of the charge to look for.
   * @returns {number} The accumulated total for the specified charge.
   */
  public calculateTotalByCharge(salesSheetRow: Array<SalesSheetRow>, chargeName: string): number {
    let total = 0;

    for (const salesSheet of salesSheetRow) {
      for (const charge of salesSheet.charges) {
        if (charge.chargeName === chargeName) {
          total += charge.charge;
        }
      }
    }

    return this.utilsService.fixNumberToDecimals(total);
  }

  /**
   * @description Calculates the total cost of sale by summing up the cost of sale
   * from each row in the provided array of SalesSheetRow objects.
   * @param {Array<SalesSheetRow>} salesSheetRow - The array of SalesSheetRow objects containing the cost of sale for each row.
   * @returns {number} - The total cost of sale, rounded to the appropriate number of decimals.
   */
  public calculateTotalCostOfSale(salesSheetRow: Array<SalesSheetRow>): number {
    let total = 0;

    for (const salesSheet of salesSheetRow) {
      total += salesSheet.costOfSale;
    }

    return this.utilsService.fixNumberToDecimals(total);
  }

  /**
   * @description Calculates the final total for all sales sheets.
   * @param {Array<SalesSheetRow>} salesSheetRow - Array of salesSheetRow objects.
   * @returns {number} The accumulated final total, rounded to a fixed number of decimals.
   */
  public calculateTotalForSalesSheet(salesSheetRow: Array<SalesSheetRow>): number {
    let total = 0;

    salesSheetRow.forEach((row: SalesSheetRow) => {
      total += row.total;
    });

    return this.utilsService.fixNumberToDecimals(total);
  }

  /**
   * @description Calculates the total sales for all sales sheets.
   * @param {Array<SalesSheetRow>} salesSheetRow - Array of salesSheetRow objects.
   * @returns {number} The accumulated total sales.
   */
  public calculateTotalSalesForSalesSheet(salesSheetRow: Array<SalesSheetRow>): number {
    let total = 0;

    salesSheetRow.forEach((row: SalesSheetRow) => {
      total += row.subTotal;
    });

    return this.utilsService.fixNumberToDecimals(total);
  }

  /**
   * @description Calculates the total VAT for all sales sheets.
   * @param {Array<SalesSheetRow>} salesSheetRow - Array of salesSheetRow objects.
   * @returns {number} The accumulated VAT total, rounded to a fixed number of decimals.
   */
  public calculateTotalVatForSalesSheet(salesSheetRow: Array<SalesSheetRow>): number {
    let total = 0;

    salesSheetRow.forEach((row: SalesSheetRow) => {
      total += row.vat;
    });

    return this.utilsService.fixNumberToDecimals(total);
  }

  /**
   * @description Calculates the VAT based on the provided subtotal.
   * @param {number} subTotal - The subtotal amount from which the VAT is calculated.
   * @param {number} defaultVatValue - The default VAT percentage to be used instead of the configured VAT value.
   * @returns {number} The VAT amount, rounded to a fixed number of decimals.
   */
  public calculateVat(subTotal: number, defaultVatValue?: number): number {
    let vatValue = this._percentageIVA;

    if (!_isNil(defaultVatValue)) {
      vatValue = defaultVatValue;
    }

    if (vatValue > AppConstants.ZERO && Number.isInteger(vatValue)) {
      vatValue = (vatValue / SALES_SHEET_CONSTANTS.DIVIDER);
    }

    return this.utilsService.fixNumberToDecimals(subTotal * vatValue);
  }

  /**
   * @description Calculates the general withholding cost for specified charges.
   * @param {Array<ShipmentSaleCharge>} charges - The list of shipment sale charges.
   * @param {Array<string>} chargeNames - The names of the charges to include in the calculation.
   * @param {boolean} hasExtraWithholdings - Indicates if extra withholdings should be applied.
   * @returns {number} - The calculated general withholding cost.
   */
  public calculateWithholdingCost(charges: Array<ShipmentSaleCharge>, chargeNames: Array<string>, hasExtraWithholdings?: boolean): number {
    const withHoldingsPercentage = 0.04;
    let totalCost = 0;

    for (const row of charges) {
      if (chargeNames.includes(row.chargeName) && hasExtraWithholdings) {
        totalCost += row.amount;
      }
    }

    return this.utilsService.fixNumberToDecimals(totalCost * withHoldingsPercentage);
  }

  /**
   * @description Filters an array of ShipmentSaleCharge objects to include only those that meet specific criteria.
   * @param {Array<ShipmentSaleCharge>} charges - The array of ShipmentSaleCharge objects to filter.
   * @returns {Array<ShipmentSaleCharge>} An array of ShipmentSaleCharge objects that meet the filtering criteria.
   */
  public filterShipmentSaleCharges(charges: Array<ShipmentSaleCharge>): Array<ShipmentSaleCharge> {
    return charges?.filter((item: ShipmentSaleCharge) => {
      return !item.saleBillingSheetFolio && item.statusName === CostStatusEnum.sent.valueOf();
    });
  }

  /**
   * @description Retrieves shipment sale charges that match the valid shipment accounts.
   * @param {Array<ShipmentAccount>} validShipmentAccounts - An array of valid shipment accounts to filter the charges.
   * @param {Array<ShipmentSaleCharge>} shipmentSaleCharges - An array of shipment sale charges to be filtered.
   * @returns {Array<ShipmentSaleCharge>} An array of shipment sale charges that match the valid shipment accounts.
   */
  public getChargesFromValidAccounts(validShipmentAccounts: Array<ShipmentAccount>,
    shipmentSaleCharges: Array<ShipmentSaleCharge>): Array<ShipmentSaleCharge> {
    const charges = [];

    for (const validAccount of validShipmentAccounts) {
      const foundCharges = this.filterShipmentSaleCharges(shipmentSaleCharges).filter((item: ShipmentSaleCharge) => {
        return item.shipmentId === validAccount.shipmentId && validAccount.accounts.find((account: AccountCharge) => {
          return account._id === item.accountId;
        }) !== undefined;
      });

      charges.push(...foundCharges);
    }

    return charges;
  }

  /**
   * @description Retrieves a specific piece of data from a sales sheet row.
   * @param {SalesSheetRow} data - A SalesSheetRow object.
   * @param {string} key - The key of the data to retrieve.
   * @param {string} label - The name of the charge, if it needs to be found within `charges`.
   * @returns {number | string} The corresponding value or 0 if the charge is not found.
   */
  public getData(data: SalesSheetRow, key: string, label: string): number | string {
    const value = data[key];
    const charges = data.charges?.length ? data.charges : [];

    if (typeof value === VariableTypes.string.valueOf()) {
      return this.addBreackLine(value, SALES_SHEET_CONSTANTS.MAX_LENGHT_STRING);
    }

    if (typeof value === VariableTypes.number.valueOf()) {
      return value;
    }

    if (Array.isArray(charges)) {
      const chargeValue = charges.find((charge: ChargesPerShipment) => {
        return charge.chargeName === label;
      })?.charge;

      return chargeValue || AppConstants.ZERO;
    }
  }

  /**
   * @description Formats a numeric or string value according to the currency symbol.
   * @param {number | string} value - The value to format.
   * @returns {string} The formatted value as a string with the currency symbol, or as a plain string.
   */
  public getFormatValue(value: number | string): string {
    if (typeof value === VariableTypes.number.valueOf()) {
      return `${this._symbolCurrent}${value}`;
    }

    return value.toString();
  }

  /**
   * @description Get the Shipper Configuration data.
   */
  public async getShipperConfig(): Promise<void> {
    this._shipperConfig = await this.configurationProvider.getShipperConfig();
    this._percentageIVA = this._shipperConfig?.percentageIVA || AppConstants.ZERO;
    this._freightsPercent = this._shipperConfig.withholdings.freightsPercentage || AppConstants.ZERO;
    this._maneuversPercent = this._shipperConfig.withholdings.maneuversPercentage || AppConstants.ZERO;
  }

  /**
   * @description Retrieves an array of valid ShipmentAccount objects based on the provided shipments and account ids.
   * @param {Array<Shipments>} shipments - The array of shipments to search through.
   * @param {Array<string>} accountIds - The list of account ids to validate against the shipments.
   * @returns {Array<ShipmentAccount>} An array of ShipmentAccount objects that contain valid accounts and their corresponding shipment ids.
   */
  public getVallidShipmentAccount(shipments: Array<Shipments>, accountIds: Array<string>): Array<ShipmentAccount> {
    const validShipmentAccounts: Array<ShipmentAccount> = [];

    for (const shipment of shipments) {
      const accounts = shipment.accountsFromAssignedOrders.filter((account: AccountCharge) => {
        return accountIds.includes(account._id) && account.otcStatus === ShipmentLoadNotExecutedStatus.Costed.valueOf();
      });

      if (accounts.length) {
        validShipmentAccounts.push({ accounts: accounts, shipmentId: shipment.shipmentId });
      }
    }

    return validShipmentAccounts;
  }

  /**
   * @description Groups charges by account Id and shipment Id.
   * @param {Array<ShipmentSaleCharge>} charges - Charges to grouped.
   * @returns {Array<SalesSheetRow>} An array of SalesSheetRow.
   */
  public groupChargesByAccountAndShipment(charges: Array<ShipmentSaleCharge>): Array<SalesSheetRow> {
    const groupedData = {};

    for (const charge of charges) {
      const key = `${charge.accountId}_${charge.shipmentId}`;

      if (!groupedData[key]) {
        groupedData[key] = {
          accountId: charge.accountId,
          accountName: charge.accountName,
          charges: [],
          costOfSale: !_isNil(charge.freightSaleEstimate) ? charge.amount : AppConstants.ZERO,
          shipmentId: charge.shipmentId,
          subTotal: AppConstants.ZERO
        };
      }

      if (_isNil(charge.freightSaleEstimate)) {
        groupedData[key].charges.push({
          charge: charge.amount,
          chargeEstimate: charge.freightSaleEstimate,
          chargeName: charge.chargeName
        });
      }
    }

    return Object.values(groupedData) as Array<SalesSheetRow>;
  }

  /**
   * @description Determines if a given number is negative.
   * @param {number} value - The value to check.
   * @returns {boolean} Returns true if the value is less than zero, otherwise false.
   */
  public isNegative(value: number): boolean {
    return value < AppConstants.ZERO;
  }

  /**
   * @description Checks if a shipment is valid based on its status and cancellation status.
   * @param {Shipments} shipment - The shipment to validate.
   * @returns {boolean} - True if the shipment is valid, otherwise false.
   */
  public isShipmentValid(shipment: Shipments): boolean {
    const validStatuses: Array<string> = [
      ShipmentStatus.deleted.valueOf(),
      ShipmentStatus.released.valueOf(),
      ShipmentStatus.costed.valueOf(),
      ShipmentStatus.inBillingSheet.valueOf()
    ];
    const cancelOTCValues = [
      ShipmentLoadNotExecutedStatus.Pending.valueOf(),
      ShipmentLoadNotExecutedStatus.Sent.valueOf()
    ];
    const isShipmentPending: boolean =
      (shipment.status !== ShipmentStatus.deleted.valueOf() || cancelOTCValues.includes(shipment.cancelOTC));

    return validStatuses.includes(shipment.status) && isShipmentPending;
  }

  /**
   * @description Processes the sales sheet information by calculating subtotal, VAT, and total values for each sales sheet row.
   * @param {Array<SalesSheetRow>} salesSheetRows - Array of salesSheetRows objects.
   */
  public prepareSalesSheetRows(salesSheetRows: Array<SalesSheetRow>): void {
    salesSheetRows.forEach((row: SalesSheetRow) => {
      const subTotalValue = this.utilsService.fixNumberToDecimals(
        this.calculateSubTotalFromCharges(row.charges) + row.costOfSale);
      const vatValue = this.calculateVat(subTotalValue);
      const totalValue = subTotalValue + vatValue;
      row.subTotal = subTotalValue;
      row.vat = vatValue;
      row.total = this.utilsService.fixNumberToDecimals(totalValue);
    });
  }

  /**
   * @description Adds line breaks to a string when its length exceeds a maximum value.
   * @param {string} value - The string to which line breaks will be added.
   * @param {number} maxValue - The maximum length allowed before inserting a line break.
   * @returns {string} - The resulting string with line breaks added.
   */
  private addBreackLine(value: string, maxValue: number): string {
    let result = AppConstants.EMPTY_STRING;

    if (value.length <= maxValue) {
      return value;
    }

    for (let i = 0; i < value.length; i += maxValue) {
      result += value.substring(i, i + maxValue) + AppConstants.BREAK_LINE;
    }

    return result;
  }
}
