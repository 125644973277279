import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { DialogGuidesData, OrderViewsGuides } from '../../../interfaces/order-reception';

const DISPLAY_COLUMNS = ['guide', 'courierGuide', 'courier', 'status', 'actions'];

@Component({
  selector: 'app-dialog-view-guides',
  templateUrl: './dialog-view-guides.component.html',
  styleUrls: ['./dialog-view-guides.component.scss', '../../../app.component.scss']
})
export class DialogViewsGuidesComponent implements OnInit {
  public displayedColumns: Array<string>;
  public dataSource: MatTableDataSource<OrderViewsGuides>;
  public guides: Array<OrderViewsGuides>;
  public orderFolio: string;

  constructor(
    private dialogRef: MatDialogRef<DialogViewsGuidesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogGuidesData) {
    this.guides = this.data.guides;
    this.orderFolio = this.data.folio;
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**
   * @description Implementation of on Init Angular component interface
   */
  public ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.guides);
    this.dataSource.paginator = this.paginator;
    this.displayedColumns = DISPLAY_COLUMNS;
    console.log(this.guides);
  }

  /**
   * @description Closes the dialog
   */
  public onClickClose(): void {
    this.dialogRef.close(this.guides);
  }
}
