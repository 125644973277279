import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ACTIONS } from '../../../interfaces/scfgrid';
import { AdditionalChargeAndDiscount, ChargeAndDiscount } from '../../../interfaces/invoiceProposal';
import { AdditionalChargeStatus } from '../../../enums';
import { AppConstants } from '../../../constants/app-constants.constants';
import { AppService } from '../../../../app/app.service';
import { DialogAddAdditionalCharges } from '../../../interfaces';
import { DialogAddAdditionalChargesConst } from './dialog-add-additional-charges.constants';
import { ILanguageLabels } from '../../../interfaces/labels/language-labels.interface';
import { INVOICE_PROPORSAL } from '../../../pages/costs/invoice-proposal/invoice-proposal.constants';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { LocalStorageService } from '../../../services/utils/local-storage.service';
import { ShipperService } from '../../../providers/shipper/shipper.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

const KEY_EN = 'en';

@Component({
  selector: 'app-dialog-add-additional-charges',
  templateUrl: './dialog-add-additional-charges.component.html',
  styleUrls: ['./dialog-add-additional-charges.component.scss', '../../../app.component.scss']
})
export class DialogAddAdditionalChargesComponent implements OnInit {

  public addtionalChargesFormGroup: FormGroup;
  public auxTotalCharges: string;
  public dialogAdditionalChargesLabels: any;
  public isCheckChargesAndDiscountsEnabled: boolean;
  public isDelete: boolean;
  public isDiscount: boolean;
  public isNegativeNumber: boolean;
  public isValidAmount: boolean;
  public languageLabels: ILanguageLabels;
  public languageSuscription: Subscription;
  public maxValue: string;
  public minValue: string;
  public placeholderValue: string;
  public totalAmount: string;
  public totalAmountValue: number;
  public totalCharges: number;
  public totalDiscounts: number;
  public totalFreight: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogAddAdditionalCharges,
    private _appService: AppService,
    private readonly builder: FormBuilder,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService,
    private _localStorageService: LocalStorageService,
    private _shipperService: ShipperService,
    private _toastService: ToastrAlertsService,
    public dialogRef: MatDialogRef<DialogAddAdditionalChargesComponent>
  ) {
    this.totalAmountValue = 0;
    this.isNegativeNumber = false;
    this.isDelete = this.data.actionName === ACTIONS.DELETE;
    this.isDiscount = this.data.dialogType === INVOICE_PROPORSAL.DISCOUNT;
    this.setLanguage();
    this.isCheckChargesAndDiscountsEnabled = false;
    this.isValidAmount = false;
    this.maxValue = '999,999,999.999';
    this.minValue = '0.001';
    this.totalFreight = this.data.totalFreight ?? 0;
    this.totalCharges = this.data.totalCharges ?? 0;
    this.totalDiscounts = this.data.totalDiscounts ?? 0;
    this.totalAmountValue = this.totalFreight + this.totalCharges - this.totalDiscounts;
    this.totalAmount = AppConstants.CURRENCY_SIGN + this.totalAmountValue.toLocaleString(INVOICE_PROPORSAL.KEY_EN);
    this.auxTotalCharges = this.isDiscount ? AppConstants.CURRENCY_SIGN + this.totalDiscounts.toLocaleString(KEY_EN) :
      AppConstants.CURRENCY_SIGN + this.totalCharges.toLocaleString(KEY_EN);
  }

  /**
   * @description Event fires when init component
   */
  public async ngOnInit(): Promise<void> {
    this.initForm();
    this.subscribeLanguageChangeEvent();
    await this.getLabels();
    this.placeholderValue = this.isDiscount ? this.dialogAdditionalChargesLabels.clientDiscountPlaceHolder :
    this.dialogAdditionalChargesLabels.clientChargePlaceHolder;
    const shipperOid = await this._appService.getShipper();
    this.isCheckChargesAndDiscountsEnabled = (await this._shipperService.getShipperById(shipperOid)).
      configuracion.isCheckChargesAndDiscountsEnabled ?? false;
    if (this.isDiscount) {
      this.totalDiscounts = this.totalDiscounts - this.addtionalChargesFormGroup.controls['value'].value;
    } else {
      this.totalCharges = this.totalCharges - this.addtionalChargesFormGroup.controls['value'].value;
    }

    if(this.isDelete) {
      this.showTotalValueToDelete();
      this.isNegativeTotalBillingInvoice();
    }
  }

  /**
   * @description Starts formGroup component
   */
  public initForm(): void {
    this.addtionalChargesFormGroup = this.builder.group({
      concept: new FormControl(this.data.currentCharge.identifier ?? null, [Validators.required]),
      value: new FormControl(this.data.currentCharge.amount ?? null, [Validators.required,
        Validators.min(DialogAddAdditionalChargesConst.MIN_VALUE), Validators.max(DialogAddAdditionalChargesConst.MAX_VALUE)])
    });
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Gets Language labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageLabels(): Promise<void> {
    this.languageLabels = await this._languageTranslateService
    .getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(() => {
      this._toastService.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets Dialog Transport Assignment Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getDialogAdditionalCharges (): Promise<void> {
    this.dialogAdditionalChargesLabels = await this._languageTranslateService
      .getLanguageLabels(LanguageConstants.DIALOG_ADDITIONAL_CHARGE_LABELS)
      .catch(() => {
        this._toastService.errorAlert(this.languageLabels.errorGettingLabels);
      });
  }

  /**
   * @description Gets the necessary tags from the JSON files to use throughout the component
   * @return {void}
   */
  public async getLabels(): Promise<void> {
    await this.getLanguageLabels();
    await this.getDialogAdditionalCharges();
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getLabels();
      },
      () => {
        this._toastService.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Close dialog opened
   */
  public onClickClose(): void {
    this.dialogRef.close(DialogAddAdditionalChargesConst.CLOSED);
  }

  /**
   * @description Deletes objects from an array retreived from local storage.
   * @param {string} localObjectName - Array name to retrieve from local storage.
   */
  private deleteObjectsFromLocalStorage(localObjectName: string): void {
    let objectsFiltered: Array<AdditionalChargeAndDiscount> = [];
    const tempObjectList = JSON.parse(this._localStorageService.getItem(localObjectName)) as Array<AdditionalChargeAndDiscount>;
    objectsFiltered = tempObjectList?.filter((charge: AdditionalChargeAndDiscount) => {
      return charge.identifier !== this.data.currentCharge.identifier;
    });
    this._localStorageService.saveItem(localObjectName, JSON.stringify(objectsFiltered));
  }

  /**
   * @description Saves or updates objects form an array retreived from local storage.
   * @param {string} localObjectName - Array name to retrieve from local storage.
   * @param {boolean} isSearchById - Search object for object ID or identifier.
   */
  private saveOrEditObjectsFromLocalStorage(localObjectName: string, body: ChargeAndDiscount, isSearchById?: boolean): void {
    const tempObjectList = JSON.parse(this._localStorageService.getItem(localObjectName)) as
    Array<AdditionalChargeAndDiscount>;
    if (this.data.actionName === ACTIONS.CREATE) {
      tempObjectList.push(body);
    } else if (this.data.actionName === ACTIONS.EDIT && isSearchById) {
      const indexChargeToEdit = tempObjectList.findIndex(charge => charge.id === body.id);
      tempObjectList[indexChargeToEdit] = body;
    } else {
      const indexChargeToEdit = tempObjectList.findIndex(charge => charge.identifier === body.identifier);
      tempObjectList[indexChargeToEdit] = body;
    }
    this._localStorageService.saveItem(localObjectName, JSON.stringify(tempObjectList));
  }

  /**
   * @description Verifies if the current charge or discount to edit has changes.
   * @param {ChargeAndDiscount} chargeEdited - Charge or discount object edited.
   * @param {Array<AdditionalChargeAndDiscount>} chargesSaved - Additional charges or discounts without changes.
   * @returns {boolean} A boolean value to able or disable save button;
   */
  private isChargeHasChanges(chargeEdited: ChargeAndDiscount, chargesSaved: Array<AdditionalChargeAndDiscount>): boolean {
    const chargeFoundIndex = chargesSaved.findIndex(charge => charge.identifier === chargeEdited.identifier);
    if (chargeFoundIndex === -1) {
      return true;
    } else if (chargeFoundIndex !== -1) {
      return chargeEdited.amount !== chargesSaved[chargeFoundIndex].amount;
    } else {
      return false;
    }
  }

  /**
   * @description Saves additional charge
   */
  public async saveAdditionalCharge(): Promise<void> {
    try {
      const chargeIdentifier = this.addtionalChargesFormGroup.controls['concept'].value;
      const amount = this.addtionalChargesFormGroup.controls['value'].value;
      this._toastService.processingAlert();
      const body: ChargeAndDiscount = {
        amount: amount,
        identifier: chargeIdentifier,
        status: this.isCheckChargesAndDiscountsEnabled ? AdditionalChargeStatus.pendingApproval : AdditionalChargeStatus.registered,
        username: this._appService.getShipperNameCookie(),
        invoiceProposalId: this.data.invoiceProposalOid,
        id: this.data?.currentCharge?._id ?? null,
        isActive: true,
        eventLogs: this.data?.currentCharge?.eventLogs ?? [],
      };

      if (this.data.actionName === ACTIONS.EDIT && this.data?.currentCharge?._id) {
        body.hasChanges = this.isChargeHasChanges(body, JSON.parse(this._localStorageService.
          getItem(DialogAddAdditionalChargesConst.CHARGES_SAVED)));
      }

      if (this.data.actionName === ACTIONS.CREATE) {
        this.saveOrEditObjectsFromLocalStorage(DialogAddAdditionalChargesConst.LOCAL_CHARGES_TO_SAVE, body, false);
      } else {
        this.saveOrEditObjectsFromLocalStorage(DialogAddAdditionalChargesConst.CHARGES_TO_SAVE, body, true);
      }
      this.saveOrEditObjectsFromLocalStorage(DialogAddAdditionalChargesConst.CHARGES, body);
    } catch (error) {
      this._toastService.errorAlert(this.dialogAdditionalChargesLabels.toastErrorSavingAdditionalCharge);
    } finally {
      this.dialogRef.close(DialogAddAdditionalChargesConst.ACCEPTED);
      this._toastService.closeProcessing();
    }
  }

  /**
   * @description Builds additional charge or discount body to save in local storage and desactivate.
   * @returns {ChargeAndDiscount} Additional charge or discount body created.
   */
  private buildChargeBodyToDelete(): ChargeAndDiscount {
    const body = {
      identifier: this.data.currentCharge.identifier,
      status: AppConstants.EMPTY_STRING,
      username: this._appService.getShipperNameCookie(),
      invoiceProposalId: this.data.invoiceProposalOid,
      id: this.data?.currentCharge?._id ?? null,
      eventLogs: this.data.currentCharge.eventLogs,
      amount: this.data.currentCharge.amount,
      isActive: false,
      hasChanges: true
    };

    return body;
  }

  /**
   * @description Makes a soft delete or total delete if the current charge.
   */
  public async deleteAdditionalCharge(): Promise<void> {
    try {
      this._toastService.processingAlert();
      const additionalChargeToDelete = this.data.currentCharge;
      const chargeName = additionalChargeToDelete.charge;
      const isRegisteredCharge = Boolean(additionalChargeToDelete?._id);
      const additionalCharges = JSON.parse(this._localStorageService.getItem(isRegisteredCharge ?
        DialogAddAdditionalChargesConst.CHARGES_TO_SAVE : DialogAddAdditionalChargesConst.LOCAL_CHARGES_TO_SAVE));

      if (isRegisteredCharge) {
        const chargeToDeleteIndex = additionalCharges.findIndex(charge => charge.id === additionalChargeToDelete._id);
        additionalCharges[chargeToDeleteIndex].isActive = false;
        additionalCharges[chargeToDeleteIndex].hasChanges = true;
        additionalCharges[chargeToDeleteIndex].username = this._appService.getShipperNameCookie();
        this._localStorageService.saveItem(DialogAddAdditionalChargesConst.CHARGES_TO_SAVE, JSON.stringify(additionalCharges));
      } else {
        const additionalCharggesFiltered = additionalCharges.filter((additionalCharge: AdditionalChargeAndDiscount) => {
          return additionalCharge.identifier !== additionalChargeToDelete.identifier && additionalCharge.isActive;
        })
        this._localStorageService.saveItem(DialogAddAdditionalChargesConst.LOCAL_CHARGES_TO_SAVE, JSON.stringify(additionalCharggesFiltered));
      }

      this.deleteObjectsFromLocalStorage(DialogAddAdditionalChargesConst.CHARGES);
      this._toastService.successAlert(`${this.dialogAdditionalChargesLabels.additionalCharge} "${chargeName}"
        ${this.dialogAdditionalChargesLabels.hasBeenDeleted}`);
    } catch (error) {
      this._toastService.errorAlert(this.dialogAdditionalChargesLabels.toastErrorDeletingAdditionalCharge);
    } finally {
      this.dialogRef.close(DialogAddAdditionalChargesConst.ACCEPTED);
      this._toastService.closeProcessing();
    }
  }

  /**
   * @description Checks decimals quantity
   */
  private checkDecimals(): void {
    const maxDecimals = 3;
    const quantity = this.addtionalChargesFormGroup.controls['value'].value;
    if (quantity && (quantity - Math.floor(quantity) == 0)) {
      return;
    }
    const valueSplitted = quantity.toString().split(AppConstants.DOT_CHAR);
    if (valueSplitted[1] && valueSplitted[1].length > maxDecimals) {
      this.addtionalChargesFormGroup.controls['value'].setErrors({'invalidDecimals': true});
    }
  }

  /**
   * @description Show total quantities in dialog sign
   */
  public showTotalCharges(): void {
    const amount = this.addtionalChargesFormGroup.controls['value'].value;
    const dialogTypeCharge = 'additionalCharge';
    this.checkAmountChanges();
    if (this.isDiscount) {
      this.totalAmountValue = this.totalFreight + this.totalCharges - this.totalDiscounts - amount;
      this.totalAmount =  AppConstants.CURRENCY_SIGN +  this.totalAmountValue.toLocaleString(KEY_EN);
      this.auxTotalCharges = AppConstants.CURRENCY_SIGN + (this.totalDiscounts +
        amount).toLocaleString(KEY_EN);
    } else if (!this.isDiscount) {
      this.totalAmountValue = this.totalFreight + this.totalCharges - this.totalDiscounts + amount;
      this.totalAmount = AppConstants.CURRENCY_SIGN + this.totalAmountValue.toLocaleString(KEY_EN);
      this.auxTotalCharges = AppConstants.CURRENCY_SIGN + (this.totalCharges +
        amount).toLocaleString(KEY_EN);
    }
    this.checkDecimals();
    if (this.data.dialogType !== dialogTypeCharge) {
      this.checkDiscounts();
    }
  }

  /**
   * @description Checks if total discounts are less than total invoice value
   */
  public checkDiscounts(): void {
    const amount = this.addtionalChargesFormGroup.controls['value'].value;
    const totalDiscounts  = amount + this.totalDiscounts;
    const totalInvoiceCharges = this.totalCharges + this.totalFreight;
    if (this.isDiscount && totalDiscounts > totalInvoiceCharges) {
      this.isValidAmount = false;
      this.addtionalChargesFormGroup.controls['value'].setErrors({'invalidValue': true});
    }
  }

  /**
   * @descriptiona Show total quantities when the dialog is called to delete process
   */
  private showTotalValueToDelete(): void {
    this.totalAmountValue = this.totalFreight + this.totalCharges - this.totalDiscounts;
    this.totalAmount = AppConstants.CURRENCY_SIGN + this.totalAmountValue.toLocaleString(KEY_EN);
    if (this.isDiscount) {
      this.auxTotalCharges = AppConstants.CURRENCY_SIGN + this.totalDiscounts.toLocaleString(KEY_EN);
    } else {
      this.auxTotalCharges = AppConstants.CURRENCY_SIGN + this.totalCharges.toLocaleString(KEY_EN);
    }
  }

  /**
   * @description Verifies if the "value" field has had any change
   */
  public checkAmountChanges(): void {
    const amount = this.addtionalChargesFormGroup.controls['value'].value;
    const chargeId = this.addtionalChargesFormGroup.controls['concept'].value;
    if (amount === this.data.currentCharge.amount && this.data.currentCharge.identifier === chargeId) {
      this.isValidAmount = false;
    } else if (amount === 0 || !amount) {
      this.isValidAmount = false;
    } else {
      this.isValidAmount = true;
    }
  }

  /**
   * @description Saves or updates discount information
   */
  public async saveDiscount(): Promise<void> {
    try {
      const discountIdentifier = this.addtionalChargesFormGroup.controls['concept'].value;
      const amount = this.addtionalChargesFormGroup.controls['value'].value;
      this._toastService.processingAlert();
      const body: ChargeAndDiscount = {
        amount: amount,
        identifier: discountIdentifier,
        status: this.isCheckChargesAndDiscountsEnabled ? AdditionalChargeStatus.pendingApproval :
          AdditionalChargeStatus.registered,
        username: this._appService.getShipperNameCookie(),
        invoiceProposalId: this.data.invoiceProposalOid,
        id: this.data?.currentCharge?._id ?? null,
        isActive: true,
        eventLogs: this.data?.currentCharge?.eventLogs ?? [],
      };

      if (this.data.actionName === ACTIONS.EDIT && this.data?.currentCharge?._id) {
        body.hasChanges = this.isChargeHasChanges(body, JSON.parse(this._localStorageService.
          getItem(DialogAddAdditionalChargesConst.DISCOUNTS_SAVED)));
      }

      if (this.data.actionName === ACTIONS.CREATE) {
        this.saveOrEditObjectsFromLocalStorage(DialogAddAdditionalChargesConst.LOCAL_DISCOUNTS_TO_SAVE, body, false);
      } else {
        this.saveOrEditObjectsFromLocalStorage(DialogAddAdditionalChargesConst.DISCOUNTS_TO_SAVE, body, true);
      }
      this.saveOrEditObjectsFromLocalStorage(DialogAddAdditionalChargesConst.DISCOUNTS, body);
    } catch (error) {
      this._toastService.errorAlert(this.dialogAdditionalChargesLabels.toastErrorSavingDiscount);
    } finally {
      this.dialogRef.close(DialogAddAdditionalChargesConst.ACCEPTED);
      this._toastService.closeProcessing();
    }
  }

  /**
   * @description Makes a soft delete or total delete to discounts.
   */
  public async deleteDiscount(): Promise<void> {
    try {
      this._toastService.processingAlert();
      const discountToDelete = this.data?.currentCharge;
      const discountName = discountToDelete.charge;
      const isRegisteredDiscount = Boolean(discountToDelete?._id);
      const discounts = JSON.parse(this._localStorageService.getItem(isRegisteredDiscount ?
        DialogAddAdditionalChargesConst.DISCOUNTS_TO_SAVE : DialogAddAdditionalChargesConst.LOCAL_DISCOUNTS_TO_SAVE));

      if (isRegisteredDiscount) {
        const discountToDeleteIndex = discounts.findIndex(charge => charge.id === discountToDelete._id);
        discounts[discountToDeleteIndex].isActive = false;
        discounts[discountToDeleteIndex].hasChanges = true;
        discounts[discountToDeleteIndex].username = this._appService.getShipperNameCookie();
        this._localStorageService.saveItem(DialogAddAdditionalChargesConst.DISCOUNTS_TO_SAVE, JSON.stringify(discounts));
      } else {
        const additionalCharggesFiltered = discounts.filter((discount: AdditionalChargeAndDiscount) => {
          return discount.identifier!== discountToDelete.identifier && discount.isActive;
        });
        this._localStorageService.saveItem(DialogAddAdditionalChargesConst.LOCAL_DISCOUNTS_TO_SAVE,
          JSON.stringify(additionalCharggesFiltered));
      }

      this.deleteObjectsFromLocalStorage(DialogAddAdditionalChargesConst.DISCOUNTS);
      this._toastService.successAlert(`${this.dialogAdditionalChargesLabels.discount} "${discountName}"
        ${this.dialogAdditionalChargesLabels.hasBeenDeleted}`);
    } catch (error) {
      this._toastService.errorAlert(this.dialogAdditionalChargesLabels.toastErrorDeletingDiscount);
    } finally {
      this.dialogRef.close(DialogAddAdditionalChargesConst.ACCEPTED);
      this._toastService.closeProcessing();
    }
  }

  /**
   * @description Shows save charge button.
   * @returns {boolean} Actives save charge button.
   */
  public saveChargeButton(): boolean {
    return !this.isDelete && !this.isDiscount;
  }

  /**
   * @description Shows delete charge button.
   * @returns {boolean} Actives delete charge button.
   */
  public deleteChargeButton(): boolean {
    return this.isDelete && !this.isDiscount;
  }

  /**
   * @description Shows save discount button.
   * @returns {boolean} Actives save discount button.
   */
  public saveDiscountButton(): boolean {
    return !this.isDelete && this.isDiscount;
  }

  /**
   * @description Shows delete discount button.
   * @returns {boolean} Activates delete discount button.
   */
  public deleteDiscountButton(): boolean {
    return this.isDelete && this.isDiscount;
  }

  /**
   * @description Verifies if the additional charge to delete is
   * returning an total billing invoice negative.
   */
  public isNegativeTotalBillingInvoice(): void {
    this.isNegativeNumber = Math.sign(this.totalAmountValue) === -1;
  }
}
