import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { API_MODULES, API_SOLUTION, API_TENANTID } from '../../pages/orders/import-orders/import-orders.properties';
import { environment } from '../../../environments/environment';
import { IImportationTemplate } from 'scf-library';
import { ImportationTemplatesResponse } from '../../interfaces/order-bulk-load';

const API_URL = environment.apiUrl;

@Injectable()
export class ImportationService {
  constructor(private http: HttpClient) {}

  /**
   * @description Get the template by shipper, module and solution.
   * @param {tenantId} tenantId - The id of the shipper.
   * @param {modules} modules - The type of module.
   * @param {solution} solution - The type of solution.
   * @returns {Promise<ImportationTemplatesResponse>} Success or fail.
   */
  public async getImportationTemplate(tenantId: string, modules: string, solution: string): Promise<Array<IImportationTemplate>> {
    return (await this.http.get<ImportationTemplatesResponse>(API_URL + '/importationTemplates' + API_TENANTID + tenantId +
      API_MODULES + modules + API_SOLUTION + solution).toPromise()).item;
  }

  /**
   * @description Remove importation template.
   * @param {string} templateOid - Importation template oid to be removed.
   * @returns {Promise<void>} Success or fail.
   */
  public async removeImportationTemplate(templateOid: string): Promise<void> {
    return await this.http.delete<void>(`${API_URL}/importationTemplates?templateOid=${templateOid}`).toPromise();
  }

  /**
   * @description Save Importation template.
   * @param {IImportationTemplate} template - Importation template to be created.
   * @returns {Promise<any>} Success or fail.
   */
  public async saveImportationTemplate(template: IImportationTemplate): Promise<any> {
    return await this.http.post<any>(API_URL + '/importationTemplates', template).toPromise();
  }
}
