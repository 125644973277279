import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AccountBillingSchemeConceptResponse } from '../../interfaces/account-billing-scheme-concept';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';

const TMS_FREIGHT_COST_API = environment.tmsFreightCostApiUrl;

/**
 * @description Provider to generate request to resources of account billing scheme concept.
 */
@Injectable()
export class AccountBillingSchemeConceptProvider {
  /**
   * @description Initialice all dependencies of provider.
   * @param {AppService} appService - Main service of project with global functions.
   * @param {HttpClient} http - Provider with methods to perform HTTP requests.
   */
  constructor(private appService: AppService, private http: HttpClient) { }

  /**
   * @description Gets all related shipper account billing scheme concepts by given shipperId/tenantId.
   * @param {string} shipperId - Optional Param to get related shipperId/tenantId.
   * @param {boolean} ignoreShipperId - Flag to determine whether to ignore the tenantId.
   * @returns {Promise<AccountBillingSchemeConceptResponse>} Shipper found accounts.
   */
  public async getAccountBillingSchemeConcepts(shipperId?: string, ignoreShipperId?: boolean): Promise<AccountBillingSchemeConceptResponse> {
    const shipperOid = shipperId ? shipperId : this.appService.getShipperOid();

    return await this.http.get<AccountBillingSchemeConceptResponse>(
      `${TMS_FREIGHT_COST_API}/accountBillingSchemeConcepts/shipper/${shipperOid}?allConcepts=${ignoreShipperId}`).toPromise();
  }
}
