import { Pipe, PipeTransform } from '@angular/core';

import { AppConstants } from '../constants/app-constants.constants';
import { LanguageTranslateService } from '../services/translate/language-translate.service';

const KEY_LOCAL_DEFAULT = 'default';
const MAX_DAY_LENGTH = 2;
const MAX_YEAR_LENGTH = 4;

@Pipe({ name: 'dateFormat' })

/**
 * @description Pipe for date format as 'DD/MM/YYYY'.
 */
export class DateFormatPipe implements PipeTransform {
  /**
   * @description Builds an instance of all necessaries services for correctly work of this component.
   * @param {LanguageTranslateService} languageTranslateService - Service to retrieve differents all component labels in active language.
   */
  constructor(
    private languageTranslateService: LanguageTranslateService
  ) { }

  /**
   * @description Capitalizes the month in a date string formatted as 'DD/MM/YYYY'.
   * @param {string} dateString - The date string in the format 'DD/MM/YYYY'.
   * @returns {string} The date string with the month capitalized.
   */
  public capitalizeMonth(dateString: string): string {
    const [day, month, year] = dateString.split(AppConstants.SLASH);
    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();

    return `${day}${AppConstants.SLASH}${capitalizedMonth}${AppConstants.SLASH}${year}`;
  }

  /**
   * @description Formats a date string into a formatted date string.
   * @param {string} dateString - The date string.
   * @returns {string} The formatted date string.
   */
  public getFormattedDate(dateString: string): string {
    const date = new Date(dateString);
    const currentLanguage = this.languageTranslateService.getLanguage();
    let local: string;

    if (currentLanguage === AppConstants.KEY_ES) {
      local = AppConstants.KEY_ES;
    } else if (currentLanguage === AppConstants.KEY_EN) {
      local = AppConstants.US_NUMBER_FORMAT;
    } else {
      local = KEY_LOCAL_DEFAULT;
    }
    const day = date.getDate().toString().padStart(MAX_DAY_LENGTH, '0');
    const month = date.toLocaleString(local, { month: 'short' });
    const year = date.getFullYear().toString().slice(-MAX_YEAR_LENGTH);

    return this.capitalizeMonth(`${day}${AppConstants.SLASH}${month}${AppConstants.SLASH}${year}`);
  }

  /**
   * @description Transforms the date string value to a formatted date string.
   * @param {string} value - The date string.
   * @returns {string} The formatted date string.
   */
  public transform(value: string): string {
    return this.getFormattedDate(value);
  }
}
