import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LanguageArrayResponse, LanguageResponse } from '../../interfaces/languageResponse.interface';
import { environment } from '../../../environments/environment';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private http: HttpClient) {}

  /**
   * @description Get languages records in database
   * @returns {Promise<LanguageArrayResponse>} Response to language request
   */
  public async getLanguages(): Promise<LanguageArrayResponse> {
    return this.http.get<LanguageArrayResponse>(apiUrl + '/languages').toPromise();
  }

  /**
   * @description Get one language record by id
   * @returns {Promise<LanguageResponse>} Response to language request
   */
  public async getOneLanguage(id: string): Promise<LanguageResponse> {
    return this.http.get<LanguageResponse>(apiUrl + '/languages' + id).toPromise();
  }
}
