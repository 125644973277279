<div class="scf-flex-row">
  <div class="dialog-input-title">
    {{ 'authErrorDialogLabels.title' | translate }}
  </div>
</div>

<div class="dialog-input-content scf-flex-row scf-flex-center-align">
  <div class="icon-flex">
    <img src="{{ properties.imgPath }}" alt="expired icon">
  </div>
  <div class="message-flex">
    <p class="dialog-description">{{ 'authErrorDialogLabels.description' | translate }}</p>
  </div>
</div>

<div class="scf-flex-row align-end">
  <button
    id="signOutButton"
    mat-raised-button
    color="primary"
    class="on-primary-button"
    (click)="onSignOut()">
    {{ 'authErrorDialogLabels.signOutButton' | translate }}
  </button>
</div>
