import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';

import { AccountBody, AccountResponse } from 'src/app/interfaces/account';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Recurrence, RecurrenceForm, RecurrenceLabels, Periodicity } from './../../interfaces/recurrence';
import { RecurrenceTags } from './recurrence.labels';

import * as _ from 'lodash';

const DAILY_RECURRENCE_TYPE = 1;
const DISABLED_PROPERTY = 'DISABLED';
const KEY_ACCOUNT = 'account';
const KEY_EXEC_TIME = 'executionTime';
const KEY_TYPE = 'type';
const KEY_WEEKDAY = 'weekday';
const RECURRENCE_TYPES = [
  { id: 1, desc: 'Diaria', modelId: 'Day' },
  { id: 2, desc: 'Semanal', modelId: 'Week' }];
const WEEKDAYS = [
  { id: 1, desc: 'Lunes', modelId: 'Monday' },
  { id: 2, desc: 'Martes', modelId: 'Tuesday' },
  { id: 3, desc: 'Miércoles', modelId: 'Wednesday' },
  { id: 4, desc: 'Jueves', modelId: 'Thursday' },
  { id: 5, desc: 'Viernes', modelId: 'Friday' },
  { id: 6, desc: 'Sábado', modelId: 'Saturday' },
  { id: 7, desc: 'Domingo', modelId: 'Sunday' }];

@Component({
  selector: 'app-recurrence',
  templateUrl: './recurrence.component.html',
  styleUrls: ['./recurrence.component.scss']
})

export class RecurrenceComponent implements OnInit, OnChanges {

  @Input() public allAccounts: AccountResponse;
  @Input() public availableAccounts: RecurrenceForm[];
  @Input() public recurrence: Recurrence;
  @Output() public cancelEvent: EventEmitter<void>;
  @Output() public recurrenceExecution: EventEmitter<Recurrence>;

  public labels: RecurrenceLabels;
  public recurrenceForm: UntypedFormGroup;
  public recurrenceTypes: Array<Periodicity>;
  public weekdays: Array<Periodicity>;

  constructor(public formBuilder: UntypedFormBuilder) {
    this.recurrenceExecution = new EventEmitter<Recurrence>();
    this.cancelEvent = new EventEmitter<void>();
    this.labels = RecurrenceTags;
  }

  public ngOnInit(): void {
    this.recurrenceTypes = RECURRENCE_TYPES;
    this.weekdays = WEEKDAYS;
    this.initializeForm();
    if (this.recurrence) {
      this.patchOnEdition();
    }
  }

  public ngOnChanges(simpleChanges): void {
    if (simpleChanges.recurrence.currentValue) {
      this.recurrence = simpleChanges.recurrence.currentValue;
      this.patchOnEdition();
    }
  }

  /**
   * @description Initialize POD configuration form
   */
  public initializeForm(): void {
    this.recurrenceForm = this.formBuilder.group({
      account: ['', Validators.compose([Validators.required])],
      type: [DAILY_RECURRENCE_TYPE, Validators.compose([Validators.required])],
      weekday: ['', Validators.compose([Validators.required])],
      executionTime: ['', Validators.compose([Validators.required])],
    });
    this.onRecurrenceTypeChange(DAILY_RECURRENCE_TYPE);
  }

  /**
   * @description Disables weekday attribute when Day periodiciy is selected
   */
  public onRecurrenceTypeChange(recurrenceTypeId: number): void {
    if (recurrenceTypeId === DAILY_RECURRENCE_TYPE && recurrenceTypeId !== undefined && recurrenceTypeId !== null) {
      this.recurrenceForm.controls[KEY_WEEKDAY].reset();
      this.recurrenceForm.controls[KEY_WEEKDAY].disable();
    } else {
      this.recurrenceForm.controls[KEY_WEEKDAY].enable();
    }
  }

  /**
   * @description Enables weekday form field depending on selected periodicity type
   * @returns {boolean} Flag to enable or disable week periodicity
   */
  public showFormField(formField: string): boolean {
    let showField = true;
    if (this.recurrenceForm.controls[formField].status === DISABLED_PROPERTY) {
      showField = false;
    }
    return showField;
  }

  /**
   * @description Set POD information in component form for editing
   */
  public patchOnEdition(): void {
    const recurrenceId = _.find(this.recurrenceTypes,
      (type: Periodicity) => type.modelId === this.recurrence.frequency).id;
    let weekdayId = '';
    const hours = _.first(this.recurrence.schedule.hours);
    const minutes = _.first(this.recurrence.schedule.minutes);
    if (recurrenceId !== DAILY_RECURRENCE_TYPE) {
      weekdayId = _.find(this.weekdays,
        (weekdayItem: Periodicity) => weekdayItem.modelId === _.first(this.recurrence.schedule.weekDays)).id;
    }
    this.recurrenceForm.patchValue({
      account: this.recurrence.accountId,
      type: recurrenceId,
      weekday: weekdayId,
      executionTime: hours + ':' + minutes
    });
    this.onRecurrenceTypeChange(recurrenceId);
  }

  /**
   * @description Save a new POD configuration
   */
  public savePOD(): void {
    const selectedAccountName = _.find(this.allAccounts.cuentas,
      (account: AccountBody) => account._id === this.recurrenceForm.controls[KEY_ACCOUNT].value).nombre;
    const recurrenceType = _.find(this.recurrenceTypes, (type: Periodicity) => type.id === this.recurrenceForm.controls[KEY_TYPE].value);
    const weekdaySelected = _.find(this.weekdays, (weekday: Periodicity) => weekday.id === this.recurrenceForm.controls[KEY_WEEKDAY].value);
    const weekdayArray = weekdaySelected ? [weekdaySelected.modelId] : [];
    const splittedTime = this.recurrenceForm.controls[KEY_EXEC_TIME].value.split(':');
    const recurrenceExecution: Recurrence = {
      accountId: this.recurrenceForm.controls[KEY_ACCOUNT].value,
      accountName: selectedAccountName,
      frequency: recurrenceType.modelId,
      interval: 1,
      schedule: {
        weekDays: (recurrenceType.id !== DAILY_RECURRENCE_TYPE ? weekdayArray : undefined),
        hours: [splittedTime[0]],
        minutes: [splittedTime[1]]
      }
    };
    this.recurrenceExecution.emit(recurrenceExecution);
  }

  /**
   * @description On cancel clean form and close
   */
  public onCancel(): void {
    this.cancelEvent.emit();
  }
}
