import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DIalogLogDetailData, DialogLogLabels } from '../../../interfaces';
import { DialogLogTags } from './dialog-log-details.labels';

const CLOSED = 'Closed';
const LOGCOLUMNS = [
  'number',
  'type',
  'username',
  'date'];

@Component({
  selector: 'app-dialog-log-details',
  templateUrl: './dialog-log-details.component.html',
  styleUrls: ['./dialog-log-details.component.scss', '../../../app.component.scss']
})
export class DialogLogDetailsComponent implements OnInit {
  public displayedColumns: Array<string>;
  public labels: DialogLogLabels;

  constructor(public dialogRef: MatDialogRef<DialogLogDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DIalogLogDetailData ) { this.labels = DialogLogTags; }

  ngOnInit(): void {
    this.displayedColumns = LOGCOLUMNS;
  }

  public onClickClose(): void {
    this.dialogRef.close(CLOSED);
  }

}
