import { AppService } from 'src/app/app.service';
import { DeliveryVsTimeKPI, FilterMenu, OrdersKPI } from '../../interfaces';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

const apiUrl = environment.apiUrl;
@Injectable()
export class IndicatorsProvider {
  public shipmentsByFilters: Subject<FilterMenu[]>;
  public shipmentsSearchBar: Subject<{ shipment: string, db: boolean }>;
  public shipperOid: string;
  public userOid: string;

  constructor(private http: HttpClient, private appService: AppService) {
    this.shipperOid = this.appService.getShipperOid();
    this.userOid = this.appService.getUserOid();
    this.shipmentsSearchBar = new Subject<{ shipment: string, db: boolean }>();
    this.shipmentsByFilters = new Subject<FilterMenu[]>();
  }

  public async deliveryVsTime(filter: Object): Promise<DeliveryVsTimeKPI> {
    return this.http.post<DeliveryVsTimeKPI>(apiUrl +
      `/shipmentRequest/${this.shipperOid}/indicatorDeliveryVsTime?userOid=${this.userOid}`,
      filter).toPromise();
  }

  public async getOrderSummary(filters?: FilterMenu[]): Promise<OrdersKPI> {
    return await this.http.post<OrdersKPI>(apiUrl +
      '/orders/orderSummary?shipperOid=' + this.shipperOid + '&userOid=' +
      this.userOid, filters).toPromise();
  }

  public async getOrderSummaryByShipment(shipment: string): Promise<OrdersKPI> {
    return await this.http.get<OrdersKPI>(apiUrl +
      '/orders/orderSummary?shipperOid=' + this.shipperOid + '&shipment=' + shipment +
      '&userOid=' + this.userOid).toPromise();
  }
}
