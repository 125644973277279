<div class="preview-title-container">
  <div class="preview-title">
    <span class="dialog-title">
      <p>{{ data.title }}</p>
    </span>
  </div>
  <div class="preview-icon">
    <mat-icon class="preview-icon-button" (click)="onClickClose()">
      close
    </mat-icon>
  </div>
</div>

<div class="log-table">
  <table
    mat-table
    [dataSource]="data.dataSource"
    aria-describedby="logShipment">
    <ng-container matColumnDef="number">
      <th
        id="columnNumber"
        mat-header-cell
        *matHeaderCellDef>
        {{ labels.numberHeaderColumn }}
      </th>
      <td
        mat-cell
        *matCellDef="let log">
       <strong> {{ log.sequence }} </strong>
    </ng-container>

    <ng-container matColumnDef="type">
      <th
        id="columnType"
        mat-header-cell
        *matHeaderCellDef>
        {{ labels.typeHeaderColumn }}
      </th>
      <td
        mat-cell
        *matCellDef="let log">
        {{ log.action }}
    </ng-container>

    <ng-container matColumnDef="username">
      <th
        id="columnUsername"
        mat-header-cell
        *matHeaderCellDef>
        {{ labels.userHeaderColumn }}
      </th>
      <td
        mat-cell
        *matCellDef="let log">
        {{ log.user }}
    </ng-container>

    <ng-container matColumnDef="date">
      <th
        id="columnRCF"
        mat-header-cell
        *matHeaderCellDef>
        {{ labels.dateHeaderColumn }}
      </th>
      <td
        mat-cell
        *matCellDef="let log">
        {{ log.timestamp | date:'dd/MM/yyyy hh:mm:ss a' }}
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<div class="button-container">
  <button
   mat-button
   cdkFocusInitial
   class="primary-button"
   (click)="onClickClose()">
   {{ data.button }}
 </button>
</div>
