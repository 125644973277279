import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { AppService } from '../../../app.service';
import { environment } from '../../../../environments/environment';

const ALLOWPROFILES = ['Transportista Biis'];
const NOPERMISSIONROUTE = '/no-permission';
const PATH = '/biis/admin/solicitudes';

@Component({
  selector: 'app-carrier-request',
  templateUrl: './carrier-request.component.html',
  styleUrls: ['../../../app.component.scss']
})
export class CarrierRequestComponent implements OnInit {
  public urlSafe: SafeResourceUrl;
  public url = `${environment.clientUrl}${PATH}?us=`;

  constructor(
    private appService: AppService,
    private cookieService: CookieService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    const petition = this.cookieService.get('auth');
    const us = JSON.parse(petition);
    us.path = PATH;

    if (!this.checkPermission()) {
      this.router.navigateByUrl(NOPERMISSIONROUTE);
    } else {
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + JSON.stringify(us));
    }
  }

  /**
   * @description: Validate if user is allowed access
   * @returns boolean indicating if it has access
   */
  public checkPermission(): boolean {
    let allowAccess = false;
    const profile = this.appService.getProfile();
    if (ALLOWPROFILES.includes(profile)) {
      allowAccess = true;
    }
    return allowAccess;
  }
}
