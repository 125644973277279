import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CardWhiteGuidesTag } from 'src/app/interfaces';
import { CardWhiteGuidesTags } from './card-white-guides.labels';

const CODE_BOX = 'BXS';
const CODE_PALLETS = 'PLS';
const CODE_PIECES = 'PCS';

@Component({
  selector: 'app-card-white-guides',
  templateUrl: './card-white-guides.component.html',
  styleUrls: ['./card-white-guides.component.scss', '../../app.component.scss']
})
export class CardWhiteGuidesComponent implements OnInit {
  @Output() wantsWhiteGuides = new EventEmitter<string>();
  public guideBoxes: boolean;
  public guidePallets: boolean;
  public guidePieces: boolean;
  public masterGuide: boolean;
  public panelOpenState: boolean;
  public tags: CardWhiteGuidesTag;
  public whiteGuides: boolean;

  constructor() {
    this.tags = CardWhiteGuidesTags;
  }

  ngOnInit(): void {
    this.setDefaultGuideValues();
  }

  /**
   * @description It sets the default values for the guides in the white guides card
   */
  public setDefaultGuideValues(): void {
    this.masterGuide = true;
    this.whiteGuides = false;
    this.guideBoxes = false;
    this.guidePallets = false;
    this.guidePieces = false;
  }

  /**
   * @description It switches the values for the checkboxes
   * @param masterGuideSelected
   */
  public switchGuideValues(masterGuideSelected = true): void {
    if (masterGuideSelected) {
      this.masterGuide = true;
      this.whiteGuides = false;
      this.guideBoxes = false;
      this.guidePallets = false;
      this.guidePieces = false;
    } else {
      this.masterGuide = false;
      this.whiteGuides = true;
      this.guideBoxes = true;
      this.guidePallets = false;
      this.guidePieces = false;
    }
    this.sendUnitCode();
  }

  /**
   * @description It returns the code depending on the checkbox selected
   */
  public sendUnitCode(): string {
    if (this.guideBoxes) {
      this.wantsWhiteGuides.emit(CODE_BOX);
      return CODE_BOX;
    } else if (this.guidePallets) {
      this.wantsWhiteGuides.emit(CODE_PALLETS);
      return CODE_PALLETS;
    } else if (this.guidePieces) {
      this.wantsWhiteGuides.emit(CODE_PIECES);
      return CODE_PIECES;
    } else {
      this.wantsWhiteGuides.emit(null);
      return null;
    }
  }
}
