export class DialogOrderBulkLoadConfigProperties {
  public static configGridColumns = {
    account: 'account',
    configSelector: 'configSelector',
    costumerPickup: 'costumerPickup',
    creationDate: 'creationDate',
    identifier: 'identifier',
    invoice: 'invoice',
    name: 'name',
    orderType: 'orderType',
    serviceType: 'serviceType',
    warehouse: 'warehouse'
  }
  public static gridPageSize: 25;
  public static gridPageSizeOptions: [25, 50, 100];
}
