import { Injectable } from '@angular/core';

import { isNil as _isNil } from 'lodash';

import { AccountInfo, ShipmentInfoCustomerBilling } from '../../interfaces/review-customer-billing';
import { AppConstants } from '../../constants/app-constants.constants';
import { ShipmentSaleCharge, ShipmentSaleChargeCapture } from '../../interfaces/shipmentSaleCharge';
import { ShipmentLoadNotExecutedStatus } from '../../enums/shipment-load-not-executed-status';
import { Shipments } from '../../interfaces';
import { ShipmentStatus } from '../../enums/shipment-status';
import { VariableCharges } from '../../enums/variable-charges';

/**
 * @description A utility service that provides methods for calculating billing for customers.
 */
@Injectable()
export class ReviewCustomerBillingService {
  /**
   * @description Initializes the variables of the class when it is instantiated.
   */
  constructor() {}

  /**
   * @description Constructs an array of account information from a shipment and its associated sale charges.
   * @param {Shipments} shipment - The shipment object containing account information and assigned orders.
   * @param {Array<ShipmentSaleCharge>} shipmentSaleCharges - An array of shipment sale charge objects.
   * @returns {Array<AccountInfo>} - An array of account information objects, each containing account details and related charges.
   */
  public buildAccountInfo(shipment: Shipments, shipmentSaleCharges: Array<ShipmentSaleCharge>): Array<AccountInfo> {
    const accountsInfo = [];

    for (const accountAssigned of shipment.accountsFromAssignedOrders) {
      const chargesByAccount: AccountInfo = {
        account: accountAssigned,
        charges: []
      };

      const shipmentSaleChargesFound = shipmentSaleCharges.filter((item: ShipmentSaleCharge) => {
        return item.accountId === accountAssigned._id;
      }).map((shipmentSaleCharge: ShipmentSaleCharge) => {
        if (shipmentSaleCharge.chargeName.toLocaleLowerCase() === VariableCharges.Freight.valueOf().toLocaleLowerCase()) {
          shipmentSaleCharge['_noDelete'] = true;
        }

        return shipmentSaleCharge;
      });

      if (shipmentSaleChargesFound.length) {
        chargesByAccount.charges.push(...shipmentSaleChargesFound);
      }

      accountsInfo.push(chargesByAccount);
    }

    return accountsInfo;
  }

  /**
   * @description Checks if the charges can be accepted for a given shipment based on its status and cancelOTC flag.
   * @param {ShipmentInfoCustomerBilling} shipment - The shipment object containing statusName and cancelOTC properties.
   * @returns {boolean} - Returns true if the charges can be accepted, false otherwise.
   */
  public canNotBeAcceptAndSendCharges(shipment: ShipmentInfoCustomerBilling): boolean {
    const validStatuses = [
      ShipmentStatus.confirmed.valueOf(),
      ShipmentStatus.inTransit.valueOf(),
      ShipmentStatus.delivered.valueOf()
    ];

    return validStatuses.includes(shipment.status);
  }

  /**
   * @description Converts the given ShipmentSaleCharge object to a simplified version.
   * @param {ShipmentSaleCharge} data - The ShipmentSaleCharge object to be simplified.
   * @returns {ShipmentSaleCharge} - Returns a simplified version of the provided ShipmentSaleCharge object.
   */
  public convertToSimpleCharge(data: ShipmentSaleCharge): ShipmentSaleCharge {
    const item: ShipmentSaleCharge = {} as ShipmentSaleCharge;

    if (data.id) {
      item.id = data.id;
    }

    item.amount = data.amount;
    item.chargeId = data.chargeId;
    item.chargeName = data.chargeName;
    item.accountId = data.accountId;
    item.accountName = data.accountName;

    return item;
  }

  /**
   * @description Checks if a charge exists in the list, excluding the specified index.
   * @param {Array<ShipmentSaleCharge>} charges - The array of charges to check.
   * @param {ShipmentSaleCharge} newItem - The charge to check.
   * @param {number} excludeIndex - The index to exclude from the check.
   * @returns {boolean} - True if the charge exists, false otherwise.
   */
  public existsCharge(charges: Array<ShipmentSaleCharge>, newItem: ShipmentSaleCharge, excludeIndex?: number): boolean {
    if (_isNil(excludeIndex)) {
      excludeIndex = AppConstants.INVALID_INDEX;
    }

    return charges.some((item: ShipmentSaleCharge, index: number) => {
      return index !== excludeIndex &&
        item.chargeId === newItem.chargeId &&
        item.accountId === newItem.accountId;
    });
  }

  /**
   * @description Finds an item from the array of charges.
   * @param {Array<ShipmentSaleCharge>} charges - The array of ShipmentSaleCharge objects.
   * @param {ShipmentSaleCharge} itemToFind - The ShipmentSaleCharge object to find in the charges array.
   * @returns {object} An object containing the index of the found item and the item.
   */
  public findItemFromIndex(charges: Array<ShipmentSaleCharge>, itemToFind: ShipmentSaleCharge): object {
    const index = charges.findIndex((item: ShipmentSaleCharge) => {
      return item.chargeId === itemToFind.chargeId &&
        item.accountId === itemToFind.accountId;
    });

    if (index !== AppConstants.INVALID_INDEX) {
      return {
        charge: charges[index],
        index
      };
    }

    return {
      charge: undefined,
      index
    };
  }

  /**
   * @description Retrieves all charges from the given shipment information.
   * @param {ShipmentInfoCustomerBilling} shipmentInfo - The shipment information containing the charges.
   * @param {boolean} isSimple - Flag indicating whether to convert charges to a simple format.
   * @returns {Array<ShipmentSaleCharge>} - Returns an array of shipment sale charges.
   */
  public getAllChargesFromShipment(shipmentInfo: ShipmentInfoCustomerBilling, isSimple?: boolean): Array<ShipmentSaleCharge> {
    const newCharges = [];

    for (const chargeInfo of shipmentInfo.accountsInfo) {
      const charges = chargeInfo.charges.map((item: ShipmentSaleCharge) => {
        return isSimple ? this.convertToSimpleCharge(item) : item;
      });
      newCharges.push(...charges);
    }

    return newCharges;
  }

  /**
   * @description Retrieves an array of shipment sale charges filtered by their status.
   * @param {Array<ShipmentSaleCharge>} charges - The array of shipment sale charges to filter.
   * @param {string} status - The status to filter the charges by.
   * @returns {Array<ShipmentSaleCharge>} - An array of shipment sale charges that match the specified status.
   */
  public getChargesByStatus(charges: Array<ShipmentSaleCharge>, status: string): Array<ShipmentSaleCharge> {
    return charges.filter((charge: ShipmentSaleCharge) => {
      return charge.statusName === status;
    });
  }

  /**
   * @description Gets the charges selected.
   * @param {Array<ShipmentSaleCharge>} charges - The array of shipment sale charges.
   * @returns {Array<ShipmentSaleCharge>} - An array of selected shipment sale charges.
   */
  public getChargesSelected(charges: Array<ShipmentSaleCharge>): Array<ShipmentSaleCharge> {
    return charges.filter((charge: ShipmentSaleCharge) => {
      return charge['_checked'];
    });
  }

  /**
   * @description Calculates the total freight sale from an array of shipment sale charges.
   * Only includes charges with a name matching 'Freight' or marked as 'isFreight'.
   * @param {Array<ShipmentSaleCharge>} charges - The array of shipment sale charges.
   * @returns {number} - The total freight sale of the charges.
   */
  public getFreightCostTotal(charges: Array<ShipmentSaleCharge>): number {
    let total: number = AppConstants.ZERO;

    for (const charge of charges) {
      let value = AppConstants.ZERO;

      if (charge.chargeName.toLocaleLowerCase() === VariableCharges.Freight.valueOf().toLocaleLowerCase() || charge['isFreight']) {
        value = charge?.amount || AppConstants.ZERO;
      }

      total += value;
    }

    return total;
  }

  /**
   * @description Calculates the total amount from an array of shipment sale charges.
   * @param {Array<ShipmentSaleCharge>} charges - The array of shipment sale charges.
   * @returns {number} - The total amount of the charges.
   */
  public getTotal(charges: Array<ShipmentSaleCharge>): number {
    let total: number = AppConstants.ZERO;

    for (const charge of charges) {
      total += charge?.amount || AppConstants.ZERO;
    }

    return total;
  }

  /**
   * @description Checks if two charges are equal.
   * @param {ShipmentSaleCharge} currentCharge - The current charge being compared.
   * @param {ShipmentSaleCharge} chargeModified - The modified charge being compared.
   * @returns {boolean} - Returns true if the charges are equal, false otherwise.
   */
  public isChargeEqual(currentCharge: ShipmentSaleCharge, chargeModified: ShipmentSaleCharge): boolean {
    return currentCharge.amount === chargeModified.amount &&
      currentCharge.chargeId === chargeModified.chargeId &&
      currentCharge.accountId === chargeModified.accountId;
  }

  /**
   * @description Checks if the charge is invalid to save.
   * @param {ShipmentSaleChargeCapture} newCharge - The new charge to be validated.
   * @param {ShipmentInfoCustomerBilling} shipmentInfo - The shipment information.
   * @param {ShipmentSaleCharge} currentCharge - The current charge being modified.
   * @returns {boolean} - Returns true if the charge is invalid to save, false otherwise.
   */
  public isInvalidChargeToSave(newCharge: ShipmentSaleChargeCapture,
    shipmentInfo: ShipmentInfoCustomerBilling, currentCharge: ShipmentSaleCharge): boolean {
    const charges: Array<ShipmentSaleCharge> = this.getAllChargesFromShipment(shipmentInfo, true);

    if (!currentCharge) {
      return this.existsCharge(charges, newCharge);
    }

    return this.isValidEdition(charges, currentCharge, newCharge);
  }

  /**
   * @description Checks the value is a negative.
   * @param {number} value - The value to check.
   * @returns {boolean} - Return true if the value is negative; otherwise return false.
   */
  public isNegative(value: number): boolean {
    return value < AppConstants.ZERO;
  }

  /**
   * @description Checks if a shipment is valid based on its status and cancellation status.
   * @param {Shipments} shipment - The shipment to validate.
   * @returns {boolean} - True if the shipment is valid, otherwise false.
   */
  public isShipmentValid(shipment: Shipments): boolean {
    const validStatuses: Array<string> = [
      ShipmentStatus.deleted.valueOf(),
      ShipmentStatus.confirmed.valueOf(),
      ShipmentStatus.inTransit.valueOf(),
      ShipmentStatus.delivered.valueOf(),
      ShipmentStatus.released.valueOf(),
      ShipmentStatus.costed.valueOf(),
      ShipmentStatus.inBillingSheet.valueOf()
    ];
    const cancelOTCValues = [
      ShipmentLoadNotExecutedStatus.Pending.valueOf(),
      ShipmentLoadNotExecutedStatus.Sent.valueOf()
    ];
    const isShipmentPending: boolean =
      (shipment.status !== ShipmentStatus.deleted.valueOf() || cancelOTCValues.includes(shipment.cancelOTC));

    return validStatuses.includes(shipment.status) && isShipmentPending;
  }

  /**
   * @description Checks if the modified charge is valid for edition.
   * @param {Array<ShipmentSaleCharge>} charges - The list of existing charges.
   * @param {ShipmentSaleCharge} currentCharge - The current charge being modified.
   * @param {ShipmentSaleCharge} chargeModified - The modified charge to be validated.
   * @returns {boolean} - Returns true if the charge is valid for edition, false otherwise.
   */
  public isValidEdition(charges: Array<ShipmentSaleCharge>,
    currentCharge: ShipmentSaleCharge, chargeModified: ShipmentSaleCharge): boolean {
    const item = this.findItemFromIndex(charges, currentCharge);

    if (item['index'] !== AppConstants.INVALID_INDEX) {
      if (this.isChargeEqual(currentCharge, chargeModified)) {
        return false;
      }

      if (this.existsCharge(charges, chargeModified, item['index'])) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * @description Toggles the checked state of a data row.
   * @param {ShipmentSaleCharge} row - The row to be toggled.
   * @param {boolean} valueChecked - The boolean value indicating whether the row should be checked or not.
   */
  public toggleRowChecked(row: ShipmentSaleCharge, valueChecked: boolean): void {
    if (valueChecked) {
      row['_checked'] = valueChecked;
    } else {
      delete row['_checked'];
    }
  }
}
