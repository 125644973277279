<mat-grid-list cols="4" rowHeight="1:.15">
  <mat-grid-tile colspan="4" rowspan="1">
    <span class="dialog-title">
      <p>{{ data.title }}</p>
    </span>
  </mat-grid-tile>
</mat-grid-list>
<br>
<mat-grid-list cols="3" rowHeight="1:.20" >
</mat-grid-list>
<mat-grid-list cols="3" rowHeight="1:.20" >
  <mat-grid-tile colspan="1" rowspan="5">
    <img src="{{ data.iconPath }}" alt="path icon">
  </mat-grid-tile>
  <mat-grid-tile colspan="2" rowspan="2">
    <span class="focus-text-class">{{ data.resume }}</span>
  </mat-grid-tile>
  <mat-grid-tile colspan="2" rowspan="1" *ngIf="data.focusQuestion === labels.emptyString">
    <form [formGroup]="rateFormGroup">
      <span class="amount-class">{{ labels.myAmount }}</span>
      <mat-form-field [floatLabel]="'never'">
        <input
          id="amountInput"
          type="number"
          matInput
          (input)="validateBudget()"
          (focusout)="validateBudget()"
          formControlName="amount"
          maxlength="10"/>
      </mat-form-field>
    </form>
  </mat-grid-tile>
  <mat-grid-tile colspan="2" rowspan="1" *ngIf="(data.focusQuestion !== labels.offers) && (data.focusQuestion !== labels.emptyString)">
    <span class="focus-question-class">{{ data.focusQuestion }}</span>
  </mat-grid-tile>
  <mat-grid-tile colspan="2" rowspan="1" *ngIf="data.question">
    <span class="question-class" *ngIf="data.question">{{ data.question }}</span>
  </mat-grid-tile>
  <mat-grid-tile class="button-container">
    <button
      id="primaryButton"
      mat-raised-button
      class="secondary-button align-left button-dialog"
      (click)="onPrimaryButton()">
      {{ data.textButton1 }}
    </button>
    <button
      id="secondaryButton"
      mat-raised-button
      [disabled]="rateFormGroup.invalid"
      class="primary-button align-right button-dialog"
      (click)="onSecondaryButton()">
      {{ data.textButton2 }}
    </button>
  </mat-grid-tile>
</mat-grid-list>
