export enum MapTrackingConstants {
  fontSize = '12px',
  fontWeight = 'bold',
  pathTemperaturePin = '../../../assets/icons/tracking/TemperatureAssets/bubblev3.svg',
  textColor = '#bd372f'
}
export enum MapConstants {
  defaultLatitude = 19.4325412,
  defaultLongitude = -99.1338442,
  timeToReloadMap = 10
}
