<div class="centered">
  <mat-progress-spinner
    *ngIf="!loggedOut"
    class="spinner"
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</div>
<iframe
  hidden="!loggedOut"
  id="logout"
  width="100%"
  height="100%"
  class="iframe-border"
  title="logoutFrame"
  [src]="urlSafe">
</iframe>
