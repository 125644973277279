<div class="table-container">
  <div>
    <table
      *ngIf="data?.length"
      [dataSource]="dataSource"
      aria-describedby="billingSheets"
      mat-table>
        <ng-container matColumnDef="folio">
          <th
            id="rfi-billing-sheet-folio-column"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ gridHeadersLabels?.folio }}
          </th>
          <td
            mat-cell
            *matCellDef="let billingSheet">
            {{ billingSheet.folio ? billingSheet.folio : gridHeadersLabels.noData }}
          </td>
        </ng-container>

        <ng-container matColumnDef="carrierName" *ngIf="!isInvoiceRegisterBySaleSheet">
          <th
            id="rfi-billing-sheet-carrier-column"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ gridHeadersLabels.carrierName }}
          </th>
          <td
            mat-cell
            *matCellDef="let billingSheet">
            {{ billingSheet.carrierName ? billingSheet.carrierName : gridHeadersLabels.noData }}
          </td>
        </ng-container>

        <ng-container matColumnDef="accountName" *ngIf="isInvoiceRegisterBySaleSheet">
          <th
            id="rfi-billing-sheet-account-column"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ gridHeadersLabels.accountName }}
          </th>
          <td
            mat-cell
            *matCellDef="let billingSheet">
            {{ billingSheet.accountName ? billingSheet.accountName : gridHeadersLabels.noData }}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="creationDate">
          <th
            id="rfi-billing-sheet-creation-date-column"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ gridHeadersLabels.creationDate }}
          </th>
          <td
            mat-cell
            *matCellDef="let billingSheet">
            {{ billingSheet.creationDate ? getBillingSheetCreationDate(billingSheet.creationDate) : gridHeadersLabels.noData }}
          </td>
        </ng-container>

        <ng-container matColumnDef="notes">
          <th
            id="rfi-billing-sheet-notes-column"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ gridHeadersLabels.notes }}
          </th>
          <td
            mat-cell
            *matCellDef="let
            billingSheet">
            {{ billingSheet.notes ? billingSheet.notes : gridHeadersLabels.noData }}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="invoice">
          <th
            id="rfi-billing-sheet-invoice-folio-column"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ gridHeadersLabels.invoiceFolio }}
          </th>
          <td
            mat-cell
            *matCellDef="let billingSheet">
            {{ billingSheet.invoice ? billingSheet.invoice : gridHeadersLabels.noData }}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="invoiceNotes">
          <th
            id="rfi-billing-sheet-invoice-notes-column"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ gridHeadersLabels.invoiceNotes }}
          </th>
          <td
            mat-cell
            *matCellDef="let billingSheet">
            {{ billingSheet.invoiceNotes ? billingSheet.invoiceNotes : gridHeadersLabels.noData }}
          </td>
        </ng-container>

        <ng-container matColumnDef="invoiceDate">
          <th id="rfi-billing-sheet-invoice-creation-date-column" mat-header-cell *matHeaderCellDef class="text-column">
            {{ gridHeadersLabels.invoiceCreationDate }}
          </th>
          <td mat-cell *matCellDef="let billingSheet">
            {{ billingSheet.invoiceDateView ? billingSheet.invoiceDateView : gridHeadersLabels.noData }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th
            id="rfi-billing-sheet-status-column"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ gridHeadersLabels.status }}
          </th>
          <td
            mat-cell
            *matCellDef="let billingSheet">
            {{ getLabelStatus(billingSheet.status) }}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="subTotal">
          <th
            id="rfi-billing-sheet-subtotal-column"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ gridHeadersLabels.subTotal }}
          </th>
          <td
            mat-cell
            *matCellDef="let billingSheet">
            {{ moneySign }} {{ billingSheet.subTotal | number: numberDigits: numberFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="vat">
          <th
            id="rfi-billing-sheet-vat-column"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ gridHeadersLabels.vat }}
          </th>
          <td
            mat-cell
            *matCellDef="let billingSheet">
            {{ moneySign }} {{ billingSheet.vat | number: numberDigits: numberFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th
            id="rfi-billing-sheet-total-column"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ gridHeadersLabels.total }}
          </th>
          <td
            mat-cell
            *matCellDef="let billingSheet">
            {{ moneySign }} {{ billingSheet.total | number: numberDigits: numberFormat }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns">
        </tr>
        <tr
          mat-row
          *matRowDef="let row;
          columns: displayedColumns;">
        </tr>
    </table>
  </div>
</div>
