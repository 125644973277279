import { Pipe, PipeTransform } from '@angular/core';
import { LanguageTranslateService } from '../services/translate/language-translate.service';

@Pipe({
    name: 'boolToWord'
})
export class BoolToWordPipe implements PipeTransform {
  constructor(
    private _languageTranslateService: LanguageTranslateService
  ) { }

  transform(value: boolean): string {
    const language = this._languageTranslateService.getLanguage();
    return value ? (language === 'es' ? 'Sí' : 'Yes') : 'No';
  }

}
