import { Injectable } from '@angular/core';

import { isNil as _isNil } from 'lodash';

import { AppConstants } from '../../constants/app-constants.constants';
import { ChargeInfo, ShipmentInfo, SupplierInfo } from '../../interfaces/review-payment-supplier';
import { GenericRegexp } from '../../../app/regexp/generic.regexp';
import { ShipmentCostCharge, ShipmentCostChargeCapture } from '../../interfaces/shipmentCostCharges';
import { VariableCharges } from '../../enums/variable-charges';

/**
 * @description A utility service that provides methods for calculating payments for suppliers.
 */
@Injectable()
export class ReviewPaymentSupplierService {
  constructor() {}

  /**
   * @description Checks if two charges are equal.
   * @param {ShipmentCostCharge} currentCharge - The current charge being compared.
   * @param {ShipmentCostCharge} chargeModified - The modified charge being compared.
   * @returns {boolean} - Returns true if the charges are equal, false otherwise.
   */
  public isChargeEqual(currentCharge: ShipmentCostCharge, chargeModified: ShipmentCostCharge): boolean {
    return currentCharge.amount === chargeModified.amount &&
      currentCharge.supplierId === chargeModified.supplierId &&
      currentCharge.chargeId === chargeModified.chargeId &&
      currentCharge.accountId === chargeModified.accountId;
  }

  /**
   * @description Converts the given ShipmentCostCharge object to a simplified version.
   * @param {ShipmentCostCharge} data - The ShipmentCostCharge object to be simplified.
   * @returns {ShipmentCostCharge} - Returns a simplified version of the provided ShipmentCostCharge object.
   */
  public convertToSimpleCharge(data: ShipmentCostCharge): ShipmentCostCharge {
    const item: ShipmentCostCharge = {} as ShipmentCostCharge;

    if (data.id) {
      item.id = data.id;
    }

    item.amount = data.amount;
    item.supplierId = data.supplierId;
    item.supplierName = data.supplierName;
    item.chargeId = data.chargeId;
    item.chargeName = data.chargeName;
    item.accountId = data?.accountId || undefined;
    item.accountName = data?.accountName || undefined;

    return item;
  }

  /**
   * @description Checks if a charge exists in the list, excluding the specified index.
   * @param {Charge} newItem - The charge to check.
   * @param {number} excludeIndex - The index to exclude from the check.
   * @returns {boolean} - True if the charge exists, false otherwise.
   */
  public existsCharge(charges: Array<ShipmentCostCharge>, newItem: ShipmentCostCharge, excludeIndex?: number): boolean {
    if (_isNil(excludeIndex)) {
      excludeIndex = AppConstants.INVALID_INDEX;
    }

    return charges.some((item: ShipmentCostCharge, index: number) => {
      return index !== excludeIndex &&
        item.supplierId === newItem.supplierId &&
        item.chargeId === newItem.chargeId &&
        item.accountId === newItem.accountId;
    });
  }

  /**
   * @description Finds an item from the array of charges.
   * @param {Array<ShipmentCostCharge>} charges - The array of ShipmentCostCharge objects.
   * @param {ShipmentCostCharge} itemToFind - The ShipmentCostCharge object to find in the charges array.
   * @returns {object} An object containing the index of the found item and the item.
   */
  public findItemFromIndex(charges: Array<ShipmentCostCharge>, itemToFind: ShipmentCostCharge): object {
    const index = charges.findIndex((item: ShipmentCostCharge) => {
      return item.supplierId === itemToFind.supplierId &&
        item.chargeId === itemToFind.chargeId &&
        item.accountId === itemToFind.accountId;
    });

    if (index !== AppConstants.INVALID_INDEX) {
      return {
        index,
        charge: charges[index]
      };
    }

    return {
      index,
      charge: undefined
    };
  }

  /**
   * @description Retrieves all charges from the given shipment information.
   * @param {ShipmentInfo} shipmentInfo - The shipment information containing the charges.
   * @param {boolean} isSimple - Flag indicating whether to convert charges to a simple format.
   * @returns {Array<ShipmentCostCharge>} - Returns an array of shipment cost charges.
   */
  public getAllChargesFromShipment(shipmentInfo: ShipmentInfo, isSimple?: boolean): Array<ShipmentCostCharge> {
    const charges = [];

    for (const chargeInfo of shipmentInfo.charges) {
      const _charges = chargeInfo.shipmentCostCharges.map((item: ShipmentCostCharge) => {
        return isSimple ? this.convertToSimpleCharge(item) : item;
      });
      charges.push(..._charges);
    }

    return charges;
  }

  /**
   * @description Calculates the total freight cost from an array of shipment cost charges.
   * Only includes charges with a name matching 'Freight' or marked as 'isFreight'.
   * @param {Array<ShipmentCostCharge>} charges - The array of shipment cost charges.
   * @returns {number} - The total freight cost of the charges.
   */
  public getFreightCostTotal(charges: Array<ShipmentCostCharge>): number {
    let total: number = AppConstants.ZERO;

    for (const charge of charges) {
      let value = AppConstants.ZERO;

      if (charge.chargeName.toLocaleLowerCase() === VariableCharges.Freight.valueOf().toLocaleLowerCase() || charge['isFreight']) {
        value = charge?.amount || AppConstants.ZERO;
      }

      total += value;
    }

    return total;
  }

  /**
   * @description Extracts and returns an array of suppliers from an array of charges.
   * @param {Array<ChargeInfo>} charges - The array of charge objects.
   * @returns {Array<SupplierInfo>} - The array of supplier objects extracted from the charges.
   */
  public getSuppliersFromCharges(charges: Array<ChargeInfo>): Array<SupplierInfo> {
    const suppliers: Array<SupplierInfo> = [];

    for (const charge of charges) {
      suppliers.push(charge.supplierInfo);
    }

    return suppliers;
  }

  /**
   * @description Calculates the total amount from an array of shipment cost charges.
   * @param {Array<ShipmentCostCharge>} charges - The array of shipment cost charges.
   * @returns {number} - The total amount of the charges.
   */
  public getTotal(charges: Array<ShipmentCostCharge>): number {
    let total: number = AppConstants.ZERO;

    for (const charge of charges) {
      total += charge?.amount || AppConstants.ZERO;
    }

    return total;
  }

  /**
   * @description Checks the value is a negative.
   * @param {number} value - The value to check.
   * @returns {boolean} - Return true if the value is negative; otherwise return false.
   */
  public isNegative(value: number): boolean {
    return value < AppConstants.ZERO;
  }

  /**
   * @description Verifies if a given amount is invalid based on the provided minimum and maximum values.
   * @param {number} value - The amount to be validated.
   * @param {number} minValue - The minimum allowed value.
   * @param {number} maxValue - The maximum allowed value.
   * @returns {boolean} - Returns true if the amount is invalid; otherwise, returns false.
   */
  public isInvalidAmount(value: number, minValue: number, maxValue: number): boolean {
    const valueStr: string = (value?.toString() || AppConstants.EMPTY_STRING).trim();

    if (value >= minValue && value <= maxValue && GenericRegexp.NUMBER_WITH_TWO_DECIMALS_POINTS.test(valueStr)) {
      return false;
    }

    return true;
  }

  /**
   * @description Checks if the modified charge is valid for edition.
   * @param {Array<ShipmentCostCharge>} charges - The list of existing charges.
   * @param {ShipmentCostCharge} currentCharge - The current charge being modified.
   * @param {ShipmentCostCharge} chargeModified - The modified charge to be validated.
   * @returns {boolean} - Returns true if the charge is valid for edition, false otherwise.
   */
  public isValidEdition(charges: Array<ShipmentCostCharge>, currentCharge: ShipmentCostCharge, chargeModified: ShipmentCostCharge): boolean {
    const item = this.findItemFromIndex(charges, currentCharge);

    if (item['index'] !== AppConstants.INVALID_INDEX) {

      if (this.isChargeEqual(currentCharge, chargeModified)) {
        return false;
      }

      if (this.existsCharge(charges, chargeModified, item['index'])) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * @description Checks if the charge is invalid to save.
   * @param {ShipmentCostChargeCapture} newCharge - The new charge to be validated.
   * @param {ShipmentInfo} shipmentInfo - The shipment information.
   * @param {ShipmentCostCharge} currentCharge - The current charge being modified.
   * @returns {boolean} - Returns true if the charge is invalid to save, false otherwise.
   */
  public isInvalidChargeToSave(newCharge: ShipmentCostChargeCapture, shipmentInfo: ShipmentInfo, currentCharge: ShipmentCostCharge): boolean {
    const charges: Array<ShipmentCostCharge> = this.getAllChargesFromShipment(shipmentInfo, true);

    if (!currentCharge) {
      return this.existsCharge(charges, newCharge);
    }

    return this.isValidEdition(charges, currentCharge, newCharge);
  }

  /**
   * @description Toggles the checked state of a shipment cost charge row.
   * @param {ShipmentCostCharge} row - The row to be toggled.
   * @param {boolean} valueChecked - The boolean value indicating whether the row should be checked or not.
   */
  public toggleRowChecked(row: ShipmentCostCharge, valueChecked: boolean): void {
    if (valueChecked) {
      row['_checked'] = valueChecked;
    } else {
      delete row['_checked'];
    }
  }
}
