export enum DialogPrivateCarrierTags {
  back = 'Regresar',
  cancel = 'Cancelar',
  cancelEditDialogQuestion = '¿Estás seguro que deseas salir de la edición de los transportistas del embarcador?',
  cancelEditDialogResume = 'Los transportistas agregados o eliminados al embarcador no serán guardados.',
  cancelEditDialogTitle = 'Edición de transportistas del embarcador',
  carriersComboTitle = 'Líneas de transporte:',
  clearSelection = 'Limpiar selección',
  countSelected = 'Seleccionado(s)',
  errorMessage = 'Ocurrió un error al actualizar el embarcador',
  selectAll = 'Seleccionar todos',
  selectAnOption = 'Selecciona una línea de transporte',
  successMessage = 'Se actualizó correctamente a ',
  title = 'Transportistas del embarcador',
  update = 'Actualizar'
}
