import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ScfGridColumn } from 'scf-library';

import { DialogBulkLoadErrors, ErrorData } from '../../../interfaces/dialog-bulk-load-errors';
import { CATALOG_CONFIG_CONSTANTS } from '../../../constants/catalogs.constants';
import { COLUMNS_FIELDS } from './dialog-bulk-load-errors.constants';
import { DialogStandardFocus } from './../../../enums/dialog-stantdard-focus';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';

@Component({
  selector: 'app-dialog-bulk-load-errors',
  styleUrls: ['./dialog-bulk-load-errors.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-bulk-load-errors.component.html',
})

/**
 * Class to display provided errors from excel bulk load.
 */
export class DialogBulkLoadErrorsComponent implements OnInit {
  @ViewChild('excelRows', { static: true }) excelRows: TemplateRef<any>;

  public errorsData: Array<ErrorData>;
  public gridColumns: Array<ScfGridColumn>;
  public isScfGridReady: boolean;
  public scfGridConfig: object;

  /**
   * @description Builds an instance of all the services necessary for this component to work correctly.
   * @param {DialogBulkLoadErrors} data - Data provided from current excel bulk load.
   * @param {MatDialogRef<DialogChargeValidationComponent>} dialogRef - Reference to opened dialog.
   * @param {LanguageTranslateService} languageTranslateService - Service to get labels in current language.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogBulkLoadErrors,
    public dialogRef: MatDialogRef<DialogBulkLoadErrorsComponent>,
    public languageTranslateService: LanguageTranslateService
  ) { }

  /**
   * @description Angular lifecycle for component initialization.
   */
  public ngOnInit(): void {
    this.errorsData = this.data.errorsData;
    this.buildSCFGrid();
  }

  /**
   * @description Closes dialog when user do clic in back button.
   */
  public onClickBackButon(): void {
    this.dialogRef.close(DialogStandardFocus.CLOSED);
  }

  /**
   * @description Closes dialog when user do clic in load file button
   * to retry upload info.
   */
  public onClickLoadButton(): void {
    this.dialogRef.close(DialogStandardFocus.CONFIRM);
  }

  /**
   * @description Builds SCF grid to show provided errors.
   */
  private async buildSCFGrid(): Promise<void> {
    this.isScfGridReady = false;
    this.scfGridConfig = {
      columnToggler: false,
      pageSize: CATALOG_CONFIG_CONSTANTS.DEFAULT_PAGE_SIZE,
      pageSizeOptions: CATALOG_CONFIG_CONSTANTS.PAGE_SIZE_OPTIONS,
      rowDraggable: false,
      selectable: false
    };

    const dialogBulkLoadErrorsLabels =
      await this.languageTranslateService.getLanguageLabels(LanguageConstants.DIALOG_BULK_LOAD_ERRORS_LABELS);
  
    this.gridColumns = [
      { field: COLUMNS_FIELDS.errorType, header: dialogBulkLoadErrorsLabels.errorTypeHeader },
      { field: COLUMNS_FIELDS.excelRows, header: dialogBulkLoadErrorsLabels.rowsHeader, columnTemplate: this.excelRows }
    ];

    this.isScfGridReady = true;
  }
}
