import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BlobProvider } from './blob-provider.service';
import { environment } from '../../../environments/environment';
import {
  EvidenceCreateResponse,
  EvidenceLog,
  EvidenceReleaseFolioBody,
  EvidenceReleaseFolioResponse,
  EvidenceRequest,
  EvidenceResponse,
  Evidences,
  EvidencesResponse,
  OrderEvidence,
  OrdersEvidence,
  OrdersIncidence,
  SearchReleaseFolioParams
} from '../../interfaces/evidence';
import { File } from 'src/app/interfaces/file';
import { CustomEvidenceFolio } from 'src/app/interfaces/customEvidenceFolio';

const apiUrl = environment.apiUrl;
const evidenceApiUrl = environment.evidenceApiUrl;
const trackingUrl = environment.trackingUrl;

@Injectable()
export class EvidenceProvider {

  constructor(
    private http: HttpClient,
    private blobService: BlobProvider
  ) { }

  public async saveEvidenceFile(file: Object, newName: string, extension: string): Promise<string> {
    return await this.blobService.saveBlob(file, newName, extension);
  }

  public async newEvidence(evidenceData: object): Promise<object> {
    return await this.http.post(evidenceApiUrl + '/', evidenceData).toPromise();
  }

  public async newEvidenceLog(log: EvidenceLog): Promise<object> {
    return await this.http.post(evidenceApiUrl + '/log', log).toPromise();
  }

  /**
   * @description adds and saves evidences for tshipment
   * @param {string} shipmentFolio folio of shipment where the evidence will be saved
   * @param {string} evidenceId id of evidence
   * @param {Array<OrdersEvidence>} orderEvidence data with all info of evidence to save
   * @returns {Promise<object>} response of endpoint
   */
  public async addOrderEvidence(shipmentFolio: string, evidenceId: string, orderEvidence: Array<OrdersEvidence> | object): Promise<object> {
    return await this.http.put(evidenceApiUrl + '/shipment/' + shipmentFolio + '/evidence/' + evidenceId, orderEvidence).toPromise();
  }

  /**
   * @description updates an evidence data of the order specified
   * @param {string} shipmentFolio folio where the order belongs
   * @param {string} evidenceId ID of evidence
   * @param {string} orderId ID of the order to update the evidence
   * @param {Array<OrdersEvidence>} orderEvidence New data of evidence to save
   * @returns {Promise<object>} response of endpoint
   */
  public async updateOrderEvidence(shipmentFolio: string, evidenceId: string, orderId: string,
    orderEvidence: Array<OrdersEvidence> | object): Promise<object> {
    const url = evidenceApiUrl + '/shipment/' + shipmentFolio + '/evidence/' + evidenceId + '/orderId/' + orderId;
    return await this.http.put(url, orderEvidence).toPromise();
  }

  public async updateFileNames(shipmentFolio: string, evidenceId: string, orderId: string, fileNames: Array<string>): Promise<object> {
    const url = evidenceApiUrl + '/shipment/' + shipmentFolio + '/evidence/' + evidenceId + '/orderId/' + orderId;
    return await this.http.post(url, fileNames).toPromise();
  }

  public async insertFileNames(shipmentFolio: string, orderId: string, fileNames: Array<string>): Promise<object> {
    const url = evidenceApiUrl + '/shipment/' + shipmentFolio + '/orderId/' + orderId;
    return await this.http.post(url, fileNames).toPromise();
  }

  public async getByShipment(params: object): Promise<EvidenceResponse> {
    return await this.http.post(evidenceApiUrl + '/search', params).toPromise();
  }

  /**
   * @description Get evidences by shipments and tenant id
   * @param {EvidenceRequest} params object to send with criteria of search
   * @returns {EvidencesResponse} elements found
   */
  public async getByShipments(params: EvidenceRequest): Promise<EvidencesResponse> {
    return await this.http.post<EvidencesResponse>(evidenceApiUrl + '/search', params).toPromise();
  }

  public async getByOids(imgId: Object): Promise<File> {
    return this.http.post<File>(apiUrl + '/solicitudes/detalles/evidencesFile/byOids', imgId).toPromise();
  }

  public async getReleaseFolio(folio: string): Promise<EvidenceReleaseFolioResponse> {
    return this.http.get<EvidenceReleaseFolioResponse>(apiUrl + '/releaseFolio/' + folio).toPromise();
  }

  /**
   * @description Get evidences identifiers by date range
   * @param {string} tenantId The client identifier
   * @param {string} beginDate Start date
   * @param {string} endDate End date
   * @returns {EvidenceResponse} Matched evidences
   */
  public async getEvidencesByDate(tenantId: string, beginDate, endDate): Promise<Array<OrderEvidence>> {
    return await this.http.get<Array<OrderEvidence>>(
      evidenceApiUrl + '/tenant/' + tenantId + '/beginDate/' + beginDate + '/endDate/' + endDate)
      .toPromise();
  }

  public async getReleaseFolioNumber(body: SearchReleaseFolioParams): Promise<number> {
    return this.http.post<number>(apiUrl + '/releaseFolios', body).toPromise();
  }

  public async getReleaseFolioByParams(body: SearchReleaseFolioParams): Promise<Array<EvidenceReleaseFolioResponse>> {
    return this.http.post<Array<EvidenceReleaseFolioResponse>>(apiUrl + '/releaseFolios', body).toPromise();
  }

  /**
   * @description Creates release folio for the orders released.
   * @param { EvidenceReleaseFolioBody } body - Release folio params to release orders.
   * @param { string } username - Username from the user has released the orders.
   * @returns { EvidenceCreateResponse } - A success response for release orders and folio created.
   */
  public async createReleaseFolio(body: EvidenceReleaseFolioBody, username: string): Promise<EvidenceCreateResponse> {
    return this.http.post<EvidenceCreateResponse>(apiUrl + '/createReleaseFolio' + '?user=' + username, body).toPromise();
  }

  /**
   * @description get evidences Files by Object Ids
   * @param ids Object with array of Files object Ids
   * @returns {Array<File>} Array of files
   */
  public async getFilesByOids(ids: Object): Promise<Array<File>> {
    const path = '/solicitudes/detalles/evidencesFile/byOids';
    return await this.http.post<Array<File>>(`${apiUrl}${path}`, ids).toPromise();
  }

  public setEvidenceStatus(shipmentRequestOid: string, detailId: string, evidenceId: string, payload: Object): Promise<any> {
    return this.http.put<any>(trackingUrl + '/shipmentRequests/' + shipmentRequestOid +
      '/details/' + detailId + '/validateEvidence/' + evidenceId, payload).toPromise();
  }

  public async notifyEvidenceRejection(shipmentRequestOid: string, reason: string, tenantId: string): Promise<object> {
    return this.http.post<object>(apiUrl + '/shipment-request/tenant/' + tenantId + '/notify/' + shipmentRequestOid,
      { reason: reason }).toPromise();
  }

  public async evidenceValidate(ordersEvidenceId: Object): Promise<any> {
    return this.http.post<any>(apiUrl + '/documentoSalida/evidenceValidate', ordersEvidenceId).toPromise();
  }

  public async updateEvidenceStatus(shipmentFolio: string, updateBody: object): Promise<object> {
    return await this.http.patch(evidenceApiUrl + '/shipment/' + shipmentFolio + '/evidence', updateBody).toPromise();
  }

  public async addOrderIncidence(tenantId: string, shipmentFolio: string, orderIncidence: any): Promise<object> {
    return await this.http.post(`${evidenceApiUrl}/tenant/${tenantId}/shipment/${shipmentFolio}/incidence/order`,
      orderIncidence).toPromise();
  }

  public async addShipmentIncidence(tenantId: string, shipmentFolio: string, incidence: any): Promise<object> {
    return await this.http.post(`${evidenceApiUrl}/tenant/${tenantId}/shipment/${shipmentFolio}/incidence`,
      incidence).toPromise();
  }

  /**
   * @description Update an order's incidence
   * @param shipmentFolio Shipment folio that includes order incidence
   * @param incidenceId Order incidence Object Id
   * @param orderId Order name
   * @param ordersIncidence Order incidence body.
   */
  public async updateOrderIncidence(shipmentFolio: string, incidenceId: string, orderId: string,
    orderIncidence: OrdersIncidence): Promise<object> {
    const url = evidenceApiUrl + '/shipment/' + shipmentFolio + '/incidence/' + incidenceId + '/orderId/' + orderId;
    return await this.http.put(url, orderIncidence).toPromise();
  }

  /**
   * @description Update array of files of an order's incidence
   * @param shipmentFolio Shipment folio that includes order incidence
   * @param incidenceId Order incidence Object Id
   * @param orderId Order name
   * @param fileNames Array of strings names of new files
   */
  public async updateOrderIncidenceFileNames(shipmentFolio: string,
    incidenceId: string, orderId: string, fileNames: Array<string>): Promise<object> {
    const url = evidenceApiUrl + '/shipment/' + shipmentFolio + '/incidence/' + incidenceId + '/orderId/' + orderId;
    return await this.http.post(url, fileNames).toPromise();
  }

  /**
   * @description Get evidence's log associated to the order's identifier
   * @param orderId Order's identifier to get log
   * @returns Evidence's log found
   */
  public async getEvidenceLogByOrderFolio(orderId: string): Promise<any> {
    return await this.http.get(`${evidenceApiUrl}/log?orderId=${orderId}`).toPromise();
  }

  /**
   * @description Create new evidence folio
   * @param {string} shipperOid Shipper identifier
   * @param {EvidenceReleaseFolioBody} body Evidence folio to be created
   * @returns {Promise<EvidenceCreateResponse>} Created evidence folio
   */
  public async createEvidencesFolio(shipperOid: string, body: EvidenceReleaseFolioBody): Promise<EvidenceCreateResponse> {
    return this.http.post<EvidenceCreateResponse>(apiUrl + `/shipperOid/${shipperOid}/evidencesFolio`, body).toPromise();
  }

  /**
   * @description Update Evidences status by shipment folio
   * @param {string} shipmentFolio Shipment folio
   * @param {object} updateBody Evidences to updated
   * @returns {Promise<object>} Updated evidence
   */
  public async updateEvidenceStatusFolio(shipmentFolio: string, updateBody: object): Promise<object> {
    return await this.http.put(evidenceApiUrl + '/shipment/' + shipmentFolio + '/evidence', updateBody).toPromise();
  }

  /**
   * @description Get evidences folios by order folios
   * @param {string} shipperOid Shipper identifier
   * @param {Array<string>} orderFolios Order folios to find
   * @returns {Promise<Array<CustomEvidenceFolio>>} Evidence folios
   */
  public async getEvidenceFoliosByOrders(shipperOid: string, orderFolios: Array<string>): Promise<Array<CustomEvidenceFolio>> {
    const payload = { orders: orderFolios };
    const path = `${apiUrl}/shipperOid/${shipperOid}/evidencesFolioByOrders`;
    return await this.http.post<Array<CustomEvidenceFolio>>(path, payload).toPromise();
  }

  /**
   * @description Init process to send ARE Date interface
   * @param {Array<Object>} ordersObject Order identifier and invoice
   * @param {string} shipperOid Shipper Object Identifier
   * @returns ARE Date Sent
   */
  public async postAreDate(ordersObject: Array<object>, shipperOid: string): Promise<object> {
    const payload = { orders: ordersObject, isAreDate: true };
    const path = `${apiUrl}/tenant/${shipperOid}/order/are-date`;
    return await this.http.post<Object>(path, payload).toPromise();
  }

  public async getEvidencesByOids(body: Array<string>): Promise<any> {
    return this.http.post<Array<Evidences>>(evidenceApiUrl + '/evidencesByOids', body).toPromise();
  }
}
