import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LanguageChangeEventService } from '../../services/translate/language-change-event.service';
import { LanguageTranslateService } from '../../services/translate/language-translate.service';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-no-info',
  templateUrl: './no-info.component.html',
  styleUrls: ['./no-info.component.scss']
})
export class NoInfoComponent implements OnInit, OnDestroy {
  @Input() title;
  @Input() message;

  public languageLabels: any;
  public languageSuscription: Subscription;

  constructor(
    private toast: ToastrAlertsService,
    public _languageChangeEventService: LanguageChangeEventService,
    public _languageTranslateService: LanguageTranslateService,
  ) {
    this.setLanguage();
  }

  /**
   * @description Fires when initialize component
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
  }

  /**
   * @description Angular destroy lifecycle
   */
  public ngOnDestroy(): void {
    this.languageSuscription?.unsubscribe();
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
      },
      (error) => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels('languageLabels')
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }
}
