import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { StarRatingLabel } from './star-rating.label';
import { StarRatingTags } from '../../../interfaces/star-rating';
import { STAR_RATE_CONSTANTS } from '../../../constants/rate-star/star-rate.constants';
@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class StarRatingComponent implements OnInit {
  public label: StarRatingTags;
  public ratingArr: Array<number>;

  @Input() public color: string;
  @Input() public optionSelected: number;
  @Input() public rating: number;
  @Input() public starCount: number;
  @Output() public ratingUpdated = new EventEmitter();

  constructor() {
    this.color = STAR_RATE_CONSTANTS.ACCENT;
    this.label = StarRatingLabel;
  }

  /**
   * @description Angular lifecycle for component initialization
   */
  public ngOnInit(): void {
    this.ratingArr = [];
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }

  /**
   * @description show the corresponding stars
   * @param {number} index the star's index
   * @return {string} a filled or border star
   */
  public showIcon(index: number): string {
    if (this.rating >= index + 1) {
      return STAR_RATE_CONSTANTS.STAR;
    } else {
      return STAR_RATE_CONSTANTS.STAR_BORDER;
    }
  }
}
