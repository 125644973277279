import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AppService } from '../../../app.service';
import { Detail, Driver, Shipments, ShipmentWithRequestData, Tracking, Vehicle } from '../../../interfaces';
import { DialogAssignTransportLabels, IDialogAssignTransportLabels } from './dialog-assign-transport-labels';
import { DriverProvider } from '../../../providers/driver/driver.provider.service';
import { ShipmentProvider } from '../../../providers/shipments/shipment-provider.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';
import { TrackingService } from '../../../providers/tracking/tracking.service';
import { VehicleProvider } from '../../../providers/vehicles/vehicles.service';

import { GeoCoord, HaversineService } from 'ng2-haversine';

const ASSIGNED_STATUS = 'Asignado';
const CONFIRM = 'confirm';
const CANCEL = 'cancel';
const IN_TRANSIT = 'En Transito';

@Component({
  selector: 'app-dialog-assign-transport',
  templateUrl: './dialog-assign-transport.component.html',
  styleUrls: ['./dialog-assign-transport.component.scss', '../../../app.component.scss']
})
export class DialogAssignTransportComponent implements OnInit {
  public allowAssign: boolean;
  public assignTransport: UntypedFormGroup;
  private carrierOid: string;
  public driver: Driver;
  public drivers: Array<Driver>;
  public inTransit: boolean;
  public labels: IDialogAssignTransportLabels;
  public lastDetail: Detail;
  public shipment: ShipmentWithRequestData;
  public traveledRoute: string;
  private username: string;
  public vehicle: Vehicle;
  public vehicles: Array<Vehicle>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private appService: AppService,
    private dialogRef: MatDialogRef<DialogAssignTransportComponent>,
    private driverProvider: DriverProvider,
    private readonly formBuilder: UntypedFormBuilder,
    private haversineService: HaversineService,
    private router: Router,
    private shipmentProvider: ShipmentProvider,
    private toastrService: ToastrAlertsService,
    private trackingService: TrackingService,
    private vehicleProvider: VehicleProvider
  ) {
    this.allowAssign = data.allowAssign;
    this.labels = DialogAssignTransportLabels;
    this.shipment = data.shipment;
  }

  /**
   * @description Fires when initialize component
   */
  public async ngOnInit(): Promise<void> {
    this.getSessionInfo();
    this.initTransportForm(this.formBuilder);
    this.getVehiclesAndDrivers();
    this.inTransit = this.shipment.status === IN_TRANSIT ? true : false;
    if (this.inTransit) {
      this.shipment.tracking = await this.getTrackingOfShipmentRequest(this.shipment);
      this.getTravelRoute();
    }
  }

  /**
   * @description Initialize Transport form
   */
  public initTransportForm(fb: UntypedFormBuilder): void {
    this.assignTransport = fb.group({
      vehicle: new UntypedFormControl(null),
      driver: new UntypedFormControl(null)
    });
  }

  /**
   * @description Set values to transport form
   */
  public setValuesForm(): void {
    const transport = this.shipment.transport;

    if (transport.plates || transport.driver) {
      const driver = this.shipment.transport.driver;
      const plates = this.shipment.transport.plates;

      const vehicleExist = this.vehicles.find(element => element.placas === plates);
      const driverExist = this.drivers.find(element => `${element.nombre} ${element.appaterno} ${element.apmaterno}` === driver);

      this.driver = driverExist;
      this.vehicle = vehicleExist;
    } else {
      this.driver = null;
      this.vehicle = null;
    }

    this.assignTransport.controls['vehicle'].setValue(this.vehicle ? this.vehicle._id : null);
    this.assignTransport.controls['driver'].setValue(this.driver ? this.driver._id : null);
  }

  /**
   * @description Get vehicle data of selected vehicle
   * @returns {Vehicle} Vehicle data
   */
  private getVehicle(): Vehicle {
    const vehicleId = this.assignTransport.value.vehicle;
    const vehicle = this.vehicles.find(element => element._id === vehicleId);
    return vehicle;
  }

  /**
   * @description Get Driver data of selected driver
   * @param {string} driverOid Driver Object Id
   * @returns {Driver} Driver data
   */
  private getDriver(driverOid: string): Driver {
    const driver = this.drivers.find(element => element._id === driverOid);
    return driver;
  }

  /**
   * @description Get CarrierOid and username of session
   */
  public getSessionInfo(): void {
    this.carrierOid = this.appService.getCarrier();
    this.username = this.appService.getShipperNameCookie();
  }

  /**
   * @description Get vehicles and drivers of Carrier and set values to form
   */
  public async getVehiclesAndDrivers(): Promise<void> {
    this.vehicles = await this.vehicleProvider.getCarrierVehicles(this.carrierOid);
    this.drivers = await this.driverProvider.getDriversByCarrierId(this.carrierOid);

    this.setValuesForm();
  }

  /**
   * @description Event fires when click on cancel button
   */
  public async onCancelClick(): Promise<void> {
    this.dialogRef.close(CANCEL);
  }

  /**
   * @description Get new status of this shipment
   * @returns {string} Status of shipment
   */
  public getNewShipmentStatus(): string {
    if (this.assignTransport.value.driver && this.assignTransport.value.vehicle) {
      return ASSIGNED_STATUS;
    } else {
      return this.shipment.status;
    }
  }

  /**
   * @description Event fires when click on send button
   */
  public async assignTransportToShipment(): Promise<void> {
    try {
      const status = this.getNewShipmentStatus();
      const selectedVehicle = this.getVehicle();
      const driver = this.getDriver(this.assignTransport.value.driver);

      const shipmentToUpdate: Shipments = {
        wepId: this.shipment.wepId,
        shipmentId: this.shipment.shipmentId,
        shipperId: this.shipment.shipperId,
        tenantId: this.shipment.shipperId,
        status: status,
        account: this.shipment.account,
        loadType: this.shipment.loadType,
        tripType: this.shipment.tripType,
        origin: this.shipment.origin,
        destination: this.shipment.destination,
        transport: {
          transportCarrier: this.shipment.transport.transportCarrier,
          vehicle: selectedVehicle ? selectedVehicle.tipo.nombre : null,
          plates: selectedVehicle ? selectedVehicle.placas : null,
          driver: driver ? `${driver.nombre} ${driver.appaterno} ${driver.apmaterno}` : null,
          helper: this.shipment.transport.helper,
          mobileDriver: driver ? driver.movil : null,
          assignByCarrier: true
        },
        specialRequirements: this.shipment.specialRequirements,
        auditor: this.shipment.auditor,
        maneuvers: this.shipment.maneuvers,
        observations: this.shipment.observations,
        internalReference: this.shipment.internalReference,
        hasAppoinmentDate: this.shipment.hasAppoinmentDate,
        beginDate: this.shipment.beginDate,
        endDate: this.shipment.endDate,
        loadStartDate: this.shipment.loadStartDate,
        loadEndDate: this.shipment.loadEndDate,
        suggestedTransport: this.shipment.suggestedTransport,
        pieces: this.shipment.pieces,
        boxes: this.shipment.boxes,
        pallets: this.shipment.pallets,
        volume: this.shipment.volume,
        weight: this.shipment.weight,
        isPartial: this.shipment.isPartial,
        validateWeightAllowed: this.shipment.validateWeightAllowed,
        distance: this.shipment.distance,
        rate: this.shipment.rate,
        isValidStop: this.shipment.isValidStop,
        waveId: this.shipment.waveId,
        doorLocationId: this.shipment.doorLocationId,
        secureNumber: this.shipment.secureNumber,
        warehouseId: this.shipment.warehouseId,
        userId: this.shipment.userId,
        lastChange: this.shipment.lastChange,
        isInterfaced: this.shipment.isInterfaced,
        orders: this.shipment.orders
      };

      await this.shipmentProvider.updateShipment(this.shipment.tenantId, this.shipment.shipmentId, shipmentToUpdate, this.username);

      this.toastrService.successAlert(this.labels.successAssign);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/shipment/carrier-shipments']));
    } catch (error) {
      this.toastrService.errorAlert(this.labels.errorAssign);
    } finally {
      this.dialogRef.close(CONFIRM);
    }
  }

  /**
   * @description Get tracking of shipment
   * @param { ShipmentWithRequestData } shipment Shipment Request
   * @returns { Pomise<Tracking> } Tracking of shipment
   */
  public async getTrackingOfShipmentRequest(shipment: ShipmentWithRequestData): Promise<Tracking> {
    let tracking: Tracking;
    tracking = await this.trackingService.getTracking(shipment.shipmentRequestOid);
    return tracking;
  }

  /**
  * @description Calculate traveled distance in kms
  **/
 public getTravelRoute(): void {
   const kilometers = ' km';
   const tracking = this.shipment.tracking;
    if (!tracking) { return; }
    const polyline = tracking.polyline;
    const polylineLength = polyline.length;
    let routeTravel = 0;
    polyline.forEach((value, index) => {
      if (index !== (polylineLength - 1)) {
        const origin: GeoCoord = { latitude: value.latitude, longitude: value.longitude };
        const destination: GeoCoord = { latitude: polyline[index + 1].latitude, longitude: polyline[index + 1].longitude };
        routeTravel = routeTravel + this.haversineService.getDistanceInKilometers(origin, destination);
      }
    });
    this.traveledRoute = routeTravel.toFixed(2) + kilometers;
  }

  /**
   * @description Close this dialog
   */
  public onClickClose(): void {
    this.dialogRef.close(CANCEL);
  }
}
