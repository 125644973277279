import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { SearsOrderPostDateRequest, SearsRequestBody, SearsResponse } from '../../interfaces/sears';

const apiUrl = environment.apiUrl;
const orderApiUrl = environment.orderApiUrl;

@Injectable({
  providedIn: 'root'
})
export class SearsProvider {
  constructor(
    private http: HttpClient
  ) {}

  /**
   * @description Generates the request to share the shipment data to external provider
   * @param {string} shipperId id of shipment
   * @param {SearsRequestBody} body Shipment data to shared
   * @return {Promise<SearsResponse>} response
   */
  public async sharedData(shipperId: string, body: SearsRequestBody): Promise<SearsResponse> {
    return await this.http.post<SearsResponse>(apiUrl + '/sharedLoadPlan/sears/' + shipperId, body).toPromise();
  }

  /**
   * @description Generate the request for getting the token by tenant valid
   * @param {string} shipperId shipper or tenant id
   * @return {Promise<SearsResponse>} response with valid data
   */
  public async getToken(shipperId: string): Promise<SearsResponse> {
    const url = apiUrl + '/shipper/' + shipperId + '/externalAuth?customerAPIid=sears';
    return await this.http.post<SearsResponse>(url, null).toPromise();
  }

  /**
   * @description Sends order data to Sears.
   * @param {string} shipperOId - Shipper Id's.
   * @param {SearsOrderPosDateRequest} body - Object wit order data will be shared.
   * @returns {Promise<SearsResponse>} Endpoint's response.
   */
  public async shareWithSearsDataOfOrder(shipperOId: string, body: SearsOrderPostDateRequest): Promise<SearsResponse> {
    return await this.http.post<SearsResponse>(
      `${orderApiUrl}/shipper/${shipperOId}/order/sears`, body
    ).toPromise();
  }
}
