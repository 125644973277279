export enum EvidencesTags {
  account = 'Cuenta',
  clientDeliveryDate = 'Fecha de entrega a cliente',
  courierGuide = 'Guía de paquetera',
  dateFolioCreation = 'Fecha de generación de folio',
  dateInRoute = 'Fecha en ruta',
  departureWareHouse = 'Bodega salida',
  destination = 'Destino',
  folioControl = 'Folio de control',
  identifier = 'Orden',
  loadType = 'Tipo de carga',
  municipality = 'Municipio',
  operator = 'Operador',
  orderCreationDate = 'Fecha creación orden',
  orderGrouper = 'Pedido',
  orderStatus = 'Estatus de la orden',
  originWareHouse = 'Bodega origen',
  plates = 'Placas',
  programDeliveryDate = 'Fecha entrega programada',
  route = 'Ruta',
  serviceType = 'Tipo de servicio',
  shipmentId = 'Folio de embarque',
  shipmentStatus = 'Estatus del embarque',
  state = 'Estado',
  transportLine = 'Línea de transporte',
  tripType = 'Tipo de viaje',
  vehicleType = 'Tipo de vehículo'
}

export enum EvidencesLabel {
  buttonTitleExportExcel = 'Exportar a Excel',
  excelDownloaded = '¡Listo! Tu archivo ha sido descargado correctamente.',
  infoNotFound = 'No se encontró información para el criterio ingresado, verifica los datos e intenta de nuevo',
  noData = 'Sin información',
  placeHolderSearchInTable = 'Buscar en la Tabla',
  titlePage = 'Reporte de evidencias',
  toastDownloadingExcel = 'Estamos procesando  la información',
  toastErrorGettingAccounts = 'Ha ocurrido un error al obtener las cuentas',
  toastSearching = 'Estamos buscando la información',
}
