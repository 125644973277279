export const ShipmentCardRequestProperties = {
  affiliateShipment: '../../assets/icons/modeList/Icon-affiliateShipment.svg',
  box: '../../../assets/icons/modeList/box.svg',
  group: '../../../assets/icons/group.svg',
  person: '../../assets/icons/modeList/person.svg',
  phone: '../../assets/icons/modeList/phone.svg',
  pin: '../../assets/icons/modeList/pin.svg',
  plate: '../../assets/icons/modeList/plates-2.svg',
  money: '../../assets/icons/modeList/attach_money_black.svg',
  number: '../../assets/icons/modeList/number.svg',
  search: '../../assets/search.svg',
  shipment: '../../assets/icons/modeList/newShipment.svg',
  tracing: '../../assets/icons/modeList/tracing.svg',
  vehicle: '../../assets/icons/modeList/vehicle.svg',
  weigth: '../../assets/icons/modeList/weight.svg',
};

export const ShipmentCardInfoProperties: IShipmentCardInfoProperties = {
  detailsDialogHeight: '610px',
  detailsDialogWidth: '700px',
  transportDialogMaxHeight: '650px',
  transportDialogWidth: '600px'
};

export interface IShipmentCardInfoProperties {
  detailsDialogHeight: string;
  detailsDialogWidth: string;
  transportDialogMaxHeight: string;
  transportDialogWidth: string;
}

export const VALID_STATUS_SHIPMENT_REQUEST_VIEW_OFFERS = [
  'creada',
  'contraoferta'
];

export const ShipmentRequestFormatDialogProperties = {
  width: '100%',
  height: '95%'
};
