export enum FreightRateColumns {
  account = 11,
  applyDate = 18,
  carrierId = 7,
  cost = 14,
  daysObjectiveEvidence = 13,
  destinationMunicipality = 6,
  destinationState = 5,
  expirationDate = 16,
  identifier = 0,
  newAmount = 17,
  originMunicipality = 4,
  originState = 3,
  rateType = 10,
  targetDaysDelivery = 12,
  temperature = 9,
  traveledKilometers = 15,
  truckTypeId = 8,
  zipCodeDestination = 2,
  zipCodeOrigin = 1
}
