<mat-expansion-panel
  (opened)="panelOpenState=true"
  (closed)="panelOpenState=false"
  [expanded]="false"
  class="guides-panel">
  
  <mat-expansion-panel-header collapsedHeight='2.5em' expandedHeight='2.5em'>
    <mat-panel-title>
      {{ 'orderMassiveLoadTags.expansionGuidesTitle' | translate }}
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-grid-list cols="4" rowHeight="1:.20">
    <mat-grid-tile>
      <mat-checkbox
        [disabled]="masterGuide"
        (change)="switchGuideValues()"
        [(ngModel)]="masterGuide"
        [ngModelOptions]="{standalone: true}"
        color="primary"
        class="checkbox__title">
        {{ 'orderMassiveLoadTags.checkboxMasterGuide' | translate }}
      </mat-checkbox>
    </mat-grid-tile>
    <mat-grid-tile colspan="3">
      <mat-checkbox
        [disabled]="whiteGuides"
        (change)="switchGuideValues(false)"
        [(ngModel)]="whiteGuides"
        [ngModelOptions]="{standalone: true}"
        color="primary"
        class="checkbox__title checkbox-long">
        {{ 'orderMassiveLoadTags.checkboxWhiteGuides' | translate }}
      </mat-checkbox>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-checkbox
        (change)="guidePieces = false; guidePallets = false; sendUnitCode();"
        [disabled]="!whiteGuides || guideBoxes"
        [(ngModel)]="guideBoxes"
        [ngModelOptions]="{standalone: true}"
        color="primary"
        class="checkbox__title">
        {{ 'orderMassiveLoadTags.guideBoxes' | translate }}
      </mat-checkbox>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-checkbox
        (change)="guideBoxes = false; guidePallets = false; sendUnitCode();"
        [disabled]="!whiteGuides || guidePieces"
        [(ngModel)]="guidePieces"
        [ngModelOptions]="{standalone: true}"
        color="primary"
        class="checkbox__title">
        {{ 'orderMassiveLoadTags.guidePieces' | translate }}
      </mat-checkbox>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-checkbox
        (change)="guidePieces = false; guideBoxes = false; sendUnitCode();"
        [disabled]="!whiteGuides || guidePallets"
        [(ngModel)]="guidePallets"
        [ngModelOptions]="{standalone: true}"
        color="primary"
        class="checkbox__title">
        {{ 'orderMassiveLoadTags.guidePallets' | translate }}
      </mat-checkbox>
    </mat-grid-tile>
    <mat-grid-tile>
    </mat-grid-tile>
  </mat-grid-list>

</mat-expansion-panel>