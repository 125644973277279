import { Injectable } from '@angular/core';

import {
  Alphabet,
  ExcelExternalProperties,
  FormatForCell,
  FormatWorkSheet,
  WorkSheet,
  WorkSheetCell,
  WorkSheetWithFormat
} from '../../interfaces/worksheet-excel';
import { GenericRegexp } from '../../regexp/generic.regexp';

import * as FileSaver from 'file-saver';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as moment from 'moment';
import _ from 'lodash';

const CODE = 65;
const DEFAULT_POSITION_IMAGE = {
  tl: { col: 8.2, row: 1.2 },
  br: { col: 11.8, row: 11.8 }
};
const DEFAULT_ROW_HEIGHT = 14.75;
const DEFAULT_COLUMN_WIDTH = 21;
const EXCEL_DATE_FORMAT = 'DDMMYYYY';
const EXCEL_DATETIME_FORMAT = 'HHmmss';
const EXCEL_DEFAULT_ROW_HEIGHT = '20';
const EXCEL_EXTENSION = '.xlsx';
const EXCEL_HORIZONTAL_ALIGN = 'center';
const EXCEL_MIN_WIDTH = '14';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_VERTICAL_ALIGN = 'middle';
const EXTENSION_IMAGE = 'png';
const EXTRA_VALUE = 1;
const FILE_NAME_SEPARATOR = '_';
const HEADER_FILL_BGCOLOR = '405A6B';
const HEADER_FILL_PATTERN = 'solid';
const HEADER_FILL_TYPE = 'pattern';
const HEADER_FONT_COLOR = 'ffffff';
const TOTAL_LETTERS_INDEX = 26;
const ZERO = 0;

@Injectable()
export class ExcelService {
  constructor() { }

  /**
   * @description Build columns headers besed on exceljs lib
   * @param headers string[] Columns headers
   */
  private buildExcelColumns (headers: string[]) {
    const excelHeaders = [];
    if (headers.length) {
      headers.forEach((header: string) => {
        const excelHeader = { header: header };
        excelHeaders.push(excelHeader);
      });
    } else {
      excelHeaders.push({ header: '' });
    }

    return excelHeaders;
  }

  /**
   * @description Export Excel file
   * @param worksheets Worksheet[] Worksheets to be exported
   * @param fileName string Excel file name to be exported
   */
  public exportAsExcelFile (worksheets: WorkSheet[], fileName: string, externalProperties?: ExcelExternalProperties ): void {
    if (worksheets.length) {
      const workbook = new Excel.Workbook();
      let minWidth;
      const alignmentDefault = {
        wrapText: false,
        vertical: EXCEL_VERTICAL_ALIGN,
        horizontal: EXCEL_HORIZONTAL_ALIGN
      };
      worksheets.forEach(ws => {
        const worksheet = workbook.addWorksheet(ws.title);
        worksheet.properties.defaultRowHeight = EXCEL_DEFAULT_ROW_HEIGHT;
        worksheet.columns = this.buildExcelColumns(ws.headers);

        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
          cell.fill = {
            type: HEADER_FILL_TYPE,
            pattern: HEADER_FILL_PATTERN,
            fgColor: { argb: HEADER_FILL_BGCOLOR }
          };
          cell.font = { color: { argb: HEADER_FONT_COLOR } };
        });
        worksheet.columns.forEach(column => {
          if (externalProperties) {
            minWidth = column.header.length < externalProperties.excelMinRowWidth ?
              externalProperties.excelMinRowWidth : column.header.length;
          } else {
            minWidth = column.header.length < EXCEL_MIN_WIDTH ? EXCEL_MIN_WIDTH : column.header.length;
          }
          column.width = minWidth;
          if (externalProperties?.columnAlignment) {
            alignmentDefault.horizontal = externalProperties.columnAlignment.horizontal;
            alignmentDefault.vertical = externalProperties.columnAlignment.vertical;
            alignmentDefault.wrapText = externalProperties.columnAlignment.wrapText;
          }
          column.alignment = alignmentDefault;
        });

        worksheet.addRows(ws.data);
      });
      if (externalProperties) {
        this.saveAsExcelFile(workbook, fileName, externalProperties.setFullName);
      } else {
        this.saveAsExcelFile(workbook, fileName);
      }
    }
  }

  /**
   * @description Create and dowload Excel file
   * @param workbook Excel Workbook
   * @param fileName File name to be exported
   */
  private saveAsExcelFile(workbook: any, fileName: string, setFullName?: boolean): void {
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      if (setFullName) {
        FileSaver.saveAs(blob, fileName + EXCEL_EXTENSION);
      } else {
        const _filename = fileName + FILE_NAME_SEPARATOR +
          moment().format(EXCEL_DATE_FORMAT) + FILE_NAME_SEPARATOR + moment().format(EXCEL_DATETIME_FORMAT) + EXCEL_EXTENSION;
        FileSaver.saveAs(blob, _filename);
      }
    });
  }

  /**
   * @description Export Excel file with a specific format
   * @param worksheets Worksheet[] Worksheets to be exported
   * @param fileName string Excel file name to be exported
   */
  public async exportExcelFileWithFormat(worksheets: WorkSheetWithFormat[],
      fileName: string,
      externalProperties?: ExcelExternalProperties
    ): Promise<void> {
    if (worksheets.length) {
      const workbook = new Excel.Workbook();
      for (const ws of worksheets) {
        if (ws.data.length) {
          const worksheet = workbook.addWorksheet(ws.title);
          let minWidth;
          const alignmentDefault = {
            wrapText: false,
            vertical: EXCEL_VERTICAL_ALIGN,
            horizontal: EXCEL_HORIZONTAL_ALIGN
          };
          worksheet.columns = this.buildExcelColumns(ws.headers);
          const headerRow = worksheet.getRow(1);
          headerRow.eachCell((cell) => {
            cell.fill = {
              type: HEADER_FILL_TYPE,
              pattern: HEADER_FILL_PATTERN,
              fgColor: { argb: HEADER_FILL_BGCOLOR }
            };
            cell.font = { color: { argb: HEADER_FONT_COLOR } };
          });
          worksheet.columns.forEach(column => {
            if (externalProperties) {
              minWidth = column.header.length < externalProperties.excelMinRowWidth ?
                externalProperties.excelMinRowWidth : column.header.length;
            } else {
              minWidth = column.header.length < EXCEL_MIN_WIDTH ? EXCEL_MIN_WIDTH : column.header.length;
            }
            column.width = minWidth;
            if (externalProperties?.columnAlignment) {
              alignmentDefault.horizontal = externalProperties.columnAlignment.horizontal;
              alignmentDefault.vertical = externalProperties.columnAlignment.vertical;
              alignmentDefault.wrapText = externalProperties.columnAlignment.wrapText;
            }
            column.alignment = alignmentDefault;
          });
          worksheet.addRows(ws.data);
          if (ws.image) {
            await this.setImage(workbook, worksheet, ws.image.url, EXTENSION_IMAGE);
          }
          if (ws.format?.length) {
            this.applyFormat(worksheet, ws.format);
          }
        }
      }

      if (externalProperties) {
        this.saveAsExcelFile(workbook, fileName, externalProperties.setFullName);
      } else {
        this.saveAsExcelFile(workbook, fileName);
      }
    }
  }

  /**
   * @description apply the styles and formats to the worksheet with array of formats
   * @param {any} workSheet for apply the styles
   * @returns {Array<FormatWorkSheet>} formats to apply
   */
  private applyFormat(workSheet: any, formats: Array<FormatWorkSheet>): void {
    try {
      if (!workSheet) { return; }

      if (!formats.length) { return; }

      for (const format of formats) {
        workSheet.eachRow((row, indexRow) => {
          row.eachCell((cell, indexCell) => {
            const rowColvalInit = this.getColAndRowFromString(format.init);
            const rowColvalEnd = this.getColAndRowFromString(format.end);
            this.setFormat(workSheet, format.format, rowColvalInit, rowColvalEnd);
          });
        });
      }
    } catch { }
  }

  /**
   * @description Get the equivalence of cell to Object that contains col,row value and address of cell
   * example: 'B1' to { col: 2, row: 1, label: 'B1' }
   * @param {string} valueOfCell value to 'convert'
   * @returns {WorkSheetCell} result of type WorkSheetCell
   */
  private getColAndRowFromString(valueOfCell: string): WorkSheetCell {
    const result: WorkSheetCell = {} as WorkSheetCell;
    try {
      const numberRow = valueOfCell.match(GenericRegexp.NUMBER)[0] ?? ZERO;
      for (let index = 0; index < valueOfCell.length; index++) {
        if (this.isLetter(valueOfCell[index])) {
          const alphabet = this.getAlphabets().find(letter => letter.value === valueOfCell[index]);
          result.col = alphabet?.key + EXTRA_VALUE;
          result.row = Number(numberRow);
          result.label = valueOfCell;
          break;
        }
      }

      return result;
    } catch {
      return result;
    }
  }

  /**
   * @description Verify if the string is number or not
   * @param {string} value to evaluate
   * @returns {boolean} result of evaluation
   */
  private isLetter(value: string): boolean {
    return value?.length === 1 && (value?.match(GenericRegexp.LETTER)[0] !== null);
  }

  /**
   * @description Build the array of alphabets
   * @returns {Promise<Alphabet>} array of alphabets
   */
  private getAlphabets(): Array<Alphabet> {
    const rasult: Array<Alphabet> = [];
    const numbers = Array.from(Array(TOTAL_LETTERS_INDEX)).map((e, index) => index + CODE);
    for (const valueNumber of numbers) {
      rasult.push({
        value: String.fromCharCode(valueNumber),
        key: (valueNumber - CODE)
      });
    }

    return rasult;
  }

  /**
   * @description Set the values of object formats to work sheet,
   * the values to set is basic, can be customized
   * @param {any} workSheet for apply the styles
   * @param {Array<FormatForCell>} formats element that contains all styles for cells
   * @param {WorkSheetCell} start value initial for apply styles
   * @param {WorkSheetCell} end value end for apply styles 'optional'
   * @param {boolean} applyAllBorders apply all the styles in case of being a range of elements
   */
  private setFormat(worksheet: any,
    formats: Array<FormatForCell>,
    start: WorkSheetCell,
    end?: WorkSheetCell,
    applyAllBorders?: boolean): void {
    for (const format of formats) {
      if (format?.border) {
        applyAllBorders = format?.applyAllBorders;
        for (let index = start.row; index <= end?.row; index++) {
          const leftBorderCell = worksheet.getCell(index, start.col);
          const rightBorderCell = worksheet.getCell(index, end?.col);
          leftBorderCell.border = {
              ...leftBorderCell.border,
              left: format.border.left
          };
          rightBorderCell.border = {
              ...rightBorderCell.border,
              right: format.border.right
          };
          if (applyAllBorders) {
            leftBorderCell.border = {
              ...leftBorderCell.border,
              right: format.border.right,
              top: format.border.top,
              botton: format.border.bottom
            };
            rightBorderCell.border = {
              ...rightBorderCell.border,
              left: format.border.left,
              top: format.border.top,
              botton: format.border.bottom
            };
          }
        }
        for (let index = start.col; index <= end?.col; index++) {
          const topBorderCell = worksheet.getCell(start.row, index);
          const bottomBorderCell = worksheet.getCell(end?.row, index);
          topBorderCell.border = {
              ...topBorderCell.border,
              top: format.border.top
          };
          bottomBorderCell.border = {
              ...bottomBorderCell.border,
              bottom: format.border.bottom
          };
          if (applyAllBorders) {
            topBorderCell.border = {
              ...topBorderCell.border,
              left: format.border.left,
              right: format.border.right,
              botton: format.border.bottom,
            };
            bottomBorderCell.border = {
              ...bottomBorderCell.border,
              right: format.border.right,
              left: format.border.left,
              top: format.border.top
            };
          }
        }
      }
      if (format?.font) {
        for (let index = start.row; index <= end?.row; index++) {
          const cell = worksheet.getCell(index, start.col);
          cell.font = format.font;
        }
        for (let index = start.col; index <= end?.col; index++) {
          const cell = worksheet.getCell(start.row, index);
          cell.font = format.font;
        }
      }
      if (format?.fill) {
        for (let index = start.row; index <= end?.row; index++) {
          const cell = worksheet.getCell(index, start.col);
          cell.fill = format.fill;
          cell.alignment = format.alignment;
        }
        for (let index = start.col; index <= end?.col; index++) {
          const cell = worksheet.getCell(start.row, index);
          cell.fill = format.fill;
          cell.alignment = format.alignment;
        }
      }
      if (format?.height) {
        for (let index = start.row; index <= end?.row; index++) {
          worksheet.getRow(index).height = format.height;
        }
      }
      if (format?.width) {
        for (let index = start.col; index <= end?.col; index++) {
          const column = worksheet.getColumn(index);
          column.width = format.width;
        }
      }
      if (format?.alignment) {
        for (let indexRow = start.row; indexRow <= end?.row; indexRow++) {
          for (let indexCol = start.col; indexCol <= end?.col; indexCol++) {
            const cell = worksheet.getCell(indexRow, indexCol);
            cell.alignment = format.alignment;
          }
        }
      }
    }
  }

  /**
   * @description Set the image to work sheet from result of request
   * @param {any} workbook workbook for set the image
   * @param {any} workSheet for use the method addImage
   * @param {string} imageUrl url for generate request
   * @param {string} extension type of extension to use
   * @param {string} rangeCells for to set the location of the image
   */
  private async setImage(workbook: any, workSheet: any, imageUrl: string, extension: string, rangeCells?: string): Promise<void> {
    let positionImage;
    positionImage = !rangeCells ? DEFAULT_POSITION_IMAGE : rangeCells;
    try {
    const image = workbook.addImage({
      buffer: await this.createRequest(imageUrl),
      extension: extension,
    });
    workSheet.addImage(image, positionImage);
    } catch { }
  }

  /**
   * @description Create request for get the image and after conver to objet type 'ArrayBuffer'
   * compatible to library to generate xls files
   * @param {string} url of resourse for request
   * @returns {Promise<ArrayBuffer>} request result
   */
  private async createRequest(url: string): Promise<ArrayBuffer> {
    try {
      const response = await fetch(url);
      return response.arrayBuffer();
    } catch { }
  }
}
