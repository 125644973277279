import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { AppConstants } from '../../../constants/app-constants.constants';
import { DialogOrderBulkLoadConfigProperties } from './dialog-create-bulk-load-config.properties';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

@Component({
  selector: 'app-dialog-create-bulk-load-config',
  templateUrl: './dialog-create-bulk-load-config.component.html',
  styleUrls: ['./dialog-create-bulk-load-config.component.scss',  '../../../app.component.scss']
})

/**
 * Class representation for DialogCreateBulkLoadConfigComponent.
 */
export class DialogCreateBulkLoadConfigComponent implements OnDestroy, OnInit {
  public configName: string;
  public dialogOBLProperties: typeof DialogOrderBulkLoadConfigProperties = DialogOrderBulkLoadConfigProperties;
  public dialogCreateBLLabelsTranslated: any;
  public languageLabels: any; 
  public languageSuscription: Subscription;

  @Output() public saveClicked = new EventEmitter<any>();

  @ViewChild('configNameInput', {static: true})
  public configNameInput: TemplateRef<any>;

  /**
   * @description Create Dialog for Order Bulk Load Config Component.
   * @param {LanguageChangeEventService} languageChangeEventService - Language Change Event Service instance.
   * @param {LanguageTranslateService} languageTranslateService - Language Translate Service instance.
   * @param {MatDialogRef<DialogOrderBulkLoadConfigComponent>} dialogRef - MatDialogRef instance.
   * @param {Array<IImportationTemplate>} data - Import template/config data for orders bulk load.
   * @param {ToastrAlertsService} toastService - Toast Service instance.
   */
  constructor(
    public languageChangeEventService: LanguageChangeEventService,
    public languageTranslateService: LanguageTranslateService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogCreateBulkLoadConfigComponent>,
    public toastService: ToastrAlertsService
  ) {
    this.setLanguage();
  }

  /**
   * @description Angular lifecycle hook for component init.
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    await this.getDialogCreateBulkLoadConfigLabels();
    await this.getLanguageLabels();
  }

  /**
   * @description Validate if config name meets business rules.
   * @returns {boolean} True if config name meets business rules, otherwise false.
   */
  public isValidConfigNameEntry(): boolean {
    if (this.configName && this.configName.trim() !== AppConstants.EMPTY_STRING) {
      return true;
    }

    return false;
  }

  /**
   * @description Get Dialog Create bulk load config translated labels for the selected language.
   */
  public async getDialogCreateBulkLoadConfigLabels(): Promise<void> {
    try {
      this.dialogCreateBLLabelsTranslated = await this.languageTranslateService
      .getLanguageLabels(LanguageConstants.DIALOG_CREATE_BUL_LOAD_CONFIG_LABELS);
    } catch (error) {
      this.toastService.errorAlert(this.languageLabels.errorGettingLabels);
    }
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   */
  public async getLanguageLabels(): Promise<void> {
    this.languageLabels = await this.languageTranslateService
    .getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(error => {
      this.toastService.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Emmits config name event to parent.
   */
  public onSaveConfig(): void {
    this.saveClicked.emit(this.configName);
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey - Languague key to set.
   */
  public setLanguage(languageKey?: string): void {
    this.languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Listen to the event fired when the language is changed by the SCF.
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this.languageChangeEventService._languageEmitter.subscribe(
      (languageKey: string) => {
        this.setLanguage(languageKey);
      },
      (error) => {
        this.toastService.errorAlert(this.languageLabels.errorChangingLanguage);
      }
    );
  }

  /**
   * @description  Angular lifecycle hook for component destroy.
   */
  public ngOnDestroy(): void {
    this.languageSuscription.unsubscribe();
  }
}
