import { Injectable, ErrorHandler, Injector } from '@angular/core';

import { ApplicationInsightsService } from './application-insights.service';

@Injectable()
export class ApplicationInsightsErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error: Error): void {
    const applicationInsightsService = this.injector.get(
      ApplicationInsightsService
    );
    applicationInsightsService.monitorigError(error);

    super.handleError(error);
  }
}
