import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()

export class MunicipalityService {

  public mexicoInfoUrl = environment.baseStorageUrl + 'statics/mexico.json';

  constructor(private http: HttpClient) { }

  public async getStates(): Promise<Object> {
    return await this.http.get<Object>(this.mexicoInfoUrl).toPromise();
  }

}
