<mat-grid-list cols="4" rowHeight="1:.15">
  <mat-grid-tile colspan="3" rowspan="1">
    <span class="dialog-title">
      <p>{{ labels.title + ' ' + shipper.nombre }}</p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1" rowspan="1">
    <mat-icon
      style="cursor: pointer"
      class="dialog-close-icon"
      (click)="onClose()">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>
<br>
<mat-grid-list cols="3" rowHeight="1:.20" >
  <mat-grid-tile colspan="1" rowspan="5">
    <img src="../../../../assets/driver.svg" width="95%" height="95%" alt="Driver image">
  </mat-grid-tile>
  <mat-grid-tile colspan="1">
  </mat-grid-tile>
  <mat-grid-tile colspan="2">
    <mat-form-field appearance="fill" class="form-field">
      <mat-label>{{ selectedCarriers.length > 0 ? '' : labels.selectAnOption }}</mat-label>
      <mat-select
      multiple
      [(value)]="selectedCarriers">
      <mat-select-trigger>
        {{ selectedCarriers.length + ' ' + labels.countSelected }}
      </mat-select-trigger>
      <mat-option (click)="selectAllCarriers()">{{ labels.selectAll }}</mat-option>
      <mat-option (click)="clearSelection()">{{ labels.clearSelection }}</mat-option>
        <mat-option *ngFor="let carrier of carrierList" [value]="carrier">
          {{ carrier.nombre }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-grid-tile>
  <mat-grid-tile colspan=2></mat-grid-tile>
  <mat-grid-tile colspan=2>
    <button mat-raised-button
     color="primary"
     class="primary-button align-right"
     (click)="onUpdate()">
     {{ labels.update }}
    </button>
    <button mat-stroked-button
     color="primary"
     class="secondary-button align-left"
     (click)="onCancel()">
     {{ labels.cancel }}
    </button>
  </mat-grid-tile>
</mat-grid-list>