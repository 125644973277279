import { ShipperShipmentRequestLabel } from '../../interfaces/index';
import { ShipmentRequestStatusResponse } from '../../interfaces/marketplace-shipper/shipment-request';

export const ShipperShipmentRequestLabels: ShipperShipmentRequestLabel = {
  budget: 'Presupuesto: ',
  cancelOffer: 'Cancelar Solicitud',
  complementReference: 'REFERENCIA A ',
  complementRequestid: 'SOLICITUD A ',
  cubicMeters: 'm³',
  dateDestination: 'Fecha de destino: ',
  dateOrigin: 'Fecha de origen: ',
  dateShipmentData: 'FECHA Y HORA DEL VIAJE',
  delete: 'Eliminar',
  deleteDiaglogResume: 'Toda la configuración e información de la solicitud seleccionado se eliminará y no podrá usarse',
  deleteDialogQuestion: '¿Estás seguro que deseas eliminar la solicitud?',
  deleteDialogResumeOne: 'Has seleccionado ',
  deleteDialogResumeTwo: ' para ser eliminado, las órdenes asociadas pasarán a un estatus "Sin asignar". Esta acción no podrá deshacerse',
  deleteDialogTitle: 'Eliminación de Solicitud ',
  deleteErrorMessage: 'El embarque no puede ser eliminado',
  deleteIcon: '../../../assets/eliminate.svg',
  deleteInTransitErrorMessage: 'El embarque se encuentra en tránsito, no puede eliminarse',
  deleteOrderQuestion: '¿Estás seguro de que deseas eliminar todas las órdenes asociadas al embarque?',
  deleteOrderResume: 'Las órdenes de este embarque pueden ser eliminadas o ser guardadas para utilizarse posteriormente',
  deleteOrderTitle: 'Eliminación de Órdenes',
  deliveries: 'Repartos: ',
  deliveriesRate: 'TARIFA CON PRESUPUESTO ',
  descriptionCard: 'Solicitudes',
  detailOffer: 'Ver detalle',
  dialogConfirmation: 'Si',
  dialogRejection: 'No',
  dollarSign: '$',
  expirationDate: 'Fecha de expiración: ',
  finished: 'Finalizadas',
  focusText: 'Esta acción no podrá deshacerse',
  getShipperConfigError: 'Ocurrió un error al obtener las configuraciones de Tendering',
  goBack: 'Regresar',
  inTransit: 'En tránsito',
  kg: 'kg',
  marketplace: 'Marketplace',
  noApply: 'N/A',
  noDate: 'Sin fecha',
  noInfo: 'Sin información',
  noValidCoordsMessage: 'Existen ubicaciones con coordenadas no válidas. Los marcadores no podrán mostrarse en el mapa correctamente',
  noReference: 'Sin referencia',
  offer: 'Oferta ',
  offersTitle: 'Ofertas de la solicitud ',
  pending: 'Pendientes',
  requestData: 'Datos de la solicitud ',
  routeTitle: 'RUTA ',
  save: 'Guardar',
  searchPlaceholder: 'Buscar',
  select: 'Seleccionar',
  selectOffers: 'Selecciona una de las ofertas de transporte',
  timeDestination: 'Hora de destino: ',
  timeOrigin: 'Hora de origen: ',
  title: 'Solicitudes Embarcador',
  totalDistance: 'Distancia total: ',
  unavailableMarketPlaceData: 'No hay información disponible',
  viewOffer: 'Ver ofertas',
  viewOffers: 'Ver ofertas',
  withoutDeliveries: 'Sin repartos',
  withoutQuote: 'Sin cotización',
};

export const SHIPMENT_REQUEST_STATUS: Array<ShipmentRequestStatusResponse> = [
  {
    cssClass: 'noOffersPoint',
    description: 'Sin ofertas',
    status: 'Creada',
    color: '#7E8894',
    shipments: []
  },
  {
    cssClass: 'oneOfferPoint',
    description: 'Una oferta',
    status: 'Una oferta',
    color: '#0F0F0F',
    shipments: []
  },
  {
    cssClass: 'acceptedPoint',
    description: 'Aceptada',
    status: 'Aceptada',
    color: '#3BB2A2',
    shipments: []
  },
  {
    cssClass: 'multiOfferPoint',
    description: 'Multi-oferta',
    status: 'Multi-oferta',
    color: '#B0D841',
    shipments: []
  },
  {
    cssClass: 'assignedPoint',
    description: 'Asignada',
    status: 'Asignada',
    color: '#3E608F',
    shipments: []
  },
  {
    cssClass: 'inTransitPoint',
    description: 'En tránsito',
    status: 'En transito',
    color: '#EE501E',
    shipments: []
  },
  {
    cssClass: 'finishedPoint',
    description: 'Finalizada',
    status: 'Finalizada',
    color: '#51A251',
    shipments: []
  },
  {
    cssClass: 'counterofferPoint',
    description: 'Contraoferta',
    status: 'Contraoferta',
    color: '#FAC100',
    shipments: []
  },
];
