import { Injectable } from '@angular/core';
import { GenericRegexp } from '../../regexp/generic.regexp';

import { AppConstants } from '../../constants/app-constants.constants';
import { DATEFORMAT_CONSTANTS } from '../../constants/dateformat.constants';

const LITERAL_T = 'T';
const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const INVALID_DATE = 'Invalid date';

@Injectable()
export class DateToolsService {
  /**
   * @description Converts a Date object into a formatted string representation including date and time.
   * @param {Date} dateToConvert - Date object representing the date and time to be converted into a string.
   * @returns {string} A formatted string representation of the input Date object, including both the date and time components.
   */
  public dateToString(dateToConvert: Date, hideHour?: boolean): string {
    const day = new Date(dateToConvert).toLocaleDateString();
    const time = new Date(dateToConvert).toLocaleTimeString([], { hour12: true });
    const stringDate = day + AppConstants.WHITE_SPACE + (hideHour ? AppConstants.EMPTY_STRING: time);

    return stringDate;
  }

  /**
   * @description calcultes the minutes between two dates
   * @param {Date} actualDate first date
   * @param {Date} pastDate second date
   * @return {number} minutes between dates
   */
  public differenceInMinutesBewteenTwoDates(actualDate: Date, pastDate: Date): number {
    let minutesDifference = (actualDate.getTime() - pastDate.getTime()) / MILLISECONDS_IN_SECOND;
    minutesDifference /= SECONDS_IN_MINUTE;
    const totalMinutes = Math.abs(Math.round(minutesDifference));

    return totalMinutes;
  }

  /**
   * @description Subtract the timezone offset from local time and generate a new date
   * @param {Date | string} date to Subtract the timezone offset
   * @return {Date} new Date
   */
  public calculateOffset(date: Date | string): Date {
    const millisecondsPerMinute =  60000;
    const millisecondsPerHour = 3600000;
    const copyDate = new Date(date);
    let offset = copyDate.getTimezoneOffset() * millisecondsPerMinute;
    offset -= millisecondsPerHour;
    copyDate.setTime(copyDate.getTime() - offset);

    return copyDate;
  }

  /**
   * @description Formats the date in 'YYYY-MM-DDTHH:MM:SS.' format with milliseconds.
   * @param {Date | string} date - To format.
   * @param {boolean} withoutMilliSeconds - Flag to avoid add milliseconds.
   * @returns {Date} Formatted date.
   */
  public formatISO(date: Date, withoutMilliseconds?: boolean): string {
    const fillString = '0';
    const maxLength = 2;
    const one = 1;
    const maxLengthOfThree = 3;
    const year = date.getFullYear();
    const month = String(date.getMonth() + one).padStart(maxLength, fillString);
    const day = String(date.getDate()).padStart(maxLength, fillString);
    const hours = String(date.getHours()).padStart(maxLength, fillString);
    const minutes = String(date.getMinutes()).padStart(maxLength, fillString);
    const seconds = String(date.getSeconds()).padStart(maxLength, fillString);
    const milliseconds = String(date.getMilliseconds()).padStart(maxLengthOfThree, fillString);

    if (withoutMilliseconds) {
      return `${year}-${month}-${day}${LITERAL_T}${hours}:${minutes}:${seconds}`;
    }

    return `${year}-${month}-${day}${LITERAL_T}${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  /**
   * @description Converts the date to client local time in the format specified in 'DD/MM/YYYY HH:mm a.m'.
   * @param {Date | string} date - Date to received.
   * @param {boolean} withOutTime - Specifies whether the output format.
   * @returns {string} Formatted date.
   */
  public getLocalTime(date: Date | string, withTime: boolean = false): string {
    try {
      const maxLength = 2;
      const hourLimit = 12;
      const extraMonth = 1;
      const pm = 'p.m.';
      const am = 'a.m.';
      const copyDate = new Date(date);
      const day = copyDate.getDate();
      const month = copyDate.getMonth() + extraMonth;
      const year = copyDate.getFullYear();
      const hours = copyDate.getHours();
      const minutes = copyDate.getMinutes();
      const amPm = hours >= hourLimit ? pm : am;
      const hours12 = hours % hourLimit === AppConstants.ZERO ? hourLimit : hours % hourLimit;

      const newDay = day.toString().padStart(maxLength, AppConstants.ZERO.toString());
      const newMonth = month.toString().padStart(maxLength, AppConstants.ZERO.toString());
      const newHours12 = hours12.toString().padStart(maxLength, AppConstants.ZERO.toString());
      const newMinutes = minutes.toString().padStart(maxLength, AppConstants.ZERO.toString());
      let fechaFormateada = `${newDay}/${newMonth}/${year}`;
      if (withTime) {
        fechaFormateada += ` ${newHours12}:${newMinutes} ${amPm}`;
      }

      return fechaFormateada;
    } catch (error) {
      return INVALID_DATE;
    }
  }

  /**
   * @description Gets timezone from provided date.
   * @param {Date} date - Date to get timezone.
   * @returns {string} Timezone from date.
   */
  public getTimezone(date: Date): string {
    const indexTwoPoints = 2;
    const dateString = date.toString();
    const indexInitTimezone = dateString.indexOf(AppConstants.DASH);
    const indexFinishTimezone = dateString.indexOf(AppConstants.INIT_PARENTHESIS);
    const timezone = dateString.slice((indexInitTimezone + 1), indexFinishTimezone).trim();
    const timezoneArray = timezone.split(AppConstants.EMPTY_STRING);
    timezoneArray.splice(indexTwoPoints, 0, AppConstants.COLON.trim());
    const finalTimeZone = timezoneArray.join(AppConstants.EMPTY_STRING);

    return finalTimeZone;
  }

  /**
   * @description Subtract hours from a specified date and return a new date.
   * @param {Date | string} date - Date to validate.
   * @returns {Date} New Date.
   */
  public isValidDate(date: string | Date): boolean {
    return (date instanceof Date || typeof date === AppConstants.KEY_STRING) && !isNaN(new Date(date).getTime());
  }

  /**
   * @description Validates if a date string in format yyyy/mm/dd is lower than current date without comparing time.
   * @param {string} date - Date value to be validated.
   * @returns {boolean} True if date is lower than current date and false if it isn't.
   */
  public isPastDate(date: string, separator?: string): boolean {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const splittedDate = date.split(separator ?? GenericRegexp.REG_SLASH_MIDDLE_DASH);
    const day = parseInt(splittedDate[2], 10);
    const month = parseInt(splittedDate[1], 10) - 1;
    const year = parseInt(splittedDate[0], 10);
    const dateToValidate = new Date(year, month, day);

    return dateToValidate < currentDate;
  }

  /**
   * @description Subtract hours from a specified date and return a new date
   * @param {Date | string} date to modify
   * @param {number} hours to subtract
   * @return {Date} new Date
   */
  public subtractHousToDate(date: Date | string, hours: number): Date {
    const copyDate = new Date(date);
    const millisecondsPerHour = 3600000;
    copyDate.setTime(copyDate.getTime() - (hours * millisecondsPerHour));

    return copyDate;
  }

  /**
   * @description Converts a date string  to "yyyy-mm-dd" format.
   * @param {string} date - To modify.
   * @returns {string} New formatted date.
   */
  private dateToFormatDayMonthYear(date: string): string {
    const maxLength = 2;
    if (!GenericRegexp.DATE_DDMMYYYY.test(date)) {
      return undefined;
    }

    const dateSplited = date.split(GenericRegexp.DELIMITER_SLASH_HYPHEN);
    const year = dateSplited[2];
    const month = dateSplited[1].padStart(maxLength, AppConstants.ZERO_STRING);
    const day = dateSplited[0].padStart(maxLength, AppConstants.ZERO_STRING);

    return `${year}-${month}-${day}`;
  }

  /**
   * @description Converts a date string in the "dd-mm-yyyy" format to the "yyyy-mm-dd" format.
   * @param {string} date - To modify.
   * @param {string} format - Initial format.
   * @returns {string} New formatted date.
   */
  public transformDateFormat(date: string, format: string): string {
    switch (format) {
      case DATEFORMAT_CONSTANTS.DATE_FORMAT_YYYYMMDD:
        return this.dateToFormatDayMonthYear(date);
        break;
      case DATEFORMAT_CONSTANTS.DATE_FORMAT_SLASH_YYYYMMDD:
        return this.dateToFormatDayMonthYear(date);
        break;
      default:
        return undefined;
        break;
    }
  }

  /**
   * @description Formats an ISO 8601 date string to DD/MM/YYYY or DD/MM/YYYY HH:SS p.m.
   * @param {string} dateToFormat - Date in ISO 8601 string format.
   * @param {string} separator - Separator for makes a specific date format.
   * @param {string} fullFormat - Indicates if the date will be formated as complete format date.
   * @param {string} timeSeparator - Separator to display beetween date and time format.
   * @returns {string} Date formatted in a string.
   */
  public formatDate(dateToFormat: string, separator: string, fullFormat?: boolean, timeSeparator?: string): string {
    const limitStringDate = 10;
    const limitDigits = 2;
    const hourLimit = 12;
    const pm = 'p.m.';
    const am = 'a.m.';
    const auxDate = new Date(dateToFormat);
    const dateSplitted =  auxDate.toISOString().substring(0, limitStringDate);
    const date = dateSplitted.match(GenericRegexp.NUMBERS_STRING);
    const year = date[0].substring(limitDigits);
    const month = date[1]
    const day = date[limitDigits];
    const hours = auxDate.getHours();

    if (fullFormat) {
      const minutes = auxDate.getMinutes();
      const hours12 = hours % hourLimit === AppConstants.ZERO ? hourLimit : hours % hourLimit;
      const amPm = hours >= hourLimit ? pm : am;
      const newHours12 = hours12.toString().padStart(limitDigits, AppConstants.ZERO.toString());
      const newMinutes = minutes.toString().padStart(limitDigits, AppConstants.ZERO.toString());
      const shortNameMoth = auxDate.toLocaleString(AppConstants.KEY_ES, { month: 'short' });

      if (timeSeparator) {
        return `${day + separator + shortNameMoth + '.' + separator + year} ${timeSeparator} ${newHours12}:${newMinutes} ${amPm}`;
      } else {
        return `${day + separator + shortNameMoth + '.' + separator + year} ${newHours12}:${newMinutes} ${amPm}`;
      }
    }

    return `${ day + separator + month + separator + year }`;
  }

  /**
   * @description Sets the last hour at the date.
   * @param {Date} dateToChange - Date to set hour, minutes, seconds and ms.
   * @returns {Date} Date at the end of the day.
   */
  public setDateAtEnd(dateToChange: Date): Date {
    return new Date(dateToChange.setHours(AppConstants.MAX_TIME.hour, AppConstants.MAX_TIME.minsAndSecs,
      AppConstants.MAX_TIME.minsAndSecs, AppConstants.MAX_TIME.miliseconds));
  }

  /**
   * @description Returns the initial date and time of the given date.
   * @param {Date} date - The input date.
   * @returns {Date} - The initial date with time set to 00:00:00.000.
   */
  public getInitialDate(date: Date): Date {
    const midnightHour = 0;
    const midnightMinute = 0;
    const midnightSecond = 0;
    const midnightMillisecond = 0;
    const newDate = new Date(date);
    newDate.setHours(midnightHour, midnightMinute, midnightSecond, midnightMillisecond);

    return newDate;
  }

  /**
   * @description Returns the last date and time of the given date.
   * @param {Date} date - The input date.
   * @returns {Date} - The last date with time set to 23:59:59.999.
   */
  public getLastDate(date: Date): Date {
    const lastHour = 23;
    const lasMinute = 59;
    const lastSecond = 59;
    const lastMillisecond = 999;
    const lastDate = new Date(date);
    lastDate.setHours(lastHour, lasMinute, lastSecond, lastMillisecond);

    return lastDate;
  }

  /**
   * @description Formats order delivery date checking appointment hour and delivery date from order.
   * @param {Date} deliveryDate - Delivery date from order to format.
   * @param {string} appointmentHour - Appointment hour assigned in order.
   * @param {string} withoutAppointmentHourLabel - Label to display when orders doesn't have an appointment hour.
   * @returns {string} - Date formated as string.
   */
  public getOrderDeliveryDate(deliveryDate: Date, appointmentHour: string, withoutAppointmentHourLabel: string): string {
    if (appointmentHour) {
      return this.formatDate(deliveryDate.toString(), AppConstants.SLASH, true);
    } else {
      const dateFormatted = this.formatDate(deliveryDate.toString(), AppConstants.SLASH, false);

      return dateFormatted + AppConstants.HYPHEN_WITH_SPACES + withoutAppointmentHourLabel;
    }
  }
}
