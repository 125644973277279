export const MARKETPLACE_SHIPPER_CONSTANTS = {
   ACCEPT: 'Aceptar',
   ACCEPTED_STATUS: 'Aceptada',
   ASSOCIATE_SHIPMENT_HEIGHT: '75%',
   ASSOCIATE_SHIPMENT_WIDTH: '55%',
   ASSSIGNED: 'Asignada',
   BEGIN_RESUME: 'Has seleccionado la oferta de $',
   CANCEL: 'Cancelar',
   CONFIRM: 'Confirm',
   CONFIRMATION_STATUS: 'OK',
   CONFIRMED: 'Confirmada',
   COUNTEROFFER: 'Contraofertada' ,
   COUNTER_OFFER_STATUS: 'Contraoferta',
   CREATED: 'Creada',
   DATE_FORMAT: 'D/MMM/yyyy h:mm a',
   DEFAULT_LATITUDE: 19.4325412,
   DEFAULT_LONGITUDE: -99.1338442,
   DELAYED: 'Retrasado',
   DELIVERED: 'Entregada',
   EMPTY_STRING: '',
   EN_DATE_FORMAT: 'en',
   FINALE_RESUME: '. La oferta será aceptada',
   ICON_PATH: '../../../assets/accept_offer.svg',
   INTERMEDIATE_RESUME: ', de la línea de transporte ',
   IN_PROGRESS: 'En curso',
   MODAL_QUESTION: '¿Deseas continuar?',
   MODAL_TITLE: 'Aceptar oferta de transportista',
   MOMENT_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
   MULTI_OFFER_STATUS: 'Multi-oferta',
   NO_COUNTER_OFFER: 'Sin contraoferta',
   NUMBER_FORMAT: '1.0',
   NUMERIC_FORMAT: 'en-GB',
   OBJECT: 'object',
   OFFERS: 'Ofertas',
   ONE_OFFER_STATUS: 'Una oferta',
   ON_TIME: 'A tiempo',
   PLANNED: 'Planeada',
   PRICE_PARAM: 'Precio',
   RATE_PARAM: 'Calificación',
   REGION: 'en-US',
   SHIPMENT_VIEW: '/marketplace-shipper/shipper-shipment-request',
   SLASH: '/',
   START: 0,
   STAR_RATING: 5,
   TIME_FORMAT: 'h:mm a',
   WIDTH: '650px',
   WIDTH_DETAIL: '70%',
   WIDTH_OFFERS: '100%',
   WITHOUT_COORDS: '0,0',
   ZERO_RESULTS: 'ZERO_RESULTS'
};
