<div class="dialog-title-flex">
  <h2 mat-dialog-title>{{ 'dialogOrderBulkLoadLabels.dialogTitle' | translate }}</h2>
  <mat-icon class="mat-icon-dialog-title" matTooltip="{{ 'dialogOrderBulkLoadLabels.tooltipTitle' | translate }}" matTooltipPosition="right">info</mat-icon>
</div>
<mat-dialog-content>
  <mat-card>
    <scf-grid
      *ngIf="isGridReady"
      [columnToggler]="false"
      [selectable]="false"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      [data]="configs"
      [actions]="actions"
      [columns]="columns"
      [rowDraggable]="false"
      (actioned)="gridActionHandler($event.data, $event.action)">
    </scf-grid>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button id="dob-cancel-button"
    class="secondary-button"
    (click)="onCancelSelection()"
    mat-button
    mat-dialog-close>
    {{ 'dialogOrderBulkLoadLabels.dialogCancelButton' | translate }}
  </button>
  <button id="dob-selection-button"
    class="primary-button"
    (click)="onConfigSelection()"
    mat-button
    [disabled]="!configSelected"
    [mat-dialog-close]="true">
    {{ 'dialogOrderBulkLoadLabels.dialogSelectButton' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #configSelector let-element="element" let-row="row">
  <ng-container>
    <mat-radio-button id="dob-config-selection-{{row.name}}-button"
      (change)="onConfigSelectionChange($event)"
      [value]="row">
    </mat-radio-button>
  </ng-container>
</ng-template>
