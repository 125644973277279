  export enum RecurrenceTags {
    accountPlaceholder = 'Cuenta',
    cancel = 'Cancelar',
    executionTime = 'Hora de ejecución',
    isRequired = 'es requerid',
    periodicityPlaceholder = 'Periodicidad',
    podInterfaceTitle = 'Interfaz POD',
    save = 'Guardar',
    saveChanges = 'Guardar cambios',
    suffixA = 'a',
    suffixO = 'o',
    weekdayPlaceholder = 'Día de la semana '
  }
