import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { remove as _remove } from 'lodash';

import { OnlyGuide, OrderRow, OrderViewGuidesTag } from '../../../interfaces/order-reception';
import { OrderGuidesLabels } from './dialog-view-guides-detail.labels';

const DISPLAY_COLUMNS = ['indexNumber', 'guide', 'actions'];

@Component({
  selector: 'app-dialog-view-guides-detail',
  templateUrl: './dialog-view-guides-detail.component.html',
  styleUrls: ['./dialog-view-guides-detail.component.scss',  '../../../app.component.scss']
})
export class DialogViewGuidesComponent implements OnInit {
  public displayedColumns: Array<string>;
  public guideModalSimplifiedDataSources: MatTableDataSource<OnlyGuide>;
  public label: OrderViewGuidesTag;
  public orderTittle: string;
  public scannedGuides: Array<OnlyGuide>;

  constructor(
    private dialogRef: MatDialogRef<DialogViewGuidesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderRow) {
      this.scannedGuides = [];
    }

  /**
   * @description Implementation of on Init Angular component interface
   */
  public ngOnInit(): void {
    for (const guide of this.data.scannedGuides) {
      this.scannedGuides.push(guide);
    }
    this.guideModalSimplifiedDataSources = new MatTableDataSource(this.scannedGuides);
    this.label = OrderGuidesLabels;
    this.displayedColumns = DISPLAY_COLUMNS;
  }

  /**
   * @description Closes the dialog
   */
  public onClickClose(): void {
    this.dialogRef.close(this.scannedGuides);
  }


  /**
   * @description Delete order guide
   * @param row row to delete
   */
  public onDelete(row: OnlyGuide): void {
    this.scannedGuides = _remove(this.scannedGuides, (scannedGuide) => scannedGuide.guide === row.guide);
    this.guideModalSimplifiedDataSources = new MatTableDataSource(this.scannedGuides);
  }

}
