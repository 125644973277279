import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DIALOG_SALES_RATE_CONFIRM_CONSTANTS } from './dialog-sales-rate-confirm.constants';

/**
 * @description Component for displaying a confirmation dialog for sales rate operations.
 */
@Component({
  selector: 'dialog-sales-rate-confirm.component',
  templateUrl: './dialog-sales-rate-confirm.component.html',
  styleUrls: ['./dialog-sales-rate-confirm.component.scss', '../../../../app.component.scss']
})
export class DialogSalesRateConfirmComponent {
  constructor(public dialogRef: MatDialogRef<DialogSalesRateConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  /**
   * @description Handler for clicking the first button in the dialog.
   */
  public onCancel(): void {
    this.dialogRef.close(DIALOG_SALES_RATE_CONFIRM_CONSTANTS.CANCEL);
  }

  /**
   * @description Handler for clicking the second button in the dialog.
   */
  public onConfirm(): void {
    this.dialogRef.close(DIALOG_SALES_RATE_CONFIRM_CONSTANTS.CONFIRM);
  }

  /**
   * @description Handler for clicking the close button in the dialog.
   */
  public onClose(): void {
    this.dialogRef.close(DIALOG_SALES_RATE_CONFIRM_CONSTANTS.CLOSED);
  }
}
