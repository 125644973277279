export const TEMPERATURE_VALUES = [
  { code: 'AAC', description: 'Ambiente controlado acondicionado', rna: 'A' },
  { code: 'ASA', description: 'Ambiente controlado sin acondicionar', rna: 'B' },
  { code: 'CAC', description: 'Congelado acondicionado', rna: 'C' },
  { code: 'CSA', description: 'Congelado sin acondicionar', rna: 'D' },
  { code: 'RAC', description: 'Refrigerado acondicionado', rna: 'E' },
  { code: 'RSA', description: 'Refrigerado sin acondicionar', rna: 'F' },
  { code: 'SSA', description: 'Ambiente sin acondicionar', rna: 'G' },
  { code: 'UAC', description: 'Ultracongelado acondicionado', rna: 'H' },
  { code: 'USA', description: 'Ultracongelado sin acondicionar', rna: 'I' }
];

export const TEMPERATURE_TYPES = [
  'Ambiente',
  'Refrigerado',
  'Congelado',
  'Ultracongelado'
];

