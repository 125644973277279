import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MapLocationData, MapLocationProperties, MapLocationLabel } from '../../interfaces/map-location';
import { MapLocationTags } from './map-location.labels';
import { Router } from '@angular/router';
import { ShipmentCommunicationService } from '../../services/shipment.service';
import { ShipmentsService } from '../../providers/shipments/shipments.service';

const ADDRESS = 'Centro de la Ciudad de México';
const DEFAULTHEIGHT = '480px';
const DEFAULTLATITUDE = 19.4325412;
const DEFAULTLONGITUDE = -99.1338442;
const DEFAULTWIDTH = '480px';
const DEFAULTZOOM = 15;

@Component({
  selector: 'app-map-location',
  templateUrl: './map-location.component.html'
})
export class MapLocationComponent implements OnInit, OnChanges {
  @Input() locationInfo: MapLocationData;
  @Input() mapProperties: MapLocationProperties;
  public address: string;
  public draggable: boolean;
  public hasShipment: boolean;
  public height: string;
  public labels: MapLocationLabel;
  public latitude: number;
  public longitude: number;
  public shipment: string;
  public width: string;
  public zoom: number;
  public zoomControl: boolean;

  constructor(
    private router: Router,
    private _shipmentsReq: ShipmentsService,
    private _shipment: ShipmentCommunicationService
    ) { this.labels = MapLocationTags; }

  ngOnInit() {
    this.setMapProperties();
    this.setLocationInfo();
  }

  ngOnChanges(): void {
    this.setMapProperties();
    this.setLocationInfo();
  }

  public setMapProperties(): void {
    if (this.mapProperties) {
      this.height = this.mapProperties.height;
      this.width =  this.mapProperties.width;
      this.zoom = this.mapProperties.zoom;
      this.draggable = this.mapProperties.draggable;
      this.zoomControl = this.mapProperties.zoomControl;
    } else {
      this.height = DEFAULTHEIGHT;
      this.width = DEFAULTWIDTH;
      this.zoom = DEFAULTZOOM;
      this.draggable = false;
      this.zoomControl = false;
    }
  }

  public setLocationInfo(): void {
    if (this.locationInfo) {
      this.latitude = this.locationInfo.latitude;
      this.longitude = this.locationInfo.longitude;
      this.address = this.locationInfo.address;
      if (this.locationInfo.shipment) {
        this.hasShipment = true;
        this.shipment = this.locationInfo.shipment;
      } else {
        this.hasShipment = false;
      }
    } else {
      this.latitude = DEFAULTLATITUDE;
      this.longitude = DEFAULTLONGITUDE;
      this.address = ADDRESS;
    }
  }

  public async goTo() {
  const shipmentreq = await this._shipmentsReq.getOneShipment(this.shipment);
    this._shipment.setShipment(shipmentreq);
    this.router.navigate(['/journey-menu/details'], { queryParams: {'id': shipmentreq.id} });
  }

}
