import { Injectable } from '@angular/core';
import { OrderSimplified } from '../../interfaces/shipment';

const SCANNED_STATUS_COMPLETE = 'Completa';
const SCANNED_STATUS_INCOMPLETE = 'Incompleta';

@Injectable()
export class ScanService {
  /**
   * @description compare scanned guides with total guides for get the status.
   * @param {OrderSimplified} order order include guides and scannedGuides
   * @return string of complete or incomplete depending if is true or false
   */
  public getScannedStatus(orderFound: OrderSimplified): string {
    return (orderFound.guides.length) <= orderFound.scannedGuides.length ? SCANNED_STATUS_COMPLETE : SCANNED_STATUS_INCOMPLETE;
  }
}
