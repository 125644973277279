<mat-grid-list cols="4" rowHeight="1:.15">
  <mat-grid-tile colspan="3" rowspan="1">
    <span class="dialog-title">
      <p>{{data.title}}</p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1" rowspan="1">
    <mat-icon
      style="cursor: pointer"
      class="dialog-close-icon"
      (click)="onClose()">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>
<br>
<mat-grid-list cols="3" rowHeight="1:.20" >
  <mat-grid-tile colspan="1" rowspan="5">
    <img 
      src="{{data.iconPath}}" 
      width="95%" 
      height="95%" 
      alt="Dialog image">
  </mat-grid-tile>
  <mat-grid-tile  colspan="2" rowspan="1">
    <span class="text text-direction">{{data.usernameInfo}}</span>
  </mat-grid-tile>
  <mat-grid-tile colspan="2" rowspan="2">
    <span class="text text-direction">{{data.passwordInfo}}</span>
  </mat-grid-tile>
  <mat-grid-tile class="button-container">
    <button
      mat-button
      cdkFocusInitial
      class="primary-button align-right"
      (click)="onClose()">
      {{data.textButton}}
    </button>
  </mat-grid-tile>
</mat-grid-list>
