<div>
    <mat-grid-list
      cols="3"
      rowHeight="1:.15"
      class="titleGuides">
      <mat-grid-tile colspan="2">
        <span class="dialog-title">
          <p>{{ "viewOrderTags.modalGuidesTittle" | translate }}<span class="focus-text"> {{ orderFolio }} </span></p>
        </span>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-icon
          id="closeButton"
          style="cursor: pointer"
          class="dialog-close-icon"
          (click)="onClickClose()">
          <img src='../../../../assets/closeGuidesModal.svg' alt="actionsMenu">
        </mat-icon>
      </mat-grid-tile>
    </mat-grid-list>
    <div class="table--scroll">
      <table
        mat-table
        [dataSource]="dataSource"
        aria-describedby="guideModalSimplifiedDataSourcesTable">
        <ng-container matColumnDef="guide">
          <th
            id="guide"
            mat-header-cell
            *matHeaderCellDef>
            {{ "viewOrderTags.whiteGuide" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let row">
            {{ row.subfolio }}
          </td>
        </ng-container>
        <ng-container matColumnDef="courierGuide">
          <th
            id="courierGuide"
            mat-header-cell
            *matHeaderCellDef>
            {{ "viewOrderTags.courierGuide" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let row">
            <div *ngIf="row.waybill">
              {{ row.waybill }}
            </div>
            <div *ngIf="!row.waybill">
              {{ "viewOrderTags.noInfo" | translate }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="courier">
          <th
            id="courier"
            mat-header-cell
            *matHeaderCellDef>
            {{ "viewOrderTags.courier" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let row">
              <div *ngIf="row.courier">
                {{ row.courier }}
              </div>
              <div *ngIf="!row.courier">
                {{ "viewOrderTags.noInfo" | translate }}
              </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th
            id="status"
            mat-header-cell
            *matHeaderCellDef>
            {{ "viewOrderTags.status" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let row">
            <div *ngIf="row.status === 'Sin Asignar'" class="status-not-assigned">
              {{ "viewOrderTags.statusUnassigned" | translate }}
            </div>
            <div *ngIf="row.status === 'Asignada'" class="status-assigned">
              {{ "viewOrderTags.statusAssigned" | translate }}
            </div>
            <div *ngIf="row.status === 'Cancelada'" class="status-cancelled">
              {{ "viewOrderTags.statusCancelled" | translate }}
            </div>
            <div *ngIf="row.status === 'En Transito'" class="status-in-transit">
              {{ "viewOrderTags.statusInTransit" | translate }}
            </div>
            <div *ngIf="row.status === 'Entregada'" class="status-delivered">
              {{ "viewOrderTags.statusDelivered" | translate }}
            </div>
            <div *ngIf="row.status === 'Rechazada'" class="status-refused">
              {{ "viewOrderTags.statusRejected" | translate }}
            </div>
            <div *ngIf="row.status === 'No Entregada'" class="status-not-delivered">
              {{ "viewOrderTags.statusNotDelivered" | translate }}
            </div>
            <div *ngIf="row.status === 'Liberada'" class="status-released">
              {{ "viewOrderTags.statusReleased" | translate }}
            </div>
            <div *ngIf="row.status === 'Parcial'" class="status-partial">
              {{ "viewOrderTags.statusPartial" | translate }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th
            id="actions"
            mat-header-cell
            *matHeaderCellDef>
            {{ "viewOrderTags.actions" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let row">
            <mat-icon [matTooltip]="'viewOrderTags.historicGuide' | translate">
              <a
              class="icon-button history-icon"
              isIconButton='true'
              [routerLink]="['/order/guide-history']"
              [queryParams]="{ subfolio: row.subfolio }"
              target="_blank">
                <img src='../../../assets/order_history.svg'
                class="icon-button history-icon" alt="Order history icon">
              </a>
            </mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div [hidden]="false">
      <mat-paginator [pageSize]="10" showFirstLastButtons></mat-paginator>
  </div>
  </div>
