import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { View } from '../../interfaces/view';

const apiUrl = environment.apiUrl;

@Injectable()
export class ViewProvider {

  constructor(private http: HttpClient) { }

  /**
   * @description Gets all views or by role
   * @param {string} roleOid Search views by role, it's an optional value
   * @returns {Array<View>} Views found
   */
  public async getViews(roleOid?: string): Promise<Array<View>> {
    const url = roleOid ? '/role/view?roleOid=' + roleOid : '/role/view/';

    return await this.http.get<Array<View>>(apiUrl + url).toPromise();
  }
}
