import { ImportationCategory } from '../../../interfaces/importation';
import { ImportationDataTypes } from '../../../enums/importation-type';

export const IMPORTATION_ORDERS_CATEGORY: ImportationCategory[] = [
  {
    label: 'Información general de la orden',
    name: 'general',
    rules: [
      {
        name: 'Nombre',
        label: 'Nombre de la orden',
        required: true,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'Referencia',
        label: 'Referencia Interna',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'Fecha',
        label: 'Fecha de entrega programada',
        required: true,
        dataType: ImportationDataTypes.Date
      },
      {
        name: 'Hora',
        label: 'Hora de entrega programada',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'Cuenta',
        label: 'Cuenta',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'ReferenciaCuenta',
        label: 'Referencia Interna Cuenta',
        required: false,
        dataType: ImportationDataTypes.Text
      }
    ]
  },
  {
    label: 'Contenido de la orden',
    name: 'contenido',
    rules: [
      {
        name: 'Peso',
        label: 'Peso',
        required: true,
        dataType: ImportationDataTypes.Number
      },
      {
        name: 'Volumen',
        label: 'Volumen',
        required: true,
        dataType: ImportationDataTypes.Number
      },
      {
        name: 'Cajas',
        label: 'Cajas',
        required: false,
        dataType: ImportationDataTypes.Number
      },
      {
        name: 'Piezas',
        label: 'Piezas',
        required: false,
        dataType: ImportationDataTypes.Number
      },
      {
        name: 'Pallets',
        label: 'Pallets',
        required: false,
        dataType: ImportationDataTypes.Number
      },
      {
        name: 'Descuento',
        label: 'Descuento',
        required: false,
        dataType: ImportationDataTypes.Number
      }
    ]
  },
  {
    label: 'Datos del Origen',
    name: 'origen',
    rules: [
      {
        name: 'NombreOrigen',
        label: 'Nombre de origen',
        required: true,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'DireccionOrigen',
        label: 'Dirección de origen',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'CodigoPostalOrigen',
        label: 'Código Postal de origen',
        required: false,
        dataType: ImportationDataTypes.Number
      },
      {
        name: 'MunicipioOrigen',
        label: 'Municipio de origen',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'EstadoOrigen',
        label: 'Estado de origen',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'LatitudOrigen',
        label: 'Latitud de origen',
        required: false,
        dataType: ImportationDataTypes.Number
      },
      {
        name: 'LongitudOrigen',
        label: 'Longitud de origen',
        required: false,
        dataType: ImportationDataTypes.Number
      }
    ]
  },
  {
    label: 'Datos del Destino',
    name: 'destino',
    rules: [
      {
        name: 'clienteId',
        label: 'Cliente ID',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'NombreDestino',
        label: 'Nombre de destino',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'DireccionDestino',
        label: 'Dirección de destino',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'CodigoPostalDestino',
        label: 'Código Postal de Destino',
        required: false,
        dataType: ImportationDataTypes.Number
      },
      {
        name: 'MunicipioDestino',
        label: 'Municipio de Destino',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'EstadoDestino',
        label: 'Estado de Destino',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'LatitudDestino',
        label: 'Latitud de Destino',
        required: false,
        dataType: ImportationDataTypes.Number
      },
      {
        name: 'LongitudDestino',
        label: 'Longitud de Destino',
        required: false,
        dataType: ImportationDataTypes.Number
      }
    ]
  },
  {
    label: 'Productos',
    name: 'productos',
    rules: [
      {
        name: 'NombreProducto',
        label: 'Nombre del producto',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'CodigoProducto',
        label: 'Código del producto',
        required: false,
        dataType: ImportationDataTypes.Text
      },
      {
        name: 'Cantidad',
        label: 'Cantidad del producto',
        required: false,
        dataType: ImportationDataTypes.Number
      },
      {
        name: 'Unidad',
        label: 'Unidad de Medida',
        required: false,
        dataType: ImportationDataTypes.Number
      },
      {
        name: 'Precio',
        label: 'Precio',
        required: false,
        dataType: ImportationDataTypes.Number
      },
      {
        name: 'cmm',
        label: 'cmm',
        required: false,
        dataType: ImportationDataTypes.Number
      }
    ]
  }
];

export const API_MODULES = '&modules=';
export const API_SOLUTION = '&solution=';
export const API_TENANTID = '?tenantId=';
export const DISABLED = true;
export const ERROR_FILE_NAME = 'Errores_Órdenes';
export const FORMAT = ['XLSX', 'XLS'];
export const IMPORTATION_DIALOG_WIDTH = '600px';
export const PERMISSIONS_MODULE = 'orders';
export const PERMISSIONS_SOLUTION = 'MEP';
export const TICKET = 'Factura';
