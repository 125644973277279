export const ImportVehiclesDetailsLabels = {
  continueButton: 'Continuar',
  detailsSubtitle: 'Detalles',
  errorDescCol: 'Descripción',
  errorRows: 'líneas no válidas con errores',
  errorTypeCol: 'Tipo',
  fieldCol: 'Campo en archivo',
  invalidVehicle: 'Error',
  retryButton: 'Reintentar',
  rowLineCol: 'Línea',
  successRows: 'líneas válidas con éxito',
  title: 'Detalle de Importación de Vehículos',
  validVehicle: 'Correcto',
  warningRows: 'líneas válidas con advertencias',
  warningVehicle: 'Advertencia'
};

export interface IImportVehiclesDetailsLabels {
  continueButton: string;
  detailsSubtitle: string;
  errorDescCol: string;
  errorRows: string;
  errorTypeCol: string;
  fieldCol: string;
  invalidVehicle: string;
  retryButton: string;
  rowLineCol: string;
  successRows: string;
  title: string;
  validVehicle: string;
  warningRows: string;
  warningVehicle: string;
}
