<div class="responsive-card">
  <h3 class="title-dialog">
    {{ data.title }}
  </h3>
  <br>
  <form [formGroup]="addtionalChargesFormGroup" *ngIf="!isDelete">
    <mat-grid-list cols="2" rowHeight="1:.3">
      <mat-grid-tile>
        <mat-form-field>
          <mat-select
            id="chargeConceptSelect"
            formControlName="concept"
            placeholder="{{ 'dialogAdditionalChargesLabels.conceptPlaceHolder' | translate }}"
            (selectionChange)="checkAmountChanges()"
            required>
            <mat-option
              *ngFor="let charge of data.chargeList"
              [value]="charge.identifier">
                {{ charge.chargeName }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ 'dialogAdditionalChargesLabels.concept' | translate }}
            <strong> {{ 'dialogAdditionalChargesLabels.isRequired' | translate }} </strong>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field>
          <input
            matInput
            id="amountInput"
            formControlName="value"
            type="number"
            required
            (input)="showTotalCharges()"
            (change)="showTotalCharges()"
            placeholder="{{ placeholderValue }}">
            <mat-error *ngIf="addtionalChargesFormGroup.controls['value'].hasError('required') && !isDiscount">
              {{ 'dialogAdditionalChargesLabels.clientCharge' | translate }} <strong>
              {{ 'dialogAdditionalChargesLabels.isRequired' | translate }} </strong>
            </mat-error>
            <mat-error *ngIf="addtionalChargesFormGroup.controls['value'].hasError('required') && isDiscount">
              {{ 'dialogAdditionalChargesLabels.discountCharge' | translate }} <strong>
              {{ 'dialogAdditionalChargesLabels.isRequired' | translate }} </strong>
            </mat-error>
            <mat-error *ngIf="addtionalChargesFormGroup.controls['value'].hasError('invalidValue') && isDiscount">
              {{ 'dialogAdditionalChargesLabels.invalidTotalDiscounts' | translate }}
            </mat-error>
            <mat-error *ngIf="addtionalChargesFormGroup.controls['value'].hasError('invalidDecimals')">
              {{ 'dialogAdditionalChargesLabels.invalidDecimals' | translate }}
            </mat-error>
            <mat-error *ngIf="addtionalChargesFormGroup.controls['value'].hasError('max')">
              {{ 'dialogAdditionalChargesLabels.maxValueField' | translate }} <strong> {{ maxValue }} </strong>
            </mat-error>
            <mat-error *ngIf="addtionalChargesFormGroup.controls['value'].hasError('min')">
              {{ 'dialogAdditionalChargesLabels.minValueField' | translate }} <strong> {{ minValue }} </strong>
            </mat-error>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </form>

  <div class="dialog-sign row" *ngIf="addtionalChargesFormGroup.controls['value'].value > 0 || isDelete">
    <img
      alt="iconDialog"
      src="{{ data.icon }}">
    <b class="dialog-sign-text"> {{ data.textContentPart1 }}
    <b class="key-word"> {{ data.keyWord }} </b> {{ data.to }} {{ totalAmount }}</b>
    <p> {{ data.textContentPart2 }}  {{ auxTotalCharges }} </p>
  </div>

  <br>
  <div class="question-sign" *ngIf="isDelete">
    <b class="question-text"> {{ 'dialogAdditionalChargesLabels.question' | translate }}  </b>
  </div>

  <mat-grid-list cols="2" class="button-list" rowHeight="1:.2">
    <mat-grid-tile class="button-container">
      <button
        id="cancelButton"
        mat-button
        class="secondary-button align-left cancel-button"
        (click)="onClickClose()">
        {{ data.cancelButton }}
      </button>
      <button
        id="saveChargeButton"
        mat-raised-button
        *ngIf="saveChargeButton()"
        [disabled]="addtionalChargesFormGroup.invalid || !isValidAmount"
        class="primary-button align-right"
        (click)="saveAdditionalCharge()">
        {{ data.acceptButton }}
      </button>
      <button
        id="deleteChargeButton"
        *ngIf="deleteChargeButton()"
        mat-raised-button
        [disabled]="isNegativeNumber"
        class="primary-button align-right"
        (click)="deleteAdditionalCharge()">
        {{ data.acceptButton }}
      </button>
      <button
        id="saveDiscountButton"
        mat-raised-button
        *ngIf="saveDiscountButton()"
        [disabled]="addtionalChargesFormGroup.invalid || !isValidAmount"
        class="primary-button align-right"
        (click)="saveDiscount()">
        {{ data.acceptButton }}
      </button>
      <button
        id="deleteDiscountButton"
        *ngIf="deleteDiscountButton()"
        mat-raised-button
        class="primary-button align-right"
        (click)="deleteDiscount()">
        {{ data.acceptButton }}
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</div>
