<div class="suite-login-background-container" *ngIf="!isSpinnerVisible">

  <div class="row suite-login-row">
    <div class="column suite-login-column">

      <div class="suite-login-container">
        <div class="suite-login-center-container">
          <mat-card class="suite-login-mat-card">
            <mat-card-content class="suite-login-mat-card-content">
              <div class="suite-login-center-container">
                <p class="suite-login-nice-to-see-you-text"> {{ 'supplynetLoginLabels.niceToSeeYouAgain' | translate }} </p>
              </div>
              <div class="suite-login-center-container">
                <p *ngIf="isLoginFormVisible" class="suite-login-enter-credentials-text"> {{ 'supplynetLoginLabels.enterCredentialsToAccess' | translate }} </p>
                <p *ngIf="isTenatFormVisible" class="suite-login-enter-credentials-text"> {{ 'supplynetLoginLabels.enterTenatToAccess' | translate }} </p>
              </div>
              <div class="suite-login-form-container" *ngIf="isLoginFormVisible">
                <form [formGroup]="loginForm">
                  <mat-form-field class="suite-login-mat-form-field" id="user-input-login">
                    <input class="email-input" matInput
                      placeholder="{{ 'supplynetLoginLabels.usernamePlaceholder' | translate }}"
                      formControlName="username">
                    <mat-error class="suite-login-mat-error">
                      <strong>
                        {{ 'supplynetLoginLabels.requiredUsername' | translate }}
                      </strong>
                    </mat-error>
                  </mat-form-field>

                  <div class="suite-login-button-container">
                    <button class="suite-login-button" mat-raised-button type="submit" (keyup.enter)="loginSubmit()"
                      (click)="loginSubmit()" color="primary" [disabled]="!loginForm.valid">
                      {{ 'supplynetLoginLabels.nextButton' | translate }}
                    </button>
                  </div>
                </form>
              </div>
  
              <div class="suite-login-form-container" *ngIf="isTenatFormVisible">
                <form [formGroup]="tenantForm" (ngSubmit)="tenantsubmit()">
                  <mat-form-field class="suite-login-mat-form-field" id="user-tenant-input-login"
                    *ngIf="tenantForm.controls['tenant']">
                    <mat-label class="suite-login-mat-form-label"> {{ 'supplynetLoginLabels.selectTenant' | translate }} </mat-label>
             
                    <mat-select class="suite-login-select-panel" id="tenant-group-select" required formControlName="tenant">
                      <mat-option *ngFor="let tenant of userTenants" [id]="tenant" [value]="tenant">
                        {{tenant.tenantDisplayName}}
                      </mat-option>
                    </mat-select>
                    
                    <mat-error class="suite-login-mat-error">
                      <strong>
                        {{ 'supplynetLoginLabels.requiredTenant' | translate }}
                      </strong>
                    </mat-error>
                  </mat-form-field>

                  <div class="suite-login-button-container">
                    <div class="row suite-login-row">
                      <div class="column suite-login-column">
                        <button id="btnCancel" mat-stroked-button color="primary" (click)="cancelTenatLogin()"
                          class="suite-secondary-button m-r-10">
                          {{ 'supplynetLoginLabels.cancelButton' | translate }}
                        </button>
                      </div>
                      <div class="column suite-login-column">
                        <button class="suite-login-button" mat-raised-button type="submit" (keyup.enter)="tenantsubmit()"
                        (click)="tenantsubmit()" color="primary" [disabled]="!tenantForm.valid">
                        {{ 'supplynetLoginLabels.nextButton' | translate }}
                      </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="suite-login-center-container">
          <img class="suite-login-powered-by-image" src="../../../assets/icons/login/netlogistik-icon.svg" alt="Powered by NetLogistiK">
        </div>
        <div class="suite-login-center-container">
          <p class="suite-login-powered-by-text"> {{ 'supplynetLoginLabels.allRightsReserved' | translate }} </p>
        </div>
      </div>
    </div>
    <div class="column suite-login-column hidesmallscreen">
      <img class="suite-login-image" src="../../../assets/icons/login/supplynet-solutions.svg"
        alt="Supplynet Solutions">
    </div>
  </div>

  <!--<div class="suite-login-container">
    <mat-card>
      <mat-card-content class="form-card">
        <div class="suite-icon-container">
          <img src="../../../assets/icons/login/supply_net_logo.png" class="suite-icon" alt="suite-icon">
        </div>
        <p class="suite-title"> {{ 'supplynetLoginLabels.supplyLabel' | translate }} </p>
        <div *ngIf="isLoginFormVisible">
          <form [formGroup]="loginForm">
            <mat-form-field id="user-input-login">
              <input class="email-input" matInput placeholder="{{ 'supplynetLoginLabels.usernamePlaceholder' | translate }}"
                formControlName="username">
            </mat-form-field>
            <button class="suite-login-button" mat-raised-button type="submit" (keyup.enter)="loginSubmit()"
              (click)="loginSubmit()" color="primary" [disabled]="!loginForm.valid">
              {{ 'supplynetLoginLabels.nextButton' | translate }}
            </button>
          </form>
        </div>

        <div *ngIf="isTenatFormVisible">
          <form [formGroup]="tenantForm" (ngSubmit)="tenantsubmit()">
            <mat-form-field class="form-field select" id="user-tenant-input-login"
              *ngIf="tenantForm.controls['tenant']">
              <mat-label> {{ 'supplynetLoginLabels.selectTenant' | translate }} </mat-label>
              <mat-select id="tenant-group-select" required formControlName="tenant">
                <mat-option *ngFor="let tenant of userTenants" [id]="tenant" [value]="tenant">
                  {{tenant.value}}
                </mat-option>
              </mat-select>
              <mat-error>
                <strong>
                  {{ 'supplynetLoginLabels.requiredTenant' | translate }}
                </strong>
              </mat-error>
            </mat-form-field>
            <div mat-dialog-actions class="suite-button-container">
              <mat-grid-list cols="2" rowsHeight="1:.10">
                <mat-grid-tile>
                  <button id="btnCancel" mat-stroked-button color="primary" (click)="cancelTenatLogin()"
                    class="suite-secondary-button m-r-10">
                    {{ 'supplynetLoginLabels.cancelButton' | translate }}
                  </button>
                </mat-grid-tile>
                <mat-grid-tile>
                  <button class="suite-login-button" mat-raised-button type="submit" (keyup.enter)="tenantsubmit()"
                    (click)="tenantsubmit()" color="primary" [disabled]="!tenantForm.valid">
                    {{ 'supplynetLoginLabels.nextButton' | translate }}
                  </button>
                </mat-grid-tile>
              </mat-grid-list>
            </div>
          </form>
        </div>

        <div class="powered-icon-container">
          <img src="../../../assets/icons/login/powered_by_net_biis_logo.png" alt="powered-by-icon">
        </div>
      </mat-card-content>
    </mat-card>
  </div>
-->
</div>


<mat-progress-spinner mode="indeterminate" diameter="100" class="suite-loading-spinner" *ngIf="isSpinnerVisible">
</mat-progress-spinner>