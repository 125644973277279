export enum MatterType {
  rawMaterial = 'Materia prima',
  processedMaterial = 'Materia procesada',
  finishedMaterial = 'Materia terminada',
  manufacturingMaterial = 'Materia para la industria manufacturera',
  otherMaterial = 'Otra'
}

export enum CofeprisSector {
  medicines = 'Medicamentos',
  dualUsePrecursorsAndChemicals = 'Precursores y químicos de uso dual',
  psychotropicsAndNarcotics = 'Psicotrópicos y estupefacientes',
  toxicSubstances = 'Sustancias tóxicas',
  pesticidesAndFertilizers = 'Plaguicidas y fertilizantes'
}

export enum CofeprisSectorCodes {
  medicines = '01',
  dualUsePrecursorsAndChemicals = '02',
  psychotropicsAndNarcotics = '03',
  toxicSubstances = '04',
  pesticidesAndFertilizers = '05'
}

export enum CofeprisFields {
 activeIngredientName = 'activeIngredientName',
 chemicalName = 'chemicalName',
 activeSubstanceGeneric = 'activeSubstanceGeneric',
 trademarkMedication = 'trademarkMedication',
 manufacturer = 'manufacturer',
 expirationDate = 'expirationDate',
 lot = 'lot',
 pharmaceuticalForm = 'pharmaceuticalForm',
 transportSpecialConditions = 'transportSpecialConditions',
 healthRegisterFolio = 'healthRegisterFolio',
 importLicense = 'importLicense',
 importVUCEMFolio = 'importVUCEMFolio',
 numCAS = 'numCAS',
 importCompany = 'importCompany',
 cofeprisPesticideNumber = 'cofeprisPesticideNumber',
 pesticideManufacturerCountryName = 'pesticideManufacturerCountryName',
 activeIngredientManufacturerCountryName = 'activeIngredientManufacturerCountryName',
 activeIngredientMaquilaCountryName = 'activeIngredientMaquilaCountryName',
 authorizedUse = 'authorizedUse',
 matterType = 'matterType',
 cofeprisSector = 'cofeprisSector'
}

/**
 * @description Main cofepris config fields.
 */
export enum MainCofeprisFields {
  matterType = 'matterType',
  cofeprisSector = 'cofeprisSector'
}

export enum TransportSpecialConditions {
  controlledTemperature = 'Temperatura controlada',
  frozen = 'Congelados',
  refrigerated = 'Refrigerados',
  roomTemperature = 'Temperatura ambiente'
}

export const COFEPRIS_CONSTANTS = {
  COFEPRIS_SECTORS_EN: [
    { label: 'Medicines', value: '01' },
    { label: 'Dual use precursors and chemicals', value: '02' },
    { label: 'Psychotropics and narcotics', value: '03' },
    { label: 'Toxic substances', value: '04' },
    { label: 'Pesticides and fertilizers', value: '05' }
  ],
  COFEPRIS_SECTORS_ES: [
    { label: CofeprisSector.medicines, value: '01' },
    { label: CofeprisSector.dualUsePrecursorsAndChemicals, value: '02' },
    { label: CofeprisSector.psychotropicsAndNarcotics, value: '03' },
    { label: CofeprisSector.toxicSubstances, value: '04' },
    { label: CofeprisSector.pesticidesAndFertilizers, value: '05' }
  ],
  MATERIAL_TYPES_EN: [
    { label: 'Raw material', value: '01' },
    { label: 'Processed material', value: '02' },
    { label: 'Finished material', value: '03' },
    { label: 'Manufacturing material', value: '04' },
    { label: 'Other material', value: '05' }
  ],
  MATERIAL_TYPES_ES: [
    { label: MatterType.rawMaterial, value: '01' },
    { label: MatterType.processedMaterial, value: '02' },
    { label: MatterType.finishedMaterial, value: '03' },
    { label: MatterType.manufacturingMaterial, value: '04' },
    { label: MatterType.otherMaterial, value: '05' }
  ],
  SECTOR_COFEPRIS: {
    KEY_01: '01',
    KEY_02: '02',
    KEY_03: '03',
    KEY_04: '04',
    KEY_05: '05'
  },
  TRANSPORT_SPECIAL_CONDITIONS_EN: [
    { label: 'Frozen', value: '01' },
    { label: 'Refrigerated', value: '02' },
    { label: 'Controlled temperature', value: '03' },
    { label: 'Room temperature', value: '04' }
  ],
  TRANSPORT_SPECIAL_CONDITIONS_ES: [
    { label: TransportSpecialConditions.frozen, value: '01' },
    { label: TransportSpecialConditions.refrigerated, value: '02' },
    { label: TransportSpecialConditions.controlledTemperature, value: '03' },
    { label: TransportSpecialConditions.roomTemperature, value: '04' }
  ]
};

export const VALID_COFEPRIS_SECTORS_FOR_ACTIVE_INGREDIENT_NAME_FIELD = [
  CofeprisSectorCodes.dualUsePrecursorsAndChemicals.toString(),
  CofeprisSectorCodes.pesticidesAndFertilizers.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_CHEMICAL_NAME_FIELD = [
  CofeprisSectorCodes.dualUsePrecursorsAndChemicals.toString(),
  CofeprisSectorCodes.toxicSubstances.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_ACTIVE_SUBSTANCE_GENERIC_FIELD = [
  CofeprisSectorCodes.medicines.toString(),
  CofeprisSectorCodes.psychotropicsAndNarcotics.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_TRADEMARK_MEDICATION_FIELD = [
  CofeprisSectorCodes.psychotropicsAndNarcotics.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_MANUFACTURER_FIELD = [
  CofeprisSectorCodes.medicines.toString(),
  CofeprisSectorCodes.dualUsePrecursorsAndChemicals.toString(),
  CofeprisSectorCodes.psychotropicsAndNarcotics.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_PHARMACEUTICAL_FORM_FIELD = [
  CofeprisSectorCodes.medicines.toString(),
  CofeprisSectorCodes.dualUsePrecursorsAndChemicals.toString(),
  CofeprisSectorCodes.psychotropicsAndNarcotics.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_TRANSPORT_SPECIAL_CONDITIONS_FIELD = [
  CofeprisSectorCodes.medicines.toString(),
  CofeprisSectorCodes.dualUsePrecursorsAndChemicals.toString(),
  CofeprisSectorCodes.psychotropicsAndNarcotics.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_EXPIRATION_DATE_FIELD = [
  CofeprisSectorCodes.medicines.toString(),
  CofeprisSectorCodes.dualUsePrecursorsAndChemicals.toString(),
  CofeprisSectorCodes.psychotropicsAndNarcotics.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_LOT_FIELD = [
  CofeprisSectorCodes.medicines.toString(),
  CofeprisSectorCodes.dualUsePrecursorsAndChemicals.toString(),
  CofeprisSectorCodes.psychotropicsAndNarcotics.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_HEALTH_REGISTER_FOLIO_FIELD = [
  CofeprisSectorCodes.medicines.toString(),
  CofeprisSectorCodes.psychotropicsAndNarcotics.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_IMPORT_LICENSE_FIELD = [
  CofeprisSectorCodes.medicines.toString(),
  CofeprisSectorCodes.dualUsePrecursorsAndChemicals.toString(),
  CofeprisSectorCodes.pesticidesAndFertilizers.toString(),
  CofeprisSectorCodes.toxicSubstances.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_IMPORT_VUCEM_FOLIO_FIELD = [
  CofeprisSectorCodes.medicines.toString(),
  CofeprisSectorCodes.dualUsePrecursorsAndChemicals.toString(),
  CofeprisSectorCodes.pesticidesAndFertilizers.toString(),
  CofeprisSectorCodes.toxicSubstances.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_NUM_CAS_FIELD = [
  CofeprisSectorCodes.toxicSubstances.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_IMPORT_COMPANY_FIELD = [
  CofeprisSectorCodes.toxicSubstances.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_COFEPRIS_PESTICIDE_NUMBER_FIELD = [
  CofeprisSectorCodes.pesticidesAndFertilizers.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_PESTICIDE_MANUFACTURER_COUNTRY_NAME_FIELD = [
  CofeprisSectorCodes.pesticidesAndFertilizers.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_ACTIVE_INGREDIENT_MANUFACTURER_COUNTRY_NAME_FIELD = [
  CofeprisSectorCodes.pesticidesAndFertilizers.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_ACTIVE_INGREDIENT_MAQUILA_COUNTRY_NAME_FIELD = [
  CofeprisSectorCodes.pesticidesAndFertilizers.toString()
];

export const VALID_COFEPRIS_SECTORS_FOR_AUTHORIZED_USE_FIELD = [
  CofeprisSectorCodes.pesticidesAndFertilizers.toString()
];

export class FieldsByCofeprisSector {
  public static readonly ACTIVE_INGREDIENT_NAME = 'activeIngredientName';
  public static readonly ACTIVE_INGREDIENT_MANUFACTURER_COUNTRY_NAME = 'activeIngredientManufacturerCountryName';
  public static readonly ACTIVE_INGREDIENT_MAQUILA_COUNTRY_NAME = 'activeIngredientMaquilaCountryName';
  public static readonly ACTIVE_SUBSTANCE_GENERIC = 'activeSubstanceGeneric';
  public static readonly AUTHORIZED_USE = 'authorizedUse';
  public static readonly CHEMICAL_NAME = 'chemicalName';
  public static readonly COFEPRIS_PESTICIDE_NUMBER = 'cofeprisPesticideNumber';
  public static readonly COFEPRIS_SECTOR = 'cofeprisSector';
  public static readonly EXPIRATION_DATE = 'expirationDate';
  public static readonly HEALTH_REGISTER_FOLIO = 'healthRegisterFolio';
  public static readonly IMPORT_COMPANY = 'importCompany';
  public static readonly IMPORT_LICENSE = 'importLicense';
  public static readonly IMPORT_VUCEM_FOLIO = 'importVUCEMFolio';
  public static readonly LOT = 'lot';
  public static readonly MANUFACTURER = 'manufacturer';
  public static readonly MATTER_TYPE = 'matterType';
  public static readonly NUM_CAS = 'numCAS';
  public static readonly PESTICIDE_MANUFACTURER_COUNTRY_NAME = 'pesticideManufacturerCountryName';
  public static readonly PHARMACEUTICAL_FORM = 'pharmaceuticalForm';
  public static readonly TRANSPORT_SPECIAL_CONDITIONS = 'transportSpecialConditions';
  public static readonly TRADEMARK_MEDICATION = 'trademarkMedication';

  public static readonly FIELDS_SECTOR_01 = [
    FieldsByCofeprisSector.HEALTH_REGISTER_FOLIO,
    FieldsByCofeprisSector.ACTIVE_SUBSTANCE_GENERIC,
    FieldsByCofeprisSector.ACTIVE_INGREDIENT_NAME,
    FieldsByCofeprisSector.CHEMICAL_NAME,
    FieldsByCofeprisSector.MANUFACTURER,
    FieldsByCofeprisSector.EXPIRATION_DATE,
    FieldsByCofeprisSector.LOT,
    FieldsByCofeprisSector.PHARMACEUTICAL_FORM,
    FieldsByCofeprisSector.TRANSPORT_SPECIAL_CONDITIONS,
    FieldsByCofeprisSector.IMPORT_VUCEM_FOLIO,
    FieldsByCofeprisSector.IMPORT_LICENSE,
    FieldsByCofeprisSector.MATTER_TYPE
  ];

  public static readonly FIELDS_SECTOR_02 = [
    FieldsByCofeprisSector.ACTIVE_INGREDIENT_NAME,
    FieldsByCofeprisSector.MANUFACTURER,
    FieldsByCofeprisSector.EXPIRATION_DATE,
    FieldsByCofeprisSector.LOT,
    FieldsByCofeprisSector.PHARMACEUTICAL_FORM,
    FieldsByCofeprisSector.TRANSPORT_SPECIAL_CONDITIONS,
    FieldsByCofeprisSector.HEALTH_REGISTER_FOLIO,
    FieldsByCofeprisSector.IMPORT_VUCEM_FOLIO,
    FieldsByCofeprisSector.IMPORT_LICENSE,
    FieldsByCofeprisSector.MATTER_TYPE,
    FieldsByCofeprisSector.CHEMICAL_NAME
  ];

  public static readonly FIELDS_SECTOR_03 = [
    FieldsByCofeprisSector.MATTER_TYPE,
    FieldsByCofeprisSector.ACTIVE_SUBSTANCE_GENERIC,
    FieldsByCofeprisSector.TRADEMARK_MEDICATION,
    FieldsByCofeprisSector.MANUFACTURER,
    FieldsByCofeprisSector.LOT,
    FieldsByCofeprisSector.PHARMACEUTICAL_FORM,
    FieldsByCofeprisSector.TRANSPORT_SPECIAL_CONDITIONS,
    FieldsByCofeprisSector.HEALTH_REGISTER_FOLIO,
    FieldsByCofeprisSector.EXPIRATION_DATE
  ];

  public static readonly FIELDS_SECTOR_04 = [
    FieldsByCofeprisSector.CHEMICAL_NAME,
    FieldsByCofeprisSector.IMPORT_LICENSE,
    FieldsByCofeprisSector.IMPORT_VUCEM_FOLIO,
    FieldsByCofeprisSector.NUM_CAS,
    FieldsByCofeprisSector.IMPORT_COMPANY,
    FieldsByCofeprisSector.MATTER_TYPE
  ];

  public static readonly FIELDS_SECTOR_05 = [
    FieldsByCofeprisSector.ACTIVE_INGREDIENT_NAME,
    FieldsByCofeprisSector.IMPORT_LICENSE,
    FieldsByCofeprisSector.IMPORT_VUCEM_FOLIO,
    FieldsByCofeprisSector.COFEPRIS_PESTICIDE_NUMBER,
    FieldsByCofeprisSector.ACTIVE_INGREDIENT_MANUFACTURER_COUNTRY_NAME,
    FieldsByCofeprisSector.ACTIVE_INGREDIENT_MAQUILA_COUNTRY_NAME,
    FieldsByCofeprisSector.AUTHORIZED_USE,
    FieldsByCofeprisSector.MATTER_TYPE
  ];
}

export const COFEPRIS_SECTORS_TO_ORDER_CREATE = [
  '01',
  '02',
  '03'
];
