export const SHIPPERS_SHARED_LOAD_PLAN_SEARS = [
  '632e26831416b85307bec716',
  '632e21f8c902c63ed8eaf2a1'
];

export const SHIPPER_SHARED_SHIPMENT_ODOO = [
  '6273e2e584b3ad23e57d9bc3',
  '62753d12212f9de746d16ee6'
];

export const SHIPPER_DICKA = [
  '5f4553fe465e0535da27b45c',
  '5f2c3bcefb19db512bc2663f'
];

export const SHIPPER_ESTAFETA = [
  '631b86c4a1245831e17ee625',
  '631b7e071fa5a4c62a730127'
];
