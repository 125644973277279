export enum NewShipmentRequestLabel {
  account = 'Cuenta',
  accountSelect = 'Selecciona la cuenta',
  action = 'Acción',
  addAnotherDelivery = 'Agregar otro',
  addDeliveries = 'Agregar reparto(s)',
  addDelivery = 'Registrar',
  addDeliveryTitle = 'Registrar un reparto',
  addDetail = 'Agregar parada',
  address = 'Dirección',
  amount = 'Monto a pagar',
  amountRequired = 'El monto es requerido',
  assign = 'Asignar',
  assignBudget = 'Quiero asignar mi presupuesto',
  assignBudgetInput = 'Ingresa la tarifa para tu solicitud (MXN)',
  assignBudgetSubtitle = 'El presupuesto que asignes será visualizado por los transportistas',
  back = 'Regresar',
  boardingRequirement = 'Requisitos especiales para embarque',
  boardingRequirementsInfo = 'Indica qué elementos debe llevar el operador para la carga',
  calculationsError = 'Ocurrió un error al calcular el tiempo estimado y la ruta de la solicitud',
  carrier = 'Transportista',
  carrierGroup = 'Grupo',
  carrierGroupError = 'No se pudieron obtener los grupos transportistas',
  carrierGroupSelect = 'Selecciona el grupo',
  carrierRate = 'Rating del transportista',
  carrierRates = 'Tarifas de Transportistas ',
  carrierSelect = 'Selecciona el transportista',
  carrierTitle = 'Transportista',
  chargeType = 'Tipo de carga',
  chargeTypeRequired = 'El tipo de carga es requerido',
  chargeTypeSelect = 'Selecciona el tipo de carga',
  city = 'Ciudad/Municipio ',
  citySelect = 'Selecciona la ciudad o Municipio ',
  commentsToCarrier = 'Anótalas a continuación:',
  commentsToCarrierPlaceholder = 'Escribe tus observaciones',
  commentsToCarrierQuestion = '¿Tienes observaciones para el transportista?',
  confirmationStep = 'Confirmación',
  contact = 'Contacto',
  continue = 'Continuar',
  counterOffersTooltip = 'Los transportistas pueden enviarte ofertas con base en tu presupuesto',
  date = 'Fecha',
  dateData = 'Fecha y hora ',
  deliveriesRegistered = 'Repartos registrados',
  deliveryInvalidWarning = 'No hay suficientes datos válidos. La creación del reparto se descartará',
  deliveryNumberCol = '#',
  deliveryWarning = 'Los datos ingresados del reparto no han sido guardados',
  destination = 'Destino',
  destinationData = 'Datos de destino',
  detailsData = 'Datos de paradas',
  dialogButton = 'Buscar',
  dialogCancelQuestion = '¿Estás seguro que deseas cancelar el proceso?',
  dialogCancelResume = 'Si cancelas el proceso de creación de Orden, todos los datos ingresados serán borrados',
  dialogCancelTitle =  'Cancelación de creación de Orden',
  dialogConfirmation = 'Si',
  dialogDestinationResume = 'Busca el destino de la orden usando el',
  dialogDestionationTitle = 'Buscar destino',
  dialogOriginTitle = 'Buscar origen',
  dialogRejection = 'No',
  diner = 'Comedor',
  especialRequirements = 'Escribe tus requisitos especiales',
  especialsRequirements = 'Requerimientos adicionales',
  expiration = 'Caducidad de solicitud',
  expirationActivation= 'Activar expiración',
  expirationDate = 'Fecha de expiración',
  expirationTime = 'Hora de expiración',
  expirationTimeError = 'La hora de expiración no puede ser menor a la hora actual',
  getSpecsError = 'Ocurrió un error al buscar la especificación del vehículo',
  getStatesError = 'Ocurrió un error al obtener los estados',
  getVehicleError = 'No se pudo encontrar el tipo de vehículo',
  idNotFoundError = 'No se encontró el id del embarcador',
  insuredCargoInfo = 'Activa esta opción si tu carga tiene un seguro contratado',
  internalReference = 'Referencia interna',
  internalReferenceInfo = 'Puedes asignar una referencia propia de tu operación',
  internet = 'Internet',
  invalidAmountError = 'Sólo se permiten cifras positivas',
  isDestinationTimeinvalid = 'La hora establecida en el destino ya no es válida. Favor de verificar',
  isOriginTimeinvalid = 'La hora establecida en el origen ya no es válida. Favor de verificar',
  isRequired = 'es requerido',
  load = 'Carga asegurada',
  loading = 'Solo maniobras de carga',
  loadingAndUnloading = 'Maniobras de carga y descarga',
  merchandiseType = 'Tipo de Mercancía',
  merchandiseTypeSelect = 'Selecciona el tipo de mercancía',
  messageError = 'No se ha podido crear la solicitud. Por favor revisa que la información ingresada en todos los pasos sea válida',
  minAmountError = 'Solo montos mayores a $0',
  minorTimeDeliveryError = 'la hora origen no puede ser mayor o igual a la hora de este reparto',
  minorTimeDestinationError = 'la hora origen no puede ser mayor o igual a la hora destino',
  moneySign = '$',
  name = 'Nombre ',
  noCoordsMessage = 'No se encontraron coordenadas válidas. La información se guardará, pero el mapa no podrá mostrar la ruta',
  notifyShipper = 'Activar notificación',
  noResultMessage = 'Actualmente esta ubicación no tiene una ruta sugerida por Google. Se guardarán los datos ingresados',
  numberColDelivery = 'Reparto',
  originData = 'Datos de origen',
  originTitle = 'Origen',
  phone = 'Teléfono',
  postalCode = 'Código Postal ',
  publish = 'Publicar',
  publishAndWait = 'Publicar y esperar cotización',
  rateData = 'Datos de tarifa',
  rateOptions = 'rateOption',
  rateStep = 'Tarifa',
  rateTooltip = 'La tarifa debe incluir todos los gastos',
  receiveCounterOffers = 'Recibir contraofertas de mi presupuesto',
  receiveOffers = 'Quiero recibir ofertas',
  receiveOffersSubtitle = 'Te notificaremos cuando un transportista realice la oferta del servicio',
  required = 'requerida',
  requirementsActivation = 'Activar requerimientos',
  responsible = 'Responsable(s)',
  restPlace = 'Área de descanso',
  routeStep = 'Ruta',
  sanitary = 'Sanitarios',
  save = 'Guardar ',
  searchLocationPlaceholder = 'Buscar una ubicación',
  send = 'Enviar',
  sendRequestTo = 'Enviar solicitud a',
  serviceStep = 'Servicio',
  serviceType = 'Tipo de servicio',
  serviceTypeSelect = 'Selecciona el Tipo de Carga',
  services = 'Servicios en ubicación de carga',
  servicesTooltip = 'Indica los servicios disponibles para el operador en la ubicación de carga',
  setServiceTime = 'Configurar ventana de servicio',
  settlement = 'Colonia',
  specification = 'Especificación de vehículo',
  specificationComplete = 'Unidad completa',
  specificationJustCabin = 'Solo cabina',
  specificationJustTrailer = 'Solo remolque',
  specificationSelect = 'Selecciona la Especificación del vehículo',
  state = 'Estado ',
  stateSelect = 'Selecciona el Estado ',
  successMessage = '¡Listo!. La cotización ha sido enviada a Marketplace correctamente.',
  tariffFoundMsg = 'Este monto fue sugerido con base en la tarifa: ',
  termAndConditions = 'Acepto términos y condiciones',
  time = 'Hora',
  timeError = 'La hora debe ser mayor a la actual',
  title = 'Crear solicitud de transporte',
  toReceiveOffers = 'Recibir ofertas',
  transportLine = 'Línea de transporte',
  transportLineSelect = 'Selecciona la línea de transporte',
  typeCommentsToCarries = 'Anótalas a continuación: ',
  ubicationReference = 'Referencias de ubicación',
  unloading = 'Solo maniobras de descarga',
  vehicleTemperature = 'Temperatura del vehículo',
  vehicleTitle = 'Vehículo y mercancía',
  vehicleType = 'Tipo de vehículo',
  vehicleTypeSelect = 'Selecciona el Tipo de Vehículo',
  volume = 'Volumen (m³)',
  weight = 'Peso (kg)'
}
