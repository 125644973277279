export const ReportFilterLabels = {
    _is: 'es',
    accounts: 'Cuentas',
    beginDate: 'Fecha de inicio',
    colon: ':',
    datesRangeType: 'Rango de fechas',
    endDate: 'Fecha de fin',
    maxDays: 30,
    orderStatus: 'Estatus de la orden',
    placeholderCarrier: 'Línea de transporte',
    required: 'requerido',
    search: 'Buscar',
    titleCard: 'Búsqueda',
    zero: 0,
  };
