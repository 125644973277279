export enum UnitOfTime {
  'd' = 'd',
  'day' = 'day',
  'days' = 'days',
  'h' = 'h',
  'hour' = 'hour',
  'hours' = 'hours',
  'M' = 'M',
  'm' = 'm',
  'millisecond' = 'millisecond',
  'milliseconds' = 'milliseconds',
  'minute' = 'minute',
  'minutes' = 'minutes',
  'month' = 'month',
  'months' = 'months',
  'ms' = 'ms',
  's' = 's',
  'second' = 'second',
  'seconds' = 'seconds',
  'w' = 'w',
  'week' = 'week',
  'weeks' = 'weeks',
  'y' = 'y',
  'year' = 'year',
  'years' = 'years'
}
