import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';

const apiUrl = environment.apiUrl;

@Injectable()
export class VehicleControlProvider {

  constructor(private http: HttpClient,
    private _appService: AppService) {  }

  public async getShipperData(shipperId: string): Promise<Object> {
    return this.http.get<Object>(apiUrl + '/embarcadores/' + shipperId).toPromise();
  }

  public async getCarrierData(carrierId: string): Promise<Object> {
    return this.http.get<Object>(apiUrl + '/lineaTransporte/' + carrierId).toPromise();
  }
}
