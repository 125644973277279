import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { AppService } from '../../../../../app/app.service';
import { AssociateShipmentsConstants, DISPLAY_COLUMNS } from '../dialog-associate-shipment/dialog-associate-shipment.constants';
import { CarrierProvider } from '../../../../../app/providers/carrier/carrier.provider.service';
import { ConfirmationProperties } from '../../../../components/marketplace/confirmation/confirmation.properties';
import { DialogAssociateShipmentLabels } from '../../dialog-marketplace/dialog-associate-shipment/dialog-associate-shipment.labels';
import { DialogAssociateShipmentsTags } from '../../../../interfaces/dialog-associate-shipments';
import {
  DialogMarketplaceConfirmComponent
} from '../../../../components/dialog/dialog-marketplace/dialog-marketplace-confirm/dialog-marketplace-confirm.component';
import { MarketplaceService } from '../../../../services/marketplace/marketplace.service';
import {
  MarketplaceShipperRequestProvider
} from '../../../../providers/marketplace-shipper-request/marketplace-shipper-request.provider.service';
import { PaginatorService } from '../../../../services/paginator/paginator.service';
import { SearchShipmentBody, WarehouseDefault } from '../../../../interfaces';
import { ShipmentData } from '../../../../interfaces/marketplace-shipper/shipment-request';
import { ShipmentProvider } from '../../../../providers/shipments/shipment-provider.service';
import { ShipmentRequestResponse } from '../../../../interfaces/marketplace-shipper/shipment-request';
import { Shipments } from '../../../../interfaces/shipment';
import { ToastrAlertsService } from '../../../../services/utils/toastr-alerts.service';
import { UserService } from '../../../../providers/user/user.service';

import { DialogData } from 'scf-library/lib/scf-lib/scf-dialog/scf-dialog.interface';
import * as moment from 'moment';

@Component({
  selector: 'app-dialog-associate-shipment',
  templateUrl: './dialog-associate-shipment.component.html',
  styleUrls: ['./dialog-associate-shipment.component.scss']
})
export class DialogAssociateShipmentComponent implements OnInit {
  @Input() public shipmentRequests: ShipmentRequestResponse;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  public beginDate: Date;
  public dataSource: MatTableDataSource<Shipments>;
  public dataUpdatedShipment: Shipments;
  public dateFormat: string;
  public destinationsNumber: number;
  public displayedColumns: Array<string>;
  public displayNoShipments: boolean;
  public endDate: Date;
  public filteredShipments: Array<Shipments>;
  public hidePaginator: boolean;
  public invalidMinBudget: boolean;
  public itemsCount: number;
  public labels: DialogAssociateShipmentsTags;
  public minDate: Date;
  public originDate: string;
  public originDateData: Date;
  public originTime: string;
  public requestDateFormat: string;
  public routeIcon: string;
  public shipmentId: string;
  public shipmentRequestData: ShipmentRequestResponse;
  public shipmentSelected: Shipments;
  public shipments: Array<Shipments>;
  public shipperOid: string;
  public timeFormat: string;
  public userName: string;
  public warehouseDefault: Array<string>;
  public warehouses: Array<WarehouseDefault>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,

    private marketplaceShipperRequestProvider: MarketplaceShipperRequestProvider,
    private paginatorService: PaginatorService,
    private router: Router,
    private toast: ToastrAlertsService,
    private userService: UserService,
    public appService: AppService,
    public carrierProvider: CarrierProvider,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogAssociateShipmentComponent>,
    public marketplaceService: MarketplaceService,
    public shipmentProvider: ShipmentProvider,
  ) {
    this.labels = DialogAssociateShipmentLabels;
    this.routeIcon = ConfirmationProperties.originDestination;
    this.shipmentRequestData = data as ShipmentRequestResponse;
    this.originDateData = this.shipmentRequestData.origin.loadDate;
    this.shipperOid = this.appService.getShipperOid();
    this.timeFormat = AssociateShipmentsConstants.TIME_FORMAT;
    this.dateFormat = AssociateShipmentsConstants.DATE_FORMAT;
    this.destinationsNumber = this.shipmentRequestData.destinations.length;
    this.filteredShipments = [];
    this.endDate = new Date();
    this.beginDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() -
                     AssociateShipmentsConstants.DAYS_TO_SUBTRACT);
    this.minDate = new Date();
    this.invalidMinBudget = false;
  }

  /**
   * @description initialize the dialog and generates information to display and process shipments
   */
  public async ngOnInit(): Promise<void> {
    this.getDateInfo();
    this.shipmentSelected = null;
    this.hidePaginator = true;
    this.toast.processingAlert();
    await this.getWarehouse();
    await this.getShipments();
    this.toast.closeProcessing();
    if (this.filteredShipments.length === 0) {
      this.displayNoShipments = true;
      this.toast.warningAlert(DialogAssociateShipmentLabels.noShipmentsFound);
    } else {
      this.displayNoShipments = false;
      this.hidePaginator = false;
      this.displayedColumns = DISPLAY_COLUMNS;
      this.dataSource = new MatTableDataSource(this.filteredShipments);
      this.paginatorService.paginatorLabels(this.paginator);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = (data, filter) => {
        const dataToSearch = data._id + data.shipmentId + data.status +
        data.origin + data.orders;
        return dataToSearch.indexOf(filter) !== -1;
      };
    }
  }

  /**
   * @description Obtains the date info from request and generate the time and date format
   */
  public getDateInfo(): void {
    this.originDate = moment(this.originDateData, AssociateShipmentsConstants.REQUEST_DATE_FORMAT).format(this.dateFormat);
    this.originTime = moment(this.originDateData, AssociateShipmentsConstants.REQUEST_DATE_FORMAT).format(this.timeFormat);
  }

  /**
   * @description receives data of shipment selected
   * @param {Shipments} shipment data of shipment selected
   */
  public selectShipment(shipment: Shipments): void {
    this.shipmentSelected = shipment;
  }

  /**
   * @description method to display a modal to confirm de association of request whit the shipment selected
   */
  public confirmSelection(): void {
    const dialogRef = this.dialog.open(DialogMarketplaceConfirmComponent, {
      data: {
        textButton1: DialogAssociateShipmentLabels.cancel,
        textButton2: DialogAssociateShipmentLabels.accept,
        iconPath: AssociateShipmentsConstants.MODAL_ICON,
        focusQuestion: DialogAssociateShipmentLabels.modalFlag,
        question: DialogAssociateShipmentLabels.modalQuestion,
        resume: DialogAssociateShipmentLabels.modalResumeBegin + this.shipmentRequestData.identifier +
                DialogAssociateShipmentLabels.modalResumeToShipment + this.shipmentSelected.shipmentId +
                DialogAssociateShipmentLabels.modalResumeEnd,
        title: DialogAssociateShipmentLabels.modalTitle
      },
      width: AssociateShipmentsConstants.MODAL_SIZE
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result[0] === AssociateShipmentsConstants.CONFIRM) {
        this.onClose();
        this.toast.processingAlert();
        this.dataUpdatedShipment = this.shipmentSelected;
        this.processShipment();
      }
    });
  }

  /**
   * @description init the process of update of the status and assignation of transport data to shipment
   */
  public async processShipment(): Promise<void> {
    try {
      await this.updateSentToTenderingStatus();
    } catch (error) {
      this.toast.closeProcessing();
      this.toast.errorAlert(DialogAssociateShipmentLabels.noEnoughData);
    }
  }

  /**
   * @description method to get the shipments and then filter it to get only shipments available to associate the request
   */
  public async getShipments(): Promise<void> {
    const dateInterval: SearchShipmentBody = {
      beginDate: this.beginDate,
      endDate: this.endDate,
      warehousesId: this.warehouseDefault
    };
    this.shipments = await this.shipmentProvider.shipmentSearch(this.shipperOid, dateInterval);
    this.filteredShipments = this.shipments.filter(element => {

      return element.origin.name === this.shipmentRequestData.origin.name &&
             element.orders.length === this.shipmentRequestData.destinations.length && element.sentToTendering === false &&
             element.status === AssociateShipmentsConstants.SHIPMENT_STATUS && element.tripType !== AssociateShipmentsConstants.TRYP_TYPE;
    });
  }

  /**
   * @description gets the warehouse of shipper
   */
  public async getWarehouse(): Promise<void> {
    this.warehouseDefault = [];
    const userOid = this.appService.getUserOid();
    const user = await this.userService.getUserById(userOid);
    this.warehouseDefault = (user.warehouseDefault) ? [user.warehouseDefault._id] : [];
  }

  /**
   * @description generate the shipment body and updates the status of shipment sent to tendering to true
   */
  private async updateSentToTenderingStatus(): Promise<void> {
    this.shipmentId = this.shipmentSelected.shipmentId;
    this.userName = this.shipmentSelected.userId;
    this.shipmentSelected.sentToTendering = true;
    this.shipmentSelected.transport.driver = this.shipmentRequestData.transport.driver;
    this.shipmentSelected.transport.plates = this.shipmentRequestData.transport.plates;
    this.shipmentSelected.transport.vehicle = this.shipmentRequestData.transport.vehicle;
    this.shipmentSelected.transport.transportCarrier = this.shipmentRequestData.acceptedCarrier.carrier.name;
    this.shipmentSelected.status = AssociateShipmentsConstants.ASSIGNED_STATUS;
    this.saveShipmentData();
    try {
      await this.shipmentProvider.updateShipment(this.shipperOid, this.shipmentId, this.shipmentSelected, this.userName);
      await this.marketplaceShipperRequestProvider.sendMarketplaceAssignedShipmentRequest(this.shipmentRequestData.id,
            this.shipmentRequestData);
      this.toast.closeProcessing();
      this.toast.successAlert(DialogAssociateShipmentLabels.successMessageBegin + this.shipmentRequestData.identifier +
                              DialogAssociateShipmentLabels.successMessageEnd + this.shipmentSelected.shipmentId);
      this.redirectToShipmentView();
    } catch (error) {
      this.toast.closeProcessing();
      this.toast.errorAlert(DialogAssociateShipmentLabels.errorMessageUpdate);
    }
  }

  /**
   * @description Get the object for shipment request to update
   * @returns {ShipmentRequestResponse} The object shipment request
   */
  private saveShipmentData(): ShipmentRequestResponse {
    this.shipmentRequestData.shipment = this.generateShipmentData();

    return this.shipmentRequestData;
  }

  /**
   * @description creates the shipment data of request with data of shipment selected
   * @returns {ShipmentData} shipmentBody data of shipment created
   */
  public generateShipmentData(): ShipmentData {
    const shipmentBody: ShipmentData = {
      id: this.shipmentSelected._id,
      shipmentId: this.shipmentSelected.shipmentId,
    };

    return shipmentBody;
  }

  /**
   * @description Redirects to the shipment view
   */
  public redirectToShipmentView(): void {
    this.router.navigateByUrl(AssociateShipmentsConstants.SLASH, { skipLocationChange: true }).then(() =>
    this.router.navigate([AssociateShipmentsConstants.SHIPPER_REQUESTS_VIEW]));
  }

  /**
   * @description Close the modal
   */
  public onClose(): void {
    this.dialogRef.close();
  }
}
