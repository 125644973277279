import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AccountProvider } from '../../providers/accounts/account-provider.service';
import { AppService } from '../../app.service';
import { DialogInputComponent } from '../../components/dialog/dialog-input/dialog-input.component';
import { OrderPrioritizationBodyRequest, OrderPrioritizationBody } from '../../interfaces/index';
import { OrderPrioritizationLabels } from './order-prioritization.labels';
import { OrderPrioritizationProperties } from './order-prioritization.properties';
import { ServerlessProvider } from '../../providers/serverless/serverless-provider.service';
import { Shipments } from '../../interfaces/shipment';
import { ToastrAlertsService } from '../utils/toastr-alerts.service';

import { map } from 'rxjs/operators';

const CLOSED = 'Closed';
const DATE_FORMAT = 'yyyy-MM-dd HH:mm';
const LOCALE = 'es-US';
const TEP = 'TEP';
// TODO Delete VALID_TENANTS const when a properly method  is created for FTP connection
const VALID_TENANTS = ['6037d9cc4fe6993def62da45', '5f877f16b6c21a48cf2c49ff'];

@Injectable()
export class OrderPrioritizationService {

  constructor(
    private _appService: AppService,
    private accountProvider: AccountProvider,
    private dialog: MatDialog,
    private serverlessProvider: ServerlessProvider,
    private toast: ToastrAlertsService
  ) {}

  /**
   * @description Send a request to Serverless in order to create a file in FTP
   * @param {Array<Shipments>} shipments All Shipping Data to send
   * @param {Date} deliveryDateToShipments Delivery date on which it will be delivered to shipments
   */
  public async createOrderPrioritizationCSVFile(shipments: Array<Shipments>, deliveryDateToShipments: Date): Promise<void> {
    const data = await this.bodyCreation(shipments, deliveryDateToShipments);
    await this.serverlessProvider.orderPrioritization(data)
    .then(() => {
      this.toast.successAlert(OrderPrioritizationLabels.toastMessageFileCreatedSuccess);
    })
    .catch(err => {
      this.toast.errorAlert(OrderPrioritizationLabels.toastMessageFileCreatedError);
      throw new Error (err);
    });
  }

  /**
   * @description Create the body to be send to the CSV Azure function
   * @param {Array<Shipments>} shipments Shipments that contains all data including orders info
   * @param {Date} deliveryDateToShipments Delivery date on which it will be delivered to shipments
   * @returns {OrderPrioritizationBodyRequest} The body to be send to Azure function
   */
  public async bodyCreation(shipments: Array<Shipments>, deliveryDateToShipments: Date): Promise<OrderPrioritizationBodyRequest> {
    const accountList =  await this.accountProvider.getAccounts();
    const currentWarehouse = (await this._appService.getUserWarehouse()).name;
    const shipmentsToCreate = [];

    for (const shipment of shipments) {
      const ordersToSend: OrderPrioritizationBody = {
        data: [],
        title: shipment.shipmentId
      };

      for (const order of shipment.orders) {
        const orderAux = [];
        orderAux.push(order.orderId,
        accountList.cuentas.find((accountInOrder) => accountInOrder._id === order.account._id).prefix !== '' ?
        accountList.cuentas.find((accountInOrder) => accountInOrder._id === order.account._id).prefix : TEP,
        (new DatePipe(LOCALE).transform(deliveryDateToShipments, DATE_FORMAT)),
        shipment.shipmentId);
        ordersToSend.data.push(orderAux);
      }

      shipmentsToCreate.push(ordersToSend);
    }

    const orderPrioritizationRequest: OrderPrioritizationBodyRequest = {
      ftpConfig: {},
      warehouse: currentWarehouse,
      shipmentsToCreate: shipmentsToCreate
    };

    return orderPrioritizationRequest;
  }

  /**
   * @description Open Dialog-Input to get date and hour which will be set in deliveryToShipment field at Order-Prioritization interface
   * @param {Array<Shipments>} shipments The shipment that will be create at FTP
   */
  public async setDeliveryDateToShipments(shipments: Array<Shipments>): Promise<void> {
    let deliveryDateToShipments;
    const dialogRef = this.dialog.open(DialogInputComponent, {
      data: {
        title: OrderPrioritizationLabels.dialogInputTitle,
        button1: OrderPrioritizationLabels.dialogInputButton
      },
      disableClose: true,
      width: OrderPrioritizationProperties.dialogInputWidth,
    });

    await dialogRef.afterClosed().pipe(map(async result => {
      if (result === CLOSED || result === undefined) {
        this.toast.warningAlert(OrderPrioritizationLabels.toastMessageFileWarning);
      } else {
        deliveryDateToShipments = result;
        await this.createOrderPrioritizationCSVFile(shipments, deliveryDateToShipments);
      }
    })).toPromise();
  }

  /**
   * @description Validate if the current userOId is valid for a FTP connection
   * @returns {Promise<boolean>} If the current userOId has a valid connection or not
   */
  public async userInterfaceValidation(): Promise<boolean> {
    // TODO set correct values to determinate if a user has connection with FTP
    const currentShipper = this._appService.getShipperOid();
    if (VALID_TENANTS.includes(currentShipper)) {
      return true;
    } else {
      return false;
    }
  }

}
