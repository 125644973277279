import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

import { AppService } from '../../../app.service';
import { environment } from '../../../../environments/environment';

import { CookieService } from 'ngx-cookie-service';

const ALLOWED_PROFILES = ['Administrador', 'Sysadmin'];
const PATH = '/biis/admin/embarcadores';

@Component({
  selector: 'app-shippers',
  templateUrl: './shippers.component.html',
  styleUrls: ['../../../app.component.scss']
})
export class ShippersComponent implements OnInit {
  public url: string = environment.clientUrl + PATH + '?us=';
  public urlSafe: SafeResourceUrl;

  constructor(
    private _appService: AppService,
    private cookieService: CookieService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkPermission();
  }

  public checkPermission(): void {
    const profile = this._appService.getProfile();
    if (ALLOWED_PROFILES.includes(profile)) {
      const petition = this.cookieService.get('auth');
      const us = JSON.parse(petition);
      us.path = PATH;
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + JSON.stringify(us));
    } else {
      this.router.navigateByUrl('/no-permission');
    }
  }
}
