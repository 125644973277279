import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const USERLANGUAGECOOKIE = 'language';
const LANGUAGEKEY_ES = 'es';

@Injectable()
export class LanguageTranslateService {
  constructor(private _translateService: TranslateService) {}

  /**
   * @description Get labels data from JSON files
   * @returns {Promise<any>} Labels
   */
  public async getLanguageLabels(labelsName: string): Promise<any> {
    return this._translateService
      .get(labelsName)
      .toPromise()
      .then((result: string) => {
        // Covert string labels to JSON
        return eval(result);
      })
      .catch((err) => console.error(err));
  }

  /**
   * @description Sets a new language according to the key passed argument.
   * If not language param, sets the language stored in local storage or the default language (es).
   * @param languageKey The new language to set.
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    let keyLanguageToSet;

    if (languageKey) {
      keyLanguageToSet = languageKey;
    } else {
      keyLanguageToSet = localStorage.getItem(USERLANGUAGECOOKIE) || 'es';
    }

    localStorage.setItem(USERLANGUAGECOOKIE, keyLanguageToSet);
    this._translateService.use(keyLanguageToSet);
  }
  /**
   * @description Gets a new language according to the key passed argument.
   * If not language param, sets the language stored in local storage or the default language (es).
   * @param languageKey The new language to set.
   * @return {string} Current language key
   */
  public getLanguage(languageKey?: string): string {
    let keyLanguageToGet;
    if (languageKey) {
      keyLanguageToGet = languageKey;
    } else {
      keyLanguageToGet = localStorage.getItem(USERLANGUAGECOOKIE) || LANGUAGEKEY_ES;
    }
    return keyLanguageToGet;
  }
}
