<mat-card mat-dialog-title>
  <mat-card-content>
    <mat-grid-list
      cols="5"
      rowHeight="3rem"
      mat-dialog-title>
      <mat-grid-tile>
        <span class="dialog-warning-icon-title">
          <mat-icon class="warning-icon-class">report_problem</mat-icon>
        </span>
      </mat-grid-tile>
      <mat-grid-tile colspan="4">
        <span class="dialog-title">
          <p>{{ data.title }}</p>
        </span>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>

<mat-dialog-content class="mat-dialog-section-class">
  <mat-card>
    <mat-card-content class="warning-msg-section">
      <div
        *ngFor="let msg of data.warningMessages;
        let indexPosition = index">
        <span class="warning-text">
          {{ indexPosition + 1 }}.- 
        </span>
        <span class="focus-text">
          {{ msg.focusText }}
        </span>
        <span class="warning-text">
          {{ msg.text }}
        </span>
        <p *ngFor="let element of msg.subElements" class="warning-text">
          • {{ element }}
          <br>
        </p>
        <p></p>
      </div>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>

<div class="cards-container-button">
  <div class="buttons">
    <button
      id="dscw-cancel-button"
      class="secondary-button cancel-button-class"
      mat-button
      mat-dialog-close>
      {{ data.cancel }}
    </button>
    <button
      id="dscw-continue-button"
      class="primary-button"
      (click)="onContinue()"
      mat-button>
      {{ data.continue }}
    </button>
  </div>
</div>
