import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { AUTHENTICATION_CONSTANTS } from '../constants/authentication.constants';
import { IMenuInterface } from '../interfaces';
import { INTERNAL_ROUTES } from './internal-routes';
import { LocalStorageService } from '../services/utils/local-storage.service';
import { SsoAuthenticationService } from '../services/authentication/sso-authentication.service';

const KEY_ALLOWEDROUTES = 'allowed_routes';
const KEY_ITEMS = 'items';
const KEY_ROUTERLINK = 'routerLink';

const ROUTE_HOME = '/home';
const ROUTE_LOGIN = '/supplynet-login';
const ROUTE_NOPERMISSION = '/no-permission';

/**
 * @class
 * @description - SSO Authentication Guard Class.
 */
@Injectable({ providedIn: 'root' })
export class SsoAuthGuard implements CanActivate {
  private url: string | undefined = '';

  /**
   * @description - Creates an instance of SsoAuthGuard class.
   * @param { LocalStorageService } localStorageService - Local storage service.
   * @param { Router } router - Router service.
   * @param { SsoAuthenticationService } ssoAuthService - SSO Authentication service.
   */
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private ssoAuthService: SsoAuthenticationService
  ) {}

  /**
   * @description - Checks if the route to navigate can be activated.
   * @param { ActivatedRouteSnapshot } route - Route associated with the component loaded.
   * @param { RouterStateSnapshot } state - State of the router at a moment in time.
   *
   * @returns { boolean } - Whether the route is activated or not.
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.url = state.url;
    if (this.checkLogin()) {
      return this.checkPermissions();
    }

    return false;
  }

  /**
   * @description - Checks if the user is logged in, otherwise redirects to login.
   *
   * @returns { boolean } - Whether the user is logged in or not.
   */
  private checkLogin(): boolean {
    const isTepAuthenticated = this.localStorageService.getItem(
      AUTHENTICATION_CONSTANTS.IS_TEP_AUTHENTICATED
    ) === 'true';

    if (this.ssoAuthService.isAuthenticated() && isTepAuthenticated) {
      return true;
    } else {
      this.router.navigateByUrl(ROUTE_LOGIN);

      return false;
    }
  }

  /**
   * @description - Checks if user has the allowed permissions to activate the route,
   * otherwise redirects to no-permission page.
   *
   * @returns { boolean } - Whether the user has permissions or not.
   */
  private checkPermissions(): boolean {
    if (this.validPath()) {
      return true;
    } else {
      this.router.navigateByUrl(ROUTE_NOPERMISSION);

      return false;
    }
  }

  /**
   * @description - Gets all available routes in localStorage and validates
   * if current url is among them.
   *
   * @returns { boolean } - Whether the route is valid or or not.
   */
  private validPath(): boolean {
    let isPathValid: boolean;
    const routes = [];
    const allowedModules = this.localStorageService.getItemAsObject(KEY_ALLOWEDROUTES);

    for (const module of allowedModules) {
      if (module.hasOwnProperty(KEY_ROUTERLINK)) {
        routes.push('/' + module.routerLink);
      }
      if (module.hasOwnProperty(KEY_ITEMS)) {
        module.items.forEach((route: IMenuInterface) => {
          return routes.push('/' + route.routerLink);
        });
      }
      for (const invisibleRoute of INTERNAL_ROUTES) {
        if (invisibleRoute.module === module.icon) {
          for (const path of invisibleRoute.routes) {
            routes.push(path);
          }
        }
      }
    }

    if (!routes.find((path: string) => {
      return path === ROUTE_HOME;
    })) {
      routes.push(ROUTE_HOME);
    }

    for (const singlePath of routes) {
      if (this.url.startsWith(singlePath)) {
        isPathValid = true;
      } else {
        isPathValid = false;
      }
      if (isPathValid) {
        break;
      }
    }

    return isPathValid;
  }
}
