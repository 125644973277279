import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';
import { AdditionalCharge, AdditionalChargeResponse, AdditionalChargeSearch } from '../../interfaces/additional-charge';
import { environment } from '../../../environments/environment';

const tmsFreightCostApiUrl = environment.tmsFreightCostApiUrl;

@Injectable()
export class AdditionalChargeProvider {

  constructor(private http: HttpClient, private _appService: AppService) { }

  /**
   * @description Gets all related shipper additional charges by given shipperId
   * @param {string} shipperId - Optional Param to get related accounts
   * @returns {Promise<AdditionalChargeResponse>} Shipper found accounts
   */
  public async getAdditionalCharges(shipperId?: string): Promise<AdditionalChargeResponse> {
    const shipperOid = shipperId ?? this._appService.getShipperOid();
    return await this.http.get<AdditionalChargeResponse>(`${tmsFreightCostApiUrl}/additionalCharges/shipper/${shipperOid}`).toPromise();
  }

  /**
   * @description Generate request to create an additional charge
   * @param {AdditionalCharge} additionalCharge to send in the request
   * @returns {Promise<AdditionalChargeResponse>} response of shipper
   */
  public async createAdditionalCharge(additionalCharge: AdditionalCharge): Promise<AdditionalChargeResponse> {
    return await this.http.post<AdditionalChargeResponse>(`${tmsFreightCostApiUrl}/additionalCharges/`, additionalCharge).toPromise();
  }

  /**
   * @description Generate request for edit the additional charge
   * @param {AdditionalCharge} additionalCharge to send in the request
   * @returns {Promise<AdditionalChargeResponse>} response of shipper
   */
  public async updateAdditionalCharge(additionalCharge: AdditionalCharge): Promise<AdditionalChargeResponse> {
    return await this.http.put<AdditionalChargeResponse>(`${tmsFreightCostApiUrl}/additionalCharges/${additionalCharge.id}`, additionalCharge).toPromise();
  }

  /**
   * @description Gets additional charges by specific criteria.
   * @param {AdditionalChargeSearch} additionalChargeSearch - Criteria to search additional charges.
   * @returns {AdditionalChargeResponse} Additional charges found.
   */
  public async searchAdditionalCharge(additionalChargeSearch: AdditionalChargeSearch): Promise<AdditionalChargeResponse> {
    return await this.http.post<AdditionalChargeResponse>(
      `${tmsFreightCostApiUrl}/additionalCharges/shipper/${additionalChargeSearch.shipperId}/search`, additionalChargeSearch
    ).toPromise();
  }
}