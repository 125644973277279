<mat-grid-list cols="3" rowHeight="1:.3">
  <mat-grid-tile colspan="2">
    <span class="dialog-title">
      <p>{{ 'dialogOrderDetailLabels.title' | translate }} <span class="focus-text">{{ data.identifier }}</span></p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1">
    <mat-icon
      style="cursor: pointer"
      class="dialog-close-icon"
      (click)="onClickClose()">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>

<div>
  <table aria-describedby="table-order-detail" class="table-order-detail">
    <ul>
      <th id="th-order-detail" colspan="2">
        <img src="../../../../assets/origin-destination-icon.svg" alt="Origin - Destination">
      </th>

      <tr>
        <td>
          <li>
            {{ 'dialogOrderDetailLabels.origin' | translate }}:
            <span class="highlighted-text">{{ data.origin }}</span>
          </li>
        </td>
        <td>
          <li>
            {{ 'dialogOrderDetailLabels.destination' | translate }}:
            <span class="highlighted-text">{{ data.destination }}</span>
          </li>
        </td>
      </tr>
      <tr>
        <td>
          <li>
            {{ 'dialogOrderDetailLabels.account' | translate }}:
            <span class="focus-text">{{ data.account.name }}</span>
          </li>
        </td>
        <td>
          <li>
            {{ 'dialogOrderDetailLabels.date' | translate }}:
            <span class="highlighted-text">{{ data.date | date : 'dd/MM/yyyy' }}</span>
          </li>
        </td>
      </tr>
      <tr>
        <td>
          <li>
            {{ 'dialogOrderDetailLabels.folioOrder' | translate }}:
            <span class="highlighted-text">{{ data.folio }}</span>
          </li>
        </td>
        <td>
          <li>
            {{ 'dialogOrderDetailLabels.dateHour' | translate }}:
            <span class="highlighted-text">
              {{ data.appointmentHour ? data.appointmentHour : 'dialogOrderDetailLabels.noDate' | translate }}
            </span>
          </li>
        </td>
      </tr>
      <tr>
        <td>
          <li>
            {{ 'dialogOrderDetailLabels.status' | translate }}:
            <span class="highlighted-text">{{ getTranslatedOrderStatusLabel(data.status) }}</span>
          </li>
        </td>
        <td>
          <li>
            {{ 'dialogOrderDetailLabels.temperature' | translate }}:
            <span class="highlighted-text">
              {{ getTemperatureDescriptionByCode(data.temperature) ? getTemperatureDescriptionByCode(data.temperature) :
              'dialogOrderDetailLabels.noData' | translate }}
            </span>
          </li>
        </td>
      </tr>
      <div *ngIf="isCourierDetail">
        <tr>
          <td>
            <li>
              {{ 'dialogOrderDetailLabels.serviceType' | translate }}:
              <span class="highlighted-text">{{ data.serviceType }}</span>
            </li>
          </td>
        </tr>
        <tr>
          <td>
            <li>
              {{ 'dialogOrderDetailLabels.guidesType' | translate }}:
              <span class="highlighted-text">{{ data.guidesType }}</span>
            </li>
          </td>
        </tr>
      </div>
    </ul>
  </table>

  <div class="order-quantities-container">
    <div class="partial-text-divider">
      <div class="subtitle">
        <span>{{ 'dialogOrderDetailLabels.quantitiesData' | translate }}</span>
      </div>
      <div class="divider">
        <hr>
      </div>
    </div>

    <table aria-describedby="table-resume-indicator" class="table-resume-indicators">
      <th id="th-resume-indicators"></th>
      <tr>
        <td class="number-indicator">{{ data.boxes }}</td>
        <td class="text-indicator">{{ 'dialogOrderDetailLabels.boxes' | translate }}</td>
        <td class="number-indicator">{{ data.pieces }}</td>
        <td class="text-indicator">{{ 'dialogOrderDetailLabels.pieces' | translate }}</td>
        <td class="number-indicator">{{ data.pallets }}</td>
        <td class="text-indicator">{{ 'dialogOrderDetailLabels.pallets' | translate }}</td>
        <td class="number-indicator">{{ data.weight }}</td>
        <td class="text-indicator">{{ 'dialogOrderDetailLabels.kilo' | translate }}</td>
        <td class="number-indicator">{{ data.volume }}</td>
        <td class="text-indicator">{{ 'dialogOrderDetailLabels.meter' | translate }}<sup>{{ 'dialogOrderDetailLabels.three' | translate }}</sup></td>
      </tr>
    </table>
  </div>
</div>
