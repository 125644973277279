import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { RegionSearch, WarehouseBody, WarehouseName } from '../../interfaces';
import { UrlBuilderService } from '../../services/utils/url-builder.service';

const apiUrl = environment.apiUrl;

@Injectable()

/**
 * Service containing all endpoint calls related to warehouses.
 */
export class WarehouseProvider {
  /**
   * @description Builds an instance of all necessaries services for correctly work of this component.
   * @param { HttpClient } http - Plugin to make requests to backend.
   * @param { UrlBuilderService } urlBuilderService - Service to build URL's.
   * @param { AppService } _appService - Main services.
   */
  constructor(
    private http: HttpClient,
    private urlBuilderService: UrlBuilderService,
    private _appService: AppService
  ) { }

  public async createWarehouse(warehouseBody: any): Promise<object> {
    const shipperOid = this._appService.getShipperOid();
    return await this.http.post<object>(apiUrl + '/shipper/' + shipperOid + '/warehouse', warehouseBody).toPromise();
  }

  public async getWarehouse(getShipperOid?: string): Promise<Array<WarehouseBody>> {
    const shipperOid = this._appService.getShipperOid();
    const routeParams = {
      shipper: (getShipperOid === undefined) ? shipperOid : getShipperOid,
      warehouse: null
    };
    const queryParams = { isActive: true };
    const url = this.urlBuilderService.buildUrl(apiUrl, routeParams, queryParams);

    return await this.http.get<Array<WarehouseBody>>(url).toPromise();
  }

  public async updateWarehouse(warehouseOid: string, body: WarehouseBody): Promise<Array<WarehouseBody>> {
    const shipperOid = this._appService.getShipperOid();
    return await this.http.put<Array<WarehouseBody>>(apiUrl + '/shipper/' + shipperOid + '/warehouse/' + warehouseOid, body).toPromise();
  }

  public async getWarehouseByOid(warehouseOid: string): Promise<WarehouseBody> {
    const shipperOid = this._appService.getShipperOid();
    return await this.http.get<WarehouseBody>(apiUrl + '/shipper/' + shipperOid + '/warehouse/' + warehouseOid).toPromise();
  }

  public async getWarehouseByName(warehouseName: string) {
    const shipperId = this._appService.getShipperOid();
    return await this.http.get<WarehouseBody>(`${apiUrl}/shipper/${shipperId}/warehouse?warehouseName=${warehouseName}`).toPromise();
  }

  public getRelatedUserWarehouses(): Observable<Array<WarehouseBody>> {
    const userOId = this._appService.getUserOid();
    const shipperOId = this._appService.getShipperOid();
    return this.http.get<Array<WarehouseBody>>(`${apiUrl}/shipper/${shipperOId}/user/${userOId}/warehouses`);
  }

  /**
   * @description Get RFC warehouses by shipperOid
   * @param {string} shipperOid Current shipper oid provided
   * @returns {Array<string>} An array with warehouses rfc
   */
  public async getRFCWarehousesByShipper(shipperOid: string): Promise<Array<string>> {
    return this.http.get<Array<string>>(`${apiUrl}/shipperOid/${shipperOid}/getRFCWarehouseByShipper`).toPromise();
  }

  /**
   * @description Gets warehouses by coincidence name.
   * @param {string} warehouseName - Warehouse name to search.
   * @param {RegionSearch} regionSearchParam - Regions identifiers and user Object ID.
   * @returns {Array<WarehouseName>} Warehouses names found.
   */
  public async getWarehouseByCoincidenceName(warehouseName: string, regionSearchParam?: RegionSearch): Promise<Array<WarehouseName>> {
    const shipperId = this._appService.getShipperOid();

    return await this.http.post<Array<WarehouseName>>(`${apiUrl}/shippers/${shipperId}/warehouseName/${warehouseName}`, regionSearchParam).toPromise();
  }
}
