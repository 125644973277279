import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent {
  @Input() generalResume: any;
  @Input() shipmentBillingDetails: any;
  @Input() pdfPrinted: any;
  @Input() sumShipmentBilling: any;
  @Input() comments: string;
}
