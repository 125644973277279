import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TrailerModel } from '../../../app/interfaces/trailers';
import { environment } from '../../../environments/environment';

const apiUrl = environment.apiUrl;

@Injectable()
export class TrailerProvider {
  constructor(private http: HttpClient) {}

  /**
   * @description Trailer data mapping.
   * @param {TrailerModel} trailerBody Incoming data from the CRUD functions.
   * @param {boolean} disable Optional value to logically delete a trailer from database.
   * @return {TrailerModel} Mapped trailer data.
   */
  private returnCoreBody(trailerBody: TrailerModel, disable?: boolean): TrailerModel {
    const coreBody = {
      _id: undefined,
      availability: trailerBody.availability,
      carrier: trailerBody.carrier,
      creationDate: trailerBody.creationDate,
      isActive: trailerBody.isActive,
      lastTimeEdit: trailerBody.lastTimeEdit,
      lastUserEditId: trailerBody.lastUserEditId,
      plates: trailerBody.plates,
      trailerSpecifics: trailerBody.trailerSpecifics,
      trailerType: trailerBody.trailerType,
      unitNumber: trailerBody.unitNumber,
      userId: trailerBody.userId
    };
    if (trailerBody._id) {
      coreBody._id = trailerBody._id;
    }
    if (disable) {
      coreBody.isActive = false;
    }
    return coreBody;
  }

  /**
   * @description Gets all the trailers available for the specified carrier.
   * @param {string} carrierOid Actual carried Id.
   * @return {Promise<Array<TrailerModel>>} Available trailer list.
   */
  public async getCarrierTrailers(carrierOid: string): Promise<Array<TrailerModel>> {
    return this.http.get<Array<TrailerModel>>(`${apiUrl}/carrier/${carrierOid}/trailers`)
      .toPromise();
  }

  /**
   * @description Creates a new trailer for the specified carrier.
   * @param {string} carrierOid Actual carried Id.
   * @param {TrailerModel} trailerBody Trailer information to create.
   * @return {Promise<Array<TrailerModel>>} Created trailer information.
   */
  public async createTrailer(carrierOid: string, trailerBody: TrailerModel): Promise<Array<TrailerModel>> {
    const coreBody = this.returnCoreBody(trailerBody);
    return this.http.post<Array<TrailerModel>>(`${apiUrl}/carrier/${carrierOid}/trailers`, coreBody)
      .toPromise();
  }

  /**
   * @description Updates an specific existing trailer.
   * @param {string} carrierOid Actual carried Id.
   * @param {TrailerModel} trailerBody Trailer information to update.
   * @return {Promise<Array<TrailerModel>>} Updated trailer information.
   */
  public async updateTrailer(carrierOid: string, trailerBody: TrailerModel): Promise<Array<TrailerModel>> {
    const coreBody = this.returnCoreBody(trailerBody);
    return this.http.put<Array<TrailerModel>>(`${apiUrl}/carrier/${carrierOid}/trailers`, coreBody)
      .toPromise();
  }

  /**
   * @description Remove the trailer logically, setting the value of "isActive" to false for the incoming trailer.
   * @param {string} carrierOid Actual carried Id.
   * @param {TrailerModel} trailerBody Trailer information to delete.
   * @return {Promise<Array<TrailerModel>>} Deleted trailer information.
   */
  public async deleteTrailer(carrierOid: string, trailerBody: TrailerModel): Promise<Array<TrailerModel>> {
    const coreBody = this.returnCoreBody(trailerBody, true);
    return this.http.put<Array<TrailerModel>>(`${apiUrl}/carrier/${carrierOid}/trailers`, coreBody)
      .toPromise();
  }
}

