import { ShipmentRequestStatusResponseBiis } from '../../interfaces/marketplace-shipper/shipment-request';

export const CustomerShipmentRequestLabels: CustomerShipmentRequestLabel = {
  assignedCarrier: 'Asignar Transportista',
  assignedMarketplace: 'Asignar en Marketplace',
  assignedShipmentsTabtitle: 'Asignadas',
  chooseDateLabel: 'Selecciona el rango de fechas',
  cubicMeters: 'm³',
  everythingShipmentsTabTitle: 'Todas',
  inTransitShipmentsTabTitle: 'En curso',
  kg: 'Kg',
  noInfo: 'Sin información',
  notOfferShipmentsTabTitle: 'Sin ofertas',
  pageTitle: 'Solicitudes Clientes',
  pendingShipmentsTabTitle: 'Pendientes',
  searchPlaceholder: 'Buscar en la lista',
  unavailableMarketPlaceData: 'No hay información disponible',
  viewDetail: 'Ver detalle',
  withoutAssignedShipmentsTabTitle: 'Sin asignar',
  withoutQuote: 'Sin cotización',
};
export interface CustomerShipmentRequestLabel {
  assignedCarrier: string;
  assignedMarketplace: string;
  assignedShipmentsTabtitle: string;
  chooseDateLabel: string;
  cubicMeters: string;
  everythingShipmentsTabTitle: string;
  inTransitShipmentsTabTitle: string;
  kg: string;
  noInfo: string;
  notOfferShipmentsTabTitle: string;
  pageTitle: string;
  pendingShipmentsTabTitle: string;
  searchPlaceholder: string;
  unavailableMarketPlaceData: string;
  viewDetail: string;
  withoutAssignedShipmentsTabTitle: string;
  withoutQuote: string;
}

export const SHIPMENT_REQUEST_STATUS_BIIS: Array<ShipmentRequestStatusResponseBiis> = [
  {
    color: '#FFC600',
    cssClass: 'withoutAssigned',
    description: 'Sin asignar',
    shipments: [],
    status: 'Cotizada',
    tabIndex: 2,
  },
  {
    color: '#5EAC52',
    cssClass: 'assignedPoint',
    description: 'Asignadas',
    shipments: [],
    status: 'Cotizada',
    tabIndex: 3,
  },
  {
    color: '#E5E9EB',
    cssClass: 'notOfferPoint',
    description: 'Sin ofertas',
    shipments: [],
    status: 'Creada',
    tabIndex: 1,
  },
  {
    color: '#3E608F',
    cssClass: 'everythingPoint',
    description: 'Todas',
    shipments: [],
    status: 'Creada',
    tabIndex: 0,
  }
];

export enum TAB_CUSTOMER_SHIPMENT {
  assigned = 3,
  everything = 0,
  notOffer = 1,
  withoutAssigned = 2,
}
