<div class="principal-container">
  <div class="title-container">
    <span class="dialog-title">
      {{ data.title }}
    </span>
    <mat-icon
      id="dso-close-button"
      class="close-icon"
      (click)="onClickClose()">
      close
    </mat-icon>
  </div>
  <div class="content-container">
    <div class="image" *ngIf="isImagePathAviable">
      <img
        src="{{ image }}"
        width="75%"
        height="75%"
        alt="Dialog image">
      </div>
    <div class="select-form">
      <form [formGroup]="selectForm">
        <mat-form-field>
          <mat-select
          id="dso-selectInput"
          formControlName="selectedOption"
          placeholder="{{ data.placeholder }}"
          required>
            <mat-option *ngFor="let option of providedOptions" [value]="option.value">
              {{ option.key }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="buttons-container">
    <button
      id="dso-cancel-button"
      mat-button
      class="secondary-button secondary-button--align"
      (click)="onClickCancel()">
      {{ data.buttonCancel }}
    </button>
    <button
      id="dso-action-button"
      [disabled]="!selectForm.valid"
      mat-button
      class="primary-button"
      (click)="onClickAction()">
      {{ data.actionButton }}
    </button>
  </div>
</div>
