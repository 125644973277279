<div class="dialog-container">
  <mat-grid-list cols="3" rowHeight="1:.15">
    <mat-grid-tile colspan="2" rowspan="1">
      <span class="dialog-title">
        <p>{{ data.title }}</p>
      </span>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-icon
        style="cursor: pointer"
        class="dialog-close-icon"
        (click)="onClickClose()">
        close
      </mat-icon>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="dialog-resume">
    {{ data.resume }}
  </div>
  <mat-grid-list cols="3" rowHeight="1:.40">
    
    <mat-grid-tile colspan="1" rowspan="2">
      <div class="image-container">
        <img
          src="{{data.imgRef}}"
          width="95%"
          height="95%"
          alt="Dialog image">
      </div>
    </mat-grid-tile>
    
    <mat-grid-tile colspan="2" rowspan="1">
      <div class="select--width">
        <mat-form-field>
          <mat-select
            matNativeControl
            [(value)]="warehouseSelected"
            (selectionChange)="verifySelection()">
            <mat-option *ngFor="let item of warehouseList" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>
    
    <mat-grid-tile class="button-container">
      <button 
        mat-button
        class="secondary-button align-left"
        (click)="onClickButton1()">
        {{ data.button1 }}
      </button>
      <button
        [disabled]="!differentWhSelected"
        mat-button
        cdkFocusInitial
        class="primary-button align-right"
        (click)="onClickButton2()">
        {{ data.button2 }}
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</div>

