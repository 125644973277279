import { UpdatedPrintedLabelsResponse } from './../../interfaces/label/updated-printed-labels-reponse';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Account } from './../../interfaces/account';
import { environment } from '../../../environments/environment';
import { CreateLabelRequest, RegisterLabelRequest, ChildLabelRequest } from '../../interfaces/label/create-label-request';
import { CreateLabelRequestData } from '../../interfaces/label/create-label-request-data';
import { CreateLabelResponse } from '../../interfaces/label/create-label-response';
import { FindLabelResponse } from '../../interfaces/label/find-label-reponse';
import { ServiceType } from './../../interfaces/service-type/service-type.interface';
import { OrderInfo } from '../../interfaces/order';

import { isEmpty as _isEmpty, find as _find, forEach as _forEach } from 'lodash';

const apiUrl = environment.labelApiUrl;
const EMPTY_STRING = '';
const PREFIX_LIMIT = 3;

@Injectable()
export class LabelProvider {

  constructor(private http: HttpClient) { }

  /**
   * @description Generates a blank label with data given
   * @param labelRequest The data to create label
   * @returns {Promise<CreateLabelResponse>} The folio order and guides with pdf blobs
   */
  public async generateLabelsByOrder(labelRequest: CreateLabelRequest): Promise<CreateLabelResponse> {
    return await this.http.post<CreateLabelResponse>(`${apiUrl}/label`, labelRequest).toPromise();
  }

  /**
   * @description Gets labels by folios given
   * @param foliosRequest
   */
  public getLabels(foliosRequest: string[]): Promise<FindLabelResponse> {
    return this.http.post<FindLabelResponse>(`${apiUrl}/label/find/`, foliosRequest).toPromise();
  }

  public updatePrintedLabels(foliosRequest: RegisterLabelRequest[]): Promise<UpdatedPrintedLabelsResponse> {
    return this.http.post<UpdatedPrintedLabelsResponse>(`${apiUrl}/label/update-printed/`, foliosRequest).toPromise();
  }

  /**
   * @description Gets an object CreateLabelRequest
   * @param orders The orders data to create labels
   * @param accounts The accounts data
   * @returns CreateLabelRequest An object with all label needed
   */
  public getCreateLabelRequest(orders: OrderInfo[], accounts: Account[], serviceTypes: ServiceType[]): CreateLabelRequest {
    const createLabelRequest: CreateLabelRequest = {
      labels: []
    };
    _forEach(orders, (order: OrderInfo) => {
      const label: CreateLabelRequestData = {
        accountDetail: {
          prefix: this.getAccountPrefix(order.account.name, accounts),
        },
        folio: order.folio,
        origin: {
          address: order.origin.address,
          city: order.origin.state,
          name: order.origin.name,
          town: order.origin.municipality,
          zipcode: order.origin.postalCode
        },
        destination: {
          address: order.destination.address,
          city: order.destination.state,
          name: order.destination.name,
          town: order.destination.municipality,
          zipcode: order.destination.postalCode,
        },
        serviceType: this.getServiceTypeName(order.serviceType, serviceTypes),
        tenantId: order.tenantId,
        externalId: order.tenantId,
        volumetrics: {
          weight: order.weight,
          volume: order.volume,
        },
        details: order.guides,
      };
      createLabelRequest.labels.push(label);
    });

    return createLabelRequest;
  }

  /**
   * @description Gets account prefix by account name
   * @param accountName The account name string
   * @param accounts The accounts data
   * @returns The assigned prefix
   */
  private getAccountPrefix(accountName: string, accounts: Account[]): string {
    let prefix: string;
    const accountFound: Account = _find(accounts, (account: Account) => {
      return account.name === accountName;
    });
    if (accountFound.prefix && !_isEmpty(accountFound.prefix)) {
      prefix = accountFound.prefix;
    } else {
      prefix = accountName.slice(0, PREFIX_LIMIT).toUpperCase();
    }

    return prefix;
  }

  /**
   * @description Gets a service type name by identifier given
   * @param identifier The service type identifier
   * @param serviceTypes The service types by shipper
   * @returns The service type name
   */
  private getServiceTypeName(identifier: string, serviceTypes: ServiceType[]): string {
    let serviceTypeName: string;
    const serviceTypeFound: ServiceType = _find(serviceTypes, (serviceType: ServiceType) => serviceType.identifier === identifier);
    if (serviceTypeFound) {
      serviceTypeName = serviceTypeFound.name;
    } else {
      serviceTypeName = EMPTY_STRING;
    }

    return serviceTypeName;
  }

}
