import { environment } from '../../../environments/environment';
import { SctPermitTypes, TrailerTypes, VehicleConfigTypes } from '../../../app/interfaces/staticCatalogs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()

export class StaticCatalogsService {

  constructor(private http: HttpClient) { }

  /**
   * @description Get all SCT permit types, code and transport key
   * @return {SctPermitTypes} All permit types from catalog
   */
  public async getSctPermitTypes(): Promise<Array<SctPermitTypes>> {
    return await this.http.get<Array<SctPermitTypes>>(environment.baseStorageUrl + 'statics/sctPermitTypes.json').toPromise();
  }

  /**
   * @description Get trailer types with corresponding SAT code
   * @return {TrailerTypes} All trailer types from catalog
   */
  public async getTrailerTypes(): Promise<Array<TrailerTypes>> {
    return await this.http.get<Array<TrailerTypes>>(environment.baseStorageUrl + 'statics/trailerTypes.json').toPromise();
  }

  /**
   * @description Get vehicle configuration SAT keys
   * @return {VehicleConfigTypes} All vehicle configuration types from catalog
   */
  public async getVehicleConfigurationKeys(): Promise<Array<VehicleConfigTypes>> {
    return await this.http.get<Array<VehicleConfigTypes>>(environment.baseStorageUrl + 'statics/vehicleConfigurationKeys.json').toPromise();
  }
}
