import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { forEach as _forEach, first as _first, isEmpty as _isEmpty, remove as _remove } from 'lodash';
import { Subscription } from 'rxjs';

import { DialogStandardComponent } from '../dialog-standard/dialog-standard.component';
import { GuideModalSimplified, OrderSimplified } from '../../../interfaces/shipment';
import { IDialogScannedGuidesLabels } from 'src/app/interfaces/labels/dialog-labels.interface';
import { ILanguageLabels } from '../../../interfaces/labels/language-labels.interface';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { MatchedGuide } from './../../../interfaces/label/matched-guide.interface';
import { ScanService } from '../../../services/utils/scan.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';
import { ViewOrderProperties } from '../../../pages/orders/view-orders/view-order.properties';

const CLOSED = 'Closed';
const CONFIRM = 'Confirm';
const COURIER_GUIDE_COLUMN = 'courierGuide';
const DISPLAYCOLUMNS = ['indexNumber', 'guide', 'pod', 'actions'];
const IMG_REF_ROUTE  = '../../../assets/eliminate.svg';

@Component({
  selector: 'app-dialog-scanned-guides',
  templateUrl: './dialog-scanned-guides-detail.component.html',
  styleUrls: ['./dialog-scanned-guides-detail.component.scss', '../../../app.component.scss']
})
export class DialogScannedGuidesComponent implements OnInit {
  public dialogScannedGuidesLabels: IDialogScannedGuidesLabels;
  public displayedColumns: Array<string>;
  public guideModalSimplifiedDataSources: Array<MatTableDataSource<GuideModalSimplified>>;
  public languageLabels: ILanguageLabels;
  public languageSuscription: Subscription;
  public orderTittle: string;
  public isCourierShipment: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Array<OrderSimplified>,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService,
    private dialogRef: MatDialogRef<DialogScannedGuidesComponent>,
    private dialog: MatDialog,
    private scanService: ScanService,
    private toast: ToastrAlertsService
  ) {
    this.setLanguage();
    this.guideModalSimplifiedDataSources = [];
    const order = _first(this.data);
    this.isCourierShipment = !_isEmpty(order.matchedGuides);
  }

  /**
  * @description read the variables value to know what type of actions to carry out, courier trip or not
  */
  public async ngOnInit() {
    this.initDisplayedColumns();
    this.initDataSource();
    this.subscribeLanguageChangeEvent();
    await this.getLabels();
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @return {void}
   */
   public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getLabels();
      },
      () => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets the necessary tags from the JSON files to use throughout the component
   * @return {void}
   */
  public async getLabels(): Promise<void> {
    await this.getLanguageLabels();
    await this.getDialogScannedGuidesLabels();
  }

  /**
   * @description Gets Dialog Scanned Guides Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getDialogScannedGuidesLabels(): Promise<void> {
    this.dialogScannedGuidesLabels = await this._languageTranslateService
    .getLanguageLabels(LanguageConstants.DIALOG_SCANNED_GUIDES_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets Language labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageLabels(): Promise<void> {
    this.languageLabels = await this._languageTranslateService
    .getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Closes the dialog
   */
  public onClickClose(): void {
    this.dialogRef.close(CLOSED);
  }

  /**
   * @description Shows a confirmation modal, if the confirm value is recived, deletes the selected guide
   * @param {GuideModalSimplified} guide The selected guide value
   */
  public deteleGuide(guide: GuideModalSimplified): void {
    const dialogRef = this.dialog.open(DialogStandardComponent, {
      data: {
        title: this.dialogScannedGuidesLabels.deleteGuide + guide.guide,
        resume: this.dialogScannedGuidesLabels.deleteDialogResume,
        imgRef: IMG_REF_ROUTE,
        question: this.dialogScannedGuidesLabels.deleteDialogQuestion,
        button1: this.dialogScannedGuidesLabels.negation,
        button2: this.dialogScannedGuidesLabels.affirmation
      },
      width: ViewOrderProperties.deleteDialogWidth
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === CONFIRM) {
        for (const order of this.data) {
          for (let i = 0; i < order.scannedGuides.length; i += 1) {
            if (order.scannedGuides[i] === guide.guide) {
              if (this.isCourierShipment) {
                _remove(order.matchedGuides, (matchedGuide: MatchedGuide) => matchedGuide.tepGuide === guide.guide);
              }
              order.scannedGuides.splice(i, 1);
              order.scannedStatus = this.scanService.getScannedStatus(order);
              this.dialogRef.close(this.data);
              this.toast.successAlert(this.dialogScannedGuidesLabels.confirmdelete);
            }
          }
        }
      }
    });
  }

  /**
  * @description Build the data that the dataSource will use to show the tabble
  */
  private buildDataSources(): void {
    for (const order of this.data) {
      const guidesModalSimplified = [];
      _forEach(order.scannedGuides, (guide: string) => {
        this.orderTittle = order.folio;
        const scannedGuideElement = { guide: guide, pod: order.folio };
        guidesModalSimplified.push(scannedGuideElement);
      });
      const dataSource = new MatTableDataSource(guidesModalSimplified);
      this.guideModalSimplifiedDataSources.push(dataSource);
    }

  }

  /**
  * @description Generate the data that the data source will use to the table of courier shipment
  */
  private initDisplayedColumns(): void {
    if (this.isCourierShipment) {
      const displayedColumTemp = DISPLAYCOLUMNS;
      const actions = displayedColumTemp.pop();
      displayedColumTemp.pop();
      this.displayedColumns = displayedColumTemp;
      this.displayedColumns.push(COURIER_GUIDE_COLUMN);
      this.displayedColumns.push(actions);
    } else {
      this.displayedColumns = DISPLAYCOLUMNS;
    }
  }

  /**
  * @description fill out the forms depending on whether it is a courier trip or not
  */
  private initDataSource(): void {
    if (this.isCourierShipment) {
      this.buildCourierDataSources();
    } else {
      this.buildDataSources();
    }
  }

  /**
   * @description get the package guides to show in the table
   */
  private buildCourierDataSources(): void {
    _forEach(this.data, (order: OrderSimplified) => {
      const guidesModalSimplified = [];
      _forEach(order.matchedGuides, (matchedGuide: MatchedGuide) => {
        const guideModalSimplified = { guide: matchedGuide.tepGuide, courierGuide: matchedGuide.courierGuide, pod: order.folio };
        guidesModalSimplified.push(guideModalSimplified);
      });
      const dataSource = new MatTableDataSource(guidesModalSimplified);
      this.guideModalSimplifiedDataSources.push(dataSource);
    });
  }

}
