import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Geolocation } from '../../interfaces/map';

@Injectable()
export class GeoLocationService {
  constructor() { }

  /**
   * @description gets your current geoposition from browser (certainty depending if your location is enabled)
   * @returns {Observable<Geolocation>} An observable of your current geoposition
   */
  public getPosition(): Observable<Geolocation> {
    return new Observable(
    (observer) => {
      navigator.geolocation.watchPosition((location: Geolocation) => {
        observer.next(location);
      });
    });
  }
}
