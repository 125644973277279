export enum DialogAssociateShipmentLabels {
  accept = 'Aceptar',
  associateShipmentTitle = 'Asociar embarque a la solicitud ',
  cancel = 'Cancelar',
  destination = 'Destino: ',
  errorMessageUpdate = 'Ocurrió un error al actualizar el status del viaje',
  loadDate = 'Fecha de carga: ',
  loadTime = 'Hora de carga: ',
  modalFlag = 'Ofertas',
  modalQuestion = '¿Deseas continuar?',
  modalResumeBegin = 'Se asociará la solicitud ',
  modalResumeEnd = ' el cual será actualizado con los datos de la solicitud',
  modalResumeToShipment = ' al embarque ',
  modalTitle = 'Confirmar asociación de embarque',
  noCoincidences = 'Sin embarques coincidentes',
  noEnoughData = 'La solicitud no cuenta con la información necesaria para realizar la asignación del viaje',
  noShipmentsFound = 'No se encontraron viajes coincidentes para esta solicitud',
  origin = 'Origen',
  originTitle = 'Origen: ',
  selectShipmentSection = 'SELECCIONAR EMBARQUE',
  shipmentId = 'ID de embarque',
  status = 'Estatus',
  stopsNumber = 'Número de paradas: ',
  successMessageBegin = 'La solicitud ',
  successMessageEnd = ' ha sido asociada al embarque ',
  whithoutCoincidences = 'Sin embarques coincidentes',
  withoutData = 'Sin información',
}
