<div class="info-container">
  <div class="card-headers">
    <p class="title">{{data.title}}</p>
    <mat-icon
      class="dialog-close-icon pointer"
      (click)="onClickClose()">
      close
    </mat-icon>
  </div>
  <div class="image-section">
    <mat-icon [ngClass] = "{'disabled-effect': isDisableButtons}" class="interactive-icons pointer"  >
    <img
      [hidden]="isDisableButtons"
      src='../../../../assets/back.svg'
      (click)="previousPicture()"
      alt="Back">
    </mat-icon>
    <div class="image-container" *ngIf="!validatePDFExtension(imageToShow[initialPosition])">
      <img
        (click)="onFileClick(imageToShow[initialPosition])"
        class="image-carousel"
        src="{{imageToShow[initialPosition]}}"
        alt="Initial Image">
    </div>
    <div class="pdf-container" *ngIf="validatePDFExtension(imageToShow[initialPosition])">
      <img
        (click)="onFileClick(imageToShow[initialPosition])"
        src="../../../../assets/pdf_Icon.png"
        alt="license image">
    </div>
    <mat-icon [ngClass] = "{'disabled-effect': isDisableButtons}" class="interactive-icons pointer left-interactive-icon">
    <img
      [hidden]="isDisableButtons"
      src='../../../../assets/next.svg'
      (click)="nextPicture()"
      alt="Next">
    </mat-icon>
    <div class="info-box">
      <div *ngFor="let info of data.details; let i = index;" >
        <p class="title-info">{{info.titleParam}}</p>
        <p [ngClass]="{'license-color': i === 0}" class="content-info">{{info.valueParam}}</p>
      </div>
    </div>
  </div>
</div>
