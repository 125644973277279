<div class="table-container">
  <div>
    <table
      [dataSource]="dataSource"
      aria-describedby="customerInvoices"
      mat-table>
        <ng-container matColumnDef="folio">
          <th
            id="folioPdfCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.folio }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.folio ? invoice.folio : noInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="account">
          <th
            id="accountPdfCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.account }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.account ? invoice.account : noInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="generationDate">
          <th
            id="generationDatePdfCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.generationDate }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.generationDate ? invoice.generationDate : noInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="invoiceConcept">
          <th
            id="invoiceConceptPdfCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.invoiceConcept }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.invoiceConcept ? invoice.invoiceConcept : noInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="invoiceFolio">
          <th
            id="invoiceFolioPdfCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.invoiceFolio }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.invoiceFolio ? invoice.invoiceFolio : noInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="invoiceObservations">
          <th
            id="invoiceObservationsPdfCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.invoiceObservations }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.invoiceObservations ? invoice.invoiceObservations : noInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="invoiceRecordDate">
          <th
            id="invoiceRecordDatePdfCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.invoiceRecordDate }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.invoiceRecordDate ? invoice.invoiceRecordDate : noInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th
            id="ciStatusPdfCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.status }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.status ? invoice.status : noInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="subtotal">
          <th
            id="ciSubtotalCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.subtotal }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.subTotal ? invoice.subTotal : noInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="vat">
          <th
            id="ciVatPdfCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.vat }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.vat ? invoice.vat : noInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="additionalCharges">
          <th
            id="additionalChargesPdfCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.additionalCharges }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.additionalCharges ? invoice.additionalCharges : noInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="discounts">
          <th
            id="ciDiscountsPdfCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.discounts }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.discounts ? invoice.discounts : noInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th
            id="ciTotalPdfCol"
            mat-header-cell
            *matHeaderCellDef
            class="text-column">
            {{ headersLabels.total }}
          </th>
          <td
            mat-cell
            *matCellDef="let invoice">
            {{ invoice.total ? invoice.total : noInfo }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns">
        </tr>
        <tr
          mat-row
          *matRowDef="let row;
          columns: displayedColumns;">
        </tr>
    </table>
  </div>
</div>
