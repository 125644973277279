import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgModel } from '@angular/forms';

import { AccountCharge } from '../../../interfaces';
import { AppConstants } from '../../../constants/app-constants.constants';
import { DialogGenerateChargesStandardProperties } from './dialog-generate-charges-standard.properties';
import { DialogStandardFocus } from '../../../enums/dialog-stantdard-focus';
import { GenericRegexp } from '../../../regexp/generic.regexp';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { UNEXECUTED_LOAD_DIALOG_CONSTANTS } from '../../../constants/unexecuted-load.constants';
import { UnexecutedLoadDialogs } from '../../../interfaces/labels/unexecuted-load.interface';

@Component({
  selector: 'app-dialog-generate-charges-standard',
  styleUrls: ['./dialog-generate-charges-standard.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-generate-charges-standard.component.html'
})

/**
 * @description Dialog standard for one or multiple charges.
 */
export class DialogGenerateChargesStandardComponent implements OnInit {
  public accountCharge: number;
  public dialogUnexecutedLoadLabels: UnexecutedLoadDialogs;
  public isConfirm: boolean;
  public isReadOnly: boolean;
  public title: string;

  /**
   * @description Contains intances from providers used in this component.
   * @param {MatDialogRef<DialogGenerateChargesStandardComponent>} dialogRef - Reference to opened dialog.
   * @param {MAT_DIALOG_DATA} data - Data provided from shipment creation component.
   * @param {MatDialog} dialog - Mat dialog service.
   * @param {LanguageTranslateService} languageTranslateService - Service to retrieve differents all component labels in active language.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UnexecutedLoadDialogs,
    public dialogRef: MatDialogRef<DialogGenerateChargesStandardComponent>,
    private dialog: MatDialog,
    private languageTranslateService: LanguageTranslateService
  ) {
    this.isConfirm = this.data.isConfirm ?? false;
    this.isReadOnly = false;
    this.setTitle();
  }

  /**
   * @description Gets Dialog Unexecuted Load Labels from translate JSON files.
   */
  public async getDialogUnexecutedLoadLabels(): Promise<void> {
    this.dialogUnexecutedLoadLabels = await this.languageTranslateService
      .getLanguageLabels(LanguageConstants.UNEXECUTED_LOAD)
      .catch(() => { });
  }

  /**
   * @description Verifies if the Data Accounts array has elements.
   * @returns {boolean} - Returns whether Data Accounts array is empty or not.
   */
  public hasAccountsArrayElements(): boolean {
    return this.data?.accounts?.length > 0;
  }

  /**
   * @description Verifies if is a confirm dialog.
   * @returns {boolean} - Returns if salesChargesConfirm or freightChargeConfirm are in the data.
   */
  public isConfirmDialog(): boolean {
    if (this.data.salesChargesConfirm || this.data.freightChargeConfirm) {
      return true;
    }

    return false;
  }

  /**
   * @description Angular lifecycle for component initialization.
   */
  public async ngOnInit(): Promise<void> {
    await this.getDialogUnexecutedLoadLabels();
    this.isReadOnly = this.data?.isReadOnly ?? false;
  }

  /**
   * @description Action when cancel button is clicked.
   */
  public onClickCancelButton(): void {
    if (this.data?.accounts?.length) {
      this.data.accounts = [];
    }

    if (this.accountCharge) {
      this.accountCharge = undefined;
    }
    this.dialogRef.close(DialogStandardFocus.CANCEL);
  }

  /**
   * @description Action when continue button is clicked.
   */
  public onClickContinueButton(): void {
    if (this.data.salesChargesConfirm || this.data.freightChargeConfirm) {
      this.dialogRef.close(DialogStandardFocus.CONFIRM);
    } else if (this.hasAccountsArrayElements() && this.hasEveryAccountAValidCharge()) {
      this.openSalesChargesConfirmDialog();
    } else if (this.data.chargePlaceholder && this.accountCharge > 0 &&
      this.accountCharge <= DialogGenerateChargesStandardProperties.maxAmountOfCharge) {
      this.openFreightChargeConfirmDialog();
    }
  }

  /**
   * @description Validates a form field based on the provided criteria.
   * @param {NgModel} field - The form field to validate.
   * @param {boolean} isDynamic - Flag to specified if the input was build Dynamic.
   */
  public validateField(field: NgModel, isDynamic?: boolean): void {
    const value = field.value || 0;

    if (this.inValidCharge(value)) {
      field.control.setErrors({ invalid: true });
      this.isConfirm = false;
    } else {
      field.control.setErrors(null);
      let result = false;

      if (isDynamic) {
        result = this.data.accounts.some(item => this.inValidCharge(item.charge || AppConstants.ZERO));
      }

      this.isConfirm = !result;
    }
  }

  /**
   * @description Checks if a given value is an invalid charge.
   * @param {number} value - The value to check.
   * @returns {boolean} - Returns true if the value is invalid, false otherwise.
   */
  public inValidCharge(value: number): boolean {
    const valueStr = (value?.toString() || AppConstants.EMPTY_STRING).trim();

    if (value >= DialogGenerateChargesStandardProperties.minAmountOfCharge && value <= DialogGenerateChargesStandardProperties.maxAmountOfCharge &&
        GenericRegexp.NUMBER_WITH_TWO_DECIMALS_POINTS.test(valueStr)) {
      return false;
    }

    return true;
  }

  /**
   * @description Opens a confirm dialog for the freight charge.
   */
  public openFreightChargeConfirmDialog(): void {
    const dialogRef = this.dialog.open(DialogGenerateChargesStandardComponent, {
      data: {
        cancel: this.dialogUnexecutedLoadLabels.cancel,
        confirmGenerateFreightChargeTitle: this.dialogUnexecutedLoadLabels.confirmGenerateFreightChargeTitle,
        continue: this.dialogUnexecutedLoadLabels.continue,
        freightChargeConfirm: this.dialogUnexecutedLoadLabels.freightChargeConfirm,
        icon: UNEXECUTED_LOAD_DIALOG_CONSTANTS.INFO,
        question: this.dialogUnexecutedLoadLabels.question,
        isConfirm: true
      },
      width: UNEXECUTED_LOAD_DIALOG_CONSTANTS.MAX_WIDTH
    });
    dialogRef.afterClosed().subscribe(async (result: string) => {
      if (result === DialogStandardFocus.CONFIRM) {
        this.dialogRef.close(this.accountCharge);
      }
    });
  }

  /**
   * @description Opens a confirm dialog for the sales charges.
   */
  public openSalesChargesConfirmDialog(): void {
    const dialogRef = this.dialog.open(DialogGenerateChargesStandardComponent, {
      data: {
        cancel: this.dialogUnexecutedLoadLabels.cancel,
        confirmSalesChargesTitle: this.dialogUnexecutedLoadLabels.confirmSalesChargesTitle,
        continue: this.dialogUnexecutedLoadLabels.continue,
        icon: UNEXECUTED_LOAD_DIALOG_CONSTANTS.INFO,
        question: this.dialogUnexecutedLoadLabels.question,
        salesChargesConfirm: this.dialogUnexecutedLoadLabels.salesChargesConfirm,
        title: this.dialogUnexecutedLoadLabels.dialogSalesChargesTitle,
        isConfirm: true
      },
      width: UNEXECUTED_LOAD_DIALOG_CONSTANTS.MAX_WIDTH
    });
    dialogRef.afterClosed().subscribe(async (result: string) => {
      if (result === DialogStandardFocus.CONFIRM) {
        this.dialogRef.close(this.data.accounts);
      }
    });
  }

  /**
   * @description Verifies whether all accounts has a charge in the valid range.
   * @returns {boolean} - Returns whether all accounts has a valid charge
   */
  private hasEveryAccountAValidCharge(): boolean {
    let isValid = true;
    this.data.accounts.forEach((account: AccountCharge) => {
      if (account.charge === 0 || account.charge === undefined ||
        account.charge > DialogGenerateChargesStandardProperties.maxAmountOfCharge) {
        isValid = false;
      }
    });

    return isValid;
  }

  /**
   * @description Determines if the capture sales charge section should be displayed.
   * @returns {boolean} Returns true if the capture sales charge section should be displayed, otherwise false.
   */
  public shouldDisplayCaptureSalesChargeSection(): boolean {
    return !this.isReadOnly && this.hasAccountsArrayElements();
  }

  /**
   * @description Determines if the confirm dialog section should be displayed.
   * @returns {boolean} Returns true if the confirm dialog section should be displayed, otherwise false.
   */
  public shouldDisplayConfirmDialogSection(): boolean {
    return !this.isReadOnly && this.isConfirmDialog();
  }

  /**
   * @description Sets the necessary title for the dialog depending of the case.
   */
  private setTitle(): void {
    if (this.data.dialogFreightChargeTitle) {
      this.title = this.data.dialogFreightChargeTitle;
    } else if (this.data.confirmGenerateFreightChargeTitle) {
      this.title = this.data.confirmGenerateFreightChargeTitle;
    } else if (this.data.dialogSalesChargesTitle) {
      this.title = this.data.dialogSalesChargesTitle;
    } else if (this.data.confirmSalesChargesTitle) {
      this.title = this.data.confirmSalesChargesTitle;
    }
  }
}
