import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { ResponseItemShipmentRequest,
  ResponseShipmentRequest,
  ShipmentRequestResponse
} from '../../interfaces/marketplace-shipper/shipment-request';


const apiUrl = environment.shipmentRequestApiUrl;

@Injectable({ providedIn: 'root' })

export class MarketplaceShipperRequestProvider {
  constructor(private http: HttpClient, private _appService: AppService) {}

  /**
   * @description Gets all Shipper requests
   * @param {string} requestQuery Query string isBiisPerson true or false
   * @param {string} statusShipment The status of shipment optional
   * @returns {Promise<ShipmentRequest>} Shipper requests
   */
  public async getMarketplaceShipmentsRequests(requestQuery: string, statusShipment?: string): Promise<ResponseShipmentRequest> {
    const shipperId = '?shipperId=' + this._appService.getShipperOid();
    if (statusShipment != null && statusShipment !== '') {
      requestQuery += '&status=' + statusShipment;
    }
    return await this.http.get<ResponseShipmentRequest>(`${apiUrl}/shipmentRequests/${shipperId}${requestQuery}`).toPromise();
  }

  /**
   * @description Gets all Shipper requests for Biis
   * @param {string} requestQuery Query string isBiisPerson true or false
   * @param {Date} startDate The start date of created shipment
   * @param {Date} endDate The end date of created shipment
   * @param {string} statusShipment The status of shipment optional
   * @returns {Promise<ShipmentRequest>} Shipper requests
   */
  public async getMarketplaceShipmentsRequestsForBiis(requestQuery: string,
      startDate: Date,
      endDate: Date,
      statusShipment?: string): Promise<ResponseShipmentRequest> {
    const shipperId = '?shipperId=' + this._appService.getShipperOid();
    if (statusShipment != null && statusShipment !== '') {
      requestQuery += '&status=' + statusShipment;
    }
    requestQuery += '&startDate=' + startDate;
    requestQuery += '&endDate=' + endDate;
    return await this.http.get<ResponseShipmentRequest>(`${apiUrl}/shipmentRequests/${shipperId}${requestQuery}`).toPromise();
  }

  /**
   * @description Get shipment requests by id
   * @param {string} shipmentRequestId The shipment request id
   * @returns {Promise<ResponseShipmentRequest>} Shipper requests
   */
  public async getMarketplaceShipmentsRequestsById(shipmentRequestId: string): Promise<ResponseItemShipmentRequest> {
    return await this.http
    .get<ResponseItemShipmentRequest>(`${apiUrl}/shipmentRequests/${shipmentRequestId}`).toPromise();
  }

  /**
   * @description Gets all Shipper requests for carriers
   * @param {string} carrierId The carrier id
   * @returns {Promise<ResponseShipmentRequest>} Shipper requests
   */
  public async getMarketplaceShipmentsRequestsForCarrier(carrierId: string): Promise<ResponseShipmentRequest> {
    return await this.http
    .get<ResponseShipmentRequest>(`${apiUrl}/shipmentRequests/carriers/${carrierId}/shipmentRequests`).toPromise();
  }

  /**
   * @description Gets all shipments requests accepted by the carrier
   * @param {string} carrierId Id from carrier
   * @returns {Promise<ResponseShipmentRequest>} Shipment requests accepted by the carrier
   */
  public async getAllShipmentsAcceptedByCarrier(carrierId: string): Promise<ResponseShipmentRequest> {
    return await this.http
    .get<ResponseShipmentRequest>(`${apiUrl}/shipmentRequests/${carrierId}/accepted`).toPromise();
  }

  /**
   * @description Request to endpoint shipment requests carriers for create the new offer
   * @param {string} carrierId The carrier id
   * @returns {Promise<ResponseShipmentRequest>} Shipper requests
   */
  public async sendMarketplaceOfferCarrierShipmentRequest(carrierId: string,
    body: ShipmentRequestResponse): Promise<ResponseShipmentRequest> {
    return await this.http
    .post<ResponseShipmentRequest>(`${apiUrl}/shipmentRequests/carriers/${carrierId}/shipmentRequests`, body).toPromise();
  }

  /**
   * @description Request to endpoint shipment requests for updated
   * @param {string} shipmentRequestId The shipment request Id
   * @returns {Promise<ResponseShipmentRequest>} Shipper requests
   */
  public async sendMarketplaceAssignedShipmentRequest(shipmentRequestId: string,
    body: ShipmentRequestResponse): Promise<ResponseShipmentRequest> {
    return await this.http
    .put<ResponseShipmentRequest>(`${apiUrl}/shipmentRequests/${shipmentRequestId}`, body).toPromise();
  }
}
