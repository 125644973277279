import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ServiceTypeUniqueId } from '../../interfaces/service-type/service-type-unique-id.interface';
import { ServiceTypeUniqueName } from '../../interfaces/service-type/service-type-unique-name-interface';
import { ServiceType } from '../../interfaces/service-type/service-type.interface';
import { ServiceTypeUriPaths } from './service-type-uri-paths.constants';

import { Observable } from 'rxjs';

const apiUrl = environment.apiUrl;

@Injectable()
export class ServiceTypeProvider {

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * @description Gets all active service type by shipperOid.
   * @param {string} shipperOid - The shipper identifier.
   * @param {boolean} isActive - Indicator for active service types.
   * @returns {Promise<Array<ServiceType>>} - A list with active service types.
   */
  public getAll(shipperOid: string, isActive?: boolean): Promise<Array<ServiceType>> {
    const url = `${apiUrl}${ServiceTypeUriPaths.BASE}${ServiceTypeUriPaths.SLASH}${shipperOid}${ServiceTypeUriPaths.IS_ACTIVE}${isActive}`;

    return this.http.get<Array<ServiceType>>(url).toPromise();
  }

  /**
   * @description Validate  if identifier is unique by shipper
   * @param {ServiceTypeUniqueId} serviceTypeUniqueId The request bodyP
   * @returns {Promise<boolean>} True if not exists
   */
  public isUniqueIdentifier(serviceTypeUniqueId: ServiceTypeUniqueId): Promise<boolean> {
    return this.http.post<boolean>(`${apiUrl}${ServiceTypeUriPaths.UNIQUE_IDENTIFIER}`, serviceTypeUniqueId).toPromise();
  }

  /**
   * @description Validate if name is unique by shipper
   * @param {ServiceTypeUniqueName} serviceTypeUniqueName The request body
   * @returns {Promise<boolean>} True if not exists
   */
  public isUniqueName(serviceTypeUniqueName: ServiceTypeUniqueName): Promise<boolean> {
    return this.http.post<boolean>(`${apiUrl}${ServiceTypeUriPaths.UNIQUE_NAME}`, serviceTypeUniqueName).toPromise();
  }

  /**
   * @description Saves new service type
   * @param {ServiceType} newServiceType The data to save
   * @returns {Observable<void>} An Observable event void
   */
  public save(newServiceType: ServiceType): Observable<void> {
    return this.http.post<void>(`${apiUrl}${ServiceTypeUriPaths.BASE}`, newServiceType);
  }

  /**
   * @description Deletes logic of service type request
   * @param {string} serviceTypeOid The service type identifier
   * @returns {Observable<void>} An observable event void
   */
  public delete(serviceTypeOid: string): Observable<void> {
    return this.http.delete<void>(`${apiUrl}${ServiceTypeUriPaths.BASE}${ServiceTypeUriPaths.SLASH}${serviceTypeOid}`);
  }

  /**
   * @description Updates service type by identifier given
   * @param {string} serviceTypeOid The service type identifier
   * @param {ServiceType} serviceType The data to be update
   * @returns {Observable<void>} An observable event void
   */
  public update(serviceTypeOid: string, serviceType: ServiceType): Observable<void> {
    return this.http.put<void>(`${apiUrl}${ServiceTypeUriPaths.BASE}${ServiceTypeUriPaths.SLASH}${serviceTypeOid}`, serviceType);
  }
}
