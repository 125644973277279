<div mat-dialog-tittle class="dialog-close-icon-scanned-guides">
  <mat-icon class="modal-close-button" (click)="onClickClose()">
    close
  </mat-icon>
</div>
<div *ngFor="let dataSource of guideModalSimplifiedDataSources">
  <mat-grid-list cols="3" rowHeight="1:.3">
    <mat-grid-tile colspan="2">
      <span class="dialog-title">
        <p>{{ 'dialogScannedGuidesLabels.orderGuidesTittle' | translate }}
          <span class="focus-text">
            {{ dataSource.data[0] ? dataSource.data[0].pod : ('dialogScannedGuidesLabels.noInfo' | translate) }}
          </span>
        </p>
      </span>
    </mat-grid-tile>
  </mat-grid-list>

  <div class="table--scroll">
    <table #tableAssignedOrders mat-table [dataSource]="dataSource" aria-describedby="ScannedGuidesTable">

      <ng-container matColumnDef="indexNumber">
        <th id="indexNumber" mat-header-cell *matHeaderCellDef> {{ 'dialogScannedGuidesLabels.number' | translate }}</th>
        <td mat-cell *matCellDef="let element; let guideIndex = index">
          {{ guideIndex + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="guide">
        <th id="guide" mat-header-cell *matHeaderCellDef> {{ 'dialogScannedGuidesLabels.guide' | translate }} </th>
        <td mat-cell *matCellDef="let guideModalSimplified">
          <div> {{guideModalSimplified.guide}} </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="pod">
        <th id="pod" mat-header-cell *matHeaderCellDef> {{ 'dialogScannedGuidesLabels.podFacture' | translate }} </th>
        <td mat-cell *matCellDef="let guideModalSimplified">
          <div>{{ guideModalSimplified.pod }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="courierGuide">
        <th id="courierGuide" mat-header-cell *matHeaderCellDef>{{ 'dialogScannedGuidesLabels.courierGuides' | translate }}</th>
        <td mat-cell *matCellDef="let guideModalSimplified">
          {{guideModalSimplified.courierGuide}}
      </ng-container>

      <ng-container matColumnDef="actions">
        <th id="actions" mat-header-cell *matHeaderCellDef> {{ 'dialogScannedGuidesLabels.actions' | translate }} </th>
        <td mat-cell *matCellDef="let guideModalSimplified" class="icon-column" style='white-space: nowrap'>
          <mat-icon [ngClass]="{'disabled-effect': guideModalSimplified.notRemovable}" [matTooltip]="Borrar"
            matTooltipPosition='above' class="icon-button" (click)="deteleGuide(guideModalSimplified)">
            delete
          </mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>

</div>
