import { Injectable } from '@angular/core';

import { AppConstants } from '../../constants/app-constants.constants';

@Injectable()

/**
 * Service to build URL's.
 */
export class UrlBuilderService {
  constructor() { }

  /**
   * @description Builds URL with dynamic route params and query params.
   * @param { string } baseUrl - API base URL.
   * @param { object } routeParams - Route params to add in the URL.
   * @param { object } queryParams - Query parmas to add in the URL.
   * @returns { string } URL built.
   */
  public buildUrl(baseUrl: string, routeParams: object, queryParams?: object): string {
    let route = AppConstants.EMPTY_STRING;
    let queryString = AppConstants.EMPTY_STRING;

    if (routeParams && Object.keys(routeParams).length) {
      for (const [key, value] of Object.entries(routeParams)) {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(value);
        route += `/${encodedKey}${value ? `/${encodedValue}` : AppConstants.EMPTY_STRING}`;
      }
    }

    if (queryParams && Object.keys(queryParams).length) {
      queryString = Object.entries(queryParams)
        .map(([key, value]: [string, string]) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join(AppConstants.AMPERSAND);
    }

    const finalUrl = `${baseUrl}${route}${queryString ? `?${queryString}` : AppConstants.EMPTY_STRING}`;

    return finalUrl;
  }
}
