import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from './../../app.service';
import { environment } from './../../../environments/environment';
import { Package } from './../../interfaces/couriers/package.interface';

import {
  CreatePackageRequest,
  CreatePackageResponse,
  CreatePackageResponseList
} from 'src/app/interfaces';

import { Observable } from 'rxjs';

const apiUrl = environment.apiUrl;

@Injectable()
export class PackageProvider {

  constructor(private http: HttpClient,
    private _appService: AppService) { }

  /**
   * @description Create the packages for the massive load of weights
   * @param requestPackagesCreate Name and measures to create a package
   */
  public createPackage(requestPackagesCreate: CreatePackageRequest): Observable<CreatePackageResponse> {
    return this.http.post<CreatePackageResponse>(`${apiUrl}/packages`, requestPackagesCreate);
  }

  /**
   * @description Bring the registered packages
   */
  public getPackages(): Promise<CreatePackageResponseList> {
    const tenantId = this._appService.getShipperOid();
    return this.http.get<CreatePackageResponseList>(`${apiUrl}/packages?tenantId=` + tenantId).toPromise();
  }

  /**
   * @description Edit the selected package
   * @param updatePackage
   */
  public updatePackage(updatePackage: CreatePackageRequest): Observable<CreatePackageResponse> {
    return this.http.put<CreatePackageResponse>(`${apiUrl}/packages`, updatePackage);
  }

  /**
   * @description Delete the selected package
   * @param deletePackageId
   */
  public deletePackage(deletePackageId: string): Observable<CreatePackageResponse> {
    return this.http.delete<CreatePackageResponse>(`${apiUrl}/packages?packageId=${deletePackageId}`);
  }
}
