import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '../../../../environments/environment';

import { CookieService } from 'ngx-cookie-service';

const PATH = '/biis/eventosViaje';

@Component({
  selector: 'app-shipment-events',
  templateUrl: './shipment-events.component.html',
  styleUrls: ['../../../app.component.scss']
})
export class ShipmentEventsComponent implements OnInit {
  public url = `${environment.clientUrl}${PATH}?us=`;
  public urlSafe: SafeResourceUrl;

  constructor(
    private cookieService: CookieService,
    private sanitizer: DomSanitizer) { }

  /**
   * @description Fires when initialize component
   */
  public ngOnInit(): void {
    const petition = this.cookieService.get('auth');
    const us = JSON.parse(petition);
    us.path = PATH;

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + JSON.stringify(us));
  }
}
