export const ShipmentRequestFormatConstants = {
  COLUMN_NAMES: [
    'destinationName',
    'destinationAddress',
    'destinationCity',
    'destinationState',
    'deliveryDate'
  ],
  TITLE_CONTAINER: 'title-container'
};
