import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogSelectWarehouseLabels } from './dialog-select-warehouse.labels';
import { ToastrAlertsService } from 'src/app/services/utils/toastr-alerts.service';
import { UserService } from 'src/app/providers/user/user.service';
import { WarehouseDefault, WarehouseChangeBody } from 'src/app/interfaces';

const CANCEL = 'Cancel';
const CLOSED = 'Closed';
const CONFIRM = 'Confirm';

@Component({
  selector: 'app-dialog-select-warehouse',
  templateUrl: './dialog-select-warehouse.component.html',
  styleUrls: ['./dialog-select-warehouse.component.scss', '../../../app.component.scss']
})
export class DialogSelectWarehouseComponent implements OnInit {
  public dataAvailable: boolean;
  public differentWhSelected: boolean;
  public userWarehouse: WarehouseDefault;
  public warehouseList: Array<WarehouseDefault>;
  public warehouseSelected: WarehouseDefault;

  ngOnInit() {
    this.warehouseList = this.data.warehouses;
    this.userWarehouse = this.data.defaultWarehouse;
    const aux = this.warehouseList.filter(item => item._id === this.userWarehouse._id);
    this.warehouseSelected = aux[0];
    this.differentWhSelected = false;
  }

  constructor(
    public dialogRef: MatDialogRef<DialogSelectWarehouseComponent>,
    private toast: ToastrAlertsService,
    private userProvider: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  public onClickButton1(): void {
    this.dialogRef.close(CANCEL);
  }
  public onClickButton2(): void {
    this.changeWarehouse();
  }
  public onClickClose(): void {
    this.dialogRef.close(CLOSED);
  }

  public async changeWarehouse() {
    const bodyAux: WarehouseChangeBody = {
      warehouseDefaultUpdate: true,
      warehouse: {
        _id: this.warehouseSelected._id,
        name: this.warehouseSelected.name
      }
    };
    await this.userProvider.updateUserDefaultWarehouse(this.data.userId, bodyAux)
      .then(res => {
        this.toast.successAlert(DialogSelectWarehouseLabels.successMessage);
        this.dialogRef.close(CONFIRM);
      })
      .catch(error => {
        this.toast.errorAlert(DialogSelectWarehouseLabels.errorMessage);
        console.log(error);
      });
  }

  public verifySelection(): void {
    if (!this.userWarehouse) {
      this.differentWhSelected = true;
      return;
    }
    this.differentWhSelected = this.warehouseSelected._id !== this.userWarehouse._id ? true : false;
    return;
  }

}
