<div>
  <mat-grid-list cols="3" rowHeight="1:.3">
    <mat-grid-tile colspan="2">
      <span class="dialog-title">
        <p>{{ label.orderGuidesTittle }}<span class="focus-text"> {{ data.folio }} </span></p>
      </span>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-icon
        style="cursor: pointer"
        class="dialog-close-icon"
        (click)="onClickClose()">
        close
      </mat-icon>
    </mat-grid-tile>
  </mat-grid-list>

  <div class="table--scroll">
    <table #tableAssignedOrders mat-table [dataSource]="guideModalSimplifiedDataSources"
      aria-describedby="guideModalSimplifiedDataSourcesTable">

      <ng-container matColumnDef="indexNumber">
        <th id="indexNumber" mat-header-cell *matHeaderCellDef> {{ label.number }}</th>
        <td mat-cell *matCellDef="let element; let guideIndex = index">
          {{ guideIndex + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="guide">
        <th id="guide" mat-header-cell *matHeaderCellDef> {{ label.guide }} </th>
        <td mat-cell *matCellDef="let OnlyGuide">
          <div> {{ OnlyGuide.guide }} </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th id="actions" mat-header-cell *matHeaderCellDef> {{ label.actions }} </th>
        <td mat-cell *matCellDef="let guideModalSimplified" class="icon-column" style='white-space: nowrap'>
          <mat-icon [matTooltip]="label.deleteGuide" (click)="onDelete(guideModalSimplified)"
            matTooltipPosition='above' class="icon-button">
            delete
          </mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>

</div>
