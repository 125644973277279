import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DeleteBlob } from '../../interfaces/blobStorage';
import { environment } from '../../../environments/environment';
import { SasProvider } from './sas-provider.service';

declare function unescape(s: string): string;
const evidenceApiUrl = environment.evidenceApiUrl;

@Injectable()
export class BlobProvider {

  constructor(
    private http: HttpClient,
    private _sasProvider: SasProvider
    ) { }

  public async sendBlob (sasURL: string, blob: Blob): Promise<any> {
    const headers = { 'x-ms-blob-type': 'BlockBlob', 'Content-Type': 'application/octet-stream' };
    return this.http.put(sasURL, blob, { headers, responseType: 'text'}).toPromise();
  }

  public async getMIMEtype(MIMEType: string): Promise<string> {
    let result = '';
    switch (MIMEType) {
      case 'jpg':
        result = 'image/jpeg';
      break;
      case 'jpeg':
        result = 'image/jpeg';
      break;
      case 'pdf':
        result = 'application/pdf';
      break;
      case 'png':
        result = 'image/png';
      break;
      default:
        result = 'application/octet-binary';
    }

    return result;
  }

  public async dataURIToBlob (dataURI: string): Promise<Blob> {
    const isBase64 = dataURI.split(',')[0].indexOf('base64') >= 0;
    const byteString = isBase64 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
    const ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: 'jpeg' });
  }

  public async saveBlob(file: Object, newFileName: string, extension: string): Promise<string> {
    const { sasURL, fileName } = await this._sasProvider.getSas(newFileName);
    const _file = <File>file;
    const MIMEType = await this.getMIMEtype(extension.toLowerCase());
    const blob = new Blob([_file], { type: MIMEType });
    await this.sendBlob(sasURL, blob);

    return fileName;
  }

  /**
   * @description Save blob for the new evidences.
   * @param { File } file - File to save.
   * @param { string } newFileName - New file name.
   * @param { string } extension - File extension.
   * @returns { string } URL file blob.
   */
  public async saveBlobEvidences(file: File, newFileName: string, extension: string): Promise<string> {
    const { sasURL, fileName } = (await this._sasProvider.getEvidenceFileSas(newFileName)).item;
    const auxFile: File = file;
    const mimeType = await this.getMIMEtype(extension.toLowerCase());
    const blob = new Blob([auxFile], { type: mimeType });
    await this.sendBlob(sasURL, blob);

    return fileName;
  }

  public async deleteBlob(fileNames: Array<DeleteBlob>): Promise<string> {
    const body = {
      fileName: fileNames
    };
    const result = await this.http.post(evidenceApiUrl + '/deleteBlob', body).toPromise();

    return result['message'];
  }


}
