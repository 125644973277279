import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Role, RoleBody, RoleUpdateBody } from '../../interfaces/role';

const apiUrl = environment.apiUrl;

@Injectable()
export class RoleProvider {

  constructor(private http: HttpClient) { }

  public async getRoles(shipperOId?: string): Promise<Array<Role>> {
    const query = shipperOId ? '?shipper=' + shipperOId : '';
    return await this.http.get<Array<Role>>(apiUrl + '/role' + query).toPromise();
  }

  public async roleInUserValidation(roleOId?: string): Promise<Array<Role>> {
    return await this.http.get<Array<Role>>(apiUrl + '/user/role/' + roleOId).toPromise();
  }

  public async createRole(roleBody: RoleBody, shipperOId?: string): Promise<Array<Role>> {
    const query = shipperOId ? '?shipper=' + shipperOId : '';
    return await this.http.post<Array<Role>>(apiUrl + '/role' + query, roleBody).toPromise();
  }

  public async getRoleByOId(roleOId?: string): Promise<Array<Role>> {
    return await this.http.get<Array<Role>>(apiUrl + '/role?roleOId=' + roleOId).toPromise();
  }

  public async deleteRole(roleOId: string): Promise<Object> {
    return await this.http.delete<Object>(apiUrl + '/role/' + roleOId).toPromise();
  }

  public async updateRole(roleOId: string, roleBody: RoleUpdateBody, shipperOId?: string): Promise<Array<Role>> {
    const query = shipperOId ? '?shipper=' + shipperOId : '';
    return await this.http.put<Array<Role>>(apiUrl + '/role/' + roleOId + query, roleBody).toPromise();
  }

  public async updateRoleNameInUser(roleOId: string, roleBody: Role): Promise<Object> {
    return await this.http.put<Object>(apiUrl + '/user/role/' + roleOId, roleBody).toPromise();
  }
}
