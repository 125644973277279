<div class="info-section">
  <div matTooltip="{{ iconTooltip }}">
    <mat-icon class="legend-info-icon">{{ iconName }}</mat-icon>
  </div>
  <div class="legend-section">
    <span>
      {{ mainText }}
    </span> 
    <span style="font-weight: bold;">
      {{ mainFocusText }}.
    </span> 
  </div>
</div>
