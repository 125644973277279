import { Injectable } from '@angular/core';

@Injectable()
export class ArrayToolsService {

  /**
   * @description Batch an array
   * @param {Array<any>} array Array to be batched
   * @param {number} chunkSize Indicates how many items per chunk
   * @returns {Array<any>} Batched array
   */
  public batch (array: Array<any>, chunkSize: number = 10) {
    const batchedArray = [];
    let  arrayAux = [];

    for (let i = 0 ; i < array.length ; i++) {
      if ( i % chunkSize === 0 && arrayAux.length !== 0 ) {
        batchedArray.push(arrayAux);
        arrayAux = [];
      }
      arrayAux.push(array[i]);
    }
    if ( arrayAux.length !== 0 ) {
      batchedArray.push(arrayAux);
    }
    return batchedArray;
  }

  /**
   * @description Get duplicates in array
   * @param {Array<string>} array Array to be analyzed
   * @returns {Array<string>} Duplicated elements
   */
  public getDuplicatesString (array: Array<string>): Array<string> {
    let duplicates: Array<string>;
    const uniq = array
      .map((name) => {
        return {
          count: 1,
          name: name
        };
      })
      .reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count;
        return a;
      }, {});

    duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);
    return duplicates;
  }
}
