import { AppConstants } from '../../constants/app-constants.constants';
import { ArrayToolsService } from '../../services/utils/array-tools.service';
import { EstimatedTravel, LocationWaypoint, ResponseMatrixOriginDestination } from '../../interfaces/waypoint';
import { environment } from 'src/environments/environment';
import { Geoposition, PolylinePoint  } from '../../interfaces';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

const trackingUrl = environment.trackingUrl;

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  constructor(
    private arrayToolsService: ArrayToolsService,
    private http: HttpClient,
    private mapsAPILoader: MapsAPILoader
  ) { }

  /**
   * @description Get the snapped polylines route
   * @param tracking ShipmentRequest['tracking']
   */
  public async getPolylinesRoute(tracking: Array<Geoposition>): Promise<Array<PolylinePoint>> {
    const body = { tracking };
    return await this.http.post<Array<PolylinePoint>>(trackingUrl + '/polylines', body)
      .toPromise();
  }

  /**
   * @description Get estimated travel between two points
   * @param {LocationWaypoint} origin Started point from the route
   * @param {LocationWaypoint} destination Last destination route
   * @returns {Promise<EstimatedTravel>} Estimated travel with total duration and distance
   */
  public getEstimatedTravel(origin: LocationWaypoint, destination: LocationWaypoint): Promise<EstimatedTravel> {
    return new Promise((resolve) => {
      this.mapsAPILoader.load().then(() => {
        const matrixOptions: google.maps.DistanceMatrixRequest = {
          destinations: [`${destination.lat.toString()}, ${destination.lng.toString()}`],
          origins: [`${origin.lat.toString()}, ${origin.lng.toString()}`],
          travelMode: google.maps.TravelMode.DRIVING,
        };

        const distanceMatrixService = new google.maps.DistanceMatrixService();
        const estimatedTravel: EstimatedTravel = {};

        distanceMatrixService.getDistanceMatrix(matrixOptions, (response, status) => {
          if (status !== AppConstants.GOOGLE_SUCCESS_RESPONSE) { return; }

          if (response) {
            estimatedTravel.time = response.rows[0].elements[0].duration.value;
            estimatedTravel.distance = response.rows[0].elements[0].distance.text;
            resolve(estimatedTravel);
          } else {
            resolve(estimatedTravel);
          }
        });
      });
    });
  }

  /**
   * @description Gets distance and duration from one origin and one destination
   * @param {string | LocationWaypoint} origin Coordinates or string addres from origin
   * @param {string | LocationWaypoint} destinations Coordinates or string addres from destination
   * @returns {<ResponseMatrixOriginDestination>} Array with all data from origin to destination
   */
  public async getAllTimesAndDistancesForStops(origin: string | LocationWaypoint, destinations: Array<string | LocationWaypoint>):
    Promise<ResponseMatrixOriginDestination> {
      const response = await this.getRouteTimeAndDistance(origin, destinations);

      return response;
  }

  /**
   * @description Gets the duration and distance for the origin and destinations received
   * @param {string | LocationWaypoint} origin coordinates or string address of origin
   * @param {Array<string | LocationWaypoint>} destinations Array of coordinates or strings address of destinations
   * @returns {Promise<Array<ResponseElements>>} Response with all duration and distance for each destination
   */
  public getRouteTimeAndDistance(origin: string | LocationWaypoint, destinations: Array<string | LocationWaypoint>):
    Promise<ResponseMatrixOriginDestination> {
    return new Promise((resolve) => {
      this.mapsAPILoader.load().then(() => {
        const request = {
          origins: [origin],
          destinations: destinations,
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        };
        const distanceMatrixService = new google.maps.DistanceMatrixService();
        distanceMatrixService.getDistanceMatrix(request, (response, status) => {
          if (status !== AppConstants.GOOGLE_SUCCESS_RESPONSE) { return; }

          if (response) {
            resolve(response);
          }
        });
      });
    });
  }
}
