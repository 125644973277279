export enum Pages {
  CurrentTrips = 'MisViajesActualesPage',
  Consignment = 'ConsignmentPage',
  Evidence = 'EvidenciaPage',
  Help = 'AyudaPage',
  Login = 'LoginPage',
  ManualVisits = 'ManualVisits',
  MapTrip = 'MapaViajePage',
  MobileApp = 'MobiltyApp',
  NotificationsCenter = 'NotificationsCenterPage',
  OrdersReception = 'OrdersReceptionPage',
  PreviousTrips = 'MisViajesPasadosPage',
  Pod = 'PodPage',
  Rating = 'CalificacionesPage',
  Rejection = 'RechazoPage',
  Stops = 'VerParadasPage',
  Telemetrics = 'AddTelemetricsPage',
  TripDetails = 'VerDetallesPage',
  Tutorial = 'TutorialPage',
  UpdatePassword = 'UpdatePasswordPage',
}
