<div class="principal-container">
  <div class="title-container">
    <span class="dialog-title">
      <strong>
        {{ 'dialogPasswordLabels.title' | translate }}
      </strong>
      <strong>
        {{ data.driver.username }}
      </strong>
    </span>
    <mat-icon class="close-icon" (click)="onClickClose()">
      close
    </mat-icon>
  </div>
  <br>
  <div class="dialog-message-container background-container">
    <div class="dialog-resume-icon">
      <mat-icon class="resume-mat-info-icon">info</mat-icon>
    </div>
    <div class="dialog-container-resume-text">
      <div class="dialog-resume-text">
        <span><strong>{{ 'dialogPasswordLabels.resumeNewPassword' | translate }}</strong></span>
        <br>
        <span>&bull; {{ 'dialogPasswordLabels.resumeLimitsLengths' | translate }}</span>
        <br>
        <span>&bull; {{ 'dialogPasswordLabels.resumePattern' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="content-container">
    <div class="password-form">
      <form [formGroup]="passwordForm">
        <mat-grid-list cols="2" rowHeight="1:.40">
          <mat-grid-tile colspan="1">
            <mat-form-field >
              <div class="password-wrapper">
                <input
                  id="dpc-new-password"
                  [type]="newPasswordType"
                  placeholder="{{ 'dialogPasswordLabels.placeholderNewPassword' | translate }}"
                  matInput
                  #inputPassword
                  formControlName="password"
                  [(value)]="password"
                  required
                  minlength="8"
                  maxlength="20">
                  <button mat-icon-button type="button" class="toggle-password" (click)="toggleNewPasswordVisibility()">
                    <mat-icon>{{ isNewPasswordInputTextType() ? 'visibility' : 'visibility_off' }}</mat-icon>
                  </button>
              </div>
              <mat-hint align="end">
                {{ inputPassword.value?.length || 0 }}/20
              </mat-hint>
              <mat-error>
                {{ getErrorMessage() }}
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <mat-form-field>
              <div class="password-wrapper">
                <input
                  id="dpc-confirm-new-password"
                  [type]="confirmNewPasswordType"
                  placeholder="{{ 'dialogPasswordLabels.placeholderConfirmPassword' | translate }}"
                  matInput
                  #inputConfirm
                  formControlName="confirmPassword"
                  [(value)]="confirmPassword"
                  required
                  minlength="8"
                  maxlength="20">
                  <button mat-icon-button type="button" class="toggle-password" (click)="toggleConfirmNewPasswordVisibility()">
                    <mat-icon>{{ isConfirmNewPasswordInputTextType() ? 'visibility' : 'visibility_off' }}</mat-icon>
                  </button>
              </div>
              <mat-hint align="end">
                {{ inputConfirm.value?.length || 0 }}/20
              </mat-hint>
              <mat-error>
                {{ getErrorMessage(true) }}
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </form>
    </div>
  </div>
  <div class="buttons-container">
    <button
      mat-button
      class="secondary-button secondary-button--align"
      (click)="onClickCancel()">
      {{ 'dialogPasswordLabels.buttonCancel' | translate }}
    </button>
    <button
      [disabled]="!isValidToUpdate()"
      mat-button
      class="primary-button"
      (click)="onClickSave()">
      {{ 'dialogPasswordLabels.buttonSave' | translate }}
    </button>
  </div>
</div>
