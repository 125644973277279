import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResponseSignatureAccesKey, SignatureAccesKey } from '../../interfaces/blobStorage';

const evidenceApiUrl = environment.evidenceApiUrl;
const EVIDENCE_API_URL = environment.tmsEvidenceApiUrl;

@Injectable()
export class SasProvider {

  constructor(private http: HttpClient) { }


  /**
   * @description Gets Evidence File Signature Access Key.
   * @param { string } fileName - Current file name to get Signature Access Key.
   * @returns { ResponseSignatureAccesKey } Signature Access Key response.
   */
  public async getEvidenceFileSas(fileName: string): Promise<ResponseSignatureAccesKey> {
    const body = { fileName: fileName };

    return this.http.post<ResponseSignatureAccesKey>(EVIDENCE_API_URL + '/signatureAccessKey', body).toPromise();
  }

  public async getSas(fileName: string): Promise<SignatureAccesKey> {
    const body = {
      fileName: fileName
    };
    return this.http.post<SignatureAccesKey>(evidenceApiUrl + '/' + 'generateSAS', body).toPromise();
  }
}
