import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogInputTag, DialogInputData } from '../../../interfaces/index';
import { DialogInputTags, DialogInputLabels } from './dialog-input.labels';
import { ToastrAlertsService } from '../../../../app/services/utils/toastr-alerts.service';

const CLOSED = 'Closed';
const COLON = ':';
const ZERO = '0';

@Component({
  selector: 'app-dialog-input',
  templateUrl: './dialog-input.component.html',
  styleUrls: ['./dialog-input.component.scss', '../../../app.component.scss']
})
export class DialogInputComponent implements OnInit {
  public date: Date;
  public hour: string;
  public isValidDate: boolean;
  public labels: DialogInputTag;
  public minDate: Date;

  constructor(
    private toast: ToastrAlertsService,
    public dialogRef: MatDialogRef<DialogInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogInputData
  ) { this.labels = DialogInputTags; }

  ngOnInit() {
    this.minDate = new Date();
    this.date = new Date();
    this.hour = (ZERO + new Date().getHours()).slice(-2) +
      COLON + (ZERO + new Date().getMinutes()).slice(-2);
    this.isValidDate = this.concatenateDateAndHour();
  }

  /**
   * @description Send back the selected if it's a valid date.
   */
  public onConfirm(): void {
    if (this.concatenateDateAndHour()) {
      this.dialogRef.close(this.date);
    }
  }

  /**
   * @description Returns 'Closed' if the dialog is closed by the user
   */
  public onClickClose(): void {
    this.dialogRef.close(CLOSED);
  }

  /**
   * @description Prevents an unexpected error at inputs
   * @param event The event to prevent
   */
  public handleKeyEnter(event: Event) {
    event.preventDefault();
  }

  /**
   * @description Concatenates the date value with the time
   * @returns {boolean} Returns true for a valid date, false in other case
   */
  public concatenateDateAndHour(): boolean {
    this.date = new Date(this.date);
    const time = this.hour.split(COLON);
    this.date.setHours(parseInt(time[0], 10), parseInt(time[1], 10), 0, 0);
    return this.dateValidation();
  }

  /**
   * @description Validates if the date selected is greater than the current date
   * @returns {boolean} Returns true for greater dates, false in other case
   */
  public dateValidation(): boolean {
    const dateNoTime =  new Date().setSeconds(0, 0);
    const currentDate = new Date(dateNoTime);
    if (this.date >= currentDate) {
      this.isValidDate = true;
      return true;
    } else {
      this.toast.warningAlert(DialogInputLabels.toastWarningDateMessage);
      this.isValidDate = false;
      return false;
    }
  }

}
