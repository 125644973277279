<div class="dialog-title-flex title-size">
  <div class="title-class" mat-dialog-title>{{ data.title }}</div>
  <span
    matTooltipPosition="above"
    matTooltip="{{ data.tooltipTitle }}">
    <mat-icon id="dscc-info-icon">info</mat-icon>
  </span>
</div>

<mat-dialog-content>
  <form [formGroup]="confirmationFormGroup">
    <mat-grid-list cols="2" rowHeight="1:.30">
      <mat-grid-tile [colspan]="2" [rowspan]="1">
        <div>
          <span class="dialog-text" >
            {{ data.theOrderOrShipmentTitle }}
          </span>
          <span class="focus-text" >
            {{ data.orderOrShipmentFolio }}
          </span>
          <span class="dialog-text">
            {{ data.willChangeFrom }}
          </span>
          <span class="focus-text">
            {{ data.currentStatus }}
          </span>
          <span class="dialog-text">
            {{ data.toTheStatus }}
          </span>
          <span class="focus-text">
            {{ data.desirableStatus }}
          </span>
        </div>
      </mat-grid-tile>
      <mat-grid-tile class="password-field-class">
        <mat-form-field>
          <input
            id="dscc-password-input"
            required
            type="password"
            formControlName="password"
            [type]="hidePassword ? 'password' : 'text'"
            matInput
            placeholder="{{ data.password }}">
            <button
              id="dscc-show-password-btn"
              mat-icon-button
              matSuffix
              [attr.aria-pressed]="hidePassword">
              <mat-icon
                (click)="showOrHidePassword()">
                {{ hidePassword ? 'visibility_off' : 'visibility'}}
              </mat-icon>
            </button>
          <mat-error>
            <span>
              {{ data.passwordRequired }}
            </span>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field>
          <input
            id="dscc-supportTicketInput"
            #drcinvoice
            maxlength="25"
            formControlName="supportTicket"
            matInput
            placeholder="{{ data.supportTicket }}">
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </form>

  <div class="cards-container-button">
    <div class="buttons">
      <button
        id="dscc-cancel"
        mat-stroked-button
        mat-dialog-close
        color="primary"
        class="secondary-button m-r-10">
        {{ data.cancel }}
      </button>
      <button
        id="save"
        mat-raised-button
        color="primary"
        class="primary-button"
        (click)="onConfirm()"
        [disabled]="confirmationFormGroup?.invalid">
        {{ data.changeStatus }}
      </button>
    </div>
  </div>
</mat-dialog-content>
