import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';

import { DeliveryDetail, DeliveryDetailItem, DeliveryDetailTag } from '../../interfaces/detail';
import { LanguageConstants } from '../../constants/language.constants';
import { LanguageChangeEventService } from '../../services/translate/language-change-event.service';
import { LanguageTranslateService } from '../../services/translate/language-translate.service';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';

const CAROUSELTITLE = 'Archivos de visita';
const EVIDENCE = 'Evidencia';
const EVIDENCETYPE = 'evidenceType';
const INCIDENCETYPE = 'incidenceType';
const KEY_FILETYPE = 'fileType';
const INPUTFILES = 'inputFilesByDetail';
const MAXPDFFILESIZE = 1024000;
const PDFFILETYPE = 'application/pdf';
const VALIDEXTENSIONS = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

@Component({
  selector: 'app-delivery-detail',
  templateUrl: './delivery-detail.component.html',
  styleUrls: ['./delivery-detail.component.scss']
})
export class DeliveryDetailComponent implements OnInit, OnDestroy {
  @Input() infoDeliveryDetail: Array<DeliveryDetail>;
  @Output() uploadFiles = new EventEmitter<object>();

  public carouselTitle: string;
  public deliveryDetailTranslatedLabels: any;
  public deliveryDetails: Array<DeliveryDetail>;
  public descriptionData: DeliveryDetailItem;
  public evidence: string;
  public file: any;
  public languageLabels: any;
  public languageSuscription: Subscription;
  public invalidFiles: Array<string>;
  public isValidFile: boolean;
  public selectedRow: number;
  constructor(
    private toast: ToastrAlertsService,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService
  ) {
    this.setLanguage();
  }

  /**
   * @description Angular destroy Lifecycle
   */
  public ngOnDestroy(): void {
    this.languageSuscription?.unsubscribe();
  }

  /**
   * @description Init lifeCycleHook about Angular Core
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
    await this.getDeliveryDetailsTranslatedLabels();
    this.carouselTitle = this.deliveryDetailTranslatedLabels.titleCarousel;
    this.evidence = this.deliveryDetailTranslatedLabels.evidence;
    const sortedData = this.sortData(this.infoDeliveryDetail);
    this.deliveryDetails = sortedData;
    this.selectedRow = 0;
    this.onRowClick(sortedData[0], 0);
  }


  public onRowClick(selection: DeliveryDetail, index: number): void {
    this.selectedRow = index;
    this.descriptionData = {
      type: selection.type,
      reason: selection.reason,
      reasonDescription: selection.reasonDescription,
      files: selection.files,
      numVisit: this.deliveryDetails.length - index,
      timestamp: selection.timestamp
    };
  }

  public sortData(arrayData: Array<DeliveryDetail>) {
    arrayData.sort((b, a) => {
      if (a.date > b.date) {
        return 1;
      }
      if (a.date < b.date) {
        return -1;
      }
      return 0;
    });

    return arrayData;
  }

  public clickFile(): void {
    document.getElementById(INPUTFILES).click();
  }

  public addFilesByDetail(): void {
    const filesToUpload = [];
    this.invalidFiles = [];
    let fileSizes = 0;
    this.file = document.getElementById(INPUTFILES);
    for (let i = 0; i < this.file.files.length; i++) {
      this.isValidFile = false;
      const fileInput = this.file.files[i];
      for (let k = 0; k < VALIDEXTENSIONS.length; k++) {
        if (fileInput.type === VALIDEXTENSIONS[k]) {
          if (fileInput.type === PDFFILETYPE) {
            if (fileInput.size > MAXPDFFILESIZE) {
              this.toast.errorAlert(this.deliveryDetailTranslatedLabels.invalidSize + this.invalidFiles);
              return;
            }
          }
          this.isValidFile = true;
          filesToUpload.push({ file: this.file.files[i], name: this.file.files[i].name, isMobilityEvidence: false });
        }
      }
      if (!this.isValidFile) {
        this.invalidFiles.push(fileInput.name);
      }
    }
      for (let j = 0; j < this.file.files.length; j++) {
        fileSizes = this.file.files[j].size + fileSizes;
      }
    if (!_.isEmpty(this.invalidFiles)) {
      this.toast.errorAlert(this.deliveryDetailTranslatedLabels.invalidFile + this.invalidFiles);
    }

    const aux = {
      files: filesToUpload,
      timestamp: this.descriptionData.timestamp,
      fileType: this.descriptionData.type,
      description: this.descriptionData.reasonDescription
    };

    if (_.isEqual(aux[KEY_FILETYPE], this.evidence)) {
      aux[EVIDENCETYPE] = this.descriptionData.reason;
    } else {
      aux[INCIDENCETYPE] = this.descriptionData.reason;
    }
    this.uploadFiles.emit(aux);
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey Optional language key string, default is spanish 'es'
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getDeliveryDetailsTranslatedLabels();
      },
      (error) => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets delivery detail Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getDeliveryDetailsTranslatedLabels(): Promise<void> {
    this.deliveryDetailTranslatedLabels =
      await this._languageTranslateService.getLanguageLabels(LanguageConstants.MANUAL_DELIVERY_DETAIL_LABELS)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }
}
