import { CATALOG_CONFIG_CONSTANTS } from '../../../constants/catalogs.constants';

export const CUSTOMER_INVOICE = {
  ACCOUNT_CONTROL_NAME: 'accountControlName',
  ACTIONS: {
    add: 'add',
    edit: 'edit',
    viewDetails: 'viewDetails'
  },
  DETAIL_DIALOG_HEIGHT: '600px',
  DETAIL_DIALOG_WIDTH: '850px',
  DIALOG_CONFIRM: 'Confirm',
  DISPLAYEDCOLUMNS: {
    account: 'account',
    actions: 'actions',
    additionalCharges: 'additionalCharges',
    discounts: 'discounts',
    folio: 'folio',
    generationDate: 'generationDate',
    invoiceConcept: 'invoiceConcept',
    invoiceFolio: 'invoiceFolio',
    invoiceObservations: 'invoiceObservations',
    invoiceRecordDate: 'invoiceRecordDate',
    status: 'status',
    subtotal: 'subtotal',
    total: 'total',
    vat: 'vat'
  },
  EXCEL_BUTTON: 'exportExcelButton',
  EXCEL_MIN_ROW_WIDTH: 25,
  EXCEL_TIME_OUT: 2000,
  GRID_COLUMNS: [
    'folio',
    'account',
    'generationDate',
    'invoiceConcept',
    'invoiceFolio',
    'invoiceObservations',
    'invoiceRecordDate',
    'status',
    'subtotal',
    'vat',
    'additionalCharges',
    'discounts',
    'total'],
  ICONS: {
    tempIcon: 'send-to-tendering',
    view: 'remove_red_eye'
  },
  MINUS_ONE: -1,
  RECORD_DIALOG_WIDTH: '550px',
  SCF_GRID_CONFIG: {
    columnToggler: false,
    pageSize: CATALOG_CONFIG_CONSTANTS.DEFAULT_PAGE_SIZE,
    pageSizeOptions: CATALOG_CONFIG_CONSTANTS.PAGE_SIZE_OPTIONS,
    rowDraggable: false,
    selectable: false
  }
};
