<div class="scf-flex-row">
  <div class="title flex-class title-class">
    <h4>{{ labels.title }} {{ shipment.mobilityId }}</h4>
  </div>

  <div class="flex-class close-class">
    <mat-icon class="cursor-pointer close-button" (click)="onClickClose()">
      close
    </mat-icon>   
  </div>
</div>

<div class="scf-flex-row">
  <div class="block flex-class calendar-class">
    <mat-icon>
      <img src="../../../../assets/icons/calendar-clock.svg" alt="calendar">
    </mat-icon>
  </div>

  <div class="block flex-class route-class">
    <p class="subtitle">{{ labels.routeSubtitle }}</p>
    <p class="element">
      &bull; <strong>{{ labels.origin }}: </strong> {{ shipment.origin.name }} <br>
      &bull; <strong>{{ labels.destination }}: </strong> {{ shipment.destination.name }}
    </p>
  </div>

  <div class="block flex-class subtitle-route-class">
    <p class="subtitle">{{labels.routeSubtitle}}</p>
    <div *ngIf="!inTransit">
      <p class="element">
        &bull; <strong>{{ labels.totalDistance }}: </strong> {{ shipment.distance ? shipment.distance : 0 }} {{ labels.kilometers }} <br>
        &bull; <strong>{{ labels.distribution }}: </strong> {{ shipment.orders.length }}
      </p>
    </div>

    <div *ngIf="inTransit">
      <p class="element">
        &bull; <strong>{{ labels.distanceTraveled }}: </strong> {{ traveledRoute }} <br>
        &bull; <strong>{{ labels.distribution }}: </strong> {{ shipment.orders.length }}
      </p>
    </div>
  </div>
</div>

<mat-divider class="mat-divider-header"></mat-divider>

<form id="assignTransportForm" [formGroup]="assignTransport">
  <div class="scf-flex-row">
    <div class="flex-class break-line-class"></div>
    <div class="flex-class transport-class">
      <div class="center-horizontal-align">
        <mat-icon>
          <img src="../../../../assets/icons/truck-gear.svg" alt="truck-gear">
        </mat-icon>
      </div>

      <p class="question">{{ labels.transportQuestion }}</p>
      <mat-form-field class="form-field select center-horizontal-align">
        <mat-select
          formControlName="vehicle"
          [disabled]="!allowAssign">
          <mat-option *ngFor="let vehicle of vehicles" [value]="vehicle._id">
            {{ vehicle.placas }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <p class="question">{{ labels.driverQuestion }}</p>
      <mat-form-field class="form-field select">
        <mat-select
          formControlName="driver"
          [disabled]="!allowAssign">
          <mat-option *ngFor="let driver of drivers" [value]="driver._id">
            {{ driver.nombre }} {{ driver.appaterno }} {{ driver.appMaterno }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>

<div *ngIf="allowAssign" class="buttons" mat-dialog-actions>
  <mat-grid-list cols="2" rowsHeight="1:.5">
    <mat-grid-tile class="mat-grid-tile-btn">
      <button
        class="secondary-button align-right"
        mat-stroked-button
        color="primary"
        (click)="onCancelClick()">
        {{ labels.cancelButton }}
      </button>
    </mat-grid-tile>
    <mat-grid-tile class="mat-grid-tile-btn">
      <button
        class="primary-button align-left"
        mat-raised-button
        color="primary"
        (click)="assignTransportToShipment()">
        {{ labels.sendButton }}
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</div>
