import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

const PATH = '/biis/embarcador/motivosrechazo';

@Component({
  selector: 'app-rejection-catalog',
  templateUrl: './rejection-catalog.component.html',
  styleUrls: ['../../../app.component.scss']
})
export class RejectionCatalogComponent implements OnInit {
  public urlSafe: SafeResourceUrl;
  public url: string = environment.clientUrl + PATH + '?us=';

  constructor(
    private cookieService: CookieService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    const petition = this.cookieService.get('auth');
    const us = JSON.parse(petition);
    us.path = PATH;

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + JSON.stringify(us));
  }

}
