import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogDuplicateAssignmentLabels } from './dialog-duplicate-assignment-labels';
import { DuplicateAssignment } from '../../../interfaces/importation-shipments';

@Component({
  selector: 'app-dialog-duplicate-assignment',
  templateUrl: './dialog-duplicate-assignment.component.html',
  styleUrls: ['./dialog-duplicate-assignment.scss']
})
export class DialogDuplicateAssignmentComponent implements OnInit {
  public labels;
  constructor(public dialogRef: MatDialogRef<DialogDuplicateAssignmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DuplicateAssignment) {
      this.labels = DialogDuplicateAssignmentLabels;
    }

  /**
   * @description Angular Lifecycle for component initialization
   */
  public ngOnInit(): void {
    this.labels = DialogDuplicateAssignmentLabels;
  }

  /**
   * @description Active the action of the button secundary
   */
  public onClickSecundary(): void {
    this.dialogRef.close(false);
  }

  /**
   * @description Active the action of the button primary.
   */
  public onClickPrimary(): void {
    this.dialogRef.close(true);
  }

  /**
   * @description Action for clousing dialog
   */
  public onClickClose(): void {
    this.dialogRef.close(false);
  }
}
