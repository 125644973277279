import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { AppService } from '../../../app.service';
import { ConfigurationProvider } from '../../../providers/configuration/configuration.provider.service';
import { EvidenceFolioFormatConstants } from './evidence-folio-format.constants';
import { EvidenceFolioFormatProperties } from './evidence-folio-format.properties';
import { EventFormat, EvidenceFolio, Shipper } from '../../../interfaces';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { ShipperService } from './../../../providers/shipper/shipper.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

import getAverageColor from 'get-average-color';
import { LanguageConstants } from 'src/app/constants/language.constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-evidence-folio-format',
  templateUrl: './evidence-folio-format.component.html',
  styleUrls: ['./evidence-folio-format.component.scss', '../../../app.component.scss']
})

export class EvidenceFolioFormatComponent implements OnInit, OnDestroy {
  @Input() evidenceFolio: EvidenceFolio;

  public codeProperty: string;
  public dataSource: MatTableDataSource<any>;
  public displayedColumnsAdditional: Array<string>;
  public displayedColumnsEvidence: Array<string>;
  public evicenceFolioFormatLabelsTranslated: any;
  public languageSuscription: Subscription;
  public languageLabels: any;
  public logoUrl: string;
  public shipperData: Shipper;
  public shipperOId: string;
  public svgPropertie: string;

  constructor(
    private _appService: AppService,
    private configProvider: ConfigurationProvider,
    private shipperService: ShipperService,
    private toast: ToastrAlertsService,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService
  ) {
    this.setLanguage();
  }

  /**
   * @description Init angular component life-cycle
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
    await this.getEvicenceFolioFormatLabels();
    this.shipperOId = this._appService.getShipperOid();
    this.displayedColumnsEvidence = EvidenceFolioFormatConstants.FIRST_EVIDENCE_COLUMN;
    this.displayedColumnsAdditional = EvidenceFolioFormatConstants.SECOND_EVIDENCE_COLUMN;
    this.getShipperLogo();
    this.dataSource = new MatTableDataSource(this.evidenceFolio.rows);
    this.getShipperInfo();
  }

  /**
   * @description Angular destroy lifecycle
   */
  public ngOnDestroy() {
    this.languageSuscription?.unsubscribe();
  }

  /**
   * @description Gets Shipper logo for the document view
   */
  public async getShipperLogo(): Promise<void> {
    this.logoUrl = await this.configProvider.getShipperLogo();
  }

  /**
   * @description Gets shipper info
   */
  public async getShipperInfo(): Promise<void> {
    try {
      const shippersInfo = await this.shipperService.getShipper();
      this.shipperData = shippersInfo.find((shipper) => shipper._id === this.shipperOId);
    } catch (error) {
      this.toast.errorAlert(this.evicenceFolioFormatLabelsTranslated.notFoundShippers);
    }
  }

  /**
   * @description Sets bar divider color rely on shipper logo
   */
  public async setBarDividerColor(): Promise<void> {
    try {
      const rgb = await getAverageColor(this.logoUrl);
      const barColor = 'rgb(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ')';
      document.getElementById(EvidenceFolioFormatConstants.TITLE_CONTAINER).style.borderBottom =
        EvidenceFolioFormatProperties.borderLinePropertiesWithoutColor + barColor;
    } catch (error) {
      this.toast.errorAlert(this.evicenceFolioFormatLabelsTranslated.notFoundInfo);
    }
  }

  /**
   * @description Set default image in case isn't defined
   * @param {EventFormat} event
   */
  public setDefaultImage(event: EventFormat): void {
    event.target.src = EvidenceFolioFormatConstants.DEFAULT_IMAGE;
    document.getElementById(EvidenceFolioFormatConstants.TITLE_CONTAINER).style.borderBottom =
      EvidenceFolioFormatProperties.borderLinePropertiesWithColor;
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey Optional language key string, default is spanish 'es'
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getEvicenceFolioFormatLabels();
      },
      (error) => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets Evidence Folios formats Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getEvicenceFolioFormatLabels(): Promise<void> {
    this.evicenceFolioFormatLabelsTranslated =
      await this._languageTranslateService.getLanguageLabels(LanguageConstants.EVIDENCE_FOLIO_FORMAT_LABEL)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }
}

