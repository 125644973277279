export const CustomerShipmentRequestLabels: CustomerQuoteShipmentRequestLabel = {
  assignedCarrier: 'Asignar Transportista',
  assignedMarketplace: 'Asignar en Marketplace',
  assignedShipmentsTabtitle: 'Asignadas',
  backLabel: 'Regresar',
  cubicMeters: 'm³',
  currency: '$',
  descriptionInput: 'Ingresa la cotización BIIS',
  descriptionPlace: 'Esta será la cotización mostrada al transportista.',
  everythingShipmentsTabTitle: 'Todas',
  frequencyLabel: 'Frecuencia',
  inTransitShipmentsTabTitle: 'En curso',
  kg: 'Kg',
  kilometers: 'Km',
  rateLabel: 'Tarifa',
  noInfo: 'Sin información',
  notOfferShipmentsTabTitle: 'Sin ofertas',
  pageTitle: 'Solicitudes Clientes',
  pendingShipmentsTabTitle: 'Pendientes',
  routeLabel: 'Ruta',
  sendMarketplaceLabel: 'Enviar a Marketplace',
  searchPlaceholder: 'Buscar en la lista',
  specificationsLabel: 'Especificaciones',
  title: 'Configurar cotización BIIS para el transporte',
  typeLabel: 'Tipo',
  transportLabel: 'Transporte',
  totalDistanceLabel: 'Distancia total',
  viewDetail: 'Ver detalle',
  withoutAssignedShipmentsTabTitle: 'Sin asignar',
  withoutQuote: 'Sin cotización',
};

export interface CustomerQuoteShipmentRequestLabel {
  assignedCarrier: string;
  assignedMarketplace: string;
  assignedShipmentsTabtitle: string;
  backLabel: string;
  cubicMeters: string;
  currency: string;
  descriptionInput: string;
  descriptionPlace: string;
  everythingShipmentsTabTitle: string;
  frequencyLabel: string;
  inTransitShipmentsTabTitle: string;
  kg: string;
  kilometers: string;
  rateLabel: string;
  noInfo: string;
  notOfferShipmentsTabTitle: string;
  pageTitle: string;
  pendingShipmentsTabTitle: string;
  routeLabel: string;
  sendMarketplaceLabel: string;
  searchPlaceholder: string;
  specificationsLabel: string;
  title: string;
  typeLabel: string;
  transportLabel: string;
  totalDistanceLabel: string;
  viewDetail: string;
  withoutAssignedShipmentsTabTitle: string;
  withoutQuote: string;
}
