import { Injectable } from '@angular/core';
import { Router, ResolveEnd, ActivatedRouteSnapshot } from '@angular/router';

import { environment } from '../../../environments/environment';
import { IApplicationInsightsService } from './application-insights.service.interface';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { isEqual, isNull, isUndefined } from 'lodash';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

const INSIGHTS_CLEAR_AUTH_ERROR = 'Application insights user authentication cannot be cleared:';
const INSIGHTS_CLOUD_ROLE_TAG = 'ai.cloud.role';
const INSIGHTS_CONFIGURATION_ERROR = 'Application insights cannot be set up:';
const INSIGHTS_DEFAUL_ROLE_NAME = 'TEP';
const INSIGHTS_EVENT_ERROR = 'Application insights event cannot be tracked:';
const INSIGHTS_EXCEPTION_ERROR = 'Application insights error cannot be tracked:';
const INSIGHTS_GENERAL_ERROR = 'Application insights cannot be started:';
const INSIGHTS_METRIC_ERROR = 'Application insights metric cannot be tracked:';
const INSIGHTS_OPERATION_NAME_TAG = 'ai.operation.name';
const INSIGHTS_PAGE_VIEW_ERROR = 'Application insights page view cannot be tracked:';
const INSIGHTS_TRACE_ERROR = 'Application insights trace cannot be tracked:';
const INSIGHTS_USER_AUTH_ERROR = 'Application Insights user authentication cannot be set:';
const SNAPSHOT_COMPONENT_NAME_TAG = 'name';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService implements IApplicationInsightsService<ApplicationInsightsService> {
  private _appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsightsInstrumentationKey
    }
  });
  private _cloudRole: string = environment.appInsightsRoleName
    ? environment.appInsightsRoleName
    : INSIGHTS_DEFAUL_ROLE_NAME;
  private _enabled = 'true';
  private _routerSubscription: Subscription;
  private _trackingConditional =
    isEqual(environment.appInsightsEnabled.toString(), this._enabled) &&
    !isUndefined(this._appInsights.config.instrumentationKey);

  constructor(private router: Router) {
    this.monitoringServiceStart();
  }

  /**
   * @description Start Application Insights monitoring service.
   */
  public monitoringServiceStart(): void {
    try {
      if (this._trackingConditional) {
        this._appInsights.loadAppInsights();
        this.monitoringServiceSetUp();
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_GENERAL_ERROR} ${error.message}`);
    }
  }

  /**
   * @description Stop Application Insights monitoring service.
   */
  public monitoringServiceStop(): void {
    this._appInsights.config.disableTelemetry = true;
  }

  /**
   * @description Application Insights configuration set up.
   */
  public monitoringServiceSetUp(): void {
    try {
      if (this._trackingConditional) {
        this.monitoringRoleNameSetUp();
        this._routerSubscription = this.router.events.pipe(
          filter(event => event instanceof ResolveEnd))
          .subscribe((event: ResolveEnd) => {
            const activatedComponent = this.getActivatedComponent(event.state.root);
            if (activatedComponent) {
              this._appInsights.addTelemetryInitializer((envelope) => {
                  envelope.tags[INSIGHTS_OPERATION_NAME_TAG] = event.urlAfterRedirects;
              });
              this.trackPageView(`${activatedComponent}`, event.urlAfterRedirects);
            }
          }
        );
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_CONFIGURATION_ERROR} ${error.message}`);
    }
  }

  /**
   * @description Configures application insights role name displayed in Azure resource.
   */
  public monitoringRoleNameSetUp(): void {
    if (!isNull(this._cloudRole) && !isUndefined(this._cloudRole)) {
      this._appInsights.addTelemetryInitializer((envelope) => {
        envelope.tags[INSIGHTS_CLOUD_ROLE_TAG] = this._cloudRole;
      });
    }
  }

  /**
   * @description Tracks events caught.
   * @param {string} name - Name of the event.
   * @param {dictionary} properties - Additional data used to filter events.
   */
  public trackEvent(name: string, properties?: { [key: string]: string }): void {
    try {
      if (this._trackingConditional) {
        this._appInsights.trackEvent(
          { name: name },
          { customProperties: properties }
        );
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_EVENT_ERROR} ${error.message}`);
    }
  }

  /**
   * @description Tracks exceptions caught.
   * @param {Error} exception - Error object.
   * @param {dictionary} properties - Additional data used to filter exceptions.
   * @param {number} severityLevel - Severity of the exception.
   */
  public trackException(exception: Error, properties?: { [key: string]: string }, severityLevel?: number ): void {
    try {
      if (this._trackingConditional && exception instanceof Error) {
        this._appInsights.trackException(
          { exception: exception, severityLevel: severityLevel },
          { customProperties: properties }
        );
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_EXCEPTION_ERROR} ${error.message}`);
    }
  }

  /**
   * @description Tracks custom metrics caught.
   * @param {string} name - Custom metric name.
   * @param {number} value - Custom metric value.
   * @param {dictionary} properties - Additional data used to filter custom metrics.
   */
  public trackMetric(name: string, value?: number, properties?: { [key: string]: string }): void {
    try {
      if (this._trackingConditional) {
        this._appInsights.trackMetric(
          { name: name, average: value },
          { customProperties: properties }
        );
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_METRIC_ERROR} ${error.message}`);
    }
  }

  /**
   * @description Tracks custom logs caught.
   * @param {string} message - Custom log.
   * @param {dictionary} properties - Additional data used to filter custom logs.
   */
  public trackTrace(message: string, properties?: { [key: string]: string }): void {
    try {
      if (this._trackingConditional) {
        this._appInsights.trackTrace(
          { message: message },
          { customProperties: properties }
        );
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_TRACE_ERROR} ${error.message}`);
    }
  }

  /**
   * @description Tracks pages views caught.
   * @param {string} name - Name of the page view.
   * @param {string} uri - Relative or absolute URL that identifies the page.
   * @param {dictionary} properties - Additional data used to filter pages.
   * @param {string} refUri - URL of the previous page that sent the user to the current page.
   */
  public trackPageView(name?: string, uri?: string, properties?: { [key: string]: string }, refUri?: string): void {
    try {
      if (this._trackingConditional) {
        this._appInsights.trackPageView({ name, uri, properties, refUri });
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_PAGE_VIEW_ERROR} ${error}`);
    }
  }

  /**
   * @description Set the authenticated user id and the account id.
   * Use this when you have identified a specific signed-in user.
   * @param {string} userId - User id identifier.
   * @param {string} accountId - User account identifier.
   * @param {boolean} storeInCookie - Cookie to set them for all events within the whole session.
  */
  public setAuthenticatedUserId(userId: string, accountId?: string, storeInCookie?: boolean): void {
    try {
      if (this._trackingConditional) {
        this._appInsights.setAuthenticatedUserContext(userId, accountId, storeInCookie);
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_USER_AUTH_ERROR} ${error}`);
    }
  }

  /**
   * @description Clears the authenticated user id and the account id from the user context,
   * and clears the associated cookie.
   */
  public clearUserId(): void {
    try {
      if (this._trackingConditional) {
        this._appInsights.clearAuthenticatedUserContext();
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_CLEAR_AUTH_ERROR} ${error}`);
    }
  }

  /**
   * @description Monitorig api errors on express, create a log error and an event.
   * @param {Error} error - Error object.
   */
  public monitorigError(error: Error): void {
    const properties = {
      Name: error.name,
      Stack: JSON.stringify(error.stack),
      Message: error.message
    };
    this.trackException(error, properties);
    this.trackEvent(`Error at ${this._cloudRole}`, properties);
  }

  /**
   * @description Trace the component of the innermost route.
   * @param {ActivatedRouteSnapshot} snapshot - Route associated with a component loaded.
   * @returns {string} Component traced
   */
  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): string {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component[SNAPSHOT_COMPONENT_NAME_TAG].toString();
  }
}
