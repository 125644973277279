import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { Chart, ChartConfiguration } from 'chart.js/auto';
import { ChartTypeRegistry } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';

import { AppConstants } from '../../constants/app-constants.constants';
import { CHART_GRAPH_CONSTANTS } from './chart-graph.constants';

@Component({
  selector: 'app-chart-graph',
  templateUrl: './chart-graph.component.html',
  styleUrls: ['./chart-graph.component.scss']
})
/**
 * @description Component to create single or multiple charts with Chart.js lib.
 */
export class ChartGraphComponent implements AfterViewInit {
  @ViewChild('chart') chart: ElementRef;
  @Input() config: ChartConfiguration;
  @Input() dataConfig: any;

  public subtitle: string;
  public title: string;

  /**
   * @description Function to execute code only after view is completely initialized.
   */
  public ngAfterViewInit(): void {
    if (this.dataConfig) {
      this.subtitle = this.dataConfig.subtitle;
      this.title = this.dataConfig.title;
      this.config = this.buildGraphConfig();
      this.config.data.datasets[0].data = this.dataConfig.data;
      this.config.data.labels = this.dataConfig.labels;
      this.config.options.backgroundColor = this.dataConfig.backgroundColor;
      this.config.options.borderColor = this.dataConfig.backgroundColor;
    }

    this.generateGraph();
  }

  /**
   * @description Builds an object with chart configuration options and design.
   * @returns {ChartConfiguration} Object with all necessary chart configuration data.
   */
  private buildGraphConfig(): ChartConfiguration {
    let title = AppConstants.EMPTY_STRING;
    let subtitle = AppConstants.EMPTY_STRING;

    if (this.dataConfig) {
      subtitle = this.dataConfig.subtitle;
      title = this.dataConfig.title;
    }

    const chartConfiguration = {
      type: <keyof ChartTypeRegistry>CHART_GRAPH_CONSTANTS.GRAPH_TYPE,
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            cutout: CHART_GRAPH_CONSTANTS.GRAPH_CUTOUT
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          filler: {
            propagate: true
          },
          legend: {
            onClick: null,
            display: true,
            position: <_DeepPartialObject<{ [scaleId: string]: number; }>><unknown>CHART_GRAPH_CONSTANTS.GRAPH_LABELS_POSITION,
            labels: {
              usePointStyle: true,
              padding: CHART_GRAPH_CONSTANTS.GRAPH_LABELS_PADDING,
              font: {
                size: CHART_GRAPH_CONSTANTS.GRAPH_LABELS_SIZE,
                weight: CHART_GRAPH_CONSTANTS.GRAPH_LABELS_WEIGHT
              }
            }
          },
          title: {
            display: true,
            font: {
              size: CHART_GRAPH_CONSTANTS.GRAPH_TITLE_SIZE
            },
            text: AppConstants.EMPTY_STRING
          }
        }
      },
      plugins: [
        {
          id: CHART_GRAPH_CONSTANTS.GRAPH_CENTER_LABEL.ID,
          beforeDraw(chart: any) {
            const {ctx, data} = chart;
            ctx.restore();
            const total = data.datasets[0].data.reduce((previousValue: number, currentValue: number) => {
              return previousValue + currentValue;
            });
            const xCoor = chart.getDatasetMeta(0).data[0].x;
            const yCoor = chart.getDatasetMeta(0).data[0].y;
            ctx.font = CHART_GRAPH_CONSTANTS.GRAPH_CENTER_LABEL.FONT_STYLE;
            ctx.textAlign = CHART_GRAPH_CONSTANTS.GRAPH_CENTER_LABEL.ALIGN;
            ctx.textBaseline = CHART_GRAPH_CONSTANTS.GRAPH_CENTER_LABEL.BASELINE;
            ctx.fillText(total, xCoor, yCoor);
            ctx.save();
          }
        },
        {
          id: CHART_GRAPH_CONSTANTS.GRAPH_TITLE_ID,
          afterDraw(chart: any) {
            const { ctx, chartArea } = chart;
            ctx.save();
            ctx.font = CHART_GRAPH_CONSTANTS.GRAPH_TITLE_FONT;
            ctx.textAlign = CHART_GRAPH_CONSTANTS.GRAPH_TEXT_ALLIGN_LEFT;
            ctx.textBaseline = CHART_GRAPH_CONSTANTS.GRAPH_CENTER_LABEL.BASELINE;
            ctx.fillStyle = CHART_GRAPH_CONSTANTS.GRAPH_TITLE_COLOR;
            ctx.fillText(title, CHART_GRAPH_CONSTANTS.GRAPH_TITLE_START_POSITION, 
              chartArea.top - CHART_GRAPH_CONSTANTS.GRAPH_TITLE_SUBTITLE_TOP_POSITION);
            ctx.restore();
          }
        },
        {
          id: CHART_GRAPH_CONSTANTS.GRAPH_SUBTITLE_ID,
          afterDraw(chart: any) {
            const { ctx, canvas, chartArea } = chart;
            ctx.save();
            ctx.font = CHART_GRAPH_CONSTANTS.GRAPH_SUBTITLE_FONT;
            ctx.textAlign = CHART_GRAPH_CONSTANTS.GRAPH_TEXT_ALLIGN_LEFT;
            ctx.textBaseline = CHART_GRAPH_CONSTANTS.GRAPH_CENTER_LABEL.BASELINE;
            ctx.fillStyle = CHART_GRAPH_CONSTANTS.GRAPH_SUBTITLE_COLOR;
            ctx.style = AppConstants.EMPTY_STRING
            ctx.fillText(subtitle, canvas.width / CHART_GRAPH_CONSTANTS.GRAPH_SUBTITLE_POSITION_ADJUST,
              chartArea.top - CHART_GRAPH_CONSTANTS.GRAPH_TITLE_SUBTITLE_TOP_POSITION);
            ctx.restore();
          }
        }
      ]
    };

    return chartConfiguration;
  }

  /**
   * @description Gets chart element context and builds graph.
   */
  private generateGraph(): void {
    const context = this.chart.nativeElement.getContext('2d');

    new Chart(
      context,
      {
        type: this.config.type,
        data: this.config.data,
        options: this.config.options,
        plugins: this.config.plugins
      }
    );
  }
}
