<div class="scf-flex-row">
  <div class="dialog-title flex-class">
    {{ labels.title }}
  </div>
  <div class="dialog-close-icon flex-class">
    <mat-icon style="cursor:pointer" (click)="onClickClose()">
      close
    </mat-icon>   
  </div>
</div>

<br/>
<div *ngIf="data.drivers.length"
  class="content scf-flex-row">
  <br/>
  <div class="scf-flex-column">
    <div>{{ labels.driverWarning }}</div>
    <div class="dialog-subtext">{{ data.drivers.join() }}</div>
  </div>
</div>

<div *ngIf="data.vehicles.length"
  class="content scf-flex-row">
  <br/>
  <div class="scf-flex-column">
    <div>{{ labels.vehicleWarning }}</div>
    <div class="dialog-subtext">{{ data.vehicles.join() }}</div>
  </div>
</div>
<br/>

<div>
  {{ labels.questionConfirm }}
</div>

<br/>
<div class="group-btn scf-flex-row">
  <div class="buttons-section-class">
    <button 
      mat-button
      class="secondary-button align-left" 
      (click)="onClickSecundary()">
      {{ labels.secondaryButton }}
    </button>
    <button
      mat-button
      mat-raised-button
      class="primary-button align-right"
      (click)="onClickPrimary()">
      {{ labels.primaryButton }}
    </button>
  </div>
</div>
