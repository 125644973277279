<div>
  <mat-grid-list cols="3" rowHeight="1:.30">
    <mat-grid-tile colspan="3" rowspan="1">
      <div class="dialog-title">
        <span class="title">
          {{ title }}
        </span>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <!-- capture sales charge section -->
  <div *ngIf="shouldDisplayCaptureSalesChargeSection()">
    <div class="dialog-charges-title-container">
      <div class="charges-title-column">
        <h3>{{ data.dialogSalesChargesSubtitle }}</h3>
      </div>
      <div class="charges-divider-column">
        <mat-divider class="mat-divider-header"></mat-divider>
      </div>
    </div>
    <div class="dialog-charges-inputs-container">
      <div class="column">
        <div *ngFor="let account of data.accounts; let i = index" class="row-input">
          <ng-container *ngIf="i % 2 === 0">
            <mat-form-field>
              <input 
                matInput 
                type="number"
                max="999999.99"
                min="1"
                step="1"
                id="dgcsc-input-dynamic-{{ i }}-primary" 
                [(ngModel)]="data.accounts[i].charge"
                (ngModelChange)="validateField(fieldModel, true)"
                name="account{{ i }}"
                #fieldModel="ngModel"
                [required]="!!data.showResume"
                [placeholder]='data.chargeOf + account.name' 
                class="dialog-input-charge"/>
                <mat-error *ngIf="fieldModel.invalid && (fieldModel.dirty || fieldModel.touched)">
                  <span *ngIf="fieldModel?.hasError('invalid') ">{{ data.chargeErrorMessage }}</span>
                </mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
      <div class="column">
        <div *ngFor="let account of data.accounts; let i = index" class="row-input">
          <ng-container *ngIf="i % 2 !== 0">
            <mat-form-field>
              <input 
              matInput 
              type="number" 
              min="1" 
              max="999999.99" 
              step="1"
              id="dgcsc-input-dynamic-{{ i }}-secondary" 
              [(ngModel)]="data.accounts[i].charge"
              (ngModelChange)="validateField(fieldModel, true)"
              name="account{{ i }}"
              #fieldModel="ngModel"
              [required]="!!data.showResume"
              [placeholder]="data.chargeOf + account.name"
              aria-describedby="account-charge-error"
              class="dialog-input-charge"/>
              <mat-error *ngIf="fieldModel.invalid && (fieldModel.dirty || fieldModel.touched)">
                <span *ngIf="fieldModel?.hasError('invalid')">{{ data.chargeErrorMessage }}</span>
              </mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- confirm body section -->
  <div *ngIf="shouldDisplayConfirmDialogSection()" class="dialog-message-container">
    <div class="dialog-resume-icon">
      <mat-icon class="resume-mat-icon">{{ data.icon }}</mat-icon>
    </div>
    <div class="dialog-container-resume-text">
      <div class="dialog-resume-text">
        <span>{{ data.salesChargesConfirm }}</span>
        <span>{{ data.freightChargeConfirm }}</span>
      </div>
      <div class="dialog-question">
        <span>{{ data.question }}</span>
      </div>
    </div>
  </div>

  <!-- capture freight cost section -->
  <mat-grid-list *ngIf="!isReadOnly" cols="6" rowHeight="90px">
    <mat-grid-tile colspan="3">
      <div *ngIf="data.chargePlaceholder">
        <mat-form-field class="input-w-100">
          <input
            matInput
            id="dgcsc-input-account-charge"
            type="number"
            min="1"
            max="999999.99"
            step="1"
            [(ngModel)]="accountCharge"
            (ngModelChange)="validateField(chargeCtrl)"
            [required]="!!data.showResume"
            [placeholder]='data.chargePlaceholder'
            aria-describedby="account-charge-error"
            name="accountCharge"
            #chargeCtrl="ngModel"
            class="dialog-input-charge"/>
            <mat-error *ngIf="chargeCtrl.invalid && (chargeCtrl.dirty || chargeCtrl.touched)">
              <span *ngIf="chargeCtrl?.hasError('invalid') ">{{ data.chargeErrorMessage }}</span>
            </mat-error>
        </mat-form-field>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="3">
      <div class="dialog-container-buttons">
        <button
          mat-stroked-button
          color="primary"
          class="secondary-button button"
          id="dgcsc-cancel-button"
          (click)="onClickCancelButton()">
          {{ data.cancel }}
        </button>
        <button 
          mat-stroked-button
          color="primary"
          class="primary-button button"
          id="dgcsc-continue-button"
          (click)="onClickContinueButton()"
          [disabled]="!isConfirm">
          {{ data.continue }}
        </button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <!-- freight cost read only section -->
  <div *ngIf="isReadOnly" class="dialog-message-container">
    <div class="dialog-resume-icon">
      <mat-icon class="resume-mat-icon">{{ data.icon }}</mat-icon>
    </div>
    <div class="dialog-container-resume-text">
      <div class="dialog-resume-text">
        <span>{{ data.salesChargesConfirm }}</span>
        <span>{{ data.freightChargeConfirm }}</span>
      </div>
      <div class="dialog-question">
        <span>{{ data.question }}</span>
      </div>
    </div>
  </div>
  <mat-grid-list *ngIf="isReadOnly" cols="1" rowHeight="50px">
    <mat-grid-tile colspan="1">
      <div class="dialog-container-buttons-read-only">
        <button
          mat-stroked-button
          color="primary"
          class="primary-button button"
          id="dgcsc-cancel-button"
          (click)="onClickCancelButton()">
          {{ data.close }}
        </button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
