export const DialogAddAdditionalChargesConst = {
  ACCEPTED: 'Accepted',
  CHARGES: 'additionalCharges',
  CHARGES_SAVED: 'additionalChargesSaved',
  CHARGES_TO_SAVE: 'additionalChargesToSave',
  CLOSED: 'Closed',
  DISCOUNTS: 'discounts',
  DISCOUNTS_SAVED: 'discountsSaved',
  DISCOUNTS_TO_SAVE: 'discountsToSave',
  LOCAL_CHARGES_TO_SAVE: 'localChargesToSave',
  LOCAL_DISCOUNTS_TO_SAVE: 'localDiscountsToSave',
  MAX_VALUE: 999999999.999,
  MIN_VALUE: 0.001,
  PREFIX_CHARGES_TO_SAVE: 'ToSave'
};
