<div class="responsive-card">
  <h3 class="title-dialog">
    {{ data.title }}
  </h3>
  
  <div class="question-sign">
    <p class="question-text"> {{ data.description }} </p>
  </div>
  
  <div class="question-sign">
    <p class="question-text"> {{ data.question }} </p>
  </div>
  <br> 

  <div class="dialog-sign row">
    <div class="icon-container">
      <img
        alt="iconDialog"
        src="{{ iconPath }}">
    </div>
    <div class="message-container">
      <b class="dialog-sign-text"> {{ data.messageTotalInvoice }} </b>
      <p> {{ data.messageTotalCharge }} </p>
    </div>
  </div>

  <mat-grid-list cols="2" class="button-list" rowHeight="1:.2">
    <mat-grid-tile class="button-container">
      <button
        id="cancelButton"
        mat-raised-button
        class="secondary-button"
        (click)="onClickCancel()">
        {{ 'invoiceProporsalLabels.cancelButton' | translate }}
      </button>
      <button
        id="acceptButton"
        mat-raised-button
        class="primary-button align-right"
        (click)="onClickAccept()">
        {{ 'invoiceProporsalLabels.acceptButton' | translate }}
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</div>
