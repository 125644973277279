import { ChangeDetectorRef, Component, Injectable, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AppService } from '../../../app.service';
import { GenericRegexp } from '../../../regexp/generic.regexp';
import { MarketplaceConstants } from '../../../pages/shipments/marketplace/new-shipment-request/new-shipment-request-constants';
import { MarketplaceService } from '../../../services/marketplace/marketplace.service';
import { NewShipmentRequestLabel } from '../../../pages/shipments/marketplace/new-shipment-request/new-shipment-request-label';
import { NewShipmentRequestTags } from '../../../interfaces/marketplace/new-shipment-request';
import { Tariff } from '../../../interfaces/tariff';

const MONEY_CIRCLE_ROUTE = '../../../../assets/CreateOrder.svg';
const MONEY_UP_ROUTE = '../../../../assets/money-up.svg';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss'],
})
export class RateComponent implements OnInit {
  public amountSuggested: number;
  public assignBudget: boolean;
  public invalidAmount: boolean;
  public invalidMinAmount: boolean;
  public invalidInput: boolean;
  public isAmountAutocompleted: boolean;
  public isUserReturn: boolean;
  public label: NewShipmentRequestTags;
  public lastTariffSuggested: Tariff;
  public moneyCircle: string;
  public moneyUp: string;
  public rateFormGroup: UntypedFormGroup;
  public rateTimePickerAssign: string;
  public rateTimePickerPublish: string;
  public rating: number;
  public receiveOffers: boolean;
  public receiveCounterOffers: boolean;
  public tariffFound: boolean;
  @Input() tariffSuggested: Tariff;

  constructor(
    private appService: AppService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private marketplaceService: MarketplaceService
    ) {
    this.assignBudget = false;
    this.invalidAmount = false;
    this.isAmountAutocompleted = true;
    this.invalidInput = false;
    this.isUserReturn = false;
    this.tariffFound = false;
    this.invalidMinAmount = false;
    this.label = NewShipmentRequestLabel;
    this.moneyCircle = MONEY_CIRCLE_ROUTE;
    this.moneyUp = MONEY_UP_ROUTE;
    this.rateTimePickerAssign = MarketplaceConstants.TIME_FORMAT;
    this.rateTimePickerPublish = MarketplaceConstants.TIME_FORMAT;
    this.receiveOffers = false;
    this.receiveCounterOffers = false;
  }

  /**
   * @description Angular lifecycle for component initialization
   */
  public ngOnInit(): void {
    this.initRateForm();
    this.rateFormGroup.get(MarketplaceConstants.RATE_OPTION).valueChanges.subscribe((value) => {
      switch (value) {
        case MarketplaceConstants.ASSIGN_BUDGET:
          this.rateFormGroup.get(MarketplaceConstants.AMOUNT).setValidators(Validators.required);
          this.rateFormGroup.get(MarketplaceConstants.COMMENTS_TO_CARRIER).disable();
          this.rateFormGroup.get(MarketplaceConstants.AMOUNT).enable();
        break;
        case MarketplaceConstants.RECEIVE_OFFERS:
          this.rateFormGroup.get(MarketplaceConstants.AMOUNT).clearValidators();
          this.rateFormGroup.get(MarketplaceConstants.AMOUNT).disable();
          this.rateFormGroup.get(MarketplaceConstants.COMMENTS_TO_CARRIER).enable();
        break;
      }
      this.rateFormGroup.get(MarketplaceConstants.AMOUNT).updateValueAndValidity();
    });
    this.assignBudgetToggle(true);
  }

  /**
   * @description sets and updates the value of amount input with the value of the tariff suggested
   */
  public updateAmountInForm (): void {
    this.rateFormGroup.get('amount').setValue(this.amountSuggested);
    this.rateFormGroup.updateValueAndValidity();
  }

  /**
   * @description force to detect changes on input tariff to set the cost of tariff to formField.
   * also checks if the user back to another step to change the origin/destination to suggest a new tariff
   */
  public validateTariffExistent(): void {
    const amountInput = this.rateFormGroup.get('amount').value ? this.rateFormGroup.get('amount').value : 0;
    this.changeDetector.detectChanges();
    if (this.isUserReturn) {
      if (this.lastTariffSuggested !== this.tariffSuggested) {
        this.amountSuggested = this.tariffSuggested.cost ? this.tariffSuggested.cost : 0;
      } else if (amountInput !== (this.tariffSuggested && this.tariffSuggested.cost)) {
        this.amountSuggested = amountInput;
      }
    } else {
      this.lastTariffSuggested = this.tariffSuggested;
      if (this.tariffSuggested && this.isAmountAutocompleted) {
        this.amountSuggested = this.tariffSuggested.cost ? this.tariffSuggested.cost : 0;
        this.tariffFound = true;
        this.isAmountAutocompleted = true;
      } else if (amountInput > 0 && !this.isAmountAutocompleted) {
        this.amountSuggested = amountInput;
      } else {
        this.amountSuggested = 0;
        this.tariffFound = false;
        this.isAmountAutocompleted = true;
      }
    }
  }

  /**
   * @description initialize the rate form
   */
  public initRateForm(): void {
    this.rateFormGroup = this.formBuilder.group({
      rateOption: new UntypedFormControl(''),
      receiveCounterOffers: new UntypedFormControl(false),
      amount: new UntypedFormControl(this.amountSuggested, [Validators.required]),
      commentsToCarrier: new UntypedFormControl('')
    });
  }

  /**
   * @description Check if the key board event value is a valid pattern
   * @param {KeyboardEvent} event The Key board event
   */
  public onKeyPressCode(event: KeyboardEvent): void {
    const pattern = GenericRegexp.ONLY_NUMBERS;
    const inputChar = event.key;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
   * @description validate and change value of a variable
   * @param {boolean} assignBudgetStatus assignBudgetStatus status of assignBudget. Can be enabled/disabled
   */
  public assignBudgetToggle(assignBudgetStatus: boolean): void {
    if (assignBudgetStatus) {
      this.assignBudget = true;
      this.receiveOffers = false;
      this.tariffFound = false;
      this.rateFormGroup.get(MarketplaceConstants.RATE_OPTION).setValue(MarketplaceConstants.ASSIGN_BUDGET);
      this.rateFormGroup.get(MarketplaceConstants.COMMENTS_TO_CARRIER).setValue(MarketplaceConstants.EMPTY_STRING);
      this.getInputStatus();
    } else {
      this.assignBudget = false;
      this.tariffFound = false;
      this.receiveCounterOffers = false;
      this.receiveOffers = false;
      this.rateFormGroup.get(MarketplaceConstants.RATE_OPTION).setValue(MarketplaceConstants.EMPTY_STRING);
      this.rateFormGroup.get(MarketplaceConstants.AMOUNT).setValue(MarketplaceConstants.EMPTY_STRING);
      this.rateFormGroup.get(MarketplaceConstants.AMOUNT).disable();
      this.invalidAmount = false;
      this.invalidMinAmount = false;
      this.getInputStatus();
    }
  }

  /**
   * @description check if the amount is valid and hide other errors to avoid display more than 1 error at the same time
   * @param {boolean} isUserInput flag to know if the method is called from the interaction of the user with amount input
   * or if the method is called by another method
   */
  public validateAmount(isUserInput: boolean): void {
    const amountInput = this.rateFormGroup.get(MarketplaceConstants.AMOUNT).value;
    if ((!this.tariffSuggested) || (this.tariffSuggested.cost <= 0) || (this.tariffSuggested.cost !== amountInput)) {
      this.tariffFound = false;
    }
    if (amountInput <= 0) {
      this.rateFormGroup.controls['amount'].setErrors({'invalidAmount': true});
    }
    this.isAmountAutocompleted = !isUserInput;
    this.getInputStatus();
  }

  /**
   * @description check if the rate step is valid to set a status for rate step
   */
  public getInputStatus(): void {
    if (!this.assignBudget && !this.receiveOffers) {
      this.invalidInput = true ;
    } else if (this.rateFormGroup.controls[MarketplaceConstants.AMOUNT].valid ||
      this.rateFormGroup.controls[MarketplaceConstants.AMOUNT].disabled) {
      this.invalidInput = false ;
    } else {
      this.invalidInput = true ;
    }
    this.updateInvalidAmountInputStatus(this.invalidInput);
  }

  /**
   * @description update the status of amount's input
   * @param {boolean} isAmountInvalid status of amount's input (valid/invalid)
   **/
  public updateInvalidAmountInputStatus(isAmountInvalid: boolean): void {
    this.appService.checkAmountInputStatus(isAmountInvalid);
  }

  /**
   * @description validate and change value of a variable
   * @param {boolean} receiveOffersStatus assignBudgetStatus status of assignBudget. Can be enabled/disabled
   */
  public receiveOffersToggle(receiveOffersStatus: boolean): void {
    if (receiveOffersStatus) {
      this.receiveOffers = true;
      this.assignBudget = false;
      this.receiveCounterOffers = false;
      this.invalidAmount = false;
      this.invalidMinAmount = false;
      this.tariffFound = false;
      this.rateFormGroup.get(MarketplaceConstants.RATE_OPTION).setValue(MarketplaceConstants.RECEIVE_OFFERS);
      this.rateFormGroup.get(MarketplaceConstants.AMOUNT).setValue(MarketplaceConstants.EMPTY_STRING);
      this.getInputStatus();
    } else {
      this.receiveOffers = false;
      this.rateFormGroup.get(MarketplaceConstants.COMMENTS_TO_CARRIER).setValue(MarketplaceConstants.EMPTY_STRING);
      this.rateFormGroup.get(MarketplaceConstants.COMMENTS_TO_CARRIER).disable();
      this.rateFormGroup.get(MarketplaceConstants.RATE_OPTION).setValue(MarketplaceConstants.EMPTY_STRING);
      this.invalidAmount = false;
      this.tariffFound = false;
      this.invalidMinAmount = false;
      this.getInputStatus();
    }
  }

  /**
   * @description Send the requirements input to the service
   * @param {string} requirements The requirement's name
   */
  public sendCommentsToCarrier(requirements: string): void {
    this.marketplaceService.specialRequirementsInput = requirements;
  }

  /**
   * @description Send the data of the rate
   * @return {FormGroup} The data of the rate
   */
  public sendRateData(): UntypedFormGroup  {
    const rate = this.rateFormGroup;
    return rate;
  }
}
