import { Injectable } from '@angular/core';

import { AccountProvider } from '../../providers/accounts/account-provider.service';
import { AppConstants } from '../../constants/app-constants.constants';
import { AppService } from '../../app.service';
import { DATEFORMAT_CONSTANTS } from '../../constants/dateformat.constants';
import { DateToolsService } from '../utils/date-tools.service';
import { Destination, SearsProduct, SearsOrderBody, SearsOrderPostDate } from '../../interfaces/sears';
import { EditOrderData, Product, Location } from '../../pages/orders/edit-order/interfaces/edit-order-data.interface';
import { GenericRegexp } from '../../regexp/generic.regexp';
import { LocationApiResponse, OrderInfo, Shipment, Shipments } from '../../interfaces';
import { LocationProvider } from '../../providers/locations/location-provider.service';
import { WhiteGuidesCodes, WhiteGuidesNumericalCodes } from '../../enums/white-guides-codes';
import { ShipmentProvider } from '../../providers/shipments/shipment-provider.service';
import { UtilsService } from '../utils.service';

import * as moment from 'moment-timezone';

@Injectable()
export class SearsOrderDataService {
  public shipperLocations: Array<LocationApiResponse>;
  constructor(
    private accountsProvider: AccountProvider,
    private appService: AppService,
    private dateToolsService: DateToolsService,
    private locationProvider: LocationProvider,
    private shipmentProvider: ShipmentProvider,
    private utilsService: UtilsService
  ) {
    this.locationProvider.getDestinationsByShipper().subscribe(
      (locations) => {
        this.shipperLocations = locations;
      }, () => {}
    );
  }

  /**
   * @description Builds object with order edited data to be sent
   * @param {EditOrderData} orderEdited Data of edited order
   * @param {string} orderAccount Name of account where order's belong
   * @returns {Promise<SearsOrderBody>} Object with necessary data that will be sent
   */
  public async buildOrderEditedData(orderEdited: EditOrderData, orderAccount: string): Promise<SearsOrderBody> {
    orderEdited.destination = this.searchAnUpdateDestinationData(orderEdited.destination);
    const allShipperAccounts = await this.accountsProvider.getAccounts();
    const accountData = allShipperAccounts.cuentas.find(account => account.nombre === orderAccount);
    const timezone = moment.tz.guess();
    const ordeDataToShare = {
      account: accountData.nombre,
      accountReference: accountData.prefix ? accountData.prefix : null,
      orderType: orderEdited.type,
      invoice: orderEdited.invoice,
      orderGrouper: orderEdited.orderGrouper,
      serviceType: orderEdited.serviceType,
      identifier: orderEdited.identifier,
      internalReference: orderEdited.internalReference,
      deliveryDate: moment(orderEdited.deliveryDate,
        DATEFORMAT_CONSTANTS.DATE_FORMAT_DB).format(DATEFORMAT_CONSTANTS.SLASH_DATE_FORMAT_DDMMYY),
      deliveryTime: orderEdited.appointmentHour,
      weight: orderEdited.weight,
      volume: orderEdited.volume,
      pieces: orderEdited.pieces,
      boxes: orderEdited.boxes,
      pallets: orderEdited.pallets,
      guides: orderEdited.guideCode ? this.getGuidesTypeNumericalCode(orderEdited.guideCode) : 0,
      customerPickUp: orderEdited.customerPickup,
      receptorCode: orderEdited.receptor,
      receptorPickup: orderEdited.receptorPickup ? true : false,
      timezone: timezone,
      origin: {
        name: orderEdited.origin.name
      },
      destination: this.buildDestinationData(orderEdited.destination),
      products: this.buildProductData(orderEdited.products),
    };

    return ordeDataToShare;
  }

  /**
   * @description Builds object with order edited data to be sent.
   * @param {EditOrderData} orderEdited - Data of edited order.
   * @param {EditOrderData} order - Data of edited order.
   * @returns {Promise<SearsOrderPostDate>} Object type 'SearsOrderPosDate' with necessary data that will be sent.
   */
  public async buildOrderPosDate(orderEdited: EditOrderData, order: OrderInfo): Promise<SearsOrderPostDate> {
    try {
      const literal = 'T';
      const cvLine = 'SE';
      const limit = 1;
      const warehouse = await this.appService.getUserWarehouse();
      const user = (await this.appService.getShipperNameCookie() ?? AppConstants.ZERO).match(GenericRegexp.NUMERICAL_NUMBER)[0] ?? AppConstants.ZERO;
      let newDate: string;
      if (orderEdited.deliveryDate instanceof Date) {
        newDate = this.dateToolsService.formatISO(orderEdited.deliveryDate).split(literal, limit).toString();
      } else {
        newDate = (orderEdited.deliveryDate as string).split(literal, limit).toString();
      }
      let previousDate: string;
      if (orderEdited?.postDatedInformation?.previousDeliveryDate) {
        previousDate = this.dateToolsService.transformDateFormat(
          orderEdited?.postDatedInformation?.previousDeliveryDate as string,
          DATEFORMAT_CONSTANTS.DATE_FORMAT_YYYYMMDD
        );
      } else if (orderEdited.deliveryDate) {
        previousDate = new Date(orderEdited.deliveryDate).toISOString().split(literal, limit).toString();
      }
      const movement_Date =
        this.dateToolsService.transformDateFormat(this.dateToolsService.getLocalTime(new Date()), DATEFORMAT_CONSTANTS.DATE_FORMAT_YYYYMMDD);
      let economic = 0;
      let stop = 0;

      if (order.shipment && order.shipment.length) {
        const shipmentIdList = order.shipment.map((item: Shipment) => {
          return item.shipmentId;
        });
        const tenantId = await this.appService.getShipperOid();
        const shipmentResponse = await this.shipmentProvider.getShipmentByShippmentIds(tenantId, shipmentIdList);

        if (shipmentResponse && shipmentResponse.shipments?.length) {
          const shipment: Shipments = shipmentResponse.shipments.pop();
          const economicValue = (shipment?.transport?.plates ?? AppConstants.ZERO_STRING)
            .match(GenericRegexp.NUMBERS_STRING)?.join(AppConstants.EMPTY_STRING);
          economic = this.utilsService.castNumber(economicValue) ?? 0;
          const orderFound = shipment.orders?.find(item => item._id.toString() === order._id.toString());
          stop = orderFound?.stop ?? AppConstants.ZERO;
        }
      }

      const cvReason = this.utilsService.castNumber(orderEdited?.postDatedInformation?.postDatedReason?.identifier) ?? AppConstants.ZERO;
      const descriptionReason = orderEdited?.postDatedInformation?.postDatedReason?.description ?? AppConstants.EMPTY_STRING;
      const searsOrderPosDate: SearsOrderPostDate = {
        identifier: orderEdited.identifier,
        orderGrouper: orderEdited.orderGrouper,
        warehouse: this.utilsService.castNumber(warehouse.name) ?? AppConstants.ZERO,
        user_Posfecha: this.utilsService.castNumber(user) ?? AppConstants.ZERO,
        movement_Date: movement_Date,
        new_Date: newDate,
        previous_Date: previousDate,
        cv_Reason: cvReason,
        description_Reason: descriptionReason,
        status: order.status,
        cv_Line: cvLine,
        economic: economic,
        stop_number: stop
      };

      return searsOrderPosDate;
    } catch (error) {
      return null;
    }
  }

  /**
   * @description builds product info for edited order
   * @param {Array<Product>} orderProducts Order products will be processed
   * @returns {Array<SearsProduct>} Order products transformed into new object with necessary properties for the order
   */
  public buildProductData(orderProducts: Array<Product>): Array<SearsProduct> {
    const productsInfo = [];
    for (const product of orderProducts) {
      const productBody = {
        code: product.code,
        name: product.name,
        unitKey: product.unitKey,
        unit: product.unitKey,
        productServiceKey: product.prodServKey,
        total: product.total,
        weight: product.weight,
        volume: product.volume,
        dangerousMaterial: product.isDangerousMaterial ? true : false,
        dangerousMaterialKey: product.dangerousMaterialKey,
        packaging: product.packaging,
        packagingDescription: product.packagingDescription,
        tariffFraction: product.tariffFraction,
        foreignTradeUuid: product.foreignTradeUuid,
        price: product.price,
        cmm: product.cmm ? true : false
      };
      productsInfo.push(productBody)
    }

    return productsInfo;
  }

  /**
   * @description builds destination data for the order
   * @param {Location} destinationData Data from destination of edited order
   * @returns {Destination} New destination property with necessary info
   */
  public buildDestinationData(destinationData: Location): Destination {
    return {
      address: destinationData.address,
      country: AppConstants.MEXICO_COUNTRY,
      identifier: destinationData.locationId ? destinationData.locationId : destinationData.name,
      internalNumber: destinationData.internalNumber ? destinationData.internalNumber : AppConstants.NO_APPLY,
      externalNumber: destinationData.externalNumber ? destinationData.externalNumber : AppConstants.EMPTY_STRING,
      municipality: destinationData.municipality,
      name: destinationData.name,
      postalCode: destinationData.postalCode,
      rfc: this.getRFCFromDestination(destinationData),
      settlement: destinationData.settlement,
      state: destinationData.state,
    }
  }

  /**
   * @description gets RFC from location depending destination properties
   * @param {Location} destinationData Object with all destination data
   * @returns {string} destination rfc if found
   */
  public getRFCFromDestination(destinationData: Location): string {
    if (destinationData && destinationData.rfc) {
      return destinationData.rfc;
    } else if (destinationData && destinationData.locationRFC) {
      return destinationData.locationRFC;
    } else {
      return null;
    }
  }

  /**
   * @description Checks Guide type selected for the order to return numerical value to option selected
   * @param {string} guideCode Guide code setted in order 
   * @returns {number} guide code selected but into numerical value
   */
  public getGuidesTypeNumericalCode(guideCode: string): number {
    switch (guideCode) {
      case WhiteGuidesCodes.boxes: {
        return WhiteGuidesNumericalCodes.boxes;
      }
      case WhiteGuidesCodes.pallets: {
        return WhiteGuidesNumericalCodes.pallets;
      }
      case WhiteGuidesCodes.Pieces: {
        return WhiteGuidesNumericalCodes.Pieces;
      }
    }
  }

  /**
   * @description Searchs destination data by name or identifier (if by name not found coincidences)
   * and sets external a internal number data
   * @param {Location} destination all data about destination
   * @returns {Location} Destination with new data setted if exists coincidences, otherwise returns the same object received
   */
  public searchAnUpdateDestinationData(destination: Location): Location {
    const searchByName = this.shipperLocations.find(location => location.nombre === destination.name);
    let searchByIdentifier;
    if (searchByName) {
      destination.externalNumber = searchByName.numeroExterior;
      destination.internalNumber = searchByName.numeroInterior;

      return destination;
    } else if (!searchByName && destination.locationId) {
      searchByIdentifier = this.shipperLocations.find(location => location.alias === destination.locationId);
      if (searchByIdentifier) {
        destination.externalNumber = searchByIdentifier.numeroExterior;
        destination.internalNumber = searchByIdentifier.numeroInterior;

        return destination;
      }
    } else {
      return destination;
    }
  }
}
