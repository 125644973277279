<mat-grid-list cols="4" rowHeight="1:.3">
  <form [formGroup]="recurrenceForm">
    <mat-grid-tile *ngIf="!recurrence"> 
      <mat-form-field>
        <mat-select 
          id="accountSelect" 
          [placeholder]="labels.accountPlaceholder" 
          required 
          formControlName="account">
            <mat-option [disabled]="true">--</mat-option>
            <mat-option *ngFor="let account of availableAccounts" [value]="account._id">
              {{ account.name }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="recurrenceForm.controls['account'].hasError('required')">
          {{ labels.accountPlaceholder }} <strong>{{ labels.isRequired }}{{ labels.suffixA }}</strong>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field>
        <mat-select 
          id="periodicitySelect" 
          [placeholder]="labels.periodicityPlaceholder" 
          required
          formControlName="type" 
          (selectionChange)="onRecurrenceTypeChange($event.value)">
            <mat-option [disabled]="true">--</mat-option>
            <mat-option *ngFor="let typeItem of recurrenceTypes" [value]="typeItem.id">
              {{ typeItem.desc }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="recurrenceForm.controls['type'].hasError('required')">
          {{ labels.periodicityPlaceholder }} <strong>{{ labels.isRequired }}{{ labels.suffixA }}</strong>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="showFormField('weekday')">
      <mat-form-field>
        <mat-select 
          id="weekdaySelect" 
          [placeholder]="labels.weekdayPlaceholder" 
          required 
          formControlName="weekday">
            <mat-option [disabled]="true">--</mat-option>
            <mat-option *ngFor="let weekday of weekdays" [value]="weekday.id">
              {{ weekday.desc }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="recurrenceForm.controls['weekday'].hasError('required')">
          {{ labels.weekdayPlaceholder }} <strong>{{ labels.isRequired }}{{ labels.suffixO }}</strong>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field>
        <input 
          id="executionTime"
          [placeholder]="labels.executionTime"
          required
          matInput type="time"
          formControlName="executionTime">
        <mat-error *ngIf="recurrenceForm.controls['executionTime'].hasError('required')">
          {{ labels.executionTime }} <strong>{{ labels.isRequired }}{{ labels.suffixA }}</strong>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </form>
</mat-grid-list>

<mat-grid-list cols="8" rowHeight="1:.30">
  <mat-grid-tile colspan="6"></mat-grid-tile>
  <mat-grid-tile>
    <button 
      id="cancelPodButton"
      mat-stroked-button 
      color="primary" 
      class="cancel-button" 
      (click)="onCancel()">
      {{ labels.cancel }}
    </button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button 
      id="savePodButton"
      mat-raised-button 
      color="primary" 
      [disabled]="recurrenceForm.invalid" 
      class="save-button"
      (click)="savePOD()">
      {{ labels.save }}
    </button>
  </mat-grid-tile>
</mat-grid-list>