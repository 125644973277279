export enum ModuleSequence {
  home,
  specific_settings,
  orders,
  shipments,
  security,
  shipment_request,
  tracking,
  evidences,
  cost,
  catalogs
}
