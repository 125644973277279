import { ExcelExternalProperties } from '../../../../interfaces/worksheet-excel';
import { WorkSheet } from '../../../../interfaces/costing-sheets-report';

import { Injectable } from '@angular/core';

import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';

const EMPTY_STRING = '';
const EXCEL_DEFAULT_ROW_HEIGHT = '20';
const EXCEL_EXTENSION = '.xls';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXTRA_ROWS = 27;
const FILE_NAME_SEPARATOR = '_';
const FOLIO_PARAM = 'SF';

@Injectable()
export class ExcelServiceReport {
  constructor() { }

  /**
   * @description Build columns headers besed on exceljs lib
   * @param headers string[] Columns headers
   * @returns {excelHeaders} object that contains the headers of the excel
   */
  private buildExcelColumns (headers: string[]) {
    const excelHeaders = [];
    if (headers.length) {
      headers.forEach((header: string) => {
        const excelHeader = { header: header };
        excelHeaders.push(excelHeader);
      });
    } else {
      excelHeaders.push({ header: '' });
    }

    return excelHeaders;
  }

  /**
   * @description Export Excel file
   * @param worksheets Worksheet[] Worksheets to be exported
   * @param fileName String Excel file name to be exported
   * @param externalProperties Additional properties for excel row
   */
  public exportAsExcelFile (worksheets: WorkSheet, fileName: string, externalProperties?: ExcelExternalProperties ): void {
    const workbook = new Excel.Workbook();
    worksheets.CostingSheet.forEach(ws => {
      let workSheetTitle = ws.WorkSheetGeneralInformation.data.filter(folio => folio.toString().includes(FOLIO_PARAM)).toString();
      const workSheetExists = workbook.worksheets.filter(sheet => sheet.name.includes(workSheetTitle));
      if (workSheetExists && workSheetExists.length) {
        workSheetTitle = `${workSheetTitle} (${workSheetExists.length})`
      }
      const worksheet = workbook.addWorksheet(workSheetTitle);
      worksheet.properties.defaultRowHeight = EXCEL_DEFAULT_ROW_HEIGHT;
      for (let index = 0; index < ws.WorkSheetGeneralInformation.data.length; index++) {
        const temporalArray = [ws.WorkSheetGeneralInformation.headers[index].toString(),
          ws.WorkSheetGeneralInformation.data[index].toString()];
        for (let i = 0; i < EXTRA_ROWS; i++) {
          temporalArray.push(EMPTY_STRING);
        }
        worksheet.addRows([temporalArray]);
      }
      worksheet.addRows(ws.TableInformation.data);
    });
    if (externalProperties) {
      this.saveAsExcelFile(workbook, fileName, externalProperties.setFullName);
    } else {
      this.saveAsExcelFile(workbook, fileName);
    }
  }

  /**
   * @description Create and dowload Excel file
   * @param workbook Excel Workbook
   * @param fileName File name to be exported
   * @param setFullName Parameter that determines the type of name that the file will have
   */
  private saveAsExcelFile(workbook: any, fileName: string, setFullName?: boolean): void {
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      if (setFullName) {
        FileSaver.saveAs(blob, fileName + EXCEL_EXTENSION);
      } else {
        FileSaver.saveAs(blob, fileName + FILE_NAME_SEPARATOR + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  }
}
