export const SALES_SHEET_CONSTANTS = {
  DAYS: 30,
  DEFAULT_ICON: 'info',
  DIALOG_WIDTH: '650px',
  DIVIDER: 100,
  DUPLICATE_CODE: 409,
  INSERT_POSITION: 3,
  KEY_ACCOUNT: 'account',
  KEY_ACCOUNT_NAME: 'accountName',
  KEY_CHARGE_PREFIX: 'charge_',
  KEY_REPORT_BUTTON: 'ss-report-button',
  KEY_SHIPMENT_ID: 'shipmentId',
  KEY_SUBTOTAL: 'subTotal',
  KEY_TOTAL: 'total',
  KEY_TOTAL_CHARGE: 'totalCharge',
  KEY_TOTAL_COST_OF_SALE: 'costOfSale',
  KEY_VAT: 'vat',
  MAX_LENGHT_STRING: 16,
  MAX_LENGTH_COMMENTS: 300,
  MIN_LENGTH_COMMENTS: 3,
  MIN_LENGTH_SHIPMENTID: 13,
  TIMEOUT: 100
};
