export const NewShipmentRequestProperties = {
  blackPin: '../../../../assets/icons/tracking/Pins/Black/hugeBlackPin.svg',
  delete: 'delete',
  greenPin: '../../../../assets/icons/tracking/Pins/Green/greenIcon.png',
  infoIcon: '../../../../assets/icon-info.svg',
  redPin: '../../../../assets/icons/tracking/Pins/Green/redIcon.png',
};

export enum NewShipmentRequestOriginProperties {
  cancelDialogWidth = '650px',
  dialogWidth = '900px'
}
