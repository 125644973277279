import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { CourierRequest } from '../../interfaces/couriers/courier-request.class';
import { CourierResponse } from '../../interfaces/couriers/courier-response';
import { Credential } from '../../interfaces/couriers/credential.interface';
import { WaybillResponse } from '../../interfaces/couriers/waybill-response.interface';

const courierApi = environment.courierConnectionUrl;

@Injectable()
export class CourierService {

  constructor(
    private http: HttpClient,
  ) { }


  /**
   * @description Courier connection api request for generate waybills
   * @param {CourierRequest} waybillRequest Waybill request data
   * @returns {Promise<CourierResponse>} The waybills generate
   */
  public requestWaybill(waybillRequest: CourierRequest): Promise<CourierResponse> {
    const url = `${courierApi}/shipment/without-rate`;
    return this.http.post<CourierResponse>(url, waybillRequest).toPromise();
  }

  /**
   * @description Courier conenction api request for credentials by tenant and type
   * @param {string} tenantId The client identifier
   * @param {string} type The credential type
   * @returns {Observable<Array<Credential>>} Observable with credential list
   */
  public getCredentialsByShipperAndType(tenantId: string, type: string): Observable<Array<Credential>> {
    const url = `${courierApi}/credential/tenantId/${tenantId}/type/${type}`;
    return this.http.get<Array<Credential>>(url);
  }

  /**
   * @description Get courier guides by order
   * @param {string} tenantId The tenantId to find
   * @param {string} folio The order's folio
   * @returns {Array<WaybillResponse>} Observable with credential list
   */
   public getCourierGuidesByOrder(tenantId: string, folio: string): Promise<Array<WaybillResponse>> {
    const url = `${courierApi}/general/tenantId/${tenantId}/order/${folio}`;
    return this.http.get<Array<WaybillResponse>>(url).toPromise();
  }
}
