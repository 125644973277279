<mat-form-field class="search-mat-container">
  <mat-select
    [formControl]="fieldMultiCtrl"
    [placeholder]="selectLabel"
    [multiple]="!isUniqueSelect"
    [required]="isRequiredInput"
    (selectionChange)="emitSelectionChange($event)"
    #multiSelect>
    <mat-option>
      <ngx-mat-select-search
      [formControl]="fieldMultiFilterCtrl"
      noEntriesFoundLabel="{{ 'searchFilterLabels.noMatches' | translate }}"
      showToggleAllCheckbox="true"
      [toggleAllCheckboxIndeterminate]="isIndeterminate"
      [toggleAllCheckboxChecked]="isChecked"
      toggleAllCheckboxTooltipMessage="{{ 'searchFilterLabels.toggleAllCheckboxTooltipMessage' | translate }}"
      (toggleAll)="toggleSelectAll($event)"
      placeholderLabel="{{ 'searchFilterLabels.placeholderLabel' | translate }}"
      [hideClearSearchButton]="true"
      minlength="3">
    </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let field of filteredFieldsMulti | async" [value]="field">
      {{ field.name }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="fieldMultiCtrl.hasError('required')">
    {{ errorMessageText ? errorMessageText : ('searchFilterLabels.fieldRequiredError' | translate) }}
  </mat-error>
</mat-form-field>
