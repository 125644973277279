import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RatesBody, UpdateStatus, FreightRateBody, RatesParamsSearch, RateResponse } from '../../interfaces/freight-rate';
const apiUrl = environment.freightRateApiUrl;

@Injectable()
export class FreightRateProvider {

  constructor(private http: HttpClient) {}

  public async getAllRatesByTenantId(tenantId: string): Promise<Array<FreightRateBody>> {
    return await this.http.get<Array<FreightRateBody>>(apiUrl + '/rate/tenant/' + tenantId).toPromise();
  }

  public async getRatesByCarrierId(tenantId: string, carrierId: string): Promise<Array<FreightRateBody>> {
    return await this.http.get<Array<FreightRateBody>>(apiUrl + '/rate/tenant/' + tenantId + '/carrier/' + carrierId).toPromise();
  }

  public async postNewFreightRate(tenantId: any, bodyRate: any): Promise<any> {
    return await this.http.post<Object>(apiUrl + '/rate/tenant/' + tenantId, bodyRate).toPromise();
  }

  public async putUpdateFreightRate(tenantId: any, bodyRate: any): Promise <any> {
    return await this.http.put<Object>(apiUrl + '/rate/tenant/' + tenantId, bodyRate).toPromise();
  }

  public async deleteFreightRate(tenantId: any, rateId: any): Promise<any> {
    return await this.http.delete<Object>(apiUrl + '/rate/tenant/' + tenantId + '/rate/' + rateId).toPromise();
  }

  public async postCreateMassiveFreightRate(tenantId: string, bodyRate: RatesBody): Promise<Array<object>> {
    return await this.http.post<Array<object>>(apiUrl + '/rate/tenant/' + tenantId, bodyRate).toPromise();
  }

  public async updateRateStatus(tenantId: string, rateId, body: UpdateStatus): Promise<object> {
    return await this.http.put<UpdateStatus>(apiUrl + '/rate/tenant/' + tenantId + '/rate/' + rateId + '/status', body).toPromise();
  }

  /**
   * @description Gets multiples rates by params.
   * @param {string} shipperOid - Current shipper Object ID.
   * @param {RatesParamsSearch} params - Params to search rates.
   * @returns {RateResponse} Rates found and success message.
   */
  public async getMuliplesRatesByParams(shipperOid: string, params: RatesParamsSearch): Promise<RateResponse> {
    return await this.http.post<RateResponse>(apiUrl + '/shipper/' + shipperOid + '/ratesByParams', params).toPromise();
  }
}
