import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogShipperCreatedData } from '../../../interfaces';

const CONFIRM = 'Confirm';

@Component({
  selector: 'app-dialog-shipper-created',
  templateUrl: './dialog-shipper-created.component.html',
  styleUrls: ['./dialog-shipper-created.component.scss', '../../../app.component.scss']
})
export class DialogShipperCreatedComponent {

  constructor(
    private dialogRef: MatDialogRef<DialogShipperCreatedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogShipperCreatedData
  ) { }

  /**
   * @description Close the dialog when close or continue button is clicked
   */
  public onClose(): void {
    this.dialogRef.close(CONFIRM);
  }
}
