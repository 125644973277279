import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogFilePreviewComponent } from '../dialog-file-preview/dialog-file-preview.component';
import { DialogFilesDetail } from '../../../interfaces/dialog-files-detail';
import { DialogFilesDetailProperties } from './dialog-files-detail.properties';
import { environment } from '../../../../environments/environment';
import { EvidenceToDisplay } from '../../../interfaces';
import { FileConversorService } from '../../../services/file-conversor';
import { IDialogFilesDetailLabels } from '../../../interfaces/labels/dialog-labels.interface';
import { ILanguageLabels } from '../../../interfaces/labels/language-labels.interface';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

import { Subscription } from 'rxjs';

const BLOB_STORAGE_URL = environment.baseStorageUrl + environment.mainContainer;
const CLOSED = 'Closed';
const PDF_EXTENSION = '.pdf';

@Component({
  selector: 'app-dialog-files-detail',
  templateUrl: './dialog-files-detail.component.html',
  styleUrls: ['./dialog-files-detail.component.scss']
})
export class DialogFilesDetailComponent implements OnInit {
  public dialogFilesDetailLabels: IDialogFilesDetailLabels;
  public images: Array<EvidenceToDisplay>;
  public imageToShow: Array<string>;
  public initialPosition: number;
  public isDisableButtons: boolean;
  public languageLabels: ILanguageLabels;
  public languageSuscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogFilesDetail,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService,
    public dialogDetail: MatDialog,
    public dialogRef: MatDialogRef<DialogFilesDetailComponent>,
    public fileConversorService: FileConversorService,
    private toast: ToastrAlertsService
    ) {
      this.setLanguage();
    }

  async ngOnInit() {
    this.initialPosition = 0;
    this.isDisableButtons = (this.data.imageArray.length <= 1);
    this.getImageToShow();
    this.fileConversorService.fileConversor(this.imageToShow, false).then((res) => {
      this.images = res;
    });
    this.subscribeLanguageChangeEvent();
    await this.getLabels();
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Gets the necessary tags from the JSON files to use throughout the component
   * @return {void}
   */
  public async getLabels(): Promise<void> {
    await this.getLanguageLabels();
    await this.getDialogFilesDetailLabels();
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getLabels();
      },
      () => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageLabels(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets Dialog Files Detail Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getDialogFilesDetailLabels(): Promise<void> {
    this.dialogFilesDetailLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.DIALOG_FILES_DETAIL_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Form the url to get the imagened from the blob
   */
  public getImageToShow(): void {
    this.imageToShow = [];
    this.data.imageArray.forEach((value) => {
      this.imageToShow.push(BLOB_STORAGE_URL + value);
    });
  }

  public onClickClose(): void {
    this.dialogRef.close(CLOSED);
  }

  /**
   * @description Change position picture for a previous one
   */
  public previousPicture(): void {
    if (this.initialPosition <= 0) {
      this.initialPosition = this.imageToShow.length - 1;
    } else {
      this.initialPosition--;
    }
  }

  /**
   * @description Change position picture for a next one
   */
  public nextPicture(): void {
    if (this.initialPosition >= this.imageToShow.length - 1) {
      this.initialPosition = 0;
    } else {
      this.initialPosition++;
    }
  }

  /**
   * @description It validates if a URL string has pdf extension
   * @param file url file
   */
  public validatePDFExtension(file: string): boolean {
    file = file.toLowerCase();
    return file.endsWith(PDF_EXTENSION);
  }

  /**
   * @description Sent url image view to other dialog to show in a bigger size
   * @param fileUrl Url of actual image
   */
  public onFileClick(fileUrl: string): void {
    const obj = this.images.filter(i => i.url === fileUrl);
    this.dialogDetail.open(DialogFilePreviewComponent, {
      data: {
        title: this.dialogFilesDetailLabels.dialogFilesPreviewTitle,
        file: obj[0].file
      },
      width: DialogFilesDetailProperties.DialogFilesPreviewWidth
    });
  }
}
