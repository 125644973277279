import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogShipmentDetail, IDialogShipmentDetail } from './dialog-shipment-detail.labels';
import { OrderForShipment, ShipmentWithRequestData, Tracking } from '../../../interfaces';
import { TrackingService} from '../../../providers/tracking/tracking.service';

import { GeoCoord, HaversineService } from 'ng2-haversine';

const INTRANSIT = 'En Transito';

@Component({
  selector: 'app-dialog-shipment-detail',
  templateUrl: './dialog-shipment-detail.component.html',
  styleUrls: ['./dialog-shipment-detail.component.scss', '../../../app.component.scss']
})
export class DialogShipmentDetailComponent implements OnInit {
  public appointmentHour: Date;
  public countOrders: number;
  public inTransit: boolean;
  public labels: IDialogShipmentDetail;
  public lastOrder: OrderForShipment;
  public orders: Array<OrderForShipment>;
  public requirements: Array<string>;
  public shipment: ShipmentWithRequestData;
  public traveledRoute: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ShipmentWithRequestData,
    private dialogRef: MatDialogRef<DialogShipmentDetailComponent>,
    private _haversineSer: HaversineService,
    private _trackingSer: TrackingService
  ) {
    this.shipment = data;
    this.orders = data.orders;
    this.requirements = [];
    this.countOrders = this.orders.length;
    this.lastOrder = this.orders[this.countOrders - 1];
   }

  /**
   * @description fires when initialize component
   */
  public async ngOnInit(): Promise<void> {
    this.labels = DialogShipmentDetail;
    this.buildRequeriments();
    this.inTransit = this.shipment.status === INTRANSIT ? true : false;
    if (this.inTransit) {
      this.shipment.tracking = await this.getTrackingOfShipmentRequest(this.shipment);
      this.getTravelRoute();
    }
  }

  /**
   * @description Build special requirements of shipment
   */
  public buildRequeriments(): void {
    if (this.shipment.specialRequirements?.requirements || this.shipment.specialRequirements?.otherRequirements) {
      let req: Array<string> = [];
      req = req.concat(this.shipment.specialRequirements.requirements);
      if (this.shipment.specialRequirements.otherRequirements !== '') {
        req = req.concat(this.shipment.specialRequirements.otherRequirements);
      }

      this.requirements = req;
    }
  }

  /**
   * @description Build generic Date bassed in time in format 'hh:mm'
   * @param {string} time Time in format 'hh:mm'
   * @returns {Date} Date builded bassed in time received
   */
  public getDateFromTime(time: string): Date {
    const dateBase = '1970-01-01 ';
    const datetime = new Date(dateBase + time);
    return datetime;
  }

  /**
   * @description Get tracking of shipment
   * @param { ShipmentWithRequestData } shipment Shipment Request
   * @returns { Pomise<Tracking> } Tracking of shipment
   */
  public async getTrackingOfShipmentRequest(shipment: ShipmentWithRequestData): Promise<Tracking> {
    let tracking: Tracking;
    tracking = await this._trackingSer.getTracking(shipment.shipmentRequestOid);
    return tracking;
  }

  /**
  * @description Calculate traveled distance in kms
  **/
 public getTravelRoute(): void {
  const kilometers = ' km';
   const tracking = this.shipment.tracking;
    if (!tracking) { return; }
    const polyline = tracking.polyline;
    const polylineLength = polyline.length;
    let routeTravel = 0;
    polyline.forEach((value, index) => {
      if (index !== (polylineLength - 1)) {
        const origin: GeoCoord = { latitude: value.latitude, longitude: value.longitude };
        const destination: GeoCoord = { latitude: polyline[index + 1].latitude, longitude: polyline[index + 1].longitude };
        routeTravel = routeTravel + this._haversineSer.getDistanceInKilometers(origin, destination);
      }
    });
    this.traveledRoute = routeTravel.toFixed(2) + kilometers;
  }

    /**
   * @description Close this dialog
   */
    public onClickClose(): void {
      this.dialogRef.close();
    }
}
